import { TextField, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import washerBulkAddModalStyle from '../../assets-soapy/jss/soapy/views/washerBulkAddModalStyle.jsx'
import Card from '../../components-soapy/Card/Card.jsx'
import Modal from '../../components-soapy/Modal.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../containers/withErrorNotification.js'
import useFormFields from '../../shared/hooks/useFormFields.js'
import { ORGANIZATION_ID, VALIDATION_STATE } from './constants.js'
import { ExportToCsv } from 'export-to-csv'
import Button from '../../components/CustomButtons/Button'

const WasherDownloadCSV = ({
  classes,
  currentDashboardUser,
  organizations,
  onClose,
  isOpen,
  washers,
  t
}) => {
  const { formFields, setFormFields } = useFormFields({
    [ORGANIZATION_ID]: {
      value: currentDashboardUser.entity.name === 'organization' ? currentDashboardUser.organizations[0].id : '',
      isRequired: true,
      name: 'Organization'
    },
    csvData: []
  })

  const organizationsObj = {}
  organizations.forEach(o => {
    organizationsObj[o.id] = o
  })

  const handleOrganizationChange = (event, value) => {
    if (value) {
      setFormFields({
        ...formFields,
        [ORGANIZATION_ID]: { ...formFields[ORGANIZATION_ID], value: value.id },
        [ORGANIZATION_ID + VALIDATION_STATE]: ''
      })
    }
  }

  const handleDownloadBtnClick = () => {
    const organizationId = formFields[ORGANIZATION_ID].value
    const organizationName = organizationsObj[formFields[ORGANIZATION_ID].value].name
    const organizationWashers = washers.filter(washer => washer.organization.id === organizationId)
    const data = organizationWashers.map(washer => {
      return {
        description: washer.name,
        id: washer.rfid
      }
    })

    const options = {
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `${organizationName}`
    }

    const csvExporter = new ExportToCsv(options)

    csvExporter.generateCsv(data)
  }

  useEffect(() => {
    return () => {}
  }, [])
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      hideActions
      showGoBackButton
      dialogPaper={classes.dialogPaper}
      onCancel={() => {
        onClose()
      }}
    >
      <GridContainer id="content-pane-layout" justify="center">
        <GridItem md={9}>
          <Card style={{ boxShadow: 'none' }}>
            <CardBody>
              <GridContainer>
                {
                  currentDashboardUser.entity.name === 'neo' || currentDashboardUser.entity.name === 'distributor'
                    ? <>
                      <GridItem
                        md={4}
                        style={{
                          marginBottom: '2rem'
                        }}
                      >
                        <Typography style={{ fontSize: '1.2rem' }}>{t('Organization') + '*'}</Typography>
                      </GridItem>
                      <GridItem
                        md={8}
                      >
                        <Autocomplete
                          style={{
                            width: '20rem',
                            height: '3rem'
                          }}
                          disablePortal
                          disableClearable
                          onChange={handleOrganizationChange}
                          id="combo-box-demo"
                          size="medium"
                          value={organizationsObj[formFields[ORGANIZATION_ID].value]
                            ? organizationsObj[formFields[ORGANIZATION_ID].value]
                            : ''}
                          classes={{ input: classes.autocomplete }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) =>
                            <TextField
                              className={
                                formFields[ORGANIZATION_ID + VALIDATION_STATE] === 'error'
                                  ? classes.inputError
                                  : ''
                              }
                              {...params}
                              variant="outlined"
                            />}
                          options={organizations}
                        />
                      </GridItem>
                    </>
                    : null
                }

                <GridContainer
                  justify="center"
                  alignItems="center"
                  id="card-footer-section"
                >
                </GridContainer>

              </GridContainer>
              <GridContainer
                justify="center"
                alignItems="center"
                id="card-footer-section"
              >
                <GridItem>
                  <div style={{marginTop: "100px"}}>
                    <Button
                      fullWidth
                      color="info"
                      disabled={!organizationsObj[formFields[ORGANIZATION_ID].value]}
                      onClick={handleDownloadBtnClick}>
                      {t('download')}
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Modal>
  )
}

export default withErrorNotification(withAuthentication(withStyles(washerBulkAddModalStyle)(withTranslation()(WasherDownloadCSV))))
