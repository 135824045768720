import React from 'react'
import { Typography, Grid } from '@material-ui/core'

const LegalInfo = () => {
  return (
    <>
      <Typography align='center' variant='subheading'>SOAPY CARE PRIVACY POLICY</Typography>
      <br />
      <Typography>
        Soapy Care Ltd. and its group companies worldwide ("Soapy" or "we") understands that
        your privacy is important to you and that you care about how your personal data is
        processed when setting up, logging into and using ("using") the Soapy Wisdom™ data
        dashboard ("Dashboard") of the Soapy CleanMachineTM hygiene stations ("CleanMachine").
        <br />
        <br />
        When you as a supervisor on behalf of your company use the Dashboard and/or the
        CleanMachine you will be asked to provide the personal details as set out below.
        <br />
        <br />
        Please read this Privacy Policy ("Policy") carefully and ensure that you understand it.
      </Typography>
      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>1.</Typography>
        </Grid>
        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>Definitions and interpretation</Typography>

          <Typography>In this Policy the following terms shall have the following meanings:</Typography>
          <br />
          <br />
          <Grid
            container
            direction='row'
          >
            <Grid
              item
              xs
            >
              <Typography style={{ fontWeight: 'bold' }}>"Applicable Law"</Typography>
            </Grid>
            <Grid
              item
              xs={7}
            >
              <Typography>
              means the provisions of all data protection laws applicable to the processing of personal data by Soapy, e.g. the General Data Protection Regulation (“GDPR”) and the Israeli Protection of Privacy Law, 5741-1981, and secondary legislation.
              </Typography>
            </Grid>

          </Grid>
          <br />
          <Grid
            container
            direction='row'
          >
            <Grid
              item
              xs
            >
              <Typography style={{ fontWeight: 'bold' }}>"Personal Data"</Typography>
            </Grid>
            <Grid
              item
              xs={7}
            >
              <Typography>
              means any and all data that relates to an identified a person who can be directly or indirectly identified from that data. In the present case, it means personal data that you give to us when you use the Dashboard and/or the CleanMachine and as set out in section 3 below.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>2.</Typography>
        </Grid>

        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>What does this Policy cover?</Typography>
          <Typography>This Policy applies to the use and processing of your Personal Data which you provided to us.</Typography>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>3.</Typography>
        </Grid>

        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>What Personal Data do we collect from you?</Typography>
          <br />
          <Grid
            container
            direction='row'
          >
            <Grid item container xs={12}>
              <Grid
                item
                xs
              >
                <Typography>3.1</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>We collect your name, function, address, e-mail address and telePhone number when you use the Dashboard and/or CleanMachine.</Typography>

              </Grid>
            </Grid>
            <br />
            <Grid container xs={12} item>
              <Grid
                item
                xs
              >
                <Typography>3.2</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>We also may collect any other information which you send us via e-mail or choose to give us via other means.</Typography>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>4.</Typography>
        </Grid>

        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>On what legal basis do we process your Personal Data?</Typography>
          <Typography>We process your Personal Data in order to render the contractual services in connection with the use of the Dashboard and/or CleanMachine. Moreover, we might need to obtain your consent in some instances.</Typography>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>5.</Typography>
        </Grid>

        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>Your rights</Typography>
          <br />
          <Grid
            container
            direction='row'
          >
            <Grid container item xs={12}>
              <Grid
                item
                xs
              >
                <Typography>5.1</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>As a data subject, you have the following rights under the Applicable Law:</Typography>
                <Grid container item xs={12}>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>5.1.1</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>the right to be informed about our collection and use of Personal Data;</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>5.1.2</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>the right of access to the Personal Data we hold about you (see section 8);</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>5.1.3</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>the right to rectification if any Personal Data we hold about you is inaccurate or incomplete (please contact us using the details in section 9);</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>5.1.4</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>the right to be forgotten – i.e. the right to ask us to delete any Personal Data we hold about you, as far as such does not interfere with the rendering of the services relating to the Dashboard and/or CleanMachine;</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>5.1.5</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>the right to restrict (i.e. prevent) the processing of your Personal Data, as far as such does not interfere with the rendering of the services relating to the Dashboard and/or CleanMachine;</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>5.1.6</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>the right to data portability (obtaining a copy of your Personal Data to re-use with another service or organisation);</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>5.1.7</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>the right to object to us using your Personal Data for particular purposes; and</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>5.1.8</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>rights with respect to automated decision making and profiling.</Typography>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid
                item
                xs
              >
                <Typography>5.2</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>If you have any cause for complaint about our use of your Personal Data, please contact us using the details provided in section 9 and we will do our best to solve the problem for you. If we are unable to help, you also have the right to lodge a complaint with any competent supervisory authority in the country you are resident. </Typography>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>6.</Typography>
        </Grid>

        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>How do we use your Personal Data?</Typography>
          <Grid
            container
            direction='row'
          >
            <Grid item container xs={12}>
              <Grid
                item
                xs
              >
                <Typography>6.1</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>We may use your Personal Data as follows:</Typography>
                <Grid container item xs={12}>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>6.1.1</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>to support and maintain the Dashboard and/or CleanMachine;</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>6.1.2</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>to contact you with updates for the Dashboard and/or CleanMachine;</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>6.1.3</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>to send you newsletters and advertising;</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>6.1.4</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>to reply to your email;</Typography>

                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs
                    >
                      <Typography>6.1.5</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                    >
                      <Typography>to allow access to your Soapy Wisdom&trade; account.</Typography>

                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid
                item
                xs
              >
                <Typography>6.2</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>You have the right to withdraw your consent given to us for using your Personal Data at any time. </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid
                item
                xs
              >
                <Typography>6.3</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>We do not pass on your Personal Data to third parties.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>7.</Typography>
        </Grid>

        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>How and where do we store your Personal Data?</Typography>

          <Grid
            container
            direction='row'
          >
            <Grid item container xs={12}>
              <Grid
                item
                xs
              >
                <Typography>7.1</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>We only keep your Personal Data for as long as we need to in order to use it as described above in section 6, and/or for as long as we have your permission to keep it.</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid
                item
                xs
              >
                <Typography>7.2</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>Your Personal Data will only be stored within the United States. Should we store and process your Personal Data in countries without an adequate data protection level, then we shall implement the necessary measures.</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid
                item
                xs
              >
                <Typography>7.3</Typography>
              </Grid>

              <Grid
                item
                xs={11}
              >
                <Typography>Data security is very important to us, and to protect your Personal Data we have taken suitable measures to safeguard and secure any Personal Data we hold about you.</Typography>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>8.</Typography>
        </Grid>

        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>How can you access your Personal Data?</Typography>
          <Typography>You have the right to ask for a copy of any of your Personal Data held by us. Please contact us for more details at support@soapy.care.</Typography>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>9.</Typography>
        </Grid>

        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>Contacting us</Typography>
          <Typography>If you have any questions about this Policy, please contact us by email at support@soapy.care. Please ensure that your query is clear, particularly if it is a request for information about the Personal Data we hold about you.</Typography>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        direction='row'
      >
        <Grid
          item
          xs
        >
          <Typography style={{ fontWeight: 'bold' }}>10.</Typography>
        </Grid>

        <Grid
          item
          xs={11}
        >
          <Typography style={{ fontWeight: 'bold' }}>Changes to our Policy</Typography>
          <Typography>We may change this Policy from time to time (for example, if the law changes). Any changes will be immediately posted on the websites www.soapy.care and www.soapywisdom.com and you will be deemed to have accepted the terms of the Policy on your first use of the Dashboard and/or CleanMachine following the alterations.</Typography>
        </Grid>
      </Grid>

    </>
  )
}

export default LegalInfo
