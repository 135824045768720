// ##############################
// // // Trends styles
// #############################

const trendsStyle = theme => ({
  tabs: {
    marginBottom: '30px',
    marginTop: '1rem'
  },
  content: {
    display: 'block',
    border: '0.2px solid black',
    padding: '0.2rem',
    borderRadius: '0.4rem',
    borderTopLeftRadius: 'unset',
    color: '#fff'
  },
  exportButtons: {
    marginLeft: 'auto'
  },
  nav: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '.3em',
    '& > a': {
      position: 'relative',
      cursor: 'pointer',
      width: '10rem',
      display: 'inline-block',
      padding: '.7em 2em .5em',
      color: '#fff',
      textDecoration: 'none',
      margin: '0 -.3em'
    },

    '& > a::before': {
      border: '0.2px solid black',
      transformOrigin: 'bottom left',
      content: "''",
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: '-1',
      borderBottom: 'none',
      borderRadius: '0.8em 1em 0 0',
      background: '#00acc1',
      transform: 'scale(1.2, 1.3) perspective(.5em) rotateX(2deg)'
    }

  },
  active: {
    zIndex: '2',
    color: 'black !important',
    '&::before': {
      backgroundColor: 'white !important',

      marginBottom: '-.08em'
    }
  }
})

export default trendsStyle
