import { configureStore } from '@reduxjs/toolkit'
//redux-persist is to restrict the current page transition to login or signup page bcoz user is already logined
//please do read documentation of redux-persist
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducer'

//in browser -> application -> localstorage -> key: persist:root -> user .....check it
const persistConfig = {
    key: "root",
    version: 1,
    storage,
};


const persistedReducer = persistReducer(persistConfig, rootReducer);



export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
            // ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        }),
});

export let persistor = persistStore(store);