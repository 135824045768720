exports.COUNTRY = 'country'
exports.SERIAL_NUMBER = 'serialNumber'
exports.STATE = 'state'
exports.CITY = 'city'
exports.DISTRICT = 'district'
exports.FACILITY = 'facility'
exports.INTERNAL_AREA = 'internalArea'
exports.NAME = 'name'
exports.LATITUDE = 'latitude'
exports.LONGITUDE = 'longitude'
exports.PASSWORD = 'password'
exports.DISTRIBUTOR = 'distributor'
exports.ORGANIZATION = 'organization'
exports.SITE = 'site'
exports.IS_ARCHIVED = 'isArchived'
exports.SOAP_TIME = 'soapTime'
exports.WATER_TIME_DURING_SOAP = 'waterTimeDuringSoap'
exports.ADDITIONAL_WATER_TIME = 'additionalWaterTime'
exports.AFTER_WASH_DELAY = 'afterWashDelay'
exports.LATHER_TIME = 'duringLathering'
exports.WATER_TIME_PER_WATER_CYCLE = 'waterTimePerWaterCycle'
exports.ID = 'id'
exports.REAGENT = 'reagent'
exports.MONITOR_ORIENTATION = 'monitorOrientation'
