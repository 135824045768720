// ##############################
// // // LoginPage view styles
// #############################

import { cardTitle, container, primaryColor } from '../../../../assets/jss/material-dashboard-pro-react.jsx'

const loginPageStyle = theme => ({
  container: {
    ...container,
    zIndex: '4',
    paddingTop: '6rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px'
    }
  },
  cardFooter: {
    display: 'inline-block',
    textAlign: 'center'
  },

  cardTitle: {
    ...cardTitle,
    color: '#FFFFFF'
  },
  textCenter: {
    textAlign: 'center'
  },
  justifyContentCenter: {
    justifyContent: 'center !important'
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: '#FFFFFF'
    },
    marginLeft: '5px',
    marginRight: '5px'
  },
  inputAdornment: {
    marginRight: '18px'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)'
  },
  cardHeader: {
    marginBottom: '20px'
  },
  socialLine: {
    padding: '0.9375rem 0'
  },
  forgotPasswordLinkDiv: {
    padding: '5px 0 15px',
    display: 'flex',
    justifyContent: 'left',
    marginLeft: '1rem'
  },
  forgotPasswordLinkText: {
    cursor: 'pointer',
    color: '#00acc1',
    fontWeight: 500,
    marginRight: '5px'
  },
  welcomeText: {
    color: '#00acc1',
    fontWeight: 500,
    fontSize: '30px'
  },
  byMachineId: {
    cursor: 'pointer',
    color: '#00acc1',
    fontWeight: 500
  },
  forgotPasswordCard: {
    textAlign: 'center'
  },
  forgotPasswordInstruction: {
    fontWeight: 400,
    fontSize: '1rem',
    fontcolor: '#574956',
    marginBottom: '4px'
  },
  forgotPasswordCardFooter: {
    display: 'inline-block',
    textAlign: 'center'
  },
  forgotPasswordCardFooterBackDiv: {
    float: 'left',
    display: 'flex',
    color: '#00acc1',
    fontWeight: 500,
    cursor: 'pointer',
    paddingTop: '10px'
  },
  forgotPasswordCardFooterBackText: {
    marginTop: '7px'
  },
  forgotPasswordCardFooterBackIcon: {
    color: '#00acc1',
    marginTop: '5px'
  },
  resetButtonDiv: {
    width: '40%',
    float: 'right'
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      borderColor: '#D2D2D2 !important',
      borderWidth: '1px !important'
    },
    '&:after': {
      borderColor: primaryColor
    }
  },
  formControl: {
    margin: '0 0 17px 0',
    position: 'relative',
    verticalAlign: 'unset',
    '& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
      color: '#495057'
    }
  },
  labelRoot: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '-4px'
  },
})

export default loginPageStyle
