import { createSlice } from "@reduxjs/toolkit";

const unitConfigurationSlice = createSlice({
    name: "unitConfiguration",
    initialState: {
        unit: null,
        unitMonitorOrientation: "",
        unitCampaignActive: false,
        isFetching: false,
        error: false,
    },
    reducers: {
        unitStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        unitSuccess: (state, action) => {
            state.isFetching = false;

            state.unit = action.payload;
        },
        unitFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
        },

        unitCampaignActiveStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        unitCampaignActiveSuccess: (state, action) => {
            state.isFetching = false;
            if (action.payload === null) {
                state.unitCampaignActive = false;
            } else {

                state.unitCampaignActive = action.payload;
            }
        },
        unitCampaignActiveFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
        },

        unitMonitorOrientationSuccess: (state, action) => {
            state.isFetching = false;
            if (action.payload === null || action.payload === "") {
                state.unitMonitorOrientation = "horizontal";

            } else {

                state.unitMonitorOrientation = action.payload;
            }
        },


    },
});

export const {
    unitStart,
    unitSuccess,
    unitFailure,
    unitCampaignActiveStart,
    unitCampaignActiveSuccess,
    unitCampaignActiveFailure,
    unitMonitorOrientationSuccess,
} = unitConfigurationSlice.actions;


const unitConfigurationReducer = unitConfigurationSlice.reducer; //it should be export default
export default unitConfigurationReducer;