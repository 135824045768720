import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import distributorModalStyle from '../../../assets-soapy/jss/soapy/views/distributorModalStyle.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import EditReportModal from './EditReport'

const ReportModal = ({
  getMid,
  reportEdited,
  report,
  organizations,
  isOpen,
  reportAdded,
  onClose,
  classes,
  sites,
  reportTypes,
  timeZoneNames,
  weekObject,
  t
}) => {
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      dialogPaper={classes.dialogPaper}
      hideActions
      showGoBackButton
      onCancel={onClose}
      title={!report || !Object.keys(report).length ? t('Add Report') : t('Edit Report')}
    >
      <div>
        <EditReportModal
          isClosed={!isOpen}
          report={report}
          reportEdited={reportEdited}
          reportAdded={reportAdded}
          organizations={organizations}
          onClose={onClose}
          isDistributorPage
          sites={sites}
          reportTypes={reportTypes}
          timeZoneNames={timeZoneNames}
          weekObject={weekObject}
        />
      </div>
    </Modal>
  )
}

export default withStyles(distributorModalStyle)(withTranslation()(ReportModal))
