import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * bulk edit units by machineIds
 * @param {string[]} selectedIds
 * @param {object} unit
 * @param {string} unit.type
 * @param {string} unit.dbTimeZone
 * @param {string} unit.internalArea
 * @param {string} unit.waterUse
 * @param {string} unit.waterWaste
 * @param {string} unit.soapUse
 * @param {string} unit.soapWaste
 * @param {string} unit.reagent
 * @param {string} unit.soapTime
 * @param {string} unit.waterTimeDuringSoap
 * @param {string} unit.latherTime
 * @param {string} unit.waterCycle
 * @param {string} unit.waterTimePerWaterCycle
 * @param {string} unit.delayTimePerWaterCycle
 * @param {string} unit.installationDate
 * @param {string} unit.debugMode
 * @param {string} unit.enableVisualError
 * @param {string} unit.soapLoadingTime
 * @param {string} unit.afterWashDelay
 * @param {string} unit.additionalWaterTime
 * @param {string} unit.temperatureSupport
 * @param {string} unit.temperatureOffset
 * @param {string} unit.monitor
 * @param {string} unit.reagentBottleId
 * @param {string} unit.ledProgressBar
 * @param {string} unit.reagentName
 * @param {string} unit.bottleWashCount
 * @param {string} unit.handsRecognition
 * @param {string} unit.recognition
 * @param {string} unit.debugHandsRecognition
 * @param {string} unit.atpMode
 * @param {string} unit.timeBetweenSoapAndWater
 */
export default async (machineIds, machine) => {
  const endpoint = apiEndpoints.machines
  const URL = SERVER_PATH + endpoint
  const requestBody = {
    machineIds,
    machine: machine
  }
  return resolveRequest(axios.put(URL, requestBody, { withCredentials: true }))
}
