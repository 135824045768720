import { Icon, InputAdornment } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { withRouter } from 'react-router-dom'
import registerPageStyle from '../assets/jss/material-dashboard-pro-react/views/registerPageStyle.jsx'
import LegalInfo from '../components-soapy/LegalInfo.jsx'
import LoadingButton from '../components-soapy/LoadingButton'
import Modal from '../components-soapy/Modal.jsx'
import LanguageSelector from '../components-soapy/MultiLanguage/LanguageSelector.js'
import SweetAlert from '../components-soapy/SweetAlert.jsx'
import Card from '../components/Card/Card.jsx'
import CardBody from '../components/Card/CardBody.jsx'
import CardFooter from '../components/Card/CardFooter.jsx'
import CardHeader from '../components/Card/CardHeader.jsx'
import CustomInput from '../components/CustomInput/CustomInput.jsx'
import GridContainer from '../components/Grid/GridContainer.jsx'
import GridItem from '../components/Grid/GridItem.jsx'
import withAuthentication from '../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../containers/withErrorNotification.js'
import register from '../endpoint-requests/dashboard-user/register'
import requestKeywords from '../shared/dashboard-user/request-keywords.js'
import filterEmptyValues from '../shared/dashboard/filterEmptyValues.js'
import useFormFields from '../shared/hooks/useFormFields.js'
import validateFormFields from '../utils/validateFormFields.js'
import { verifyLength } from '../utils/validators'

const PASSWORD = 'password'
const FIRST_NAME = 'firstName'
const PHONE_NUMBER = 'phoneNumber'
const POSITION = 'position'
const LAST_NAME = 'lastName'
const CONFIRM_PASSWORD = 'confirmPassword'

const VALIDATION_STATE = 'ValidationState'

const phoneInputComponent = (() => {
  return React.forwardRef(function (props, r) {
    return <CustomInput
      labelText="Phone number"
      inputProps={{
        ...props,
        inputRef: r
      }}
      formControlProps={{
        fullWidth: true
      }}
    />
  })
})()

let spinnerTimeout = null
const Register = ({ match, classes, history, t, i18n, showErrorNotification, languageCodes }) => {
  const [isSubmitSuccessfulModalActive, setIsSubmitSuccessfulModalActive] = useState(false)
  const [isLegalInfoModalOpen, setIsLegalInfoModalOpen] = useState(false)
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [isConsentOpen, setIsConsentOpen] = useState(true)
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false)

  const { formFields, setFormFields } = useFormFields({
    [FIRST_NAME]: {
      value: '',
      isRequired: true,
      name: 'First name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 100),
          message: 'First name should not be empty, and up to 100 characters'
        }
      ]
    },
    [LAST_NAME]: {
      value: '',
      isRequired: true,
      name: 'Last name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 100),
          message: 'First name should not be empty, and up to 100 characters'
        }
      ]
    },
    [PHONE_NUMBER]: {
      value: '',
      isRequired: false,
      name: 'Phone number',
      validators: [
        {
          isValid: (value) => isValidPhoneNumber(value),
          message: 'Phone number is invalid'
        }
      ]
    },
    [PASSWORD]: {
      value: '',
      isRequired: true,
      name: 'Password',
      validators: [
        {
          isValid: (value) => value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/),
          message: { passwordError: true }
        }
      ]
    },
    [CONFIRM_PASSWORD]: {
      value: '',
      isRequired: true,
      name: 'Confirm Password',
      validators: [
        {
          isValid: (value) => value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/),
          message: { passwordError: true }
        }
      ]
    },
    [POSITION]: { value: '', isRequired: false, name: 'Position' }
  })

  const submitForm = async () => {
    const requestObject = {
      [requestKeywords.FIRST_NAME]: formFields[FIRST_NAME].value,
      [requestKeywords.LAST_NAME]: formFields[LAST_NAME].value,
      [requestKeywords.PHONE_NUMBER]: formFields[PHONE_NUMBER].value,
      [requestKeywords.PASSWORD]: formFields[PASSWORD].value,
      [requestKeywords.POLICY_ACCEPTED]: isPolicyAccepted,
      [requestKeywords.POSITION]: formFields[POSITION].value
    }

    activateSpinner()
    try {
      await register(match.params.registrationToken, filterEmptyValues(requestObject))
      setIsSubmitSuccessfulModalActive(true)
    } catch (err) {
      showErrorNotification(err.message)
    }
    disableSpinner()
  }

  const handlePrivacyPolicyApproval = () => {
    setIsPolicyAccepted(true)
    setIsConsentOpen(false)
    setIsLegalInfoModalOpen(false)
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const handleMoreInfo = (e) => {
    e.preventDefault()
    setIsLegalInfoModalOpen(true)
  }

  const hideSuccessfulSubmitModal = () => {
    history.push('/login')
  }

  const arePasswordsMatching = () => {
    if (!formFields[PASSWORD].value || !formFields[CONFIRM_PASSWORD].value) return false
    return formFields[PASSWORD].value === formFields[CONFIRM_PASSWORD].value
  }

  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [])

  const handleTextChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: event.target.value } })
  }
  return (
    <div className={classes.container}>
      <div style={{ position: 'absolute', right: '1rem', top: '1rem' }}>
        <LanguageSelector isDarkBackground languageCodes={languageCodes} i18n={i18n}/>
      </div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <div>
              <div
                style={{
                  borderRadius: '6px',
                  textAlign: 'center',
                  margin: '-20% 0 10% 0'
                }}
              >
                <img
                  style={{ width: '30%', margin: '10%' }}
                  src={require('../assets/img/login-logo.png')}
                  alt="logo"
                />
              </div>
              <Card
                login
                style={{
                  borderRadius: '20px',
                  boxShadow: '0 1px 4px 2px rgba(0, 0, 0, 0.14)'
                }}
              >
                <CardBody>
                  <CustomInput
                    error={formFields[FIRST_NAME + VALIDATION_STATE] === 'error'}
                    labelText={`${t('First name')} *`}
                    id="firstName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: formFields[FIRST_NAME].value,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            description
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: event => handleTextChange(event, FIRST_NAME),
                      type: 'text'
                    }}
                  />
                  <CustomInput
                    error={formFields[LAST_NAME + VALIDATION_STATE] === 'error'}
                    labelText={`${t('Last name')} *`}
                    id="lastName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: formFields[LAST_NAME].value,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            description
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: event => handleTextChange(event, LAST_NAME),
                      type: 'text'
                    }}
                  />
                  <PhoneInput
                    value={formFields[PHONE_NUMBER].value}
                    inputComponent={phoneInputComponent}
                    onChange={(phoneNumber) => {
                      setFormFields({
                        ...formFields,
                        [PHONE_NUMBER]: { ...formFields[PHONE_NUMBER], value: phoneNumber }
                      })
                    }}
                  />
                  <CustomInput
                    error={formFields[POSITION + VALIDATION_STATE] === 'error'}
                    labelText={`${t('Position')}`}
                    id="position"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: formFields[POSITION].value,
                      autoComplete: 'position',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            description
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: event => handleTextChange(event, POSITION),
                      type: 'text'
                    }}
                  />
                  <CustomInput
                    error={formFields[PASSWORD + VALIDATION_STATE] === 'error'}
                    labelText={`${t('Password')} *`}
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: formFields[PASSWORD].value,
                      autoComplete: 'new-password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: event => handleTextChange(event, PASSWORD),
                      type: 'password'
                    }}
                  />
                  <CustomInput
                    error={formFields[PASSWORD].value && formFields[CONFIRM_PASSWORD].value && !arePasswordsMatching()}
                    helpText={formFields[PASSWORD].value && formFields[CONFIRM_PASSWORD].value && !arePasswordsMatching() && 'Passwords do not match'}
                    labelText={`${t('Confirm Password')} *`}
                    id="confirmPassword"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: formFields[CONFIRM_PASSWORD].value,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: event => handleTextChange(event, CONFIRM_PASSWORD),
                      type: 'password'
                    }}
                  />
                  <p style={{
                    textAlign: 'center',
                    fontSize: '13px'
                  }}>
                    {t(
                      `By creating an account, you agree to our `
                    )}
                    <a id="consentContainerLink" href="# "
                       onClick={handleMoreInfo}
                       style={{ color: '#00acc1' }}
                       target="_blank">{t('Privacy Policy')}</a>
                  </p>
                </CardBody>
                <CardFooter
                  className={classes.justifyContentCenter}
                  style={{ display: 'inline-block', textAlign: 'center' }}
                >
                  <LoadingButton
                    color="info"
                    size="sm"
                    onClick={handleSaveBtnClick}
                    disabled={isAwaitingResponse || !arePasswordsMatching()}
                    isLoading={isAwaitingResponse}
                    id="loginButton"
                    block
                    fullWidth
                    round
                    divStyle={{ justifyContent: 'center' }}
                    div2Style={{ width: '50%', marginTop: '-20px' }}
                  >
                    {t('Save')}
                  </LoadingButton>

                  {isSubmitSuccessfulModalActive ? (
                    <SweetAlert
                      success
                      id="changePasswordSuccessAlert"
                      title={<h2 style={{ fontSize: '1rem' }}>{t('Credentials changed successfully')}</h2>}
                      confirmBtnText={t('Ok')}
                      style={{
                        position: 'absolute'
                      }}
                      onConfirm={() => hideSuccessfulSubmitModal()}
                      onCancel={() => hideSuccessfulSubmitModal()}
                    />
                  ) : null}

                </CardFooter>

              </Card>
            </div>
          </form>
        </GridItem>
      </GridContainer>
      <Modal
        isOpen={isLegalInfoModalOpen}
        applyButtonText={t('OK')}
        title={t('Legal Info')}
        onApply={handlePrivacyPolicyApproval}
        hideCancel={true}
        onCancel={() => setIsLegalInfoModalOpen(false)}
      >
        <LegalInfo/>
      </Modal>
    </div>
  )
}

export default withErrorNotification(withRouter(withAuthentication(withStyles(registerPageStyle)(withTranslation()(Register)))))
