import { Typography } from '@material-ui/core'
import React from 'react'

export const passwordError =
  <div style={{ position: 'sticky' }}>
    <Typography variant="body2" component="p">
      Password must contain  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

      At least one number &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

      At least one uppercase &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

      At least one lowercase &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

      At least 8 characters &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

      At least one special sign

    </Typography>
  </div>