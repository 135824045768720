import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import dailyWashesRequestKeywords from '../../shared/data/daily-washes.js'
import { SERVER_PATH } from '../endpoints.js'

const deleteDailyWashes = async (ids) => {
  const URL = SERVER_PATH + dailyWashesRequestKeywords.ENDPOINT_URL + '?' + ids.map(id => `ids=${id}`).join('&')

  return resolveRequest(
    await axios.delete(URL, {
      withCredentials: true
    })
  )
}

export default deleteDailyWashes
