import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const sendReport = async (id) => {
  const URL = SERVER_PATH + apiEndpoints.report + '/send-report/' + id
  return resolveRequest(axios.post(URL, {}, { withCredentials: true }))
}

export default sendReport
