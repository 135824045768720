import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import withStyles from '@material-ui/core/styles/withStyles'
import Check from '@material-ui/icons/Check'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import dashboardUserTableModalStyle from '../assets-soapy/jss/soapy/views/dashboardUserTableModalStyle.jsx'
import tableStyle from '../assets/jss/material-dashboard-pro-react/components/tableStyle.jsx'
import withAuthentication from '../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../containers/withErrorNotification.js'
import {
  EMAIL, FIRST_NAME,
  LAST_LANE,
  NAME, POSITION,
  ROLE
} from '../shared/data/dashboard-users.js'
import addReactTableFilterPlaceholder from '../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../shared/react-table/defaultFilterMethod.js'
import Modal from './Modal'

const DEFAULT_PAGE_SIZE = 10
const INFO_COLOR = '#00acc1'
const ID = 'id'

const all = (obj) => {
  for (const key in obj) {
    if (!obj[key]) {
      return false
    }
  }
  return true
}

const convertToTableData = (selectedDashboardUserIds, dashboardUsers) => {
  const tableData = []
  if (selectedDashboardUserIds) {
    selectedDashboardUserIds.forEach(selectedDashboardUserId => {
      tableData.push(dashboardUsers.find(dashboardUser => dashboardUser.id === selectedDashboardUserId))
    })
  }
  for (const dashboardUser of dashboardUsers) {
    if (selectedDashboardUserIds && selectedDashboardUserIds.indexOf(dashboardUser.id) !== -1) {
      continue
    }
    tableData.push(dashboardUser)
  }

  return tableData
}

const DashboardUsersTableModal = ({
  t,
  classes,
  isLoadingDashboardUsers = false,
  dashboardUsers = [],
  multiSelect = false,
  title = t('Dashboard users'),
  hasPermission,
  showErrorNotification,
  onClose,
  displayedDashboardUserIds,
  onApply,
  currentDashboardUser,
  isOpen
}) => {
  const [selectedDashboardUsers, setSelectedDashboardUsers] = useState({})
  const [selectedDashboardUser, setSelectedDashboardUser] = useState('')
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  // needed to force react-table to rerender
  const [tableData, setTableData] = useState(dashboardUsers)

  const handleApply = async () => {
    setImmediate(() => setIsAwaitingResponse(true))
    if (multiSelect) {
      const selectedDashboardUserIds = []
      for (const dashboardUserId of Object.keys(selectedDashboardUsers)) {
        if (selectedDashboardUsers[dashboardUserId]) {
          selectedDashboardUserIds.push(dashboardUserId)
        }
      }
      try {
        await onApply(selectedDashboardUserIds)
      } catch (err) {
        showErrorNotification(err.message)
      }
    } else {
      try {
        await onApply(selectedDashboardUser)
      } catch (err) {
        showErrorNotification(err.message)
      }
    }
    setIsAwaitingResponse(false)
  }
  useEffect(() => {
    if (multiSelect) {
      const selectedMap = dashboardUsers.reduce((acc, dashboardUser) => {
        acc[dashboardUser.id] = false
        return acc
      }, {})
      for (const dashboardUserId of displayedDashboardUserIds) {
        selectedMap[dashboardUserId] = true
      }
      setSelectedDashboardUsers(selectedMap)
    } else {
      if (displayedDashboardUserIds.length) {
        setSelectedDashboardUser(displayedDashboardUserIds[0])
      }
    }
    setTableData(convertToTableData(displayedDashboardUserIds, dashboardUsers))
  }, [dashboardUsers]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    addReactTableFilterPlaceholder()
  })

  const handleSelectAll = () => {
    const isAllSelected = all(selectedDashboardUsers)
    if (isAllSelected) {
      setSelectedDashboardUsers((selectedDashboardUsers) => {
        for (const dashboardUserId of Object.keys(selectedDashboardUsers)) {
          selectedDashboardUsers[dashboardUserId] = false
        }
        return selectedDashboardUsers
      })
    } else {
      setSelectedDashboardUsers((selectedDashboardUsers) => {
        for (const dashboardUserId of Object.keys(selectedDashboardUsers)) {
          selectedDashboardUsers[dashboardUserId] = true
        }
        return selectedDashboardUsers
      })
    }
  }

  const handleMultiSelectMachine = (dashboardUserId) => {
    setSelectedDashboardUsers({
      ...selectedDashboardUsers,
      [dashboardUserId]: !selectedDashboardUsers[dashboardUserId]
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      dialogPaper={classes.dialogPaper}
      hideActions={!hasPermission({ name: 'site dashboard users', action: 'create' }) || !dashboardUsers.length}
      onApply={handleApply}
      isLoading={isLoadingDashboardUsers || isAwaitingResponse}
      onCancel={onClose}
    >

      {isLoadingDashboardUsers
        ? <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
        : <ReactTable
          data={tableData}
          style={tableStyle}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          columns={[
            {
              id: 'checkbox',
              show: hasPermission({ name: 'site dashboard users', action: 'create' }) && dashboardUsers.length,
              minWidth: 30,
              Header: () => (
                <FormControlLabel
                  style={{ marginLeft: '0rem' }}
                  onClick={(e) => {
                    // to prevent sorting from triggering
                    e.stopPropagation()
                  }}
                  control={
                    <Checkbox
                      id='dashboardUserModalCheckboxHeader'
                      tabIndex={-1}
                      onChange={() => {
                        handleSelectAll()
                        // hacky way to force react table to rerender, because e.stopPropagation() will block rendering
                        setTableData([...dashboardUsers])
                      }}
                      checkedIcon={
                        <Check
                          className={classes.checkedIcon}
                          style={{ color: INFO_COLOR }}
                        />
                      }
                      icon={<Check className={classes.uncheckedIcon} />}
                      checked={all(selectedDashboardUsers)}
                    />
                  }
                />
              ),
              Cell: ({ original = {} }) => (
                <div style={{ textAlign: 'left' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='dashboardUserModalCheckboxCell'
                        tabIndex={-1}
                        onChange={() => handleMultiSelectMachine(original[ID])}
                        checkedIcon={
                          <Check
                            className={classes.checkedIcon}
                            style={{ color: INFO_COLOR }}
                          />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        checked={selectedDashboardUsers[original[ID]]}

                      />
                    }
                  />
                </div>
              ),
              sortable: true,
              accessor: orig => orig[ID],
              sortMethod: (a, b) => {
                // if descending, checked checkboxes has to be first, else they should be last
                const isAChecked = selectedDashboardUsers[a]
                const isBChecked = selectedDashboardUsers[b]
                return isAChecked === isBChecked ? 0 : isAChecked ? -1 : 1
              },
              filterable: false
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left',
                    maxHeight: '60px'
                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('First name')}</span>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${FIRST_NAME}`,
              sortable: false
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left',
                    maxHeight: '60px'
                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Last name')}</span>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${LAST_LANE}`,
              sortable: false
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left',
                    maxHeight: '60px'
                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Email')}</span>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${EMAIL}`,
              sortable: false
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left',
                    maxHeight: '60px'
                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Position')}</span>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${POSITION}`,
              sortable: false
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left',
                    maxHeight: '60px'
                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Role')}</span>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${ROLE}.${NAME}`,
              sortable: false
            }
          ]}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          minRows={0}
          showPaginationBottom={dashboardUsers.length > DEFAULT_PAGE_SIZE}
          className='-striped -highlight'
        />}
    </Modal>
  )
}

export default withErrorNotification(withAuthentication(withStyles(dashboardUserTableModalStyle)(withTranslation()(DashboardUsersTableModal))))
