import React from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import '../assets-soapy/scss/material-dashboard-pro-react.css?v=1.4.0'
import withAuthentication from '../containers/Authentication/withAuthentication.js'
import AnonymousPages from './AnonymousPages.jsx'
import AuthPages from './AuthPages.jsx'
import Dashboard from './Dashboard.jsx'
import '../shared/momentLanguages'

const AuthLayoutRouter = (props) => {
  const { hist, accessLevel } = props
  let layoutComponent
  switch (accessLevel) {
    case 'basic':
      layoutComponent = Dashboard
      break
    case 'auth':
      layoutComponent = AuthPages
      break
    case 'unknown':
      layoutComponent = AnonymousPages
      break
    default:
      layoutComponent = AnonymousPages
  }
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/" component={layoutComponent}/>
      </Switch>
    </Router>
  )
}

export default withAuthentication(AuthLayoutRouter)
