import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getCookie from '../../shared/cookie/get-cookie'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'

const Sites = ({
  setOpenMap,
  sites,
  site,
  openMapModal,
  hasPermission,
  openSiteModal,
  t
}) => {
  const language = getCookie('language')
  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [language])

  return (
    <>
      {sites.length ? (
        <>
          <Button
            color="info"
            id="sitesMapViewButton"
            round
            onClick={() => {
              setOpenMap(true)
              // openMapModal()
            }}
            style={{ marginRight: 5 }}
          >
            {t('Map view')}
          </Button>
        </>
      ) : null}

      {
        hasPermission({ name: 'sites', action: 'create' }) && <Button
          color="info"
          id="addSiteBtn"
          round
          onClick={() => openSiteModal()}
          style={{ marginRight: 5 }}
        >
          {t('Add site')}
        </Button>
      }
    </>
  )
}

export default withAuthentication(withTranslation()(Sites))
