import { Checkbox, FormControl, TextField } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Edit from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import formStyle from '../../../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../components-soapy/LoadingButton.jsx'
import ProceedModal from '../../../components-soapy/ProceedModal.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CardHeader from '../../../components/Card/CardHeader.jsx'
import CardIcon from '../../../components/Card/CardIcon.jsx'
import CustomInput from '../../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import useFormFields from '../../../shared/hooks/useFormFields.js'
import validateFormFields from '../../../utils/validateFormFields.js'
import { isEmail, verifyLength } from '../../../utils/validators'
import { Autocomplete, ToggleButtonGroup } from '@material-ui/lab'
import Check from '@material-ui/icons/Check'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Clear from '@material-ui/icons/Clear'
import { Add } from '@material-ui/icons'
import Button from '../../../components/CustomButtons/Button'
import DateModal from './DateModal'
import { addReport } from '../../../endpoint-requests/reports/addReport'
import { editReport } from '../../../endpoint-requests/reports/editReport'
import ToggleButton from '@material-ui/lab/ToggleButton'

const INFO_COLOR = '#00acc1'
const NAME = 'name'
const COUNTRY = 'country'
const STATE = 'state'
const CITY = 'city'
const DISTRICT = 'district'
const ADDRESS = 'address'
const ZIPCODE = 'zipcode'
const VALIDATION_STATE = 'ValidationState'
const SITE_IDS = 'sideIds'
const ORGANIZATION_ID = 'organizationId'
const REPORT_TYPE_ID = 'reportTypeId'
const ACTIVE = 'active'
const NEW_EMAIL = 'newEmail'
const EMAILS = 'emails'
const TIME_ZONE = 'timeZone'
const HOUR = 'hour'
const WEEK_DAYS = 'weekDays'
const INCLUDE_TOP_WASHERS = 'includeTopWashers'
const INCLUDE_HAND_MOVEMENT = 'includeHandMovement'
const RANGE = 'range'

let spinnerTimeout = null

const EditReport = ({
  classes,
  isClosed,
  showErrorNotification,
  reportEdited,
  isDistributorPage = false,
  reportAdded,
  report,
  sites,
  reportTypes,
  timeZoneNames,
  currentDashboardUser,
  weekObject,
  organizations,
  t
}) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false)

  const ranges = {
    7: '1 Week',
    14: '2 Weeks',
    21: '3 Weeks',
    30: '1 Month',
  }

  const isNeo = currentDashboardUser.entity.name === 'neo'

  const reportEmails = report.emails ? report.emails.map(email => email.email) : [currentDashboardUser.email]

  const [isClosedDateModal, setIsClosedDateModal] = useState(true)

  const { formFields, setFormFields } = useFormFields({
    [NAME]: {
      value: '',
      isRequired: true,
      name: 'Name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 256)
        }
      ]
    },
    [COUNTRY]: { value: '', isRequired: false, name: 'Country' },
    [STATE]: { value: '', isRequired: false, name: 'State' },
    [CITY]: { value: '', isRequired: false, name: 'City' },
    [ZIPCODE]: { value: '', isRequired: false, name: 'Zipcode' },
    [DISTRICT]: { value: '', isRequired: false, name: 'District' },
    [SITE_IDS]: { value: [], isRequired: true, name: 'Site' },
    [ORGANIZATION_ID]: { value: null, isRequired: false, name: 'Organization' },
    [REPORT_TYPE_ID]: { value: null, isRequired: true, name: 'Report Type' },
    [ACTIVE]: { value: false, isRequired: false, name: 'Status' },
    [NEW_EMAIL]: { value: '', isRequired: false, name: 'Email', valid: true },
    [ADDRESS]: { value: '', isRequired: false, name: 'Address' },
    [EMAILS]: { value: reportEmails, isRequired: false, name: 'Address' },
    [TIME_ZONE]: { value: '', isRequired: false, name: 'Time Zone' },
    [HOUR]: { value: '', isRequired: false, name: 'Hour' },
    [WEEK_DAYS]: { value: [], isRequired: false, name: 'Week Days' },
    [INCLUDE_TOP_WASHERS]: { value: false, isRequired: false, name: 'Include Top Washers' },
    [INCLUDE_HAND_MOVEMENT]: { value: false, isRequired: false, name: 'Include Hand Movement' },
    [RANGE]: { value: 7, isRequired: false, name: 'Report Date Range' },
  })
  let allSites = Object.keys(sites).map(id => {
    return {
      id,
      name: sites[id].name,
      organizationId: sites[id].organizationId
    }
  })

  let filteredSites = isNeo ? allSites.filter(s => s.organizationId === formFields[ORGANIZATION_ID]) : allSites

  const [sitesArray, setSitesArray] = useState(filteredSites)

  const submitForm = async (proceed = false) => {
    const reportObject = {
      name: formFields[NAME].value,
      sites: formFields[SITE_IDS].value,
      organization: formFields[ORGANIZATION_ID].value,
      reportTypeId: formFields[REPORT_TYPE_ID].value,
      active: formFields[ACTIVE].value,
      emails: formFields[EMAILS].value,
      timeZone: formFields[TIME_ZONE].value,
      scheduleHour: formFields[HOUR].value,
      scheduleWeekDays: formFields[WEEK_DAYS].value.join(','),
      includeTopWashers: formFields[INCLUDE_TOP_WASHERS].value,
      includeHandMovement: formFields[INCLUDE_HAND_MOVEMENT].value,
      range: formFields[RANGE].value,
    }
    activateSpinner()
    try {
      if (!report.id) {
        const addedReport = await addReport(reportObject)
        reportAdded(addedReport)
      } else {
        const editedReport = await editReport(report.id, reportObject)
        reportEdited(editedReport)
      }
    } catch (err) {
      if (err.errorType === 'CONFLICTED_DISTRIBUTOR') {
        setIsProceedModalOpen(true)
      } else {
        showErrorNotification(err.message)
      }
    }
    disableSpinner()
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }
  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  const closeOpenDateModal = () => {
    setIsClosedDateModal(!isClosedDateModal)
  }

  let weekDaysText = formFields[WEEK_DAYS].value.map(day => weekObject[day]).join(', ')

  useEffect(() => {
    let weekDays = report.scheduleWeekDays ? report.scheduleWeekDays.split(',') : []
    setFormFields(
      (formFields) => ({
        [NAME]: { ...formFields[NAME], value: report[NAME] || '' },
        [COUNTRY]: { ...formFields[COUNTRY], value: report[COUNTRY] || '' },
        [STATE]: { ...formFields[STATE], value: report[STATE] || '' },
        [CITY]: { ...formFields[CITY], value: report[CITY] || '' },
        [ZIPCODE]: { ...formFields[ZIPCODE], value: report[ZIPCODE] || '' },
        [DISTRICT]: { ...formFields[DISTRICT], value: report[DISTRICT] || '' },
        [SITE_IDS]: {
          ...formFields[SITE_IDS],
          value: report.sites ? report.sites.map((site) => site.id) : []
        },
        [ORGANIZATION_ID]: {
          ...formFields[ORGANIZATION_ID],
          value: report.organization ? report.organization.id : ''
        },
        [REPORT_TYPE_ID]: { ...formFields[REPORT_TYPE_ID], value: report[REPORT_TYPE_ID] || '' },
        [EMAILS]: { ...formFields[EMAILS], value: reportEmails || [] },
        [ACTIVE]: { ...formFields[ACTIVE], value: report[ACTIVE] || false },
        [ADDRESS]: { ...formFields[ADDRESS], value: report[ADDRESS] || '' },
        [ZIPCODE]: { ...formFields[ZIPCODE], value: report[ZIPCODE] || '' },
        [NEW_EMAIL]: { ...formFields[NEW_EMAIL], value: report[NEW_EMAIL] || '' },
        [TIME_ZONE]: { ...formFields[TIME_ZONE], value: report[TIME_ZONE] || '' },
        [HOUR]: { ...formFields[HOUR], value: report.scheduleHour || '' },
        [WEEK_DAYS]: { ...formFields[WEEK_DAYS], value: weekDays || [] },
        [INCLUDE_TOP_WASHERS]: { ...formFields[INCLUDE_TOP_WASHERS], value: report[INCLUDE_TOP_WASHERS] || false },
        [INCLUDE_HAND_MOVEMENT]: {
          ...formFields[INCLUDE_HAND_MOVEMENT],
          value: report[INCLUDE_HAND_MOVEMENT] || false
        },
        [RANGE]: {
          ...formFields[RANGE],
          value: report[RANGE] || 7
        },
      }))
  }, [report, isClosed]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [])

  const handleSiteChange = (event, values) => {
    if (values) {
      let ids = values.map(value => value.id)
      ids = ids.filter((v, i, a) => a.indexOf(v) === i)
      setFormFields({ ...formFields, [SITE_IDS]: { ...formFields[SITE_IDS], value: ids } })
    }
  }

  const handleDropdownChange = (event, key) => {
    const { value } = event.target
    setFormFields({ ...formFields, [key]: { ...formFields[key], value } })
  }

  const handleOrganizationChange = (event) => {
    const organizationId = event.target.value
    let allSites = Object.keys(sites).map(id => {
      return {
        id,
        name: sites[id].name,
        organizationId: sites[id].organizationId
      }
    })

    let filteredSites = isNeo ? allSites.filter(s => s.organizationId === organizationId) : allSites

    setSitesArray(filteredSites)
    setFormFields({
        ...formFields,
        [ORGANIZATION_ID]: { ...formFields[ORGANIZATION_ID], value: organizationId }
      },
    )
  }

  const handleCheckboxChange = (event, fieldName, forceSetTrue = false) => {
    const value = !forceSetTrue ? !Boolean(formFields[fieldName].value) : true
    setFormFields({
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value: value }
    })
  }

  const handleTextChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: event.target.value } })
  }

  const handleTimeChange = (e) => {
    setFormFields({ ...formFields, [HOUR]: { ...formFields[HOUR], value: e.target.value } })
  }

  const removeEmail = (event, fieldName) => {
    let newEmails = formFields[EMAILS].value.filter(e => e !== fieldName)
    setFormFields({ ...formFields, [EMAILS]: { ...formFields[EMAILS], value: newEmails } })
  }

  const selectWeekDay = (event, number) => {
    let weekDays = formFields[WEEK_DAYS].value
    if (weekDays.includes(number)) {
      weekDays = weekDays.filter(e => e !== number)
    } else {
      weekDays.push(number)
    }
    setFormFields({ ...formFields, [WEEK_DAYS]: { ...formFields[WEEK_DAYS], value: weekDays } })
  }

  const addEmail = (event, fieldName) => {
    let emails = formFields[EMAILS].value

    if (isEmail(fieldName) && !emails.includes(fieldName)) {
      emails.push(fieldName)
      setFormFields({
        ...formFields,
        [EMAILS]: { ...formFields[EMAILS], value: emails },
        [NEW_EMAIL]: { ...formFields[NEW_EMAIL], valid: true },
      })
    } else {
      setFormFields({
        ...formFields,
        [NEW_EMAIL]: { ...formFields[NEW_EMAIL], valid: false }
      })
    }
  }

  return (
    <GridContainer id="content-pane-layout" justify="center">
      <DateModal
        handleTextChange={handleTextChange}
        handleTimeChange={handleTimeChange}
        handleCheckboxChange={handleCheckboxChange}
        formFields={formFields}
        isOpen={!isClosed & !isClosedDateModal}
        timeZoneNames={timeZoneNames}
        selectWeekDay={selectWeekDay}
        onClose={closeOpenDateModal}
        weekObject={weekObject}
      />
      <ProceedModal
        isOpen={isProceedModalOpen}
        onClose={() => setIsProceedModalOpen(false)}
        msg="Organizations you chose might belong to a different report, do you still want to proceed?"
        onApply={() => {
          setIsProceedModalOpen(false)
          submitForm(true)
        }}
      />
      <GridItem md={9}>
        <Card style={isDistributorPage ? { boxShadow: 'none' } : {}}>
          {!isDistributorPage
            ? <CardHeader>
              <CardIcon color="info">
                <Edit
                  className={classes.cardHeaderIcon}
                  style={{ fontSize: 30 }}
                />
              </CardIcon>
            </CardHeader>
            : null}
          <CardBody>
            <div>
              <form>
                <GridContainer
                  direction="column"
                  justify="center"
                  alignItems="center"
                  id="card-body-section"
                >
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="reportName"
                      labelText={`${t('Name')} *`}
                      inputProps={{
                        value: formFields[NAME].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ width: '40vh' }}>
                    <div onClick={() => closeOpenDateModal()}>
                      <div>
                        {weekDaysText}
                      </div>
                      <div>
                        {formFields[HOUR].value ? `Hour: ${formFields[HOUR].value}` : ''}
                      </div>
                      <div>
                        {formFields[TIME_ZONE].value ? `Time zone: ${formFields[TIME_ZONE].value}` : ''}
                      </div>
                      <Button variant="contained" disableElevation>Delivery Date</Button>
                    </div>
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh', display: 'flex', marginTop: '2%' }}>
                    <ToggleButtonGroup
                      color="primary"
                      value={formFields[ACTIVE].value}
                      exclusive
                      onChange={(event) => handleCheckboxChange(event, ACTIVE)}
                      aria-label="Platform"
                    >
                      <ToggleButton value={true}>Active</ToggleButton>
                      <ToggleButton value={false}>Inactive</ToggleButton>
                    </ToggleButtonGroup>
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh', display: 'flex' }}>
                    <CustomInput
                      id="reportName"
                      labelText={`${t('Add email')}`}
                      inputProps={{
                        value: formFields[NEW_EMAIL].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, NEW_EMAIL)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true,
                        error: !formFields[NEW_EMAIL].valid
                      }}
                    />
                    <Add
                      onClick={(event) => addEmail(event, formFields[NEW_EMAIL].value)}
                      style={{ marginTop: '20px', cursor: 'pointer' }}
                    />
                  </GridItem>
                  {formFields[EMAILS].value ?
                    formFields[EMAILS].value.map(email =>
                      <GridItem style={{ minWidth: '40vh', display: 'flex' }}>
                        <CustomInput
                          id="reportName"
                          labelText={`${t('Email')}`}
                          inputProps={{
                            value: email,
                            type: 'name'
                          }}
                          formControlProps={{
                            fullWidth: true,
                            disabled: true
                          }}
                        />
                        <Clear
                          onClick={(event) => removeEmail(event, email)}
                          style={{ marginTop: '20px', cursor: 'pointer' }}
                        />
                      </GridItem>
                    )
                    : null}
                  {isNeo ? <GridItem style={{ minWidth: '40vh' }}>
                    <FormControl
                      fullWidth
                    >
                      <InputLabel style={{
                        color: '#aaaaaa',
                        fontSize: '14px',
                        fontWeight: 400
                      }}
                      >
                        {t('Organization')}
                      </InputLabel>
                      <Select
                        id="defaultPageSelect"
                        value={formFields[ORGANIZATION_ID].value || ''}
                        onChange={(event) => {
                          handleOrganizationChange(event)
                        }}
                      >
                        {
                          Object.keys(organizations).map(key => {
                            return (
                              <MenuItem
                                value={key}
                                key={organizations[key]}
                              >
                                {organizations[key]}
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </GridItem> : null}
                  <GridItem style={{
                    width: '41vh',
                    margin: '27px 0 17px 0',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={sitesArray}
                        onChange={(event, value) => {
                          handleSiteChange(event, value)
                        }}
                        value={formFields[SITE_IDS].value.map(id => {
                          return {
                            id,
                            name: sites[id].name
                          }
                        })}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => {
                          return (
                            <>
                              <Checkbox
                                checkedIcon={<Check
                                  className={classes.checkedIcon}
                                  style={{ color: INFO_COLOR }}
                                />}
                                icon={<Check
                                  className={classes.uncheckedIcon}
                                />}
                                style={{ marginRight: 8 }}
                                checked={formFields[SITE_IDS].value.indexOf(option.id) > -1}
                              />
                              {option.name}
                            </>
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t('Site')}
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <FormControl
                      fullWidth
                    >
                      <InputLabel style={{
                        color: '#aaaaaa',
                        fontSize: '14px',
                        fontWeight: 400
                      }}
                      >
                        {t('Report date range')}
                      </InputLabel>
                      <Select
                        id="defaultPageSelect"
                        value={formFields[RANGE].value || ''}
                        onChange={(event) => {
                          handleDropdownChange(event, RANGE)
                        }}
                      >
                        {
                          Object.keys(ranges).map(key => {
                            return (
                              <MenuItem
                                value={key}
                                key={ranges[key]}
                              >
                                {ranges[key]}
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <FormControl
                      fullWidth
                    >
                      <InputLabel style={{
                        color: '#aaaaaa',
                        fontSize: '14px',
                        fontWeight: 400
                      }}
                      >
                        {t('Report Type')}
                      </InputLabel>
                      <Select
                        id="defaultPageSelect"
                        value={formFields[REPORT_TYPE_ID].value || ''}
                        onChange={(event) => {
                          handleDropdownChange(event, REPORT_TYPE_ID)
                        }}
                      >
                        {
                          Object.keys(reportTypes).map(key => {
                            return (
                              <MenuItem
                                value={key}
                                key={reportTypes[key]}
                              >
                                {reportTypes[key]}
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh', margin: '16px 0 0px -36px', display: 'flex' }}>
                    <Checkbox
                      id="reportActiveCheckbox"
                      tabIndex={-1}
                      onChange={(event) => handleCheckboxChange(event, INCLUDE_TOP_WASHERS)}

                      checkedIcon={
                        <Check
                          className={classes.checkedIcon}
                          style={{ color: INFO_COLOR }}
                        />
                      }
                      icon={<Check className={classes.uncheckedIcon}/>}
                      checked={formFields[INCLUDE_TOP_WASHERS]?.value ? formFields[INCLUDE_TOP_WASHERS].value : false}
                      style={{ marginLeft: '9px' }}
                    />
                    <div
                      style={{ marginTop: '8px' }}
                    >{t('Include Top Washers')}
                    </div>
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh', margin: '16px 0 0px -36px', display: 'flex' }}>
                    <Checkbox
                      id="reportActiveCheckbox"
                      tabIndex={-1}
                      onChange={(event) => handleCheckboxChange(event, INCLUDE_HAND_MOVEMENT)}
                      checkedIcon={
                        <Check
                          className={classes.checkedIcon}
                          style={{ color: INFO_COLOR }}
                        />
                      }
                      icon={<Check className={classes.uncheckedIcon}/>}
                      checked={formFields[INCLUDE_HAND_MOVEMENT]?.value ? formFields[INCLUDE_HAND_MOVEMENT].value : false}
                      style={{ marginLeft: '9px' }}
                    />
                    <div
                      style={{ marginTop: '8px' }}
                    >{t('Include Hand Movement')}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  justify="center"
                  alignItems="center"
                  id="card-footer-section"
                >
                  <GridItem>
                    <LoadingButton
                      color="info"
                      type="submit"
                      id="reportEditSaveButton"
                      onClick={(e) => {
                        e.preventDefault()
                        handleSaveBtnClick()
                      }}
                      fullWidth
                      disabled={isAwaitingResponse}
                      isLoading={isAwaitingResponse}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </GridItem>
                </GridContainer>
              </form>

            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withTranslation()(EditReport))))
