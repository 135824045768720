import { Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import notificationSettingsModalStyle from '../../../assets-soapy/jss/soapy/views/notificationSettingsModalStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../components-soapy/LoadingButton'
import Modal from '../../../components-soapy/Modal.jsx'
import NotificationSettingsForm from '../../../components-soapy/NotificationSettings/NotificationSettingsForm.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import editNotificationSettings from '../../../endpoint-requests/dashboard-user/editNotificationSettings.js'

const HIGH_TEMPERATURE_EMAIL = 'highTemperatureEmail'
const FAILED_WASH_EMAIL = 'failedWashEmail'
const MALFUNCTIONS_EMAIL = 'malfunctionsEmail'
const CHANGE_SOAP_EMAIL = 'changeSoapEmail'
const HIGH_TEMPERATURE_SMS = 'highTemperatureSms'
const FAILED_WASH_SMS = 'failedWashSms'
const MALFUNCTIONS_SMS = 'malfunctionsSms'
const CHANGE_SOAP_SMS = 'changeSoapSms'

let spinnerTimeout = null
const ResetPasswordModal = ({
  isOpen,
  notificationSettingsChanged,
  classes,
  dashboardUserId,
  notificationSettings,
  showErrorNotification,
  isNotificationSettingsLoading,
  onClose,
  t
}) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [formFields, setFormFields] = useState({
    [HIGH_TEMPERATURE_EMAIL]: false,
    [FAILED_WASH_EMAIL]: false,
    [MALFUNCTIONS_EMAIL]: false,
    [CHANGE_SOAP_EMAIL]: false,
    [HIGH_TEMPERATURE_SMS]: false,
    [FAILED_WASH_SMS]: false,
    [MALFUNCTIONS_SMS]: false,
    [CHANGE_SOAP_SMS]: false
  })

  useEffect(() => {
    setFormFields({
      [HIGH_TEMPERATURE_EMAIL]: notificationSettings[HIGH_TEMPERATURE_EMAIL] || false,
      [FAILED_WASH_EMAIL]: notificationSettings[FAILED_WASH_EMAIL] || false,
      [MALFUNCTIONS_EMAIL]: notificationSettings[MALFUNCTIONS_EMAIL] || false,
      [CHANGE_SOAP_EMAIL]: notificationSettings[CHANGE_SOAP_EMAIL] || false,
      [HIGH_TEMPERATURE_SMS]: notificationSettings[HIGH_TEMPERATURE_SMS] || false,
      [FAILED_WASH_SMS]: notificationSettings[FAILED_WASH_SMS] || false,
      [MALFUNCTIONS_SMS]: notificationSettings[MALFUNCTIONS_SMS] || false,
      [CHANGE_SOAP_SMS]: notificationSettings[CHANGE_SOAP_SMS] || false
    })
  }, [notificationSettings, onClose])

  const handleSaveBtnClick = async () => {
    activateSpinner()
    try {
      await editNotificationSettings(dashboardUserId, formFields)
      notificationSettingsChanged()
    } catch (err) {
      showErrorNotification(err.message)
    }
    disableSpinner()
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      () => setIsAwaitingResponse(true),
      300
    )
  }

  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const handleSwitchChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: event.target.checked })
  }
  return (
    <Modal
      isOpen={isOpen}
      dialogPaper={classes.dialogPaper}
      hideActions
      showGoBackButton
      title={t('Notification settings')}
      onCancel={() => {
        onClose()
      }}
      t={t}
    >
      {
        isNotificationSettingsLoading ? <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
                                        />
          : <GridContainer id='content-pane-layout' justify='center'>
            <GridItem md={9}>
              <Card style={{ boxShadow: 'none' }}>

                <CardBody>
                  <div>
                    <form>
                      <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='center'
                      >
                        <NotificationSettingsForm formFields={formFields} handleSwitchChange={handleSwitchChange} spacing={4} />

                        <Grid item>
                          <LoadingButton
                            color='info'
                            onClick={handleSaveBtnClick}
                            fullWidth
                            id='changePasswordSaveButton'
                            disabled={isAwaitingResponse}
                            isLoading={isAwaitingResponse}
                          >
                            {t('Save')}
                          </LoadingButton>
                        </Grid>
                      </Grid>

                    </form>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
      }

    </Modal>
  )
}

export default withErrorNotification(withStyles(notificationSettingsModalStyle)(withTranslation()(ResetPasswordModal)))
