import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'

import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const fetchMachineSettings = () => {
  const URL = SERVER_PATH + apiEndpoints.machineUpdateSettings
  return resolveRequest(axios.get(URL, { withCredentials: true }))
}

export default fetchMachineSettings
