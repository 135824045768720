import MachinesModal from '../../components-soapy/MachinesModal.jsx'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'
import formatMachineBtnText from '../../shared/machine/formatMachineBtnText.js'
import formatDateBtnText from '../../shared/date-format/formatDateBtnText.js'
import { Tooltip, Typography } from '@material-ui/core'
import Refresh from '@material-ui/icons/Refresh'
import 'chartist-plugin-fill-donut'
import 'jspdf-autotable'
import * as moment from 'moment'
import 'moment-timezone'
import React from 'react'
import DateModal from '../../components-soapy/DateModal/DateModal.jsx'
import { BrowserView, isMobile } from 'react-device-detect'
import ReactTable from 'react-table'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import { withTranslation } from 'react-i18next'
import Button from '../../components/CustomButtons/Button'
import {
  BackOfLeftHand,
  BackOfLeftHandFailed,
  BackOfLeftThumb,
  BackOfLeftThumbFailed,
  BackOfRightHand,
  BackOfRightHandFailed,
  BackOfRightThumb,
  BackOfRightThumbFailed,
  BetweenFingers,
  BetweenFingersFailed,
  FingernailLeft,
  FingernailLeftFailed,
  FingernailRight,
  FingernailRightFailed,
  PalmToPalm,
  PalmToPalmFailed
} from '../../shared/hands-recognition/icons'

const PALM_TO_PALM = 'palmToPalm'
const BACK_OF_LEFT_HAND = 'backOfLeftHand'
const BACK_OF_RIGHT_HAND = 'backOfRightHand'
const BACK_OF_LEFT_THUMB = 'backOfLeftThumb'
const BACK_OF_RIGHT_THUMB = 'backOfRightThumb'
const FINGERNAIL_RIGHT = 'fingernailRight'
const FINGERNAIL_LEFT = 'fingernailLeft'
const BETWEEN_FINGERS = 'betweenFingers'
const TIME = 'createdAt'
const DEFAULT_PAGE_SIZE = 10

const NewPresentational = ({
  t,
  handRecognitionData,
  fromDate,
  toDate,
  openDateModal,
  closeDateModal,
  selectedMachineId,
  closeMachinesModal,
  fetchHandRecognitions,
  machines,
  isMachinesModalOpen,
  openMachinesModal,
  isDateModalOpen
}) => {
  const handleRefreshButtonClick = () => {
    fetchHandRecognitions(fromDate, toDate, selectedMachineId)
  }

  const dateBtnText = formatDateBtnText(fromDate, toDate)
  const machine = machines.find(machine => machine.id === selectedMachineId)
  const machinesBtnText = formatMachineBtnText(machine)
  return (
    <FullPagePaper style={{ marginTop: '4rem' }}>
      <BrowserView>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            color='info'
            round
            id='handRecognitionDateButton'
            onClick={openDateModal}
            style={isMobile ? { fontSize: '10px' } : { marginRight: 5 }}
          >
            {dateBtnText}
          </Button>
          <DateModal
            isOpen={isDateModalOpen}
            closeModal={closeDateModal}
            isDateRange
            displayedDate={fromDate}
            displayedToDate={toDate}
            onApply={(fromDate, toDate) => fetchHandRecognitions(fromDate, toDate, selectedMachineId)}
          />
          <Button
            color='info'
            round
            id='handRecognitionMachineButton'
            onClick={openMachinesModal}
            style={isMobile ? { fontSize: '10px' } : { marginRight: 5 }}
          >
            {machinesBtnText}
          </Button>
          {
            isMachinesModalOpen
              ? <MachinesModal
                  isOpen={isMachinesModalOpen}
                  onClose={closeMachinesModal}
                  displayedMachineIds={selectedMachineId ? [selectedMachineId] : []}
                  machines={machines}
                  onApply={(machineId) => {
                    fetchHandRecognitions(fromDate, toDate, machineId)
                  }}
                />
              : null
          }

          <Button
            justIcon
            round
            color='info'
            id='handRecognitionRefreshButton'
            onClick={handleRefreshButtonClick}
            style={{ marginRight: '5px' }}
          >
            <Tooltip title={t('Refresh the page')}>
              <Refresh />
            </Tooltip>
          </Button>
        </div>
      </BrowserView>
      <BrowserView>
        {!handRecognitionData.length || !selectedMachineId
          ? (
            <h1>{t('No Washes Found')}</h1>
            )
          : (
            <ReactTable
              data={handRecognitionData}
              defaultFilterMethod={defaultFilterMethod}
              columns={[
                {
                  Header: () => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: 0
                      }}
                    >
                      <Typography variant='body2'>{t('Time')}</Typography>
                    </div>
                  ),
                  Cell: ({ original }) => (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        {moment.utc(original[TIME]).local().format('HH:mm')}
                      </span>
                    </div>
                  ),
                  style: { textAlign: 'left' },
                  filterMethod: (filter, rows) => {
                    const id = filter.pivotId || filter.id
                    const cellValue = rows[id]
                    const filterValue = filter.value
                    if (filterValue) {
                      return moment.utc(cellValue).local().format('HH:mm').includes(filterValue)
                    }
                    return false
                  },
                  accessor: TIME,
                  sortable: true,
                  filterable: true,
                  width: 70
                },
                {
                  Header: () => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: 0
                      }}
                    >
                      <Typography variant='body2'>{t('Palm To Palm')}</Typography>
                    </div>
                  ),
                  Cell: ({ original }) => (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        {original[PALM_TO_PALM] ? PalmToPalm : PalmToPalmFailed}
                      </span>
                    </div>
                  ),
                  style: { textAlign: 'left' },
                  accessor: PALM_TO_PALM,
                  sortable: true,
                  width: 100
                },
                {
                  Header: () => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: 0
                      }}
                    >
                      <Typography variant='body2'>{t('Between Fingers')}</Typography>
                    </div>
                  ),
                  Cell: ({ original }) => (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        {original[BETWEEN_FINGERS] ? BetweenFingers : BetweenFingersFailed}
                      </span>
                    </div>
                  ),
                  style: { textAlign: 'left' },
                  accessor: BETWEEN_FINGERS,
                  sortable: true,
                  width: 100
                },
                {
                  Header: () => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: 0
                      }}
                    >
                      <Typography variant='body2'>{t('Back Of Left Hand')}</Typography>
                    </div>
                  ),
                  Cell: ({ original }) => (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        {original[BACK_OF_LEFT_HAND] ? BackOfLeftHand : BackOfLeftHandFailed}
                      </span>
                    </div>
                  ),
                  style: { textAlign: 'left' },
                  accessor: BACK_OF_LEFT_HAND,
                  sortable: true,
                  width: 100
                },
                {
                  Header: () => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: 0
                      }}
                    >
                      <Typography variant='body2'>{t('Back Of Right Hand')}</Typography>
                    </div>
                  ),
                  Cell: ({ original }) => (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        {original[BACK_OF_RIGHT_HAND] ? BackOfRightHand : BackOfRightHandFailed}
                      </span>
                    </div>
                  ),
                  style: { textAlign: 'left' },
                  accessor: BACK_OF_RIGHT_HAND,
                  sortable: true,
                  width: 100
                },
                {
                  Header: () => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: 0
                      }}
                    >
                      <Typography variant='body2'>{t('Back Of Left Thumb')}</Typography>
                    </div>
                  ),
                  Cell: ({ original }) => (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        {original[BACK_OF_LEFT_THUMB] ? BackOfLeftThumb : BackOfLeftThumbFailed}
                      </span>
                    </div>
                  ),
                  style: { textAlign: 'left' },
                  accessor: BACK_OF_LEFT_THUMB,
                  sortable: true,
                  width: 100
                },
                {
                  Header: () => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: 0
                      }}
                    >
                      <Typography variant='body2'>{t('Back Of Right Thumb')}</Typography>
                    </div>
                  ),
                  Cell: ({ original }) => (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        {original[BACK_OF_RIGHT_THUMB] ? BackOfRightThumb : BackOfRightThumbFailed}
                      </span>
                    </div>
                  ),
                  style: { textAlign: 'left' },
                  accessor: BACK_OF_RIGHT_THUMB,
                  sortable: true,
                  width: 100
                },
                {
                  Header: () => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: 0
                      }}
                    >
                      <Typography variant='body2'>{t('Fingernail Left')}</Typography>
                    </div>
                  ),
                  Cell: ({ original }) => (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        {original[FINGERNAIL_LEFT] ? FingernailLeft : FingernailLeftFailed}
                      </span>
                    </div>
                  ),
                  style: { textAlign: 'left' },
                  accessor: FINGERNAIL_LEFT,
                  sortable: true,
                  width: 100
                },
                {
                  Header: () => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: 0
                      }}
                    >
                      <Typography variant='body2'>{t('Fingernail right')}</Typography>
                    </div>
                  ),
                  Cell: ({ original }) => (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        {original[FINGERNAIL_RIGHT] ? FingernailRight : FingernailRightFailed}
                      </span>
                    </div>
                  ),
                  style: { textAlign: 'left' },
                  accessor: FINGERNAIL_RIGHT,
                  sortable: true,
                  width: 100
                }
              ]}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              nextText={t('Next')}
              pageText={t('Page')}
              ofText={t('Of')}
              rowsText={t('Rows')}
              previousText={t('Previous')}
              onPageChange={() => {
                document.getElementById('mainPanel').scrollTop = 0
              }}
              minRows={0}
              showPaginationBottom={
              handRecognitionData.length > DEFAULT_PAGE_SIZE
            }
              className='-striped -highlight'
            />
            )}
      </BrowserView>
    </FullPagePaper>
  )
}

export default withTranslation()(NewPresentational)
