import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints'

export default (registrationToken, requestBody) => {
  const URL = SERVER_PATH + apiEndpoints.register + `/${registrationToken}`
  return resolveRequest(axios.post(URL, requestBody, {
    withCredentials: true
  }))
}
