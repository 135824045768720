import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'
import getCookie from '../../shared/cookie/get-cookie'
import setCookie from '../../shared/cookie/set-cookie.js'

export default async (id) => {
  const endpoint = `${apiEndpoints.machines}/${id}`
  const URL = SERVER_PATH + endpoint
  removeCookies(id)
  return resolveRequest(axios.delete(URL, { withCredentials: true }))
}

function removeCookies (id) {
  const usageMachineIds = getCookie('usageMachineIds').split(',')
  const liveWashesMachineId = getCookie('liveWashesMachineId')
  if (liveWashesMachineId === id) {
    setCookie('liveWashesMachineId', '')
  }
  const index = usageMachineIds.indexOf(id)

  if (index > -1) {
    usageMachineIds.splice(index, 1)
    setCookie('usageMachineIds', usageMachineIds)
  }
}
