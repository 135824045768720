import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#03b2cb',
    },
  },
})
theme.typography.body2 = {
  [theme.breakpoints.up('md')]: {
    fontSize: '0.88rem',
  },
  '@media (max-width:1400px)': {
    fontSize: '0.75rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.61rem',
  },
  '@media (max-width:960px)': {
    fontSize: '0.65rem',
  },
}
theme.typography.body1 = {
  [theme.breakpoints.up('md')]: {
    fontSize: '0.88rem',
  },
  '@media (max-width:1590px)': {
    fontSize: '0.7rem',
  },
  '@media (max-width:1400px)': {
    fontSize: '0.6rem',
  },
  '@media (max-width:1300px)': {
    fontSize: '0.5rem',
  },
}

export default theme
