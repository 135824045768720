import {
  FormControl,
  MenuItem,
  Select,
  withStyles
} from '@material-ui/core'
import React from 'react'
import {
  isMobile
} from 'react-device-detect'
import chineseFlag from '../../assets-soapy/img/flags/china-flag-icon-32.png'
import frenchFlag from '../../assets-soapy/img/flags/france-flag-icon-32.png'
import germanFlag from '../../assets-soapy/img/flags/germany-flag-icon-32.png'
import italianFlag from '../../assets-soapy/img/flags/italy-flag-icon-32.png'
import portugueseFlag from '../../assets-soapy/img/flags/portugal-flag-icon-32.png'
import russianFlag from '../../assets-soapy/img/flags/russia-flag-icon-32.png'
import spanishFlag from '../../assets-soapy/img/flags/spain-flag-icon-32.png'
import britishFlag from '../../assets-soapy/img/flags/united-kingdom-flag-icon-32.png'
import languageSelectorStyle from '../../assets-soapy/jss/soapy/components/languageSelectorStyle'
import GridItem from '../../components/Grid/GridItem.jsx'
import getCookie from '../../shared/cookie/get-cookie'
import setCookie from '../../shared/cookie/set-cookie.js'

const LanguageSelector = ({
  languageCodes,
  i18n,
  classes,
  isDarkBackground
}) => {
  console.log(getCookie('language') || 'en')
  const getFlagImgByLanguageCode = function (langCode) {
    switch (langCode) {
      case 'en':
        return britishFlag
      case 'de':
        return germanFlag
      case 'cn':
        return chineseFlag
      case 'es':
        return spanishFlag
      case 'ru':
        return russianFlag
      case 'it':
        return italianFlag
      case 'fr':
        return frenchFlag
      case 'pt':
        return portugueseFlag
      default:
        return britishFlag
    }
  }
  return (<GridItem
    style={
      !isMobile
        ? {
          width: '10rem',
          color: 'white'
        }
        : {
          position: 'relative',
          color: 'white',
          width: '10rem',
          right: '0%'
    }
    }
    className={
      classes.root
    }
    id='languageSelectorRoot'
  >
    <FormControl id='languageSelectorFormControl' fullWidth>
      <Select
        id='languageSelectorSelect'
        classes={
          isDarkBackground ? {
            icon: classes.selectIcon,
            root: classes.selectIcon
          } : {}
        }
        disableUnderline className={
          classes.select
        }
        inputProps={
          {
            name: 'simpleSelect'
          }
        }
        value={
          getCookie('language') || 'en'
        }
        onChange={
          (event => {
            const code = event.target.value
            setCookie('language', code)
            i18n.changeLanguage(code)
          })
        }
      >
        {
          languageCodes.map(item => {
            return <MenuItem
              id={item}
              value={
                item
              }
              key={
                item
              }
            >
              <div
                className={
                  classes.listItem
                }
                id='languageSelectorListItem'
              >
                <img
                  alt='flag'
                  src={
                    getFlagImgByLanguageCode(item)
                  }
                /> <span className={
                  classes.listItemText
                }
                   > {
                    item.toUpperCase()
                  }
                   </span>
              </div>
                   </MenuItem>
          })
        }
      </Select>
    </FormControl>
          </GridItem>
  )
}

export default withStyles(languageSelectorStyle)(LanguageSelector)
