import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'
import failedSession from '../failedSession'

/**
 * @param {object} requestBody
 * @param {string} requestBody.newPermissionName
 * @param {string} requestBody.permissionName
 * @param {string} requestBody.roleId
 * @param {string} requestBody.newRoleId
 * @param {string} requestBody.actions
 */
const editPolicy = async (requestBody) => {
  try {
    const URL = SERVER_PATH + apiEndpoints.policies

    await axios.put(URL, requestBody, {
      withCredentials: true
    })

    return { didSucceed: true }
  } catch (e) {
    failedSession(e)
    return { didSucceed: false, errorType: 'SERVER_ERROR' }
  }
}

export default editPolicy
