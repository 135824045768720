import { Collapse, Icon, InputAdornment, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import AddAlert from '@material-ui/icons/AddAlert'
import Email from '@material-ui/icons/Email'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import loginPageStyle from '../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'
import LoadingButton from '../components-soapy/LoadingButton'
import Card from '../components/Card/Card.jsx'
import CardBody from '../components/Card/CardBody.jsx'
import CardFooter from '../components/Card/CardFooter.jsx'
import CookieConsentModal from '../components/Consent/CookieConsentModal.jsx'
import CustomInput from '../components/CustomInput/CustomInput.jsx'
import GridContainer from '../components/Grid/GridContainer.jsx'
import GridItem from '../components/Grid/GridItem.jsx'
import Snackbar from '../components/Snackbar/Snackbar.jsx'
import withAuthentication from '../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../containers/withErrorNotification.js'
import loginRequest from '../endpoint-requests/dashboard-user/login'
import { forgotPassword } from '../endpoint-requests/login/forgotPassword'
import getStatus from '../endpoint-requests/status/getStatus.js'
import removeAllCookies from '../shared/cookie/remove-all-cookies.js'
import useFormFields from '../shared/hooks/useFormFields.js'
import validateFormFields from '../utils/validateFormFields.js'
import { isEmail, verifyLength } from '../utils/validators'
import isValidPassword from '../shared/dashboard-user/isValidPassword.js'
import changeDashboardUserPassword from '../endpoint-requests/dashboard-user/changeDashboardUserPassword'
import { getUser } from '../redux/apiCalls'
import { connect, useDispatch, useSelector } from 'react-redux'
import { onClear } from '../redux/actions'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import classNames from 'classnames'
import LanguageSelector from '../components-soapy/MultiLanguage/LanguageSelector'

const EMAIL = 'email'
const PASSWORD = 'password'
const CONFIRM_PASSWORD_1 = 'confirmPassword1'
const CONFIRM_PASSWORD_2 = 'confirmPassword2'
const CODE = 'code'

const VALIDATION_STATE = 'ValidationState'

const Login = ({
  t,
  classes,
  history,
  login,
  i18n,
  languageCodes,
  showErrorNotification,
  onClear
}) => {
  console.log(t, i18n)
  const [showCookieConsent, setShowCookieConsent] = useState(
    !localStorage.getItem('useCookies')
  )
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [cardAnimation, setCardAnimation] = useState('cardHidden')
  const [isLoginDisabled, setIsLoginDisabled] = useState(false)
  const [isLogin, setIsLogin] = useState(true)
  const dispatch = useDispatch()
  const previousUser = useSelector((state) => state.user.user)
  const [
    isForgotPasswordNotificationActive,
    setIsForgotPasswordNotificationActive,
  ] = useState(false)
  const [isConfirmNewPassword, setIsConfirmNewPassword] = useState(false)
  const [requireTwoFA, setRequireTwoFA] = useState(false)
  const [isForgotPassword, setIsForgotPassword] = useState(false)
  const [dashboardUser, setDashboardUser] = useState(null)
  const [isEmailNotFoundText, setIsEmailNotFoundText] = useState(false)

  const underlineClasses = classNames({
    [classes.underline]: true,
  })

  const inputClasses = classNames({
    [classes.input]: true,
  })

  const { formFields, setFormFields } = useFormFields({
    [EMAIL]: {
      name: 'Email',
      isRequired: true,
      value: '',
      validators: [
        {
          isValid: (value) => isEmail(value),
          message: 'Should be in the form of: john@example.com',
        },
      ],
    },
    [PASSWORD]: {
      name: 'Password',
      isRequired: true,
      value: '',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 256),
          message: 'Should not be empty, and up to 256 characters',
        },
      ],
    },
    [CONFIRM_PASSWORD_1]: {
      name: 'Password',
      value: '',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 256),
          message: 'Should not be empty, and up to 256 characters',
        },
      ],
    },
    [CONFIRM_PASSWORD_2]: {
      name: 'Password',
      value: '',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 256),
          message: 'Should not be empty, and up to 256 characters',
        },
      ],
    },
    [CODE]: {
      name: 'Code',
      value: '',
    },
  })

  const handleTextChange = (event, fieldName) => {
    setFormFields({
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value: event.target.value },
      [fieldName + VALIDATION_STATE]: '',
    })
  }

  const handleCodeChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, '')
    setFormFields({
      ...formFields,
      [CODE]: { ...formFields[CODE], value },
      [CODE + VALIDATION_STATE]: '',
    })
  }

  const changeValidationStateToError = (affectedFieldsNames) => {
    const errorStates = {}
    affectedFieldsNames.forEach((fieldName) => {
      errorStates[fieldName + VALIDATION_STATE] = 'error'
    })

    setFormFields({ ...formFields, ...errorStates })
  }

  const startStatusCheckInterval = () => {
    const statusCheckInterval = setInterval(async () => {
      const isAlive = await getStatus()
      if (isAlive) {
        setIsLoginDisabled(false)
        clearInterval(statusCheckInterval)
      }
    }, 30000)
  }

  const handleCookieConsentApproval = () => {
    localStorage.setItem('cookies_enabled', '1')
    setShowCookieConsent(false)
  }

  const handleCookieConsentMoreInfo = (e) => {
    e.preventDefault()
    setShowCookieConsent(true)
  }

  const submitLoginForm = async (isLogin = true) => {
    if (!(localStorage.getItem('cookies_enabled') === '1'))
      return showErrorNotification(
        'You have to accept cookie consent in order to use our service'
      )

    setImmediate(() => setIsAwaitingResponse(true))
    try {
      await getStatus()
    } catch (err) {
      showStatusError()
      setIsAwaitingResponse(false)
      setIsLoginDisabled(true)
      startStatusCheckInterval()
      return
    }
    removeAllCookies()
    try {
      const dashboardUser = await loginRequest({
        email: formFields[EMAIL].value,
        password: formFields[PASSWORD].value,
        twoFACode: isLogin ? null : formFields[CODE].value ? formFields[CODE].value : null
      })
      if (previousUser.id !== dashboardUser.id) {
        onClear()
      }
      await getUser(dispatch, dashboardUser)
      const { hasTempPassword, organizations, requireTwoFA } = dashboardUser
      if (hasTempPassword) {
        setIsLogin(false)
        setIsConfirmNewPassword(true)
        setDashboardUser(dashboardUser)
      } else {
        if (requireTwoFA) {
          setIsLogin(false)
          setRequireTwoFA(true)
          setDashboardUser(dashboardUser)
        } else {
          setRequireTwoFA(false)
          localStorage.setItem('organizations', organizations)
          login(dashboardUser)
          const defaultPage = dashboardUser.defaultPage ? dashboardUser.defaultPage : '/dashboard'
          history.push(defaultPage)
        }
      }
    } catch (err) {
      switch (err.errorType) {
        case 'INVALID_CREDENTIALS':
          showErrorNotification('Invalid credentials, please try again')
          changeValidationStateToError(Object.keys(formFields))
          break
        case 'INVALID_TWO_FA_CODE':
          showErrorNotification(err.message, false)
          break
        case 'ERROR_TYPE_INVALID_IP_ADDRESS':
          showErrorNotification("Invalid ip address, please contact administrator")
          break
        default:
          showErrorNotification(err.message)
      }
    }
    setIsAwaitingResponse(false)
  }

  useEffect(() => {
    setTimeout(function () {
      setCardAnimation('')
    }, 700)
  }, [])

  const showForgotPasswordNotification = () => {
    if (!isForgotPasswordNotificationActive) {
      setIsLogin(true)
      setIsForgotPasswordNotificationActive(true)
      setTimeout(function () {
        setIsForgotPasswordNotificationActive(false)
      }, 5000)
    }
  }

  const submitResetForm = async () => {
    setImmediate(() => setIsAwaitingResponse(true))
    try {
      await forgotPassword(formFields[EMAIL].value)
    } catch (err) {
      setIsEmailNotFoundText(true)
      return false
    }
    return true
  }

  const handleLoginBtnClick = async (isLogin = true) => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      await submitLoginForm(isLogin)
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleResetPasswordBtnClick = async (dashboardUser) => {
    const { confirmPassword1, confirmPassword2 } = formFields
    const confirmPassword1Value = confirmPassword1.value
    const confirmPassword2Value = confirmPassword2.value

    if (confirmPassword1Value !== confirmPassword2Value) {
      return showErrorNotification('Passwords does not match')
    } else if (!isValidPassword(confirmPassword1Value)) {
      return showErrorNotification({ passwordError: true })
    }
    setIsAwaitingResponse(true)
    await changeDashboardUserPassword(dashboardUser.id, confirmPassword1Value)
    setIsAwaitingResponse(false)
    login(dashboardUser)
    history.push('/dashboard')
  }

  const handleResetBtnClick = async () => {
    setIsEmailNotFoundText(false)
    const { isValid, error } = await validateFormFields(
      _.pick(formFields, EMAIL)
    )
    if (isValid) {
      const emailExists = await submitResetForm()
      setIsAwaitingResponse(false)
      if (emailExists) showForgotPasswordNotification()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const showStatusError = () => {
    showErrorNotification(
      <div>
        <Typography style={{ textAlign: 'center', fontSize: '1rem' }}>
          {t('Something is wrong!')}
        </Typography>
        <Typography style={{ fontSize: '0.9rem' }}>
          {t('We are going to fix it up and have things back to normal soon.')}
        </Typography>
      </div>
    )
  }

  async function resendCode () {
    await handleLoginBtnClick(false)
    showErrorNotification('Code was resent to email', true)
  }

  console.log(languageCodes)
  return (
    <div className={classes.container}>
      <div style={{ position: 'absolute', right: '1rem', top: '1rem' }}>
        <LanguageSelector
          isDarkBackground={false}
          languageCodes={languageCodes}
          i18n={i18n}
        />
      </div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Snackbar
            place="tc"
            color="info"
            icon={AddAlert}
            onClose={() => setIsForgotPasswordNotificationActive(false)}
            message={t('Password Was Sent')}
            open={isForgotPasswordNotificationActive}
            closeNotification={() =>
              setIsForgotPasswordNotificationActive(false)
            }
            close
          />
          <form
            style={{ marginTop: '-85%' }}
          >
            <div
              style={{
                borderRadius: '6px',
                textAlign: 'center',
                // margin: '-85% 0 10% 0'
              }}
            >
              <img
                style={{ width: '30%', margin: '10%' }}
                src={require('../assets/img/login-logo.png')}
                alt="logo"
              />
              <p className={classes.welcomeText}>{t('Welcome to Soapy')}</p>
            </div>
            {isLogin ?
              (
                // login
                <div>
                  <Card
                    login
                    className={classes[cardAnimation]}
                    style={{
                      borderRadius: '20px',
                      boxShadow: '0 1px 4px 2px rgba(0, 0, 0, 0.14)'
                    }}
                  >
                    <CardBody>
                      <CustomInput
                        error={formFields[EMAIL + VALIDATION_STATE] === 'error'}
                        labelText={`${t('Email')} *`}
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: formFields[EMAIL].value,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                          ),
                          autoComplete: 'email',
                          onChange: (event) => handleTextChange(event, EMAIL),
                          type: 'email',
                        }}
                      />
                      <CustomInput
                        error={formFields[PASSWORD + VALIDATION_STATE] === 'error'}
                        labelText={`${t('Password')} *`}
                        id="password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: formFields[PASSWORD].value,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: 'password',
                          onChange: (event) => handleTextChange(event, PASSWORD),
                          type: 'password',
                        }}
                      />
                    </CardBody>

                    <CardFooter
                      className={classes.cardFooter}
                    >
                      <LoadingButton
                        color="info"
                        size="sm"
                        onClick={handleLoginBtnClick}
                        disabled={isAwaitingResponse || isLoginDisabled}
                        isLoading={isAwaitingResponse}
                        id="loginButton"
                        divStyle={{ justifyContent: 'center' }}
                        div2Style={{ width: '50%', marginTop: '-20px' }}
                        block
                        round
                      >
                        {t('Login')}
                      </LoadingButton>
                      <div className={classes.forgotPasswordLinkDiv}>
                    <span
                      onClick={() => {
                        setIsLogin(!isLogin)
                        setIsForgotPassword(true)
                      }}
                      href="# "
                      id="forgotPasswordLink"
                      className={classes.forgotPasswordLinkText}
                    >
                      {t('Forgot Password')}
                    </span>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              ) : (
                <div>
                  {isConfirmNewPassword ? (
                    // confirm password
                    <Card
                      style={{
                        borderRadius: '20px',
                        boxShadow: '0 1px 4px 2px rgba(0, 0, 0, 0.14)'
                      }}
                      login className={classes[cardAnimation]}>
                      <CardBody className={classes.forgotPasswordCard}>
                        <p className={classes.forgotPasswordInstruction}>
                          {t('Confirm new password')}
                        </p>
                        <CustomInput
                          error={
                            formFields[PASSWORD + VALIDATION_STATE] === 'error'
                          }
                          labelText={`${t('Password')} *`}
                          id="password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: formFields[CONFIRM_PASSWORD_1].value,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            autoComplete: 'password',
                            onChange: (event) =>
                              handleTextChange(event, CONFIRM_PASSWORD_1),
                            type: 'password',
                          }}
                        />
                        <CustomInput
                          error={
                            formFields[PASSWORD + VALIDATION_STATE] === 'error'
                          }
                          labelText={`${t('Password')} *`}
                          id="password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: formFields[CONFIRM_PASSWORD_2].value,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            autoComplete: 'password',
                            onChange: (event) =>
                              handleTextChange(event, CONFIRM_PASSWORD_2),
                            type: 'password',
                          }}
                        />
                      </CardBody>
                      <CardFooter
                        className={`${classes.justifyContentCenter} ${classes.forgotPasswordCardFooter
                        }`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            onClick={() => setIsLogin(!isLogin)}
                            className={classes.forgotPasswordCardFooterBackDiv}
                            id="backToLogin"
                          >
                            <Icon
                              className={`${classes.inputAdornmentIcon} ${classes.forgotPasswordCardFooterBackIcon
                              }`}
                            >
                              arrow_back_ios
                            </Icon>
                            <p
                              className={classes.forgotPasswordCardFooterBackText}
                            >
                              {t('Back To Login')}
                            </p>
                          </div>
                          <div className={classes.resetButtonDiv}>
                            <LoadingButton
                              color="info"
                              size="sm"
                              onClick={() =>
                                handleResetPasswordBtnClick(dashboardUser)
                              }
                              disabled={isAwaitingResponse}
                              isLoading={isAwaitingResponse}
                              block
                              fullWidth
                              round
                            >
                              {t('Save')}
                            </LoadingButton>
                          </div>
                        </div>
                      </CardFooter>
                    </Card>
                  ) : (
                    <div>
                      {isForgotPassword ?
                        // forgot password
                        <Card login className={classes[cardAnimation]}
                              style={{
                                borderRadius: '20px',
                                boxShadow: '0 1px 4px 2px rgba(0, 0, 0, 0.14)'
                              }}
                        >
                          <CardBody className={classes.forgotPasswordCard}>
                            <p
                              style={{ margin: '15px 0 30px' }}
                              className={classes.forgotPasswordInstruction}>
                              {t(
                                'Enter the email address associated with your account, and we\'ll email you a new password'
                              )}
                            </p>
                            <CustomInput
                              error={formFields[EMAIL + VALIDATION_STATE] === 'error'}
                              labelText={`${t('Email')} *`}
                              id="email"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: formFields[EMAIL].value,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Email className={classes.inputAdornmentIcon}/>
                                  </InputAdornment>
                                ),
                                autoComplete: 'email',
                                onChange: (event) => handleTextChange(event, EMAIL),
                                type: 'email',
                              }}
                            />
                            {isEmailNotFoundText ? (
                              <div style={{ color: 'red' }}>
                                The email address you entered could not be found
                              </div>
                            ) : null}
                          </CardBody>
                          <CardFooter
                            className={`${classes.justifyContentCenter} ${classes.forgotPasswordCardFooter
                            }`}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                onClick={() => {
                                  setIsLogin(!isLogin)
                                  setIsForgotPassword(false)
                                }}
                                className={classes.forgotPasswordCardFooterBackDiv}
                                id="backToLogin"
                              >
                                <Icon
                                  className={`${classes.inputAdornmentIcon} ${classes.forgotPasswordCardFooterBackIcon
                                  }`}
                                >
                                  arrow_back_ios
                                </Icon>
                                <p
                                  className={classes.forgotPasswordCardFooterBackText}
                                >
                                  {t('Back To Login')}
                                </p>
                              </div>
                              <div className={classes.resetButtonDiv}>
                                <LoadingButton
                                  color="info"
                                  size="sm"
                                  onClick={handleResetBtnClick}
                                  disabled={isAwaitingResponse}
                                  isLoading={isAwaitingResponse}
                                  block
                                  fullWidth
                                  round
                                >
                                  {t('Reset')}
                                </LoadingButton>
                              </div>
                            </div>
                          </CardFooter>
                        </Card> :
                        <div>
                          {
                            requireTwoFA ?
                              (
                                // Two-Factor Authentication
                                <Card
                                  login
                                  className={classes[cardAnimation]}
                                  style={{
                                    borderRadius: '20px',
                                    boxShadow: '0 1px 4px 2px rgba(0, 0, 0, 0.14)'
                                  }}
                                >
                                  <CardBody className={classes.forgotPasswordCard}>
                                    <p className={classes.forgotPasswordInstruction}>
                                      {t(
                                        '6 digit code was sent to your email account,\nplease enter the code below and click submit'
                                      )}
                                    </p>
                                    <p style={{ fontWeight: 'bold', margin: 0 }}>
                                      {t(
                                        'Code'
                                      )}
                                    </p>
                                    <FormControl
                                      fullWidth={true}
                                      classes={classes.formControl}
                                    >
                                      <InputLabel
                                        className={classes.labelRoot}
                                      >
                                        {`${t('Code')} *`}
                                      </InputLabel>
                                      <Input
                                        value={formFields[CODE].value}
                                        onChange={(event) => handleCodeChange(event)}
                                        classes={{
                                          input: inputClasses,
                                          underline: underlineClasses
                                        }}
                                      />
                                    </FormControl>
                                    <div style={{ color: 'blue', display: 'flex' }}>
                                      <p style={{ cursor: 'pointer', color: '#00acc1' }} onClick={resendCode}>
                                        resend the code
                                      </p>
                                    </div>
                                  </CardBody>
                                  <CardFooter
                                    className={classes.cardFooter}
                                  >
                                    <LoadingButton
                                      color="info"
                                      size="sm"
                                      onClick={() => handleLoginBtnClick(false)}
                                      disabled={isAwaitingResponse || !Boolean(formFields[CODE].value)}
                                      isLoading={isAwaitingResponse}
                                      divStyle={{ justifyContent: 'center' }}
                                      div2Style={{ width: '50%', marginTop: '-20px' }}
                                      block
                                      round
                                    >
                                      {t('Submit')}
                                    </LoadingButton>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <div
                                        onClick={() => {
                                          setIsLogin(!isLogin)
                                          setFormFields({
                                            ...formFields,
                                            [CODE]: ''
                                          })
                                        }}
                                        className={classes.forgotPasswordCardFooterBackDiv}
                                        id="backToLogin"
                                      >
                                        <Icon
                                          className={`${classes.inputAdornmentIcon} ${classes.forgotPasswordCardFooterBackIcon
                                          }`}
                                        >
                                          arrow_back_ios
                                        </Icon>
                                        <p
                                          className={classes.forgotPasswordCardFooterBackText}
                                        >
                                          {t('Back To Login')}
                                        </p>
                                      </div>
                                    </div>
                                  </CardFooter>
                                </Card>
                              ) : null
                          }
                        </div>
                      }
                    </div>
                  )}
                </div>
              )}
          </form>
        </GridItem>
      </GridContainer>
      {!localStorage.getItem('cookies_enabled') ? (
        <>
          <Collapse in={showCookieConsent} timeout="auto" unmountOnExit>
            <CookieConsentModal
              isOpen
              handleApproval={handleCookieConsentApproval}
              handleMoreInfo={handleCookieConsentMoreInfo}
            />
          </Collapse>
        </>
      ) : null}
    </div>
  )
}

export default connect(null, { onClear })(withAuthentication(
  withStyles(loginPageStyle)(withTranslation()(withErrorNotification(Login))))
)
