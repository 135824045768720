import React from 'react'
import Grid from '@material-ui/core/Grid'

import GridContainer from '../components/Grid/GridContainer.jsx'
import Card from '../components-soapy/Card/Card.jsx'
import CardBody from '../components/Card/CardBody.jsx'

const FullPagePaper = ({ children: body, openViewIcons, setOpenViewIcons, openActionIcons, setOpenActionIcons, }) => {
  const handleViewIconsClose = () => {
    if (openViewIcons === true) {
      setOpenViewIcons(false);
    }
  }

  const handleActionIconsClose = () => {
    if (openActionIcons === true) {
      setOpenActionIcons(false);
    }
  }

  return (
    <>
      <GridContainer id='fullPagePaper' style={{ overflowAnchor: 'none' }} onClick={() => {
        handleViewIconsClose()
        handleActionIconsClose()
      }}>
        <Grid id='fullPagePaperGrid' item xs={12}>
          <Card id='fullPagePaperGridCard'>
            <CardBody id='fullPagePaperCardBody'>{body}</CardBody>
          </Card>
        </Grid>
      </GridContainer>
    </>

  )
}

export default FullPagePaper
