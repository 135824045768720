import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'

export default async (campaignId) => {
  const URL = SERVER_PATH + apiEndpoints.campaigns + `/${campaignId}/unarchive`

  return resolveRequest(
    axios.post(URL, null, {
      withCredentials: true
    })
  )
}
