import resolveRequest from '../../utils/resolveRequest.js'
import filterEmptyValues from '../../shared/dashboard/filterEmptyValues.js'
import axios from 'axios'

import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * @param {object} obj
 * @param {string} obj.name
 * @param {string} obj.country
 * @param {string} obj.state
 * @param {string} obj.city
 * @param {string} obj.district
 * @param {string} obj.address
 * @param {string} obj.zipcode
 * @param {string} obj.distributorId
 * @param {string} obj.contactEmail
 * @param {string} obj.contactName
 * @param {string} obj.website
 * @param {array} obj.ipAddresses
 */
export const addOrganization = async (obj) => {
  const endpoint = `${apiEndpoints.organizations}`
  const URL = SERVER_PATH + endpoint

  return resolveRequest(axios.post(URL, filterEmptyValues(obj), { withCredentials: true }))
}
