import withErrorNotification from '../containers/withErrorNotification.js'
import React, { useState } from 'react'

import Modal from './Modal.jsx'

const ProceedModal = ({
  onClose,
  showErrorNotification,
  onApply,
  isOpen,
  msg
}) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)

  const handleApply = async () => {
    setImmediate(() => setIsAwaitingResponse(true))
    try {
      await onApply()
    } catch (err) {
      showErrorNotification(err.message)
    }
    setIsAwaitingResponse(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onApply={handleApply}
      isLoading={isAwaitingResponse}
      onCancel={onClose}
      msg={msg}
      applyButtonText='YES'
    >
      <div id='proceedModal'>
        <h4> {
          msg
        }
        </h4>
      </div>
    </Modal>
  )
}

export default withErrorNotification(ProceedModal)
