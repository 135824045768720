import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const fetchGroups = async () => {
    const URL = SERVER_PATH + apiEndpoints.groups

    return resolveRequest(axios.get(URL, { withCredentials: true }))
}

export default fetchGroups
