let moment = require('moment')
const twix = require('twix')

//getDateRange
export const getDateRange = (timeSpan) => {
    //-----todate---//
    let nowDate = (new Date()).toISOString();

    //-----yesterday---//
    let yesterday = (new Date(Date.now() - 864e5)).toISOString()

    //----updatedFromDate----//
    let dateArray = timeSpan && timeSpan.split(" ")
    let updatedFromDate = moment(nowDate).subtract(dateArray[0], dateArray[1]);

    updatedFromDate = new Date(updatedFromDate.format()).toISOString()
    //--------replacing updatedFromDate time to 00:00:00.000Z------//
    updatedFromDate = `${updatedFromDate.split("T")[0]}T00:00:00.000Z`
    //-----dateCategory----//
    let itr = moment.twix(updatedFromDate, nowDate).iterate("days");
    let dateCategory = [];
    while (itr.hasNext()) {
        dateCategory.push(itr.next().format("YYYY-MM-DD"))
    }

    return {
        nowDate,
        yesterday,
        updatedFromDate,
        dateCategory
    }
}
