import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'

import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * @param {object} requestObject
 * @param {string} requestObject.password
 * @param {string} requestObject.serialNumber
 */
export default async (requestObject) => {
  const endpoint = apiEndpoints.resetUUID
  const URL = SERVER_PATH + endpoint

  return resolveRequest(axios.post(URL, requestObject, { withCredentials: true }))
}
