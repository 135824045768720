import React from 'react'
import { withTranslation } from 'react-i18next'
import ExportExcel from '../../components-soapy/Excel/ExportExcel.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'

const DailyWashExportTable = ({
  t,
  excelDataset
}) => {
  return (
    <ExportExcel
      fileName={t('Washers')}
      title={t('Washers')}
      dataSet={excelDataset}
    />
  )
}

export default withAuthentication(withTranslation()(DailyWashExportTable))
