import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * @param {object} site
 * @param {string} site.name
 * @param {string} site.organizationId
 * @param {string} site.distributorId
 * @param {string} site.temperatureType
 * @param {string} site.country
 * @param {string} site.state
 * @param {string} site.city
 * @param {string} site.district
 * @param {string} site.address
 * @param {string} site.zipcode
 * @param {string} site.contactEmail
 * @param {string} site.contactName
 * @param {string} site.website
 * @param {number} site.latitude
 * @param {number} site.longitude
 * @param {string} id
 */
export const editSite = async (site, id) => {
  const endpoint = `${apiEndpoints.sites}/${id}`
  const URL = SERVER_PATH + endpoint
  return resolveRequest(axios.put(URL, site, { withCredentials: true }))
}
