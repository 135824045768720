import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import React from 'react'
import Button from '../../components/CustomButtons/Button'
import { withTranslation } from 'react-i18next'

const DistributorsTableHeader = ({
  openDistributorModal,
  hasPermission,
  t
}) => {
  return (
    hasPermission({ name: 'distributors', action: 'create' }) && <Button
      color='info'
      round
      id='addDistributorBtn'
      onClick={() => openDistributorModal()}
      style={{ marginRight: 5 }}
                                                                 >
      {t('Add Distributor')}
    </Button>
  )
}

export default withTranslation()(withAuthentication(DistributorsTableHeader))
