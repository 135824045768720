import axios from 'axios'
import dashboardUserRequestKeywords from '../../shared/dashboard-user/request-keywords'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints'

export default async (id, password) => {
  const endpoint = `${apiEndpoints.dashboardUsers}/${id}/change-password`
  const URL = SERVER_PATH + endpoint
  const requestBody = {
    [dashboardUserRequestKeywords.PASSWORD]: password
  }
  return resolveRequest(axios.put(URL, requestBody, { withCredentials: true }))
}
