import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        loader: false,
        isFetching: false,
        error: false,
    },
    reducers: {

        loaderSuccess: (state, action) => {
            state.isFetching = false;
            state.loader = action.payload
            state.error = false;


        },


    },
});

export const {
    loaderSuccess
} = loaderSlice.actions;


const loaderReducer = loaderSlice.reducer; //it should be export default
export default loaderReducer;