import { useState } from 'react'

/**
 * @typedef {object} formField
 * @property {string} formField.value - value.
 * @property {boolean | function} formField.isRequired
 * @property {string} formField.name - name of the field, it's used in validation error message - {name} is required!.
 * @property {object[]} [formField.validators]  - validation functions.
 * @property {(value: any)=>boolean} formField.validators[].isValid - function to determine if it's valid or not.
 * @property {string} formField.validators[].message - message to show user, in case of error.
 */

/**
 *
 * @typedef {object} stateFields
 * @property {Object.<string, formField>} stateFields.formFields
 * @property {React.Dispatch<Object.<string, formField>>} stateFields.setFormFields
 */

/**
 * form fields hook.
 * @param {boolean} defaultFormFields - form fields object.
 * @returns {{ formFields: Object.<string, formField>,  setFormFields: React.Dispatch<Object.<string, formField>>}}
 */
const useFormFields = (defaultFormFields) => {
  const [formFields, setFormFields] = useState(defaultFormFields)

  return { formFields, setFormFields }
}

export default useFormFields
