export const apiEndpoints = {
  me: '/me',
  organizationTemperatureConfig: '/organizations/temperature-config',
  resetUUID: '/machines/reset-uuid',
  login: '/auth/login',
  register: '/auth/register',
  logout: '/auth/logout',
  changePassword: '/me/change-password',
  multiLanguage: '/multi-language',
  machineConfigurations: '/machine-configurations',
  support: '/support',
  machineDailyUsage: '/data/daily-machine-usage',
  machineDailyUsageBySites: '/data/daily-machine-usage/get-by-sites',
  dashboardUsers: '/dashboard-users',
  distributors: '/distributors',
  policies: '/policies',
  entities: '/entities',
  permissions: '/permissions',
  roles: '/roles',
  machines: '/machines',
  disconnectionLogs: '/disconnection-logs',
  forgotPassword: '/auth/forgot-password',
  machineTimeZones: '/time-zones',
  machineUpdateSettings: '/machine-update-settings',
  washStatus: '/wash-status',
  status: '/status',
  sites: '/sites',
  contacts: '/contacts',
  unsubscribe: '/notification-settings/unsubscribe',
  products: '/products',
  version: '/version',
  resetCache: '/reset-cache',
  organizations: '/organizations',
  organizationDashboardUsers: '/organization-dashboard-users',
  reagentSuppliers: '/reagent-suppliers',
  reagentBottles: '/reagent-bottles',
  washers: '/washers',
  campaigns: '/campaigns',
  activeCampaignLogs: '/active-campaign-logs',
  clone: '/clone',
  faces: '/faces',
  setTrigger: '/set-trigger',
  getHandsRecognition: '/get-hands-recognition',
  getHandsRecognitionIcons: '/hands-recognition-icon',
  machineWashConfigurations: '/wash-configurations',
  shiftConfiguration: '/shift-configuration',
  getShiftConfiguration: '/shift-configuration/get-shift-data',
  getDateRange: '/shift-configuration/date-range',
  championsResults: '/champion',
  report: '/report',
  reportTypes: '/report/types',
  groups: '/groups',
  addgroup: '/groups/create',
  groupsOfOrganization: '/groups/all'
}

export const SERVER_PATH = process.env.REACT_APP_BACKEND_SERVER_URL
