import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import distributorModalStyle from '../../../assets-soapy/jss/soapy/views/distributorModalStyle.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import EditDateModal from './EditDate'

const DateModal = ({
  isOpen,
  onClose,
  timeZoneNames,
  handleTextChange,
  handleTimeChange,
  formFields,
  selectWeekDay,
  weekObject,
  handleCheckboxChange,
  t
}) => {
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      onCancel={onClose}
      hideActions
    >
      <div>
        <EditDateModal
          handleCheckboxChange={handleCheckboxChange}
          timeZoneNames={timeZoneNames}
          isClosed={!isOpen}
          onClose={onClose}
          handleTextChange={handleTextChange}
          handleTimeChange={handleTimeChange}
          formFields={formFields}
          selectWeekDay={selectWeekDay}
          weekObject={weekObject}
        />
      </div>
    </Modal>
  )
}

export default withStyles(distributorModalStyle)(withTranslation()(DateModal))
