export default (obj) => {
  const result = {}
  for (const key in obj) {
    const value = obj[key]
    if (!value && value !== 0 && value !== false) {
      continue
    }
    if (Array.isArray(value) && !value.length) {
      continue
    }
    result[key] = value
  }
  return result
}
