import fetchOrganizations from '../endpoint-requests/organization/fetchOrganizations'
import getAllSites from '../endpoint-requests/site/fetchSites'
import getSiteConfiguration from '../endpoint-requests/site/getSiteConfiguration'
import fetchDailyMachineUsages from '../endpoint-requests/usage/fetchDailyMachineUsages'
import {
  dateRangeFailure,
  dateRangeStart,
  dateRangeSuccess,
  fromDateFailure,
  fromDateStart,
  fromDateSuccess,
  toDateFailure,
  toDateStart,
  toDateSuccess,
} from './datesReducer'
import { minWashCountStart, minWashCountSuccess } from './minWashCountReducer'
import fetchHandsRecognition from '../endpoint-requests/hands-recognition/fetchHandsRecognition'
import { sortData } from './SortData.js'
import {
  currentOrganizationFailure,
  currentOrganizationIdFailure,
  currentOrganizationIdStart,
  currentOrganizationIdSuccess,
  currentOrganizationStart,
  currentOrganizationSuccess,
  getOrganizationsFailure,
  getOrganizationsStart,
  getOrganizationsSuccess,
} from './organizationsRedux'
import {
  autoRefreshTimeFailure,
  autoRefreshTimeStart,
  autoRefreshTimeSuccess,
  shiftConfigurationDataFailure,
  shiftConfigurationDataStart,
  shiftConfigurationDataSuccess,
} from './shiftsReducer'
import {
  currentSiteFailure,
  currentSiteIdFailure,
  currentSiteIdStart,
  currentSiteIdSuccess,
  currentSitesFailure,
  currentSitesIdsFailure,
  currentSitesIdsStart,
  currentSitesIdsSuccess,
  currentSitesNamesFailure,
  currentSitesNamesStart,
  currentSitesNamesSuccess,
  currentSitesStart,
  currentSitesSuccess,
  currentSiteStart,
  currentSiteSuccess,
  siteCampaignActiveFailure,
  siteCampaignActiveStart,
  siteCampaignActiveSuccess,
  siteMachinesIdsFailure,
  siteMachinesIdsStart,
  siteMachinesIdsSuccess,
  sitesMachinesIdsFailure,
  sitesMachinesIdsStart,
  sitesMachinesIdsSuccess,
  userSitesFailure,
  userSitesStart,
  userSitesSuccess
} from './sitesRedux'
import { userFailure, userStart, userSuccess } from './userReducer'
import { pagesFailure, pagesStart, pagesSuccess } from './pagesReducer'
import {
  unitCampaignActiveFailure,
  unitCampaignActiveStart,
  unitCampaignActiveSuccess
} from './unitConfigurationReducer'
import { fetchDailyWashesMultiMachines } from '../endpoint-requests/daily-wash/fetchDailyWashesMultiMachines'
import { dailyWashesFailure, dailyWashesStart, dailyWashesSuccess, } from './dailyWashesReducer'
import { clientLogoSuccess, importantImageUploadSuccess } from './shiftImagesReducer'
import { fetchShiftConfiguration } from '../endpoint-requests/shift-configuration/getShiftConfiguration'
import { createShiftConfiguration } from '../endpoint-requests/shift-configuration/createShiftConfiguration'
import { getReportVersion } from '../endpoint-requests/reports/getReportVersion'
import { fetchDateRange } from '../endpoint-requests/shift-configuration/getDateRange'
import { getDateRange } from './shift/utils/getDateRange'
import { updateShiftResult } from './shift/utils/updateShiftResult'
import splitDailyMachineUsages from '../pages/Trends/splitDailyMachineUsages'
import Datetime from 'react-datetime'

let moment = require('moment')
const twix = require('twix')

//Get all organizations
export const getOrganizations = async (dispatch) => {
  dispatch(getOrganizationsStart())

  try {
    const organizationData = await fetchOrganizations()
    if (organizationData.length > 0) {
      organizationData.sort(sortData)
      dispatch(getOrganizationsSuccess(organizationData))
      return organizationData
    } else {
      dispatch(getOrganizationsFailure())
    }
  } catch (error) {
    dispatch(getOrganizationsFailure())
  }
}

//Get current organization and organizationId
export const getCurrentOrganization = async (dispatch, orgName, orgId) => {
  if (orgName === '' || orgId === '') {
    dispatch(currentOrganizationFailure())
    dispatch(currentOrganizationIdFailure())
  }
  dispatch(currentOrganizationStart())
  dispatch(currentOrganizationIdStart())
  dispatch(currentOrganizationSuccess(orgName))

  dispatch(currentOrganizationIdSuccess(orgId))
}

//Get all user's sites
export const getSites = async (dispatch, organizationId,) => {
  dispatch(userSitesStart())

  try {
    const sitesData = await getAllSites()
    const filteredSites = sitesData.filter(site => site.organizationId === organizationId)

    if (filteredSites.length > 0) {
      filteredSites.sort(sortData)
      dispatch(userSitesSuccess(filteredSites))

    }
    if (filteredSites.length === 0) {

      dispatch(userSitesSuccess([]))

    }

    return filteredSites
  } catch (error) {
    dispatch(userSitesFailure())
  }
}

//Get current site and siteId
export const getCurrentSite = async (dispatch, siteName, siteId, siteMachinesIds) => {
  if (siteName === '' || siteId === '') {
    dispatch(currentSiteFailure())
    dispatch(currentSiteIdFailure())
    dispatch(siteMachinesIdsFailure())

  }
  dispatch(currentSiteStart())
  dispatch(currentSiteIdStart())
  dispatch(siteMachinesIdsStart())

  dispatch(currentSiteSuccess(siteName))

  dispatch(currentSiteIdSuccess(siteId))
  dispatch(siteMachinesIdsSuccess(siteMachinesIds))

}

//Get current site and siteId
export const getCurrentSites = async (dispatch, currentSitesArray, currentSitesNamesArray, currentSitesIdsArray, currentSitesMachinesIds) => {
  if (currentSitesNamesArray.length === 0 || currentSitesIdsArray.length === 0) {
    dispatch(currentSitesFailure())
    dispatch(currentSitesNamesFailure())
    dispatch(currentSitesIdsFailure())
    dispatch(sitesMachinesIdsFailure())

  }
  dispatch(currentSitesStart())
  dispatch(currentSitesNamesStart())
  dispatch(currentSitesIdsStart())
  dispatch(sitesMachinesIdsStart())

  dispatch(currentSitesSuccess(currentSitesArray))
  dispatch(currentSitesNamesSuccess(currentSitesNamesArray))
  dispatch(currentSitesIdsSuccess(currentSitesIdsArray))
  dispatch(sitesMachinesIdsSuccess(currentSitesMachinesIds))

}

//Get current site campaign active
export const getSiteCampaignActive = async (dispatch, siteId) => {
  if (siteId === '') {
    dispatch(siteCampaignActiveFailure())
  }
  dispatch(siteCampaignActiveStart())
  try {
    const { isCampaignActive } = await getSiteConfiguration(siteId)
    dispatch(siteCampaignActiveSuccess(isCampaignActive))
  } catch (error) {
    dispatch(siteCampaignActiveFailure())

  }

}

//Get unit campaign active
export const getUnitCampaignActive = async (dispatch, siteId) => {
  dispatch(unitCampaignActiveStart())
  if (siteId === '' || siteId === null) {
    dispatch(unitCampaignActiveSuccess(null))

  } else {

    try {
      const { isCampaignActive } = await getSiteConfiguration(siteId)
      dispatch(unitCampaignActiveSuccess(isCampaignActive))
    } catch (error) {
      dispatch(unitCampaignActiveFailure())

    }
  }

}

//Get fromDate and toDate
export const getFromToDate = async (dispatch, dateRange, fromDate, toDate) => {
  if (fromDate === '' || toDate === '') {
    dispatch(fromDateFailure())
    dispatch(toDateFailure())
    dispatch(dateRangeFailure())

  }
  dispatch(fromDateStart())
  dispatch(toDateStart())
  dispatch(dateRangeStart())
  dispatch(dateRangeSuccess(dateRange))
  dispatch(fromDateSuccess(fromDate))
  dispatch(toDateSuccess(toDate))
}

//Get user
export const getUser = async (dispatch, user) => {
  if (!user) {
    dispatch(userFailure())
  }
  dispatch(userStart())
  dispatch(userSuccess(user))
}

//Get pages
export const getPages = async (dispatch, pages) => {
  if (!pages) {
    dispatch(pagesFailure())
  }
  dispatch(pagesStart())
  dispatch(pagesSuccess(pages))
}

//Get minWashCount
export const getMinWashCount = async (dispatch, minWashCount) => {

  if (minWashCount === '') {
    dispatch(minWashCountSuccess(''))
  }
  dispatch(minWashCountStart())

  dispatch(minWashCountSuccess(minWashCount))

}

//get shift configuration images
export const getShiftConfigurationImages = async (dispatch, currentOrganizationId) => {
  dispatch(autoRefreshTimeStart())
  try {
    const { clientLogo, importantUpdateImage, autoRefreshTime } = await fetchShiftConfiguration(currentOrganizationId)
    dispatch(autoRefreshTimeSuccess(autoRefreshTime))
    dispatch(clientLogoSuccess(clientLogo))
    dispatch(importantImageUploadSuccess(importantUpdateImage))
  } catch (error) {
    console.log(error)
    dispatch(autoRefreshTimeFailure())
  }
}

//create and update shiftConfiguration
export const shiftConfiguration = async (dispatch, config, setShiftConfigurationData, setLoading, nowDate, yesterday,) => {
  dispatch(shiftConfigurationDataStart())
  try {
    const shiftConfigurationResponse = await createShiftConfiguration(config)
    if (Object.keys(shiftConfigurationResponse).length > 0) {
      const shiftData = await updateShiftResult(parseInt(moment().hours()), shiftConfigurationResponse.shifts, nowDate, yesterday, shiftConfigurationResponse.currentSitesIds, shiftConfigurationResponse.minimumWashes)
      setShiftConfigurationData({ ...shiftConfigurationResponse, ...shiftData })
      dispatch(shiftConfigurationDataSuccess({ ...shiftConfigurationResponse, ...shiftData }))
      dispatch(autoRefreshTimeSuccess(shiftConfigurationResponse.autoRefreshTime))
    } else {
      setShiftConfigurationData(null)
      dispatch(shiftConfigurationDataSuccess(null))
      dispatch(autoRefreshTimeSuccess(1))
    }
    setLoading(false)
  } catch (error) {
    dispatch(shiftConfigurationDataFailure())
    console.log(error)
  }
}

function getChartData (chartDataByDay) {
  const { data, dates } = chartDataByDay
  const washDates = []
  const washValues = []

  data.forEach((washesByMachine, index) => {
    if (washesByMachine) {
      const washCount = Object.keys(washesByMachine).reduce((previous, machineId) => previous + washesByMachine[machineId].washCount, 0)
      const dateName = dates[index]._i
      washValues.push(washCount)
      washDates.push(dateName)
    }
  })

  return {
    washDates,
    washValues
  }

}

//get shift configuration
export const getShiftConfiguration = async (dispatch, setShiftConfigurationData, setLoading) => {
  try {
    const dateRange = await fetchDateRange()
    if (dateRange) {
      const { nowDate, yesterday, updatedFromDate, dateCategory } = getDateRange(dateRange)
      const shiftResponse = await fetchShiftConfiguration({ nowDate, yesterday, updatedFromDate, dateCategory })
      if (Object.keys(shiftResponse).length > 0) {
        const { data, series, xaxis, champions, dailyMachineUsages, groupsOfOrganization } = shiftResponse
        const fromDate = Datetime.moment(updatedFromDate)
        const toDate = Datetime.moment(nowDate)
        const chartDataByDay = splitDailyMachineUsages(dailyMachineUsages, fromDate, toDate, 'day')
        const { washDates, washValues } = getChartData(chartDataByDay)
        const shiftData = await updateShiftResult(parseInt(moment().hours()), data.shifts, nowDate, yesterday, data.sites, data.minimumWashes)
        setShiftConfigurationData({
          ...data,
          ...shiftData,
          washDates,
          washValues,
          series,
          xaxis,
          champions,
          dailyMachineUsages,
          fromDate,
          toDate,
          groupsOfOrganization,
        })
        dispatch(shiftConfigurationDataSuccess({
          ...data,
          ...shiftData,
          washDates,
          washValues,
          series,
          xaxis,
          champions,
          dailyMachineUsages,
          fromDate,
          toDate,
          groupsOfOrganization,
        }))
        dispatch(autoRefreshTimeSuccess(data.autoRefreshTime))
      } else {
        setShiftConfigurationData(null)
        dispatch(shiftConfigurationDataSuccess(null))
        dispatch(autoRefreshTimeSuccess(1))
      }
    } else {
      setShiftConfigurationData(null)
      dispatch(shiftConfigurationDataSuccess(null))
      dispatch(autoRefreshTimeSuccess(1))
    }
    setLoading(false)
  } catch (error) {
    console.log(error)
  }
}

//get daily washes for multiple machines
export const getDailyWashesMultiMachines = async (dispatch, fromDate, toDate, machineIds, setLoader, setTemperatureThreshold) => {
  dispatch(dailyWashesStart())
  try {
    const { dailyWashes, temperatureThreshold } = await fetchDailyWashesMultiMachines(fromDate, toDate, machineIds)
    if (dailyWashes.error) {
      dispatch(dailyWashesFailure())
      setLoader(false)
      return
    }
    dispatch(dailyWashesSuccess(dailyWashes))
    setTemperatureThreshold(temperatureThreshold)
    setLoader(false)
  } catch (error) {
    dispatch(dailyWashesFailure())
    setLoader(false)
  }
}

//get daily machine usage on trends page
export const getTrendsDailyMachineUsages = async (fromDate, toDate, siteMachinesIds, setDailyMachineUsages, setHandRecognitionData, setLoader) => {

  try {
    const fetchDailyMachineUsagesData = await fetchDailyMachineUsages(`${fromDate}`, `${toDate}`, siteMachinesIds, null, null, true)
    const handSummaryData = await fetchHandsRecognition({
      date: `${fromDate}`,
      fromHour: null,
      machineIds: siteMachinesIds,
      toDate: `${toDate}`,
      toHour: null,
    })

    setDailyMachineUsages(fetchDailyMachineUsagesData)
    setHandRecognitionData(handSummaryData)
    setLoader(false)
  } catch (error) {
    console.log(error)
  }
}

//fetch reportVersion
export const fetchReportVersion = async (setReportVersion) => {
  const { version } = await getReportVersion()
  setReportVersion(version)
}



