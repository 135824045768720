import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { withTranslation } from 'react-i18next'
import fetchDashboardStatistics from '../../endpoint-requests/dashboard/fetchDashboardStatistics.js'
import Dashboard from './Presentational.jsx'
import Datetime from 'react-datetime'

const DAYS_IN_WEEK = 7
const WEEKS_IN_YEAR = 52

const defaultFromDate = Datetime.moment().subtract(7, 'day').set({ hour: 0, minute: 0, second: 0 })
const defaultToDate = Datetime.moment().set({ hour: 23, minute: 59, second: 59 })

// create a pretty way to display remaining time
const transformDaysCountIntoText = (daysCount, t) => {
  const weeksCount = Math.floor(daysCount / DAYS_IN_WEEK)
  const yearsCount = Math.floor(weeksCount / WEEKS_IN_YEAR)
  let yearText = t('Year')
  let weekText = t('Week')
  if (yearsCount > 1) {
    yearText = t('Years')
  }
  if (weeksCount > 1) {
    weekText = t('Weeks')
  }
  if (yearsCount > 0) {
    const weeksRemainder = weeksCount - yearsCount * WEEKS_IN_YEAR
    return `${yearsCount} ${yearText}, ${weeksRemainder} ${weekText}`
  } else {
    return `${weeksCount} ${weekText}`
  }
}

class DashboardContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      waterUsageConsumption: [0, 0],
      soapUsageConsumption: [0, 0],
      totalMachines: 0,
      totalWashes: 0,
      operatingMachineDaysSinceFirstInstallation: 0,
      hasNetworkErrorOccurred: false,
      hasFinishedLoading: false,
      isLoading: false,
    }
  }

  adjustToNewDateRange = async (fromDate, toDate, fromHour, toHour, filterByHours) => {
    this.setState({ hasFinishedLoading: false })
    const response = await fetchDashboardStatistics(fromDate, toDate, fromHour, toHour, filterByHours)
    const {
      waterUsageConsumption,
      soapUsageConsumption,
      totalMachines,
      totalWashes,
      operatingMachineDaysSinceFirstInstallation,
    } = response.data

    this.setState({ operatingMachineDaysSinceFirstInstallation })

    const operatingMachineDaysSinceFirstInstallationText = transformDaysCountIntoText(
      operatingMachineDaysSinceFirstInstallation,
      this.props.t
    )

    this.setState({
      waterUsageConsumption: waterUsageConsumption,
      soapUsageConsumption: soapUsageConsumption,
      totalMachines: totalMachines,
      totalWashes: totalWashes,
      operatingMachineDaysSinceFirstInstallationText: operatingMachineDaysSinceFirstInstallationText,
      hasFinishedLoading: true,
    })

  }

  async componentDidMount () {
    const response = await fetchDashboardStatistics(defaultFromDate, defaultToDate, defaultFromDate, defaultFromDate)
    const foundError = response.error
    if (foundError) {
      this.setState({ hasNetworkErrorOccurred: true })
    } else {
      const {
        waterUsageConsumption,
        soapUsageConsumption,
        totalMachines,
        totalWashes,
        operatingMachineDaysSinceFirstInstallation,
      } = response.data

      this.setState({ operatingMachineDaysSinceFirstInstallation })

      const operatingMachineDaysSinceFirstInstallationText = transformDaysCountIntoText(
        operatingMachineDaysSinceFirstInstallation,
        this.props.t
      )

      this.setState({
        waterUsageConsumption: waterUsageConsumption,
        soapUsageConsumption: soapUsageConsumption,
        totalMachines: totalMachines,
        totalWashes: totalWashes,
        operatingMachineDaysSinceFirstInstallationText: operatingMachineDaysSinceFirstInstallationText,
        hasFinishedLoading: true,
      })
    }
  }

  render () {

    console.log(this.state.totalMachines)
    const {
      waterUsageConsumption,
      soapUsageConsumption,
      totalMachines,
      totalWashes,
      operatingMachineDaysSinceFirstInstallationText,
      hasNetworkErrorOccurred,
      hasFinishedLoading,
    } = this.state
    const { t } = this.props
    return hasNetworkErrorOccurred ? (
      <h1>{t('Session Is Over Error')}</h1>
    ) : !hasFinishedLoading ? (
      <CircularProgress
        color="primary"
        style={{ position: 'absolute', top: '50%', left: '50%' }}
      />
    ) : (
      <Dashboard
        defaultFromDate={defaultFromDate}
        defaultToDate={defaultToDate}
        waterUsageConsumption={waterUsageConsumption}
        soapUsageConsumption={soapUsageConsumption}
        totalMachines={totalMachines}
        totalWashes={totalWashes}
        adjustToNewDateRange={this.adjustToNewDateRange}
        operatingMachineDaysSinceFirstInstallation={
          this.state.operatingMachineDaysSinceFirstInstallation
        }
        operatingMachineDaysSinceFirstInstallationText={
          operatingMachineDaysSinceFirstInstallationText
        }
      />
    )
  }
}

export default withTranslation()(DashboardContainer)
