import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import React from 'react'
import Button from '../../components/CustomButtons/Button'
import { withTranslation } from 'react-i18next'

const PoliciesTableHeader = ({
  openAddPolicyModal,
  hasPermission,
  t
}) => {
  return (

    hasPermission({ name: 'policies', action: 'create' }) && <Button
      color='info'
      round
      id='addPolicyBtn'
      onClick={() => openAddPolicyModal()}
      style={{ marginRight: 5 }}
                                                             >
      {t('Add Policy')}
                                                             </Button>
  )
}

export default withTranslation()(withAuthentication(PoliciesTableHeader))
