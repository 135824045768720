import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import organizationModalStyle from '../../../assets-soapy/jss/soapy/views/organizationModalStyle.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import EditOrganization from './EditOrganization'

const OrganizationModal = ({ getMid, organizations, organization, organizationEdited, distributors, isOpen, organizationAdded, classes, onClose, t }) => {
  const isEdit = organization && Object.keys(organization).length
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      dialogPaper={classes.dialogPaper}
      hideActions
      showGoBackButton
      onCancel={onClose}
      title={isEdit ? t('Edit organization') : t('Add organization')}
    >
      <div>
        <EditOrganization
          isClosed={!isOpen}
          organizationEdited={organizationEdited}
          organization={organization}
          organizations={organizations}
          distributors={distributors}
          organizationAdded={organizationAdded}
          onClose={onClose}
          isEdit={isEdit}
        />
      </div>
    </Modal>
  )
}

export default withStyles(organizationModalStyle)(withTranslation()(OrganizationModal))
