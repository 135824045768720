import SiteMapModal from './modals/SiteMapModal.jsx'
import fetchDistributorDashboardUsers from '../../endpoint-requests/distributor/fetchDistributorDashboardUsers.js'
import getSiteDashboardUsers from '../../endpoint-requests/site/getSiteDashboardUsers.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useMachine } from '@xstate/react'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import DashboardUsersTableModal from '../../components-soapy/DashboardUsersTableModal.jsx'
import ConfirmationModal from '../../components-soapy/ConfirmationModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import MachinesModal from '../../components-soapy/MachinesModal.jsx'
import ProceedModal from '../../components-soapy/ProceedModal'
import Snackbar from '../../components/Snackbar/Snackbar.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import deleteContact from '../../endpoint-requests/contact/deleteContact.js'
import fetchDistributors from '../../endpoint-requests/distributor/fetchDistributors.js'
import fetchMachinesLocations from '../../endpoint-requests/machine/getMachines.js'
import fetchOrganizations from '../../endpoint-requests/organization/fetchOrganizations.js'
import fetchOrganizationCampaigns from '../../endpoint-requests/organization/getOrganizationCampaigns.js'
import addSiteDashboardUsers from '../../endpoint-requests/site/addSiteDashboardUsers.js'
import addSiteUnits from '../../endpoint-requests/site/addSiteUnits.js'
import deleteSite from '../../endpoint-requests/site/deleteSite.js'
import cloneSite from '../../endpoint-requests/site/cloneSite.js'
import fetchSites from '../../endpoint-requests/site/fetchSites'
import getSiteConfiguration from '../../endpoint-requests/site/getSiteConfiguration'
import getSiteContacts from '../../endpoint-requests/site/getSiteContacts.js'
import { getSiteUnits } from '../../endpoint-requests/site/getSiteUnits.js'
import AddContactModal from './modals/AddContactModal/AddContactModal.jsx'
import ConfigurationModal from './modals/ConfigurationModal.jsx'
import ContactsModal from './modals/ContactsModal.jsx'
import MapModal from './modals/MapModal/MapModal.jsx'
import SiteModal from './modals/SiteModal/SiteModal'
import SitesTable from './SitesTable.jsx'
import SitesTableHeader from './SitesTableHeader'
import { useDispatch, useSelector } from 'react-redux'
import { siteMonitorOrientationSuccess } from '../../redux/sitesRedux.js'

function getMachine(user) {
  return Machine({
    id: 'units',
    initial: 'loadingSites',
    context: {
      sites: {},
      machines: [],
      dashboardUsers: [],
      displayedDashboardUserIds: [],
      organizations: {},
      organizationCampaigns: [],
      siteConfiguration: {},
      error: '',
      distributors: {},
      site: {},
      contact: {},
      contacts: [],
      displayedMachineIds: [],
      isLoadingConfigurationModal: false,
      isDeleteModalOpen: false
    },
    states: {
      loadingSites: {
        invoke: {
          src: (context, event) => Promise.all([
            fetchSites(),
            user.entity.name === 'neo' ? fetchDistributors() : [],
            user.entity.name === 'distributor' || user.entity.name === 'neo' ?
              fetchOrganizations({ order: 'asc', orderBy: 'name' }) : []
          ]),
          onDone: {
            target: 'main',
            actions: assign({
              sites: (context, event) => event.data[0],
              distributors: (context, event) => {
                return event.data[1].reduce((acc, distributor) => {
                  acc[distributor.id] = distributor.name
                  return acc
                }, {})
              },
              organizations: (context, event) => {
                return event.data[2].reduce((acc, organization) => {
                  acc[organization.id] = { name: organization.name, distributorId: organization.distributorId }
                  return acc
                }, {})
              }
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message
            })
          }
        }
      },
      loadingContacts: {
        invoke: {
          src: (context, event) => getSiteContacts(context.site.id),
          onDone: {
            target: 'contactsModal',
            actions: assign({
              contacts: (context, event) => event.data
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message
            })
          }
        }
      },
      failedFetchingData: {
        type: 'final'
      },
      deleteModal: {
        on: {
          CLOSE_DELETE_MODAL: 'main',
          SITE_DELETED: {
            target: 'main',
            actions: ['deleteSite']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      cloneSiteModal: {
        on: {
          CLOSE_CLONE_SITE_MODAL: 'main',
          SITE_CLONED: {
            target: 'main',
            actions: ['addSite']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },

      main: {
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        on: {
          OPEN_MAP_MODAL: {
            target: 'mapModal',
            actions: ['setSite', 'hideSuccessNotification']
          },
          OPEN_CLONE_SITE_MODAL: {
            target: 'cloneSiteModal',
            actions: ['setSite', 'hideSuccessNotification']
          },
          OPEN_SITE_MODAL: {
            target: 'siteModal',
            actions: ['setSite', 'hideSuccessNotification']
          },
          OPEN_SITE_MAP_MODAL: {
            target: 'siteMapModal',
            actions: ['setSite', 'hideSuccessNotification']
          },
          OPEN_CONFIGURATION_MODAL: {
            target: 'configurationModal',
            actions: ['setSite', 'hideSuccessNotification']
          },
          OPEN_DASHBOARD_USERS_MODAL: {
            target: 'loadingDashboardUsers',
            actions: ['setSite', 'hideSuccessNotification']
          },
          OPEN_CONTACTS_MODAL: {
            target: 'loadingContacts',
            actions: ['setSite', 'hideSuccessNotification']
          },
          OPEN_DELETE_MODAL: {
            target: 'deleteModal',
            actions: ['setSite', 'hideSuccessNotification']
          },
          OPEN_MACHINES_MODAL: {
            target: 'loadingMachines',
            actions: ['setSite', 'hideSuccessNotification']
          },
          HIDE_SUCCESS_NOTIFICATION: {
            actions: ['hideSuccessNotification']
          }
        }
      },
      mapModal: {
        on: {
          CLOSE_MAP_MODAL: 'main'
        }
      },
      siteModal: {
        on: {
          CLOSE_SITE_MODAL: 'main',
          SITE_ADDED: {
            target: 'main',
            actions: ['addSite']
          },
          SITE_EDITED: {
            target: 'main',
            actions: ['editSite']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      proceedModal: {
        on: {
          SITE_MACHINES_ADDED: {
            target: 'loadingSites'
          },
          CLOSE_PROCEED_MODAL: 'machineModal',
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      addContactModal: {
        on: {
          CLOSE_ADD_CONTACT_MODAL: 'contactsModal',
          CONTACT_ADDED: {
            target: 'contactsModal',
            actions: ['addContact']
          },
          CONTACT_EDITED: {
            target: 'contactsModal',
            actions: ['editContact']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      deleteContactModal: {
        on: {
          CLOSE_DELETE_CONTACT_MODAL: 'contactsModal',
          CONTACT_DELETED: {
            target: 'contactsModal',
            actions: ['deleteContact']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      contactsModal: {
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        on: {
          CLOSE_CONTACTS_MODAL: 'main',
          CONTACTS_CHANGED: 'main',
          OPEN_ADD_CONTACT_MODAL: {
            target: 'addContactModal',
            actions: ['setContact', 'hideSuccessNotification']
          },
          OPEN_DELETE_CONTACT_MODAL: {
            target: 'deleteContactModal',
            actions: ['setContact', 'hideSuccessNotification']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      loadingDashboardUsers: {
        invoke: {
          src: (context) => {
            return Promise.all([
              user.entity.name === 'site' || (user.entity.name === 'distributor' && (user.role.name === 'user' || user.role.name === 'technician')) ? [] : getSiteDashboardUsers(context.site.id, { onlyIds: true }),
              user.entity.name === 'site' || (user.entity.name === 'distributor' && (user.role.name === 'user' || user.role.name === 'technician')) ? getSiteDashboardUsers(context.site.id) : fetchDistributorDashboardUsers(context.site.distributorId, { canAttachToSite: true })
            ])
          },
          onDone: {
            target: 'dashboardUserModal',
            actions: assign({
              displayedDashboardUserIds: (context, event) => event.data[0].map(dashboardUser => dashboardUser.id),
              dashboardUsers: (context, event) => event.data[1]
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message
            })
          }
        }
      },
      dashboardUserModal: {
        on: {
          CLOSE_DASHBOARD_USER_MODAL: 'main',
          DASHBOARD_USERS_ADDED: {
            target: 'loadingSites'
          },
          OPEN_PROCEED_MODAL: 'proceedModal',
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }

      },
      loadingMachines: {
        invoke: {
          src: (context) => user.entity.name === 'organization'
            ? getSiteUnits(context.site.id, {
              onlyIds: false
            })
            : Promise.all([fetchMachinesLocations({ fetchOrphans: true, distributorId: context.site.distributorId }), getSiteUnits(context.site.id, {
              onlyIds: true
            })]),
          onDone: {
            target: 'machineModal',
            actions: assign({
              displayedMachineIds: (context, event) => {
                if (user.entity.name === 'organization') {
                  return []
                } else {
                  return event.data[1].map(machine => machine.id)
                }
              },
              machines: (context, event) => {
                if (user.entity.name === 'organization') {
                  return event.data
                } else {
                  return event.data[0]
                }
              }
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message
            })
          }
        }
      },
      machineModal: {
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        on: {
          CLOSE_MACHINE_MODAL: 'main',
          SITE_MACHINES_ADDED: {
            target: 'loadingSites'
          },
          OPEN_PROCEED_MODAL: {
            target: 'proceedModal',
            actions: ['setDisplayedMachineIds', 'hideSuccessNotification']
          },
          SHOW_MACHINE_LOADING: {
            actions: ['showMachineLoading']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      siteMapModal: {
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        on: {
          CLOSE_SITE_MAP_MODAL: 'main',
          SITE_MAP_UPLOADED: {
            target: 'main',
            actions: ['setSiteMapUrl']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      configurationModal: {
        on: {
          CLOSE_CONFIGURATION_MODAL: 'main',
          SITE_CONFIGURATION_ADDED: 'main',
          SHOW_CONFIGURATION_MODAL_LOADING: {
            actions: ['showConfigurationModalLoading']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        },
        entry: ['showConfigurationModalLoading'],
        invoke: {
          src: (context) => Promise.all([
            getSiteConfiguration(context.site.id),
            user.entity.name === 'neo' ? fetchOrganizationCampaigns(context.site.organization.id) : []
          ]),
          onDone: {
            actions: assign({
              siteConfiguration: (context, event) => event.data[0],
              organizationCampaigns: (context, event) => event.data[1],
              isLoadingConfigurationModal: (context, event) => false
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message,
              isLoadingConfigurationModal: (context, event) => false
            })
          }
        }
      }
    }
  },
    {
      actions: {
        showContactsLoading: assign({
          isLoadingContacts: true
        }),
        showMachineLoading: assign({
          isLoadingMachines: true
        }),
        showConfigurationModalLoading: assign({
          isLoadingConfigurationModal: true
        }),
        showSuccessNotification: assign({
          isSuccessNotificationVisible: true
        }),
        hideSuccessNotification: assign({
          isSuccessNotificationVisible: false
        }),
        editContact: assign({
          contacts: (context, event) => context.contacts.map(contact => contact.id === event.contact.id ? event.contact : contact)
        }),
        addContact: assign({
          contacts: (context, event) => [event.contact, ...context.contacts]
        }),
        setContact: assign({
          contact: (context, event) => event.contact
        }),
        setDisplayedMachineIds: assign({
          displayedMachineIds: (context, event) => event.displayedMachineIds
        }),
        deleteContact: assign({
          contacts: (context, event) => context.contacts.filter(contact => contact.id !== event.contact.id)
        }),
        editSite: assign({
          sites: (context, event) => context.sites.map(site => site.id === event.site.id ? event.site : site)
        }),
        addSite: assign({
          sites: (context, event) => [event.site, ...context.sites]
        }),
        setSite: assign({
          site: (context, event) => event.site
        }),
        setSiteMapUrl: assign({
          sites: (context, event) => context.sites.map(site => site.id === event.site.id ? { ...site, mapUrl: event.siteMapUrl } : site)
        }),
        deleteSite: assign({
          sites: (context, event) => context.sites.filter(site => site.id !== event.site.id)
        }),
        addSiteDashboardUsers: assign({
          sites: (context, event) => context.sites.map(site => site.id === event.site.id ? { ...site, dashboard_users: [...event.dashboardUserIds] } : site)
        })
      },
      guards: {
        isSuccessNotificationVisible: (context, event) => {
          return !!context.isSuccessNotificationVisible
        }
      }
    }
  )
}

const SitesContainer = ({ t, currentDashboardUser, hasPermission }) => {
  const [current, send] = useMachine(getMachine(currentDashboardUser))
  const dispatch = useDispatch()
  const [openMap, setOpenMap] = useState(false)
  const [openActionIcons, setOpenActionIcons] = useState(false)

  switch (current.value) {
    case 'loadingSites':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t('Session Is Over Error')}</div>
    default: {
      const {
        sites,
        machines,
        site,
        isSuccessNotificationVisible,
        organizations,
        dashboardUsers,
        displayedDashboardUserIds,
        organizationCampaigns,
        contacts,
        contact,
        siteConfiguration,
        isLoadingConfigurationModal,
        distributors,
        displayedMachineIds
      } = current.context
      const isSiteModalOpen = current.value === 'siteModal'
      const isLoadingMachines = current.value === 'loadingMachines'
      const isSiteMapModalOpen = current.value === 'siteMapModal'
      const isDeleteModalOpen = current.value === 'deleteModal'
      const isDashboardUsersModalOpen = current.value === 'dashboardUserModal' || current.value === 'loadingDashboardUsers'
      const isConfigurationModalOpen = current.value === 'configurationModal'
      const isProceedModalOpen = current.value === 'proceedModal'
      const isContactsModalOpen = current.value === 'contactsModal' || current.value === 'addContactModal' || current.value === 'deleteContactModal' || current.value === 'loadingContacts'
      const isDeleteContactModalOpen = current.value === 'deleteContactModal'
      const isMapModalOpen = current.value === 'mapModal'
      const isAddContactModalOpen = current.value === 'addContactModal'
      const isLoadingContacts = current.value === 'loadingContacts'
      const isLoadingDashboardUsers = current.value === 'loadingDashboardUsers'
      const isCloneSiteModalOpen = current.value === 'cloneSiteModal'
      const isMachinesModalOpen = current.value === 'machineModal' || current.value === 'proceedModal' || current.value === 'loadingMachines'
      return (
        <FullPagePaper openActionIcons={openActionIcons} setOpenActionIcons={setOpenActionIcons} >
          <Snackbar
            place='tc'
            color='success'
            icon={NotificationsIcon}
            message={t('Saved') + '!'}
            open={isSuccessNotificationVisible}
          />
          <ContactsModal
            isOpen={isContactsModalOpen}
            onClose={() => send('CLOSE_CONTACTS_MODAL')}
            openAddContactModal={() => {
              send({ type: 'OPEN_ADD_CONTACT_MODAL', contact: {} })
            }}
            openUpdateContactModal={(contact) => {
              send({ type: 'OPEN_ADD_CONTACT_MODAL', contact })
            }}
            openDeleteContactModal={(contact) => {
              send({ type: 'OPEN_DELETE_CONTACT_MODAL', contact })
            }}
            isLoadingContacts={isLoadingContacts}
            contacts={contacts}
          />
          {
            isMapModalOpen
              ? <MapModal
                isOpen={isMapModalOpen}
                locations={Object.keys(site).length ? [site] : sites}
                role={currentDashboardUser.role.name}
                onClose={() => send('CLOSE_MAP_MODAL')}
              />
              : null
          }

          <DashboardUsersTableModal
            dashboardUsers={dashboardUsers}
            isOpen={isDashboardUsersModalOpen}
            multiSelect
            isLoadingDashboardUsers={isLoadingDashboardUsers}
            displayedDashboardUserIds={displayedDashboardUserIds}
            onClose={(added) => {
              send('CLOSE_DASHBOARD_USER_MODAL')
            }}
            onApply={async dashboardUserIds => {
              await addSiteDashboardUsers(dashboardUserIds, site.id, false)
              send(['SHOW_SUCCESS_NOTIFICATION', 'DASHBOARD_USERS_ADDED'])
            }}
          />
          <SiteModal
            sites={sites}
            isOpen={isSiteModalOpen}
            distributors={distributors}
            organizations={organizations}
            site={site}
            onClose={() => {
              dispatch(siteMonitorOrientationSuccess(site.monitorOrientation));
              send('CLOSE_SITE_MODAL')
            }
            }
            siteAdded={(site) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'SITE_ADDED', site }])
            }}
            siteEdited={(site) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'SITE_EDITED', site }])
            }}
          />
          <AddContactModal
            isOpen={isAddContactModalOpen}
            contact={contact}
            siteId={site.id}
            onClose={() => send('CLOSE_ADD_CONTACT_MODAL')}
            contactAdded={(contact) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'CONTACT_ADDED', contact }])
            }}
            contactEdited={(contact) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'CONTACT_EDITED', contact }])
            }}
          />
          <ConfirmationModal
            isOpen={isCloneSiteModalOpen}
            onClose={() => {
              send('CLOSE_CLONE_SITE_MODAL')
            }}
            body='You are about to clone a site'
            applyBtnText='Confirm'
            title='Clone'
            onApply={async () => {
              const clonedSite = await cloneSite(site.id)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'SITE_CLONED', site: clonedSite }])
            }}
          />
          <ConfirmationModal
            isOpen={isDeleteModalOpen}
            onClose={() => {
              send('CLOSE_DELETE_MODAL')
            }}
            onApply={async () => {
              await deleteSite(site.id)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'SITE_DELETED', site }])
            }}
          />
          <ConfirmationModal
            isOpen={isDeleteContactModalOpen}
            onApply={async () => {
              await deleteContact(contact.id)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'CONTACT_DELETED', contact }])
            }}
            onClose={() => {
              send('CLOSE_DELETE_CONTACT_MODAL')
            }}
          />
          {
            isMachinesModalOpen
              ? <MachinesModal
                machines={machines}
                isOpen={isMachinesModalOpen}
                showCheckboxes={hasPermission({ name: 'site machines', action: 'create' })}
                showActions={hasPermission({ name: 'site machines', action: 'create' })}
                multiSelect
                isLoadingMachines={isLoadingMachines}
                displayedMachineIds={displayedMachineIds}
                title={t('Site machines')}
                onClose={() => {
                  send('CLOSE_MACHINE_MODAL')
                }}
                onApply={async (machineIds) => {
                  try {
                    await addSiteUnits(machineIds, site.id, false)
                  } catch (err) {
                    if (err.errorType === 'SITE_CONFLICT') {
                      return send({ type: 'OPEN_PROCEED_MODAL', displayedMachineIds: machineIds })
                    }
                    throw new Error(err.message)
                  }
                  send(['SHOW_SUCCESS_NOTIFICATION', 'SITE_MACHINES_ADDED'])
                }}
              />
              : null
          }
          {
            isProceedModalOpen
              ? <ProceedModal
                isOpen={isProceedModalOpen}
                onApply={async () => {
                  await addSiteUnits(displayedMachineIds, site.id, true)
                  send(['SHOW_SUCCESS_NOTIFICATION', 'SITE_MACHINES_ADDED'])
                }}
                onClose={() => send('CLOSE_PROCEED_MODAL')}
                msg='Units you chose might belong to a different site, do you still want to proceed?'
              />
              : null
          }

          <ConfigurationModal
            closeModal={() => send('CLOSE_CONFIGURATION_MODAL')}
            isOpen={isConfigurationModalOpen}
            isLoading={isLoadingConfigurationModal}
            organizationCampaigns={organizationCampaigns}
            siteId={site.id}
            siteConfigurationAdded={() => {
              send(['SHOW_SUCCESS_NOTIFICATION', 'SITE_CONFIGURATION_ADDED'])
            }}
            siteConfiguration={siteConfiguration}
          />
          {
            isSiteMapModalOpen
              ? <SiteMapModal
                isOpen={isSiteMapModalOpen}
                siteId={site.id}
                siteMapUrl={site.mapUrl}
                onClose={() => {
                  send(['CLOSE_SITE_MAP_MODAL'])
                }}
                siteMapUploaded={(url) => {
                  send(['SHOW_SUCCESS_NOTIFICATION', { type: 'SITE_MAP_UPLOADED', siteMapUrl: url, site }])
                }}
              />
              : null
          }

          <SitesTableHeader
            setOpenMap={setOpenMap}
            sites={sites}
            site={site}
            openMapModal={() => send({ type: 'OPEN_MAP_MODAL', site: {} })}
            openSiteModal={() => send({ type: 'OPEN_SITE_MODAL', site: {} })}
          />
          <SitesTable
            openActionIcons={openActionIcons}
            setOpenActionIcons={setOpenActionIcons}
            openMap={openMap}
            setOpenMap={setOpenMap}
            sites={sites}
            siteAdded={(site) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'SITE_ADDED', site }])
            }}
            siteEdited={(site) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'SITE_EDITED', site }])
            }}
            allSites={sites}
            openSiteModal={(site) => {
              send({ type: 'OPEN_SITE_MODAL', site })
            }}
            openCloneModal={(site) => send({ type: 'OPEN_CLONE_SITE_MODAL', site })}
            openContactsModal={(site) => {
              send({ type: 'OPEN_CONTACTS_MODAL', site })
            }}
            openDeleteModal={(site) => {
              send({ type: 'OPEN_DELETE_MODAL', site })
            }}
            openMachinesModal={(site) => {
              send({ type: 'OPEN_MACHINES_MODAL', site })
            }}
            openDashboardUsersModal={(site) => {
              send({ type: 'OPEN_DASHBOARD_USERS_MODAL', site })
            }}
            openSiteMapModal={(site) => {
              send({ type: 'OPEN_SITE_MAP_MODAL', site })
            }}
            openMapModal={(site = {}) => {
              send({ type: 'OPEN_MAP_MODAL', site })
            }}
            openConfigurationModal={(site) => {
              send({ type: 'OPEN_CONFIGURATION_MODAL', site })
            }}
          />
        </FullPagePaper>
      )
    }
  }
}

export default withAuthentication(withTranslation()(SitesContainer))
