import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux';
import { useStylesPopUp } from '../../Shift/shiftStyles';
import { popUpSuccess } from '../../../redux/popUpReducer';
import { campaignMonitorOrientationSuccess } from '../../../redux/campaignReducer';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: "none",
    borderRadius: "3px",
    boxShadow: 24,
    p: 4,
};

export default function CampaignOrientationModal({ setIsActive, setFormFields, formFields }) {

    const open = useSelector((state) => state.popUp.open)
    const campaign = useSelector((state) => state.campaign.campaign)
    const dispatch = useDispatch()
    const classes = useStylesPopUp();

    const handleClose = () => {
        dispatch(popUpSuccess(false))
        setFormFields({
            ...formFields,
            monitorOrientation: campaign.monitorOrientation,
        })
        dispatch(campaignMonitorOrientationSuccess(campaign.monitorOrientation))
    };

    const handleOk = () => {
        dispatch(popUpSuccess(false))
        setFormFields({
            ...formFields,
            monitorOrientation: campaign.monitorOrientation,
        })
        dispatch(campaignMonitorOrientationSuccess(campaign.monitorOrientation))
    }
    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" >
                        Warning Message
                    </Typography>
                    <Close
                        onClick={() => handleClose()}
                        className={classes.myClassName}
                    />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: "10px" }} >
                        This campaign is active and running, orientation change available in nonactive campaigns only?
                    </Typography>
                    <div className="popupButtonDiv" style={{
                        float: "right",
                        display: 'flex', alignItems: 'center', justifyContent: 'end'
                    }}>
                        <p style={{
                            cursor: 'pointer',
                            width: "50px",
                            fontWeight: "300",
                            fontSize: "16px",
                            textAlign: 'right',
                        }}
                            onClick={() => handleOk()} >Ok</p>
                    </div>
                </Box>
            </Modal>
        </div >
    );
}
