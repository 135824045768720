import { MenuItem } from '@material-ui/core'
import React from 'react'

const ActionIconsDropDown = ({ actions, setOpenActionIcons, }) => {
    const handleClose = () => {
        setOpenActionIcons(false)
    }

    let actionIconsDropDownStyles = {
        display: "flex",
        borderRadius: "4px",
        right: "2px",
        width: "300px",
        position: "absolute",
        textAlign: "center",
        backgroundColor: "#fff",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        zIndex: 100

    }
    return (
        <div
            style={actionIconsDropDownStyles}
        >
            {
                actions.map((action, index) => (
                    <>
                        <MenuItem
                            key={index}
                            onClick={handleClose}>
                            <div >
                                {Object.values(action)}

                            </div>
                        </MenuItem>
                    </>
                ))
            }
        </div >
    )
}

export default ActionIconsDropDown