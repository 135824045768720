import { Tooltip, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Remove from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import React, { useEffect, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import appStyle from '../../assets-soapy/jss/soapy/layouts/dashboardStyle'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'
import tableStyle from '../../shared/styles/tableSortIcon.css'

const DEFAULT_PAGE_SIZE = 10
const PERMISSION_NAME = 'permissionName'
const PERMISSION_ACTIONS = 'permissionActions'
const ENTITY_NAME = 'entityName'
const ROLE_NAME = 'roleName'
const ACTIONS = 'actions'

function convertToTableData (
  policies,
  openUpdateWasherModal,
  openDeletePolicyModal,
  hasPermission,
  t
) {
  const policiesByActions = policies.reduce((acc, policy) => {
    const permissionName = policy.permission.name
    const roleId = policy.role.id
    if (acc[permissionName + roleId]) {
      acc[permissionName + roleId][PERMISSION_ACTIONS].push(policy.permission.action)
      // performance impact of this should be researched
      acc[permissionName + roleId][PERMISSION_ACTIONS].sort()
    } else {
      acc[permissionName + roleId] = {
        [PERMISSION_NAME]: policy.permission.name,
        [PERMISSION_ACTIONS]: [policy.permission.action],
        [ENTITY_NAME]: policy.role.entity.name,
        [ROLE_NAME]: policy.role.name,
        [ACTIONS]: (
          <div className='actions-left'>
            {
              hasPermission({ name: 'policies', action: 'edit' })
                ? <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    openUpdateWasherModal({ ...policy, [PERMISSION_ACTIONS]: acc[permissionName + roleId][PERMISSION_ACTIONS] })
                  }}
                  id='editPolicyBtn'
                  className='edit'
                  >
                  <Tooltip title={t('Edit')}>
                    <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                  </Tooltip>
                  </Button>
                : null
            }
            {
              hasPermission({ name: 'policies', action: 'delete' })
                ? <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    openDeletePolicyModal(policy)
                  }}
                  id='deletePolicyBtn'
                >
                  <Tooltip title={t('Delete')}>
                    <Remove style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                  </Tooltip>
                </Button>
                : null
            }

          </div>
        )
      }
    }
    return acc
  }, {})
  return Object.values(policiesByActions)
}

const Presentational = ({
  policies,
  classes,
  hasPermission,
  openAddPolicyModal,
  openDeletePolicyModal,
  t
}) => {
  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [])

  const tableData = useMemo(() => convertToTableData(
    policies,
    openAddPolicyModal,
    openDeletePolicyModal,
    hasPermission,
    t
  ), [policies]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <ReactTable
      data={tableData}
      style={tableStyle}
      filterable
      defaultFilterMethod={defaultFilterMethod}
      columns={[
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Entity Name')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: ENTITY_NAME
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Role Name')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: ROLE_NAME
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Permission Name')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: PERMISSION_NAME
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Allowed Actions')}</Typography>
            </div>
          ),
          Cell: ({
            original
          }) => (
            <div>
              {original[PERMISSION_ACTIONS] ? original[PERMISSION_ACTIONS].join(', ') : ''}
            </div>
          ),
          style: { textAlign: 'left' },
          filterable: false,
          accessor: PERMISSION_ACTIONS
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Actions')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: ACTIONS,
          filterable: false,
          sortable: false
        }

      ]}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      defaultSorted={[
        {
          id: 'country',
          desc: false
        }, {
          id: 'city',
          desc: false
        }
      ]}
      minRows={0}
      nextText={t('Next')}
      pageText={t('Page')}
      onPageChange={() => {
        document.getElementById('mainPanel').scrollTop = 0
      }}
      ofText={t('Of')}
      rowsText={t('Rows')}
      previousText={t('Previous')}
      showPaginationBottom={
        tableData.length > DEFAULT_PAGE_SIZE
      }

      className='-striped -highlight'
    />
  )
}

export default withRouter(withAuthentication(withStyles(appStyle)(withTranslation()(Presentational))))
