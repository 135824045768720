import {
  Button,
  Tooltip
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import GetAppIcon from '@material-ui/icons/GetApp'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import siteMapModalStyle from '../../../assets-soapy/jss/soapy/views/siteMapModalStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../components-soapy/LoadingButton.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import uploadSiteMap from '../../../endpoint-requests/site/uploadSiteMap.js'
import useFormFields from '../../../shared/hooks/useFormFields.js'
import validateFormFields from '../../../utils/validateFormFields.js'
import {
  SITE_MAP_FILE, VALIDATION_STATE
} from '../constants.js'

let spinnerTimeout = null

const SiteMapModal = ({
  classes,
  siteMapUploaded,
  hasPermission,
  siteId,
  siteMapUrl,
  showErrorNotification,
  onClose,
  isOpen,
  t
}) => {
  const { formFields, setFormFields } = useFormFields({
    [SITE_MAP_FILE]: { value: '', isRequired: true, name: 'Site map file' }
  })
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setFormFields({ ...formFields, [SITE_MAP_FILE]: { ...formFields[SITE_MAP_FILE], value: file }, [SITE_MAP_FILE + VALIDATION_STATE]: '' })
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }

  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const submitForm = async () => {
    const formData = new FormData()
    formData.append('file', formFields[SITE_MAP_FILE].value, formFields[SITE_MAP_FILE].value.name)

    activateSpinner()
    try {
      const { url } = await uploadSiteMap(siteId, formData)
      disableSpinner()
      siteMapUploaded(url)
    } catch (err) {
      disableSpinner()
      showErrorNotification(err.message)
    }
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    return () => {
      disableSpinner()
    }
  }, [])
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      hideActions
      showGoBackButton
      dialogPaper={classes.dialogPaper}
      onCancel={() => {
        disableSpinner()
        onClose()
      }}
      title={t('Upload site map')}
    >
      <GridContainer id='content-pane-layout' justify='center'>

        <GridItem md={9}>
          <Card style={{ boxShadow: 'none' }}>
            <CardBody>
              <GridContainer>

                <GridContainer
                  justify='center'
                  alignItems='center'
                  id='card-footer-section'
                >
                  {
                    hasPermission({ name: 'site map', action: 'create' })
                      ? <Button
                        variant='contained'
                        component='label'
                        id='bottlesUploadFile'
                        color='primary'
                        className='uploadBtn'
                      >
                        {formFields[SITE_MAP_FILE].value ? formFields[SITE_MAP_FILE].value.name : t('Site map file')}
                        <input
                          id='myInput'
                          type='file'
                          accept='.pdf'
                          style={{ display: 'none' }}
                          onChange={(event) => handleFileChange(event)}
                        />
                      </Button>
                      : null

                  }

                  {
                    siteMapUrl && hasPermission({ name: 'site map', action: 'read' })
                      ? <Tooltip title={t('Download site map')}>
                        <Link
                          to={{ pathname: siteMapUrl }}
                          target='_blank'
                          color='primary'
                        >
                          <GetAppIcon color='primary' />
                        </Link>
                      </Tooltip>
                      : null
                  }

                </GridContainer>

              </GridContainer>
              <GridContainer
                justify='center'
                alignItems='center'
                id='card-footer-section'
              >
                <GridItem>
                  <LoadingButton
                    color='info'
                    type='submit'
                    id='washerAddSaveButton'
                    onClick={(e) => {
                      e.preventDefault()
                      handleSaveBtnClick()
                    }}
                    fullWidth
                    disabled={isAwaitingResponse}
                    isLoading={isAwaitingResponse}
                  >
                    {t('Upload')}
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Modal>
  )
}

export default withErrorNotification(withAuthentication(withStyles(siteMapModalStyle)(withTranslation()(SiteMapModal))))
