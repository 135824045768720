exports.FIRST_NAME = 'firstName'
exports.LAST_LANE = 'lastName'
exports.EMAIL = 'email'
exports.ORGANIZATIONS = 'organizations'
exports.SITES = 'sites'
exports.ENTITY_NAME = 'entityName'
exports.DISTRIBUTOR_NAME = 'distributorName'
exports.PHONE_NUMBER = 'phoneNumber'
exports.POSITION = 'position'
exports.ROLE = 'role'
exports.NAME = 'name'
exports.CREATED_BY_ORG = 'createdByOrganization'
exports.CREATED_BY_SITE = 'createdBySite'
exports.EDIT = 'edit'
