import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { withTranslation } from 'react-i18next'
// @material-ui/icons
import Close from '@material-ui/icons/Close'
import notificationsStyle from '../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx'
import Button from '../components/CustomButtons/Button.jsx'
import LoadingButton from './LoadingButton.jsx'

const Modal = ({
  isOpen,
  title,
  isLoading,
  onApply,
  onCancel,
  applyButtonText,
  dialogPaper,
  cancelButtonText,
  hideActions,
  goBackButtonText,
  children: body,
  showGoBackButton,
  t,
  classes,
  hideCancel
}) => {
  const displayedApplyButtonText = applyButtonText || t('Apply')
  const displayedCancelButtonText = cancelButtonText || t('Cancel')
  const displayedGoBackButtonText = goBackButtonText || t('Back')
  return (
    <>
      <Dialog
        open={isOpen}
        keepMounted
        id='modalDialog'
        onClose={onCancel}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'
        fullWidth
        classes={{ paper: dialogPaper }}
      >
        {isLoading}
        <DialogTitle id='modalDialogTitle' disableTypography className={classes.modalHeader}>
          <Button
            className={classes.modalCloseButton}
            justIcon
            id='modalDialogCloseButton'
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={onCancel}
          >
            <Close id='modalDialogCloseButtonIcon' className={classes.modalClose} />
          </Button>
          <h4 id='modalDialogTitle' className={classes.modalTitle}>{title}</h4>
        </DialogTitle>
        <DialogContent id='modalDialogBody'>{body}</DialogContent>
        <DialogActions id='modalDialogActions'>
          {
            !hideActions &&
            <>
              <LoadingButton id='modalDialogApplyButton' disabled={isLoading} isLoading={isLoading} color='transparent' onClick={onApply}>
                {t(displayedApplyButtonText)}
              </LoadingButton>
              {hideCancel ? null : <Button id="modalDialogCancelButton" disabled={isLoading} onClick={onCancel} color="danger" simple>
                {t(displayedCancelButtonText)}
              </Button>}
            </>

          }
          {
            showGoBackButton
              ? <Button id='dashboardUsersResetPasswordBackButton' color='transparent' onClick={onCancel}>
                {displayedGoBackButtonText}
              </Button>
              : null
          }
        </DialogActions>
      </Dialog>
    </>

  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  hideActions: PropTypes.bool,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  showGoBackButton: PropTypes.bool,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  applyButtonText: PropTypes.string,
  goBackButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  dialogPaper: PropTypes.string,
  children: PropTypes.any,
  classes: PropTypes.object
}

export default withStyles(notificationsStyle)(withTranslation()(Modal))
