exports.ID = 'id'
exports.TYPE = 'type'
exports.COUNTRY = 'country'
exports.STATE = 'state'
exports.CITY = 'city'
exports.DISTRICT = 'district'
exports.SITE = 'site'
exports.DISTRIBUTOR = 'distributor'
exports.ADDRESS = 'address'
exports.ZIP_CODE = 'zipcode'
exports.LAST_SYNC = 'lastSync'
exports.STATUS = 'status'
exports.FACILITY = 'facility'
exports.VIEWS = 'views'
exports.INTERNAL_AREA = 'internalArea'
exports.DB_TIME_ZONE = 'dbTimeZone'
exports.SERIAL_NUMBER = 'serialNumber'
exports.ORGANIZATION = 'organization'
exports.SITE = 'site'
exports.INSTALLATION_DATE = 'installationDate'
exports.LAST_VERSION = 'lastVersion'
exports.CURRENT_BOTTLE_WASH_COUNT = 'currentBottleWashCount'
exports.CONNECTION_TYPE = 'connectionType'
exports.MONITOR_ORIENTATION = 'monitorOrientation'
exports.WLAN_MAC_ADDRESS = 'wlanMacAddress'
exports.ELAN_MAC_ADDRESS = 'elanMacAddress'
exports.ATP_FINISH_DATE = 'atpFinishDate'



