//for more than one reducer, use combineReducers
import { combineReducers } from '@reduxjs/toolkit'
import sitesReducer from './sitesRedux'
import organizationsReducer from './organizationsRedux'
import datesReducer from './datesReducer'
import shiftsReducer from './shiftsReducer'
import minWashCountReducer from './minWashCountReducer'
import popUpReducer from './popUpReducer'
import dailyMachineUsagesReducer from './dailyMachineUsagesRedux'
import championsReducer from './championsRedux'
import loaderReducer from './loaderReducer'
import currentShiftResultsReducer from './currentShiftResultsReducer'
import previousShiftResultsReducer from './previousShiftResultsReducer'
import shiftImagesReducer from './shiftImagesReducer'
import { DESTROY_SESSION } from './actions'
import userReducer from './userReducer'
import campaignReducer from './campaignReducer'
import unitConfigurationReducer from './unitConfigurationReducer'
import dailyWashesReducer from './dailyWashesReducer'
import groupsReducer from './groups/groupsReducer'

const appReducer = combineReducers({
  sites: sitesReducer,
  //   machines: machinesReducer,
  //   champions: championsReducer,
  organizations: organizationsReducer,
  //   shiftConfiguration: shiftConfigurationReducer,
  dates: datesReducer,
  shifts: shiftsReducer,
  minWash: minWashCountReducer,
  popUp: popUpReducer,
  dailyMachineUsages: dailyMachineUsagesReducer,
  champions: championsReducer,
  loader: loaderReducer,
  currentShiftResults: currentShiftResultsReducer,
  previousShiftResults: previousShiftResultsReducer,
  shiftImages: shiftImagesReducer,
  user: userReducer,
  campaign: campaignReducer,
  dailyWashes: dailyWashesReducer,
  unitConfiguration: unitConfigurationReducer,
  groups: groupsReducer
})

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) {
    localStorage.removeItem("persist:root")
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer