import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux';
import { useStylesPopUp } from '../../Shift/shiftStyles';
import getCloneCampaign from "../../../endpoint-requests/campaign/cloneCampaign.js"
import { CircularProgress } from '@material-ui/core';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: "none",
  borderRadius: "3px",
  boxShadow: 24,
  p: 4,
};

export default function CloneCampaignModal({ setCopyCampaign, setLoader, loader, setCloneCampaign, cloneCampaign }) {
  const campaign = useSelector((state) => state.campaign.campaign)
  const classes = useStylesPopUp();
  const dispatch = useDispatch()

  const handleClose = () => {
    setCloneCampaign(false)
  };

  const handleConfirm = () => {
    setLoader(true)
    const getCampaignCopy = async () => {
      try {
        const res = await getCloneCampaign(campaign.id)
        if (res) {
          setCopyCampaign(res)
          setTimeout(() => {
            setLoader(false)
            setCloneCampaign(false)
          }, 10000);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getCampaignCopy()
  }
  return (
    <>
      <Modal
        open={cloneCampaign}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" >
            Clone
          </Typography>
          <Close
            onClick={() => handleClose()}
            className={classes.myClassName}
          />
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: "10px" }} >
            You are about to clone a campaign
          </Typography>
          <div className="popupButtonDiv" style={{ float: "right" }}>
            <p style={{
              cursor: 'pointer',
              marginRight: "50px",
              width: "50px",
              fontWeight: "300"
            }} onClick={() => handleConfirm()} >
              {loader ? <CircularProgress size={25} /> : "Confirm"}
            </p>
            <p style={{
              cursor: 'pointer',
              color: "red",
              width: "50px",
              fontWeight: "300"
            }} onClick={() => { (setCloneCampaign(false)) }} >Cancel</p>

          </div>
        </Box>
      </Modal>
    </ >
  );
}
