import ImageModal from '../ImageModal.jsx'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { useEffect, useRef, useState } from 'react'
import ImageUploadStyle from '../../assets-soapy/jss/soapy/components/ImageUploadStyle.jsx'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Button from '../../components/CustomButtons/Button'

const ImageUploadCard = ({
  setAsyncImageError,
  asyncImageError,
  classes,
  onChange = () => null,
  title = '',
  t,
  showDisplayTime = false,
  onDisplayTimeChange = () => null,
  style = {},
  displayTime,
  src = null,
  file = null,
  previewImageHeight = '10rem',
  error = false,
  showActions = true,
  accept = 'image/png, image/jpg, image/jpeg',
  maxSize = 5,
  isUploadAll = false
}) => {
  if (asyncImageError === true) {
    error = true
  }
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [displayTimeValue, setDisplayTimeValue] = useState(displayTime)
  const campaignMonitorOrientation = useSelector((state) => state.campaign.campaignMonitorOrientation)
  const [aspectRatio, setAspectRatio] = useState('16/9')
  useEffect(() => {
    if (campaignMonitorOrientation === 'vertical') {
      setAspectRatio('3/4')
    }
    if (campaignMonitorOrientation === 'horizontal') {
      setAspectRatio('16/9')
    }
    setDisplayTimeValue(displayTime)
  }, [campaignMonitorOrientation, aspectRatio, displayTime])

  const previewImage = src || (file ? URL.createObjectURL(file) : null)
  const handleSelectImage = (event) => {
    const files = isUploadAll ? event.target.files : event.target.files[0]
    onChange(files)
  }
  const handleRemoveImage = () => {
    onChange(null)
    // setAsyncImageError(false)
  }

  const fileInputRef = useRef(null)
  return !isUploadAll ?
    (
      <div
        style={style}
      >
        <div className={classes.title}>
          <Typography variant="body2">{title}</Typography>
        </div>
        <ImageModal
          imageUrl={previewImage}
          isOpen={isImageModalOpen}
          onClose={() => setIsImageModalOpen(false)}
        />
        <div
          className={`${classes.fileUpload} ${error ? classes.fileUploadError : ''}`}
        >
          {
            previewImage
              ?
              <img
                className={classes.fileUploadImage}
                style={{
                  height: previewImageHeight,
                  aspectRatio: aspectRatio,
                }}
                onClick={() => setIsImageModalOpen(true)} src={previewImage} alt="previewImage"/>
              : <div className={classes.imageUploadWrap}
                     style={{ paddingBottom: previewImageHeight }}
              >
                <input
                  className={classes.fileUploadInput}
                  ref={fileInputRef}
                  type="file" onChange={handleSelectImage}
                  accept={accept}
                />
              </div>
          }

          {
            showActions
              ? <div className={classes.actions}>
                <Grid
                  container
                  alignItems="center"
                >
                  <Grid
                    item
                    md={showDisplayTime ? 3 : 6}
                  >
                    <IconButton
                      color="primary"
                      className={classes.addImageBtn}
                      style={
                        showDisplayTime
                          ? {
                            paddingLeft: 0,
                            paddingRight: 0
                          }
                          : {}
                      }
                      size="medium"
                      disabled={!!previewImage}
                      onClick={() => {
                        fileInputRef.current.click()
                      }}
                    >
                      <AddBoxIcon/>
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    md={showDisplayTime ? 3 : 6}
                  >
                    <IconButton
                      color="secondary"
                      style={
                        showDisplayTime
                          ? {
                            paddingLeft: 0,
                            paddingRight: 0
                          }
                          : {}
                      }
                      onClick={handleRemoveImage}
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </Grid>
                  {
                    showDisplayTime
                      ? <Grid
                        item
                        md={6}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            shrink style={{
                            width: '100%'
                          }}
                          >{t('Display time')}
                          </InputLabel>
                          <Select
                            id="displayTimeSelect"
                            value={displayTimeValue}
                            onChange={(event) => {
                              setDisplayTimeValue(event.target.value)
                              onDisplayTimeChange(event.target.value)
                            }}
                          >
                            {
                              Array(30).fill().map((_, i) => {
                                return <MenuItem key={i + 1} value={i + 1}>{i + 1} Sec</MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                      </Grid>

                      : null
                  }

                </Grid>

              </div>
              : null
          }

        </div>
      </div>
    ) : <div>
      <Button
        color="info"
        variant="contained"
        onClick={() => {
          fileInputRef.current.value = null
          fileInputRef.current.click()
        }}

      >
        Select All
      </Button>
      <input
        multiple
        className={classes.fileUploadInput}
        ref={fileInputRef}
        type="file"
        onChange={handleSelectImage}
        accept={accept}
      />
    </div>
}

export default withTranslation()(withStyles(ImageUploadStyle)(ImageUploadCard))
