import axios from 'axios'
import filterEmptyValues from '../../shared/dashboard/filterEmptyValues.js'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * @param {object} siteObject
 * @param {string} siteObject.name
 * @param {string} siteObject.organizationId
 * @param {string} siteObject.distributorId
 * @param {string} siteObject.temperatureType
 * @param {string} siteObject.country
 * @param {string} siteObject.state
 * @param {string} siteObject.city
 * @param {string} siteObject.district
 * @param {string} siteObject.address
 * @param {string} siteObject.zipcode
 * @param {string} siteObject.contactEmail
 * @param {string} siteObject.contactName
 * @param {string} siteObject.website
 * @param {number} siteObject.latitude
 * @param {number} siteObject.longitude
 */
export const addSite = async (siteObject) => {
  const endpoint = `${apiEndpoints.sites}`
  const URL = SERVER_PATH + endpoint
  return resolveRequest(axios.post(URL, filterEmptyValues(siteObject), { withCredentials: true }))
}
