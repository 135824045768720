import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 *
 * @param {object} options - options.
 * @param {'desc' | 'asc'} options.order - order of records.
 * @param {string} options.orderBy - field to order by.
 * @param {boolean} options.fetchDefault - fetch default organizations of distributor.
 */
const fetchOrganizations = async (options = {
  order: 'desc',
  orderBy: 'createdAt',
  fetchDefault: false
}) => {
  const queryParams = new URLSearchParams(options)
  const URL = SERVER_PATH + apiEndpoints.organizations + '?' + queryParams.toString()

  return resolveRequest(axios.get(URL, { withCredentials: true }))
}

export default fetchOrganizations
