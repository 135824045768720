import dateModalStyle from '../../assets-soapy/jss/soapy/views/dateModalStyle.jsx'
import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import Datetime from 'react-datetime'
import getLanguageLocale from '../../shared/multi-language/getLanguageLocale'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Modal from '../../components-soapy/Modal'

// @material-ui/core components
import InputLabel from '@material-ui/core/InputLabel'

import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'

/// ////////// UTILITY /////////////////////
const isValidDate = current => {
  const today = Datetime.moment()
  return current.isBefore(today) // TODO: Change to `isSameOrBefore(today, 'day')
}
/// /////////// COMPONENT ///////////////////

const DateModal = ({
  displayedDate,
  updateDate,
  onApply,
  closeModal,
  isOpen,
  displayedToDate,
  isDateRange,
  t,
  classes
}) => {
  const [date, setDate] = useState(displayedDate)
  const [toDate, setToDate] = useState(displayedToDate)
  const title = t('Filter by date')

  const handleApplyBtnClick = () => {
    onApply(date, toDate)
    closeModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      dialogPaper={classes.dialogPaper}
      title={title}
      id='dateModal'
      onApply={handleApplyBtnClick}
      onCancel={() => {
        setDate(displayedDate)
        closeModal()
      }}
    >
      <br />
      <br />
      <GridContainer>
        <GridItem md={6}>
          <InputLabel style={{ fontSize: '14px' }}>{isDateRange ? t('From date') : t('Date')}</InputLabel>
          <Datetime
            locale={getLanguageLocale()}
            inputProps={{ style: { fontSize: '12px' }, id: 'dateInput', onChange: () => null }}
            value={date}
            onChange={(newDate) => setDate(newDate)}
            timeFormat='HH:mm'
            dateFormat='MMM DD, YYYY'
            closeOnSelect
            isValidDate={isValidDate} // Limit selectable dates
          />
        </GridItem>
        {
          isDateRange
            ? <GridItem md={6}>
              <InputLabel style={{ fontSize: '14px' }}>{t('To date')}</InputLabel>
              <Datetime
                locale={getLanguageLocale()}
                inputProps={{ style: { fontSize: '12px' }, id: 'dateInput', onChange: () => null }}
                value={toDate}
                onChange={(newDate) => setToDate(newDate)}
                timeFormat='HH:mm'
                dateFormat='MMM DD, YYYY'
                closeOnSelect
                isValidDate={isValidDate}
              />
              </GridItem>
            : null
        }
      </GridContainer>
    </Modal>
  )
}

DateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  displayedDate: PropTypes.any.isRequired,
  updateDate: PropTypes.func.isRequired,
  fetchAndUpdateDailyWashes: PropTypes.func.isRequired
}

export default withStyles(dateModalStyle)(withTranslation()(DateModal))
