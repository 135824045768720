import Snackbar from '../components/Snackbar/Snackbar.jsx'
import Modal from './Modal.jsx'
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import AddAlert from '@material-ui/icons/AddAlert'

let errorNotificationTimeout = null
const ConfirmationModal = ({
  isGroupContainWashers,
  t,
  isOpen,
  applyBtnText = 'Delete',
  onApply,
  onClose,
  title = isGroupContainWashers ? 'Warning' : 'Delete',
  body = isGroupContainWashers ? 'This group contain washers, are you sure you want to delete?' : 'Are you sure?',
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isErrorNotificationActive, setIsErrorNotificationActive] = useState(false)
  const [errorNotificationMessage, setErrorNotificationMessage] = useState(null)
  useEffect(() => {
    return () => {
      clearTimeout(errorNotificationTimeout)
      setIsErrorNotificationActive(false)
      setErrorNotificationMessage(null)
      errorNotificationTimeout = null
    }
  }, [])

  const showErrorNotification = (errorMessage) => {
    clearTimeout(errorNotificationTimeout)
    setIsErrorNotificationActive(true)
    setErrorNotificationMessage(errorMessage)
    errorNotificationTimeout = setTimeout(
      function () {
        setIsErrorNotificationActive(false)
      },
      6000
    )
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        title={t(title)}
        onApply={async () => {
          setImmediate(() => setIsLoading(true))
          try {
            await onApply()
          } catch (err) {
            showErrorNotification(err.message)
          }
          setImmediate(() => setIsLoading(false))
        }}
        onCancel={() => onClose()}
        isLoading={isLoading}
        applyButtonText={t(applyBtnText)}
      >
        <div>
          <h4 style={{ color: `${isGroupContainWashers ? 'red' : 'black'}` }}>{t(body)}</h4>
        </div>
        <div id='alerts-section'>
          <Snackbar
            place='tc'
            color='danger'
            icon={AddAlert}
            message={errorNotificationMessage}
            open={isErrorNotificationActive}
            closeNotification={() => setIsErrorNotificationActive(false)}
            close
          />
        </div>
      </Modal>
    </>
  )
}

export default withTranslation()(ConfirmationModal)
