import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'

export default async (campaignId, archive = false) => {
  const URL = SERVER_PATH + apiEndpoints.campaigns + `/${campaignId}?archive=${archive}`

  return resolveRequest(
    axios.delete(URL, {
      withCredentials: true
    })
  )
}
