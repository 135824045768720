import DashboardPage from '../pages/Dashboard/Container'
import DashboardIcon from '@material-ui/icons/Dashboard'
import Shift from '../pages/Shift/Shift'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'


export default [
  {
    name: 'Dashboard',
    title: 'My status since first installation',
    hasMobileView: true,
    component: DashboardPage,
    path: '/dashboard',
    requiredPermissions: [
      {
        name: 'dashboard statistics',
        action: 'read',
      },
    ],
    layout: '',
    icon: DashboardIcon,
  },
  {
    name: 'Shift Monitor',
    title: 'Shift Monitor',
    hasMobileView: true,
    component: Shift,
    path: '/shift',
    requiredPermissions: [
      {
        name: 'shift',
        action: 'read',
      },
    ],
    layout: '',
    icon: TrackChangesIcon
  },
]
