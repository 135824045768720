import Campaigns from '../pages/Campaigns/Campaigns.jsx'
import {
  AssignmentTurnedIn,
  Build,
  Business,
  DeviceHub,
  People,
  PeopleOutline,
  ReportProblem,
  SettingsApplications,
} from '@material-ui/icons'
import Assessment from '@material-ui/icons/Assessment'
import PresentToAllIcon from '@material-ui/icons/PresentToAll'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import DonutLarge from '@material-ui/icons/DonutLarge'
import PanTool from '@material-ui/icons/PanTool'
import Settings from '../pages/Settings/Settings'
import Policy from '../pages/Policy/Policies.jsx'
import UnitConfiguration from '../pages/Unit-Configuration/UnitConfigurations'
import Bottles from '../pages/Bottles/Container'
import DashboardUsers from '../pages/Dashboard-Users/DashboardUsers'
import DailyWashesContainer from '../pages/DailyWashes/Container'
import HandsRecognitionContainer from '../pages/HandsRecognition/Container'
import OrganizationsContainer from '../pages/Organizations/Organizations'
import TrendsContainer from '../pages/Trends/Container'
import Units from '../pages/Units/Units.jsx'
import Washers from '../pages/Washers/Washers'
import StatusContainer from '../pages/Status/Status'
import DistributorsContainer from '../pages/Distributors/Distributors'
import ReportContainer from '../pages/Reports/Reports'
import SitesContainer from '../pages/Sites/Sites'
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite'
import PolicyIcon from '@material-ui/icons/Policy'

export const dashRoutes = [
  {
    name: 'Trends',
    title: 'Trends',
    hasMobileView: true,
    component: TrendsContainer,
    path: '/trends',
    requiredPermissions: [
      {
        name: 'machines',
        action: 'read',
      },
      {
        name: 'daily machine usages',
        action: 'read',
      },
      {
        name: 'organization configuration',
        action: 'read',
      },
    ],
    layout: '',
    icon: Assessment,
    subMenu: false,
  },
  {
    name: 'Daily Washes',
    component: DailyWashesContainer,
    title: 'Daily Washes',
    hasMobileView: true,
    path: '/daily-washes',
    requiredPermissions: [
      {
        name: 'daily washes',
        action: 'read',
      },
      {
        name: 'machines',
        action: 'read',
      },
    ],
    layout: '',
    icon: DonutLarge,
    subMenu: false,
  },
  {
    name: 'Hands recognition',
    component: HandsRecognitionContainer,
    title: 'Hands recognition',
    hasMobileView: true,
    path: '/hands-recognition',
    requiredPermissions: [
      {
        name: 'daily washes',
        action: 'read',
      },
      {
        name: 'machines',
        action: 'read',
      },
      {
        name: 'hand recognition',
        action: 'read',
      },
    ],
    layout: '',
    icon: PanTool,
    subMenu: false,
  },
  {
    name: 'Units',
    title: 'Units',
    hasMobileView: true,
    component: Units,
    path: '/myunits',
    requiredPermissions: [
      {
        name: 'machines',
        action: 'read',
      },
    ],
    layout: '',
    icon: DeviceHub,
    subMenu: false,
  },
  {
    name: 'Status',
    title: 'Status',
    hasMobileView: true,
    component: StatusContainer,
    path: '/status',
    requiredPermissions: [
      {
        name: 'status',
        action: 'read',
      },
    ],
    layout: '',
    icon: AssignmentTurnedIn,
    subMenu: false,
  },
  {
    name: 'Washers',
    title: 'Washers',
    hasMobileView: true,
    component: Washers,
    path: '/washers',
    requiredPermissions: [
      {
        name: 'washers',
        action: 'read',
      },
    ],
    layout: '',
    icon: PeopleOutline,
    subMenu: true
  },
  {
    path: '/dashboard-users',
    title: 'Dashboard Users',
    hasMobileView: true,
    name: 'Dashboard Users',
    component: DashboardUsers,
    requiredPermissions: [
      {
        name: 'dashboard users',
        action: 'read',
      },
    ],
    layout: '',
    icon: People,
    subMenu: false,
  },
  {
    path: '/configuration',
    title: 'Unit configuration',
    hasMobileView: true,
    name: 'Unit configuration',
    component: UnitConfiguration,
    requiredPermissions: [
      {
        name: 'machine configurations',
        action: 'read',
      },
      {
        name: 'products',
        action: 'read',
      },
    ],
    layout: '',
    icon: Build,
    subMenu: false,
  },
  {
    path: '/campaigns',
    title: 'Campaigns',
    hasMobileView: true,
    name: 'Campaigns',
    component: Campaigns,
    requiredPermissions: [
      {
        name: 'campaigns',
        action: 'read',
      },
    ],
    layout: '',
    icon: PresentToAllIcon,
    subMenu: false,
  },
  {
    path: '/organizations',
    title: 'Organizations',
    hasMobileView: true,
    name: 'Organizations',
    component: OrganizationsContainer,
    requiredPermissions: [
      {
        name: 'organizations',
        action: 'read',
      },
    ],
    layout: '',
    icon: Business,
    subMenu: false,
  },
  {
    path: '/distributors',
    title: 'Distributors',
    hasMobileView: true,
    name: 'Distributors',
    component: DistributorsContainer,
    requiredPermissions: [
      {
        name: 'distributors',
        action: 'read',
      },
    ],
    layout: '',
    icon: BusinessCenterIcon,
    subMenu: false,
  },
  {
    path: '/sites',
    title: 'Sites',
    hasMobileView: true,
    name: 'Sites',
    component: SitesContainer,
    requiredPermissions: [
      {
        name: 'sites',
        action: 'read',
      },
    ],
    layout: '',
    icon: SettingsInputCompositeIcon,
    subMenu: false,
  },
  {
    path: '/bottles',
    title: 'Bottles',
    hasMobileView: true,
    name: 'Bottles',
    requiredPermissions: [
      {
        name: 'bottles',
        action: 'create',
      },
      {
        name: 'suppliers',
        action: 'read',
      },
      {
        name: 'suppliers',
        action: 'create',
      },
    ],
    component: Bottles,
    layout: '',
    subMenu: false,
  },
  {
    path: '/reports',
    title: 'Reports',
    name: 'Reports',
    requiredPermissions: [
      {
        name: 'reports',
        action: 'create',
      },
      {
        name: 'reports',
        action: 'read',
      },
      {
        name: 'reports',
        action: 'edit',
      },
      {
        name: 'reports',
        action: 'delete',
      },
    ],
    component: ReportContainer,
    layout: '',
    icon: ReportProblem,
    subMenu: false,
  },
  {
    path: '/policy',
    title: 'Policy',
    hasMobileView: true,
    name: 'Policy',
    component: Policy,
    requiredPermissions: [
      {
        name: 'policies',
        action: 'read',
      },
    ],
    layout: '',
    icon: PolicyIcon,
    subMenu: false,
  },
  {
    path: '/machine-update-settings',
    title: 'Settings',
    hasMobileView: true,
    name: 'Settings',
    component: Settings,
    requiredPermissions: [
      {
        name: 'machine settings',
        action: 'read',
      },
    ],
    layout: '',
    icon: SettingsApplications,
    subMenu: false,
  },
  // {
  //   name: "Shift",
  //   title: "Shift",
  //   hasMobileView: true,

  //   component: Shift,
  //   path: "/shift",
  //   requiredPermissions: [
  //     {
  //       name: "shift statistics",
  //       action: "read",
  //     },
  //   ],
  //   layout: "",
  //   // icon: ShiftIcon,
  // },
  // {
  //   path: '/sequences',
  //   title: 'Unit Sequences',
  //   name: 'Sequences',
  //   component: null,
  //   layout: '/admin/units',
  //   icon: LinearScale
  // }
]

// EXAMPLE FOR COLLAPSED ROUTE
// {
//   collapse: true,
//   name: 'Admin',
//   icon: Settings,
//   state: 'pageCollapse',
//   views: [
//     {
//       name: 'Dashboard',
//       title: 'My Status Since First Installation',
//       component: DashboardPage,
//       path: '/dashboard',
//       requiredPermissions: [{
//         name: 'dashboard statistics',
//         action: 'read'
//       }],
//       layout: '',
//       icon: DashboardIcon
//     }
//   ]
// }

export default dashRoutes

// import Campaigns from "../pages/Campaigns/Campaigns.jsx";
// import {
//   AssignmentTurnedIn,
//   Build,
//   Business,
//   DeviceHub,
//   People,
//   PeopleOutline,
//   ReportProblem,
//   SettingsApplications,
// } from "@material-ui/icons";
// import Assessment from "@material-ui/icons/Assessment";
// import PresentToAllIcon from "@material-ui/icons/PresentToAll";
// import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
// import DonutLarge from "@material-ui/icons/DonutLarge";
// import PanTool from "@material-ui/icons/PanTool";
// import Settings from "../pages/Settings/Settings";
// import Policy from "../pages/Policy/Policies.jsx";
// import UnitConfiguration from "../pages/Unit-Configuration/UnitConfigurations";
// import Bottles from "../pages/Bottles/Container";
// import DashboardUsers from "../pages/Dashboard-Users/DashboardUsers";
// import DailyWashesContainer from "../pages/DailyWashes/Container";
// import HandsRecognitionContainer from "../pages/HandsRecognition/Container";
// import OrganizationsContainer from "../pages/Organizations/Organizations";
// import TrendsContainer from "../pages/Trends/Container";
// import Units from "../pages/Units/Units.jsx";
// import Washers from "../pages/Washers/Washers";
// import StatusContainer from "../pages/Status/Status";
// import DistributorsContainer from "../pages/Distributors/Distributors";
// import ReportContainer from "../pages/Reports/Reports";
// import SitesContainer from "../pages/Sites/Sites";
// import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";
// import PolicyIcon from "@material-ui/icons/Policy";

// //-------------- Routes after Dashboard --------------//
// export const dashRoutes = [
//   {
//     name: "Trends",
//     title: "Trends",
//     hasMobileView: true,
//     component: TrendsContainer,
//     path: "/trends",
//     requiredPermissions: [
//       {
//         name: "machines",
//         action: "read",
//       },
//       {
//         name: "daily machine usages",
//         action: "read",
//       },
//       {
//         name: "organization configuration",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: Assessment,
//     subMenu: false,
//   },
//   {
//     name: "Daily Washes",
//     component: DailyWashesContainer,
//     title: "Daily Washes",
//     hasMobileView: true,
//     path: "/daily-washes",
//     requiredPermissions: [
//       {
//         name: "daily washes",
//         action: "read",
//       },
//       {
//         name: "machines",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: DonutLarge,
//     subMenu: false,
//   },
//   {
//     name: "Hands recognition",
//     component: HandsRecognitionContainer,
//     title: "Hands recognition",
//     hasMobileView: true,
//     path: "/hands-recognition",
//     requiredPermissions: [
//       {
//         name: "daily washes",
//         action: "read",
//       },
//       {
//         name: "machines",
//         action: "read",
//       },
//       {
//         name: "hand recognition",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: PanTool,
//     subMenu: false,
//   },
//   {
//     name: "Units",
//     title: "Units",
//     hasMobileView: true,
//     component: Units,
//     path: "/myunits",
//     requiredPermissions: [
//       {
//         name: "machines",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: DeviceHub,
//     subMenu: false,
//   },

//   {
//     name: "Status",
//     title: "Status",
//     hasMobileView: true,
//     component: StatusContainer,
//     path: "/status",
//     requiredPermissions: [
//       {
//         name: "status",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: AssignmentTurnedIn,
//     subMenu: false,
//   },
//   {
//     name: "Washers",
//     title: "Washers",
//     hasMobileView: true,
//     component: Washers,
//     path: "/washers",
//     requiredPermissions: [
//       {
//         name: "washers",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: PeopleOutline,
//     subMenu: true,
//   },
//   {
//     path: "/dashboard-users",
//     title: "Dashboard Users",
//     hasMobileView: true,
//     name: "Dashboard Users",
//     component: DashboardUsers,
//     requiredPermissions: [
//       {
//         name: "dashboard users",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: People,
//     subMenu: false,
//   },

//   {
//     path: "/configuration",
//     title: "Unit configuration",
//     hasMobileView: true,
//     name: "Unit configuration",
//     component: UnitConfiguration,
//     requiredPermissions: [
//       {
//         name: "machine configurations",
//         action: "read",
//       },
//       {
//         name: "products",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: Build,
//     subMenu: false,
//   },

//   {
//     path: "/campaigns",
//     title: "Campaigns",
//     hasMobileView: true,
//     name: "Campaigns",
//     component: Campaigns,
//     requiredPermissions: [
//       {
//         name: "campaigns",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: PresentToAllIcon,
//     subMenu: false,
//   },

//   {
//     path: "/organizations",
//     title: "Organizations",
//     hasMobileView: true,
//     name: "Organizations",
//     component: OrganizationsContainer,
//     requiredPermissions: [
//       {
//         name: "organizations",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: Business,
//     subMenu: false,
//   },
//   {
//     path: "/distributors",
//     title: "Distributors",
//     hasMobileView: true,
//     name: "Distributors",
//     component: DistributorsContainer,
//     requiredPermissions: [
//       {
//         name: "distributors",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: BusinessCenterIcon,
//     subMenu: false,
//   },
//   {
//     path: "/sites",
//     title: "Sites",
//     hasMobileView: true,
//     name: "Sites",
//     component: SitesContainer,
//     requiredPermissions: [
//       {
//         name: "sites",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: SettingsInputCompositeIcon,
//     subMenu: false,
//   },
//   {
//     path: "/bottles",
//     title: "Bottles",
//     hasMobileView: true,
//     name: "Bottles",
//     requiredPermissions: [
//       {
//         name: "bottles",
//         action: "create",
//       },
//       {
//         name: "suppliers",
//         action: "read",
//       },
//       {
//         name: "suppliers",
//         action: "create",
//       },
//     ],
//     component: Bottles,
//     layout: "",
//     subMenu: false,
//   },
//   {
//     path: "/reports",
//     title: "Reports",
//     name: "Reports",
//     requiredPermissions: [
//       {
//         name: "reports",
//         action: "create",
//       },
//       {
//         name: "reports",
//         action: "read",
//       },
//       {
//         name: "reports",
//         action: "edit",
//       },
//       {
//         name: "reports",
//         action: "delete",
//       },
//     ],
//     component: ReportContainer,
//     layout: "",
//     icon: ReportProblem,
//     subMenu: false,
//   },
//   {
//     path: "/policy",
//     title: "Policy",
//     hasMobileView: true,
//     name: "Policy",
//     component: Policy,
//     requiredPermissions: [
//       {
//         name: "policies",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: PolicyIcon,
//     subMenu: false,
//   },
//   {
//     path: "/machine-update-settings",
//     title: "Settings",
//     hasMobileView: true,
//     name: "Settings",
//     component: Settings,
//     requiredPermissions: [
//       {
//         name: "machine settings",
//         action: "read",
//       },
//     ],
//     layout: "",
//     icon: SettingsApplications,
//     subMenu: false,
//   },
//   // {
//   //   name: "Shift",
//   //   title: "Shift",
//   //   hasMobileView: true,

//   //   component: Shift,
//   //   path: "/shift",
//   //   requiredPermissions: [
//   //     {
//   //       name: "shift statistics",
//   //       action: "read",
//   //     },
//   //   ],
//   //   layout: "",
//   //   // icon: ShiftIcon,
//   // },
//   // {
//   //   path: '/sequences',
//   //   title: 'Unit Sequences',
//   //   name: 'Sequences',
//   //   component: null,
//   //   layout: '/admin/units',
//   //   icon: LinearScale
//   // }
// ];

// // EXAMPLE FOR COLLAPSED ROUTE
// // {
// //   collapse: true,
// //   name: 'Admin',
// //   icon: Settings,
// //   state: 'pageCollapse',
// //   views: [
// //     {
// //       name: 'Dashboard',
// //       title: 'My Status Since First Installation',
// //       component: DashboardPage,
// //       path: '/dashboard',
// //       requiredPermissions: [{
// //         name: 'dashboard statistics',
// //         action: 'read'
// //       }],
// //       layout: '',
// //       icon: DashboardIcon
// //     }
// //   ]
// // }

// export default dashRoutes;

