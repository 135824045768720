import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Slide from '@material-ui/core/Slide'
import { withTranslation } from 'react-i18next'
// @material-ui/icons
import Close from '@material-ui/icons/Close'

import notificationsStyle from '../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx'

import Button from '../components/CustomButtons/Button.jsx'

const Transition = props => <Slide direction='down' {...props} />

const Modal = ({
  isOpen,
  title,
  isLoading,
  onApply,
  onApply2,
  onCancel,
  applyButtonText,
  applyButtonText2,
  dialogPaper,
  cancelButtonText,
  hideActions,
  children: body,
  t,
  classes,
  unit
}) => {
  const displayedApplyButtonText = applyButtonText || 'Apply'
  const displayedApplyButtonText2 = applyButtonText2 || 'Apply'
  const displayedCancelButtonText = cancelButtonText || 'Cancel'
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      id='modalDialog'
      onClose={onCancel}
      aria-labelledby='classic-modal-slide-title'
      aria-describedby='classic-modal-slide-description'
      fullWidth
      classes={{ paper: dialogPaper }}
    >
      {isLoading}
      <DialogTitle id='modalDialogTitle' disableTypography className={classes.modalHeader}>
        <Button
          className={classes.modalCloseButton}
          justIcon
          id='modalDialogCloseButton'
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={onCancel}
        >
          <Close id='modalDialogCloseButtonIcon' className={classes.modalClose} />
        </Button>
        <h4 id='modalDialogTitle' className={classes.modalTitle}>{title}</h4>
      </DialogTitle>
      <div style={{ textAlign: 'center', margin: '8px' }}>
        <Button
          style={{ margin: '5px' }}
          color='info'
          id='apply1'
          onClick={onApply}
        >
          {t(displayedApplyButtonText)}
        </Button>
        <Button
          style={{ margin: '5px' }}
          color='info'
          id='apply2'
          onClick={onApply2}
        >
          {t(displayedApplyButtonText2)}
        </Button>
      </div>
      {
        !hideActions &&
          <DialogActions
            style={{ margin: 0 }}
            id='modalDialogActions'
          >
            <Button id='modalDialogCancelButton' disabled={isLoading} onClick={onCancel} color='danger' simple>
              {t(displayedCancelButtonText)}
            </Button>
          </DialogActions>
      }
    </Dialog>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  hideActions: PropTypes.bool,
  title: PropTypes.string,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  applyButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  dialogPaper: PropTypes.string,
  children: PropTypes.any,
  classes: PropTypes.object
}

export default withStyles(notificationsStyle)(withTranslation()(Modal))
