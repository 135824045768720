import resolveRequest from '../../utils/resolveRequest.js'
import filterEmptyValues from '../../shared/dashboard/filterEmptyValues.js'
import dashboardUserRequestKeywords from '../../shared/dashboard-user/request-keywords'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints'

export default async (
  firstName,
  lastName,
  email,
  organizationIds,
  position,
  isDashboardUserPage,
  roleName,
  phoneNumber,
  distributorId,
  entityName,
  siteIds,
  defaultPage,
  fullScreen,
  twoFA,
) => {
  const endpoint = apiEndpoints.dashboardUsers
  const URL = SERVER_PATH + endpoint
  const requestBody = {
    [dashboardUserRequestKeywords.FIRST_NAME]: firstName,
    [dashboardUserRequestKeywords.LAST_NAME]: lastName,
    [dashboardUserRequestKeywords.EMAIL]: email,
    [dashboardUserRequestKeywords.ORGANIZATION_IDS]: typeof (organizationIds) === 'string' ? [organizationIds] : organizationIds,
    [dashboardUserRequestKeywords.POSITION]: position,
    [dashboardUserRequestKeywords.PHONE_NUMBER]: phoneNumber,
    [dashboardUserRequestKeywords.ROLE_NAME]: roleName,
    [dashboardUserRequestKeywords.DISTRIBUTOR_ID]: distributorId,
    [dashboardUserRequestKeywords.SITE_IDS]: typeof (siteIds) === 'string' ? [siteIds] : siteIds,
    [dashboardUserRequestKeywords.ENTITY_NAME]: entityName,
    [dashboardUserRequestKeywords.DEFAULT_PAGE]: defaultPage,
    [dashboardUserRequestKeywords.FULL_SCREEN]: fullScreen,
    [dashboardUserRequestKeywords.TWO_FA]: twoFA,
  }
  const filteredRequestBody = filterEmptyValues(requestBody)
  return resolveRequest(axios.post(URL, filteredRequestBody, { withCredentials: true }))
}
