import { createSlice } from "@reduxjs/toolkit";

const dailyMachineUsagesSlice = createSlice({
    name: "dailyMachineUsages",
    initialState: {
        dailyMachineUsages: [],
        totalWashCount: 0,
        avgWashScore: 0,
        avgScrubTime: 0,
        washDates: [],
        washValues: [],
        handWashValues: [],
        handWashMovements: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        dailyMachineUsagesStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        dailyMachineUsagesSuccess: (state, action) => {
            state.isFetching = false;
            state.dailyMachineUsages = action.payload;


        },
        dailyMachineUsagesFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        totalWashCountStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        totalWashCountSuccess: (state, action) => {
            state.isFetching = false;

            state.totalWashCount = action.payload;


        },
        totalWashCountFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        avgWashScoreStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        avgWashScoreSuccess: (state, action) => {
            state.isFetching = false;

            state.avgWashScore = action.payload;


        },
        avgWashScoreFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        avgScrubTimeStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        avgScrubTimeSuccess: (state, action) => {
            state.isFetching = false;

            state.avgScrubTime = action.payload;


        },
        avgScrubTimeFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        washDatesSuccess: (state, action) => {
            state.isFetching = false;
            state.washDates = action.payload;


        },
        washValuesSuccess: (state, action) => {
            state.isFetching = false;
            state.washValues = action.payload;


        },
        handWashValuesStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        handWashValuesSuccess: (state, action) => {
            state.isFetching = false;

            state.handWashValues = action.payload;


        },
        handWashValuesFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        handWashMovementsStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        handWashMovementsSuccess: (state, action) => {
            state.isFetching = false;

            state.handWashMovements = action.payload;


        },
        handWashMovementsFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        dailyMachineUsagesLogoutSuccess: (state, action) => {
            state.isFetching = false;

            state.handWashValues = []
            state.dailyMachineUsages = []
            state.totalWashCount = 0
            state.avgWashScore = 0
            state.avgScrubTime = 0
            state.washDates = []
            state.washValues = []
            state.handWashMovements = []


        },

    },
});

export const {
    dailyMachineUsagesStart,
    dailyMachineUsagesSuccess,
    dailyMachineUsagesFailure,
    totalWashCountStart,
    totalWashCountSuccess,
    totalWashCountFailure,
    avgWashScoreStart,
    avgWashScoreSuccess,
    avgWashScoreFailure,
    avgScrubTimeStart,
    avgScrubTimeSuccess,
    avgScrubTimeFailure,
    washDatesSuccess,
    washValuesSuccess,
    handWashValuesStart,
    handWashValuesSuccess,
    handWashValuesFailure,
    handWashMovementsStart,
    handWashMovementsSuccess,
    handWashMovementsFailure,
    dailyMachineUsagesLogoutSuccess
} = dailyMachineUsagesSlice.actions;


const dailyMachineUsagesReducer = dailyMachineUsagesSlice.reducer; //it should be export default
export default dailyMachineUsagesReducer;