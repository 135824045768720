import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'

import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export const setTrigger = async (body) => {
  const endpoint = `${apiEndpoints.setTrigger}`
  const URL = SERVER_PATH + endpoint
  return resolveRequest(axios.post(URL, body, { withCredentials: true }))
}
