
const machinesMobileModalStyle = theme => ({
  dialogPaper: {
    minHeight: '600px',
    maxHeight: '600px',
    maxWidth: '600px',
    margin: '20px'
  }
})

export default machinesMobileModalStyle
