import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMachine } from '@xstate/react'
import React from 'react'
import Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import fetchMachines from '../../endpoint-requests/machine/getMachines.js'
import fetchDailyMachineUsages from '../../endpoint-requests/usage/fetchDailyMachineUsages.js'
import getCookie from '../../shared/cookie/get-cookie.js'
import setCookie from '../../shared/cookie/set-cookie.js'
import Presentational from './Presentational.jsx'
import fetchHandsRecognition from '../../endpoint-requests/hands-recognition/fetchHandsRecognition'

const defaultFromDate = Datetime.moment().subtract(7, 'day').set({ hour: 0, minute: 0, second: 0 })
const defaultToDate = Datetime.moment().set({ hour: 23, minute: 59, second: 59 })

function getMachine(user) {
  return Machine({
    id: 'units',
    initial: 'loadAll',
    context: {
      machines: [],
      dailyMachineUsages: [],
      selectedMachineIds: [],
      filterByHours: false,
      fromHour: defaultFromDate,
      toHour: defaultToDate,
      fromDate: defaultFromDate,
      toDate: defaultToDate,
      handsRecognitionData: []
    },
    states: {
      loadAll: {
        entry: ['extractFromCookie'],
        invoke: {
          src: (context, event) => Promise.all([
            fetchMachines({ fetchArchived: true, defaultOrganization: user.entity.name === 'distributor' && user.role.name === 'admin' }),
            fetchDailyMachineUsages(
              context.fromDate,
              context.toDate,
              context.selectedMachineIds,
              context.filterByHours ? context.fromHour : null,
              context.filterByHours ? context.toHour : null,
              context.filterByHours
            ),
            fetchHandsRecognition({
              date: context.fromDate,
              toDate: context.toDate,
              fromHour: context.filterByHours ? context.fromHour : null,
              toHour: context.filterByHours ? context.toHour : null,
              machineIds: context.selectedMachineIds
            })
          ]),
          onDone: {
            target: 'main',
            actions: assign({
              machines: (context, event) => event.data[0],
              selectedMachineIds: (context, event) => {
                // filter machines that no longer exist
                return context.selectedMachineIds.filter(machineId => event.data[0].findIndex(machine => machine.id === machineId) !== -1)
              },
              dailyMachineUsages: (context, event) => event.data[1],
              handsRecognitionData: (context, event) => event.data[2]
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.message
            })
          }
        }
      },
      loadDailyMachineUsages: {
        invoke: {
          src: (context, event) => {
            return Promise.all([
              fetchDailyMachineUsages(
                context.fromDate,
                context.toDate,
                context.selectedMachineIds,
                context.filterByHours ? context.fromHour : null,
                context.filterByHours ? context.toHour : null,
                context.filterByHours
              ),
              fetchHandsRecognition({
                date: context.fromDate,
                toDate: context.toDate,
                fromHour: context.filterByHours ? context.fromHour : null,
                toHour: context.filterByHours ? context.toHour : null,
                machineIds: context.selectedMachineIds
              })
            ])
          },
          onDone: {
            target: 'main',
            actions: assign({
              dailyMachineUsages: (context, event) => event.data[0],
              handsRecognitionData: (context, event) => event.data[1]
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.message
            })
          }
        }
      },
      failedFetchingData: {
        type: 'final'
      },

      machinesModal: {
        on: {
          CLOSE_MACHINES_MODAL: 'main',
          LOAD_DAILY_MACHINE_USAGES: {
            target: 'loadDailyMachineUsages',
            actions: [
              'setFromDate',
              'setToDate',
              'setFromHour',
              'setToHour',
              'setFilterByHours',
              'setSelectedMachineIds'
            ]
          }
        }
      },
      dateModal: {
        on: {
          CLOSE_DATE_MODAL: 'main',
          LOAD_DAILY_MACHINE_USAGES: {
            target: 'loadDailyMachineUsages',
            actions: [
              'setFromDate',
              'setToDate',
              'setFromHour',
              'setToHour',
              'setFilterByHours',
              'setSelectedMachineIds'
            ]
          }
        }
      },
      main: {
        on: {
          OPEN_DATE_MODAL: 'dateModal',
          OPEN_MACHINES_MODAL: 'machinesModal'
        }
      }
    }
  },
    {
      actions: {
        extractFromCookie: assign({
          fromDate: (context, event) => getCookie('trendsFromDate') ? Datetime.moment(getCookie('trendsFromDate')) : defaultFromDate,
          toDate: (context, event) => getCookie('trendsToDate') ? Datetime.moment(getCookie('trendsToDate')) : defaultToDate,
          selectedMachineIds: (context, event) => getCookie('usageMachineIds') ? getCookie('usageMachineIds').split(',') : []
        }),
        setFromDate: assign({
          fromDate: (context, event) => event.fromDate
        }),
        setToDate: assign({
          toDate: (context, event) => event.toDate
        }),
        setFromHour: assign({
          fromHour: (context, event) => event.fromHour
        }),
        setToHour: assign({
          toHour: (context, event) => event.toHour
        }),
        setFilterByHours: assign({
          filterByHours: (context, event) => event.filterByHours
        }),
        setSelectedMachineIds: assign({
          selectedMachineIds: (context, event) => event.selectedMachineIds
        })
      }
    }
  )
}

const TrendsContainer = ({ t, currentDashboardUser }) => {
  const [current, send] = useMachine(getMachine(currentDashboardUser))

  switch (current.value) {
    case 'loadAll':
    case 'loadDailyMachineUsages':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t(current.context.error)}</div>
    default: {
      const {
        machines,
        dailyMachineUsages,
        fromDate,
        selectedMachineIds,
        fromHour,
        toHour,
        filterByHours,
        toDate,
        handsRecognitionData
      } = current.context
      const isMachineModalOpen = current.value === 'machineModal'
      const isDateModalOpen = current.value === 'dateModal'
      const isMachinesModalOpen = current.value === 'machinesModal'

      const showNoDataError = dailyMachineUsages ? !dailyMachineUsages.length : true

      return (
        <Presentational
          dailyMachineUsagesInfo={dailyMachineUsages}
          selectedMachineIds={selectedMachineIds}
          showNoDataError={showNoDataError}
          openDateModal={
            () => send('OPEN_DATE_MODAL')
          }
          openMachinesModal={
            () => send('OPEN_MACHINES_MODAL')
          }
          closeDateModal={
            () => send('CLOSE_DATE_MODAL')
          }
          closeMachinesModal={
            () => send('CLOSE_MACHINES_MODAL')
          }
          isMachinesModalOpen={isMachinesModalOpen}
          isDateModalOpen={isDateModalOpen}
          machines={machines}
          fromHour={fromHour}
          toHour={toHour}
          filterByHours={filterByHours}
          fromDate={fromDate}
          toDate={toDate}
          isMachineModalOpen={isMachineModalOpen}
          handsRecognitionDataInfo={handsRecognitionData}
          fetchDailyMachineUsages={(fromDate, toDate, machineIds, fromHour, toHour, filterByHours) => {
            setCookie('trendsFromDate', fromDate)
            setCookie('trendsToDate', toDate)
            send({
              type: 'LOAD_DAILY_MACHINE_USAGES',
              fromDate,
              fromHour,
              toHour,
              filterByHours,
              toDate,
              selectedMachineIds: machineIds
            })
          }}

        />
      )
    }
  }
}

export default withAuthentication(withTranslation()(TrendsContainer))
