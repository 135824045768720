import {
  CircularProgress,

  Tooltip, Typography
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Remove from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import React from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import contactsModalStyle from '../../../assets-soapy/jss/soapy/views/contactsModalStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import Button from '../../../components/CustomButtons/Button'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import defaultFilterMethod from '../../../shared/react-table/defaultFilterMethod.js'
import tableStyle from '../../../shared/styles/tableSortIcon.css'

const DEFAULT_PAGE_SIZE = 10
const EMAIL = 'email'
const NAME = 'name'
const PHONE_NUMBER = 'phoneNumber'

const ContactsModal = ({
  contacts,
  isOpen,
  classes,
  onClose,
  openAddContactModal,
  openDeleteContactModal,
  hasPermission,
  currentDashboardUser,
  openUpdateContactModal,
  isLoadingContacts,
  t
}) => {
  return (
    <Modal
      isOpen={isOpen}
      dialogPaper={classes.dialogPaper}
      hideActions
      title={t('Contacts')}
      onCancel={() => {
        onClose()
      }}
      t={t}
    >
      {
        isLoadingContacts ? <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
          : <GridContainer id='content-pane-layout' justify='center'>
            <GridItem md={12}>
              <Card style={{ boxShadow: 'none' }}>

                <CardBody>
                  <div>
                    {
                      hasPermission({ name: 'site contacts', action: 'create' })
                        ? <Button
                          color='info'
                          id='unitsMapViewButton'
                          size='sm'
                          round
                          onClick={() => {
                            openAddContactModal()
                          }}
                          style={{ marginRight: 5 }}
                          >
                          {t('Add Contact')}
                        </Button>
                        : null
                    }
                    <ReactTable
                      data={contacts}
                      style={tableStyle}
                      filterable
                      defaultFilterMethod={defaultFilterMethod}
                      columns={[
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: 'left'
                              }}
                            >
                              <Typography variant='body2'>{t('Name')}</Typography>
                            </div>
                          ),
                          style: { textAlign: 'left' },
                          accessor: `${NAME}`
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: 'left'
                              }}
                            >
                              <Typography variant='body2'>{t('Email')}</Typography>
                            </div>
                          ),
                          style: { textAlign: 'left' },
                          accessor: `${EMAIL}`
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: 'left'
                              }}
                            >
                              <Typography variant='body2'>{t('Phone number')}</Typography>
                            </div>
                          ),
                          style: { textAlign: 'left' },
                          accessor: `${PHONE_NUMBER}`
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: 'left',
                                maxHeight: '60px',
                                marginLeft: 0,
                                paddingBottom: '84px'
                              }}
                            >
                              <Typography variant='body2'>{t('Actions')}</Typography>
                            </div>
                          ),
                          style: { textAlign: 'left' },
                          Cell: ({ original }) => (
                            <div style={{ display: 'flex' }}>
                              {
                                hasPermission({ name: 'site contacts', action: 'edit' })
                                  ? <div
                                    id='contactUpdateModalBtn'
                                    onClick={() => {
                                      openUpdateContactModal(original)
                                    }}
                                  >
                                    <Tooltip title={t('Edit')}>
                                      <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                                    </Tooltip>
                                    </div>
                                  : null
                              }
                              {
                                hasPermission({ name: 'site contacts', action: 'delete' })
                                  ? <div
                                    id='contactDeleteModalBtn'
                                    onClick={() => openDeleteContactModal(original)}
                                    >
                                    <Tooltip title={t('Delete')}>
                                      <Remove style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                                    </Tooltip>
                                    </div>
                                  : null
                              }

                            </div>
                          ),
                          sortable: false,
                          filterable: false
                        }
                      ]}
                      defaultPageSize={DEFAULT_PAGE_SIZE}
                      defaultSorted={[
                        {
                          id: 'country',
                          desc: false
                        }, {
                          id: 'city',
                          desc: false
                        }
                      ]}
                      minRows={0}
                      nextText={t('Next')}
                      pageText={t('Page')}
                      onPageChange={() => {
                        document.getElementById('mainPanel').scrollTop = 0
                      }}
                      ofText={t('Of')}
                      rowsText={t('Rows')}
                      previousText={t('Previous')}
                      showPaginationBottom={
                        contacts.length > DEFAULT_PAGE_SIZE
                      }

                      className='-striped -highlight'
                    />

                  </div>
                </CardBody>
              </Card>
            </GridItem>
            </GridContainer>
      }

    </Modal>
  )
}

export default withAuthentication(withStyles(contactsModalStyle)(withTranslation()(ContactsModal)))
