import machineUsagesBySites from '../../../endpoint-requests/usage/machineUsagesBySites'
import { calculateShiftData } from './particularShiftCalculation'

export const getCurrentDailyMachineUsages = async (currentFrom, currentTo, sitesIds, minimumWashes, washersIds) => {
    try {
        let currentTotalWashes = 0
        let currentTotalHygenicWashes = 0
        let currentAveragedResult = 0
        let currentWashesLeft = 0
        let fromDate = new Date(currentFrom).toISOString()
        let toDate = new Date(currentTo).toISOString()


        const fetchDailyMachineUsagesData = await machineUsagesBySites(fromDate, toDate, sitesIds, washersIds, false,)
        if (fetchDailyMachineUsagesData.length !== 0) {
            const currentShiftResult = calculateShiftData(fetchDailyMachineUsagesData)
            currentTotalWashes = currentShiftResult.washCount
            currentAveragedResult = currentShiftResult.averageWashScore
            currentTotalHygenicWashes = currentTotalWashes - currentShiftResult.failedWashCount

            if (minimumWashes - currentTotalWashes > 0) {
                currentWashesLeft = minimumWashes - currentTotalWashes
            }
            return { currentTotalWashes, currentTotalHygenicWashes, currentAveragedResult, currentWashesLeft }
        }
        if (minimumWashes - currentTotalWashes > 0) {
            currentWashesLeft = minimumWashes - currentTotalWashes
        }
        return { currentTotalWashes, currentTotalHygenicWashes, currentAveragedResult, currentWashesLeft }

    } catch (error) {
        console.log(error);

    }
}