import { apiEndpoints, SERVER_PATH } from '../../../endpoint-requests/endpoints.js'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useRef } from 'react'
import { withTranslation } from 'react-i18next'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import {
  HANDS_ICON_NAMES_LOWERCASE,
  HANDS_RECOGNITION_KEYS
} from '../../../shared/hands-recognition/handsRecognitionNames'
import HighchartsReact from 'highcharts-react-official'
import trendsStyle from '../../../assets-soapy/jss/soapy/views/trendsStyle'
import Highcharts from 'highcharts'

const endpoint = `${apiEndpoints.getHandsRecognitionIcons}`
const handsRecognitionIconsURL = SERVER_PATH + endpoint

const HandsRecognitionCount = ({
  classes,
  t,
  data
}) => {
  const countHandsRecognition = (data) => {
    const handsRecognitionKeys = Object.values(HANDS_RECOGNITION_KEYS)
    const resultObject = {}
    const successSeriesData = []
    const failedSeriesData = []
    const xaxis = []

    handsRecognitionKeys.forEach(key => {
      resultObject[key] = []
    })

    data.forEach(item => {
      handsRecognitionKeys.forEach(key => {
        const value = item[key]
        if (value !== null) {
          resultObject[key].push(value)
        }
      })
    })

    handsRecognitionKeys.forEach(key => {
      const countSuccess = resultObject[key].filter(Boolean).length
      xaxis.push(HANDS_ICON_NAMES_LOWERCASE[key])
      // null is better than 0, because 0 values are not pretty in the chart
      successSeriesData.push(countSuccess || null)
      failedSeriesData.push((resultObject[key].length - countSuccess) || null)
    })

    return {
      xaxis,
      series: [
        {
          data: successSeriesData,
          name: t('Success')
        },
        {
          data: failedSeriesData,
          name: t('Failed')
        }
      ]
    }
  }

  const chartData = countHandsRecognition(data)

  const washesChartRef = useRef()
  const chart = <HighchartsReact
    highcharts={Highcharts}
    constructorType="chart"
    options={{
      yAxis: {
        title:
        {
          text: t('')
        }
      },
      xAxis: {
        categories: chartData.xaxis,
        labels: {
          useHTML: true,
          formatter: function () {
            const name = this.value
            let output = ''
            Object.keys(HANDS_ICON_NAMES_LOWERCASE).forEach(key => {
              const iconName = HANDS_ICON_NAMES_LOWERCASE[key]
              if (name === iconName) {
                output = key
              }
            })
            return `<br><img src=${handsRecognitionIconsURL}/${output}.svg height="40" width="40"/>`
          },
          style: {
            'text-align': 'center'
          }
        }
      },
      series: chartData.series,
      chart: {
        type: 'column',
        height: 500
      },
      title: {
        text: ''
      },

      tooltip: {
        formatter: function () {
          return '<b>' + this.x + '</b><br/>' +
            this.series.name + ': ' + this.y + '<br/>' +
            t('Total') + ': ' + this.point.stackTotal
        }
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            style: {
              textOutline: '0px'
            }
          }
        }
      },
      colors: [
        '#4074c1',
        '#ffe78a'
      ]
    }}
    ref={washesChartRef}
  />

  return (
    <div className={classes.content}>
      <div
        style={{
          marginTop: 30,
          position: 'relative',
          height: '100%'
        }}
      >
        {chart}
      </div>
    </div>
  )
}

export default withAuthentication(withTranslation()(withStyles(trendsStyle)(HandsRecognitionCount)))
