import { Checkbox, FormControl, TextField } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Edit from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import formStyle from '../../../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../components-soapy/LoadingButton.jsx'
import ProceedModal from '../../../components-soapy/ProceedModal.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CardHeader from '../../../components/Card/CardHeader.jsx'
import CardIcon from '../../../components/Card/CardIcon.jsx'
import CustomInput from '../../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import { addDistributor } from '../../../endpoint-requests/distributor/addDistributor'
import { editDistributor } from '../../../endpoint-requests/distributor/editDistributor'
import useFormFields from '../../../shared/hooks/useFormFields.js'
import validateFormFields from '../../../utils/validateFormFields.js'
import { verifyLength } from '../../../utils/validators'
import { Autocomplete } from '@material-ui/lab'
import Check from '@material-ui/icons/Check'

const INFO_COLOR = '#00acc1'
const NAME = 'name'
const COUNTRY = 'country'
const STATE = 'state'
const CITY = 'city'
const DISTRICT = 'district'
const ADDRESS = 'address'
const ZIPCODE = 'zipcode'
const ORGANIZATION_IDS = 'organizationIds'
const VALIDATION_STATE = 'ValidationState'

let spinnerTimeout = null

const EditDistributor = ({
  classes,
  isClosed,
  showErrorNotification,
  distributorEdited,
  isDistributorPage = false,
  distributorAdded,
  distributor,
  organizations,
  t
}) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false)
  const organizationsArray = Object.keys(organizations).map(id => {
    return {
      id,
      name: organizations[id]
    }
  })

  const { formFields, setFormFields } = useFormFields({
    [NAME]: {
      value: '',
      isRequired: true,
      name: 'Name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 256)
        }
      ]
    },
    [COUNTRY]: { value: '', isRequired: false, name: 'Country' },
    [STATE]: { value: '', isRequired: false, name: 'State' },
    [CITY]: { value: '', isRequired: false, name: 'City' },
    [ZIPCODE]: { value: '', isRequired: false, name: 'Zipcode' },
    [DISTRICT]: { value: '', isRequired: false, name: 'District' },
    [ORGANIZATION_IDS]: { value: [], isRequired: false, name: 'Organization' },
    [ADDRESS]: { value: '', isRequired: false, name: 'Address' }
  })

  const submitForm = async (proceed = false) => {
    const distributorObject = {
      name: formFields[NAME].value,
      country: formFields[COUNTRY].value,
      state: formFields[STATE].value,
      organizationIds: formFields[ORGANIZATION_IDS].value,
      city: formFields[CITY].value,
      proceed,
      district: formFields[DISTRICT].value,
      address: formFields[ADDRESS].value,
      zipcode: formFields[ZIPCODE].value
    }

    activateSpinner()
    try {
      if (!distributor.id) {
        const addedDistributor = await addDistributor(distributorObject)
        distributorAdded(addedDistributor)
      } else {
        const editedDistributor = await editDistributor(distributor.id, distributorObject)
        distributorEdited(editedDistributor)
      }
    } catch (err) {
      if (err.errorType === 'CONFLICTED_DISTRIBUTOR') {
        setIsProceedModalOpen(true)
      } else {
        showErrorNotification(err.message)
      }
    }
    disableSpinner()
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }
  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    setFormFields(
      (formFields) => ({
        [NAME]: { ...formFields[NAME], value: distributor[NAME] || '' },
        [COUNTRY]: { ...formFields[COUNTRY], value: distributor[COUNTRY] || '' },
        [STATE]: { ...formFields[STATE], value: distributor[STATE] || '' },
        [CITY]: { ...formFields[CITY], value: distributor[CITY] || '' },
        [ZIPCODE]: { ...formFields[ZIPCODE], value: distributor[ZIPCODE] || '' },
        [DISTRICT]: { ...formFields[DISTRICT], value: distributor[DISTRICT] || '' },
        [ORGANIZATION_IDS]: {
          ...formFields[ORGANIZATION_IDS],
          value: distributor.organizations ? distributor.organizations.map((organization) => organization.id) : []
        },
        [ADDRESS]: { ...formFields[ADDRESS], value: distributor[ADDRESS] || '' },
        [ZIPCODE]: { ...formFields[ZIPCODE], value: distributor[ZIPCODE] || '' }
      }))
  }, [distributor, isClosed]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [])

  const handleOrganizationChange = (event, values) => {
    if (values) {
      const ids = values.map(value => value.id)
      setFormFields({ ...formFields, [ORGANIZATION_IDS]: { ...formFields[ORGANIZATION_IDS], value: ids } })
    }
  }

  const handleTextChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: event.target.value } })
  }

  return (
    <GridContainer id='content-pane-layout' justify='center'>

      <ProceedModal
        isOpen={isProceedModalOpen}
        onClose={() => setIsProceedModalOpen(false)}
        msg='Organizations you chose might belong to a different distributor, do you still want to proceed?'
        onApply={() => {
          setIsProceedModalOpen(false)
          submitForm(true)
        }}
      />
      <GridItem md={9}>
        <Card style={isDistributorPage ? { boxShadow: 'none' } : {}}>
          {!isDistributorPage
            ? <CardHeader>
              <CardIcon color='info'>
                <Edit
                  className={classes.cardHeaderIcon}
                  style={{ fontSize: 30 }}
                />
              </CardIcon>
            </CardHeader>
            : null}
          <CardBody>
            <div>
              <form>
                <GridContainer
                  direction='column'
                  justify='center'
                  alignItems='center'
                  id='card-body-section'
                >
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id='distributorName'
                      labelText={`${t('Name')} *`}
                      inputProps={{
                        value: formFields[NAME].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id='distributorCountry'
                      labelText={t('Country')}
                      inputProps={{
                        value: formFields[COUNTRY].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, COUNTRY)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('State')}
                      id='distributorState'
                      inputProps={{
                        value: formFields[STATE].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, STATE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('City')}
                      id='distributorCity'
                      inputProps={{
                        value: formFields[CITY].value,
                        onChange: event => {
                          handleTextChange(event, CITY)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('District')}
                      id='distributorDistrict'
                      inputProps={{
                        value: formFields[DISTRICT].value,
                        onChange: event => {
                          handleTextChange(event, DISTRICT)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('Address')}
                      id='distributorAddress'
                      inputProps={{
                        value: formFields[ADDRESS].value,
                        onChange: event => {
                          handleTextChange(event, ADDRESS)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id='distributorZipCode'
                      labelText={t('Zip code')}
                      inputProps={{
                        value: formFields[ZIPCODE].value,
                        onChange: event => {
                          handleTextChange(event, ZIPCODE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{
                    width: '41vh',
                    margin: '27px 0 17px 0',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        id='checkboxes-tags-demo'
                        options={organizationsArray}
                        onChange={(event, value) => {
                          handleOrganizationChange(event, value)
                        }}
                        value={formFields[ORGANIZATION_IDS].value.map(id => {
                          return {
                            id,
                            name: organizations[id]
                          }
                        })}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => {
                          return (
                            <>
                              <Checkbox
                                checkedIcon={<Check
                                  className={classes.checkedIcon}
                                  style={{ color: INFO_COLOR }}
                                             />}
                                icon={<Check
                                  className={classes.uncheckedIcon}
                                      />}
                                style={{ marginRight: 8 }}
                                checked={formFields[ORGANIZATION_IDS].value.indexOf(option.id) > -1}
                              />
                              {option.name}
                            </>
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t('Organization')}
                            variant='outlined'
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  justify='center'
                  alignItems='center'
                  id='card-footer-section'
                >
                  <GridItem>
                    <LoadingButton
                      color='info'
                      type='submit'
                      id='distributorEditSaveButton'
                      onClick={(e) => {
                        e.preventDefault()
                        handleSaveBtnClick()
                      }}
                      fullWidth
                      disabled={isAwaitingResponse}
                      isLoading={isAwaitingResponse}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </GridItem>
                </GridContainer>
              </form>

            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withTranslation()(EditDistributor))))
