import Unsubscribe from '../pages/Unsubscribe.jsx'

// @material-ui/icons
import Fingerprint from '@material-ui/icons/Fingerprint'

const mapPathToRoute = {
  '/unsubscribe': {
    path: '/unsubscribe/:unsubscribeToken',
    name: 'Unsubscribe',
    short: 'Unsubscribe',
    mini: 'US',
    icon: Fingerprint,
    component: Unsubscribe
  }
}

export default mapPathToRoute
