/* Shared module between backend and frontend */

exports.ID = 'id'
exports.FIRST_NAME = 'firstName'
exports.LAST_NAME = 'lastName'
exports.EMAIL = 'email'
exports.ORGANIZATION_IDS = 'organizationIds'
exports.POSITION = 'position'
exports.POLICY_ACCEPTED = 'policyAccepted'
exports.ROLE_NAME = 'roleName'
exports.SITE_IDS = 'siteIds'
exports.DISTRIBUTOR_ID = 'distributorId'
exports.ENTITY_NAME = 'entityName'
exports.ROLE = 'role'
exports.PASSWORD = 'password'
exports.PHONE_NUMBER = 'phoneNumber'
exports.DEFAULT_PAGE = 'defaultPage'
exports.FULL_SCREEN = 'fullScreen'
exports.TWO_FA = 'twoFA'

exports.CURRENT_PASSWORD = 'currentPassword'
exports.NEW_PASSWORD = 'newPassword'

exports.ERROR_TYPE = 'errorType' // property
exports.ERROR_TYPE_INVALID_CREDENTIALS = 'invalidCredentials' // value
exports.ERROR_TYPE_FAILED_STATIC_VALIDATION = 'failedStaticValidation' // value
exports.ERROR_TYPE_NO_DASHBOARD_USER_FOUND = 'noDashboardUserFound' // value
exports.ERROR_TYPE_FAILED_SESSION_MATCHING = 'failedSessionMatching' // value
