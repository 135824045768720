import { IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import 'react-phone-number-input/style.css'
import ImageUploadCard from '../../../components-soapy/ImageUpload/ImageUploadCard.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import { VALIDATION_STATE } from '../constants.js'

const CampaignItemCards = (
  {
    setAsyncImageError,
    asyncImageError,
    items,
    maxCount,
    handleItemChange,
    handleDisplayTimeChange,
    handleAddItem,
    showDisplayTime = false,
    itemType,
    itemName
  }
) => {
  return (
    <GridContainer>
      {
        items.map((item, idx) => {
          const name = `${itemName} ${idx + 1}`
          return (
            <GridItem
              md={4}
              key={name}
            >
              <ImageUploadCard
                setAsyncImageError={setAsyncImageError}
                asyncImageError={asyncImageError}
                title={name}
                error={
                  item[VALIDATION_STATE] === 'error'
                }
                previewImageHeight='56.25%'
                showDisplayTime={showDisplayTime}
                displayTime={showDisplayTime ? item.displayTime : null}
                onChange={(file) => {
                  handleItemChange(file, idx, itemType)
                }}
                onDisplayTimeChange={(displayTime) => {
                  handleDisplayTimeChange(idx, itemType, displayTime)
                }}
                file={item.file}
                src={item.url}
                accept='image/png, image/jpg, image/jpeg'
              />
            </GridItem>
          )
        })
      }

      {
        items.length >= maxCount
          ? null
          : <GridItem
            style={{
              marginTop: 'auto',
              marginBottom: 'auto'
            }}
          >
            <div>
              <IconButton
                color='primary'
                size='medium'
                onClick={() => handleAddItem(itemType)}
              >
                <AddIcon />
              </IconButton>
            </div>
          </GridItem>

      }

      {/* {
          formFields[ITEMS].value.length === 1
            ? null
            : <GridItem>
              <IconButton
                color='secondary'
                size='medium'
                onClick={() => handleRemoveLastItem()}
              >
                <RemoveIcon />
              </IconButton>
              </GridItem>
        } */}

    </GridContainer>
  )
}

export default CampaignItemCards
