import axios from 'axios'
import dashboardUserRequestKeywords from '../../shared/dashboard-user/request-keywords'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints'

export const editDashboardUser = async (
  firstName,
  lastName,
  organizationIds,
  position,
  id,
  roleName,
  phoneNumber,
  distributorId,
  entityName,
  siteIds,
  defaultPage,
  fullScreen,
  twoFA
) => {
  const endpoint = `${apiEndpoints.dashboardUsers}/${id}`
  const URL = SERVER_PATH + endpoint
  const requestBody = {
    [dashboardUserRequestKeywords.FIRST_NAME]: firstName,
    [dashboardUserRequestKeywords.LAST_NAME]: lastName,
    [dashboardUserRequestKeywords.ORGANIZATION_IDS]: typeof (organizationIds) === 'string' ? [organizationIds] : organizationIds.length ? organizationIds : undefined,
    [dashboardUserRequestKeywords.POSITION]: position,
    [dashboardUserRequestKeywords.ROLE_NAME]: roleName,
    [dashboardUserRequestKeywords.PHONE_NUMBER]: phoneNumber,
    [dashboardUserRequestKeywords.DISTRIBUTOR_ID]: distributorId,
    [dashboardUserRequestKeywords.SITE_IDS]: typeof (siteIds) === 'string' ? [siteIds] : siteIds.length ? siteIds : undefined,
    [dashboardUserRequestKeywords.ENTITY_NAME]: entityName,
    [dashboardUserRequestKeywords.DEFAULT_PAGE]: defaultPage,
    [dashboardUserRequestKeywords.FULL_SCREEN]: fullScreen,
    [dashboardUserRequestKeywords.TWO_FA]: twoFA,
  }
  return resolveRequest(axios.put(URL, requestBody, { withCredentials: true }))
}
