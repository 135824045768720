import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export default async (dashboardUserIds, siteId) => {
  const endpoint = `${apiEndpoints.sites}/${siteId}/dashboard-users`
  const URL = SERVER_PATH + endpoint
  const requestBody = {
    dashboardUserIds
  }
  return resolveRequest(axios.post(URL, requestBody, { withCredentials: true }))
}
