import getBottleWashCount from '../../../shared/unit-configuration/getBottleWashCount.js'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import adminUnitModalStyle from '../../../assets-soapy/jss/soapy/views/adminUnitModalStyle.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import addMachine from '../../../endpoint-requests/machine/addMachine.js'
import {
  ADDITIONAL_WATER_TIME,
  AFTER_WASH_DELAY, ATP_MODE, DB_TIME_ZONE,
  DEBUG_HANDS_RECOGNITION, DEBUG_MODE,
  DELAY_TIME_PER_WATER_CYCLE,
  ENABLE_VISUAL_ERROR,
  RECOGNITION,
  HANDS_RECOGNITION, INSTALLATION_DATE, INTERNAL_AREA,
  LATHER_TIME,
  LED_PROGRESS_BAR, MONITOR,
  NUMBER_OF_UNIT,
  PRODUCT_NAME_VERSION, REAGENT,
  REAGENT_BOTTLE_ID,
  REAGENT_NAME,
  SOAP_LOADING_TIME,
  SOAP_TIME,
  SOAP_USE,
  SOAP_WASTE,
  TEMPERATURE_OFFSET,
  TEMPERATURE_SUPPORT,
  TYPE, WATER_CYCLE,
  WATER_TIME_DURING_SOAP,
  WATER_TIME_PER_WATER_CYCLE,
  WATER_USE,
  WATER_WASTE, AUTOMATIC_SOAP_PURGE, MONITOR_ORIENTATION
} from '../constants.js'
import MachineModal from './MachineModal.jsx'

const UnitModal = ({ isOpen, onClose, machinesAdded, classes, t, ...rest }) => {
  const handleSubmit = async (formFields) => {
    const createdMachines = await addMachine({
      type: formFields[TYPE].value,
      reagent: formFields[REAGENT].value,
      dbTimeZone: formFields[DB_TIME_ZONE].value,
      internalArea: formFields[INTERNAL_AREA].value,
      waterUse: formFields[WATER_USE].value,
      waterWaste: formFields[WATER_WASTE].value,
      soapUse: formFields[SOAP_USE].value,
      soapWaste: formFields[SOAP_WASTE].value,
      productName: formFields[PRODUCT_NAME_VERSION].value.split('-')[0],
      version: formFields[PRODUCT_NAME_VERSION].value.split('-')[1],
      soapTime: formFields[SOAP_TIME].value,
      waterTimeDuringSoap: formFields[WATER_TIME_DURING_SOAP].value,
      latherTime: formFields[LATHER_TIME].value,
      waterCycle: formFields[WATER_CYCLE].value,
      waterTimePerWaterCycle: formFields[WATER_TIME_PER_WATER_CYCLE].value,
      delayTimePerWaterCycle: formFields[DELAY_TIME_PER_WATER_CYCLE].value,
      debugMode: formFields[DEBUG_MODE].value,
      soapLoadingTime: formFields[SOAP_LOADING_TIME].value,
      temperatureSupport: formFields[TEMPERATURE_SUPPORT].value,
      monitor: formFields[MONITOR].value,
      reagentBottleId: formFields[REAGENT_BOTTLE_ID].value,
      atpMode: formFields[ATP_MODE].value,
      ledProgressBar: formFields[LED_PROGRESS_BAR].value,
      temperatureOffset: formFields[TEMPERATURE_OFFSET].value,
      enableVisualError: formFields[ENABLE_VISUAL_ERROR].value,
      numberOfUnit: formFields[NUMBER_OF_UNIT].value,
      afterWashDelay: formFields[AFTER_WASH_DELAY].value,
      additionalWaterTime: formFields[ADDITIONAL_WATER_TIME].value,
      reagentName: formFields[REAGENT_NAME].value,
      bottleWashCount: getBottleWashCount(formFields[SOAP_TIME].value, formFields[REAGENT_NAME].value),
      installationDate: formFields[INSTALLATION_DATE].value._d,
      handsRecognition: formFields[HANDS_RECOGNITION].value,
      recognition: formFields[RECOGNITION].value,
      debugHandsRecognition: formFields[DEBUG_HANDS_RECOGNITION].value,
      automaticSoapPurge: formFields[AUTOMATIC_SOAP_PURGE].value,
      monitorOrientation: formFields[MONITOR_ORIENTATION].value,
    })
    machinesAdded(createdMachines)
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('Add machine')}
      onCancel={onClose}
      dialogPaper={classes.dialogPaper}
      hideActions
      showGoBackButton
    >
      <div>
        <MachineModal
          handleSubmit={handleSubmit}
          {...rest}
        />
      </div>
    </Modal>
  )
}

export default withStyles(adminUnitModalStyle)(withTranslation()(UnitModal))
