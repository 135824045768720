import { Tooltip } from '@material-ui/core'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import VpnKey from '@material-ui/icons/VpnKey'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import formStyle from '../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../components-soapy/Card/Card.jsx'
import LoadingButton from '../components-soapy/LoadingButton'
import SweetAlert from '../components-soapy/SweetAlert.jsx'
import CardBody from '../components/Card/CardBody.jsx'
import CardHeader from '../components/Card/CardHeader.jsx'
import CardIcon from '../components/Card/CardIcon.jsx'
import CustomInput from '../components/CustomInput/CustomInput.jsx'
// core components
import GridContainer from '../components/Grid/GridContainer.jsx'
import GridItem from '../components/Grid/GridItem.jsx'
import Snackbar from '../components/Snackbar/Snackbar.jsx'
import withAuthentication from '../containers/Authentication/withAuthentication.js'
import { changePassword } from '../endpoint-requests/dashboard-user/password'
import deleteAllCookies from '../shared/cookie/remove-all-cookies'
import { verifyLength } from '../utils/validators'
import withErrorNotification from '../containers/withErrorNotification'

const ENTER_KEY = 13
const VERIFY_LENGTH_MESSAGE = 'should not be empty, and up to 256 characters'

const CURRENT_PASSWORD = 'currentPassword'
const NEW_PASSWORD = 'newPassword'
const NEW_PASSWORD_CONFIRM = 'newPasswordConfirm'

const VALIDATION_STATE = 'ValidationState'

const fieldNames = [CURRENT_PASSWORD, NEW_PASSWORD, NEW_PASSWORD_CONFIRM]

const mapFieldNameToValidationHandler = {
  [CURRENT_PASSWORD]: text => {
    return verifyLength(text, 1, 100)
  },
  [NEW_PASSWORD]: text => {
    return text.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)
  },
  [NEW_PASSWORD_CONFIRM]: text => {
    return text.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)
  }
}

const mapFieldNameToInvalidFieldNotificationMessage = {
  [CURRENT_PASSWORD]: 'Current password ' + VERIFY_LENGTH_MESSAGE,
  [NEW_PASSWORD]: { passwordError: true},
  [NEW_PASSWORD_CONFIRM]: { passwordError: true}
}

class ChangePassword extends React.Component {
  constructor (props) {
    super(props)
    let fieldsStates = {}
    fieldNames.forEach(fieldName => {
      fieldsStates[fieldName] = ''
      fieldsStates[fieldName + VALIDATION_STATE] = ''
    })

    this.state = {
      ...fieldsStates,
      isSubmitSuccessfulModalActive: false,
      isErrorNotificationActive: false,
      errorNotificationMessage: '',
      isAwaitingResponse: false
    }
  }

  componentDidUpdate () {
    const {
      t
    } = this.props
    this.validationErrors = {
      newPasswordFieldsDontMatch: {
        message: t('New password fields do not match'),
        affectedFieldsNames: [NEW_PASSWORD, NEW_PASSWORD_CONFIRM]
      },
      currentPasswordInvalid: {
        message: t('Current password is invalid'),
        affectedFieldsNames: [CURRENT_PASSWORD]
      },
      unknownError: {
        message: t('Could not complete the request'),
        affectedFieldsNames: []
      }
    }
  }

  validateForm = () => {
    /* Validate individual fields */
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i]
      const fieldValue = this.state[fieldName]
      const isValid = mapFieldNameToValidationHandler[fieldName](fieldValue)
      if (!isValid) {
        return {
          isValid: false,
          error: {
            message: mapFieldNameToInvalidFieldNotificationMessage[fieldName],
            affectedFieldsNames: [this.props.t(fieldName)]
          }
        }
      }
    }
    /* Validate combination fields: newPassword and newPasswordConfirm */
    if (this.state.newPassword !== this.state.newPasswordConfirm) {
      return {
        isValid: false,
        error: this.validationErrors.newPasswordFieldsDontMatch
      }
    }
    return { isValid: true }
  }

  submitForm = async () => {
    const { currentPassword, newPassword } = this.state

    this.activateSpinner()
    const { didSucceed, error } = await changePassword(
      currentPassword,
      newPassword
    )
    this.disableSpinner()
    this.handleResponse(didSucceed, error)
  }

  activateSpinner = () => {
    this.spinnerTimeout = setTimeout(
      function () {
        this.setState({ isAwaitingResponse: true })
      }.bind(this),
      300
    )
  }

  disableSpinner = () => {
    clearTimeout(this.spinnerTimeout)
    this.setState({ isAwaitingResponse: false })
  }

  resetFormState = () => {
    fieldNames.forEach(fieldName => {
      this.setState({
        [fieldName]: '',
        [fieldName + VALIDATION_STATE]: ''
      })
    })
  }

  handleResponse = (didSucceed, error) => {
    if (didSucceed) {
      this.resetFormState()
      this.hideErrorNotification()
      this.showSuccessfulSubmitModal()
    } else if (error['type'] === 'INVALID_CREDENTIALS') {
      this.props.showErrorNotification(
        this.validationErrors.currentPasswordInvalid.message
      )
      this.changeValidationStateToError(
        this.validationErrors.currentPasswordInvalid.affectedFieldsNames
      )
    } else {
      this.props.showErrorNotification(this.validationErrors.unknownError.message)
    }
  }

  changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      this.setState({ [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  showSuccessfulSubmitModal = () => {
    this.setState({
      isSubmitSuccessfulModalActive: true
    })
  }

  hideSuccessfulSubmitModal = () => {

    this.setState({
      isSubmitSuccessfulModalActive: false
    })
    this.logout()
  }

  logout = () => {
    const { history, logout } = this.props
    try {
      deleteAllCookies()
      logout()
      history.push('/login') // Redirect after log-out
    } catch (e) {
      console.log('Logout failed')
    }
  }

  hideErrorNotification = () => {
    this.setState({ isErrorNotificationActive: false })
  }

  processForm = () => {
    const { isValid, error } = this.validateForm()
    if (isValid) {
      this.submitForm()
    } else {
      this.props.showErrorNotification(error.message)
      this.changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  handleTextChange = (event, fieldName) => {
    const text = event.target.value
    this.setState({ [fieldName]: text })
    /* Reset input validation states, useful for combination fields validation */
    fieldNames.forEach(fieldName => {
      this.setState({ [fieldName + VALIDATION_STATE]: '' })
    })
  }

  handleKeyDown = event => {
    if (event.which === ENTER_KEY) {
      this.processForm()
    }
  }

  handleSaveBtnClick = () => {
    this.processForm()
  }

  componentWillUnmount () {
    clearTimeout(this.spinnerTimeout)
    this.spinnerTimeout = null
  }

  render () {
    const { isSubmitSuccessfulModalActive, isAwaitingResponse } = this.state
    const { classes, t } = this.props
    return (
      <GridContainer id="content-pane-layout" justify="center">
        <GridItem md={9}>
          <Card>
            <CardHeader>
              <Tooltip title={t('Change password')}>
                <CardIcon color="info">
                  <VpnKey
                    className={classes.cardHeaderIcon}
                    style={{ fontSize: 30 }}
                  />
                </CardIcon>
              </Tooltip>
            </CardHeader>
            <CardBody>
              <div>
                <form>
                  <GridContainer
                    direction="column"
                    justify="center"
                    alignItems="center"
                    id="card-body-section"
                  >
                    <GridItem
                      style={{ minWidth: '40vh' }}
                      id="current-password-grid-item"
                    >
                      <CustomInput
                        id="currentPasswordInput"
                        error={
                          this.state[CURRENT_PASSWORD + VALIDATION_STATE] ===
                          'error'
                        }
                        labelText={`${t('Current password')} *`}
                        inputProps={{
                          value: this.state[CURRENT_PASSWORD],
                          type: 'password',
                          autoComplete: 'current-password',
                          onChange: event =>
                            this.handleTextChange(event, CURRENT_PASSWORD),
                          onKeyDown: event => this.handleKeyDown(event)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem
                      style={{ minWidth: '40vh' }}
                      id="new-password-grid-item"
                    >
                      <CustomInput
                        id="newPasswordInput"
                        error={
                          this.state[NEW_PASSWORD + VALIDATION_STATE] ===
                          'error'
                        }
                        labelText={`${t('New password')} *`}
                        inputProps={{
                          value: this.state[NEW_PASSWORD],
                          type: 'password',
                          autoComplete: 'new-password',
                          onChange: event =>
                            this.handleTextChange(event, NEW_PASSWORD),
                          onKeyDown: event => this.handleKeyDown(event)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem
                      style={{ minWidth: '40vh' }}
                      id="newPasswordConfirmInput"
                    >
                      <CustomInput
                        id="new-password-confirm-custom-input"
                        error={
                          this.state[
                          NEW_PASSWORD_CONFIRM + VALIDATION_STATE
                            ] === 'error'
                        }
                        labelText={`${t('Confirm new password')} *`}
                        inputProps={{
                          value: this.state[NEW_PASSWORD_CONFIRM],
                          type: 'password',
                          autoComplete: 'new-password',
                          onChange: event =>
                            this.handleTextChange(event, NEW_PASSWORD_CONFIRM),
                          onKeyDown: event => this.handleKeyDown(event)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    justify="center"
                    alignItems="center"
                    id="card-footer-section"
                  >
                    <GridItem>
                      <LoadingButton
                        color="info"
                        onClick={this.handleSaveBtnClick}
                        fullWidth={true}
                        id="changePasswordSaveButton"
                        disabled={isAwaitingResponse}
                        isLoading={isAwaitingResponse}
                      >
                        {t('Save')}
                      </LoadingButton>
                    </GridItem>
                  </GridContainer>


                </form>
                <div id="alerts-section">
                  <Snackbar
                    place="tc"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.errorNotificationMessage}
                    open={this.state.isErrorNotificationActive}
                    closeNotification={() =>
                      this.setState({ isErrorNotificationActive: false })
                    }
                    close
                  />
                  {isSubmitSuccessfulModalActive ? (
                    <SweetAlert
                      success
                      id="changePasswordSuccessAlert"
                      title={<h2 style={{ fontSize: '1rem' }}>{t('Password Changed Text')}</h2>}
                      confirmBtnText={t('Ok')}
                      style={{
                        position: 'absolute'
                      }}
                      onConfirm={() => this.hideSuccessfulSubmitModal()}
                      onCancel={() => this.hideSuccessfulSubmitModal()}
                    />
                  ) : null}
                </div>


              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withRouter(withTranslation()(ChangePassword)))))
