import ExportExcel from '../../components-soapy/Excel/ExportExcel.jsx'
import React from 'react'
import { withTranslation } from 'react-i18next'
import ReactExport from 'react-export-excel'
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const isValidLeftSensorDistance = (distance) => {
  return distance <= 21 && distance >= 2
}

const isValidTopSensorDistance = (distance) => {
  return distance <= 27 && distance >= 3
}

const getTableDatasetExcel = (tableRef) => {
  if (!tableRef || !tableRef.current) {
    return
  }
  const tableData = tableRef.current.getResolvedState().sortedData
  const dataset = []
  for (const row of tableData) {
    dataset.push({
      time: row.time || '',
      bottleId: row.bottleId || '',
      isCardReader: row.isCardReader ? 'v' : 'x',
      isNCSConnected: row.isNCSConnected ? 'v' : 'x',
      cameraStatus: row.cameraStatus ? 'v' : 'x',
      externalCameraStatus: row.externalCameraStatus ? 'v' : 'x',
      bodyTemperature: row.temperature || '',
      cpuTemperature: row.cpuTemperature || '',
      ...(row.reagentDistances && row.reagentDistances.length
        ? {
            usedReagentTime: 1,
            usedReagentLeft: `${row.reagentDistances[0][0]} ${isValidLeftSensorDistance(row.reagentDistances[0][0]) ? 'v' : 'x'}`,
            usedReagentTop: `${row.reagentDistances[0][1]} ${isValidTopSensorDistance(row.reagentDistances[0][1]) ? 'v' : 'x'}`
          }
        : null
      ),
      ...(row.latherDistances && row.latherDistances.length
        ? {
            rubScrubTime: 1,
            rubScrubLeft: `${row.latherDistances[0][0]} ${isValidLeftSensorDistance(row.latherDistances[0][0]) ? 'v' : 'x'}`,
            rubScrubTop: `${row.latherDistances[0][1]} ${isValidTopSensorDistance(row.latherDistances[0][1]) ? 'v' : 'x'}`
          }
        : null
      ),
      ...(row.rinsingDistances && row.rinsingDistances.length
        ? {
            rinsingTime: 1,
            rinsingLeft: `${row.rinsingDistances[0][0]} ${isValidLeftSensorDistance(row.rinsingDistances[0][0]) ? 'v' : 'x'}`,
            rinsingTop: `${row.rinsingDistances[0][1]} ${isValidTopSensorDistance(row.rinsingDistances[0][1]) ? 'v' : 'x'}`
          }
        : null
      )
    })
    const maxIndex = Math.max(row.reagentDistances && row.reagentDistances.length, row.reagentDistances && row.latherDistances.length, row.reagentDistances && row.rinsingDistances.length)
    for (let i = 1; i < maxIndex; i++) {
      dataset.push({
        ...(row.reagentDistances && row.reagentDistances[i]
          ? {
              usedReagentTime: i + 1,
              usedReagentLeft: `${row.reagentDistances[i][0]} ${isValidLeftSensorDistance(row.reagentDistances[i][0]) ? 'v' : 'x'}`,
              usedReagentTop: `${row.reagentDistances[i][1]} ${isValidTopSensorDistance(row.reagentDistances[i][1]) ? 'v' : 'x'}`
            }
          : null
        ),
        ...(row.latherDistances && row.latherDistances[i]
          ? {
              rubScrubTime: i + 1,
              rubScrubLeft: `${row.latherDistances[i][0]} ${isValidLeftSensorDistance(row.latherDistances[i][0]) ? 'v' : 'x'}`,
              rubScrubTop: `${row.latherDistances[i][1]} ${isValidTopSensorDistance(row.latherDistances[i][1]) ? 'v' : 'x'}`
            }
          : null
        ),
        ...(row.rinsingDistances && row.rinsingDistances[i]
          ? {
              rinsingTime: i + 1,
              rinsingLeft: `${row.rinsingDistances[i][0]} ${isValidLeftSensorDistance(row.rinsingDistances[i][0]) ? 'v' : 'x'}`,
              rinsingTop: `${row.rinsingDistances[i][1]} ${isValidTopSensorDistance(row.rinsingDistances[i][1]) ? 'v' : 'x'}`
            }
          : null
        )
      })
    }
  }
  return dataset
}

const StatusTableExport = ({
  tableRef,
  t
}) => {
  const excelHeaders = {
    [t('Time')]: 'time',
    [t('bottleID')]: 'bottleId',
    [t('Card reader')]: 'isCardReader',
    [t('Internal camera connection')]: 'cameraStatus',
    [t('External camera connection')]: 'externalCameraStatus',
    [t('Body temperature')]: 'bodyTemperature',
    [t('CPU temperature')]: 'cpuTemperature',
    [t('NCS')]: 'isNCSConnected',
    [`${t('Soap or sanitizer received﻿')} (${t('Time')}, ${t('S')})`]: 'usedReagentTime',
    [`${t('Soap or sanitizer received﻿')} (${t('Left')}, ${t('SM')})`]: 'usedReagentLeft',
    [`${t('Soap or sanitizer received﻿')} (${t('Top')}, ${t('SM')})`]: 'usedReagentTop',
    [`${t('Rub & Scrub')} (${t('Time')}, ${t('S')})`]: 'rubScrubTime',
    [`${t('Rub & Scrub')} (${t('Left')}, ${t('SM')})`]: 'rubScrubLeft',
    [`${t('Rub & Scrub')} (${t('Top')}, ${t('SM')})`]: 'rubScrubTop',
    [`${t('Rinsing')} (${t('Time')}, ${t('S')})`]: 'rinsingTime',
    [`${t('Rinsing')} (${t('Left')}, ${t('SM')})`]: 'rinsingLeft',
    [`${t('Rinsing')} (${t('Top')}, ${t('SM')})`]: 'rinsingTop'
  }

  const excelColumns = Object.keys(excelHeaders).map(name => {
    return <ExcelColumn key={name} label={name} value={excelHeaders[name]} />
  })
  return (
    <div>
      <ExportExcel
        fileName={'wash-status' + new Date()}
        title={t('Wash Statuses')}
        data={getTableDatasetExcel.bind(null, tableRef)}
        columns={excelColumns}
      />
    </div>
  )
}

export default withTranslation()(StatusTableExport)
