import filterEmptyValues from '../../shared/dashboard/filterEmptyValues.js'
import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * add new machine
 * @param {object} machine
 * @param {string} machine.type
 * @param {string} machine.dbTimeZone
 * @param {string} machine.internalArea
 * @param {string} machine.productName
 * @param {string} machine.version
 * @param {string} machine.waterUse
 * @param {string} machine.waterWaste
 * @param {string} machine.soapUse
 * @param {string} machine.soapWaste
 * @param {string} machine.serialNumber
 * @param {string} machine.soapTime
 * @param {string} machine.waterTimeDuringSoap
 * @param {string} machine.latherTime
 * @param {string} machine.waterCycle
 * @param {string} machine.waterTimePerWaterCycle
 * @param {string} machine.delayTimePerWaterCycle
 * @param {string} machine.installationDate
 * @param {string} machine.debugMode
 * @param {string} machine.enableVisualError
 * @param {string} machine.soapLoadingTime
 * @param {string} machine.afterWashDelay
 * @param {string} machine.additionalWaterTime
 * @param {string} machine.temperatureSupport
 * @param {string} machine.temperatureOffset
 * @param {string} machine.monitor
 * @param {string} machine.reagentBottleId
 * @param {string} machine.ledProgressBar
 * @param {string} machine.reagentName
 * @param {string} machine.bottleWashCount
 * @param {string} machine.handsRecognition
 * @param {string} machine.recognition
 * @param {string} machine.debugHandsRecognition
 * @param {string} machine.atpMode
 * @param {string} machine.timeBetweenSoapAndWater
 */

export default async (machine) => {
  const endpoint = `${apiEndpoints.machines}`
  const URL = SERVER_PATH + endpoint
  return resolveRequest(axios.post(URL, filterEmptyValues(machine), { withCredentials: true }))
}
