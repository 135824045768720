import NotificationSettings from '../pages/Notification-Settings/Container.jsx'
import { CallSplit } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import LockIcon from '@material-ui/icons/Lock'
import ChangePasswordPage from '../pages/ChangePassword.jsx'
import EditProfilePage from '../pages/EditProfile/EditProfileComponent.jsx'
import Version from '../pages/Version/Version.jsx'
import NotificationsIcon from '@material-ui/icons/Notifications'

export default [
  {
    path: '/edit-profile-page',
    title: 'Profile',
    hasMobileView: true,
    name: 'Edit profile',
    component: EditProfilePage,
    requiredPermissions: [],
    layout: '',
    icon: EditIcon,
  },
  {
    path: '/notification-settings',
    title: 'Notification settings',
    hasMobileView: true,
    name: 'Notification settings',
    component: NotificationSettings,
    requiredPermissions: [
      {
        name: 'notification settings',
        action: 'read',
      },
      {
        name: 'notification settings',
        action: 'edit',
      },
    ],
    layout: '',
    icon: NotificationsIcon,
  },
  {
    path: '/change-password-page',
    title: 'Change password',
    hasMobileView: true,
    name: 'Change password',
    component: ChangePasswordPage,
    requiredPermissions: [],
    layout: '',
    icon: LockIcon,
  },
  {
    path: '/version',
    name: 'Version',
    hasMobileView: true,
    component: Version,
    requiredPermissions: [],
    layout: '',
    icon: CallSplit,
  },
]
