exports.COMMUNICATION = 'communication'
exports.USED_SOAP = 'didUseSoap'
exports.DID_LATHER = 'didLather'
exports.LATHERED = 'lathered'
exports.RINSING = 'rinsing'
exports.LEFT_DISTANCE = 'leftDistance'
exports.TOP_DISTANCE = 'topDistance'
exports.DISTANCE = 'distance'
exports.DISTANCES = 'distances'
exports.CAMERA_STATUS = 'cameraStatus'
exports.ATP_IMAGE = 'atpImage'
exports.EXTERNAL_CAMERA_STATUS = 'externalCameraStatus'
exports.TEMPERATURE = 'temperature'
exports.WASH_METRICS = 'washMetrics'
exports.RINSE_ALL_CYCLES_OUTCOMES = 'rinseAllCyclesOutcomes'
exports.BS = 'bs'
exports.DL = 'dl'
exports.R1 = 'r1'
exports.R2 = 'r2'
exports.R3 = 'r3'
exports.R4 = 'r4'
exports.TIME = 'time'
exports.DATE = 'date'
exports.BOTTLE_ID = 'bottleId'
exports.CYCLES = 'Cycles'
exports.REAGENT = 'reagent'
exports.REAGENT_DISTANCES = 'reagentDistances'
exports.LATHER_DISTANCES = 'latherDistances'
exports.RINSING_DISTANCES = 'rinsingDistances'
exports.IS_NCS_CONNECTED = 'isNCSConnected'
exports.IS_CARD_READER = 'isCardReader'
exports.RFID = 'rfid'
exports.CPU_TEMPERATURE = 'cpuTemperature'

