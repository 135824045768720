import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'

/**
 * @param {object} requestBody
 * @param {string} requestBody.permissionName
 * @param {string} requestBody.roleId
 */
const deletePolicy = async (requestBody) => {
  const URL = SERVER_PATH + apiEndpoints.policies

  resolveRequest(axios.delete(URL, {
    withCredentials: true,
    data: requestBody
  }))
}

export default deletePolicy
