import {
  getDashboardUserFullName
} from '../../utils/textFomatters.js'
import getLanguageLocale from '../../shared/multi-language/getLanguageLocale.js'
import {
  Tooltip,
  Tabs,
  Tab,
  Typography
} from '@material-ui/core'
import Remove from '@material-ui/icons/Delete'
import { FileCopy } from '@material-ui/icons'

import Edit from '@material-ui/icons/Edit'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import StorageIcon from '@material-ui/icons/Storage'
import ReactTable from 'react-table'
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getCookie from '../../shared/cookie/get-cookie'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'
import {
  DESCRIPTION, NAME, UPDATE_DATE, SITES, VIEWS, STATUS, UPDATED_BY, ORIENTATION
} from './constants.js'
import _ from 'lodash'
import moment from 'moment'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import StayPrimaryLandscape from '@material-ui/icons/StayPrimaryLandscape'
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait'
import { useDispatch } from 'react-redux'
import { campaignActiveSuccess, campaignMonitorOrientationSuccess, campaignSuccess } from '../../redux/campaignReducer.js'
import CloneCampaignModal from './modals/CloneCampaignModal.jsx'
import fetchCampaigns from '../../endpoint-requests/campaign/fetchCampaigns.js'


const ID = 'id'
const ACTIONS = 'actions'

const DEFAULT_PAGE_SIZE = 10

const convertToTableData = (dispatch,
  setCloneCampaign,
  allCampaigns,
  // campaigns,
  openUpdateCampaignModal,
  openDeleteCampaignModal,
  openArchiveCampaignModal,
  openActiveCampaignLogsModal,
  openUnarchiveCampaignModal,
  t,
  archived,
  hasPermission
) =>
  allCampaigns.reduce((acc, campaign) => {
    if (archived && !campaign.deletedAt) {
      return acc
    }

    if (!archived && campaign.deletedAt) {
      return acc
    }

    acc.push({
      [ID]: campaign.id,
      [DESCRIPTION]: campaign.description,
      [ORIENTATION]: (
        <div>
          {
            hasPermission({ name: 'campaign active logs', action: 'read' })
              ? <div
                id='campaignConfigurationEditBtn'
              >
                {campaign.monitorOrientation === "vertical" ?
                  <StayCurrentPortrait /> :
                  <StayPrimaryLandscape />
                }
              </div>
              : null
          }
        </div>
      ),
      [UPDATE_DATE]: campaign.updatedAt,
      [UPDATED_BY]: getDashboardUserFullName(campaign.updatedBy),
      [SITES]: campaign.sites.sort(site => {
        const isCampaignActive = _.get(site, 'siteConfiguration.isCampaignActive')
        return isCampaignActive ? -1 : 1
      }),
      [STATUS]: campaign.sites.map(site => _.get(site, 'siteConfiguration.isCampaignActive')),
      [NAME]: campaign.name,
      [VIEWS]: (
        <div>
          {
            hasPermission({ name: 'campaign active logs', action: 'read' })
              ? <div
                onClick={() => openActiveCampaignLogsModal(campaign)}
                id='campaignConfigurationEditBtn'
              >
                <Tooltip title={t('Active campaign log')}>
                  <StorageIcon style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
        </div>
      ),
      [ACTIONS]: (
        <div style={{ display: 'flex' }}>
          {
            hasPermission({ name: 'campaigns', action: 'edit' }) && !campaign.deletedAt
              ? <div
                onClick={() => {
                  dispatch(campaignSuccess(campaign))
                  dispatch(campaignActiveSuccess(campaign.sites))
                  openUpdateCampaignModal(campaign)
                  dispatch(campaignMonitorOrientationSuccess(campaign.monitorOrientation))
                }}
                id='campaignConfigurationEditBtn'
              >
                <Tooltip title={t('Edit')}>
                  <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
          {
            hasPermission({ name: 'campaigns', action: 'delete' }) && campaign.deletedAt
              ? <div
                onClick={() => openDeleteCampaignModal(campaign)}
                id='campaignConfigurationDeleteBtn'
              >
                <Tooltip title={t('Delete')}>
                  <Remove style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
          {
            hasPermission({ name: 'campaigns', action: 'delete' }) && !campaign.deletedAt
              ? <div
                onClick={() => openArchiveCampaignModal(campaign)}
                id='campaignConfigurationDeleteBtn'
              >
                <Tooltip title={t('Archive')}>
                  <ArchiveIcon style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
          {
            hasPermission({ name: 'campaigns', action: 'delete' }) && campaign.deletedAt
              ? <div
                onClick={() => openUnarchiveCampaignModal(campaign)}
                id='campaignConfigurationDeleteBtn'
              >
                <Tooltip title={t('Unarchive')}>
                  <UnarchiveIcon style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
          {
            hasPermission({ name: 'campaigns', action: 'edit' }) && !campaign.deletedAt
              ? <div
                onClick={() => {
                  dispatch(campaignSuccess(campaign))

                  setCloneCampaign(true)

                }}
                id='campaignConfigurationEditBtn'
              >
                <Tooltip title={t('Clone')}>
                  <FileCopy style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
        </div>
      )

    })
    return acc
  }, [])
const CampaignsTable = ({
  campaigns,
  openDeleteCampaignModal,
  openUpdateCampaignModal,
  openArchiveCampaignModal,
  openActiveCampaignLogsModal,
  openUnarchiveCampaignModal,
  hasPermission,
  t
}) => {
  const language = getCookie('language')
  const [tabIndex, setTabIndex] = useState(0)
  const dispatch = useDispatch()
  const [allCampaigns, setAllCampaigns] = useState(campaigns)
  const [cloneCampaign, setCloneCampaign] = useState(false)
  const [copyCampaign, setCopyCampaign] = useState(null)
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    const getAllCampaigns = async () => {
      try {
        const res = await fetchCampaigns()
        setAllCampaigns(res)
      } catch (error) {
        console.log(error);
      }
    }
    getAllCampaigns()
    addReactTableFilterPlaceholder()
  }, [language, copyCampaign, campaigns])

  const activeCampaigns = useMemo(() => convertToTableData(dispatch,
    setCloneCampaign,
    allCampaigns,
    // campaigns,
    openUpdateCampaignModal,
    openDeleteCampaignModal,
    openArchiveCampaignModal,
    openActiveCampaignLogsModal,
    openUnarchiveCampaignModal,
    t,
    false,
    hasPermission
  ), [allCampaigns]) // eslint-disable-line react-hooks/exhaustive-deps

  const archivedCampaigns = useMemo(() => convertToTableData(dispatch,
    setCloneCampaign,
    allCampaigns,
    // campaigns,
    openUpdateCampaignModal,
    openDeleteCampaignModal,
    openArchiveCampaignModal,
    openActiveCampaignLogsModal,
    openUnarchiveCampaignModal,
    t,
    true,
    hasPermission
  ), [allCampaigns]) // eslint-disable-line react-hooks/exhaustive-deps

  const campaignTableData = useMemo(() => {
    return tabIndex === 0 ? activeCampaigns : archivedCampaigns
  }, [tabIndex, activeCampaigns, archivedCampaigns])

  return (
    <>
      {cloneCampaign === true &&
        <CloneCampaignModal setCopyCampaign={setCopyCampaign} setLoader={setLoader} loader={loader} cloneCampaign={cloneCampaign} setCloneCampaign={setCloneCampaign} />
      }
      <Tabs
        value={tabIndex}
        onChange={(event, newValue) => {
          setTabIndex(newValue)
        }}
        variant='standard'
        indicatorColor='primary'
        textColor='primary'
      >
        <Tab label={t('General')} />
        <Tab label={t('Archived')} />
      </Tabs>
      {
        campaignTableData.length
          ? <ReactTable
            data={campaignTableData}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            columns={[
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <Typography variant='body2'>{t('Name')}</Typography>
                  </div>
                ),
                style: { textAlign: 'left' },
                accessor: NAME

              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <Typography variant='body2'>{t('Description')}</Typography>
                  </div>
                ),
                style: { textAlign: 'left' },
                accessor: DESCRIPTION

              },
              {
                Header: () => (
                  <>
                    <div
                      style={{
                        textAlign: 'left'

                      }}
                    >
                      <Typography variant='body2'>{t('Orientation')}</Typography>
                    </div>
                  </>
                ),
                style: { textAlign: 'center' },
                accessor: ORIENTATION,
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <Typography variant='body2'>{t('Status')}</Typography>
                  </div>
                ),
                Cell: ({ original }) => {
                  return original.sites.map(site => {
                    return (
                      <div key={site.id}>
                        <FiberManualRecordIcon style={{ color: _.get(site, 'siteConfiguration.isCampaignActive') ? 'green' : 'grey', fontSize: '1rem' }} />
                      </div>
                    )
                  })
                },
                style: { textAlign: 'left' },
                accessor: STATUS,
                filterable: false
              },

              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <Typography variant='body2'>{t('Related sites')}</Typography>
                  </div>
                ),
                Cell: ({ original }) => {
                  return original.sites.map(site => {
                    return (
                      <div key={site.id}>{site.name}
                      </div>
                    )
                  })
                },
                style: { textAlign: 'left' },
                filterMethod: (filter, rows) => {
                  const id = filter.pivotId || filter.id
                  const sites = rows[id]
                  const filterValue = filter.value
                  for (const site of sites) {
                    if (site.name.includes(filterValue)) {
                      return true
                    }
                  }
                  return false
                },
                accessor: SITES
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'
                    }}
                  >
                    <Typography variant='body2'>{t('Update date')}</Typography>
                  </div>
                ),
                Cell: ({ original }) => {
                  return moment(original[UPDATE_DATE]).locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm')
                },
                style: { textAlign: 'left' },
                accessor: UPDATE_DATE,
                sortable: true,
                filterable: true
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'
                    }}
                  >
                    <Typography variant='body2'>{t('Updated by')}</Typography>
                  </div>
                ),

                style: { textAlign: 'left' },
                accessor: UPDATED_BY,
                sortable: true,
                filterable: true
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left',
                      marginLeft: 0
                    }}
                  >
                    <Typography variant='body2'>{t('Views')}</Typography>
                  </div>
                ),
                style: { textAlign: 'left' },
                accessor: VIEWS,
                sortable: false,
                filterable: false
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left',
                      marginLeft: 0
                    }}
                  >
                    <Typography variant='body2'>{t('Actions')}</Typography>
                  </div>
                ),
                style: { textAlign: 'left' },
                accessor: 'actions',
                sortable: false,
                filterable: false
              }

            ]}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            minRows={0}
            showPaginationBottom={
              campaignTableData.length > DEFAULT_PAGE_SIZE
            }
            onPageChange={() => {
              document.getElementById('mainPanel').scrollTop = 0
            }}
            nextText={t('Next')}
            pageText={t('Page')}
            ofText={t('Of')}
            rowsText={t('Rows')}
            previousText={t('Previous')}
            className='-striped -highlight'
          />
          : <h1>{t('No campaigns found')}</h1>
      }

    </>
  )
}

export default withAuthentication(withTranslation()(CampaignsTable))
