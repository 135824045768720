import extendedTablesStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
export default theme => ({
  ...extendedTablesStyle,
  dialogPaper: {
    minHeight: 'calc(70vh + 0)',
    maxHeight: 'calc(70vh + 0)',
    minWidth: 'calc(70vw - 0)',
    maxWidth: 'calc(70vw - 0)'
  }
})
