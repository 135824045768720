import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export default async (options = {
  fetchArchived: false,
  fetchDeleted: false,
  fetchOrphans: false,
  defaultOrganization: false,
  distributorId: null
}
) => {
  const URL = SERVER_PATH + apiEndpoints.machines + `?fetchArchived=${options.fetchArchived}&defaultOrganization=${options.defaultOrganization}&fetchDeleted=${options.fetchDeleted}&fetchOrphans=${options.fetchOrphans}${options.distributorId ? `&distributorId=${options.distributorId}` : ''}`
  return resolveRequest(
    axios.get(URL, {
      withCredentials: true
    })
  )
}
