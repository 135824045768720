import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import dashboardUserModalStyle from '../../../assets-soapy/jss/soapy/views/dashboardUserModalStyle.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import EditDashboardUser from './EditDashboardUser.jsx'

const DashboardUserModal = ({
  dashboardUser,
  isOpen,
  dashboardUserAdded,
  organizations,
  distributors,
  dashboardUserEdited,
  roles,
  sites,
  classes,
  onClose,
  t
					   }) => {
  const title = Object.keys(dashboardUser).length ? t('Edit dashboard user') : t('Add Dashboard User')
  return (
    <Modal
      isOpen={isOpen}
      id='dashboardUsersModal'
      title={title}
      hideActions
      showGoBackButton
      dialogPaper={classes.dialogPaper}
      onCancel={onClose}
      t={t}
    >
      <div>
        <EditDashboardUser
          id='dashboardUsersEditModal'
          isClosed={!isOpen}
          roles={roles}
          dashboardUserEdited={dashboardUserEdited}
          sites={sites}
          distributors={distributors}
          organizations={organizations}
          dashboardUser={dashboardUser}
          dashboardUserAdded={dashboardUserAdded}
          onClose={onClose}
        />
      </div>
    </Modal>
  )
}

export default withStyles(dashboardUserModalStyle)(withTranslation()(DashboardUserModal))
