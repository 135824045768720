import { createSlice } from "@reduxjs/toolkit";

const currentShiftResultsSlice = createSlice({
    name: "currentShiftResults",
    initialState: {
        currentShiftResults: [],
        totalWashCount: 0,
        avgWashScore: 0,
        failedCount: 0,
        currentWashLeft: 0,
        isFetching: false,
        error: false,
    },
    reducers: {
        currentShiftResultsStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentShiftResultsSuccess: (state, action) => {

            state.isFetching = false;

            state.currentShiftResults = action.payload;


        },
        currentShiftResultsFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentWashCountStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentWashCountSuccess: (state, action) => {
            state.isFetching = false;

            state.totalWashCount = action.payload;


        },
        currentWashCountFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentAvgWashScoreStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentAvgWashScoreSuccess: (state, action) => {
            state.isFetching = false;

            state.avgWashScore = action.payload;


        },
        currentAvgWashScoreFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },

        currentFailedCountStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentFailedCountSuccess: (state, action) => {
            state.isFetching = false;

            state.failedCount = action.payload;


        },
        currentFailedCountFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentWashLeftSuccess: (state, action) => {
            state.isFetching = false;

            if ((action.payload.minWashCount - action.payload.currentTotalWashCount) < 0) {
                state.currentWashLeft = 0
            } else {
                state.currentWashLeft = Math.abs(action.payload.minWashCount - action.payload.currentTotalWashCount);

            }

        },
        currentWashLeftSuccessAuto: (state, action) => {
            state.isFetching = false;

            if ((action.payload.minWashCnt - action.payload.washCount) < 0) {
                state.currentWashLeft = 0
            } else {
                state.currentWashLeft = Math.abs(action.payload.minWashCnt - action.payload.washCount);

            }

        },
        currentShiftLogoutSuccess: (state, action) => {
            state.isFetching = false;
            state.currentShiftResults = []
            state.totalWashCount = 0
            state.avgWashScore = 0
            state.failedCount = 0
            state.currentWashLeft = 0
            //no action required...u can remove action
        },

    },
});

export const {
    currentShiftResultsStart,
    currentShiftResultsSuccess,
    currentShiftResultsFailure,
    currentWashCountStart,
    currentWashCountSuccess,
    currentWashCountFailure,
    currentAvgWashScoreStart,
    currentAvgWashScoreSuccess,
    currentAvgWashScoreFailure,

    currentFailedCountStart,
    currentFailedCountSuccess,
    currentFailedCountFailure,
    currentWashLeftSuccess,
    currentShiftLogoutSuccess,
    currentWashLeftSuccessAuto
} = currentShiftResultsSlice.actions;


const currentShiftResultsReducer = currentShiftResultsSlice.reducer; //it should be export default
export default currentShiftResultsReducer;