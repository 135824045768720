import UnitConfigurationTableExport from './UnitConfigurationTableExport.jsx'
import React from 'react'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import { withTranslation } from 'react-i18next'

const isSelected = (obj, count = 1) => {
  let numberOfSelected = 0
  for (const key in obj) {
    if (obj[key]) {
      numberOfSelected++
    }
    if (numberOfSelected === count) return true
  }
  return false
}

const UnitConfigurationsTableHeader = ({
  hasPermission,
  selected,
  tableRef,
  openEditMachineModal,
  openBulkEditMachineModal,
  machinesExist,
  t
}) => {
  return (
    <div style={{ display: 'flex' }}>
      {
        hasPermission({ name: 'machines', action: 'create' })
          ? <Button
              color='info'
              round
              onClick={openEditMachineModal}
              style={{ marginRight: 5 }}
              id='adminUnitAddUnitButton'
            >
            {t('Add unit')}
            </Button>
          : null
      }
      {
        machinesExist && hasPermission({ name: 'machines', action: 'edit' })
          ? <Button
              color='info'
              round
              id='adminUnitBulkEditButton'
              disabled={!isSelected(selected, 2)}
              onClick={openBulkEditMachineModal}
              style={{ marginRight: 5 }}
            >
            {t('Bulk edit')}
            </Button>
          : null
      }
      {
        machinesExist
          ? <UnitConfigurationTableExport tableRef={tableRef} />
          : null
      }
    </div>
  )
}

export default withTranslation()(withAuthentication(UnitConfigurationsTableHeader))
