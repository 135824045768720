import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';
import AddAlert from '@material-ui/icons/AddAlert';
import GridContainer from '../.././components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Snackbar from '../../components/Snackbar/Snackbar.jsx';
import Card from '../../components-soapy/Card/Card.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import validationFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import sweetAlertStyle from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import withAuthentication from '../../containers/Authentication/withAuthentication.js';
import LoadingButton from '../../components-soapy/LoadingButton';
import fetchReagentSuppliers from "../../endpoint-requests/reagent-suppliers/fetchReagentSuppliers";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../../components/CustomButtons/Button";
import getRowsFromExcelFile from "../../shared/export/readExcelFile";
import soapDispenserIcon from "../../assets/img/soap-for-hands.svg";
import createRegentBottles from "../../endpoint-requests/reagent-bottles/createReagentBottles";
import {withTranslation} from 'react-i18next'
import _ from 'lodash'
import {
    Tooltip
} from '@material-ui/core'


const formStyle = {
    ...validationFormsStyle,
    cardHeaderIcon: {
        color: '#ffffff'
    }
};

const SUPPLIER_ID = 'reagentSupplierId'


const CustomSweetAlert = ({classes, ...rest}) => (
    <SweetAlert
        {...rest}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
    />
);

const StyledSweetAlert = withStyles(sweetAlertStyle)(CustomSweetAlert);



const styles = {
    inputLabelStyle: {
        color: "#aaaaaa",
        fontSize: '14px',
        fontWeight: 400
    },
    selectStyle: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#495057"
    }
}

function validateDuplicateIds(bottleIds) {
    const duplicateIds = _.filter(bottleIds, (val, i, iteratee) => _.includes(iteratee, val, i + 1))
    return _.uniq(duplicateIds)
}

function validateBottleIds(bottleIds) {
    for (let id of bottleIds) {
        if (!id || id.length !== 8) {
            return false
        }
    }
    return true
}

class AdminSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSubmitSuccessfulModalActive: false,
            isErrorNotificationActive: false,
            errorNotificationMessage: '',
            isAwaitingResponse: true
        };
    }

    async componentDidMount() {
        const {data: suppliers} = await fetchReagentSuppliers();

        if (suppliers) {
            this.setState({
                suppliers,
                isAwaitingResponse: false,
            })
        }
    }

    componentDidUpdate(){
        const {
            t
        } = this.props
        this.validationErrors = {

            unknownError: {
                message: t("Could not complete the request"),
                affectedFieldsNames: []
            },
            excel: {
                message: t("Excel file is not good formatted"),
                duplicateIds: t("There are duplicate bottle IDs"),
            }
        };
    }

    submitForm = async () => {
        const {
            reagentSupplierId,
            bottleIds
        } = this.state;

        const isGoodFormatted = validateBottleIds(bottleIds)

        if (isGoodFormatted) {
            const duplicateIds = validateDuplicateIds(bottleIds)

            if (!duplicateIds.length) {
                this.activateSpinner();
                const {didSucceed, existingBottleIds} = await createRegentBottles(reagentSupplierId, bottleIds);
                this.disableSpinner();
                this.handleResponse(didSucceed, existingBottleIds);
            } else {
                this.bottleIdErrors(duplicateIds, 'duplicate')
            }
        } else {
            this.showErrorNotification(this.validationErrors.excel.message)
        }

    };

    activateSpinner = () => {
        this.spinnerTimeout = setTimeout(
            function () {
                this.setState({isAwaitingResponse: true});
            }.bind(this),
            300
        );
    };

    disableSpinner = () => {
        clearTimeout(this.spinnerTimeout);
        this.setState({isAwaitingResponse: false});
    };

    resetFormState = async () => {
        const {data} = await fetchReagentSuppliers();
        if (data) {
            const {dashboardServerUrl, faceRecognitionServerUrl, updateParametersTime} = data;
            this.setState({
                dashboardServerUrl,
                faceRecognitionServerUrl,
                updateParametersTime
            })
        }
    };

    bottleIdErrors = (bottleIds, type = 'exists') => {
        let errorText = ''
        const showCount = 20
        const bottleIdCount = bottleIds.length
        let idName = 'ID'
        if (bottleIdCount > 1) {
            idName = 'IDs'
        }
        let andMoreText = ''
        const hideBottleIdCount = bottleIdCount - showCount

        if (hideBottleIdCount > 0) {
            bottleIds = bottleIds.slice(0, showCount)
            andMoreText = `and more ${hideBottleIdCount}`
        }

        let bottleIdsText = bottleIds.join(', ')

        if (type === 'duplicate') {
            errorText = `Duplicate bottle ${idName}: ${bottleIdsText} ${andMoreText}`
        } else {
            errorText = `Bottle ${idName} already exists: ${bottleIdsText} ${andMoreText}`
        }

        this.showErrorNotification(errorText);
    }

    handleResponse = (didSucceed, existingBottleIds) => {
        if (!didSucceed) {
            this.resetFormState();
            let errorText = this.validationErrors.unknownError.message

            if (existingBottleIds.length) {
                this.bottleIdErrors(existingBottleIds)
            } else {
                this.showErrorNotification(errorText);
            }

        } else {
            this.setState({
                isSubmitSuccessfulModalActive: true
            })
        }

        this.setState({
            reagentSupplierId: '',
            bottleIds: [],
            fileName: null
        })
    };


    hideSuccessfulSubmitModal = () => {
        this.setState({
            isSubmitSuccessfulModalActive: false
        });
    };

    showErrorNotification = errorMessage => {
        const {isErrorNotificationActive} = this.state;
        if (!isErrorNotificationActive) {
            this.setState({
                isErrorNotificationActive: true,
                errorNotificationMessage: this.props.t(errorMessage)
            });
        }
    };


    handleTextChange = (event, fieldName) => {
        const text = event.target.value;
        this.setState({[fieldName]: text});
    };

    async onChangeFile(event) {
        try {
            event.stopPropagation();
            event.preventDefault();
            const file = event.target.files[0];
            const rows = await getRowsFromExcelFile(file)
            const fileName = file.name

            const bottleIds = rows.map(row => row[0])

            this.setState({
                bottleIds,
                fileName,
                isExcelGoodFormatted: true
            });

        } catch (e) {
            this.showErrorNotification(this.validationErrors.excel)
        }
    }

    componentWillUnmount() {
        clearTimeout(this.spinnerTimeout);
        this.spinnerTimeout = null;
    }

    render() {
        const {
            isSubmitSuccessfulModalActive,
            isAwaitingResponse,
            reagentSupplierId,
            isExcelGoodFormatted,
            fileName,
            suppliers
        } = this.state;

        const isSaveButtonEnabled = reagentSupplierId && isExcelGoodFormatted

        const {classes,t} = this.props;

        const menuItemClasses = {
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
        }

        return (
            <GridContainer id="content-pane-layout" justify="center">
                {isAwaitingResponse ?
                    <CircularProgress
                        color="primary"
                        style={{position: 'absolute', top: '50%', left: '50%'}}
                    /> :
                    <GridItem md={9}>
                        <Card>
                            <CardHeader>
                                <Tooltip title={t('Bottles')}>
                                <CardIcon color="info">
                                    <img
                                        src={soapDispenserIcon}
                                        style={{width: '2em', height: '2em'}}
                                        alt="soapDispenser"
                                    />
                                </CardIcon>
                                </Tooltip>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <form>
                                        <GridContainer
                                            direction="column"
                                            justify="center"
                                            alignItems="center"
                                            id="card-body-section"
                                        >
                                            <GridItem style={{minWidth: '40vh'}}>
                                                <FormControl
                                                    fullWidth
                                                >
                                                    <InputLabel style={styles.inputLabelStyle}
                                                    >
                                                        {t('Supplier')}
                                                    </InputLabel>
                                                    <Select
                                                        id='bottlesSupplier'
                                                        style={styles.selectStyle}
                                                        MenuProps={{className: classes.selectMenu}}
                                                        value={reagentSupplierId || ''}
                                                        inputProps={{
                                                            onChange: event => this.handleTextChange(event, SUPPLIER_ID),
                                                            onKeyDown: event => this.handleKeyDown(event)
                                                        }}
                                                    >
                                                        {
                                                            suppliers.map(item => {
                                                                return <MenuItem
                                                                    classes={menuItemClasses}
                                                                    value={item.id}
                                                                >
                                                                    {`${item.supplierName}, ${item.name}, ${item.type}`}
                                                                </MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <Button
                                                variant="contained"
                                                component="label"
                                                id='bottlesUploadFile'
                                                color="info"
                                                style={{
                                                    minWidth: '36vh',
                                                    margin: '14px'
                                                }}
                                            >
                                                {fileName || t('Upload file')}
                                                <input id="myInput"
                                                       type="file"
                                                       accept=".xlsx"
                                                       ref={(ref) => this.upload = ref}
                                                       style={{display: 'none'}}
                                                       onChange={this.onChangeFile.bind(this)}
                                                />
                                            </Button>
                                        </GridContainer>
                                        <GridContainer
                                            justify="center"
                                            alignItems="center"
                                            id="card-footer-section"
                                        >
                                            <GridItem>
                                                <LoadingButton
                                                    id='bottlesSaveButton'
                                                    color="info"
                                                    onClick={this.submitForm}
                                                    fullWidth={true}
                                                    disabled={!isSaveButtonEnabled}
                                                    isLoading={isAwaitingResponse}
                                                >
                                                    {t('Save')}
                                                </LoadingButton>
                                            </GridItem>
                                        </GridContainer>
                                    </form>
                                    <div id="alerts-section">
                                        <Snackbar
                                            id='bottlesErrorAlert'
                                            place="tc"
                                            color="danger"
                                            icon={AddAlert}
                                            message={this.state.errorNotificationMessage}
                                            open={this.state.isErrorNotificationActive}
                                            closeNotification={() =>
                                                this.setState({isErrorNotificationActive: false})
                                            }
                                            close
                                        />
                                        {isSubmitSuccessfulModalActive ? (
                                            <StyledSweetAlert
                                                id='bottlesSavedAlert'
                                                success
                                                title={t("Saved")+'!'}
                                                confirmBtnText={t("Ok")}
                                                style={{
                                                    position: 'absolute'
                                                }}
                                                onConfirm={() => this.hideSuccessfulSubmitModal()}
                                                onCancel={() => this.hideSuccessfulSubmitModal()}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>}
            </GridContainer>
        );
    }
}

AdminSettings.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withAuthentication(withStyles(formStyle)(withTranslation()(AdminSettings)));
