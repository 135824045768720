import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const fetchCampaigns = async () => {
  const URL = SERVER_PATH + apiEndpoints.campaigns
  return resolveRequest(axios.get(URL, { withCredentials: true }))
}

export default fetchCampaigns
