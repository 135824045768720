import Snackbar from '../components/Snackbar/Snackbar.jsx'
import AddAlert from '@material-ui/icons/AddAlert'
import i18n from 'i18next'
import React, { useEffect, useState } from 'react'
import { CardContent, IconButton } from '@material-ui/core'
import Card from '../components-soapy/Card/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'
import { passwordError } from '../shared/passwordError'

const withErrorSnackbar = (Component) => {
  const useStyles = makeStyles({
    root: {
      display: 'inline-block',
      position: 'absolute',
      maxWidth: 250,
      zIndex: 1000,
      top: 12,
      left: '40%',
      padding: '10px',
      backgroundColor: '#f55a4e',
      color: 'white'
    },
    title: {
      fontSize: 14,
    },
    closeButton: {
      right: 0,
      top: 0,
      display: 'inline-block',
      position: 'absolute',
      color: 'white'
    }
  })

  let errorNotificationTimeout = null
  return (props) => {
    const [isErrorNotificationActive, setIsErrorNotificationActive] = useState(false)
    const [errorNotificationMessage, setErrorNotificationMessage] = useState(null)
    const [isInfoMessage, setIsInfoMessage] = useState(false)
    const [isPasswordError, setIsPasswordError] = useState(false)
    const t = i18n.t.bind(i18n)

    const classes = useStyles()

    const showErrorNotification = (message, isInfo = false) => {
      if (message.passwordError !== undefined) {
        setIsPasswordError(true)
      } else {
        setIsPasswordError(false)
      }
      if (isInfo) {
        setIsInfoMessage(true)
      } else {
        setIsInfoMessage(false)
      }
      clearTimeout(errorNotificationTimeout)
      setIsErrorNotificationActive(true)
      setErrorNotificationMessage(t(message))
      errorNotificationTimeout = setTimeout(
        function () {
          setIsErrorNotificationActive(false)
        },
        6000
      )
    }

    useEffect(() => {
      return () => {
        clearTimeout(errorNotificationTimeout)
        setIsErrorNotificationActive(false)
        errorNotificationTimeout = null
      }
    }, [])
    return (
      <>
        {!isPasswordError ?
          <Snackbar
            place="tc"
            id="errorSnackbar"
            color={isInfoMessage ? 'info' : 'danger'}
            icon={AddAlert}
            message={errorNotificationMessage}
            open={isErrorNotificationActive}
            closeNotification={() => setIsErrorNotificationActive(false)}
            close
          /> :
          <div>
            {isErrorNotificationActive ?
              <Card className={classes.root} style={{ position: 'fixed' }}>
                <IconButton
                  className={classes.closeButton}
                  aria-label="settings"
                  onClick={() => setIsErrorNotificationActive(false)}
                >
                  <Close/>
                </IconButton>
                <CardContent>
                  {passwordError}
                </CardContent>
              </Card> : null}
          </div>}
        <Component {...props} showErrorNotification={showErrorNotification}/>

      </>
    )
  }
}

export default withErrorSnackbar
