import validateFormFields from '../../utils/validateFormFields.js'
import useFormFields from '../../shared/hooks/useFormFields.js'
import withErrorNotification from '../../containers/withErrorNotification.js'
import { InputBase, Slider, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import unitConfigurationModalStyle
  from '../../assets/jss/material-dashboard-pro-react/views/unitConfigurationModalStyle.jsx'
import Modal from './Modal'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import updateMachineWashConfiguration from '../../endpoint-requests/machine/updateMachineWashConfiguration'
import {
  ADDITIONAL_WATER_TIME,
  AFTER_WASH_DELAY,
  ID,
  INTERNAL_AREA,
  LATHER_TIME,
  REAGENT,
  SOAP_TIME,
  TRIGGER_SOAP,
  WATER_TIME_DURING_SOAP,
  WATER_TIME_PER_WATER_CYCLE
} from './constants'
import { setTrigger } from '../../endpoint-requests/machine/setTrigger'
import LoadingButton from '../../components-soapy/LoadingButton'
import moment from 'moment'

const ONE_OZ_TO_ML = 29.754
const DISABLE_PRIME_BUTTON_TIME = 10
const infoIcon = <InfoOutlined style={{ color: '#666666' }}/>

let spinnerTimeout = null

const UnitConfigurationModal = ({
  machine,
  t,
  isOpen,
  showErrorNotification,
  classes,
  closeModal,
  onApplyConfigurationModal
}) => {
  const { formFields, setFormFields } = useFormFields({
    [ADDITIONAL_WATER_TIME]: {
      value: 0,
      validators: [
        {
          isValid: (value) => {
            return value >= 0 && value <= 10
          },
          message: 'Extra rinsing should be between 0 and 10'
        }
      ]
    },
    [AFTER_WASH_DELAY]: {
      value: 0,
      validators: [
        {
          isValid: (value) => {
            return value >= 0 && value <= 5
          },
          message: 'After wash delay should be between 0 and 5'
        }
      ]
    },
    [LATHER_TIME]: {
      value: 0,
      validators: [
        {
          isValid: (value) => {
            return value >= 0 && value <= 60
          },
          message: 'Rub & Scrub time should be between 0 and 60'
        }
      ]
    },
    [SOAP_TIME]: {
      value: 0.1,
      validators: [
        {
          isValid: (value) => {
            return value >= 0 && value <= 1
          },
          message: 'Soap or sanitizer time should be between 0 and 1'
        }
      ]
    },
    [WATER_TIME_DURING_SOAP]: {
      value: 0.1,
      validators: [
        {
          isValid: (value) => {
            return value >= 0 && value <= 1
          },
          message: 'Water time during soap should be between 0 and 1'
        }
      ]
    },
    [INTERNAL_AREA]: {
      value: ''
    },
    [WATER_TIME_PER_WATER_CYCLE]: {
      value: 10,
      validators: [
        {
          isValid: (value) => {
            return value >= 0 && value <= 60
          },
          message: 'Rinsing time should be between 0 and 60'
        }
      ]
    },
    [REAGENT]: {
      value: 'soap'
    },
    [TRIGGER_SOAP]: {
      value: false
    }
  })
  const [measurement, setMeasurement] = useState('imperial')
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [isAwaitingTriggerResponse, setIsAwaitingTriggerResponse] = useState(false)

  const convertVolumeToText = (result) => {
    if (measurement === 'imperial') {
      const volume = Math.floor(result / ONE_OZ_TO_ML * 100) / 100
      return `(${volume > 0 ? volume : 0} oz)`
    }
    const volume = Math.floor(result * 100) / 100
    return `(${volume > 0 ? volume : 0} ml)`
  }

  useEffect(() => {
    setFormFields(
      (formFields) => ({
        [ADDITIONAL_WATER_TIME]: {
          ...formFields[ADDITIONAL_WATER_TIME],
          value: machine[ADDITIONAL_WATER_TIME] || 0
        },
        [INTERNAL_AREA]: {
          ...formFields[INTERNAL_AREA],
          value: machine[INTERNAL_AREA] || ''
        },
        [AFTER_WASH_DELAY]: {
          ...formFields[AFTER_WASH_DELAY],
          value: machine[AFTER_WASH_DELAY] || 0
        },
        [LATHER_TIME]: {
          ...formFields[LATHER_TIME],
          value: machine[LATHER_TIME] || 0
        },
        [SOAP_TIME]: {
          ...formFields[SOAP_TIME],
          value: machine[SOAP_TIME] || 0
        },
        [WATER_TIME_DURING_SOAP]: {
          ...formFields[WATER_TIME_DURING_SOAP],
          value: machine[WATER_TIME_DURING_SOAP] || 0
        },
        [WATER_TIME_PER_WATER_CYCLE]: {
          ...formFields[WATER_TIME_PER_WATER_CYCLE],
          value: machine[WATER_TIME_PER_WATER_CYCLE] || 0
        },
        [REAGENT]: {
          ...formFields[REAGENT],
          value: machine[REAGENT] || 'soap'
        },
        [TRIGGER_SOAP]: {
          ...formFields[TRIGGER_SOAP],
          value: machine[TRIGGER_SOAP] || false
        }
      })
    )
  }, [machine]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      disableSpinner()
    }
  }, [])

  const handleValueChange = (value, fieldName) => {
    setFormFields({
      ...formFields,
      [fieldName]: {
        ...formFields[fieldName],
        value
      }
    })
  }

  const handleTextChange = (event, fieldName) => {
    setFormFields({
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value: event.target.value }
    })
  }

  const handlePurgeSoap = async (machine) => {
    handleValueChange(true, TRIGGER_SOAP)
    setIsAwaitingTriggerResponse(true)
    const body = {
      id: machine.id,
      soap: true
    }
    await setTrigger(body)
    machine[TRIGGER_SOAP] = true
    onApplyConfigurationModal(machine)
    setIsAwaitingTriggerResponse(false)
  }
  const reagentTimeVolume = () => {

    const { reagentName, reagent } = machine
    let result

    if (reagent === 'sanitizer') {
      result = 11.1 * formFields[SOAP_TIME].value - 0.533
      return convertVolumeToText(result)
    }

    if (reagentName === 'soapyLore') {
      result = 4 * formFields[SOAP_TIME].value - 0.0429
      return convertVolumeToText(result)
    }

    result = 5.43 * formFields[SOAP_TIME].value - 0.0429
    return convertVolumeToText(result)
  }
  const waterTimeDuringSoapVolume = () => {
    const result = formFields[WATER_TIME_DURING_SOAP].value * 10
    return convertVolumeToText(result)
  }
  const waterTimePerWaterCycleVolume = () => {
    const result = formFields[WATER_TIME_PER_WATER_CYCLE].value * 10
    return convertVolumeToText(result)
  }
  const additionalWaterVolume = () => {
    const result = formFields[ADDITIONAL_WATER_TIME].value * 10
    return convertVolumeToText(result)
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const processForm = async (onApplyConfigurationModal) => {
    activateSpinner()
    const { error } = await validateFormFields(formFields)
    if (error) {
      return showErrorNotification(error.message)
    }
    try {
      const editedMachine = await updateMachineWashConfiguration(machine[ID], {
        additionalWaterTime: formFields[ADDITIONAL_WATER_TIME].value,
        afterWashDelay: formFields[AFTER_WASH_DELAY].value,
        latherTime: formFields[LATHER_TIME].value,
        soapTime: formFields[SOAP_TIME].value,
        internalArea: formFields[INTERNAL_AREA].value,
        waterTimeDuringSoap: formFields[WATER_TIME_DURING_SOAP].value,
        waterTimePerWaterCycle: formFields[WATER_TIME_PER_WATER_CYCLE].value
      })
      disableSpinner()
      onApplyConfigurationModal(editedMachine)
    } catch (err) {
      showErrorNotification(err.message)
      disableSpinner()
    }
  }

  function isPrime () {
    let triggerTime = formFields[TRIGGER_SOAP].value
    if (!triggerTime) {
      return false
    } else if (triggerTime === true) {
      return true
    }
    let disableTime = moment(triggerTime).add(DISABLE_PRIME_BUTTON_TIME, 'minutes')
    return moment() < disableTime
  }

  return (
    <Modal
      isOpen={isOpen}
      title=""
      isLoading={isAwaitingResponse}
      onCancel={closeModal}
    >
      <div style={{ height: '500px' }}>
        <ToggleButtonGroup
          value={measurement}
          exclusive
          onChange={(e, value) => {
            setMeasurement(value)
          }}
          aria-label="text unitValue"
        >
          <ToggleButton id="unitConfImperialBtn" value="imperial" aria-label="left aligned">
            Imperial
          </ToggleButton>
          <ToggleButton id="unitConfMetricBtn" value="metric" aria-label="centered">
            Metric
          </ToggleButton>
        </ToggleButtonGroup>
        <Grid className={classes.gridRow} container spacing={3}>
          <Grid item xs={3}>
            {t('Soap or sanitizer time')}
          </Grid>
          <Grid item xs>
            <Tooltip title={t('Reagent dispensing duration. ' +
              'See volume measurement.')}
            >
              {infoIcon}
            </Tooltip>
          </Grid>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs>
                <Slider
                  value={formFields[SOAP_TIME].value}
                  id="unitConfReagentTimeSlider"
                  onChange={(e, value) => handleValueChange(value, SOAP_TIME)}
                  step={0.05}
                  min={0}
                  max={1}
                  valueLabelDisplay="on"
                />
              </Grid>
              {t('Sec')}
            </Grid>
          </Grid>
          <Grid item xs>
            <b>{reagentTimeVolume()}</b>
          </Grid>
        </Grid>
        {formFields[REAGENT].value === 'soap'
          ? <Grid className={classes.gridRow} container spacing={3}>
            <Grid item xs={3}>
              {t('Water time during soap')}
            </Grid>
            <Grid item xs>
              <Tooltip title={t('Water for Rub & Scrub dispensing duration. ' +
                'See volume measurement.\n')}
              >
                {infoIcon}
              </Tooltip>
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs>
                  <Slider
                    value={formFields[WATER_TIME_DURING_SOAP].value}
                    id="unitConfWaterTimeReagentSlider"
                    onChange={(e, value) => handleValueChange(value, WATER_TIME_DURING_SOAP)}
                    step={0.05}
                    min={0}
                    max={1}
                    valueLabelDisplay="on"
                  />
                </Grid>
                {t('Sec')}
              </Grid>
            </Grid>
            <Grid item xs>
              <b>{waterTimeDuringSoapVolume()}</b>
            </Grid>
          </Grid>
          : null}
        <Grid className={classes.gridRow} container spacing={3}>
          <Grid item xs={3}>
            {t('Rub & Scrub time')}
          </Grid>
          <Grid item xs>
            <Tooltip title={t('Rub & Scrub duration. \n' +
              'No water dispensed while Rub & Scrub.\n')}
            >
              {infoIcon}
            </Tooltip>
          </Grid>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs>
                <Slider
                  value={formFields[LATHER_TIME].value}
                  id="unitConfWaterTimeSoapSlider"
                  onChange={(e, value) => handleValueChange(value, LATHER_TIME)}
                  step={5}
                  min={0}
                  max={60}
                  valueLabelDisplay="on"
                />
              </Grid>
              {t('Sec')}
            </Grid>
          </Grid>
          <Grid item xs/>
        </Grid>
        {formFields[REAGENT].value === 'soap'
          ? <Grid className={classes.gridRow} container spacing={3}>
            <Grid item xs={3}>
              {t('Rinsing time')}
            </Grid>
            <Grid item xs>
              <Tooltip title={t('Water shower duration for rinsing soap from hands')}>
                {infoIcon}
              </Tooltip>
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs>
                  <Slider
                    value={formFields[WATER_TIME_PER_WATER_CYCLE].value}
                    onChange={(e, value) => handleValueChange(value, WATER_TIME_PER_WATER_CYCLE)}
                    step={5}
                    id="unitConfRinsingSlider"
                    min={0}
                    max={60}
                    valueLabelDisplay="on"
                  />
                </Grid>
                {t('Sec')}
              </Grid>
            </Grid>
            <Grid item xs>
              <b>{waterTimePerWaterCycleVolume()}</b>
            </Grid>
          </Grid>
          : null}
        {formFields[REAGENT].value === 'soap'
          ? <Grid className={classes.gridRow} container spacing={3}>
            <Grid item xs={3}>
              {t('Extra rinsing')}
            </Grid>
            <Grid item xs>
              <Tooltip title={t('Additional water shower after initial pause.\n' +
                'Triggered when hands remain within the station.')}
              >
                {infoIcon}
              </Tooltip>
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs>
                  <Slider
                    value={formFields[ADDITIONAL_WATER_TIME].value}
                    id="unitConfExtraRinsingSlider"
                    onChange={(e, value) => handleValueChange(value, ADDITIONAL_WATER_TIME)}
                    step={1}
                    min={0}
                    max={10}
                    valueLabelDisplay="on"
                  />
                </Grid>
                {t('Sec')}
              </Grid>
            </Grid>
            <Grid item xs>
              <b>{additionalWaterVolume()}</b>
            </Grid>
          </Grid>
          : null}
        <Grid className={classes.gridRow} container spacing={3}>
          <Grid item xs={3}>
            {t('After wash delay')}
          </Grid>
          <Grid item xs>
            <Tooltip title={t('Delay between end of one wash cycle and the start of the next cycle')}>
              {infoIcon}
            </Tooltip>
          </Grid>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs>
                <Slider
                  value={formFields[AFTER_WASH_DELAY].value}
                  id="unitConfAfterWashDelaySlider"
                  onChange={(e, value) => handleValueChange(value, AFTER_WASH_DELAY)}
                  step={1}
                  min={0}
                  max={5}
                  valueLabelDisplay="on"
                />
              </Grid>
              {t('Sec')}
            </Grid>
          </Grid>
          <Grid item xs/>
        </Grid>
        <Grid className={classes.gridRow} container spacing={3}>
          <Grid item xs={3}>
            {t('Internal area')}
          </Grid>
          <Grid item xs/>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs>
                <InputBase
                  id="description"
                  fullWidth
                  inputProps={{
                    className: classes.input
                  }}
                  value={formFields[INTERNAL_AREA].value}
                  onChange={event => {
                    handleTextChange(event, INTERNAL_AREA)
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs/>
        </Grid>
        <Grid className={classes.gridRow} container spacing={3}>
          <Grid item xs={3}/>
          <Grid item xs/>
          <Grid item xs={5} style={{ padding: 0 }}>
            <Grid container>
              <Grid item xs>
                <LoadingButton
                  color="info"
                  id="saveButtonId"
                  disabled={isAwaitingResponse}
                  onClick={() => processForm(onApplyConfigurationModal)}
                  isLoading={isAwaitingResponse}
                >
                  {t('Save')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs/>
        </Grid>
        <hr/>
        <Grid className={classes.gridRow} container spacing={3}>
          <Grid item xs={3}>
            {t('Prime soap or sanitizer')}
          </Grid>
          <Grid item xs>
            <Tooltip title={t('Prime soap or sanitizer button will send a prime order to CleanMachine, once pressed.' +
              ' It will take up to 5 minutes for the order to be executed.')}
            >
              {infoIcon}
            </Tooltip>
          </Grid>
          <Grid item xs={5} style={{ padding: 0 }}>
            <Grid container>
              <Grid item xs>
                <LoadingButton
                  color="info"
                  id="purgeSoapButtonId"
                  disabled={isPrime() || isAwaitingTriggerResponse}
                  onClick={() => handlePurgeSoap(machine)}
                  isLoading={isAwaitingTriggerResponse}
                >
                  {t('Prime')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs/>
        </Grid>
      </div>
    </Modal>
  )
}

export default withErrorNotification(withAuthentication(withStyles(unitConfigurationModalStyle)(withTranslation()(UnitConfigurationModal))))
