import { Tooltip } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import withStyles from '@material-ui/core/styles/withStyles'
import { DeviceHub } from '@material-ui/icons'
import Check from '@material-ui/icons/Check'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import machinesModalStyle from '../assets-soapy/jss/soapy/views/machinesModalStyle.jsx'
import withAuthentication from '../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../containers/withErrorNotification.js'
import {
  DISTRIBUTOR,
  INTERNAL_AREA,
  IS_ARCHIVED,
  MONITOR_ORIENTATION,
  ORGANIZATION,
  SERIAL_NUMBER,
  SITE
} from '../shared/data/machines-locations.js'
import addReactTableFilterPlaceholder from '../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../shared/react-table/defaultFilterMethod.js'
import tableStyle from '../shared/styles/tableSortIcon.css'
import Modal from './Modal'
import StayPrimaryLandscape from '@material-ui/icons/StayPrimaryLandscape'
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait'
import { useDispatch, useSelector } from 'react-redux'

const DEFAULT_PAGE_SIZE = 10
const INFO_COLOR = '#00acc1'
const ID = 'id'

const all = (obj) => {
  if (_.isEmpty(obj)) {
    return false
  }
  for (const key in obj) {
    if (!obj[key]) {
      return false
    }
  }
  return true
}

const convertToTableData = (selectedMachineIds, machines) => {
  const tableData = []
  if (selectedMachineIds) {
    selectedMachineIds.forEach(selectedMachineId => {
      tableData.push(machines.find(machine => machine.id === selectedMachineId))
    })
  }
  for (const machine of machines) {
    if (selectedMachineIds && selectedMachineIds.indexOf(machine.id) !== -1) {
      continue
    }
    tableData.push(machine)
  }

  return tableData
}

const TrendsMachinesModal = ({
  t,
  classes,
  isLoadingMachines = false,
  machines = [],
  multiSelect = false,
  showErrorNotification,
  uncheckFiltered = false,
  showCheckboxes = true,
  showActionButtons = true,
  onClose,
  displayedMachineIds,
  onApply,
  title = t('Filter by machines'),
  currentDashboardUser,
  isOpen
}) => {
  const [filtered, setFiltered] = useState([{ id: 'isArchived', value: false }])
  const [selectedMachines, setSelectedMachines] = useState({})
  const [selectedMachine, setSelectedMachine] = useState('')
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const reactTableRef = useRef()
  // needed to force react-table to rerender
  const [tableData, setTableData] = useState(machines)
  const siteMonitorOrientation = useSelector((state) => state.sites.siteMonitorOrientation)
  const open = useSelector((state) => state.popUp.open)
  const dispatch = useDispatch()

  const handleApply = async () => {
    setImmediate(() => setIsAwaitingResponse(true))
    if (multiSelect) {
      const selectedMachineIds = []
      for (const machineId of Object.keys(selectedMachines)) {
        if (selectedMachines[machineId]) {
          selectedMachineIds.push(machineId)
        }
      }
      try {
        await onApply(selectedMachineIds)
      } catch (err) {
        showErrorNotification(err.message)
      }
    } else {
      try {
        await onApply(selectedMachine)
      } catch (err) {
        showErrorNotification(err.message)
      }
    }
    setIsAwaitingResponse(false)
  }

  useEffect(() => {
    if (multiSelect) {
      const selectedMap = machines.reduce((acc, machine) => {
        acc[machine.id] = false
        return acc
      }, {})
      for (const machineId of displayedMachineIds) {
        selectedMap[machineId] = true
      }
      setSelectedMachines(selectedMap)
    } else {
      if (displayedMachineIds.length) {
        setSelectedMachine(displayedMachineIds[0])
      }
    }
    setTableData(convertToTableData(displayedMachineIds, machines))
  }, [machines]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    addReactTableFilterPlaceholder()
  })

  const handleStatusFilterChange = (status) => {
    const updatedFiltered = filtered.filter(value => value.id !== 'isArchived')
    switch (status) {
      case 'Active':
        setFiltered([...updatedFiltered, { id: 'isArchived', value: false }])
        break
      case 'Archived':
        setFiltered([...updatedFiltered, { id: 'isArchived', value: true }])
        break
      default:
        setFiltered([...updatedFiltered, { id: 'isArchived', value: null }])
    }
  }

  const handleSelectAll = () => {
    const isAllSelected = all(selectedMachines)
    if (isAllSelected) {
      setSelectedMachines((selectedMachines) => {
        for (const machineId of Object.keys(selectedMachines)) {
          selectedMachines[machineId] = false
        }
        return selectedMachines
      })
    } else {
      const sortedData = reactTableRef.current.state.sortedData
      let a = sortedData.reduce((acc, row) => {
        const id = row._original.id
        acc[id] = true
        return acc
      }, {})
      setSelectedMachines(a)
    }
  }
  const handleMultiSelectMachine = (machineId, monitorOrientation) => {
    setSelectedMachines({
      ...selectedMachines,
      [machineId]: !selectedMachines[machineId]
    })
  }
  const handleSelectMachine = (machineId) => {
    if (selectedMachine === machineId) {
      setSelectedMachine('')
    } else {
      setSelectedMachine(machineId)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onApply={handleApply}
      hideActions={!machines.length || !showActionButtons}
      dialogPaper={classes.dialogPaper}
      isLoading={isLoadingMachines || isAwaitingResponse}
      onCancel={onClose}
    >
      {isLoadingMachines
        ? <CircularProgress
          color="primary"
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />

        : <ReactTable
          data={tableData}
          style={tableStyle}
          defaultFilterMethod={defaultFilterMethod}
          columns={[
            {
              id: 'checkbox',
              minWidth: 40,
              show: Boolean(machines.length) || showCheckboxes,
              Header: () => {
                return multiSelect
                  ? (
                    <FormControlLabel
                      style={{ marginLeft: '0.5rem' }}
                      onClick={(e) => {
                        // to prevent sorting from triggering
                        e.stopPropagation()
                      }}
                      control={
                        <Checkbox
                          id="machinesModalCheckboxHeader"
                          tabIndex={-1}
                          onChange={() => {
                            handleSelectAll()
                            // hacky way to force react table to rerender, because e.stopPropagation() will block rendering
                            setTableData([...machines])
                          }}
                          checkedIcon={
                            <Check
                              className={classes.checkedIcon}
                              style={{ color: INFO_COLOR }}
                            />
                          }
                          icon={<Check className={classes.uncheckedIcon}/>}
                          checked={all(selectedMachines)}
                        />
                      }
                      label=""
                    />
                  )
                  : null
              },
              Cell: ({ original = {} }) => (
                <div style={{ textAlign: 'left' }}>
                  <FormControlLabel
                    control={
                      multiSelect
                        ? (<Checkbox
                          id="machinesModalCheckboxCell"
                          tabIndex={-1}
                          onChange={() => handleMultiSelectMachine(original[ID], original[MONITOR_ORIENTATION])}
                          checkedIcon={
                            <Check
                              className={classes.checkedIcon}
                              style={{ color: INFO_COLOR }}
                            />
                          }
                          icon={<Check className={classes.uncheckedIcon}/>}
                          checked={selectedMachines[original[ID]] || false}
                        />)
                        :
                        (<Checkbox
                          id="machinesModalCheckboxCell"
                          tabIndex={-1}
                          onChange={() => {
                            handleSelectMachine(original[ID])
                          }}
                          checkedIcon={
                            <Check
                              className={classes.checkedIcon}
                              style={{ color: INFO_COLOR }}
                            />
                          }
                          icon={<Check className={classes.uncheckedIcon}/>}
                          checked={selectedMachine === original[ID]}
                        />)
                    }
                  />
                </div>
              ),
              sortable: multiSelect,
              filterable: false,
              sortMethod: (a, b) => {
                // if descending, checked checkboxes has to be first, else they should be last
                const isAChecked = selectedMachines[a]
                const isBChecked = selectedMachines[b]
                return isAChecked === isBChecked ? 0 : isAChecked ? -1 : 1
              },
              accessor: orig => orig[ID]
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'

                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Serial number')}</span>
                </div>
              ),
              Cell: ({ original }) => {
                return original[IS_ARCHIVED] ? <i>{original[SERIAL_NUMBER]}</i> : original[SERIAL_NUMBER]
              },
              style: { textAlign: 'left' },
              filterable: true,
              accessor: SERIAL_NUMBER
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Internal area')}</span>
                </div>
              ),
              Cell: ({ original }) => {
                return original[IS_ARCHIVED] ? <i>{original[INTERNAL_AREA]}</i> : original[INTERNAL_AREA]
              },
              style: { textAlign: 'left' },
              accessor: INTERNAL_AREA,
              filterable: true
            },
            {
              Header: () => (
                <div style={{
                  textAlign: 'left'
                }}
                >
                  <span style={{ fontSize: 20 }}>{t('Organization')}</span>
                </div>
              ),
              Cell: ({ original }) => {
                return original[IS_ARCHIVED] ? <i>{original[ORGANIZATION]}</i> : original[ORGANIZATION]
              },
              style: { textAlign: 'left' },
              show: currentDashboardUser.entity.name === 'neo' || currentDashboardUser.entity.name === 'distributor',
              filterable: true,
              accessor: ORGANIZATION
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'

                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Distributor')}</span>
                </div>
              ),
              Cell: ({ original }) => {
                return original[IS_ARCHIVED] ? <i>{original[DISTRIBUTOR]}</i> : original[DISTRIBUTOR]
              },
              style: { textAlign: 'left' },
              filterable: true,
              show: currentDashboardUser.entity.name === 'neo',
              accessor: DISTRIBUTOR
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'

                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Site')}</span>
                </div>
              ),
              Cell: ({ original }) => {
                return original[IS_ARCHIVED] ? <i>{original[SITE]}</i> : original[SITE]
              },
              style: { textAlign: 'left' },
              filterable: true,
              accessor: SITE
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Monitor Position')}</span>
                </div>
              ),
              style: { textAlign: 'left' },
              Cell: ({ original }) =>
                original[MONITOR_ORIENTATION] === 'vertical' ?
                  <StayCurrentPortrait/>
                  :
                  <StayPrimaryLandscape/>
              ,
              accessor: MONITOR_ORIENTATION,
              sortable: true,
              sortMethod: (a, b) => {
                if (a === 'vertical') return 1
                return -1
              }
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'

                  }}
                >
                  <span style={{ fontSize: 20 }}>{t('Status')}</span>
                </div>
              ),
              Cell: ({ original }) => {
                if (original[IS_ARCHIVED]) {
                  return (
                    <Tooltip title={t('Archived')}>
                      <DeviceHub className={classes.archivedIconBtn}/>
                    </Tooltip>
                  )
                } else {
                  return (
                    <Tooltip title={t('Active')}>
                      <DeviceHub className={classes.activeIconBtn}/>
                    </Tooltip>
                  )
                }
              },
              Filter: ({ filter, onChange }) => {
                return (
                  <select
                    onChange={event => handleStatusFilterChange(event.target.value)}
                    style={{ width: '100%', fontSize: '1rem' }}
                    value={filter.value ? 'Archived' : filter.value === null ? 'All' : 'Active'}
                  >
                    <option value="All">{t('All')}</option>
                    <option value="Archived">{t('Archived')}</option>
                    <option value="Active">{t('Active')}</option>
                  </select>
                )
              },
              style: { textAlign: 'left' },
              filterable: true,
              accessor: IS_ARCHIVED
            }
          ]}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          minRows={0}
          filtered={filtered}
          ref={reactTableRef}
          onFilteredChange={filtered => {
            setFiltered(filtered)
          }}
          showPaginationBottom={machines.length > DEFAULT_PAGE_SIZE}
          className="-striped -highlight"
          nextText={t('Next')}
          pageText={t('Page')}
          ofText={t('Of')}
          rowsText={t('Rows')}
          previousText={t('Previous')}
        />}
    </Modal>
  )
}

export default withErrorNotification(withAuthentication(withStyles(machinesModalStyle)(withTranslation()(TrendsMachinesModal))))
