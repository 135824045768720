
import extendedTablesStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
export default theme => ({
  ...extendedTablesStyle,
  dialogPaper: {
    minHeight: 'calc(70vh + 160px)',
    maxHeight: 'calc(70vh + 160px)',
    minWidth: 'calc(70vw + 40px)',
    maxWidth: 'calc(70vw + 40px)'
  }
})
