import { SERVER_PATH } from '../endpoints'
import { ENDPOINT_URL } from '../../shared/data/dashboard-statistics'
import axios from 'axios'
import failedSession from '../failedSession'
import requestKeywords from '../../shared/data/daily-machine-usage'

export default async (
  fromDate = undefined,
  toDate = undefined,
  fromHour = undefined,
  toHour = undefined,
  filterByHours
) => {
  const URL = SERVER_PATH + ENDPOINT_URL
  try {
    const requestBody = {
      [requestKeywords.FROM_DATE]: fromDate,
      [requestKeywords.TO_DATE]: toDate,
      [requestKeywords.TO_HOUR]: filterByHours ? toHour : null,
      [requestKeywords.FROM_HOUR]: filterByHours ? fromHour : null
    }

    const { data } = await axios.post(
      URL,
      requestBody, {
        withCredentials: true
      })
    return { data: data }
  } catch (e) {
    failedSession(e)
    return { error: 'unknown' }
  }
}
