import getLanguageLocale from '../../shared/multi-language/getLanguageLocale'
// split types
const DAY = 'day'
const WEEK = 'week'
const MONTH = 'month'

export default (startDate, endDate, formatType, dates) => {
  let formatString
  switch (formatType) {
    case MONTH:
      formatString = 'MMM'
      break
    case WEEK:
      formatString = 'MMM DD'
      break
    case DAY:
      formatString = 'MMM DD'
      break
    default:
      formatString = 'MMM DD'
  }
  return dates.map((date, index) => {
    if (formatType === WEEK) {
      const start = index === 0 ? startDate : date.clone().startOf('week')
      const end = index === (dates.length - 1) ? endDate : date.clone().endOf('week')
      return start.locale(getLanguageLocale()).format(formatString) + '-' + end.locale(getLanguageLocale()).format(formatString)
    } else {
      return date.locale(getLanguageLocale()).format(formatString)
    }
  })
}