import extendedTablesStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'

export default theme => ({
  ...extendedTablesStyle,
  dialogPaper: {
    minHeight: '85vh',
    maxHeight: '85vh',
    minWidth: '80vw',
    maxWidth: '80vw'
  },
  inputError: {
    boxShadow: `0 0 0 0.2rem ${theme.palette.error.light}`,
    borderColor: '#e82626',
    borderRadius: '4px'
  },
  autocomplete: {
    fontSize: 16,
    height: 3,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#03b2cb',
      borderWidth: '2px'
    }
  }
})
