import { Checkbox, IconButton, Tooltip, Typography, } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  AssignmentTurnedIn,
  Check,
  Clear,
  Delete,
  Done,
  Edit,
  SettingsInputAntenna,
  Sync,
  VpnKey
} from '@material-ui/icons'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import Link from 'react-router-dom/Link'
import ReactTable from 'react-table'
import formStyle from '../../assets-soapy/jss/soapy/components/formStyle.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getCookie from '../../shared/cookie/get-cookie'
import setCookie from '../../shared/cookie/set-cookie.js'
import {
  ATP_FINISH_DATE,
  CONNECTION_TYPE,
  CURRENT_BOTTLE_WASH_COUNT,
  DISTRIBUTOR,
  ELAN_MAC_ADDRESS,
  INSTALLATION_DATE,
  INTERNAL_AREA,
  LAST_SYNC,
  LAST_VERSION,
  MONITOR_ORIENTATION,
  ORGANIZATION,
  SERIAL_NUMBER,
  SITE,
  STATUS,
  VIEWS,
  WLAN_MAC_ADDRESS
} from '../../shared/data/machines'
import getLanguageLocale from '../../shared/multi-language/getLanguageLocale'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'
import tableStyle from '../../shared/styles/tableSortIcon.css'
import Assessment from '@material-ui/icons/Assessment'
import DonutLarge from '@material-ui/icons/DonutLarge'
import StayPrimaryLandscape from '@material-ui/icons/StayPrimaryLandscape'
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait'
import { unitMonitorOrientationSuccess, unitSuccess } from '../../redux/unitConfigurationReducer.js'
import { useDispatch, } from 'react-redux'
import { getUnitCampaignActive } from '../../redux/apiCalls.js'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ViewIconsDropDown from './modals/ViewIconsDropdown.js'
import ActionIconsDropDown from './modals/ActionIconsDropDown.js'

const ID = 'id'
const ACTIONS = 'actions'

const DEFAULT_PAGE_SIZE = 10
const INFO_COLOR = '#00acc1'

function redirectToStatus (serialNumber) {
  setCookie('statusSerialNumber', serialNumber)
  setCookie('statusDate', Datetime.moment().toString())
  setCookie('statusToDate', Datetime.moment().endOf('day').toString())
}

const convertToReactTableData = (
  dispatch,
  machines,
  openEditMachineModal,
  openDeleteModal,
  openUuidDeleteModal,
  openTriggerSoapModal,
  openDisconnectionLogsModal,
  updateParametersTime,
  hasPermission,
  t,
  handleActionsClick,
  handleActionsClose,
  openActions,
  ITEM_HEIGHT,
  anchorElActions,
  handleViewsClick,
  handleViewsClose,
  openViews,
  anchorElViews,
  openViewIcons,
  setOpenViewIcons,
  openActionIcons,
  setOpenActionIcons,
  selectedUnitId
) =>
  machines.map(unit => {

    const views = [
      {
        dailyWashes:
          <div>
            <Tooltip title={t('Daily washes')} placement="right">
              <Link
                to="/daily-washes"
                id="unitsDailyWashesLink"
                onClick={() => {
                  setCookie('dailyWashesMachineId', unit.id)
                }}
                className="edit"
              >
                <DonutLarge style={{ color: '#3c4858', margin: '5px', height: '18px' }}/>
              </Link>
            </Tooltip>
          </div>
      },
      {
        trends:
          <div>
            <Tooltip title={t('Trends')} placement="right">
              <Link
                to="/trends"
                id="unitsTrendsLink"
                onClick={() => {
                  setCookie('usageMachineIds', unit.id)
                }}
                className="remove"
              >
                <Assessment style={{ color: '#3c4858', margin: '5px', height: '18px' }}/>
              </Link>
            </Tooltip>
          </div>
      },
      {
        status:
          <div>
            <Tooltip title={t('Status')} placement="right">
              <Link
                to="/status"
                id="unitsStatusLink"
                onClick={() => {
                  redirectToStatus(unit.serialNumber)
                }}
                className="edit"
              >
                <AssignmentTurnedIn
                  style={{ color: '#3c4858', margin: '5px', height: '18px' }}
                />
              </Link>
            </Tooltip>
          </div>
      }
    ]

    let actions = [
      {
        triggerSoap: <div
          id="adminUnitTriggerSoap"
          onClick={() => openTriggerSoapModal(unit)}
          className="remove"
        >
          <Tooltip title={t('Trigger soap')} placement="right">
            <Sync style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
          </Tooltip>
        </div>
      },

    ]

    if (hasPermission({ name: 'machines', action: 'edit' })) {
      actions.push({
        edit:
          <div
            id="adminUnitEdit"
            onClick={() => {
              getUnitCampaignActive(dispatch, unit.siteId)
              dispatch(unitSuccess(unit))
              dispatch(unitMonitorOrientationSuccess(unit.monitorOrientation))
              openEditMachineModal(unit)
            }}
            className="remove"
          >
            <Tooltip title={t('Edit')} placement="right">
              <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
            </Tooltip>
          </div>
      })
    }

    if (hasPermission({ name: 'machines', action: 'delete' })) {
      actions.push({
        delete:
          <div
            id="adminUnitDelete"
            onClick={() => openDeleteModal(unit)}
            className="remove"
          >
            <Tooltip title={t('Delete')} placement="right">
              <Delete style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
            </Tooltip>
          </div>
      })
    }

    if (hasPermission({ name: 'machine disconnection logs', action: 'read' })) {
      actions.push({
        read: <div
          id="adminUnitDisconnectionLogs"
          onClick={() => openDisconnectionLogsModal(unit)}
          className="remove"
        >
          <Tooltip title={t('Disconnection logs')} placement="right">
            <SettingsInputAntenna style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
          </Tooltip>
        </div>
      })
    }

    if (unit.uuid) {
      actions.push({
        deleteUUID:
          <div
            id="adminUnitUuid"
            onClick={() => openUuidDeleteModal(unit)}
            className="remove"
          >
            <Tooltip title={t('Delete UUID')} placement="right">
              <VpnKey style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
            </Tooltip>
          </div>
      })

    }

    const status = moment.utc().diff(unit.lastSync, 'minutes') <= updateParametersTime
    return {
      [ID]: unit.id,
      [SERIAL_NUMBER]: unit.serialNumber,
      [ORGANIZATION]: unit.site.organization.name,
      [INTERNAL_AREA]: unit.internalArea,
      [VIEWS]: (
        <div style={{ position: 'relative' }}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={() => handleViewsClick(unit)}
          >
            <Tooltip title={t('More Views')}>
              <MoreHorizIcon/>
            </Tooltip>
          </IconButton>
          {
            openViewIcons && unit.id === selectedUnitId &&
            <ViewIconsDropDown views={views} setOpenViewIcons={setOpenViewIcons}/>
          }
        </div>
      ),
      [CURRENT_BOTTLE_WASH_COUNT]: unit.currentBottleWashCount,
      [CONNECTION_TYPE]: unit.connectionType,
      [LAST_VERSION]: unit.lastVersion,
      [LAST_SYNC]: unit.lastSync && Datetime.moment(unit.lastSync).locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm'),
      [ATP_FINISH_DATE]: unit[ATP_FINISH_DATE] && Datetime.moment(unit[ATP_FINISH_DATE]).locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm'),
      [SITE]: unit.site ? unit.site.name : '',
      [DISTRIBUTOR]: unit.distributor ? unit.distributor.name : '',
      [INSTALLATION_DATE]: unit.installationDate ? Datetime.moment(unit.installationDate).format('LL') : '',
      [ACTIONS]: (
        <div style={{ position: 'relative' }}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={() => handleActionsClick(unit)}
          >
            <Tooltip title={t('More Actions')}>
              <MoreHorizIcon/>
            </Tooltip>
          </IconButton>
          {
            openActionIcons && unit.id === selectedUnitId &&
            <ActionIconsDropDown actions={actions} setOpenActionIcons={setOpenActionIcons}/>
          }
        </div>
      ),
      [STATUS]: status,
      [MONITOR_ORIENTATION]:
        {
          icon: unit[MONITOR_ORIENTATION] === 'vertical' ? <StayCurrentPortrait/> : <StayPrimaryLandscape/>,
          value: unit[MONITOR_ORIENTATION] === 'vertical'
        },
      [WLAN_MAC_ADDRESS]: unit.wlanMacAddress ? unit.wlanMacAddress : '',
      [ELAN_MAC_ADDRESS]: unit.elanMacAddress ? unit.elanMacAddress : '',

    }
  })

const any = (obj) => {
  for (const key in obj) {
    if (obj[key]) {
      return true
    }
  }
  return false
}

const all = (obj) => {
  for (const key in obj) {
    if (!obj[key]) {
      return false
    }
  }
  return true
}

const Units = ({
  openViewIcons,
  setOpenViewIcons,
  openActionIcons,
  setOpenActionIcons,
  machines,
  openDeleteModal,
  classes,
  openEditMachineModal,
  openDisconnectionLogsModal,
  selected,
  setSelected,
  tableRef,
  updateParametersTime,
  currentDashboardUser,
  hasPermission,
  openUuidDeleteModal,
  openTriggerSoapModal,
  t
}) => {

  const dispatch = useDispatch()

  const [anchorElActions, setAnchorElActions] = React.useState(null)
  const [anchorElViews, setAnchorElViews] = React.useState(null)
  const [selectedUnitId, setSelectedUnitId] = useState('')

  const openActions = Boolean(anchorElActions)
  const openViews = Boolean(anchorElViews)

  // const handleActionsClick = (e) => {
  //   setAnchorElActions(e.currentTarget);
  // };

  const handleActionsClick = (unit) => {
    setSelectedUnitId(unit.id)
    if (openActionIcons === false) {
      setOpenActionIcons(true)
    }
    if (openActionIcons === true) {
      setOpenActionIcons(false)
    }
  }

  // const handleViewsClick = (e) => {
  //   setAnchorElViews(e.currentTarget);
  // };

  const handleViewsClick = (unit) => {
    setSelectedUnitId(unit.id)
    if (openViewIcons === false) {
      setOpenViewIcons(true)
    }
    if (openViewIcons === true) {
      setOpenViewIcons(false)
    }
  }

  const handleActionsClose = () => {
    setAnchorElActions(null)
  }
  const handleViewsClose = () => {
    setAnchorElViews(null)
  }

  const ITEM_HEIGHT = 48

  const entity = currentDashboardUser.entity.name
  const language = getCookie('language')
  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [language])
  const unitsAsRows = useMemo(() => convertToReactTableData(
    dispatch,
    machines,
    openEditMachineModal,
    openDeleteModal,
    openUuidDeleteModal,
    openTriggerSoapModal,
    openDisconnectionLogsModal,
    updateParametersTime,
    hasPermission,
    t,
    handleActionsClick,
    handleActionsClose,
    openActions,
    ITEM_HEIGHT,
    anchorElActions,
    handleViewsClick,
    handleViewsClose,
    openViews,
    anchorElViews,
    openViewIcons,
    setOpenViewIcons,
    openActionIcons,
    setOpenActionIcons,
    selectedUnitId
  ), [machines, openActionIcons, openViewIcons]) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleRow = (id) => {
    setSelected({
      ...selected,
      ...(selected[id] ? { [id]: !selected[id] } : { [id]: true })
    })
  }

  const toggleSelectAll = (e) => {
    const newSelected = {}
    if (e.target.checked) {
      tableRef.current.getResolvedState().sortedData.forEach(item => {
        newSelected[item['unit.id']] = true
      })
    }

    setSelected(newSelected)
  }

  return (
    <ReactTable
      data={unitsAsRows}
      style={tableStyle}
      ref={tableRef}
      filterable
      defaultFilterMethod={defaultFilterMethod}
      columns={[
        {
          id: 'checkbox',
          accessor: '',
          Cell: ({ original }) => {
            return (
              <Checkbox
                id="adminUnitCheckboxCell"
                type="checkbox"
                checkedIcon={
                  <Check
                    className={classes.checkedIcon}
                    style={{ color: INFO_COLOR }}
                  />
                }
                icon={<Check className={classes.uncheckedIcon}/>}
                checked={selected[original.id] === true}
                onChange={() => toggleRow(original.id)}
              />
            )
          },
          Header: x => {
            return (
              <Checkbox
                id="adminUnitCheckboxHeader"
                type="checkbox"
                checked={Object.keys(selected).length && all(selected) && (machines.length === Object.keys(selected).length)}
                className="checkbox"
                checkedIcon={
                  <Check
                    className={classes.checkedIcon}
                    style={{ color: INFO_COLOR }}
                  />
                }
                icon={<Check className={classes.uncheckedIcon}/>}
                indeterminateIcon={
                  <Check
                    className={classes.checkedIcon}
                    style={{ color: INFO_COLOR }}
                  />
                }
                onChange={(e) => toggleSelectAll(e)}
              />
            )
          },
          sortable: false,
          filterable: false,
          width: 50
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('ID')}</Typography>
            </div>
          ),
          style: { textAlign: 'left', fontSize: '12px' },
          accessor: ID
        }, {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Serial number')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: SERIAL_NUMBER
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Organization')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: ORGANIZATION
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Distributor')}</Typography>
            </div>
          ),
          show: entity === 'neo',
          style: { textAlign: 'left' },
          accessor: DISTRIBUTOR
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Site')}</Typography>
            </div>
          ),
          show: entity === 'neo',
          style: { textAlign: 'left' },
          accessor: SITE
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Internal area')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: INTERNAL_AREA
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Monitor Position')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          Cell: ({ original }) => original[MONITOR_ORIENTATION].icon,
          accessor: MONITOR_ORIENTATION,
          sortable: true,
          sortMethod: (a, b) => {
            a = a.value
            if (a) return 1
            return -1
          }
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Soap usage count')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: CURRENT_BOTTLE_WASH_COUNT
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Version')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: LAST_VERSION
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Connection Type')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: CONNECTION_TYPE
        },
        {
          Header: () => (
            <div style={{ paddingBottom: '42px', textAlign: 'left' }}>
              <Typography variant="body1">{t('Online')}</Typography>
            </div>
          ),
          Cell: ({ original }) => {
            return (
              <div>
                <div>
                  <div
                    className="remove"
                  >
                    {original[STATUS]
                      ? <Done style={{ color: 'green', height: '18px', cursor: 'pointer' }}/>
                      : <Clear style={{ color: 'red', height: '18px', cursor: 'pointer' }}/>}
                  </div>
                </div>
              </div>
            )
          },
          style: { textAlign: 'left' },
          accessor: STATUS,
          sortable: true,
          filterable: false
        },
        {
          Header: () => (
            <div style={{ paddingBottom: '42px', textAlign: 'left' }}>
              <Typography variant="body1">{t('Last connection')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: LAST_SYNC,
          sortMethod: (a, b) => {
            const aDate = new Date(a)
            const bDate = new Date(b)
            return aDate > bDate ? 1 : -1
          },
          sortable: true,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                maxHeight: '60px',
                marginLeft: 0,
                paddingBottom: '84px'
              }}
            >
              <Typography variant="body2">{t('Views')}</Typography>
            </div>
          ),
          style: { textAlign: 'left', overflow: 'visible' },
          accessor: VIEWS,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0
              }}
            >
              <Typography variant="body1">{t('Actions')}</Typography>
            </div>
          ),
          style: { textAlign: 'left', overflow: 'visible' },
          accessor: ACTIONS,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('WLAN Mac Address')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          Cell: ({ original }) => original[WLAN_MAC_ADDRESS],
          accessor: WLAN_MAC_ADDRESS,
          sortable: true,
          sortMethod: (a, b) => {
            a = a.value
            if (a) return 1
            return -1
          }
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('ELAN Mac Address')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          Cell: ({ original }) => original[ELAN_MAC_ADDRESS],
          accessor: ELAN_MAC_ADDRESS,
          sortable: true,
          sortMethod: (a, b) => {
            a = a.value
            if (a) return 1
            return -1
          }
        }, {
          Header: () => (
            <div style={{ paddingBottom: '42px', textAlign: 'left' }}>
              <Typography variant="body1">{t('HCA Finish Date')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: ATP_FINISH_DATE,
          sortMethod: (a, b) => {
            const aDate = new Date(a)
            const bDate = new Date(b)
            return aDate > bDate ? 1 : -1
          },
          sortable: true,
          filterable: false
        },
      ]}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      minRows={0}
      showPaginationBottom={
        unitsAsRows.length > DEFAULT_PAGE_SIZE
      }
      className="-striped -highlight"
      nextText={t('Next')}
      pageText={t('Page')}
      onPageChange={() => {
        document.getElementById('mainPanel').scrollTop = 0
      }}
      ofText={t('Of')}
      rowsText={t('Rows')}
      previousText={t('Previous')}
      onFilteredChange={() => {
        // we have to uncheck all selected machines when we filter to avoid unexpected behavior in bulk edit
        if (any(selected)) {
          const sortedData = tableRef.current.getResolvedState().sortedData
          // only sorted machines can be checked, rest should get unchecked
          const filteredSelectedUnits = sortedData.reduce((acc, row) => {
            if (selected[row['unit.id']]) {
              acc[row['unit.id']] = selected[row['unit.id']]
            }
            return acc
          }, {})
          setSelected(filteredSelectedUnits)
        }
      }}
    />
  )
}

export default withAuthentication(withStyles(formStyle)(withTranslation()(Units)))
