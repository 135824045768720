import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle";


class HeaderLinks extends React.Component {
	state = {
		open: false
	};

	render() {
		const {classes, rtlActive} = this.props;
		const wrapper = classNames({
			[classes.wrapperRTL]: rtlActive
		});

		return (
			<div className={wrapper}>

			</div>
		);
	}
}

HeaderLinks.propTypes = {
	classes: PropTypes.object.isRequired,
	rtlActive: PropTypes.bool
};

export default withStyles(headerLinksStyle)(HeaderLinks);
