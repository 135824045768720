import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'

import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export default async (serialNumber, password) => {
  const endpoint = apiEndpoints.machines

  const URL = SERVER_PATH + endpoint + `/${serialNumber}/status-configuration`

  return resolveRequest(axios.post(URL, { password }, { withCredentials: true }))
}
