exports.DASHBOARD_SERVER_URL = 'dashboardServerUrl'
exports.FACE_RECOGNITION_SERVER_URL = 'faceRecognitionServerUrl'
exports.UPDATE_PARAMETERS_TIME = 'updateParametersTime'
exports.CREATE_USAGE_EVENT_URL = 'createUsageEventUrl'
exports.LOG_URL = 'logUrl'
exports.UPDATE_PARAMETERS_TIME = 'updateParametersTime'
exports.HAND_RECOGNITION_URL = 'handRecognitionUrl'


exports.ERROR_TYPE = 'errorType'
