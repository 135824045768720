import validationFormsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle'
import dashboardAppStyle from '../../../../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx'

const appStyle = theme => ({
  ...validationFormsStyle,
  ...dashboardAppStyle(theme),
  archivedIconBtn: {
    color: 'grey'
  },
  activeIconBtn: {
    color: theme.palette.primary.main
  },
  actionIconBtn: {
    color: '#3c4858',
    margin: '5px',
    height: '18px',
    cursor: 'pointer'
  }
})

export default appStyle
