import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const addGroup = async (newGroupData) => {
    const URL = SERVER_PATH + apiEndpoints.addgroup
    return resolveRequest(axios.post(URL, newGroupData, { withCredentials: true }))
}

export default addGroup
