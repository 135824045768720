import React from 'react'
import { withTranslation } from 'react-i18next'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'

const OrganizationsTableHeader = ({
  hasPermission,
  t,
  openOrganizationModal
}) => {
  return (
    hasPermission({ name: 'organizations', action: 'create' }) && <Button
      color='info'
      round
      id='addOrganizationBtn'
      onClick={openOrganizationModal}
      style={{ marginRight: 5 }}
    >
      {t('Add organization')}
    </Button>
  )
}

export default withTranslation()(withAuthentication(OrganizationsTableHeader))
