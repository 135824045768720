import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export default async (distributorId, options = {
  canAttachToSite: false
}) => {
  const queryParams = new URLSearchParams(options)
  const distributorDashboardUsersUrl = SERVER_PATH + `${apiEndpoints.dashboardUsers}?distributorId=${distributorId}&&${queryParams.toString()}`
  return resolveRequest(axios.get(distributorDashboardUsersUrl, { withCredentials: true }))
}
