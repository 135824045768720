import { MuiThemeProvider } from '@material-ui/core/styles'
import { createBrowserHistory } from 'history'
import i18n from 'i18next'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { initReactI18next } from 'react-i18next'
import './assets-soapy/scss/material-dashboard-pro-react.css?v=1.4.0'
import theme from './assets-soapy/theme'
import Cache from './components-soapy/Cache'
import AuthContainer from './containers/Authentication/AuthContainer.js'
import getCurrentUser from './endpoint-requests/dashboard-user/getCurrentUser'
import fetchTranslations from './endpoint-requests/multi-language/fetchTranslations'
import AuthLayoutRouter from './layouts/AuthLayoutRouter.jsx'
import routes from './routes/routes.js'
import getCookie from './shared/cookie/get-cookie'
import './shared/momentLanguages'
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'

const load = async () => {
  let accessLevel = 'auth'
  let currentDashboardUser = null

  const hist = createBrowserHistory()
  const pathName = hist.location.pathname
  if (routes['/' + pathName.split('/')[1]]) {
    accessLevel = 'unknown'
  } else {
    try {
      const res = await getCurrentUser()
      accessLevel = 'basic'
      currentDashboardUser = res.data
    } catch (e) {
      console.log('Couldnt authenticate a basic dashboard user')
    }
  }
  const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
  ReactGA.initialize(trackingId)

  hist.listen((location) => {
    ReactGA.set({ page: location.pathname }) // Update the user's current page
    ReactGA.pageview(location.pathname) // Record a pageview for the given page
  })

  const translations = await fetchTranslations()
  const resources = translations.reduce((resources, translation) => {
    for (const langCode of Object.keys(translation)) {
      if (!resources[langCode] || !resources[langCode].translation) {
        resources[langCode] = {
          translation: {},
        }
      }
      resources[langCode].translation[translation.en] = translation[langCode]
    }
    return resources
  }, {})

  i18n.use(initReactI18next).init({
    resources,
    lng: getCookie('language') || 'en',
    react: {
      useSuspense: false,
    },
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  })

  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthContainer
          accessLevel={accessLevel}
          languageCodes={['en', 'it']}
          currentDashboardUser={currentDashboardUser}
        >
          <MuiThemeProvider theme={theme}>
            <AuthLayoutRouter hist={hist}/>
            <Cache/>
          </MuiThemeProvider>
        </AuthContainer>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )
}

load().then(() => {
  /* By default, CRA will auto-refresh after code changes, however Webpack HMR can often refresh it faster with this snippet */
  if (module.hot) {
    module.hot.accept()
  }
})
