import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import withAuthentication from '../../containers/Authentication/withAuthentication';
import { withTranslation } from 'react-i18next';
import FullPagePaper from '../../components-soapy/FullPagePaper'
import Snackbar from '../../components/Snackbar/Snackbar'
import GroupsTableHeader from './GroupsTableHeader'
import GroupsTable from './GroupsTable';
import AddGroupModal from "./AddGroupModal";
import DeleteModal from '../../components-soapy/ConfirmationModal.jsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { deleteSelectedGroup } from '../../redux/groups/groupsReducer'
import { getGroups } from '../../redux/groups/apiCalls'
import { getOrganizations } from '../../redux/apiCalls'
import deleteGroup from '../../../src/endpoint-requests/groups/deleteGroup'

const GroupsContainer = ({ t }) => {
  const dispatch = useDispatch()
  const { groups } = useSelector((state) => state.groups)
  const { organizations } = useSelector((state) => state.organizations)
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isGroupContainWashers, setIsGroupContainWashers] = useState(false);
  const [isDeleteWasherModalOpen, setIsDeleteWasherModalOpen] = useState(false);
  const [isSuccessNotificationVisible, setIsSuccessNotificationVisible] = useState(false);
  const [loading, setLoading] = useState(false)

  //-------------UseEffect api call for get all groups and get all organizations------------------//
  useEffect(() => {
    setLoading(true)
    getOrganizations(dispatch)
    getGroups(dispatch, setLoading)
  }, [])

  //-------------------Function for show success notification-----------------------//
  useEffect(() => {
    setTimeout(() => {
      if (isSuccessNotificationVisible === true) {
        setIsSuccessNotificationVisible(false)
      }
    }, 3000);
  }, [isSuccessNotificationVisible])

  // -------------------------Render Group Page------------------------//
  return (
    <>
      {
        loading ?
          <CircularProgress
            color="primary"
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          /> :
          < FullPagePaper >
            <Snackbar
              place="tc"
              color="success"
              icon={NotificationsIcon}
              message={t('Saved') + '!'}
              open={isSuccessNotificationVisible}
            />
            {
              addGroupModal ? <AddGroupModal
                selectedGroup={selectedGroup}
                isOpen={addGroupModal}
                onClose={() => setAddGroupModal(!addGroupModal)}
                isSuccessNotificationVisible={isSuccessNotificationVisible}
                setIsSuccessNotificationVisible={setIsSuccessNotificationVisible}
                organizationsList={organizations}
              /> : null
            }
            <DeleteModal
              isGroupContainWashers={isGroupContainWashers}
              isOpen={isDeleteWasherModalOpen}
              onClose={() => {
                setIsDeleteWasherModalOpen(!isDeleteWasherModalOpen)
              }}
              onApply={async () => {
                if (selectedGroup?.washers?.length > 0) {
                  setIsGroupContainWashers(true)
                } else {
                  await deleteGroup(selectedGroup.id)
                  dispatch(deleteSelectedGroup(selectedGroup.id))
                  setIsSuccessNotificationVisible(() => !isSuccessNotificationVisible)
                  setIsDeleteWasherModalOpen(!isDeleteWasherModalOpen)
                  setIsGroupContainWashers(false)
                }
              }}
            />
            {isGroupContainWashers && <DeleteModal
              isGroupContainWashers={isGroupContainWashers}
              isOpen={isDeleteWasherModalOpen}
              onClose={() => {
                setIsDeleteWasherModalOpen(!isDeleteWasherModalOpen)
                setIsGroupContainWashers(false)
              }}
              onApply={async () => {
                await deleteGroup(selectedGroup.id)
                dispatch(deleteSelectedGroup(selectedGroup.id))
                setIsSuccessNotificationVisible(() => !isSuccessNotificationVisible)
                setIsDeleteWasherModalOpen(!isDeleteWasherModalOpen)
                setIsGroupContainWashers(false)
              }}
            />}
            <GroupsTableHeader
              totalGroups={groups.length}
              addGroupModal={addGroupModal}
              setAddGroupModal={setAddGroupModal}
              setSelectedGroup={setSelectedGroup}
            />
            <GroupsTable
              groups={groups}
              setAddGroupModal={setAddGroupModal}
              setIsDeleteWasherModalOpen={setIsDeleteWasherModalOpen}
              setSelectedGroup={setSelectedGroup}
            />
          </FullPagePaper >
      }
    </>
  );
};

export default withAuthentication(withTranslation()(GroupsContainer));
