import { People, PeopleOutline } from '@material-ui/icons';
import Washers from '../pages/Washers/Washers';
import Groups from '../pages/Groups/Groups';

// --------------- Washer Submenu Routes ------------- //
export default [
  {
    name: 'Washers',
    title: 'Washers',
    hasMobileView: true,
    component: Washers,
    path: '/washers',
    requiredPermissions: [
      {
        name: 'washers',
        action: 'read',
      },
    ],
    layout: '',
    icon: PeopleOutline,
  },
  {
    name: 'Groups',
    title: 'Groups',
    hasMobileView: true,
    component: Groups,
    path: '/groups',
    requiredPermissions: [
      {
        name: 'groups',
        action: 'read',
      },
    ],
    layout: '',
    icon: People,
  },
];
