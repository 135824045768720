import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Modal from '@material-ui/core/Modal'
import Close from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux'
import { useStylesPopUp } from './shiftStyles'
import Datetime from 'react-datetime'
import GridItem from '../../components/Grid/GridItem'
import { InputLabel } from '@material-ui/core'
import GridContainer from '../../components/Grid/GridContainer'
import AddIcon from '@material-ui/icons/Add'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import DeleteIcon from '@material-ui/icons/Delete'
import { hoursListSuccess, shiftListSuccess } from '../../redux/shiftsReducer'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(10em * 3)',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '3px',
  boxShadow: 24,
  p: 4,
}

export default function ShiftHourPicker ({
  openHourPicker,
  setOpenHourPicker,
  shiftData,
  setShiftData,
  currentHour,
  shiftList,
  setShiftList,
  hoursList,
  setHoursList
}) {

  const { currentShift } = useSelector((state) => state.shifts)
  const classes = useStylesPopUp()
  const [fromHour, setFromHour] = useState('00')
  const [toHour, setToHour] = useState('00')
  const [selectedCurrentShift, setSelectedCurrentShift] = useState(currentShift)
  const [isValidFromTime, setIsValidFromTime] = useState(true)
  const [isValidToTime, setIsValidToTime] = useState(true)
  const [isValidShift, setIsValidShift] = useState(true)
  const [shiftBoxMarginTop, setShiftBoxMarginTop] = useState('30px')
  const dispatch = useDispatch()

  useEffect(() => {
    shiftList.map((shift) => {
      if (currentHour >= shift.split(':')[0] && currentHour < shift.split(':')[1].split(' - ')[1]) {
        setSelectedCurrentShift(shift)
      }
    })
  }, [shiftList])

  useEffect(() => {
    if (shiftList.length === 0) {
      setHoursList([])
    }
  }, [shiftList])

  const handleClose = () => {
    setOpenHourPicker(false)
    setShiftList(shiftList.filter((shift) => shift !== fromHour + ' - ' + toHour))
  }
  const handleFromHour = (e) => {
    const re = /^[0-9\b]+$/
    if (typeof e === 'object') {
      setFromHour(e.hours() + ':' + e.minutes() + '0')
      setIsValidShift(true)
    }
    if (typeof e === 'string') {
      if (e > 23) {
        setFromTimeError(false)
      } else if (e === '') {
        setFromTimeError(false)
      } else if (!re.test(e)) {
        setFromTimeError(false)
        setFromHour(e + ':' + '00')
      } else {
        setFromTimeError(true)
        setFromHour(e + ':' + '00')
      }
    }
  }

  const setFromTimeError = (valid) => {
    setIsValidFromTime(valid)
    setIsValidShift(valid)
    if (valid === false) {
      setShiftBoxMarginTop('10px')
    }
  }

  const handleToHour = (e) => {
    const re = /^[0-9\b]+$/

    if (typeof e === 'object') {
      setToHour(e.hours() + ':' + e.minutes() + '0')
      setIsValidShift(true)
    }
    if (typeof e === 'string') {
      if (e > 23) {
        setToTimeError(false)
      } else if (e === '') {
        setToTimeError(false)
      } else if (!re.test(e)) {
        setToTimeError(false)
        setToHour(e + ':' + '00')
      } else {
        setToTimeError(true)
        setToHour(e + ':' + '00')
      }
    }
  }

  const setToTimeError = (valid) => {
    setIsValidToTime(valid)
    setIsValidShift(valid)
    if (valid === false) {
      setShiftBoxMarginTop('10px')
    }
  }

  const checkIsValidShift = (fromHour, toHour) => {

    for (let i = 0; i < shiftList.length; i++) {
      if (shiftList.length > 0) {
        if (Math.abs(formatShiftHours(shiftList.length - 1, ' - ', 1, ':', 0) - formatShiftHours(shiftList.length - 1, ' - ', 0, ':', 0)) === 0) {
          setShiftError()
          return false
        }
      }
      if (formatHours(fromHour) >= formatShiftHours(i, ' - ', 0, ':', 0) && formatHours(fromHour) < formatShiftHours(i, ' - ', 1, ':', 0)) {
        setShiftError()
        return false
      }
      if (formatHours(toHour) > formatShiftHours(i, ' - ', 0, ':', 0) && formatHours(toHour) < formatShiftHours(i, ' - ', 1, ':', 0)) {
        setShiftError()
        return false
      }
      if (formatHours(toHour) >= formatShiftHours(i, ' - ', 0, ':', 0) && formatHours(toHour) >= formatShiftHours(i, ' - ', 1, ':', 0)) {
        if (formatHours(fromHour) < formatShiftHours(i, ' - ', 0, ':', 0) && formatHours(fromHour) < formatShiftHours(i, ' - ', 1, ':', 0)) {
          setShiftError()
          return false
        }
      }
    }
    if (shiftList.includes('0:00 - 0:00')) {
      setShiftError()
      return false
    } else if (fromHour === '0:00' && toHour === '0:00' && shiftList.length > 0) {
      setShiftError()
      return false
    } else if (formatHours(fromHour) === formatHours(toHour)) {
      if (formatHours(fromHour) === 0) {
        setIsValidShift(true)
        return true
      }
    }
    setIsValidShift(true)
    return true
  }

  const formatHours = (hour) => {
    return Number(hour.split(':')[0])
  }

  const formatShiftHours = (i, firstSplit, firstElement, secondSplit, secondElement) => {
    return Number(shiftList[i].split(firstSplit)[firstElement].split(secondSplit)[secondElement])
  }

  const setShiftError = () => {
    setIsValidShift(false)
    setShiftBoxMarginTop('10px')
    setTimeout(() => {
      setIsValidShift(true)
      setShiftBoxMarginTop('30px')
    }, 3000)
  }

  const handleAddShiftHours = () => {
    if (isValidFromTime === false || isValidToTime === false) {
      setShiftError()
    } else {
      const isValidShift = checkIsValidShift(fromHour, toHour)
      if (isValidShift) {
        let shiftListArray = []
        let hoursListArray = []

        if (fromHour === '00' && toHour === '00') {
          if (shiftList.length === 0) {
            setShiftList([...shiftList, '0:00 - 0:00'])
          } else {
            setShiftError()
          }
        } else if (fromHour === '00' && toHour !== '00') {
          let thr = toHour.split(':')[0]
          if (thr > 9) {
            shiftListArray = [...shiftList, `${fromHour}:00` + ' - ' + toHour.slice(2)]
            hoursListArray = [...hoursList, fromHour.split(':')[0], toHour.slice(2).split(':')[0]]
            setShiftAndHours(shiftListArray, hoursListArray)
          } else {
            shiftListArray = [...shiftList, `${fromHour}:00` + ' - ' + toHour]
            hoursListArray = [...hoursList, fromHour.split(':')[0], toHour.split(':')[0]]
            setShiftAndHours(shiftListArray, hoursListArray)
          }
        } else if (fromHour !== '00' && toHour === '00') {
          let fhr = fromHour.split(':')[0]
          if (fhr > 9) {
            shiftListArray = [...shiftList, fromHour.slice(2) + ' - ' + `${toHour}:00`]
            hoursListArray = [...hoursList, fromHour.slice(2).split(':')[0], toHour.split(':')[0]]
            setShiftAndHours(shiftListArray, hoursListArray)
          } else {
            shiftListArray = [...shiftList, fromHour + ' - ' + `${toHour}:00`]
            hoursListArray = [...hoursList, fromHour.split(':')[0], toHour.split(':')[0]]
            setShiftAndHours(shiftListArray, hoursListArray)
          }
        } else if (fromHour === toHour && fromHour !== '00') {
          if (shiftList.length === 0) {
            shiftListArray = [...shiftList, fromHour + ' - ' + toHour]
            hoursListArray = [...hoursList, fromHour.split(':')[0]]
            setShiftAndHours(shiftListArray, hoursListArray)
          } else {
            setShiftError()
          }
        } else {
          shiftListArray = [...shiftList, fromHour + ' - ' + toHour]
          hoursListArray = [...hoursList, fromHour.split(':')[0], toHour.split(':')[0]]
          setShiftAndHours(shiftListArray, hoursListArray)
        }
      }
    }
  }
  const setShiftAndHours = (shiftListArray, hoursListArray) => {
    setShiftList([...new Set(shiftListArray.sort((a, b) => a.split(':')[0] - b.split(':')[0]))])
    setHoursList([...new Set(hoursListArray.sort((a, b) => a - b))])
  }

  const handleUp = () => {
    setSelectedCurrentShift(shiftList[shiftList.indexOf(selectedCurrentShift) - 1])
    if (shiftList.indexOf(selectedCurrentShift) === 0) {
      setSelectedCurrentShift(shiftList[shiftList.length - 1])
    }
  }

  const handleDown = () => {
    setSelectedCurrentShift(shiftList[shiftList.indexOf(selectedCurrentShift) + 1])
    if (shiftList.indexOf(selectedCurrentShift) === shiftList.length - 1) {
      setSelectedCurrentShift(shiftList[0])
    }
  }

  const handleDelete = () => {
    setShiftList(shiftList.filter((shift) => shift !== selectedCurrentShift))
    setSelectedCurrentShift(shiftList[shiftList.indexOf(selectedCurrentShift) + 1])
    if (shiftList.indexOf(selectedCurrentShift) === shiftList.length - 1) {
      setSelectedCurrentShift(shiftList[0])
    }
  }
  const handleApply = () => {
    setOpenHourPicker(false)
    dispatch(hoursListSuccess(hoursList))
    dispatch(shiftListSuccess(shiftList))
  }

  const handleCancel = () => {
    setOpenHourPicker(false)
    setShiftList(shiftList.filter((shift) => shift !== fromHour + ' - ' + toHour))
  }
  return (
    <div>
      <Modal
        open={openHourPicker}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '10px' }}>
            Select Shift Hours
          </Typography>
          <Close
            onClick={() => handleClose()}
            className={classes.myClassName}
          />
          <GridContainer>
            <GridItem md={5}>
              <InputLabel style={{ fontSize: '14px' }}>{'From'}</InputLabel>
              <Datetime
                viewMode="time"
                onChange={(e) => handleFromHour(e)}
                dateFormat=""
                timeFormat={'HH'}
                closeOnSelect={true}
                defaultValue="00"
              />
            </GridItem>
            <GridItem md={5}>
              <InputLabel style={{ fontSize: '14px' }}>{'To'}</InputLabel>
              <Datetime
                autoComplete="new-password"
                viewMode="time"
                onChange={(e) => handleToHour(e)}
                dateFormat=""
                timeFormat={'HH'}
                closeOnSelect={true}
                defaultValue="00"
              />
            </GridItem>
            <GridItem md={2}>
              {/* {fromHour !== "" && toHour !== "" ?
                                (
                                    isValidFromTime && isValidToTime
                                        ?
                                        <AddIcon style={{ cursor: 'pointer' }} onClick={() => handleAddShiftHours()} />
                                        : <p style={{ color: "red" }}>Invalid Time!!!</p>
                                )
                                :
                                null
                            } */}
              {
                isValidShift &&

                <AddIcon style={{ cursor: 'pointer' }} onClick={() => handleAddShiftHours()}/>
              }

            </GridItem>
            {isValidShift === false &&
              <p style={{ color: 'red', marginLeft: '15px', margingTop: '15px' }}>Invalid shift</p>
            }
          </GridContainer>
          <GridContainer>
            <GridItem md={10}>
              <div style={{ border: '1px solid gray', height: '340px', marginTop: shiftBoxMarginTop }}>
                <div style={{
                  margin: '10px',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}>
                  {shiftList.length > 0 && shiftList.map((shift, index) => (
                    <>
                      <div key={index} style={{
                        border: `${shift}` === `${selectedCurrentShift}` && '1px solid teal',
                        margin: '10px',
                        backgroundColor: `${shift}` === `${selectedCurrentShift}` && `#00acc1`,
                        borderRadius: `${shift}` === `${selectedCurrentShift}` && `3px`,
                        color: `${shift}` === `${selectedCurrentShift}` && 'white'
                      }}>
                        <b style={{
                          fontSize: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>{shift}</b>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </GridItem>
            <GridItem md={2}>
              <div style={{
                border: '1px solid gray',
                height: '340px',
                marginTop: shiftBoxMarginTop,
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div style={{ marginTop: '80px', height: '250px', }}>
                  <div style={{
                    cursor: 'pointer',
                    display: 'flex',
                    marginTop: '20px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <ArrowUpwardIcon onClick={() => handleUp()}/>
                  </div>
                  <div style={{
                    cursor: 'pointer',
                    display: 'flex',
                    marginTop: '20px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <ArrowDownwardIcon onClick={() => handleDown()}/>
                  </div>
                  <div style={{
                    cursor: 'pointer',
                    display: 'flex',
                    marginTop: '20px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <DeleteIcon onClick={() => handleDelete()}/>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div style={{ marginTop: '3%' }}>
            <p>Please select the start and end time of the shift and then click "+" button to add it to the list of
              shifts</p>
          </div>
          <div className="popupButtonDiv" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            float: 'right'
          }}>
            {
              shiftList.length > 0 && isValidFromTime && isValidToTime &&
              <p style={{
                cursor: 'pointer',
                marginRight: '50px',
                width: '50px',
                fontWeight: '300'
              }} onClick={() => handleApply()}>Apply</p>
            }

            <p style={{
              cursor: 'pointer',
              color: 'red',
              width: '50px',
              fontWeight: '300'
            }} onClick={() => handleCancel()}>Cancel</p>
          </div>
        </Box>
      </Modal>
    </div>
  )
}