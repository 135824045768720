/* global google */
import React, { useState } from 'react'
import { InfoWindow, Marker } from 'react-google-maps'

const MarkerCarrierIcon = ({ lac, }) => {

  const { latitude, longitude } = lac
  const [openedInfoWindow, setOpenedInfoWindow] = useState(null)

  const handleClick = (lac) => {
    setOpenedInfoWindow(lac.id)
  }

  const handleInfoWindowClose = () => {
    setOpenedInfoWindow(null)
  }

  return (
    <Marker
      // defaultIcon={soapyLogo}
      key={lac.id}
      icon={{
        url: `/googleMapImages/soapyLocationMarker.png`,
        size: new google.maps.Size(80, 80),
        condition: 'normal',
      }}
      defaultPosition={{ lat: latitude ? latitude : 0, lng: longitude ? longitude : 0 }}
      onClick={() => handleClick(lac)}
    >
      {lac.id === openedInfoWindow &&
        <InfoWindow
          onCloseClick={() => handleInfoWindowClose()}
        >
          <>
            {lac.name && <h4>{('Name')} - {lac.name}</h4>}
            {lac.country && <h4>{('Country')} - {lac.country}</h4>}
            {lac.city && <h4>{('City')} - {lac.city}</h4>}
          </>
        </InfoWindow>}
    </Marker>
  )
}

// const mapDispatchToProps = dispatch => ({
//   handleClick: lac => dispatch(ActionCreators.setPerimeterSelectedLac(lac))
// })

export default MarkerCarrierIcon
