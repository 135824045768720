import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * @param {string} id
 * @param {object} unitObject
 * @param {number} unitObject.latherTime
 * @param {number} unitObject.afterWashDelay
 * @param {number} unitObject.additionalWaterTime
 * @param {number} unitObject.soapTime
 * @param {number} unitObject.waterTimeDuringSoap
 * @param {number} unitObject.waterTimePerWaterCycle
 * @param {string} unitObject.internalArea
 */
export default async (id, unitObject) => {
  const URL = SERVER_PATH + apiEndpoints.machines + `/${id}` + apiEndpoints.machineWashConfigurations

  return resolveRequest(
    axios.put(URL, unitObject, { withCredentials: true })
  )
}
