import extendedTablesStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'

export default theme => ({
  ...extendedTablesStyle,
  dialogPaper: {
    minHeight: '25rem',
    maxHeight: '25rem',
    minWidth: '40rem',
    maxWidth: '40rem'
  },
  uploadBtn: {
    minWidth: '36vh',
    margin: '1rem',
    marginTop: '4rem'
  }
})
