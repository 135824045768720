import regression from '../linearRegression.js'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withAuthentication from '../../../containers/Authentication/withAuthentication'
import Highcharts from 'highcharts'

const WashesSumChart = ({ t, chartData, formatChartDates, washesSumChartRef, fromDate, toDate, ...rest }) => {
  const [options, setOptions] = useState({
    yAxis: {
      min: 0,
      stackLabels: {
        enabled: true
      },
      title: {
        text: t('Washes')
      }
    }

  })

  useEffect(() => {
    const seriesData = chartData.data.map(machineIdToDailyMachineUsages => {
      if (!machineIdToDailyMachineUsages) return 0
      let washCount = 0
      for (const machineId in machineIdToDailyMachineUsages) {
        const dailyMachineUsage = machineIdToDailyMachineUsages[machineId]
        washCount += dailyMachineUsage.washCount || 0
      }
      return washCount
    })

    const siteSeries = chartData.data.reduce((acc, machineIdToDailyMachineUsages) => {
      for (const machineId in machineIdToDailyMachineUsages) {
        const dailyMachineUsage = machineIdToDailyMachineUsages[machineId]
        if (dailyMachineUsage.machine && dailyMachineUsage.machine.site) {
          const site = dailyMachineUsage.machine.site
          if (!acc[site.id]) {
            acc[site.id] = {
              name: `${site.name}${site.district ? `, ${site.district}` : ''}${site.city ? `, ${site.city}` : ''}`,
              events: {
                legendItemClick: function () {
                  return false
                }
              }
            }
          }
        }
      }
      return acc
    }, {})

    //code for line on the graph
    const regressionXData = [...Array(seriesData.length).keys()]
    const regressionLine = regression(seriesData, regressionXData).line
    // replace negative value with 0, we don't need negative values in chart
    if (regressionLine[1][1] < 0) regressionLine[1][1] = 0
    if (regressionLine[0][1] < 0) regressionLine[0][1] = 0

    setOptions({
      ...options,
      yAxis: {
        title:
        {
          text: t('Washes sum')
        }
      },
      series: [
        {
          data: seriesData,
          showInLegend: false,
          name: t('Total')
        },
        {
          type: 'line',
          name: 'regression line',
          // data: regressionLine, //hiding the line from the graph temporary
          showInLegend: false,
          stickyTracking: false,
          marker: {
            enabled: false
          },
          tooltip: {
            formatter: () => null
          }
        },
        ...Object.values(siteSeries)
      ]
    }
    )
  }, [chartData]) // eslint-disable-line react-hooks/exhaustive-deps

  const chart = <HighchartsReact
    highcharts={Highcharts}
    constructorType="chart"
    options={{
      ...options,
      xAxis: { categories: formatChartDates(chartData.dates) },
      yAxis: {
        title:
        {
          text: t('Washes sum')
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.x + '</b><br/>' +
            t('Total') + ': ' + (this.point.stackTotal || this.point.y)
        }
      },
      chart: {
        type: 'column',
        height: 500
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      legend: {
        itemStyle: {
          cursor: 'default'
        }

      },
      exporting: { enabled: false },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            style: {
              textOutline: '0px'
            }
          }
        }
      },
      colors: [
        '#4074c1',
        '#b2c7e5',
        '#a4a4a4',
        '#d9d9d9',
        '#f67c3d',
        '#fccbb0',
        '#ffd84b',
        '#fff1b6',
        '#8aaac9',
        '#d3dfea'
      ]
    }}
    ref={washesSumChartRef}
  />

  return (
    <div
      style={{
        marginTop: 30,
        position: 'relative',
        height: '100%'
      }}
    >
      {chart}
    </div>
  )
}

export default withAuthentication(withTranslation()(WashesSumChart))
