import React, { useState } from 'react'
import { InfoWindow, Marker } from 'react-google-maps'
import { withTranslation } from 'react-i18next'
import soapyLogo from '../../../../assets-soapy/img/soapy_location_29_45.png'
import { CITY, COUNTRY, NAME } from '../../../../shared/data/sites'
import { LATITUDE as LATITUDE_LOCAL, LONGITUDE as LONGITUDE_LOCAL } from '../../constants.js'

const GoogleMarkersWrapper = ({ locations, role, t }) => {
  const [openedInfoWindow, setOpenedInfoWindow] = useState(null)

  const handleMarkerClick = (machineId) => {
    setOpenedInfoWindow(machineId)
  }
  const handleInfoWindowClose = () => {
    setOpenedInfoWindow(null)
  }
  return (
    Object.entries(locations).map(
      ([machineId, {
        [LATITUDE_LOCAL]: lat,
        [LONGITUDE_LOCAL]: lng,
        [CITY]: city,
        [COUNTRY]: country,
        [NAME]: name
      }]) => (
        <>
          <Marker
            position={{ lat, lng }}
            key={machineId}
            onClick={() => handleMarkerClick(machineId)}
            defaultIcon={soapyLogo}
          >
            {machineId === openedInfoWindow &&
              <InfoWindow
                onCloseClick={() => handleInfoWindowClose()}
              >
                <>
                  {name && <h4>{t('Name')} - {name}</h4>}
                  {country && <h4>{t('Country')} - {country}</h4>}
                  {city && <h4>{t('City')} - {city}</h4>}
                </>
              </InfoWindow>}
          </Marker>
        </>

      )
    )
  )
}

export default withTranslation()(GoogleMarkersWrapper)
