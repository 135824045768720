import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const editGroup = async (editGroupData, groupId) => {
    const URL = SERVER_PATH + apiEndpoints.groups + `/${groupId}`
    return resolveRequest(
        axios.put(URL, editGroupData, {
            withCredentials: true
        })
    )
}

export default editGroup