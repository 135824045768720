import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import adminUnitModalStyle from '../../../assets-soapy/jss/soapy/views/adminUnitModalStyle.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import editMachine from '../../../endpoint-requests/machine/editMachine.js'
import getBottleWashCount from '../../../shared/unit-configuration/getBottleWashCount.js'
import {
  ADDITIONAL_WATER_TIME,
  AFTER_WASH_DELAY,
  ATP_MODE,
  AUTOMATIC_SOAP_PURGE,
  DB_TIME_ZONE,
  DEBUG_HANDS_RECOGNITION,
  DEBUG_MODE,
  DELAY_TIME_PER_WATER_CYCLE,
  ENABLE_VISUAL_ERROR,
  HANDS_RECOGNITION,
  INSTALLATION_DATE,
  INTERNAL_AREA, IS_SOAP_PURGE, IS_WATER_PURGE,
  LATHER_TIME,
  LED_PROGRESS_BAR,
  MONITOR,
  MONITOR_ORIENTATION,
  PUBLIC_PLACES_SUPPORT,
  PURGE_MINUTES_AFTER_LAST_WASH,
  PURGE_PRIME_REAGENT_SECONDS,
  PURGE_REPEAT_TIMES,
  REAGENT,
  REAGENT_BOTTLE_ID,
  REAGENT_NAME,
  RECOGNITION,
  SOAP_LOADING_TIME,
  SOAP_TIME,
  SOAP_USE,
  SOAP_WASTE, STOP_CYCLE_SUPPORT,
  TEMPERATURE_OFFSET,
  TEMPERATURE_SUPPORT,
  TIME_BETWEEN_SOAP_AND_WATER,
  TYPE, WASH_START_SOAP_WATER,
  WATER_CYCLE,
  WATER_TIME_DURING_SOAP,
  WATER_TIME_PER_WATER_CYCLE,
  WATER_USE,
  WATER_WASTE,
} from '../constants.js'
import MachineModal from './MachineModal.jsx'

const EditMachineModal = ({ isOpen, onClose, machineEdited, classes, openPurgeModal, t, ...rest }) => {
  const handleSubmit = async (formFields) => {
    const machine = await editMachine(rest.machine.id, {
      additionalWaterTime: formFields[ADDITIONAL_WATER_TIME].value,
      afterWashDelay: formFields[AFTER_WASH_DELAY].value,
      atpMode: formFields[ATP_MODE].value,
      reagent: formFields[REAGENT].value,
      bottleWashCount: getBottleWashCount(formFields[SOAP_TIME].value, formFields[REAGENT_NAME].value),
      dbTimeZone: formFields[DB_TIME_ZONE].value,
      debugHandsRecognition: formFields[DEBUG_HANDS_RECOGNITION].value,
      debugMode: formFields[DEBUG_MODE].value,
      delayTimePerWaterCycle: formFields[DELAY_TIME_PER_WATER_CYCLE].value,
      enableVisualError: formFields[ENABLE_VISUAL_ERROR].value,
      recognition: formFields[RECOGNITION].value,
      handsRecognition: formFields[HANDS_RECOGNITION].value,
      installationDate: formFields[INSTALLATION_DATE].value._d,
      internalArea: formFields[INTERNAL_AREA].value,
      latherTime: formFields[LATHER_TIME].value,
      ledProgressBar: formFields[LED_PROGRESS_BAR].value,
      monitor: formFields[MONITOR].value,
      reagentBottleId: formFields[REAGENT_BOTTLE_ID].value,
      reagentName: formFields[REAGENT_NAME].value,
      soapLoadingTime: formFields[SOAP_LOADING_TIME].value,
      soapTime: formFields[SOAP_TIME].value,
      soapUse: formFields[SOAP_USE].value,
      soapWaste: formFields[SOAP_WASTE].value,
      temperatureOffset: formFields[TEMPERATURE_OFFSET].value,
      temperatureSupport: formFields[TEMPERATURE_SUPPORT].value,
      type: formFields[TYPE].value,
      waterCycle: formFields[WATER_CYCLE].value,
      waterTimeDuringSoap: formFields[WATER_TIME_DURING_SOAP].value,
      waterTimePerWaterCycle: formFields[WATER_TIME_PER_WATER_CYCLE].value,
      waterUse: formFields[WATER_USE].value,
      waterWaste: formFields[WATER_WASTE].value,
      timeBetweenSoapAndWater: formFields[TIME_BETWEEN_SOAP_AND_WATER].value,
      automaticSoapPurge: formFields[AUTOMATIC_SOAP_PURGE].value,
      monitorOrientation: formFields[MONITOR_ORIENTATION].value,
      publicPlacesSupport: formFields[PUBLIC_PLACES_SUPPORT].value,
      purgeMinutesAfterLastWash: formFields[PURGE_MINUTES_AFTER_LAST_WASH].value,
      primeReagentSeconds: formFields[PURGE_PRIME_REAGENT_SECONDS].value,
      purgeRepeatTimes: formFields[PURGE_REPEAT_TIMES].value,
      stopCycleSupport: formFields[STOP_CYCLE_SUPPORT].value,
      washStartSoapWater: formFields[WASH_START_SOAP_WATER].value,
      isSoapPurge: formFields[IS_SOAP_PURGE].value,
      isWaterPurge: formFields[IS_WATER_PURGE].value,
    })
    machineEdited(machine)
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('Edit machine')}
      onCancel={onClose}
      dialogPaper={classes.dialogPaper}
      hideActions
      showGoBackButton
    >
      <div>
        <MachineModal
          isEdit
          handleSubmit={handleSubmit}
          openPurgeModal={openPurgeModal}
          {...rest}
        />
      </div>
    </Modal>
  )
}

export default withStyles(adminUnitModalStyle)(withTranslation()(EditMachineModal))
