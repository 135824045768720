import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints'

/**
 * @param {object} contact
 * @param {string} contactId
 * @param {string} contact.name
 * @param {string} contact.email
 * @param {string} contact.phoneNumber
 * @param {boolean} contact.highTemperatureEmail
 * @param {boolean} contact.failedWashEmail
 * @param {boolean} contact.malfunctionsEmail
 * @param {boolean} contact.changeSoapEmail
 * @param {boolean} contact.highTemperatureSms
 * @param {boolean} contact.failedWashSms
 * @param {boolean} contact.malfunctionsSms
 * @param {boolean} contact.changeSoapSms
 */
export default (contact, contactId) => {
  const endpoint = `${apiEndpoints.contacts}/${contactId}`
  const URL = SERVER_PATH + endpoint
  return resolveRequest(axios.put(URL, contact, { withCredentials: true }))
}
