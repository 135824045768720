import getLanguageLocale from '../../../shared/multi-language/getLanguageLocale.js'
import {
  Typography,
  IconButton
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import ImageIcon from '@material-ui/icons/Image'
import ReactTable from 'react-table'
import activeCampaignLogsModalStyle from '../../../assets-soapy/jss/soapy/views/activeCampaignLogsModalStyle.jsx'
import Modal from '../../../components-soapy/Modal'
import addReactTableFilterPlaceholder from '../../../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../../../shared/react-table/defaultFilterMethod.js'
import tableStyle from '../../../shared/styles/tableSortIcon.css'
import moment from 'moment'

const DEFAULT_PAGE_SIZE = 10

const ActiveCampaignLogsModal = ({
  isOpen,
  title,
  isLoading,
  onCancel,
  classes,
  activeCampaignLogs,
  openCampaignItemsModal,
  t
}) => {
  const [filtered, setFiltered] = useState([])
  const [sorted, setSorted] = useState([])
  useEffect(() => {
    addReactTableFilterPlaceholder()
  })

  useEffect(() => {
    setFiltered([])
    setSorted([])
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      dialogPaper={classes.dialogPaper}
      hideActions
      isLoading={isLoading}
      onCancel={onCancel}
    >

      {isLoading
        ? <CircularProgress
            color='primary'
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />

        : <ReactTable
            data={activeCampaignLogs}
            style={tableStyle}
            defaultFilterMethod={defaultFilterMethod}
            columns={[
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <Typography variant='body2'>{t('Site name')}</Typography>
                  </div>
                ),
                Cell: ({ original }) => {
                  return <div>{_.get(original, 'site.name', '[deleted site]')}</div>
                },
                style: { textAlign: 'left' },
                filterable: true,
                accessor: 'site.name'
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'
                    }}
                  >
                    <Typography variant='body2'>{t('Start date')}</Typography>
                  </div>
                ),
                Cell: ({ original }) => {
                  const startDate = original.startDate
                  return <div>{startDate ? moment(startDate).locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm') : 'unknown'}</div>
                },
                style: { textAlign: 'left' },
                filterable: true,
                accessor: 'startDate'
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'
                    }}
                  >
                    <Typography variant='body2'>{t('End date')}</Typography>
                  </div>
                ),
                Cell: ({ original }) => {
                  const endDate = original.endDate
                  return <div>{endDate ? moment(endDate).locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm') : 'unknown'}</div>
                },
                style: { textAlign: 'left' },
                filterable: true,
                accessor: 'endDate'
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left',
                      marginLeft: 0
                    }}
                  >
                    <Typography variant='body2'>{t('Views')}</Typography>
                  </div>
                ),
                Cell: ({ original }) => {
                  return (
                    <IconButton
                      color='primary'
                      size='medium'
                      onClick={() => openCampaignItemsModal(original.campaignItemRinsingUrlsCSV ? original.campaignItemRinsingUrlsCSV.split(',') : [], original.campaignItemWelcomeUrlsCSV ? original.campaignItemWelcomeUrlsCSV.split(',') : [])}
                    >
                      <ImageIcon />
                    </IconButton>
                  )
                },
                style: { textAlign: 'left' },
                filterable: false,
                sortable: false
              }

            ]}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSorted={[
              {
                id: 'checkbox',
                desc: false
              }
            ]}
            minRows={0}
            showPaginationBottom={activeCampaignLogs.length > DEFAULT_PAGE_SIZE}
            className='-striped -highlight'
            nextText={t('Next')}
            filtered={filtered}
            onFilteredChange={filtered => { setFiltered(filtered) }}
            sorted={sorted}
            onSortedChange={sorted => { setSorted(sorted) }}
            pageText={t('Page')}
            ofText={t('Of')}
            rowsText={t('Rows')}
            previousText={t('Previous')}
          />}
    </Modal>
  )
}

export default withStyles(activeCampaignLogsModalStyle)(withTranslation()(ActiveCampaignLogsModal))
