import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export const forgotPassword = async (email) => {
  const endpoint = `${apiEndpoints.forgotPassword}`
  const URL = SERVER_PATH + endpoint
  const requestBody = { email }
  return resolveRequest(axios.post(URL, requestBody, { withCredentials: true }))
}
