// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle
} from '../../../../assets/jss/material-dashboard-pro-react.jsx'

export default theme => ({
  container: {
    ...container,
    zIndex: '4',
    paddingTop: '6rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px'
    }
  },
  cardFooter: { display: 'inline-block', textAlign: 'center' },

  cardTitle: {
    ...cardTitle,
    color: '#FFFFFF'
  },
  textCenter: {
    textAlign: 'center'
  },
  justifyContentCenter: {
    justifyContent: 'center !important'
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: '#FFFFFF'
    },
    marginLeft: '5px',
    marginRight: '5px'
  },
  inputAdornment: {
    marginRight: '18px'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)'
  },
  cardHeader: {
    marginBottom: '20px'
  },
  socialLine: {
    padding: '0.9375rem 0'
  }
})
