import React from 'react'
import withAuthentication from '../../containers/Authentication/withAuthentication';
import { withTranslation } from 'react-i18next';
import Button from '../../components/CustomButtons/Button'

// -----------------------Groups Table Header--------------------------//
const GroupsTableHeader = ({
  currentDashboardUser,
  setSelectedGroup,
  addGroupModal,
  setAddGroupModal,
  totalGroups,
  t
}) => {

  //-------------------Define Variables for showing Add Group Button Conditionally-----------------------//
  const { role, entity } = currentDashboardUser
  const roleName = role.name;
  const entityName = entity.name;
  const isShowAddGroupBtn = roleName === 'user' && (entityName === 'organization' || entityName === 'distributor')

  return (
    <div>
      {!isShowAddGroupBtn && <Button color="info"
        round
        id="groupAddBtn"
        onClick={() => {
          setAddGroupModal(!addGroupModal)
          setSelectedGroup(null)
        }}
        style={{ marginRight: 5 }}>
        {t('Add Group')}
      </Button>}
      <div style={{ textAlign: 'right' }}><b>{t('Total Groups')}: {totalGroups}</b></div>
    </div >
  )
}

export default withTranslation()(withAuthentication(GroupsTableHeader))

