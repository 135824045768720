import Button from '../../components/CustomButtons/Button.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import React from 'react'
import { withTranslation } from 'react-i18next'

const DashboardUsersTableHeader = ({
  openDashboardUserModal,
  t,
  hasPermission
}) => {
  return (

    hasPermission({ name: 'dashboard users', action: 'create' }) && <Button
      color='info'
      round
      id='dashboardUsersAddDashboardUser'
      onClick={openDashboardUserModal}
      style={{ marginRight: 5 }}
                                                                    >
      {t('Add Dashboard User')}
                                                                    </Button>
  )
}

export default withTranslation()(withAuthentication(DashboardUsersTableHeader))
