import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const getReportVersion = async () => {
    const URL = SERVER_PATH + apiEndpoints.report + '/version'
    return resolveRequest(axios.get(URL, {
        withCredentials: true,
    }))
}

export { getReportVersion }
