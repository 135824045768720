import React from 'react';
import ReactGA from 'react-ga';
import AuthContext from './AuthContext.js';

class AuthContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { accessLevel: props.accessLevel, languageCodes: props.languageCodes, currentDashboardUser: { ...props.currentDashboardUser } };

    }

    login = responseData => {
        this.setState({ accessLevel: 'basic', currentDashboardUser: { ...responseData } })
        ReactGA.set({
            userId: responseData.id
        })
    };

    machineLogin = () => {
        this.setState({ accessLevel: 'machine' })
    }
    hasPermission = (permission) => {
        const { currentDashboardUser } = this.state
        const permissions = currentDashboardUser.role.permissions
        return Boolean(permissions[permission.name] && permissions[permission.name][permission.action])
    }

    logout = () => {
        this.setState({ accessLevel: 'auth' });
        ReactGA.set({
            userId: null
        })
    };

    updateCurrentDashboardUser = currentDashboardUser => {
        this.setState({ currentDashboardUser: { ...this.state.currentDashboardUser, ...currentDashboardUser } });
    };

    render() {
        return (
            <AuthContext.Provider
                value={{
                    accessLevel: this.state.accessLevel,
                    currentDashboardUser: this.state.currentDashboardUser,
                    login: this.login,
                    hasPermission: this.hasPermission,
                    languageCodes: this.state.languageCodes,
                    machineLogin: this.machineLogin,
                    logout: this.logout,
                    updateCurrentDashboardUser: this.updateCurrentDashboardUser
                }}
            >
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthContainer;
