import {
  FormControlLabel, Grid,
  Switch,
  Typography
} from '@material-ui/core'
import React from 'react'
import { withTranslation } from 'react-i18next'

const HIGH_TEMPERATURE_EMAIL = 'highTemperatureEmail'
const FAILED_WASH_EMAIL = 'failedWashEmail'
const MALFUNCTIONS_EMAIL = 'malfunctionsEmail'
const CHANGE_SOAP_EMAIL = 'changeSoapEmail'
const HIGH_TEMPERATURE_SMS = 'highTemperatureSms'
const FAILED_WASH_SMS = 'failedWashSms'
const MALFUNCTIONS_SMS = 'malfunctionsSms'
const CHANGE_SOAP_SMS = 'changeSoapSms'

const NotificationSettingsForm = ({ formFields, handleSwitchChange, spacing, t }) => {
  return (
    <>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item xs={spacing || 2}>
          <Typography variant='subheading' item>{t('Temperature')}</Typography>
        </Grid>
        <Grid item>
          <FormControlLabel id='notificationSettingsHighTemperatureEmailSwitch' control={<Switch color='primary' checked={formFields[HIGH_TEMPERATURE_EMAIL]} onChange={(e) => handleSwitchChange(e, HIGH_TEMPERATURE_EMAIL)} />} label={t('Email')} />
        </Grid>
        <Grid item>
          <FormControlLabel id='notificationSettingsHighTemperaturePhoneSwitch' control={<Switch color='primary' checked={formFields[HIGH_TEMPERATURE_SMS]} onChange={(e) => handleSwitchChange(e, HIGH_TEMPERATURE_SMS)} />} label={t('SMS')} />
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item xs={spacing || 2}>
          <Typography variant='subheading' item>{t('Failed wash')}</Typography>
        </Grid>
        <Grid item>
          <FormControlLabel id='notificationSettingsFailedWashEmailSwitch' control={<Switch color='primary' disabled checked={formFields[FAILED_WASH_EMAIL]} onChange={(e) => handleSwitchChange(e, FAILED_WASH_EMAIL)} />} label={t('Email')} />
        </Grid>
        <Grid item>
          <FormControlLabel id='notificationSettingsFailedWashPhoneSwitch' control={<Switch color='primary' disabled checked={formFields[FAILED_WASH_SMS]} onChange={(e) => handleSwitchChange(e, FAILED_WASH_SMS)} />} label={t('SMS')} />
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item xs={spacing || 2}>
          <Typography variant='subheading' item>{t('Malfunctions')}</Typography>
        </Grid>
        <Grid item>
          <FormControlLabel id='notificationSettingsMalfunctionsEmailSwitch' control={<Switch color='primary' disabled checked={formFields[MALFUNCTIONS_EMAIL]} onChange={(e) => handleSwitchChange(e, MALFUNCTIONS_EMAIL)} />} label={t('Email')} />
        </Grid>
        <Grid item>
          <FormControlLabel id='notificationSettingsMalfunctionsPhoneSwitch' control={<Switch color='primary' disabled checked={formFields[MALFUNCTIONS_SMS]} onChange={(e) => handleSwitchChange(e, MALFUNCTIONS_SMS)} />} label={t('SMS')} />
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item xs={spacing || 2}>
          <Typography variant='subheading' item>{t('Change soap')}</Typography>
        </Grid>
        <Grid item>
          <FormControlLabel id='notificationSettingsChangeSoapEmailSwitch' control={<Switch color='primary' checked={formFields[CHANGE_SOAP_EMAIL]} onChange={(e) => handleSwitchChange(e, CHANGE_SOAP_EMAIL)} />} label={t('Email')} />
        </Grid>
        <Grid item>
          <FormControlLabel id='notificationSettingsChangeSoapPhoneSwitch' control={<Switch color='primary' checked={formFields[CHANGE_SOAP_SMS]} onChange={(e) => handleSwitchChange(e, CHANGE_SOAP_SMS)} />} label={t('SMS')} />
        </Grid>
      </Grid>
    </>
  )
}

export default withTranslation()(NotificationSettingsForm)
