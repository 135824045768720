export default theme => {
  return {
    fileUpload: {
      backgroundColor: '#faf9f5',
      border: '2px solid #dfdfdf',
      borderRadius: '5px',
      width: '100%',
      margin: '0 auto',
      paddingTop: '0.5rem',
      paddingRight: '1rem',
      paddingBottom: '0.5rem',
      paddingLeft: '1rem',
    },
    fileUploadError: {
      border: `2px solid ${theme.palette.error.light}`
    },
    addImageBtn: {
      color: theme.palette.primary.dark
    },
    imageUploadWrap: {
      marginTop: '1.5rem',
      border: '3px dashed #dfdfdf',
      position: 'relative',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        border: '3px dashed #ffffff'
      }
    },
    fileUploadImage: {
      marginTop: '1.5rem',
      width: '100%',
      position: 'relative',
      cursor: 'pointer',
    },
    fileUploadInput: {
      position: 'absolute',
      margin: '0',
      padding: '0',
      width: '100%',
      height: '100%',
      outline: 'none',
      opacity: '0',
      cursor: 'pointer'
    },
    actions: {
      textAlign: 'center'
    },
    fileUploadBtn: {
      width: '50px',
      margin: '0',
      marginTop: '5px',
      border: 'none',
      padding: '10px',
      borderRadius: '4px',
      outline: 'none',
      textTransform: 'uppercase',
      fontWeight: '700'
    },
    title: {
      textAlign: 'center'
      // marginBottom: '0.2rem'
    }
  }
}
