import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';
import AddAlert from '@material-ui/icons/AddAlert';
import Settings from '@material-ui/icons/Settings';
import GridContainer from '../.././components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import Snackbar from '../../components/Snackbar/Snackbar.jsx';
import Card from '../../components-soapy/Card/Card.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import validationFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import sweetAlertStyle from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import withAuthentication from '../../containers/Authentication/withAuthentication.js';
import updateMachineSettings from '../../endpoint-requests/machine-update-settings/machineUpdateSettings';
import LoadingButton from '../../components-soapy/LoadingButton';
import fetchMachineSettings from "../../endpoint-requests/machine-update-settings/fetchMachineSettings";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withTranslation} from 'react-i18next'
import {Tooltip} from '@material-ui/core'
import Button from "../../components/CustomButtons/Button";
import ResetCacheModalContainer from "./resetCacheModalContainer";

const formStyle = {
	...validationFormsStyle,
	cardHeaderIcon: {
		color: '#ffffff'
	}
};

const CustomSweetAlert = ({classes, ...rest}) => (
	<SweetAlert
		{...rest}
		confirmBtnCssClass={classes.button + ' ' + classes.success}
	/>
);

const StyledSweetAlert = withStyles(sweetAlertStyle)(CustomSweetAlert);

const ENTER_KEY = 13;
const VERIFY_URL_MESSAGE = 'should start with http';

const DASHBOARD_SERVER_URL = 'dashboardServerUrl';
const EVENT_USAGE_URL = 'createUsageEventUrl';
const LOG_URL = 'logUrl';
const FACE_RECOGNITION_SERVER_URL = 'faceRecognitionServerUrl';
const UPDATE_PARAMETERS_TIME = 'updateParametersTime';
const HAND_RECOGNITION_URL = 'handRecognitionUrl';

const VALIDATION_STATE = 'ValidationState';

const fieldNames = [
	DASHBOARD_SERVER_URL,
	EVENT_USAGE_URL,
	LOG_URL,
	FACE_RECOGNITION_SERVER_URL,
	HAND_RECOGNITION_URL,
	UPDATE_PARAMETERS_TIME,
];

const verifyHttp = (text) => {
	return text.slice(0, 4) === 'http'
};

const mapFieldNameToValidationHandler = {
	[DASHBOARD_SERVER_URL]: text => {
		return verifyHttp(text);
	},
	[EVENT_USAGE_URL]: text => {
		return verifyHttp(text);
	},
	[LOG_URL]: text => {
		return verifyHttp(text);
	},
	[FACE_RECOGNITION_SERVER_URL]: text => {
		return verifyHttp(text);
	},
	[HAND_RECOGNITION_URL]: text => {
		return verifyHttp(text);
	},
};

const mapFieldNameToInvalidFieldNotificationMessage = {
	[DASHBOARD_SERVER_URL]: 'Dashboard Server URL ' + VERIFY_URL_MESSAGE,
	[EVENT_USAGE_URL]: 'Dashboard Server URL ' + VERIFY_URL_MESSAGE,
	[LOG_URL]: 'Dashboard Server URL ' + VERIFY_URL_MESSAGE,
	[FACE_RECOGNITION_SERVER_URL]: 'Face recognition server URL ' + VERIFY_URL_MESSAGE,
	[HAND_RECOGNITION_URL]: 'Hand recognition URL ' + VERIFY_URL_MESSAGE,
};


class AdminSettings extends React.Component {

	constructor(props) {
		super(props);
		let fieldsStates = {};
		fieldNames.forEach(fieldName => {
			fieldsStates[fieldName] = '';
			fieldsStates[fieldName + VALIDATION_STATE] = '';
		});

		this.state = {
			...fieldsStates,
			isSubmitSuccessfulModalActive: false,
			isErrorNotificationActive: false,
			errorNotificationMessage: '',
			isAwaitingResponse: true,
			isSettingsChange: false
		};
	}

	async componentDidMount() {
		const data = await fetchMachineSettings();
		if (data) {
			const {
				dashboardServerUrl,
				faceRecognitionServerUrl,
				updateParametersTime,
				createUsageEventUrl,
				logUrl,
				handRecognitionUrl
			} = data;
			this.setState({
				dashboardServerUrl,
				faceRecognitionServerUrl,
				updateParametersTime,
				createUsageEventUrl,
				logUrl,
				handRecognitionUrl,
				isAwaitingResponse: false,
			})

		}
	}

	componentDidUpdate() {
		const {
			t
		} = this.props
		this.validationErrors = {
			faceRecognitionServerUrlFieldsDontMatch: {
				message: t("New password fields don't match"),
				affectedFieldsNames: [FACE_RECOGNITION_SERVER_URL, UPDATE_PARAMETERS_TIME]
			},
			dashboardServerUrlInvalid: {
				message: t('Current password is invalid'),
				affectedFieldsNames: [DASHBOARD_SERVER_URL]
			},
			createUsageEventUrlInvalid: {
				message: t('Event Usage Url Is Invalid'),
				affectedFieldsNames: [EVENT_USAGE_URL]
			},
			logUrlInvalid: {
				message: t('Log Url Is Invalid'),
				affectedFieldsNames: [LOG_URL]
			},
			unknownError: {
				message: t("Could not complete the request."),
				affectedFieldsNames: []
			}
		};
	}

	validateForm = () => {
		for (let i = 0; i < fieldNames.length - 1; i++) {
			const fieldName = fieldNames[i];
			const fieldValue = this.state[fieldName];
			const isValid = mapFieldNameToValidationHandler[fieldName](fieldValue);
			if (!isValid) {
				return {
					isValid: false,
					isResetCacheModalOpen: false,
					error: {
						message: mapFieldNameToInvalidFieldNotificationMessage[fieldName],
						affectedFieldsNames: [fieldName]
					}
				};
			}
		}
		return {isValid: true};
	};

	submitForm = async () => {
		const {
			dashboardServerUrl,
			faceRecognitionServerUrl,
			updateParametersTime,
			createUsageEventUrl,
			logUrl,
			handRecognitionUrl,
		} = this.state;

		this.activateSpinner();
		const didSucceed = await updateMachineSettings(
			dashboardServerUrl,
			faceRecognitionServerUrl,
			updateParametersTime,
			createUsageEventUrl,
			logUrl,
			handRecognitionUrl
		);
		this.disableSpinner();
		this.handleResponse(didSucceed);
	};

	activateSpinner = () => {
		this.spinnerTimeout = setTimeout(
			function () {
				this.setState({isAwaitingResponse: true});
			}.bind(this),
			300
		);
	};

	disableSpinner = () => {
		clearTimeout(this.spinnerTimeout);
		this.setState({isAwaitingResponse: false});
	};

	resetFormState = async () => {
		const {data} = await fetchMachineSettings();
		if (data) {
			const {
				dashboardServerUrl,
				faceRecognitionServerUrl,
				updateParametersTime,
				createUsageEventUrl,
				logUrl,
				handRecognitionUrl
			} = data;
			this.setState({
				dashboardServerUrl,
				faceRecognitionServerUrl,
				updateParametersTime,
				createUsageEventUrl,
				logUrl,
				handRecognitionUrl
			})
		}
	};

	handleResponse = (didSucceed) => {
		if (!didSucceed) {
			this.resetFormState();
			this.showErrorNotification(this.validationErrors.unknownError.message);
		} else {
			this.setState({isSettingsChange: false})
		}
	};

	changeValidationStateToError = affectedFieldsNames => {
		affectedFieldsNames.forEach(fieldName => {
			this.setState({[fieldName + VALIDATION_STATE]: 'error'});
		});
	};

	hideSuccessfulSubmitModal = () => {
		this.setState({
			isSubmitSuccessfulModalActive: false
		});
	};

	showErrorNotification = errorMessage => {
		const {isErrorNotificationActive} = this.state;
		if (!isErrorNotificationActive) {
			this.setState({
				isErrorNotificationActive: true,
				errorNotificationMessage: errorMessage
			});
			setTimeout(
				function () {
					this.setState({isErrorNotificationActive: false});
				}.bind(this),
				6000
			);
		}
	};

	processForm = () => {
		const {isValid, error} = this.validateForm();
		if (isValid) {
			this.submitForm();
		} else {
			this.showErrorNotification(error.message);
			this.changeValidationStateToError(error.affectedFieldsNames);
		}
	};

	handleTextChange = (event, fieldName) => {
		const text = event.target.value;
		this.setState({[fieldName]: text, isSettingsChange: true});
		fieldNames.forEach(fieldName => {
			this.setState({[fieldName + VALIDATION_STATE]: ''});
		});
	};

	handleKeyDown = event => {
		if (event.which === ENTER_KEY) {
			this.processForm();
		}
	};

	handleSaveBtnClick = () => {
		this.processForm();
	};

	componentWillUnmount() {
		clearTimeout(this.spinnerTimeout);
		this.spinnerTimeout = null;
	}

	openCloseResetCacheModal = () => {
		this.setState({
			isResetCacheModalOpen: !this.state.isResetCacheModalOpen
		})
	}

	render() {
		const {
			isSubmitSuccessfulModalActive,
			isAwaitingResponse,
			isSettingsChange,
			isResetCacheModalOpen,
		} = this.state;

		const {classes, t, currentDashboardUser} = this.props;

		return (
			<GridContainer id="content-pane-layout" justify="center">
				{isAwaitingResponse ?
					<CircularProgress
						color="primary"
						style={{position: 'absolute', top: '50%', left: '50%'}}
					/> :
					<GridItem md={9}>
						<Card>
							<CardHeader>
								<Tooltip title={t('Settings')}>
									<CardIcon color="info">
										<Settings
											className={classes.cardHeaderIcon}
											style={{fontSize: 30}}
										/>
									</CardIcon>
								</Tooltip>
							</CardHeader>
							<CardBody>
								<div>
									<form>
										<GridContainer
											direction="column"
											justify="center"
											alignItems="center"
											id="card-body-section"
										>
											<GridItem
												style={{minWidth: '60vh'}}
												id="dashboard-server-url-grind-item"
											>
												<CustomInput
													id="dashboard-server-url-custom-input"
													error={
														this.state[DASHBOARD_SERVER_URL + VALIDATION_STATE] ===
														'error'
													}
													labelText={t('Dashboard Server URL')}
													inputProps={{
														value: this.state[DASHBOARD_SERVER_URL],
														type: 'name',
														disabled: currentDashboardUser.role.name === 'user',
														onChange: event =>
															this.handleTextChange(event, DASHBOARD_SERVER_URL),
														onKeyDown: event => this.handleKeyDown(event)
													}}
													formControlProps={{
														fullWidth: true
													}}
												/>
											</GridItem>
											<GridItem
												style={{minWidth: '60vh'}}
												id="event-usage-url-grind-item"
											>
												<CustomInput
													id="event-usage-url-custom-input"
													error={
														this.state[EVENT_USAGE_URL + VALIDATION_STATE] ===
														'error'
													}
													labelText={t('Event Usage URL')}
													inputProps={{
														value: this.state[EVENT_USAGE_URL],
														type: 'name',
														disabled: currentDashboardUser.role.name === 'user',
														onChange: event =>
															this.handleTextChange(event, EVENT_USAGE_URL),
														onKeyDown: event => this.handleKeyDown(event)
													}}
													formControlProps={{
														fullWidth: true
													}}
												/>
											</GridItem>
											<GridItem
												style={{minWidth: '60vh'}}
												id="log-url-grind-item"
											>
												<CustomInput
													id="log-url-custom-input"
													error={
														this.state[LOG_URL + VALIDATION_STATE] ===
														'error'
													}
													labelText={t('Log URL')}
													inputProps={{
														value: this.state[LOG_URL],
														type: 'name',
														disabled: currentDashboardUser.role.name === 'user',
														onChange: event =>
															this.handleTextChange(event, LOG_URL),
														onKeyDown: event => this.handleKeyDown(event)
													}}
													formControlProps={{
														fullWidth: true
													}}
												/>
											</GridItem>
											<GridItem
												style={{minWidth: '60vh'}}
												id="face-recognition-server-url-grid-item"
											>
												<CustomInput
													id="face-recognition-server-url-custom-input"
													error={
														this.state[FACE_RECOGNITION_SERVER_URL + VALIDATION_STATE] ===
														'error'
													}
													labelText={t('Face Recognition Server URL')}
													inputProps={{
														value: this.state[FACE_RECOGNITION_SERVER_URL],
														type: 'name',
														disabled: currentDashboardUser.role.name === 'user',
														onChange: event =>
															this.handleTextChange(event, FACE_RECOGNITION_SERVER_URL),
														onKeyDown: event => this.handleKeyDown(event)
													}}
													formControlProps={{
														fullWidth: true
													}}
												/>
											</GridItem>
											<GridItem
												style={{minWidth: '60vh'}}
											>
												<CustomInput
													error={
														this.state[HAND_RECOGNITION_URL + VALIDATION_STATE] ===
														'error'
													}
													labelText={t('Hand recognition URL')}
													inputProps={{
														value: this.state[HAND_RECOGNITION_URL],
														type: 'name',
														disabled: currentDashboardUser.role.name === 'user',
														onChange: event =>
															this.handleTextChange(event, HAND_RECOGNITION_URL),
														onKeyDown: event => this.handleKeyDown(event)
													}}
													formControlProps={{
														fullWidth: true
													}}
												/>
											</GridItem>
											<GridItem
												style={{minWidth: '60vh'}}
												id="update-parameters-time-grid-item"
											>
												<CustomInput
													id="update-parameters-time-custom-input"
													error={
														this.state[
														UPDATE_PARAMETERS_TIME + VALIDATION_STATE
															] === 'error'
													}
													labelText={t('Update Parameters Time')}
													inputProps={{
														value: this.state[UPDATE_PARAMETERS_TIME],
														type: 'number',
														disabled: currentDashboardUser.role.name === 'user',
														onChange: event =>
															this.handleTextChange(event, UPDATE_PARAMETERS_TIME),
														onKeyDown: event => this.handleKeyDown(event)
													}}
													formControlProps={{
														fullWidth: true
													}}
												/>
											</GridItem>
										</GridContainer>
										{
											currentDashboardUser.role.name !== 'user' && <GridContainer
												justify="center"
												alignItems="center"
												id="card-footer-section"
											> <GridItem>
												<LoadingButton
													color="info"
													onClick={this.handleSaveBtnClick}
													fullWidth={true}
													disabled={!isSettingsChange}
													isLoading={isAwaitingResponse}
												>
													{t('Save')}
												</LoadingButton>
											</GridItem>

											</GridContainer>
										}
									</form>
									<div id="alerts-section">
										<Snackbar
											place="tc"
											color="danger"
											icon={AddAlert}
											message={this.state.errorNotificationMessage}
											open={this.state.isErrorNotificationActive}
											closeNotification={() =>
												this.setState({isErrorNotificationActive: false})
											}
											close
										/>
										{isSubmitSuccessfulModalActive ? (
											<StyledSweetAlert
												success
												title={t("Saved") + '!'}
												confirmBtnText={t("Ok")}
												style={{
													position: 'absolute'
												}}
												onConfirm={() => this.hideSuccessfulSubmitModal()}
												onCancel={() => this.hideSuccessfulSubmitModal()}
											/>
										) : null}
									</div>
								</div>
							</CardBody>
						</Card>
					</GridItem>}
				<ResetCacheModalContainer
					isOpen={isResetCacheModalOpen}
					onClose={this.openCloseResetCacheModal}
				/>
				{
					currentDashboardUser.role.name !== 'user' &&
					<GridItem md={9} style={{marginTop: '4%'}}>
						<Card>
							<CardHeader style={{paddingBottom: 0}}>
								<Tooltip title={t('Reset cache')}>
									<CardIcon color="info">
										<Settings
											className={classes.cardHeaderIcon}
											style={{fontSize: 30}}
										/>
									</CardIcon>
								</Tooltip>
							</CardHeader>
							<CardBody style={{paddingTop: 0}}>
								<div style={{textAlign: "center"}}>
									<h5>{t('Reset Cache')}</h5>
									<Button
										color="info"
										onClick={this.openCloseResetCacheModal}
									>
										{t('Reset')}
									</Button>

								</div>
							</CardBody>
						</Card>
					</GridItem>
				}
			</GridContainer>
		);
	}
}

AdminSettings.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withAuthentication(withStyles(formStyle)(withTranslation()(AdminSettings)));
