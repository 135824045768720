import React from 'react'
import Map from './Map'
import Modal from '@material-ui/core/Modal'
import Box from '@material-ui/core/Box'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'hidden',
};


function MapApp({ sites, setOpenMap, openMap }) {
    const handleClose = () => {
        setOpenMap(false);
    }

    return (
        <Modal
            open={openMap}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Map
                    defaultOptions={{ scaleControl: true }}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=visualization,drawing,geometry,places`}
                    loadingElement={<div style={{ height: '100%' }}> Loading... </div>}
                    containerElement={<div style={{ height: '85vh' }} />}
                    mapElement={<div style={{ height: '85%' }} />}
                    sites={sites}
                />
            </Box>
        </Modal>
    )
}

export default MapApp
