import React from 'react'
import withClearCache from '../containers/Cache/withClearCache'

const ClearCacheComponent = withClearCache(MainApp)

function Cache () {
  return <ClearCacheComponent />
}

function MainApp () {
  return null
}

export default Cache
