import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'
import failedSession from '../failedSession'

const getUsers = async () => {
  try {
    const URL = SERVER_PATH + apiEndpoints.washers

    const { data: users } = await axios.get(URL, {
      withCredentials: true
    }
    )
    return users
  } catch (e) {
    failedSession(e)
    return { error: 'unknown' }
  }
}

export default getUsers
