import { useState, useEffect } from 'react'

const all = (obj) => {
  for (const key in obj) {
    if (!obj[key]) {
      return false
    }
  }
  return true
}

const any = (obj) => {
  for (const key in obj) {
    if (obj[key]) {
      return true
    }
  }
  return false
}

const useSelector = (ids) => {
  const [selected, setSelected] = useState({})

  useEffect(() => {
    const selected = {}
    for (const id of ids) {
      selected[id] = false
    }
    setSelected(selected)
  }, [JSON.stringify(ids)]) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleSelectAll = () => {
    const isAllSelected = checkIsAllSelected()
    if (isAllSelected) {
      unselectAll()
    } else {
      selectAll()
    }
  }

  const selectAll = () => {
    const s = {}
    for (const id in selected) {
      s[id] = true
    }
    setSelected(s)
  }

  const unselectAll = () => {
    const s = {}
    for (const id in selected) {
      s[id] = false
    }
    setSelected(s)
  }

  const checkIsAllSelected = () => all(selected)

  const checkIsAnySelected = () => any(selected)

  const getSelected = () => {
    const result = []
    for (const id in selected) {
      if (selected[id]) {
        result.push(id)
      }
    }
    return result
  }

  const select = (id) => {
    setSelected({
      ...selected,
      [id]: !selected[id]
    })
  }
  const checkIsChecked = (id) => selected[id]

  return {
    selected,
    checkIsChecked,
    getSelected,
    toggleSelectAll,
    selectAll,
    unselectAll,
    select,
    checkIsAllSelected,
    checkIsAnySelected
  }
}

export default useSelector
