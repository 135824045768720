import axios from 'axios'
import failedSession from '../failedSession'
import { apiEndpoints, SERVER_PATH } from '../endpoints'

export default async (id) => {
  const endpoint = `${apiEndpoints.dashboardUsers}/${id}/notification-settings`
  const URL = SERVER_PATH + endpoint
  try {
    const res = await axios.get(URL, { withCredentials: true })
    return { didSucceed: true, data: res.data }
  } catch (e) {
    failedSession(e)
    return { didSucceed: false, errprType: 'SERVER_ERROR' }
  }
}
