import React from 'react'
// @material-ui/core components
import { withTranslation } from 'react-i18next'
import EditProfile from './EditProfile'
import { useSelector } from 'react-redux'
import { allPages, pages } from '../../routes/pages'

function EditProfileComponent (props) {
  return (
    <div>
      <EditProfile/>
    </div>
  )
}

export default (withTranslation()(EditProfileComponent))
