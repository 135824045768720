// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect, useRef, useState } from 'react'
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'
import { withTranslation } from 'react-i18next'
import mapModalStyle from '../../../../assets-soapy/jss/soapy/views/mapModalStyle.jsx'
import Modal from '../../../../components-soapy/Modal.jsx'
import getCookie from '../../../../shared/cookie/get-cookie'
import GoogleMarkersWrapper from './GoogleMarkersWrapper'

const getRegularMap = (locations) => {
  return withScriptjs(
    withGoogleMap(({ openedInfoWindow, handleMarkerClick, handleInfoWindowClose, role }) => (

      <GoogleMap
        defaultCenter={{ lat: 0, lng: 0 }}
        ref={map => {
          if (map) {
            const bounds = new window.google.maps.LatLngBounds()
            Object.values(locations).forEach((c, i) => {
              const { latitude, longitude } = c
              if (!latitude && !longitude && latitude !== 0 && longitude !== 0) {
                return
              }
              bounds.extend(new window.google.maps.LatLng(
                latitude,
                longitude
              ))
            })
            map.fitBounds(bounds)
          }
        }}

        defaultOptions={{
          scrollwheel: true,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: false,
          mapTypeControl: false
        }}
      >
        <GoogleMarkersWrapper role={role} locations={locations} />
      </GoogleMap>
    ))
  )
}

const cachedMaps = {}

const MapModal = ({ isOpen, onClose, locations, role, classes, t }) => {
  const getLangCode = () => {
    return getCookie('language') === 'cn' ? 'zh' : (getCookie('language') || 'en')
  }

  const title = t('Map')

  const RegularMapComponent = getRegularMap(locations)
  const API = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
  const [googleMapsUrl, setGoogleMapsUrl] = useState(`${API}&language=${getLangCode()}`)

  const [mapLanguage, setMapLanguage] = useState(getLangCode())
  const language = getCookie('language')

  const updateGoogleMapsLanguage = () => {
    const langCode = getLangCode()
    setGoogleMapsUrl(`${API}&language=${langCode}`)
    setMapLanguage(langCode)
    cachedMaps[mapLanguage] = { ...window.google.maps }
    if (langCode in cachedMaps) {
      window.google.maps = cachedMaps[langCode] // load Maps API from cache
    }
  }

  useEffect(() => {
    if (window.google) {
      updateGoogleMapsLanguage()
    }
  }, [language]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      dialogPaper={classes.dialogPaper}
      hideActions
      showGoBackButton
      onCancel={onClose}
      t={t}
    >
      <RegularMapComponent
        googleMapURL={googleMapsUrl}
        loadingElement={<div style={{ height: '100%' }} />}
        role={role}
        containerElement={
          <div
            style={{
              height: '70vh',
              width: '70vw',
              borderRadiuss: '6px',
              overflow: 'hidden',
              marginTop: 20
            }}
          />
        }
        mapElement={<div style={{ height: '100%' }} />}
      />

    </Modal>
  )
}

export default withStyles(mapModalStyle)(withTranslation()(MapModal))
