const filterRoutesByPaths = (routes, paths) => {
  return routes.reduce((acc, route) => {
    if (route.collapse) {
      const routeWithFilteredViews = { ...route, views: [...filterRoutesByPaths(route.views, paths)] }
      if (routeWithFilteredViews.views.length) {
        acc.push(routeWithFilteredViews)
      }
    } else if (paths[route.layout + route.path]) {
      acc.push(route)
    }
    return acc
  }, [])
}

export default filterRoutesByPaths
