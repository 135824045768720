import fetchOrganizations from '../../endpoint-requests/organization/fetchOrganizations.js'
import ProceedModal from '../../components-soapy/ProceedModal.jsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useMachine } from '@xstate/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import DeleteModal from '../../components-soapy/ConfirmationModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import Snackbar from '../../components/Snackbar/Snackbar.jsx'
import addDistributorUnits from '../../endpoint-requests/distributor/addDistributorUnits'
import deleteDistributor from '../../endpoint-requests/distributor/deleteDistributor.js'
import fetchDistributors from '../../endpoint-requests/distributor/fetchDistributors'
import { getDistributorUnits } from '../../endpoint-requests/distributor/getDistributorUnits'
import fetchMachinesLocations from '../../endpoint-requests/machine/getMachines.js'
import DistributorModal from './modals/DistributorModal'
import DistributorsTable from './DistributorsTable'
import DistributorsTableHeader from './DistributorsTableHeader.jsx'
import DistributorsMachinesModal from '../../components-soapy/DistributorsMachinesModal.jsx'

const unitsMachine = Machine({
    id: 'units',
    initial: 'loadDistributorData',
    context: {
      distributors: {},
      machines: [],
      organizations: {},
      error: '',
      isLoadingMachines: false,
      distributor: {},
      displayedMachineIds: [],
      isDeleteModalOpen: false,
      isSuccessNotificationVisible: false
    },
    states: {
      loadDistributorData: {
        invoke: {
          src: (context, event) => Promise.all([
            fetchDistributors(),
            fetchOrganizations({ order: 'asc', orderBy: 'name' })
          ]),
          onDone: {
            target: 'main',
            actions: assign({
              distributors: (context, event) => event.data[0],
              organizations: (context, event) => {
                return event.data[1].reduce((acc, organization) => {
                  acc[organization.id] = organization.name
                  return acc
                }, {})
              }
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message
            })
          }
        }
      },
      failedFetchingData: {
        type: 'final'
      },
      deleteModal: {
        on: {
          CLOSE_DELETE_MODAL: 'main',
          DISTRIBUTOR_DELETED: {
            target: 'main',
            actions: ['deleteDistributor']
          },
          CLOSE_DISTRIBUTOR_MODAL: 'loadDistributorData',
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },

      main: {
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        on: {
          OPEN_DISTRIBUTOR_MODAL: {
            target: 'distributorModal',
            actions: ['setDistributor', 'hideSuccessNotification']
          },
          OPEN_DELETE_MODAL: {
            target: 'deleteModal',
            actions: ['setDistributor', 'hideSuccessNotification']
          },
          OPEN_MACHINES_MODAL: {
            target: 'machineModal',
            actions: ['setDistributor', 'hideSuccessNotification']
          },
          HIDE_SUCCESS_NOTIFICATION: {
            actions: ['hideSuccessNotification']
          }

        }
      },
      distributorModal: {
        on: {
          CLOSE_DISTRIBUTOR_MODAL: 'main',
          DISTRIBUTOR_EDITED: {
            target: 'main',
            actions: ['editDistributor']
          },
          DISTRIBUTOR_ADDED: {
            target: 'main',
            actions: ['addDistributor']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      proceedModal: {
        on: {
          CLOSE_PROCEED_MODAL: 'machineModal',
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      machineModal: {
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        on: {
          CLOSE_MACHINE_MODAL: 'main',
          OPEN_PROCEED_MODAL: {
            target: 'proceedModal',
            actions: ['setDisplayedMachines', 'hideSuccessNotification']
          },
          DISTRIBUTOR_ADDED: 'loadDistributorData',
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        },
        entry: ['showMachineLoading'],
        invoke: {
          src: (context) => Promise.all([fetchMachinesLocations({ fetchOrphans: true }), getDistributorUnits(context.distributor.id, { onlyIds: true })]),
          onDone: {
            actions: assign({
              displayedMachineIds: (context, event) => event.data[1].map(machine => machine.id),
              machines: (context, event) => event.data[0],
              isLoadingMachines: (context, event) => false
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message,
              isLoadingMachines: (context, event) => false
            })
          }
        }
      }

    }
  },
  {
    actions: {
      showMachineLoading: assign({
        isLoadingMachines: true
      }),
      showSuccessNotification: assign({
        isSuccessNotificationVisible: true
      }),
      hideSuccessNotification: assign({
        isSuccessNotificationVisible: false
      }),
      editDistributor: assign({
        distributors: (context, event) => context.distributors.map(distributor => distributor.id === event.distributor.id ? event.distributor : distributor)
      }),
      addDistributor: assign({
        distributors: (context, event) => [event.distributor, ...context.distributors]
      }),
      setDistributor: assign({
        distributor: (context, event) => event.distributor
      }),
      deleteDistributor: assign({
        distributors: (context, event) => context.distributors.filter(distributor => distributor.id !== event.distributor.id)
      }),
      setDisplayedMachines: assign({
        displayedMachineIds: (context, event) => event.displayedMachineIds
      })
    },
    guards: {
      isSuccessNotificationVisible: (context, event) => !!context.isSuccessNotificationVisible
    }

  }
)

const DistributorsContainer = ({ t }) => {
  const [current, send] = useMachine(unitsMachine)
  switch (current.value) {
    case 'loadDistributorData':
      return (
        <CircularProgress
          color="primary"
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t('Session Is Over Error')}</div>
    default: {
      const {
        distributors,
        machines,
        distributor,
        isSuccessNotificationVisible,
        organizations,
        isLoadingMachines,
        displayedMachineIds
      } = current.context
      const isDistributorModalOpen = current.value === 'distributorModal'
      const isDeleteModalOpen = current.value === 'deleteModal'
      const isProceedModalOpen = current.value === 'proceedModal'
      const isMachinesModalOpen = current.value === 'machineModal' || current.value === 'proceedModal'

      return (
        <FullPagePaper>
          <Snackbar
            place="tc"
            color="success"
            icon={NotificationsIcon}
            message={t('Saved') + '!'}
            open={isSuccessNotificationVisible}
          />
          <DistributorModal
            isOpen={isDistributorModalOpen}
            distributor={distributor}
            organizations={organizations}
            onClose={() => send('CLOSE_DISTRIBUTOR_MODAL')}
            distributorAdded={(distributor) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'DISTRIBUTOR_ADDED', distributor }])
            }}
            distributorEdited={(distributor) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'DISTRIBUTOR_EDITED', distributor }])
            }}
          />
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={() => {
              send('CLOSE_DELETE_MODAL')
            }}
            onApply={async () => {
              await deleteDistributor(distributor.id)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'DISTRIBUTOR_DELETED', distributor }])
            }}
          />
          {
            isMachinesModalOpen
              ? <DistributorsMachinesModal
                machines={machines}
                isOpen={isMachinesModalOpen}
                multiSelect
                isLoadingMachines={isLoadingMachines}
                title={t('Distributor units')}
                displayedMachineIds={displayedMachineIds}
                onClose={() => {
                  send('CLOSE_MACHINE_MODAL')
                }}
                onApply={async (machineIds) => {
                  try {
                    await addDistributorUnits(machineIds, distributor.id, false)
                  } catch (err) {
                    if (err.errorType === 'DISTRIBUTOR_CONFLICT') {
                      return send({ type: 'OPEN_PROCEED_MODAL', displayedMachineIds: machineIds })
                    }
                    throw new Error(err.errorType)
                  }
                  send(['SHOW_SUCCESS_NOTIFICATION', 'CLOSE_MACHINE_MODAL'])
                }}
              />
              : null
          }
          {
            isProceedModalOpen
              ? <ProceedModal
                isOpen={isProceedModalOpen}
                onApply={async () => {
                  await addDistributorUnits(displayedMachineIds, distributor.id, true)
                  send(['SHOW_SUCCESS_NOTIFICATION', 'CLOSE_PROCEED_MODAL'])
                }}
                onClose={() => send('CLOSE_PROCEED_MODAL')}
                msg="Units you chose might belong to a different distributor, do you still want to proceed?"
              />
              : null
          }

          <DistributorsTableHeader
            openDistributorModal={() => {
              send({ type: 'OPEN_DISTRIBUTOR_MODAL', distributor: {} })
            }}
          />
          <DistributorsTable
            distributors={distributors}
            openDeleteModal={(distributor) => {
              send({ type: 'OPEN_DELETE_MODAL', distributor })
            }}
            openMachinesModal={(distributor) => {
              send({ type: 'OPEN_MACHINES_MODAL', distributor })
            }}
            openUpdateModal={(distributor = {}) => {
              send({ type: 'OPEN_DISTRIBUTOR_MODAL', distributor })
            }}

          />
        </FullPagePaper>
      )
    }
  }
}

export default withTranslation()(DistributorsContainer)
