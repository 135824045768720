import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const fetchOrganizationCampaigns = async (organizationId) => {
  const URL = `${SERVER_PATH + apiEndpoints.organizations}/${organizationId}${apiEndpoints.campaigns}`

  return resolveRequest(axios.get(URL, { withCredentials: true }))
}

export default fetchOrganizationCampaigns
