import deleteDailyWashes from '../../endpoint-requests/daily-wash/deleteDailyWashes.js'
import DeleteModal from '../../components-soapy/ConfirmationModal.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMachine } from '@xstate/react'
import React from 'react'
import Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Snackbar from '../../components/Snackbar/Snackbar'
import { assign, Machine } from 'xstate'
import fetchMachinesLocations from '../../endpoint-requests/machine/getMachines.js'
import fetchDailyWashes from '../../endpoint-requests/daily-wash/fetchDailyWashes.js'
import getCookie from '../../shared/cookie/get-cookie.js'
import DailyWashes from './Presentational.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDailyWashesMultiMachines } from '../../endpoint-requests/daily-wash/fetchDailyWashesMultiMachines.js'
import { dailyWashesSuccess } from '../../redux/dailyWashesReducer.js'

const defaultFromDate = Datetime.moment().subtract(7, 'day').set({ hour: 0, minute: 0, second: 0 })
const defaultToDate = Datetime.moment().set({ hour: 23, minute: 59, second: 59 })

function getMachine (user) {
  return Machine({
    id: 'units',
    initial: 'loadAll',
    context: () => ({
      dailyWashes: [],
      temperatureThreshold: 35.8,
      machines: [],
      selectedMachineId: null,
      isSuccessNotificationVisible: false,
      selectedDailyWashes: [],
      fromDate: defaultFromDate,
      toDate: defaultToDate
    }),
    states: {
      loadAll: {
        entry: ['extractFromCookie'],
        invoke: {
          src: (context, event) => {
            return Promise.all([fetchMachinesLocations({
              fetchArchived: true,
              defaultOrganization: user.entity.name === 'distributor' && user.role.name === 'admin'
            }), context.selectedMachineId && context.selectedMachineId !== 'null' ? fetchDailyWashes(context.fromDate, context.toDate, context.selectedMachineId) : { data: [] }])
          },
          onDone: {
            target: 'main',
            actions: assign({
              machines: (context, event) => event.data[0],
              dailyWashes: (context, event) => event.data[1].data,
              selectedMachineId: (context, event) => event.data[0].findIndex(machine => machine.id === context.selectedMachineId) === -1 ? null : context.selectedMachineId,
              temperatureThreshold: (context, event) => event.data[1].temperatureThreshold
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.error
            })
          }
        }
      },
      loadDailyWashes: {
        invoke: {
          src: (context, event) => fetchDailyWashes(context.fromDate, context.toDate, context.selectedMachineId),
          onDone: {
            target: 'main',
            actions: assign({
              dailyWashes: (context, event) => event.data.data,
              temperatureThreshold: (context, event) => event.data.temperatureThreshold
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.error
            })
          }
        }
      },
      failedFetchingData: {
        type: 'final'
      },
      machinesModal: {
        on: {
          SET_DEFAULT_WASHES: {
            target: 'main',
            actions: ['setDefaultWashes']
          },
          CLOSE_MACHINES_MODAL: 'main',
          LOAD_DAILY_WASHES: {
            target: 'loadDailyWashes',
            actions: ['setFromDate', 'setToDate', 'setSelectedMachineId']
          }
        }
      },
      dateModal: {
        on: {
          SET_DEFAULT_WASHES: {
            target: 'main',
            actions: ['setDefaultWashes']
          },
          CLOSE_DATE_MODAL: 'main',
          LOAD_DAILY_WASHES: {
            target: 'loadDailyWashes',
            actions: ['setFromDate', 'setToDate', 'setSelectedMachineId']
          }
        }
      },
      deleteDailyWashModal: {
        on: {
          CLOSE_DELETE_DAILY_WASH_MODAL: {
            target: 'main',
            actions: ['setSelectedDailyWashes']
          },
          DAILY_WASH_DELETED: {
            target: 'main',
            actions: ['deleteSelectedDailyWashes']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      main: {
        exit: ['hideSuccessNotification'],
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        on: {
          OPEN_DATE_MODAL: 'dateModal',
          OPEN_DELETE_DAILY_WASH_MODAL: {
            target: 'deleteDailyWashModal',
            actions: ['setSelectedDailyWashes']
          },
          OPEN_MACHINES_MODAL: 'machinesModal',
          LOAD_DAILY_WASHES: {
            target: 'loadDailyWashes',
            actions: ['setFromDate', 'setToDate', 'setSelectedMachineId']
          }
        }
      }
    }
  }, {
    actions: {
      showSuccessNotification: assign({
        isSuccessNotificationVisible: true
      }),
      hideSuccessNotification: assign({
        isSuccessNotificationVisible: false
      }),
      setDefaultWashes: assign({
        dailyWashes: (context, event) => [],
        selectedMachineId: (context, event) => null,
        temperatureThreshold: (context, event) => 0,
        fromDate: (context, event) => event.fromDate,
        toDate: (context, event) => event.toDate,
        id: (context, event) => event.id
      }),
      setSelectedDailyWashes: assign({
        selectedDailyWashes: (context, event) => event.selectedDailyWashes
      }),
      extractFromCookie: assign({
        fromDate: (context, event) => getCookie('dailyWashesFromDate') ? Datetime.moment(getCookie('dailyWashesFromDate')) : defaultFromDate,
        toDate: (context, event) => getCookie('dailyWashesToDate') ? Datetime.moment(getCookie('dailyWashesToDate')) : defaultToDate,
        selectedMachineIds: (context, event) => getCookie('dailyWashesMachineIds') && getCookie('dailyWashesMachineIds') !== 'null' ? getCookie('dailyWashesMachineIds') : null
      }),
      setFromDate: assign({
        fromDate: (context, event) => event.fromDate
      }),
      setToDate: assign({
        toDate: (context, event) => event.toDate
      }),
      setSelectedMachineId: assign({
        selectedMachineId: (context, event) => event.selectedMachineId
      }),
      deleteSelectedDailyWashes: assign({
        dailyWashes: (context, event) => context.dailyWashes.filter(dailyWash => context.selectedDailyWashes.indexOf(dailyWash.id) === -1),
        selectedDailyWashes: []
      }),

    },
    guards: {
      isSuccessNotificationVisible: (context, event) => {
        return !!context.isSuccessNotificationVisible
      }
    }
  })
}

const DailyWashesContainer = ({ t, currentDashboardUser }) => {

  const [current, send] = useMachine(getMachine(currentDashboardUser))
  const { dailyWashesToDate } = useSelector((state) => state.dailyWashes)
  const dispatch = useDispatch()
  const dailyWashesRedux = useSelector((state) => state.dailyWashes.dailyWashes)
  const dailyWashesSelectedMachineIds = useSelector((state) => state.dailyWashes.dailyWashesSelectedMachineIds)
  const dailyWashesFromDate = useSelector((state) => state.dailyWashes?.dailyWashesFromDate)
  switch (current.value) {
    case 'loadAll':
    case 'loadDailyWashes':
      return (
        <CircularProgress
          color="primary"
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t('Session Is Over Error')}</div>
    default: {
      const {
        temperatureThreshold,
        selectedDailyWashes,
        isSuccessNotificationVisible,
        machines,
        toDate
      } = current.context
      // const showNoDataError = dailyMachineUsages ? !dailyMachineUsages.length : true
      const isDateModalOpen = current.value === 'dateModal'
      const isMachinesModalOpen = current.value === 'machinesModal'
      const isDeleteDailyWashModalOpen = current.value === 'deleteDailyWashModal'
      return (
        <div>
          <Snackbar
            place="tc"
            color="success"
            icon={NotificationsIcon}
            message={t('Saved') + '!'}
            open={isSuccessNotificationVisible}
          />
          <DeleteModal
            isOpen={isDeleteDailyWashModalOpen}
            onClose={() => {
              send({ type: 'CLOSE_DELETE_DAILY_WASH_MODAL', selectedDailyWashes: [] })
            }}
            onApply={async () => {
              await deleteDailyWashes(selectedDailyWashes)
              const { dailyWashes } = await fetchDailyWashesMultiMachines(dailyWashesFromDate, dailyWashesToDate, dailyWashesSelectedMachineIds,)
              dispatch(dailyWashesSuccess(dailyWashes))

              send(['SHOW_SUCCESS_NOTIFICATION', 'DAILY_WASH_DELETED'])
            }}
          />
          <DailyWashes
            selectedMachineIds={dailyWashesSelectedMachineIds}
            fromDate={dailyWashesFromDate ? Datetime.moment(dailyWashesFromDate) : defaultFromDate}
            openDateModal={
              () => send('OPEN_DATE_MODAL')
            }
            openDeleteDailyWashModal={
              (selected) => send({ type: 'OPEN_DELETE_DAILY_WASH_MODAL', selectedDailyWashes: selected })
            }
            openMachinesModal={
              () => send('OPEN_MACHINES_MODAL')
            }
            closeDateModal={
              () => send('CLOSE_DATE_MODAL')
            }
            closeMachinesModal={
              () => send('CLOSE_MACHINES_MODAL')
            }
            isMachinesModalOpen={isMachinesModalOpen}
            isDateModalOpen={isDateModalOpen}
            toDate={toDate}
            dailyWashes={dailyWashesRedux}
            temperatureThreshold={temperatureThreshold}
            // fetchDailyWashes={(fromDate, toDate, machineIds) => {
            //   setCookie('dailyWashesFromDate', fromDate)
            //   setCookie('dailyWashesToDate', toDate)
            //   setCookie('dailyWashesMachineIds', machineIds)
            //   if (machineIds.length === 0) {
            //     return send({ type: 'SET_DEFAULT_WASHES', toDate, fromDate })
            //   }
            //   send({ type: 'LOAD_DAILY_WASHES', fromDate, toDate, selectedMachineIds: machineIds })
            // }}
            machines={machines}
          />
        </div>
      )
    }
  }
}

export default withAuthentication(withTranslation()(DailyWashesContainer))







