import getBottleWashCount from '../../../shared/unit-configuration/getBottleWashCount.js'
import editMachines from '../../../endpoint-requests/machine/editMachines.js'
import MachineModal from './MachineModal.jsx'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import adminUnitModalStyle from '../../../assets-soapy/jss/soapy/views/adminUnitModalStyle.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import {
  ADDITIONAL_WATER_TIME,
  AFTER_WASH_DELAY, ATP_MODE, DB_TIME_ZONE,
  DEBUG_HANDS_RECOGNITION, DEBUG_MODE,
  DELAY_TIME_PER_WATER_CYCLE,
  ENABLE_VISUAL_ERROR,
  RECOGNITION,
  HANDS_RECOGNITION, INSTALLATION_DATE, INTERNAL_AREA,
  LATHER_TIME,
  LED_PROGRESS_BAR, MONITOR, REAGENT_BOTTLE_ID,
  REAGENT_NAME,
  SOAP_LOADING_TIME,
  SOAP_TIME,
  SOAP_USE,
  SOAP_WASTE,
  TEMPERATURE_OFFSET, TEMPERATURE_SUPPORT,
  TYPE, WATER_CYCLE,
  WATER_TIME_DURING_SOAP,
  REAGENT,
  WATER_TIME_PER_WATER_CYCLE,
  WATER_USE,
  WATER_WASTE, AUTOMATIC_SOAP_PURGE, MONITOR_ORIENTATION
} from '../constants.js'

const UnitModal = ({ isOpen, onClose, machinesEdited, classes, t, ...rest }) => {
  const handleSubmit = async (formFields) => {
    const machines = await editMachines(Object.keys(rest.selected), {
      additionalWaterTime: formFields[ADDITIONAL_WATER_TIME].value,
      afterWashDelay: formFields[AFTER_WASH_DELAY].value,
      atpMode: formFields[ATP_MODE].value,
      bottleWashCount: getBottleWashCount(formFields[SOAP_TIME].value, formFields[REAGENT_NAME].value),
      dbTimeZone: formFields[DB_TIME_ZONE].value,
      debugHandsRecognition: formFields[DEBUG_HANDS_RECOGNITION].value,
      debugMode: formFields[DEBUG_MODE].value,
      delayTimePerWaterCycle: formFields[DELAY_TIME_PER_WATER_CYCLE].value,
      enableVisualError: formFields[ENABLE_VISUAL_ERROR].value,
      recognition: formFields[RECOGNITION].value,
      handsRecognition: formFields[HANDS_RECOGNITION].value,
      installationDate: formFields[INSTALLATION_DATE].value._d,
      internalArea: formFields[INTERNAL_AREA].value,
      reagent: formFields[REAGENT].value,
      latherTime: formFields[LATHER_TIME].value,
      ledProgressBar: formFields[LED_PROGRESS_BAR].value,
      monitor: formFields[MONITOR].value,
      reagentBottleId: formFields[REAGENT_BOTTLE_ID].value,
      reagentName: formFields[REAGENT_NAME].value,
      soapLoadingTime: formFields[SOAP_LOADING_TIME].value,
      soapTime: formFields[SOAP_TIME].value,
      soapUse: formFields[SOAP_USE].value,
      soapWaste: formFields[SOAP_WASTE].value,
      temperatureOffset: formFields[TEMPERATURE_OFFSET].value,
      temperatureSupport: formFields[TEMPERATURE_SUPPORT].value,
      type: formFields[TYPE].value,
      waterCycle: formFields[WATER_CYCLE].value,
      waterTimeDuringSoap: formFields[WATER_TIME_DURING_SOAP].value,
      waterTimePerWaterCycle: formFields[WATER_TIME_PER_WATER_CYCLE].value,
      waterUse: formFields[WATER_USE].value,
      waterWaste: formFields[WATER_WASTE].value,
      automaticSoapPurge: formFields[AUTOMATIC_SOAP_PURGE].value,
      monitorOrientation: formFields[MONITOR_ORIENTATION].value,
    })
    machinesEdited(machines)
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('Bulk edit')}
      onCancel={onClose}
      dialogPaper={classes.dialogPaper}
      hideActions
      showGoBackButton
    >
      <div>
        <MachineModal
          handleSubmit={handleSubmit}
          isBulkEdit
          {...rest}
        />
      </div>
    </Modal>
  )
}

export default withStyles(adminUnitModalStyle)(withTranslation()(UnitModal))
