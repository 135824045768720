import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrganizations, getSites, shiftConfiguration, } from '../../redux/apiCalls'
import Modal from '@material-ui/core/Modal'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import PublishIcon from '@material-ui/icons/Publish'
import { style } from './ConfigurationPopupStyle'
import { useStyles, useStylesPopUp } from './shiftStyles'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import { popUpSuccess } from '../../redux/popUpReducer'
import { apiEndpoints, SERVER_PATH } from '../../endpoint-requests/endpoints'
import { dateCategorySuccess } from '../../redux/datesReducer'
import SiteSelectionLoader from './SiteSelectionLoader'
import Close from '@material-ui/icons/Close'
import getAllSites from '../../endpoint-requests/site/fetchSites'
import { sortData } from './SortData'
import DeleteIcon from '@material-ui/icons/Delete'
import { Checkbox, Tooltip, } from '@material-ui/core'
import { setCurrentFromTime } from './utils/setCurrentFromTime'
import Check from '@material-ui/icons/Check'
import withStyles from '@material-ui/core/styles/withStyles'
import { withTranslation } from 'react-i18next'
import withAuthentication from '../../containers/Authentication/withAuthentication'
import withErrorNotification from '../../containers/withErrorNotification'
import customCheckboxRadioSwitch from '../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'
import SettingsIcon from '@material-ui/icons/Settings'
import ShiftHourPicker from './ShiftHourPicker'
import { importantImageUploadSuccess } from '../../redux/shiftImagesReducer'
import { getDateRange } from '../../redux/shift/utils/getDateRange'
import groupsOfOrganization from '../../endpoint-requests/groups/groupsOfOrganization'
import useFormFields from '../../shared/hooks/useFormFields.js'
import { MINIMUM_RESULT_SCORE, NUMBER_OF_WASHES, REFRESH_TIME } from './constants'

const INFO_COLOR = '#00acc1'
const showHideElementsObject = {
  'showHandMovement': 'Hand movement',
  'showShiftStatus': 'Shift status',
  'showTopWashers': 'Top washers',
  'showImportantUpdates': 'Important updates'
}

const ShiftPopup = ({
  classes,
  setIsActive,
  isActive,
  apiCall,
  autoRefreshTimerId,
  shiftData,
  setShiftData,
  shiftList,
  setShiftList,
  hoursList,
  setHoursList,
  shiftConfigurationData,
  setShiftConfigurationData,
  setLoading
}) => {
  const organizations = useSelector((state) => state.organizations.organizations)
  const sites = useSelector((state) => state.sites.sites)
  const selectedOrganization = useSelector((state) => state.organizations.currentOrganization)
  const selectedOrganizationId = useSelector((state) => state.organizations.currentOrganizationId)
  const autoRefreshTimeRedux = useSelector((state) => state.shifts.autoRefreshTime)
  const { currentSites, currentSitesNames, currentSitesIds, sitesMachinesIds } = useSelector((state) => state.sites)
  const {
    fromDate,
    toDate,
    currentShift,
    minimumWashes,
    importantUpdateImage,
    dateRange,
  } = useSelector((state) => state.shifts.shiftConfigurationData ? state.shifts.shiftConfigurationData : {})
  const dispatch = useDispatch()

  const localClasses = useStyles()
  const styleClasses = useStylesPopUp()

  const [shiftSelectionError, setShiftSelectionError] = useState(false)
  const [loaderTimer, setLoaderTimer] = useState(false)
  const [buttonStatus, setButtonStatus] = useState(false)
  const [importantImageUpload, setImportantImageUpload] = useState(importantUpdateImage ? importantUpdateImage : '')
  // const [clientImageUpload, setClientImageUpload] = useState(null)
  const [imageError, setImageError] = useState(false)
  const [currentHour, setCurrentHour] = useState(moment().hours())
  const [org, setOrg] = useState(shiftConfigurationData ? shiftConfigurationData.currentOrganizationName : '')
  const [orgId, setOrgId] = useState(shiftConfigurationData ? shiftConfigurationData.organization : '')
  const [from, setFrom] = useState(fromDate ? fromDate : '')
  const [to, setTo] = useState(toDate ? toDate : '')
  const [dtRange, setDtRange] = useState(dateRange ? dateRange : '1 weeks')
  const [openHourPicker, setOpenHourPicker] = useState(false)

  //-----------------Multi Sites Select-------------//
  const [localSites, setLocalSites] = useState(sites ? sites : [])
  const [currentSitesArray, setCurrentSitesArray] = useState(shiftConfigurationData ? shiftConfigurationData.currentSites : localSites)
  const [currentShowElements, setCurrentShowElements] = useState(shiftConfigurationData ? {
      showHandMovement: shiftConfigurationData.showHandMovement,
      showImportantUpdates: shiftConfigurationData.showImportantUpdates,
      showShiftStatus: shiftConfigurationData.showShiftStatus,
      showTopWashers: shiftConfigurationData.showTopWashers
    }
    : {
      showHandMovement: true,
      showImportantUpdates: true,
      showShiftStatus: true,
      showTopWashers: true
    })
  const [currentSitesNamesArray, setCurrentSitesNamesArray] = useState(shiftConfigurationData ? shiftConfigurationData.currentSitesNames : [])
  const [currentSitesIdsArray, setCurrentSitesIdsArray] = useState(shiftConfigurationData ? shiftConfigurationData.currentSitesIds : [])
  const [currentSitesMachinesIds, setCurrentSitesMachinesIds] = useState(sitesMachinesIds ? sitesMachinesIds : [])

  //-----------------Multi Group Select--------------//
  const [groupsOfOrganizationData, setGroupsOfOrganizationData] = useState(shiftConfigurationData ? shiftConfigurationData.groupsOfOrganization : [])
  const [currentGroupsArray, setCurrentGroupsArray] = useState(shiftConfigurationData ? shiftConfigurationData.groups : [])
  const [currentGroupsNamesArray, setCurrentGroupsNamesArray] = useState([])
  const [currentGroupsIdsString, setCurrentGroupsIdsString] = useState(shiftConfigurationData ? (shiftConfigurationData.groupsIds?.length > 0 ? shiftConfigurationData.groupsIds.join(',') : undefined) : undefined)
  const { formFields, setFormFields } = useFormFields({
    [MINIMUM_RESULT_SCORE]: {
      value: shiftConfigurationData ? shiftConfigurationData.minimumResultScore : 0,
      errorValidation: false,
      errorMessage: ''
    },
    [NUMBER_OF_WASHES]: {
      value: minimumWashes ? minimumWashes : 0,
      errorValidation: false,
      errorMessage: ''
    },
    [REFRESH_TIME]: {
      value: autoRefreshTimeRedux ? autoRefreshTimeRedux : 5,
      errorValidation: false,
      errorMessage: ''
    },
  })
  const minResultScore = formFields[MINIMUM_RESULT_SCORE].value
  const minWashCount = formFields[NUMBER_OF_WASHES].value
  const autoRefreshTime = formFields[REFRESH_TIME].value
  const autoRefreshTimeError = formFields[REFRESH_TIME].errorValidation

  const minimumResultScoreValues = [0, 20, 40, 60, 80]

  useEffect(() => {
    setCurrentHour(moment().hours())
    let itr = moment.twix(new Date(fromDate), new Date(toDate)).iterate('days')
    let range = []
    while (itr.hasNext()) {
      range.push(itr.next().format('YYYY-MM-DD'))
    }
    dispatch(dateCategorySuccess(range))
  }, [])

  useEffect(() => {
    getOrganizations(dispatch).then(organizations => {
      if (organizations && organizations.length === 1) {
        handleOrganization(organizations[0].name, organizations[0].id)
      }
    })
  }, [])

  useEffect(() => {
    if (
      org &&
      currentSitesArray.length !== 0 &&
      dtRange &&
      // importantImageUpload &&
      !imageError &&
      minResultScore !== '' &&
      minWashCount !== '' &&
      autoRefreshTime !== '' &&
      !autoRefreshTimeError &&
      !shiftSelectionError
    ) {
      setButtonStatus(false)
    } else {
      setButtonStatus(true)
    }
  }, [org, currentSitesArray, dtRange, importantImageUpload, imageError, minResultScore, minWashCount, autoRefreshTime, autoRefreshTimeError, shiftSelectionError])

  useEffect(() => {
    if (shiftList.length === 0) {
      setShiftSelectionError(true)
    } else {
      setShiftSelectionError(false)
    }
  }, [shiftList])

  useEffect(() => {
    //while unmounting we are checking the old and new autoRefreshTime
    //clear only if there is a change in autoRefreshTime
    return () => {
      if (autoRefreshTime !== autoRefreshTimeRedux) {
        clearInterval(autoRefreshTimerId)
      }
    }
  }, [autoRefreshTime])

  useEffect(() => {
    if (shiftConfigurationData) {
      getSites(dispatch, shiftConfigurationData.organization)
    }
  }, [])

  useEffect(() => {
    setShiftList(shiftConfigurationData ? shiftConfigurationData.shifts : [])
  }, [])

  const handleOrganization = (organizationName, organizationId) => {
    setLoaderTimer(true)
    setOrg(organizationName)
    setOrgId(organizationId)

    const getSitesAndGroupsOfOrganization = async () => {
      try {
        const sitesAndGroupsData = await Promise.all([
          getAllSites(),
          groupsOfOrganization(organizationId)
        ])
        const filteredSites = sitesAndGroupsData[0].filter(site => site.organizationId === organizationId)
        setGroupsOfOrganizationData(sitesAndGroupsData[1])
        filteredSites.sort(sortData)
        if (filteredSites.length !== 0) {
          setLocalSites(filteredSites)
          setLoaderTimer(false)
        } else {
          setLocalSites([])
          setLoaderTimer(false)
          setCurrentSitesArray([])
          setCurrentSitesNamesArray([])
          setCurrentSitesIdsArray([])
          setCurrentGroupsArray([])
          setCurrentGroupsNamesArray([])
          setCurrentGroupsIdsString(undefined)
          setCurrentSitesMachinesIds([])
        }
      } catch (error) {
        console.log(error)
      }
    }
    getSitesAndGroupsOfOrganization()
  }

  //------------Handle Multi Select Sites Function--------------//
  const handleMultiSelectSites = (site) => {

    if (currentSitesNamesArray.length === 0) {
      let array = []
      array.push(site)
      formatSites(array)
      return
    }
    if (!currentSitesNames?.includes(site.name)) {
      if (!currentSitesNamesArray?.includes(site.name)) {
        formatSites([...currentSitesArray, site])
        return
      } else {
        const filteredSites = currentSitesArray.filter((currentSite) => currentSite.id !== site.id)
        formatSites(filteredSites)
        return
      }
    }
    if (currentSitesNames?.includes(site.name)) {
      if (!currentSitesNamesArray?.includes(site.name)) {
        formatSites([...currentSitesArray, site])
      } else {
        const filteredSites = currentSitesArray.filter((currentSite) => currentSite.id !== site.id)
        formatSites(filteredSites)
      }
    } else {
      const filteredSites = currentSitesArray.filter((currentSite) => currentSite.id !== site.id)
      formatSites(filteredSites)
    }
  }

  const formatSites = (filteredSites) => {
    getSitesNames(filteredSites)
    getSitesIds(filteredSites)
    // getSitesMachinesIds(filteredSites)
    setCurrentSitesArray(filteredSites)
  }

  const getSitesNames = (sites) => {
    const siteNames = sites.map((site) => {
      return site.name
    })
    setCurrentSitesNamesArray(siteNames)
  }

  const getSitesIds = (sites) => {
    const siteIds = sites.map((site) => {
      return site.id
    })
    setCurrentSitesIdsArray(siteIds)
  }

  const getSitesMachinesIds = (sites) => {
    let machinesIds = []

    sites.map((site) => {
      machinesIds = machinesIds.concat(site['machines'])
      return machinesIds
    })
    const result = machinesIds.map((machineId) => {
      return machineId.id
    })
    setCurrentSitesMachinesIds(result)
  }

  //------------Handle Multi Select Groups Function--------------//
  const handleMultiSelectGroups = (group) => {
    if (currentGroupsNamesArray.length === 0) {
      let array = []
      array.push(group)
      formatGroups(array)
    } else if (!currentGroupsNamesArray?.includes(group.name)) {
      formatGroups([...currentGroupsArray, group])
    } else {
      const filteredGroups = currentGroupsArray.filter((currentGroup) => currentGroup.id !== group.id)
      formatGroups(filteredGroups)
    }
  }

  const formatGroups = (filteredGroups) => {
    getGroupsNames(filteredGroups)
    getGroupsIds(filteredGroups)
    setCurrentGroupsArray(filteredGroups)
  }

  //------------Handle Multi Select Show Element Function--------------//
  const handleMultiSelectElements = (element) => {
    setCurrentShowElements({
      ...currentShowElements,
      [element]: !currentShowElements[element]
    })
  }

  const getGroupsNames = (groups) => {
    const groupNames = groups.map((group) => {
      return group.name
    })
    setCurrentGroupsNamesArray(groupNames)
  }

  const getGroupsIds = (groups) => {
    const groupIds = groups.map((group) => {
      return group.id
    }).join(',')
    setCurrentGroupsIdsString(groupIds !== '' ? groupIds : undefined)
  }

  const handleDateRange = (e) => {
    let dateArray = e.target.value.split(' ')
    let myDate = moment().subtract(dateArray[0], dateArray[1])
    myDate = new Date(myDate.format()).toISOString()
    myDate = setCurrentFromTime(myDate)
    setFrom(myDate)
    setTo(new Date().toISOString())
    setDtRange(e.target.value)

    let itr = moment.twix(myDate, new Date(new Date().toISOString())).iterate('days')
    let range = []
    while (itr.hasNext()) {
      range.push(itr.next().format('YYYY-MM-DD'))
    }
    dispatch(dateCategorySuccess(range))
  }

  const changeMinimumResultScore = (e) => {
    updatingFormFields(MINIMUM_RESULT_SCORE, e.target.value, false, '')
  }

  const validateDimensions = (file) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = async (e) => {
      const image = new Image()
      image.src = e.target.result
      image.onload = (e) => {
        const height = e.target.height
        const width = e.target.width
        if (height !== 720 && width !== 1280) {
          setImageError(true)
          return false
        }
        setImportantImageUpload(file)
        setImageError(false)
        return true
      }
    }

  }

  const handleImportantImageUpload = (e) => {
    const file = e.target.files[0]
    const requiredFileSize = 5
    const fileSize = file?.size
    const fileSizeMB = fileSize / 1024 / 1024
    const fileSizeRoundof = Math.round(fileSizeMB * 10) / 10
    const requiredFileTypes = ['png', 'jpg', 'jpeg']
    let selectedFileType = file?.type.split('/')[1]
    if (requiredFileTypes.includes(selectedFileType) && fileSizeRoundof < requiredFileSize) {
      validateDimensions(file)
    } else {
      setImageError(true)
    }
  }
  const handleDelete = () => {
    setImportantImageUpload('')
    dispatch(importantImageUploadSuccess(''))
  }

  const handleClose = () => {
    setIsActive(false)
    dispatch(popUpSuccess(false))
  }

  const handleBack = () => {
    setIsActive(false)
    dispatch(popUpSuccess(false))
  }

  const openShiftHourPicker = () => {
    setOpenHourPicker(true)
  }
  const updatingFormFields = (fieldName, value, errorValidation, errorMessage) => {
    setFormFields({
      ...formFields,
      [fieldName]: {
        value,
        errorValidation,
        errorMessage
      }
    })
  }

  const isInputValidate = (e, fieldName, errorMessage, numLength) => {
    const re = /^[0-9\b]+$/
    if (e.target.value === '') {
      updatingFormFields(fieldName, '', true, errorMessage)
    } else if (Number(e.target.value) < 1 && fieldName === REFRESH_TIME) {
      updatingFormFields(fieldName, '0', true, 'Auto Refresh Time must not be less than 1')
    } else if (re.test(e.target.value)) {
      updatingFormFields(fieldName, Number((e.target.value).substring(0, numLength)), false, '')
    }
  }
  const handleTextChange = (e, fieldName) => {
    switch (fieldName) {
      case MINIMUM_RESULT_SCORE:
        isInputValidate(e, fieldName, 'Please select minimum result score', 5)
        break
      case NUMBER_OF_WASHES:
        isInputValidate(e, fieldName, 'Please select number of washes', 5)
        break
      case REFRESH_TIME:
        isInputValidate(e, fieldName, 'Please enter auto refresh time', 2)
        break
    }
  }

  const handleSubmit = () => {
    setLoading(true)
    //---clearing old setInterval timer---//
    // clearInterval(autoRefreshTimerId)
    setIsActive(false)
    dispatch(popUpSuccess(false))

    const { nowDate, yesterday, updatedFromDate, dateCategory } = getDateRange(dtRange)

    let data = new FormData()
    data.append('organization', orgId)
    data.append('currentShift', 2)
    data.append('sites', currentSitesIdsArray)
    data.append('groupsIds', currentGroupsIdsString)
    data.append('minimumResultScore', minResultScore)
    data.append('minimumWashes', minWashCount)
    data.append('autoRefreshTime', Number(autoRefreshTime))
    data.append('importantUpdateImage', importantImageUpload)
    data.append('dateRange', dtRange)
    data.append('shifts', shiftList)
    data.append('currentHour', moment().hours())
    data.append('nowDate', nowDate)
    data.append('yesterday', yesterday)
    data.append('updatedFromDate', updatedFromDate)
    data.append('dateCategory', dateCategory)
    data.append('showHandMovement', Boolean(currentShowElements.showHandMovement))
    data.append('showImportantUpdates', Boolean(currentShowElements.showImportantUpdates))
    data.append('showShiftStatus', Boolean(currentShowElements.showShiftStatus))
    data.append('showTopWashers', Boolean(currentShowElements.showTopWashers))

    const config = {
      method: 'post',
      url: SERVER_PATH + apiEndpoints.shiftConfiguration,
      data: data,
      withCredentials: true
    }
    shiftConfiguration(dispatch, config, setShiftConfigurationData, setLoading, nowDate, yesterday,)
  }
  return (
    <>
      {
        openHourPicker &&
        <ShiftHourPicker openHourPicker={openHourPicker} setOpenHourPicker={setOpenHourPicker} shiftData={shiftData}
                         setShiftData={setShiftData} currentHour={currentHour} hoursList={hoursList}
                         setHoursList={setHoursList} shiftList={shiftList} setShiftList={setShiftList}/>
      }
      <Modal
        open={isActive}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Shift Configuration</h3>
          <Close onClick={handleClose}
                 className={styleClasses.myClassName}
          />
          <div style={{
            display: 'flex', alignItems: 'center', flexDirection: 'column',
            overflowY: 'auto',
            // maxHeight: "386px",
            overflowX: 'hidden',
            width: '100%'
          }}>
            {organizations.length > 1 &&
              <div className="configurationPopup">
                <FormControl className={localClasses.formControl}>
                  <InputLabel id="mutiple-select-label" style={{ fontSize: '14px' }}>Select
                    Organizations</InputLabel>
                  <Select
                    value={org}
                    style={{ width: '350px', fontSize: '14px', }}
                  >
                    {organizations.map((organization) => (
                      <MenuItem key={organization.id} value={organization.name}
                                onClick={() => {
                                  setCurrentSitesArray([])
                                  setCurrentSitesNamesArray([])
                                  setCurrentSitesIdsArray([])
                                  handleOrganization(organization.name, organization.id)}
                                }
                      >
                        <ListItemText
                          primary={organization.name} localClasses={{ primary: localClasses.selectAllText }}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>}
            {
              org !== '' && loaderTimer === true ? <SiteSelectionLoader/> :
                <>
                  <div className="configurationPopup">
                    <FormControl className={localClasses.formControl}>
                      <InputLabel id="mutiple-select-label" style={{ fontSize: '14px' }}>Select Sites</InputLabel>
                      <Select
                        value={currentSitesArray}
                        style={{ width: '350px', fontSize: '14px' }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        renderValue={(currentSitesArray) => currentSitesArray.map((item) => item.name)?.join(', ')}
                      >
                        {localSites.map((site) => (
                          <MenuItem key={site.id}
                                    value={site}
                                    onClick={() => handleMultiSelectSites(site)}>
                            <Checkbox
                              id="editSiteDistributorSelectCheckbox"
                              checked={currentSitesArray.find((currentSite) => currentSite.id === site.id) !== undefined}
                              checkedIcon={<Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />}
                              icon={<Check
                                className={classes.uncheckedIcon}
                              />}
                            />

                            <ListItemText
                              primary={site.name} localClasses={{ primary: localClasses.selectAllText }}
                              selected={true}/>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="configurationPopup">
                    <FormControl className={localClasses.formControl}>
                      <InputLabel id="mutiple-select-label" style={{ fontSize: '14px' }}>Select Groups</InputLabel>
                      <Select
                        value={currentGroupsArray}
                        style={{ width: '350px', fontSize: '14px' }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        renderValue={(currentGroupsArray) => currentGroupsArray.map((item) => item.name)?.join(', ')}
                      >
                        {groupsOfOrganizationData?.map((group) => (
                          <MenuItem key={group.id}
                                    value={group}
                                    onClick={() => handleMultiSelectGroups(group)}
                          >
                            <Checkbox
                              id="groupDistributorSelectCheckbox"
                              checked={currentGroupsArray ? currentGroupsArray.find((currentGroup) => currentGroup.id === group.id) !== undefined : false}
                              checkedIcon={<Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />}
                              icon={<Check
                                className={classes.uncheckedIcon}
                              />}
                            />
                            <ListItemText
                              primary={group.name} localClasses={{ primary: localClasses.selectAllText }}
                              selected={true}/>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
            }
            <div className="configurationPopup">
              <FormControl className={localClasses.formControl}>
                <InputLabel htmlFor="demo-simple-select-label"
                            style={{ fontSize: '14px' }}
                >Select Date Range</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="fromDate"
                  value={dtRange}
                  onChange={(e) => handleDateRange(e)}
                  style={{ width: '350px', fontSize: '14px' }}
                >
                  <MenuItem value="1 weeks">Week</MenuItem>
                  <MenuItem value="2 weeks">2 Weeks</MenuItem>
                  <MenuItem value="3 weeks">3 Weeks</MenuItem>
                  <MenuItem value="1 months">1 Month</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="configurationPopup">
              <FormControl className={localClasses.formControl}>
                <InputLabel id="mutiple-select-label" style={{ fontSize: '14px' }}>Show Hide Elements</InputLabel>
                <Select
                  value={Object.keys(currentShowElements).filter(key => currentShowElements[key])}
                  style={{ width: '350px', fontSize: '14px' }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  renderValue={(currentElements) => currentElements.map(key => showHideElementsObject[key])?.join(', ')}
                >
                  {Object.keys(showHideElementsObject).map((key) => (
                    <MenuItem key={key}
                              value={key}
                              onClick={() => handleMultiSelectElements(key)}>
                      <Checkbox
                        id="editSiteDistributorSelectCheckbox"
                        checked={currentShowElements[key]}
                        checkedIcon={<Check
                          className={classes.checkedIcon}
                          style={{ color: INFO_COLOR }}
                        />}
                        icon={<Check
                          className={classes.uncheckedIcon}
                        />}
                      />

                      <ListItemText
                        primary={showHideElementsObject[key]} localClasses={{ primary: localClasses.selectAllText }}
                        selected={true}/>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ width: '350px', marginTop: 15, marginBottom: 15, }}>
              <span style={{ fontSize: '15px', }}>1280x720, png/jpg/jpeg</span>
              <div className="configurationPopup2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginRight: '40px', }}>
                    Upload Image
                  </Typography>
                </div>
                {/* for in label tag must same as id in input tag */}
                <div>
                  <label for="file2" style={{ cursor: 'pointer', color: '#3c4858', }}>
                    <PublishIcon style={{ color: 'black', }}/>
                  </label>
                  {
                    importantImageUpload && <DeleteIcon style={{ color: 'red', cursor: 'pointer', marginLeft: '10px' }}
                                                        onClick={() => handleDelete()}/>
                  }
                  <input
                    type="file"
                    id="file2"
                    style={{ display: 'none', }}
                    onChange={handleImportantImageUpload}
                  />
                </div>

              </div>
              {imageError && <p style={{ color: 'red' }}>Invalid Upload</p>}
            </div>
            <div style={{
              width: '350px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '10px'
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Current Shifts
              </Typography>
              <Tooltip title={'Shifts option lets you create shifts adjusted to your organization\'s needs,' +
                ' where you can monitor each shift\'s performance'}>
                <Typography style={{ margin: '0 0 0 -52%' }} id="modal-modal-title" variant="h6" component="h2">
                  *
                </Typography>
              </Tooltip>
              <SettingsIcon onClick={() => openShiftHourPicker()}
                            style={{ width: '30px', height: '30px', cursor: 'pointer' }}/>
            </div>
            {shiftSelectionError &&
              <p style={{ color: 'red', marginLeft: '-224px', marginTop: '-15px' }}>Please create
                shifts</p>}
            <div className="configurationPopup">
              <FormControl className={localClasses.formControl}>
                <InputLabel htmlFor="demo-simple-select-label" style={{ fontSize: '14px' }}
                >Minimum Result Score</InputLabel>
                <Select
                  value={formFields[MINIMUM_RESULT_SCORE].value}
                  onChange={(e) => changeMinimumResultScore(e)}
                  style={{ width: '350px', fontSize: '14px' }}
                >
                  {minimumResultScoreValues.map(value => {
                    return <MenuItem value={value}>{value}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="configurationPopup">
              <TextField
                onChange={(e) => {
                  handleTextChange(e, NUMBER_OF_WASHES)
                }}
                inputProps={{ style: { width: 350, fontSize: 20 } }}
                value={formFields[NUMBER_OF_WASHES].value}
                className="textField" placeholder="Minimum Shift Count"
                label="Target Number of Washes"
                error={formFields[NUMBER_OF_WASHES].errorValidation}
                helperText={formFields[NUMBER_OF_WASHES].errorMessage}
                maxLength={5}
              />
            </div>
            <div className="configurationPopup">
              <TextField
                onChange={(e) => {
                  handleTextChange(e, REFRESH_TIME)
                }}
                inputProps={{ style: { width: 350, fontSize: 20 } }}
                value={formFields[REFRESH_TIME].value}
                className="textField" placeholder="Auto Refresh Time"
                label="Auto Refresh Time (Minutes)"
                error={formFields[REFRESH_TIME].errorValidation}
                helperText={formFields[REFRESH_TIME].errorMessage}
                maxLength={2}
              />
            </div>
          </div>
          <div className="popupButtonDiv">
            <Button variant="contained" style={{ cursor: 'pointer' }} onClick={() => handleBack()}>Back</Button>
            <Button variant="contained" style={{ cursor: 'pointer' }} onClick={() => handleSubmit()}
                    disabled={buttonStatus}
            >Save</Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default withErrorNotification(withAuthentication(withStyles(customCheckboxRadioSwitch)(withTranslation()(ShiftPopup))))









