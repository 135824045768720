import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Check from '@material-ui/icons/Check'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import formStyle from '../../../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../components-soapy/LoadingButton.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CustomInput from '../../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import { addOrganization } from '../../../endpoint-requests/organization/addOrganization.js'
import { editOrganization } from '../../../endpoint-requests/organization/editOrganization.js'
import useFormFields from '../../../shared/hooks/useFormFields.js'
import validateFormFields from '../../../utils/validateFormFields.js'
import { isEmail, verifyLength } from '../../../utils/validators'
import { Add, Clear } from '@material-ui/icons'

const INFO_COLOR = '#00acc1'

const NAME = 'name'
const COUNTRY = 'country'
const LATITUDE = 'latitude'
const LONGITUDE = 'longitude'
const STATE = 'state'
const CITY = 'city'
const DISTRICT = 'district'
const ADDRESS = 'address'
const ZIP_CODE = 'zipcode'
const CONTACT_NAME = 'contactName'
const DISTRIBUTOR_ID = 'distributorId'
const CONTACT_EMAIL = 'contactEmail'
const WEBSITE = 'website'
const VALIDATION_STATE = 'ValidationState'
const STATUS = 'status'
const IP_ADDRESSES = 'ipAddresses'
const NEW_IP_NAME = 'newIpName'
const NEW_IP_START = 'newIpStart'
const NEW_IP_END = 'newIpEnd'
const IP_NAME = 'name'
const IP_START = 'ipStart'
const IP_END = 'ipEnd'
const MASK_RECOGNITION = 'maskRecognition'
const ENABLE_IP_FILTER = 'enableIpFilter'

let spinnerTimeout = null

const EditOrganization = ({
  classes,
  showErrorNotification,
  organizationAdded,
  currentDashboardUser,
  organizationEdited,
  organizations,
  organization,
  distributors,
  t
}) => {
  const isEdit = !!Object.keys(organization).length
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const { formFields, setFormFields } = useFormFields({
    [NAME]: {
      value: '',
      isRequired: true,
      name: 'Name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 256),
          message: 'Name should not be empty, and up to 256 characters'
        }
      ]
    },
    [CONTACT_EMAIL]: {
      value: '',
      isRequired: false,
      name: 'Contact email',
      validators: [
        {
          isValid: (value) => isEmail(value),
          message: 'Email is not valid'
        }
      ]
    },
    [COUNTRY]: { value: '', isRequired: false, name: 'Country' },
    [STATE]: { value: '', isRequired: false, name: 'State' },
    [CITY]: { value: '', isRequired: false, name: 'City' },
    [ZIP_CODE]: { value: '', isRequired: false, name: 'Zip code' },
    [LONGITUDE]: { value: '', isRequired: false, name: 'Latitude' },
    [LATITUDE]: { value: '', isRequired: false, name: 'Longitude' },
    [DISTRICT]: { value: '', isRequired: false, name: 'District' },
    [ADDRESS]: { value: '', isRequired: false, name: 'Address' },
    [WEBSITE]: { value: '', isRequired: false, name: 'Website' },
    [DISTRIBUTOR_ID]: { value: '', isRequired: () => currentDashboardUser.entity.name === 'neo', name: 'Distributor' },
    [CONTACT_NAME]: { value: '', isRequired: false, name: 'Contact name' },
    [STATUS]: { value: '', isRequired: true, name: 'Status' },
    [NEW_IP_NAME]: { value: '', isRequired: false, valid: true },
    [NEW_IP_START]: { value: '', isRequired: false, valid: true },
    [NEW_IP_END]: { value: '', isRequired: false, valid: true },
    [IP_ADDRESSES]: { value: [], isRequired: false, name: 'IP addresses' },
    [MASK_RECOGNITION]: { value: false, name: 'Mask recognition' },
    [ENABLE_IP_FILTER]: { value: false, name: 'Enable IP filter' },
  })

  const submitForm = async () => {
    const organizationRequestObject = {
      name: formFields[NAME].value,
      country: formFields[COUNTRY].value,
      state: formFields[STATE].value,
      contactName: formFields[CONTACT_NAME].value,
      contactEmail: formFields[CONTACT_EMAIL].value,
      distributorId: formFields[DISTRIBUTOR_ID].value,
      website: formFields[WEBSITE].value,
      city: formFields[CITY].value,
      district: formFields[DISTRICT].value,
      address: formFields[ADDRESS].value,
      zipcode: formFields[ZIP_CODE].value,
      status: formFields[STATUS].value,
      ipAddresses: formFields[IP_ADDRESSES].value,
      maskRecognition: formFields[MASK_RECOGNITION].value,
      enableIpFilter: formFields[ENABLE_IP_FILTER].value
    }

    activateSpinner()
    try {
      if (!isEdit) {
        const createdSite = await addOrganization(organizationRequestObject)
        organizationAdded(createdSite)
      } else {
        const editedSite = await editOrganization(organization.id, organizationRequestObject)
        organizationEdited(editedSite)
      }
    } catch (err) {
      showErrorNotification(err.message)
    }
    disableSpinner()
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    setFormFields(
      (formFields) =>
        ({
          [NAME]: { ...formFields[NAME], value: organization[NAME] || '' },
          [COUNTRY]: { ...formFields[COUNTRY], value: organization[COUNTRY] || '' },
          [STATE]: { ...formFields[STATE], value: organization[STATE] || '' },
          [CITY]: { ...formFields[CITY], value: organization[CITY] || '' },
          [ZIP_CODE]: { ...formFields[ZIP_CODE], value: organization[ZIP_CODE] || '' },
          [LONGITUDE]: { ...formFields[LONGITUDE], value: organization[LONGITUDE] || '' },
          [LATITUDE]: { ...formFields[LATITUDE], value: organization[LATITUDE] || '' },
          [DISTRICT]: { ...formFields[DISTRICT], value: organization[DISTRICT] || '' },
          [CONTACT_EMAIL]: { ...formFields[CONTACT_EMAIL], value: organization[CONTACT_EMAIL] || '' },
          [CONTACT_NAME]: { ...formFields[CONTACT_NAME], value: organization[CONTACT_NAME] || '' },
          [WEBSITE]: { ...formFields[WEBSITE], value: organization[WEBSITE] || '' },
          [ADDRESS]: { ...formFields[ADDRESS], value: organization[ADDRESS] || '' },
          [DISTRIBUTOR_ID]: {
            ...formFields[DISTRIBUTOR_ID],
            value: currentDashboardUser.entity.name === 'neo' ? organization[DISTRIBUTOR_ID] : currentDashboardUser.distributorId
          },
          [STATUS]: { ...formFields[STATUS], value: organization[STATUS] || 'active' },
          [MASK_RECOGNITION]: { ...formFields[MASK_RECOGNITION], value: organization[MASK_RECOGNITION] || false },
          [ENABLE_IP_FILTER]: { ...formFields[ENABLE_IP_FILTER], value: organization[ENABLE_IP_FILTER] || false },
          [IP_ADDRESSES]: { ...formFields[IP_ADDRESSES], value: organization.organization_ip_addresses || [] },
          [NEW_IP_NAME]: { ...formFields[NEW_IP_NAME], value: organization[NEW_IP_NAME] || '' },
          [NEW_IP_START]: { ...formFields[NEW_IP_START], value: organization[NEW_IP_START] || '' },
          [NEW_IP_END]: { ...formFields[NEW_IP_END], value: organization[NEW_IP_END] || '' },
        })
    )
  }, [organization]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [])

  const handleTextChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: event.target.value } })
  }

  const handleIpChange = (event, fieldName) => {
    const value = event.target.value
    const lastChar = value[value.length - 1]
    if (lastChar === '.' || lastChar === '' || lastChar === undefined || !isNaN(parseInt(lastChar))) {
      setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: value } })
    }
  }

  function validIp (value) {
    const rgx = /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/
    return value.match(rgx)
  }

  function validIpRange (start, end) {
    start = parseInt(start.toString().replaceAll('.', ''))
    end = parseInt(end.toString().replaceAll('.', ''))
    return start <= end
  }

  const addIpAddresses = () => {
    const ipAddresses = formFields[IP_ADDRESSES].value
    const newIpAddress = {
      id: ipAddresses.length + 1,
      [IP_NAME]: formFields[NEW_IP_NAME].value,
      [IP_START]: formFields[NEW_IP_START].value,
      [IP_END]: formFields[NEW_IP_END].value,
    }

    let isValidName = false
    let isValidStart = false
    let isValidEnd = false

    if (newIpAddress[NAME].length) {
      isValidName = true
    }

    if (validIp(newIpAddress[IP_START])) {
      isValidStart = true
    }

    if (validIp(newIpAddress[IP_END])) {
      isValidEnd = true
    }

    if (isValidName && isValidStart && isValidEnd) {
      const isValidRange = validIpRange(formFields[NEW_IP_START].value, formFields[NEW_IP_END].value)
      if (!isValidRange) {
        setFormFields({
          ...formFields,
          [NEW_IP_START]: { ...formFields[NEW_IP_START], valid: false },
          [NEW_IP_END]: { ...formFields[NEW_IP_END], valid: false },
        })
        showErrorNotification('IP address range is not valid')
        return
      }
      ipAddresses.push(newIpAddress)
      setFormFields({
        ...formFields,
        [IP_ADDRESSES]: { ...formFields[IP_ADDRESSES], value: ipAddresses },
        [NEW_IP_NAME]: { ...formFields[NEW_IP_NAME], value: '', valid: true },
        [NEW_IP_START]: { ...formFields[NEW_IP_START], value: '', valid: true },
        [NEW_IP_END]: { ...formFields[NEW_IP_END], value: '', valid: true },
      })
    } else {
      showErrorNotification('Invalid IP address')
      setFormFields({
        ...formFields,
        [NEW_IP_NAME]: { ...formFields[NEW_IP_NAME], valid: isValidName },
        [NEW_IP_START]: { ...formFields[NEW_IP_START], valid: isValidStart },
        [NEW_IP_END]: { ...formFields[NEW_IP_END], valid: isValidEnd },
      })
    }
  }

  const removeIpAddress = (id) => {
    const ipAddresses = formFields[IP_ADDRESSES].value
    const newIpAddresses = ipAddresses.filter(ipAddress => ipAddress.id !== id)
    setFormFields({
      ...formFields,
      [IP_ADDRESSES]: { ...formFields[IP_ADDRESSES], value: newIpAddresses }
    })
  }

  const maskRecognitionCheckboxChange = () => {
    setFormFields({
      ...formFields,
      [MASK_RECOGNITION]: {
        ...formFields[MASK_RECOGNITION],
        value: !formFields[MASK_RECOGNITION].value
      }
    })
  }
  const enableIPFilterCheckboxChange = () => {
    setFormFields({
      ...formFields,
      [ENABLE_IP_FILTER]: {
        ...formFields[ENABLE_IP_FILTER],
        value: !formFields[ENABLE_IP_FILTER].value
      }
    })
  }
  return (
    <GridContainer id="content-pane-layout" justify="center">
      <GridItem md={9}>
        <Card style={{ boxShadow: 'none' }}>
          <CardBody>
            <div>
              <form>
                <GridContainer
                  direction="column"
                  justify="center"
                  alignItems="center"
                  id="card-body-section"
                >
                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      id="organizationName"
                      error={formFields[NAME + VALIDATION_STATE]}
                      labelText={`${t('Name')} *`}
                      inputProps={{
                        value: formFields[NAME].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      id="organizationCountry"
                      labelText={t('Country')}
                      inputProps={{
                        value: formFields[COUNTRY].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, COUNTRY)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      labelText={t('State')}
                      id="organizationState"
                      inputProps={{
                        value: formFields[STATE].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, STATE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      labelText={t('City')}
                      id="organizationCity"
                      inputProps={{
                        value: formFields[CITY].value,
                        onChange: event => {
                          handleTextChange(event, CITY)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      labelText={t('District')}
                      id="organizationDistrict"
                      inputProps={{
                        value: formFields[DISTRICT].value,
                        onChange: event => {
                          handleTextChange(event, DISTRICT)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      labelText={t('Address')}
                      id="organizationAddress"
                      inputProps={{
                        value: formFields[ADDRESS].value,
                        onChange: event => {
                          handleTextChange(event, ADDRESS)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      id="organizationZipCode"
                      labelText={t('Zip code')}
                      inputProps={{
                        value: formFields[ZIP_CODE].value,
                        onChange: event => {
                          handleTextChange(event, ZIP_CODE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      labelText={t('Contact name')}
                      id="organizationContactName"
                      inputProps={{
                        value: formFields[CONTACT_NAME].value,
                        onChange: event => {
                          handleTextChange(event, CONTACT_NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      labelText={t('Contact email')}
                      id="organizationContactEmail"
                      inputProps={{
                        value: formFields[CONTACT_EMAIL].value,
                        onChange: event => {
                          handleTextChange(event, CONTACT_EMAIL)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ width: '100%' }}>
                    <CustomInput
                      labelText={t('Website')}
                      id="organizationWebsite"
                      inputProps={{
                        value: formFields[WEBSITE].value,
                        onChange: event => {
                          handleTextChange(event, WEBSITE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  {currentDashboardUser.entity.name === 'neo'
                    ? <GridItem style={{
                      width: '100%',
                      margin: '0 0 17px 0',
                      paddingTop: '27px',
                      position: 'relative',
                      verticalAlign: 'unset'
                    }}
                    >
                      <FormControl fullWidth>
                        <InputLabel
                          style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          id="editSiteDistributor"
                        >
                          {`${t('Distributor')} *`}
                        </InputLabel>
                        <Select
                          error={formFields[DISTRIBUTOR_ID + VALIDATION_STATE]}
                          style={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#495057'
                          }}
                          id="editSiteDistributorSelect"
                          input={<Input/>}
                          renderValue={(selected) => distributors[formFields[DISTRIBUTOR_ID].value]}
                          value={formFields[DISTRIBUTOR_ID].value}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          inputProps={{
                            onChange: event => {
                              handleTextChange(event, DISTRIBUTOR_ID)
                            }
                          }}
                        >
                          {
                            Object.keys(distributors).map(item => {
                              return (
                                <MenuItem
                                  id="editSiteDistributorSelectMenuItem"
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={item}
                                  value={item}
                                >
                                  <Checkbox
                                    id="editSiteDistributorSelectCheckbox"
                                    checkedIcon={<Check
                                      className={classes.checkedIcon}
                                      style={{ color: INFO_COLOR }}
                                    />}
                                    icon={<Check
                                      className={classes.uncheckedIcon}
                                    />}
                                    checked={formFields[DISTRIBUTOR_ID].value === item}
                                  />
                                  <ListItemText primary={distributors[item]}/>
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    : null}
                  <GridItem style={{
                    width: '100%',
                    margin: '0 0 17px 0',
                    paddingTop: '27px',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={formFields[STATUS].value}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        inputProps={{
                          onChange: event => {
                            handleTextChange(event, STATUS)
                          }
                        }}
                        label="Status"
                      >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="archived">Archived</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem style={{
                    width: '100%',
                    margin: '0px 0px 17px -19px',
                    paddingTop: '27px',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <Checkbox
                      disabled={isEdit}
                      id="machinesModalCheckboxHeader"
                      tabIndex={-1}
                      onChange={() => {maskRecognitionCheckboxChange()}}
                      checkedIcon={
                        <Check
                          className={classes.checkedIcon}
                          style={{ color: INFO_COLOR }}
                        />
                      }
                      icon={<Check className={classes.uncheckedIcon}/>}
                      checked={formFields[MASK_RECOGNITION].value}
                    />
                    <Typography display="inline">{t('Face mask support')}</Typography>
                  </GridItem>
                  <GridItem style={{
                    width: '100%',
                    margin: '0px 0px 17px -19px',
                    paddingTop: '27px',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <Checkbox
                      id="enableIpFilter"
                      tabIndex={-1}
                      onChange={() => {enableIPFilterCheckboxChange()}}
                      checkedIcon={
                        <Check
                          className={classes.checkedIcon}
                          style={{ color: INFO_COLOR }}
                        />
                      }
                      icon={<Check className={classes.uncheckedIcon}/>}
                      checked={formFields[ENABLE_IP_FILTER].value}
                    />
                    <Typography display="inline">{t('Enable IP filter')}</Typography>
                  </GridItem>
                  <GridItem
                    style={{
                      width: '100%',
                      margin: '0px 0px 17px 0px',
                      paddingTop: '27px',
                      position: 'relative',
                      verticalAlign: 'unset',
                      display: 'flex'
                    }}
                  >
                    <CustomInput
                      id="reportName"
                      labelText={`${t('Rule Name')}`}
                      inputProps={{
                        value: formFields[NEW_IP_NAME].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, NEW_IP_NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true,
                        error: !formFields[NEW_IP_NAME].valid
                      }}
                    />
                    <CustomInput
                      id="reportName"
                      labelText={`${t('Start IPv4')}`}
                      inputProps={{
                        value: formFields[NEW_IP_START].value,
                        type: 'name',
                        onChange: event => {
                          handleIpChange(event, NEW_IP_START)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true,
                        error: !formFields[NEW_IP_START].valid
                      }}
                    />
                    <CustomInput
                      id="reportName"
                      labelText={`${t('End IPv4')}`}
                      inputProps={{
                        value: formFields[NEW_IP_END].value,
                        type: 'name',
                        onChange: event => {
                          handleIpChange(event, NEW_IP_END)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true,
                        error: !formFields[NEW_IP_END].valid
                      }}
                    />
                    <Add
                      onClick={(event) => addIpAddresses()}
                      style={{ marginTop: '20px', cursor: 'pointer' }}
                    />
                  </GridItem>
                  {formFields[IP_ADDRESSES].value.map(ipAddress => {
                    return (
                      <GridItem
                        style={{
                          width: '100%',
                          margin: '0px 0px 17px 0px',
                          paddingTop: '27px',
                          position: 'relative',
                          verticalAlign: 'unset',
                          display: 'flex'
                        }}
                      >
                        <CustomInput
                          id="reportName"
                          labelText={`${t('Rule Name')}`}
                          inputProps={{
                            value: ipAddress[IP_NAME],
                            type: 'name',
                            disabled: true
                          }}
                        />
                        <CustomInput
                          id="reportName"
                          labelText={`${t('Start IPv4')}`}
                          inputProps={{
                            value: ipAddress[IP_START],
                            type: 'name',
                            disabled: true
                          }}
                        />
                        <CustomInput
                          id="reportName"
                          labelText={`${t('End IPv4')}`}
                          inputProps={{
                            value: ipAddress[IP_END],
                            type: 'name',
                            disabled: true
                          }}
                        />
                        <Clear
                          onClick={(event) => removeIpAddress(ipAddress.id)}
                          style={{ marginTop: '20px', cursor: 'pointer' }}
                        />
                      </GridItem>
                    )
                  })}
                </GridContainer>
                <GridContainer
                  justify="center"
                  alignItems="center"
                  id="card-footer-section"
                >
                  <GridItem>
                    <LoadingButton
                      color="info"
                      type="submit"
                      id="siteEditSaveButton"
                      onClick={(e) => {
                        e.preventDefault()
                        handleSaveBtnClick()
                      }}
                      fullWidth
                      disabled={isAwaitingResponse}
                      isLoading={isAwaitingResponse}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </GridItem>
                </GridContainer>
              </form>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

EditOrganization.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withTranslation()(EditOrganization))))
