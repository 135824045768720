import { CircularProgress, FormControlLabel, Switch, Tooltip, Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Refresh from '@material-ui/icons/Refresh'
import Chartist from 'chartist'
import 'chartist-plugin-fill-donut'
import 'jspdf-autotable'
import * as moment from 'moment'
import 'moment-timezone'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ChartistGraph from 'react-chartist'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import dailyWashStyle from '../../assets-soapy/jss/soapy/views/dailyWashStyle.jsx'
import DateModal from '../../components-soapy/DateModal/DateModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import TwoThumbs from '../../components-soapy/Slider/TwoThumbs'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getCookie from '../../shared/cookie/get-cookie.js'
import setCookie from '../../shared/cookie/set-cookie.js'
import StarIcon from '@material-ui/icons/Star'
import dailyWashesRequestKeywords from '../../shared/data/daily-washes.js'
import {
  BackOfLeftHand,
  BackOfLeftHandFailed,
  BackOfLeftThumb,
  BackOfLeftThumbFailed,
  BackOfRightHand,
  BackOfRightHandFailed,
  BackOfRightThumb,
  BackOfRightThumbFailed,
  BetweenFingers,
  BetweenFingersFailed,
  FingernailLeft,
  FingernailLeftFailed,
  FingernailRight,
  FingernailRightFailed,
  PalmToPalm,
  PalmToPalmFailed
} from '../../shared/hands-recognition/icons'
import useSelector from '../../shared/hooks/useSelector.js'
import { formatMachineBtnText } from '../../shared/machine/formatMachineBtnText.js'
import getLanguageLocale from '../../shared/multi-language/getLanguageLocale.js'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import toFahrenheit from '../../shared/temperature/toFahrenheit.js'
import DailyWashExportTable from './DailyWashExportTable.jsx'
import {
  DID_USE_SOAP,
  DURING_LATHER,
  DURING_RINSING,
  HANDS_ICON_NAMES,
  HANDS_RECOGNITION,
  HANDS_RECOGNITION_KEYS,
  ID,
  IS_FAILED,
  LATHERING_TIME,
  RESULT,
  RINSING_TIME,
  SERIAL_NUMBER,
  SITE,
  SITE_INTERNAL_AREA,
  TEMPERATURE,
  TEMPERATURE_TYPE,
  WASH_DATE,
  WASH_METRICS,
  WASHER,
  WASHER_ID,
  GROUP
} from './constants'
import DailyWashesMachinesModal from '../../components-soapy/DailyWashesMachinesModal.jsx'
import { getDailyWashesMultiMachines } from '../../redux/apiCalls.js'
import { useDispatch } from 'react-redux'
import {
  dailyWashesExcelDataSuccess,
  dailyWashesFromDateSuccess,
  dailyWashesToDateSuccess
} from '../../redux/dailyWashesReducer.js'
import Box from '@material-ui/core/Box'
import Datetime from 'react-datetime'
import StarHalfIcon from '@material-ui/icons/StarHalf'
import StarOutlineIcon from '@material-ui/icons/StarOutline'

const REAGENT = 'reagent'

const DEFAULT_PAGE_SIZE = 10

const getStatistics = (dailyWashesTableData) => {
  return dailyWashesTableData.reduce((acc, dailyWash) => {
    const result = dailyWash[WASH_METRICS][RESULT]
    acc.washFailedCount += dailyWash[WASH_METRICS][IS_FAILED] ? 1 : 0
    if (acc.washAveragePercentage === null) {
      acc.washAveragePercentage = result
    } else {
      acc.washAveragePercentage = (acc.washAveragePercentage * acc.washCount + result) / (acc.washCount + 1)
    }
    acc.washCount++
    return acc
  }, { washCount: 0, washFailedCount: 0, washAveragePercentage: null })
}

function convertDailyWashesToTableData (dailyWashes, latheringTimeServer, rinsingTimeServer) {
  console.log(dailyWashes)
  return dailyWashes.map(washEvent => {
    const {
      [dailyWashesRequestKeywords.SITE]: site,
      [dailyWashesRequestKeywords.SITE_INTERNAL_AREA]: siteInternalArea,
      [dailyWashesRequestKeywords.WASHER]: washer,
      [dailyWashesRequestKeywords.WASHER_ID]: washerId,
      [dailyWashesRequestKeywords.ID]: id,
      [dailyWashesRequestKeywords.TEMPERATURE]: temperature,
      [dailyWashesRequestKeywords.DATE]: washDate,
      [dailyWashesRequestKeywords.WASH_PERCENTAGE]: washPercentage,
      [dailyWashesRequestKeywords.IS_FAILED]: isFailed,
      [dailyWashesRequestKeywords.WASH_METRICS]: washMetrics,
      handsRecognition,
      serialNumber,
      group
    } = washEvent

    let {
      [dailyWashesRequestKeywords.DID_USE_SOAP]: didUseSoap,
      [dailyWashesRequestKeywords.REAGENT]: reagent,
      [dailyWashesRequestKeywords.DURING_LATHERING]: duringLathering,
      [dailyWashesRequestKeywords.DURING_RINSING]: duringRinsing,
      [dailyWashesRequestKeywords.LATHERING_TIME]: latheringTime,
      [dailyWashesRequestKeywords.RINSING_TIME]: rinsingTime,
      [dailyWashesRequestKeywords.RINSE_ALL_CYCLES_OUTCOMES]: rinseAllCyclesOutcomes
    } = washMetrics

    const isSoapReagent = rinseAllCyclesOutcomes.length > 0

    latheringTime = latheringTime !== undefined ? latheringTime : latheringTimeServer
    rinsingTime = rinsingTime !== undefined ? rinsingTime : rinsingTimeServer

    return {
      [SITE]: site,
      [SITE_INTERNAL_AREA]: siteInternalArea,
      [WASHER]: washer,
      [WASHER_ID]: washerId,
      [ID]: id,
      [TEMPERATURE]: temperature && temperature.toFixed(1),
      [WASH_DATE]: moment(washDate).locale(getLanguageLocale()).format('MMM DD HH:mm'),
      [HANDS_RECOGNITION]: {
        handsRecognition,
        count: Object.values(handsRecognition).filter(Boolean).length,
      },
      [WASH_METRICS]: {
        [DID_USE_SOAP]: didUseSoap,
        [REAGENT]: reagent,
        [IS_FAILED]: isFailed,
        [DURING_LATHER]: duringLathering,
        [DURING_RINSING]: isSoapReagent ? duringRinsing : '-',
        [LATHERING_TIME]: Math.floor(latheringTime),
        [RINSING_TIME]: Math.floor(rinsingTime),
        [RESULT]: washPercentage
      },
      [SERIAL_NUMBER]: serialNumber,
      [GROUP]: group,
    }
  })
}

const isCellTextFiltered = (cellText, searchText) => {
  return cellText.toLowerCase().includes(searchText.toLowerCase())
}

const isCellNumberFiltered = (cellText, searchText) => {
  return cellText.toString().includes(searchText.toString())
}

const formatDateBtnText = (fromDate, filterToDate) => `${fromDate.locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm')} - ${filterToDate.locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm')}`

const DailyWashes = ({
  // temperatureThreshold,
  selectedMachineIds,
  fromDate,
  toDate,
  dailyWashes,
  isDateModalOpen,
  openDateModal,
  openMachinesModal,
  closeDateModal,
  classes,
  closeMachinesModal,
  hasPermission,
  openDeleteDailyWashModal,
  isMachinesModalOpen,
  fetchDailyWashes,
  currentDashboardUser,
  machines,
  t
}) => {
  const [isCelsius, setIsCelsius] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [loader, setLoader] = useState(false)
  const [filterToDate, setFilterToDate] = useState(Datetime.moment().set({ hour: 23, minute: 59, second: 59 }))
  const [machinesSerialNumbers, setMachinesSerialNumbers] = useState([])
  const [temperatureThreshold, setTemperatureThreshold] = useState(37.8)
  const dispatch = useDispatch()

  const {
    toggleSelectAll,
    select,
    checkIsChecked,
    selected,
    checkIsAllSelected,
    unselectAll,
    checkIsAnySelected,
    getSelected
  } = useSelector(dailyWashes?.map(dailyWash => dailyWash.id))
  const tableRef = useRef()
  const language = getCookie('language')

  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [language])

  useEffect(() => {
    const isCelsius = getCookie('dailyWashesIsCelsius')
    if (isCelsius) {
      setIsCelsius(isCelsius === 'true')
    } else if (currentDashboardUser.entity.name === 'site' && currentDashboardUser.sites.length) {
      setIsCelsius(currentDashboardUser.sites[0][TEMPERATURE_TYPE] === 'C')
    }
    const machine = machines.filter(machine => selectedMachineIds.includes(machine.id))
    const serialNumbers = machine.map((machine) => {
      return machine.serialNumber
    })
    setMachinesSerialNumbers(serialNumbers)
  }, [selectedMachineIds]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRefreshButtonClick = () => {
    setLoader(true)
    adjustToNewMachinesSelection(fromDate, filterToDate, selectedMachineIds)
  }

  const toggleTemperatureScale = () => {
    setCookie('dailyWashesIsCelsius', !isCelsius)
    setIsCelsius(!isCelsius)
  }

  const adjustToNewMachinesSelection = async (fromDate, filterToDate, machineIds) => {
    getDailyWashesMultiMachines(dispatch, fromDate, filterToDate, machineIds, setLoader, setTemperatureThreshold)
    dispatch(dailyWashesFromDateSuccess(fromDate))
    dispatch(dailyWashesToDateSuccess(filterToDate))
    closeMachinesModal()
  }
  const dateBtnText = formatDateBtnText(fromDate, filterToDate)
  const machine = machines.filter(machine => selectedMachineIds.includes(machine.id))
  const latheringTimeServer = machine && machine.duringLathering
  const rinsingTimeServer = machine && machine.duringRinsing
  const machinesBtnText = formatMachineBtnText(machine)
  const dailyWashesTableDataset = useMemo(() => convertDailyWashesToTableData(dailyWashes, latheringTimeServer, rinsingTimeServer), [dailyWashes, latheringTimeServer, rinsingTimeServer])
  const {
    washCount,
    washFailedCount,
    washAveragePercentage
  } = useMemo(() => getStatistics(dailyWashesTableDataset), [dailyWashesTableDataset])
  useEffect(() => {
    dispatch(dailyWashesExcelDataSuccess(dailyWashesTableDataset))
  }, [dailyWashesTableDataset])

  useEffect(() => {
    if (selectedMachineIds.length !== 0) {
      setLoader(true)
      adjustToNewMachinesSelection(fromDate, filterToDate, selectedMachineIds)
    }
  }, [])

  const renderCheckIcons = isOk => isOk
    ? (
      <Check
        style={{
          fillOpacity: 0.7,
          fontSize: '18px',
          color: '#4ADD4A'
        }}
      />
    )
    : (
      <Close
        style={{
          fillOpacity: 0.7,
          fontSize: '18px',
          color: '#DD4A65'
        }}
      />
    )

  const drawHandsIcon = (icon, title) =>
    <Tooltip title={title}>
      {icon}
    </Tooltip>

  const renderHandsRecognitionIcons = data => {
    if (data) {
      return (
        <div>
          <div>
            {data[HANDS_RECOGNITION_KEYS.PALM_TO_PALM]
              ? drawHandsIcon(PalmToPalm, HANDS_ICON_NAMES.PalmToPalm)
              : drawHandsIcon(PalmToPalmFailed, HANDS_ICON_NAMES.PalmToPalm)}
            {data[HANDS_RECOGNITION_KEYS.BETWEEN_FINGERS]
              ? drawHandsIcon(BetweenFingers, HANDS_ICON_NAMES.BetweenFingers)
              : drawHandsIcon(BetweenFingersFailed, HANDS_ICON_NAMES.BetweenFingers)}
            {data[HANDS_RECOGNITION_KEYS.BACK_OF_LEFT_HAND]
              ? drawHandsIcon(BackOfLeftHand, HANDS_ICON_NAMES.BackOfLeftHand)
              : drawHandsIcon(BackOfLeftHandFailed, HANDS_ICON_NAMES.BackOfLeftHand)}
          </div>
          <did>
            {data[HANDS_RECOGNITION_KEYS.BACK_OF_RIGHT_HAND]
              ? drawHandsIcon(BackOfRightHand, HANDS_ICON_NAMES.BackOfRightHand)
              : drawHandsIcon(BackOfRightHandFailed, HANDS_ICON_NAMES.BackOfRightHand)}
            {data[HANDS_RECOGNITION_KEYS.BACK_OF_LEFT_THUMB]
              ? drawHandsIcon(BackOfLeftThumb, HANDS_ICON_NAMES.BackOfLeftThumb)
              : drawHandsIcon(BackOfLeftThumbFailed, HANDS_ICON_NAMES.BackOfLeftThumb)}
            {data[HANDS_RECOGNITION_KEYS.BACK_OF_RIGHT_THUMB]
              ? drawHandsIcon(BackOfRightThumb, HANDS_ICON_NAMES.BackOfRightThumb)
              : drawHandsIcon(BackOfRightThumbFailed, HANDS_ICON_NAMES.BackOfRightThumb)}
          </did>
          <div>
            {data[HANDS_RECOGNITION_KEYS.FINGERNAIL_LEFT]
              ? drawHandsIcon(FingernailLeft, HANDS_ICON_NAMES.FingernailLeft)
              : drawHandsIcon(FingernailLeftFailed, HANDS_ICON_NAMES.FingernailLeft)}
            {data[HANDS_RECOGNITION_KEYS.FINGERNAIL_RIGHT]
              ? drawHandsIcon(FingernailRight, HANDS_ICON_NAMES.FingernailRight)
              : drawHandsIcon(FingernailRightFailed, HANDS_ICON_NAMES.FingernailRight)}
          </div>

        </div>
      )
    }
    return null
  }

  const isData = Boolean(dailyWashesTableDataset.length)

  const temperatureColorCheck = (temperature, temperatureThreshold) => {
    if (temperature < 35.8) {
      return '#4ADD4A'
    }
    if (temperature >= 41.5) {
      return '#DD4A65'
    }
    if (35.8 < temperature && temperature < temperatureThreshold) {
      return '#4ADD4A'
    }
    return '#DD4A65'
  }

  const getStars = (result) => {
    const score = result / 100 * 5
    let count = parseInt(score)
    const add = score - count

    if (add >= 0.25) {
      if (add < 0.75) {
        count += 0.5
      } else {
        count += 1
      }
    }

    let starsFull = Array(parseInt(score)).fill(<StarIcon style={{ color: '#03b2cb' }}/>)
    let starsHalf = count % 1 ? [<StarHalfIcon style={{ color: '#03b2cb' }}/>] : []
    let starsEmpty = Array(5 - (starsFull.length + starsHalf.length)).fill(<StarOutlineIcon
      style={{ color: '#03b2cb' }}/>)

    const allStars = starsFull.concat(starsHalf, starsEmpty)
    return <div style={{ display: 'flex' }}>
      {allStars.map(as => <div>{as}</div>)}
    </div>
  }

  return (
    <>
      {
        loader
          ?
          <Box sx={{ position: 'absolute', marginLeft: '47%', marginTop: '18%', zIndex: '100' }}>
            <CircularProgress/>
          </Box>
          :
          <FullPagePaper style={{ marginTop: '4rem' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color="info"
                round
                id="dailyWashDateButton"
                onClick={openDateModal}
                style={isMobile ? { fontSize: '10px' } : { marginRight: 5 }}
              >
                {dateBtnText}
              </Button>
              <DateModal
                isOpen={isDateModalOpen}
                closeModal={closeDateModal}
                isDateRange
                displayedDate={fromDate}
                displayedToDate={filterToDate}
                onApply={(fromDate, toDate) => {
                  setFilterToDate(toDate)
                  setLoader(true)
                  adjustToNewMachinesSelection(fromDate, toDate, selectedMachineIds)
                }}
              />
              <Button
                color="info"
                round
                id="dailyWashMachineButton"
                onClick={openMachinesModal}
                style={isMobile ? { fontSize: '10px' } : { marginRight: 5 }}
              >
                {t('Machine Chosen')} : {machinesBtnText}
              </Button>
              {
                isMachinesModalOpen
                  ?
                  <DailyWashesMachinesModal
                    setLoader={setLoader}
                    multiSelect
                    uncheckFiltered
                    isOpen={isMachinesModalOpen}
                    onClose={closeMachinesModal}
                    displayedMachineIds={selectedMachineIds}
                    machines={machines}
                    onApply={machineIds => {
                      adjustToNewMachinesSelection(fromDate, filterToDate, machineIds)
                    }
                    }/>
                  : null
              }
              <Button
                justIcon
                round
                color="info"
                id="dailyWashRefreshButton"
                onClick={handleRefreshButtonClick}
                style={{ marginRight: '5px' }}
              >
                <Tooltip title={t('Refresh the page')}>
                  <Refresh/>
                </Tooltip>
              </Button>

              {
                washCount
                  ? <Button
                    justIcon
                    round
                    id="dailyWashToggleTemperatureScaleButton"
                    color="info"
                    onClick={toggleTemperatureScale}
                  >
                    <Tooltip title={t('Toggle temperature scale')}>
                      <span id="dailyWashTemperatureScale" style={{ fontSize: '1rem' }}>{isCelsius ? '°F' : '°C'}</span>
                    </Tooltip>
                  </Button>
                  : null
              }

              <div style={{ marginLeft: '5%' }}>
                {
                  isData
                    ? <DailyWashExportTable
                      tableRef={tableRef}
                      machinesBtnText={machinesBtnText}
                      serialNumbers={machinesSerialNumbers}
                      dateBtnText={dateBtnText}
                    />
                    : null
                }
              </div>

              {washCount
                ? <div
                  id="dailyWashStatistics" style={isMobile
                  ? {
                    display: 'grid'
                  }
                  : {
                    marginLeft: 'auto'
                  }}
                >
                  <div style={{
                    fontSize: '14px',
                    color: '#3c4858',
                    display: 'inline-block'
                  }}
                  >{t('Total washes')}: {washCount}
                  </div>
                  {isMobile ? null : ' | '}
                  <div style={{
                    fontSize: '14px',
                    color: '#3c4858',
                    display: 'inline-block',
                    fontWeight: 'bold'
                  }}
                  >{t('Total hygienic washes')}: {washCount - washFailedCount}
                  </div>
                  {isMobile ? null : ' | '}
                  <div style={{
                    fontSize: '14px',
                    color: '#3c4858',
                    display: 'inline-block'
                  }}
                  >{t('Averaged result')}: {Math.round(washAveragePercentage)}%
                  </div>
                </div>
                : null}
            </div>
            {
              hasPermission({ name: 'daily washes', action: 'delete' })
                ? <>
                  <FormControlLabel
                    style={{
                      fontSize: '1rem',
                      color: '#3c4858',
                      marginLeft: '0.1rem'
                    }}
                    id="editModeFormControl"
                    control={
                      <Switch
                        id="editModeSwitch"
                        checked={isEditMode}
                        onChange={() => {
                          if (isEditMode) {
                            unselectAll()
                          }
                          setIsEditMode(!isEditMode)
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={t('Edit mode')}
                  />
                  {
                    isEditMode
                      ? <Tooltip title={t('Remove selected washes')}>
                        <IconButton
                          aria-label="asdsd"
                          color="secondary"
                          disabled={!checkIsAnySelected()}
                          onClick={() => openDeleteDailyWashModal(getSelected())}
                        >
                          <DeleteIcon/>
                        </IconButton>
                      </Tooltip>
                      : null
                  }

                </>
                : null
            }

            {!washCount || selectedMachineIds.length === 0
              ? (
                <h1>{t('No Washes Found')}</h1>
              )
              :
              (
                <ReactTable
                  data={dailyWashesTableDataset}
                  ref={tableRef}
                  defaultFilterMethod={(filter, row) => {
                    const id = filter.pivotId || filter.id

                    if (typeof row[id] === 'number') {
                      return row[id] ? isCellNumberFiltered(row[id], filter.value) : false
                    } else {
                      return row[id] ? isCellTextFiltered(row[id], filter.value) : false
                    }
                  }}
                  columns={[
                    {
                      id: 'checkbox',
                      show: isEditMode,
                      minWidth: 40,
                      Header: () => {
                        return (
                          <Checkbox
                            id="dailyWashCheckAll"
                            onChange={() => {
                              toggleSelectAll()
                            }}
                            checkedIcon={
                              <Check
                                color="primary"
                                className={classes.checkedIcon}
                              />
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checked={checkIsAllSelected()}
                          />
                        )
                      },
                      Cell: ({ original = {} }) => {
                        return (
                          <div style={{ textAlign: 'left' }}>
                            <Checkbox
                              id="dailyWashCheckOne"
                              onChange={() => select(original[ID])}
                              checkedIcon={
                                <Check
                                  className={classes.checkedIcon}
                                  color="primary"
                                />
                              }
                              icon={<Check className={classes.uncheckedIcon}/>}
                              checked={checkIsChecked(original[ID]) || false}
                            />
                          </div>
                        )
                      },
                      sortable: false,
                      filterable: false,
                      sortMethod: (a, b) => {
                        // if descending, checked checkboxes has to be first, else they should be last
                        const isAChecked = selected[a]
                        const isBChecked = selected[b]
                        return isAChecked === isBChecked ? 0 : isAChecked ? -1 : 1
                      },
                      accessor: orig => orig[ID]
                    },
                    {
                      Header: () => (
                        <div
                          style={{
                            textAlign: 'left',
                            marginTop: 0

                          }}
                        >
                          <Typography variant="body2">{t('Time')}</Typography>
                        </div>
                      ),
                      Cell: ({ original }) => (
                        <div>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 400
                            }}
                          >
                            {original[WASH_DATE]}
                          </span>
                        </div>
                      ),
                      style: { textAlign: 'left' },
                      accessor: WASH_DATE,
                      sortable: true,
                      filterable: true,
                      width: 60
                    },
                    {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'
                          }}
                        >
                          <Typography variant="body2">{t('Group')}</Typography>
                        </div>
                      ),
                      Cell: ({ original }) => (
                        <div>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 400
                            }}
                          >
                            {original[GROUP]}
                          </span>
                        </div>
                      ),
                      style: { textAlign: 'left' },
                      accessor: GROUP,
                      sortable: true,
                      filterable: true
                    },
                    {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'
                          }}
                        >
                          <Typography variant="body2">{t('Washer')}</Typography>
                        </div>
                      ),
                      Cell: ({ original }) => (
                        <div>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 400
                            }}
                          >
                            {original[WASHER]}
                          </span>
                        </div>
                      ),
                      style: { textAlign: 'left' },
                      accessor: WASHER,
                      sortable: true,
                      filterable: true
                    },
                    {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'
                          }}
                        >
                          <Typography variant="body2">{t('Machine')}</Typography>
                        </div>
                      ),
                      Cell: ({ original }) => (
                        <div>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 400
                            }}
                          >
                            {original[SERIAL_NUMBER]}
                          </span>
                        </div>
                      ),
                      style: { textAlign: 'left' },
                      accessor: SERIAL_NUMBER,
                      sortable: true,
                      filterable: true
                    },
                    {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'
                          }}
                        >
                          <Typography variant="body2">{t('Site')}</Typography>
                        </div>
                      ),
                      Cell: ({ original }) => (
                        <div>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 400
                            }}
                          >
                            {original[SITE]}
                          </span>
                        </div>
                      ),
                      style: { textAlign: 'left' },
                      accessor: SITE,
                      sortable: true,
                      filterable: true
                    },
                    {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'
                          }}
                        >
                          <Typography variant="body2">{t('Internal Area')}</Typography>
                        </div>
                      ),
                      Cell: ({ original }) => (
                        <div>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 400
                            }}
                          >
                            {original[SITE_INTERNAL_AREA]}
                          </span>
                        </div>
                      ),
                      style: { textAlign: 'left' },
                      accessor: SITE_INTERNAL_AREA,
                      sortable: true,
                      filterable: true,
                      width: 80,
                    },
                    {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'
                          }}
                        >
                          <Typography variant="body2">{t('User temperature')}</Typography>
                        </div>
                      ),
                      Cell: ({ original }) => {
                        if (!original[TEMPERATURE]) {
                          return null
                        }
                        return (
                          <div>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: temperatureColorCheck(original[TEMPERATURE], temperatureThreshold)
                              }}
                            >
                              {original[TEMPERATURE] < 35.8 ? t('low') : original[TEMPERATURE] >= 41.5 ? t('high') : isCelsius ? original[TEMPERATURE] : toFahrenheit(original[TEMPERATURE]).toFixed(1)}
                            </span>
                          </div>
                        )
                      },
                      style: { textAlign: 'left' },
                      accessor: TEMPERATURE,
                      width: 70,
                      sortable: true,
                      filterable: false

                    },
                    {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'

                          }}
                        >
                          <Typography variant="body2">{t('Soap or sanitizer received')}</Typography>
                        </div>
                      ),
                      Cell: ({ original }) => renderCheckIcons(original[WASH_METRICS][DID_USE_SOAP]),
                      style: { textAlign: 'left' },
                      accessor: `${WASH_METRICS}.${DID_USE_SOAP}`,
                      sortable: true,
                      width: 100
                    }, {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'

                          }}
                        >
                          <Typography variant="body2">{t('Hands recognition')}</Typography>
                        </div>
                      ),
                      Cell: ({ original }) => renderHandsRecognitionIcons(original[HANDS_RECOGNITION].handsRecognition),
                      style: { textAlign: 'left' },
                      accessor: `${HANDS_RECOGNITION}`,
                      sortable: true,
                      width: 200,
                      sortMethod: (a, b) => {
                        a = a.count
                        b = b.count
                        if (a.length === b.length) {
                          return a < b ? 1 : -1
                        }
                        return a.length < b.length ? 1 : -1
                      }
                    },
                    {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'
                          }}
                        >
                          <Typography variant="body2">{t('Rub & Scrub')}</Typography>
                        </div>
                      ),
                      Cell: ({ original, index }) => {
                        const latheringTime = original[WASH_METRICS][[LATHERING_TIME]]
                        return (
                          <ChartistGraph
                            className="bar-chart"
                            key={index}
                            data={{
                              series: [{
                                value: original[WASH_METRICS][[DURING_LATHER]],
                                className: 'donut-lathering-a'
                              }, {
                                value: latheringTime - original[WASH_METRICS][[DURING_LATHER]],
                                className: 'donut-lathering-b'
                              }]
                            }}
                            responsiveOptions={[
                              ['screen and (max-width: 1600px) ', {
                                showPoint: false,
                                donutWidth: 20
                              }],
                              ['screen and (max-width: 1200px) ', {
                                showPoint: false,
                                donutWidth: 10
                              }],
                              ['screen and (min-width: 1200px) and (max-width: 1350px)', {
                                showPoint: false,
                                donutWidth: 15
                              }]

                            ]}
                            type="Pie"
                            options={{
                              donut: true,
                              donutWidth: 40,
                              donutSolid: true,

                              showLabel: false,
                              plugins: [
                                Chartist.plugins.fillDonut({
                                  items: [{
                                    style: {
                                      fontSize: '2rem'
                                    },
                                    content: original[WASH_METRICS][[DURING_LATHER]] === '-' ? '-' : `${original[WASH_METRICS][[DURING_LATHER]]}/${latheringTime}`
                                  }]
                                })
                              ]
                            }}
                          />
                        )
                      },
                      style: { marginLeft: '-2rem', marginRight: '2rem' },
                      accessor: `${WASH_METRICS}.${DURING_LATHER}`,
                      sortable: true

                    },
                    {
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'
                          }}
                        >
                          <Typography variant="body2">{t('Rinsing')}</Typography>
                        </div>
                      ),
                      Cell: ({ original, index }) => {
                        const risingTime = original[WASH_METRICS][[RINSING_TIME]]

                        return (
                          <ChartistGraph
                            className="bar-chart"
                            data={{
                              series: [{
                                value: original[WASH_METRICS][[DURING_RINSING]],
                                className: 'donut-lathering-a'
                              }, {
                                value: risingTime - original[WASH_METRICS][[DURING_RINSING]],
                                className: 'donut-lathering-b'
                              }]
                            }}
                            responsiveOptions={[
                              ['screen and (max-width: 1600px) ', {
                                showPoint: false,
                                donutWidth: 20
                              }],
                              ['screen and (max-width: 1200px) ', {
                                showPoint: false,
                                donutWidth: 10
                              }],
                              ['screen and (min-width: 1200px) and (max-width: 1350px)', {
                                showPoint: false,
                                donutWidth: 15
                              }]

                            ]}
                            type="Pie"
                            key={index}
                            options={{
                              donut: true,
                              donutWidth: 40,
                              donutSolid: true,
                              showLabel: false,
                              plugins: [
                                Chartist.plugins.fillDonut({
                                  items: [{
                                    style: {
                                      fontSize: '2rem'
                                    },
                                    content: original[WASH_METRICS][[DURING_RINSING]] === '-' ? '-' : `${original[WASH_METRICS][[DURING_RINSING]]}/${risingTime}`
                                  }]
                                })
                              ]
                            }}
                          />
                        )
                      },

                      style: { textAlign: 'left', marginLeft: '-2rem', marginRight: '2rem' },
                      accessor: `${WASH_METRICS}.${DURING_RINSING}`,
                      sortable: true
                    },
                    {
                      style: { marginLeft: '-2rem', marginRight: '2rem' },
                      Header: () => (
                        <div
                          style={{

                            textAlign: 'left'
                          }}
                        >
                          <Typography variant="body2">{t('Result')}</Typography>
                        </div>
                      ),
                      Cell: ({ original, index }) => {
                        return (
                          <ChartistGraph
                            className="bar-chart"
                            key={index}
                            data={{
                              series: [{
                                value: original[WASH_METRICS][[RESULT]],
                                className: 'donut-lathering-a'
                              },
                                { value: 100 - original[WASH_METRICS][[RESULT]], className: 'donut-lathering-b' }]
                            }}
                            responsiveOptions={[
                              ['screen and (max-width: 1600px) ', {
                                showPoint: false,
                                donutWidth: 20
                              }],
                              ['screen and (max-width: 1200px) ', {
                                showPoint: false,
                                donutWidth: 10
                              }],
                              ['screen and (min-width: 1200px) and (max-width: 1350px)', {
                                showPoint: false,
                                donutWidth: 15
                              }]

                            ]}
                            type="Pie"
                            options={{
                              donut: true,
                              donutWidth: 40,
                              donutSolid: true,
                              showLabel: false,
                              plugins: [
                                Chartist.plugins.fillDonut({
                                  items: [{
                                    style: {
                                      fontSize: '2rem'
                                    },
                                    content: original[WASH_METRICS][[RESULT]] === '-' ? '-' : `${original[WASH_METRICS][[RESULT]]}${'%'}`
                                  }]
                                })
                              ]
                            }}
                          />
                        )
                      },
                      filterMethod: (filter, row) => {
                        const min = filter.value[0]
                        const max = filter.value[1]

                        return row['washMetrics.result'] >= min && row['washMetrics.result'] <= max
                      },
                      Filter: ({ filter, onChange }) =>
                        (
                          <TwoThumbs
                            onFinalChange={(values) => onChange(values)}
                            trackRight="20%"
                          />),
                      accessor: `${WASH_METRICS}.${RESULT}`,
                      sortable: true,
                      filterable: true
                    },
                    // {
                    //   Cell: ({ original }) => {
                    //     return getStars(original[WASH_METRICS][RESULT])
                    //   },
                    //   width: 150,
                    //   accessor: `${WASH_METRICS}.${RESULT}`,
                    //   sortable: false
                    // }
                  ]}
                  defaultPageSize={DEFAULT_PAGE_SIZE}
                  nextText={t('Next')}
                  pageText={t('Page')}
                  ofText={t('Of')}
                  rowsText={t('Rows')}
                  previousText={t('Previous')}
                  onPageChange={() => {
                    document.getElementById('mainPanel').scrollTop = 0
                  }}
                  minRows={0}
                  showPaginationBottom={
                    dailyWashesTableDataset.length > DEFAULT_PAGE_SIZE
                  }
                  className="-striped -highlight"
                />
              )}
          </FullPagePaper>
      }
    </>
  )
}

export default withStyles(dailyWashStyle)(withAuthentication(withTranslation()(DailyWashes)))
