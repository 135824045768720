import { Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Edit from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import formStyle from '../../../../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../../components-soapy/LoadingButton.jsx'
import NotificationSettingsForm from '../../../../components-soapy/NotificationSettings/NotificationSettingsForm.jsx'
import CardBody from '../../../../components/Card/CardBody.jsx'
import CardHeader from '../../../../components/Card/CardHeader.jsx'
import CardIcon from '../../../../components/Card/CardIcon.jsx'
import CustomInput from '../../../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../../containers/withErrorNotification.js'
import editContact from '../../../../endpoint-requests/contact/editContact'
import addContact from '../../../../endpoint-requests/site/addSiteContact'
import useFormFields from '../../../../shared/hooks/useFormFields.js'
import validateFormFields from '../../../../utils/validateFormFields.js'
import { isEmail, verifyLength } from '../../../../utils/validators'

const VALIDATION_STATE = 'ValidationState'

const NAME = 'name'
const EMAIL = 'email'
const PHONE_NUMBER = 'phoneNumber'
const HIGH_TEMPERATURE_EMAIL = 'highTemperatureEmail'
const FAILED_WASH_EMAIL = 'failedWashEmail'
const MALFUNCTIONS_EMAIL = 'malfunctionsEmail'
const CHANGE_SOAP_EMAIL = 'changeSoapEmail'
const HIGH_TEMPERATURE_SMS = 'highTemperatureSms'
const FAILED_WASH_SMS = 'failedWashSms'
const MALFUNCTIONS_SMS = 'malfunctionsSms'
const CHANGE_SOAP_SMS = 'changeSoapSms'

let spinnerTimeout = null
const phoneInputComponent = (() => {
  return React.forwardRef(function (props, r) {
    return <CustomInput
      labelText='Phone number'
      inputProps={{
        ...props,
        inputRef: r
      }}
      formControlProps={{
        fullWidth: true
      }}
           />
  })
})()

const AddContact = ({
  classes,
  isClosed,
  showErrorNotification,
  contactEdited,
  isContactPage = false,
  contactAdded,
  siteId,
  contact,
  t
}) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const { formFields, setFormFields } = useFormFields({
    [NAME]: {
      value: '',
      isRequired: true,
      name: 'Name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 256),
          message: 'Name should not be empty, and up to 256 characters'
        }
      ]
    },
    [EMAIL]: {
      value: '',
      isRequired: true,
      name: 'Email',
      validators: [
        {
          isValid: (value) => isEmail(value),
          message: 'Email is not valid'
        }
      ]
    },
    [PHONE_NUMBER]: {
      value: '',
      isRequired: false,
      name: 'Phone number',
      validators: [
        {
          isValid: (value) => isValidPhoneNumber(value),
          message: 'Phone number Is Invalid'
        }
      ]
    },
    [HIGH_TEMPERATURE_EMAIL]: false,
    [FAILED_WASH_EMAIL]: false,
    [MALFUNCTIONS_EMAIL]: false,
    [CHANGE_SOAP_EMAIL]: false,
    [HIGH_TEMPERATURE_SMS]: false,
    [FAILED_WASH_SMS]: false,
    [MALFUNCTIONS_SMS]: false,
    [CHANGE_SOAP_SMS]: false
  })
  const submitForm = async () => {
    activateSpinner()
    try {
      if (!contact.id) {
        const newContact = await addContact({
          name: formFields[NAME].value,
          email: formFields[EMAIL].value,
          phoneNumber: formFields[PHONE_NUMBER].value,
          highTemperatureEmail: formFields[HIGH_TEMPERATURE_EMAIL],
          failedWashEmail: formFields[FAILED_WASH_EMAIL],
          malfunctionsEmail: formFields[MALFUNCTIONS_EMAIL],
          changeSoapEmail: formFields[CHANGE_SOAP_EMAIL],
          highTemperatureSms: formFields[HIGH_TEMPERATURE_SMS],
          failedWashSms: formFields[FAILED_WASH_SMS],
          malfunctionsSms: formFields[MALFUNCTIONS_SMS],
          changeSoapSms: formFields[CHANGE_SOAP_SMS]
        }, siteId)
        contactAdded(newContact)
      } else {
        const editedContact = await editContact({
          name: formFields[NAME].value,
          email: formFields[EMAIL].value,
          phoneNumber: formFields[PHONE_NUMBER].value,
          highTemperatureEmail: formFields[HIGH_TEMPERATURE_EMAIL],
          failedWashEmail: formFields[FAILED_WASH_EMAIL],
          malfunctionsEmail: formFields[MALFUNCTIONS_EMAIL],
          changeSoapEmail: formFields[CHANGE_SOAP_EMAIL],
          highTemperatureSms: formFields[HIGH_TEMPERATURE_SMS],
          failedWashSms: formFields[FAILED_WASH_SMS],
          malfunctionsSms: formFields[MALFUNCTIONS_SMS],
          changeSoapSms: formFields[CHANGE_SOAP_SMS]
        }, contact.id)
        contactEdited(editedContact)
      }
    } catch (err) {
      showErrorNotification(err.message)
    }

    disableSpinner()
  }

  const handleSwitchChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: event.target.checked })
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    setFormFields(
      (formFields) => ({
        [NAME]: { ...formFields[NAME], value: contact[NAME] || '' },
        [EMAIL]: { ...formFields[EMAIL], value: contact[EMAIL] || '' },
        [PHONE_NUMBER]: { ...formFields[PHONE_NUMBER], value: contact[PHONE_NUMBER] || '' },
        [HIGH_TEMPERATURE_EMAIL]: contact.notificationSettings ? contact.notificationSettings[HIGH_TEMPERATURE_EMAIL] : false,
        [HIGH_TEMPERATURE_SMS]: contact.notificationSettings ? contact.notificationSettings[HIGH_TEMPERATURE_SMS] : false,
        [FAILED_WASH_EMAIL]: contact.notificationSettings ? contact.notificationSettings[FAILED_WASH_EMAIL] : false,
        [FAILED_WASH_SMS]: contact.notificationSettings ? contact.notificationSettings[FAILED_WASH_SMS] : false,
        [MALFUNCTIONS_EMAIL]: contact.notificationSettings ? contact.notificationSettings[MALFUNCTIONS_EMAIL] : false,
        [MALFUNCTIONS_SMS]: contact.notificationSettings ? contact.notificationSettings[MALFUNCTIONS_SMS] : false,
        [CHANGE_SOAP_EMAIL]: contact.notificationSettings ? contact.notificationSettings[CHANGE_SOAP_EMAIL] : false,
        [CHANGE_SOAP_SMS]: contact.notificationSettings ? contact.notificationSettings[CHANGE_SOAP_SMS] : false
      })
    )
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [contact, isClosed]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [])

  const handleTextChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: event.target.value }, [fieldName + VALIDATION_STATE]: '' })
  }
  return (
    <GridContainer id='content-pane-layout' justify='center'>

      <GridItem md={9}>
        <Card style={isContactPage ? { boxShadow: 'none' } : {}}>
          {!isContactPage ? <CardHeader>
            <CardIcon color='info'>
              <Edit
                className={classes.cardHeaderIcon}
                style={{ fontSize: 30 }}
              />
            </CardIcon>
          </CardHeader> : null}
          <CardBody>
            <div>
              <form>
                <GridContainer
                  direction='column'
                  justify='center'
                  alignItems='center'
                  id='card-body-section'
                >
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id='editProfileFirstName'
                      labelText={`${t('Name')} *`}
                      error={
                        formFields[NAME + VALIDATION_STATE] === 'error'
                      }
                      inputProps={{
                        value: formFields[NAME].value,
                        type: 'name', // TODO: Verify this
                        autoComplete: 'name', // TODO: Verify this
                        onChange: event => {
                          handleTextChange(event, NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id='editProfileEmail'
                      labelText={`${t('Email')} *`}
                      error={
                        formFields[EMAIL + VALIDATION_STATE] === 'error'
                      }
                      style={{ color: 'grey' }}
                      inputProps={{
                        value: formFields[EMAIL].value,
                        type: 'name', // TODO: Verify this
                        autoComplete: 'name', // TODO: Verify this
                        onChange: event => {
                          handleTextChange(event, EMAIL)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                  <GridItem style={{ minWidth: '40vh' }}>
                    <PhoneInput
                      id='contactPhoneInput'
                      value={formFields[PHONE_NUMBER].value}
                      inputComponent={phoneInputComponent}
                      onChange={(phoneNumber) => {
                        setFormFields({ ...formFields, [PHONE_NUMBER]: { ...formFields[PHONE_NUMBER], value: phoneNumber } })
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <Grid
                  container
                  direction='column'
                  justify='center'
                  alignItems='center'
                >

                  <NotificationSettingsForm formFields={formFields} handleSwitchChange={handleSwitchChange} spacing={4} />
                  <Grid
                    item
                  >

                    <LoadingButton
                      color='info'
                      type='submit'
                      id='siteEditSaveButton'
                      onClick={(e) => {
                        e.preventDefault()
                        handleSaveBtnClick()
                      }}
                      fullWidth
                      disabled={isAwaitingResponse}
                      isLoading={isAwaitingResponse}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </Grid>

                </Grid>

              </form>

            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withTranslation()(AddContact))))
