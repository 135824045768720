import axios from 'axios'

import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import requestKeywords from '../../shared/update-machine-settings/request-keywords'
import failedSession from '../failedSession'

const updateMachineSettings = async (
	dashboardServerUrl,
	faceRecognitionServerUrl,
	updateParametersTime,
	createUsageEventUrl,
	logUrl,
	handRecognitionUrl
	) => {
  const URL = SERVER_PATH + apiEndpoints.machineUpdateSettings
  try {
    const requestBody = {
      [requestKeywords.DASHBOARD_SERVER_URL]: dashboardServerUrl,
      [requestKeywords.FACE_RECOGNITION_SERVER_URL]: faceRecognitionServerUrl,
      [requestKeywords.UPDATE_PARAMETERS_TIME]: updateParametersTime,
      [requestKeywords.CREATE_USAGE_EVENT_URL]: createUsageEventUrl,
      [requestKeywords.LOG_URL]: logUrl,
      [requestKeywords.HAND_RECOGNITION_URL]: handRecognitionUrl,
    }

    const { data: statusesObject } = await axios.post(URL, requestBody, {
      withCredentials: true
    })

    return statusesObject === 'OK'
  } catch (e) {
    failedSession(e)
    return { error: 'unknown' }
  }
}

export default updateMachineSettings
