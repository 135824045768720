import { Box, CircularProgress, Tooltip, withStyles } from '@material-ui/core'
import Highcharts from 'highcharts'
import highchartsExporting from 'highcharts/modules/exporting'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import trendsStyle from '../../assets-soapy/jss/soapy/views/trendsStyle'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import SelectButton from '../../components-soapy/SelectButton.jsx'
import TrendsMachinesModal from '../../components-soapy/TrendsMachinesModal'
import Button from '../../components/CustomButtons/Button.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getCookie from '../../shared/cookie/get-cookie'
import setCookie from '../../shared/cookie/set-cookie.js'
import getLanguageLocale from '../../shared/multi-language/getLanguageLocale.js'
import DateModal from '../../shared/DateModal/DateModal'
import HandsRecognitionCountChart from './HandsRecognitionCount/HandsRecognitionCountChart.jsx'
import MaxTemperatureChart from './MaxTemperature/MaxTemperatureChart'
import MaxTemperatureExport from './MaxTemperature/MaxTemperatureExport.js'
import WashesChart from './Washes/WashesChart.jsx'
import WashesExport from './Washes/WashesExport.jsx'
import WashesSumChart from './WashesSum/WashesSumChart.js'
import WashesSumExport from './WashesSum/WashesSumExport.js'
import Refresh from '@material-ui/icons/Refresh'
import { getTrendsDailyMachineUsages } from '../../redux/apiCalls'
import splitDailyMachineUsages from './splitDailyMachineUsages'
import formatChartDates from './formatChartDates'

// init the module
highchartsExporting(Highcharts)
const WASH_FAILED_COUNT = 'washFailedCount'
const MAX_TEMPERATURE = 'maxTemperature'
const WASH_COUNT = 'washCount'
const TEMPERATURE_TYPE = 'temperatureType'
const WASH_AVERAGE_PERCENTAGE = 'washAveragePercentage'

// split types
const DAY = 'day'
const WEEK = 'week'
const MONTH = 'month'

const formatDateBtnText = (fromDate, toDate) =>
  `${fromDate.locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm')} - ${toDate.locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm')}`

const formatMachineBtnText = (machines, chosenMachinesIds, t) => {
  chosenMachinesIds = chosenMachinesIds.filter((id) => machines.findIndex(machine => machine.id === id) !== -1)

  if (chosenMachinesIds.length === 0) {
    return t('No machines chosen')
  } else {
    if (chosenMachinesIds.length === 1) {
      const { site, internalArea, siteCity } = machines.find(machine => machine.id === chosenMachinesIds[0])
      return `${site || ''}${internalArea ? ' -  ' + internalArea : ''}${siteCity ? ' -  ' + siteCity : ''}`
    } else {
      return `Machines chosen: ${chosenMachinesIds.length}`
    }
  }
}

const getSum = (fieldName, dailyMachineUsages) => {
  return dailyMachineUsages.reduce((sum, dailyMachineUsage) => {
    if (!dailyMachineUsage[fieldName]) return sum
    if (fieldName === WASH_AVERAGE_PERCENTAGE) {
      sum += dailyMachineUsage[fieldName] * dailyMachineUsage[WASH_COUNT]
    } else {
      sum += dailyMachineUsage[fieldName]
    }

    return sum
  }, 0)
}

const Trends = ({
  fetchDailyMachineUsages,
  machines,
  selectedMachineIds,
  isDateModalOpen,
  openDateModal,
  openMachinesModal,
  closeDateModal,
  closeMachinesModal,
  isMachinesModalOpen,
  highcharts,
  classes,
  fromDate,
  currentDashboardUser,
  filterByHours,
  fromHour,
  toHour,
  toDate,
  showNoDataError,
  dailyMachineUsagesInfo,
  selectMachines,
  handsRecognitionDataInfo,
  t
}) => {
  const [isCelsius, setIsCelsius] = useState(getCookie('trendsIsCelsius') === 'true')
  const [activeTab, setActiveTab] = useState(getCookie('trendsActiveTab') ? parseInt(getCookie('trendsActiveTab')) : 1)
  const [splitDatesBy, setSplitDatesBy] = useState(
    getCookie('splitDatesBy') ? getCookie('splitDatesBy') : DAY
  )
  const [dailyMachineUsages, setDailyMachineUsages] = useState(dailyMachineUsagesInfo)
  const [handsRecognitionData, setHandRecognitionData] = useState(handsRecognitionDataInfo)
  const [loader, setLoader] = useState(false)

  const [machinesBtnText, setMachinesBtnText] = useState(formatMachineBtnText(
    machines,
    selectedMachineIds,
    t
  ))
  const washesChartRef = useRef()
  const maxTemperatureChartRef = useRef()
  const washesSumChartRef = useRef()

  useEffect(() => {
    setDailyMachineUsages(dailyMachineUsagesInfo)
    setHandRecognitionData(handsRecognitionDataInfo)
  }, [dailyMachineUsagesInfo, handsRecognitionDataInfo])

  const adjustToNewDateRange = (fromDate, toDate, fromHour, toHour, filterByHours) => {
    fetchDailyMachineUsages(fromDate, toDate, selectedMachineIds, fromHour, toHour, filterByHours)
    closeDateModal()
  }

  useEffect(() => {
    if (currentDashboardUser.entity.name === 'site' && currentDashboardUser.sites.length) {
      setIsCelsius(currentDashboardUser.sites[0][TEMPERATURE_TYPE] === 'C')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleTemperatureScale = () => {
    setCookie('trendsIsCelsius', !isCelsius)
    setIsCelsius(!isCelsius)
  }

  const setActiveTabState = (tabIndex) => {
    setCookie('trendsActiveTab', tabIndex)
    setActiveTab(tabIndex)
  }

  const adjustToNewMachinesSelection = async (fromDate, toDate, machineIds) => {
    setMachinesBtnText(formatMachineBtnText(machines, machineIds))
    fetchDailyMachineUsages(fromDate, toDate, machineIds, fromHour, toHour, filterByHours)
    closeMachinesModal()
    setCookie('usageMachineIds', machineIds)
  }

  const handleRefreshButtonClick = () => {
    setLoader(true)
    setMachinesBtnText(formatMachineBtnText(machines, selectedMachineIds))
    getTrendsDailyMachineUsages(fromDate, toDate, selectedMachineIds, setDailyMachineUsages, setHandRecognitionData, setLoader)
  }

  const chartDataByWeek = useMemo(() => splitDailyMachineUsages(dailyMachineUsages, fromDate, toDate, WEEK), [dailyMachineUsages]) // eslint-disable-line react-hooks/exhaustive-deps
  const chartDataByMonth = useMemo(() => splitDailyMachineUsages(dailyMachineUsages, fromDate, toDate, MONTH), [dailyMachineUsages]) // eslint-disable-line react-hooks/exhaustive-deps
  const chartDataByDay = useMemo(() => splitDailyMachineUsages(dailyMachineUsages, fromDate, toDate, DAY), [dailyMachineUsages]) // eslint-disable-line react-hooks/exhaustive-deps
  const totalWashes = useMemo(() => getSum(WASH_COUNT, dailyMachineUsages), [dailyMachineUsages])
  const countFailed = useMemo(() => getSum(WASH_FAILED_COUNT, dailyMachineUsages), [dailyMachineUsages])
  const averagePercentage = useMemo(() => {
    return totalWashes ? Math.round(getSum(WASH_AVERAGE_PERCENTAGE, dailyMachineUsages) / totalWashes) : 0
  }, [totalWashes, dailyMachineUsages])
  const chartData = splitDatesBy === DAY ? chartDataByDay : splitDatesBy === WEEK ? chartDataByWeek : splitDatesBy === MONTH ? chartDataByMonth : null

  const dateBtnText = formatDateBtnText(fromDate, toDate)

  const totalWashesText = `${t('Total washes')}: ${totalWashes}`
  const totalFailWashesText = `${t('Total hygienic washes')}: ${totalWashes - countFailed}`
  const averageResultText = `${t('Averaged result')}: ${averagePercentage}%`
  return (
    <>
      {
        loader
          ?
          <Box sx={{ position: 'absolute', marginLeft: '45%', marginTop: '18%', zIndex: '100' }}>
            <CircularProgress/>
          </Box>
          :
          <FullPagePaper>
            <div id="divToPrint">
              <div style={{ display: 'flex' }}>
                <Button
                  style={{ marginRight: 5 }}
                  color="info"
                  id="trendsDateButton"
                  round
                  onClick={openDateModal}
                >
                  {dateBtnText}
                </Button>
                <DateModal
                  displayedFromDate={fromDate}
                  displayedToDate={toDate}
                  filterByHours={filterByHours}
                  fromHour={fromHour}
                  toHour={toHour}
                  handleApplyBtnClick={(fromDate, toDate, fromHour, toHour, filterByHours) => adjustToNewDateRange(fromDate, toDate, fromHour, toHour, filterByHours)}
                  closeModal={closeDateModal}
                  isOpen={isDateModalOpen}
                />
                <Button id="trendsMachinesButton" color="info" round onClick={openMachinesModal}>
                  {machinesBtnText}
                </Button>
                {
                  totalWashes
                    ? <SelectButton
                      menuItems={[DAY, WEEK, MONTH]}
                      selected={splitDatesBy}
                      btnProps={
                        {
                          style: { marginLeft: 5 },
                          round: true,
                          color: 'info'
                        }
                      }
                      btnTxt="Group by:"
                      onSelect={(value) => {
                        setCookie('splitDatesBy', value)
                        setSplitDatesBy(value)
                      }}
                    />
                    : null
                }
                <Button
                  justIcon
                  round
                  color="info"
                  id="dailyWashRefreshButton"
                  onClick={handleRefreshButtonClick}
                  style={{ marginLeft: '5px' }}
                >
                  <Tooltip title={t('Refresh the page')}>
                    <Refresh/>
                  </Tooltip>
                </Button>
                {
                  activeTab === 3
                    ? <Button
                      justIcon
                      id="trendsToggleTemperatureScaleButton"
                      round
                      style={{ marginLeft: 5 }}
                      color="info"
                      onClick={toggleTemperatureScale}
                    >
                      <Tooltip title={t('Toggle temperature scale')}>
                        <span id="trendsTemperatureScale" style={{ fontSize: '1rem' }}>{isCelsius ? '°F' : '°C'}</span>
                      </Tooltip>
                    </Button>
                    : null
                }
                {totalWashes
                  ? <div
                    id="trendsStatistics" style={{
                    marginLeft: 'auto'
                  }}
                  >
                    <div style={{
                      fontSize: '14px',
                      color: '#3c4858',
                      display: 'inline-block'
                    }}
                    >{totalWashesText}
                    </div>
                    {' | '}
                    <div style={{
                      fontSize: '14px',
                      color: '#3c4858',
                      display: 'inline-block',
                      fontWeight: 'bold'
                    }}
                    >{totalFailWashesText}
                    </div>
                    {' | '}
                    <div style={{
                      fontSize: '14px',
                      color: '#3c4858',
                      display: 'inline-block'
                    }}
                    >{averageResultText}
                    </div>
                  </div>
                  : null}
              </div>
              {
                isMachinesModalOpen
                  ? <TrendsMachinesModal
                    machines={machines}
                    multiSelect
                    uncheckFiltered
                    isOpen={isMachinesModalOpen}
                    displayedMachineIds={selectedMachineIds}
                    onClose={closeMachinesModal}
                    onApply={machineIds => adjustToNewMachinesSelection(fromDate, toDate, machineIds)}
                  />
                  : null
              }

              {showNoDataError
                ? (
                  <h3>
                    {t('No data found for the selected unit(s) in the selected date range')}
                  </h3>
                )
                : (
                  <>

                    <div className={classes.tabs}>
                      <nav className={classes.nav}>
                        <a
                          value="1" href=" #" id="trendsWashesTab" className={activeTab === 1 ? classes.active : null}
                          onClick={() => setActiveTabState(1)}
                        >{t('Washes')}
                        </a>
                        <a
                          value="2" href=" #" id="trendsWashesSumTab"
                          className={activeTab === 2 ? classes.active : null}
                          onClick={() => setActiveTabState(2)}
                        >{t('Washes sum')}
                        </a>
                        <a
                          value="3" href=" #" id="trendsTemperatureTab"
                          className={activeTab === 3 ? classes.active : null}
                          onClick={() => setActiveTabState(3)}
                        >{t('Temperature')}
                        </a>
                        <a
                          value="3" href=" #" id="handMovementTab" className={activeTab === 4 ? classes.active : null}
                          onClick={() => setActiveTabState(4)}
                        >{t('Hand movement summary')}
                        </a>

                        {
                          activeTab === 1
                            ? <WashesExport washesChartRef={washesChartRef} dateBtnText={dateBtnText}/>
                            : null

                        }
                        {
                          activeTab === 2
                            ? <WashesSumExport
                              washesSumChartRef={washesSumChartRef}
                              dateBtnText={dateBtnText}
                            />
                            : null
                        }
                        {
                          activeTab === 3
                            ? <MaxTemperatureExport
                              isCelsius={isCelsius}
                              maxTemperatureChartRef={maxTemperatureChartRef}
                              dateBtnText={dateBtnText}
                            />
                            : null
                        }
                      </nav>
                      {
                        activeTab === 1
                          ? <div className={classes.content}>
                            <WashesChart formatChartDates={formatChartDates.bind(null, fromDate, toDate, splitDatesBy)}
                                         selectedMachineIds={selectedMachineIds} machines={machines}
                                         washesChartRef={washesChartRef} fromDate={fromDate} toDate={toDate}
                                         chartData={chartData}/>
                          </div>
                          : null

                      }
                      {
                        activeTab === 2
                          ? <div className={classes.content}>
                            <WashesSumChart formatChartDates={formatChartDates.bind(null, fromDate, toDate, splitDatesBy)}
                                            washesSumChartRef={washesSumChartRef} chartData={chartData}
                                            fromDate={fromDate} toDate={toDate}/>
                          </div>
                          : null
                      }
                      {
                        activeTab === 3
                          ? <div className={classes.content}>
                            <MaxTemperatureChart
                              formatChartDates={formatChartDates.bind(null, fromDate, toDate, splitDatesBy)}
                              machines={machines} isCelsius={isCelsius} chartRef={maxTemperatureChartRef}
                              chartData={chartData} machineIds={selectedMachineIds} fromDate={fromDate} toDate={toDate}/>
                          </div>
                          : null
                      }
                      {
                        activeTab === 4
                          ? <HandsRecognitionCountChart
                            data={handsRecognitionData}
                          />
                          : null
                      }
                    </div>
                  </>
                )}
            </div>
          </FullPagePaper>
      }
    </>
  )
}

export default withAuthentication(withTranslation()(withStyles(trendsStyle)(Trends)))
