import fetchSites from '../../endpoint-requests/site/fetchSites.js'
import fetchDistributors from '../../endpoint-requests/distributor/fetchDistributors.js'
import fetchOrganizations from '../../endpoint-requests/organization/fetchOrganizations.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useMachine } from '@xstate/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import DeleteModal from '../../components-soapy/ConfirmationModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import Snackbar from '../../components/Snackbar/Snackbar.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import deleteDashboardUser from '../../endpoint-requests/dashboard-user/deleteDashboardUser.js'
import getNotificationSettings from '../../endpoint-requests/dashboard-user/getNotificationSettings.js'
import fetchDashboardUsers from '../../endpoint-requests/dashboard-user/fetchDashboardUsers.js'
import DashboardUsersTable from './DashboardUsersTable'
import DashboardUsersTableHeader from './DashboardUsersTableHeader.jsx'
import DashboardUserModal from './modals/DashboardUserModal'
import NotificationSettingsModal from './modals/NotificationSettingsModal.js'
import ResetPasswordModal from './modals/ResetPasswordModal.js'

const getMachine = (user) => {
  return Machine({
    initial: 'loadingDashboardUsers',
    context: {
      dashboardUsers: {},
      organizations: {},
      distributors: {},
      isNotificationSettingsLoading: false,
      notificationSettings: {},
      sites: [],
      errorType: '',
      dashboardUser: {},
      isSuccessNotificationVisible: false,
      isDeleteModalOpen: false
    },
    states: {
      loadingDashboardUsers: {
        invoke: {
          src: (context, event) => Promise.all(
            [
              fetchDashboardUsers(),
              user.entity.name === 'organization' || user.entity.name === 'site' ? user.organizations : fetchOrganizations({ order: 'asc', orderBy: 'name' }),
              user.entity.name === 'neo' ? fetchDistributors() : null,
              user.entity.name === 'site' ? user.sites : fetchSites()
            ]
          ),
          onDone: {
            target: 'main',
            actions: assign({
              dashboardUsers: (context, event) => event.data[0],
              organizations: (context, event) => {
                const organizations = event.data[1]
                return organizations.reduce((acc, organization) => {
                  acc[organization.id] = {
                    name: organization.name,
                    distributorId: organization.distributorId
                  }
                  return acc
                }, {})
              },
              distributors: (context, event) => {
                const distributors = event.data[2]
                if (distributors) {
                  return distributors.reduce((acc, distributor) => {
                    acc[distributor.id] = distributor.name
                    return acc
                  }, {})
                }
                return null
              },
              sites: (context, event) => {
                const sites = event.data[3]
                return sites.reduce((acc, site) => {
                  acc[site.id] = {
                    name: site.name,
                    organizationId: site.organizationId
                  }
                  return acc
                }, {})
              }
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.error
            })
          }
        }
      },
      failedFetchingData: {
        type: 'final'
      },
      deleteModal: {
        on: {
          OPEN_DELETE_MODAL: 'deleteModal',
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          },
          CLOSE_DELETE_MODAL: 'main',
          DASHBOARD_USER_DELETED: {
            target: 'main',
            actions: ['deleteDashboardUser']
          }
        }
      },
      main: {
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        exit: ['hideSuccessNotification'],
        on: {
          OPEN_DASHBOARD_USER_MODAL: {
            target: 'dashboardUserModal',
            actions: ['setDashboardUser']
          },
          OPEN_RESET_PASSWORD_MODAL: {
            target: 'resetPasswordModal',
            actions: ['setDashboardUser']
          },
          OPEN_NOTIFICATION_SETTINGS_MODAL: {
            target: 'notificationSettingsModal',
            actions: ['setDashboardUser']
          },
          OPEN_DELETE_MODAL: {
            target: 'deleteModal',
            actions: ['setDashboardUser']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          },
          HIDE_SUCCESS_NOTIFICATION: {
            actions: ['hideSuccessNotification']
          },
          CLOSE_DELETE_MODAL: 'main'
        }
      },
      dashboardUserModal: {
        on: {
          CLOSE_DASHBOARD_USER_MODAL: 'main',
          DASHBOARD_USER_ADDED: {
            target: 'main',
            actions: ['addDashboardUser']
          },
          DASHBOARD_USER_EDITED: {
            target: 'main',
            actions: ['editDashboardUser']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      notificationSettingsModal: {
        entry: ['showNotificationSettingsLoading'],
        on: {
          CLOSE_RESET_PASSWORD_MODAL: 'main',
          NOTIFICATION_SETTINGS_CHANGED: 'main',
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        },
        invoke: {
          src: (context) => getNotificationSettings(context.dashboardUser.id),
          onDone: {
            actions: assign({
              notificationSettings: (context, event) => event.data.data,
              isNotificationSettingsLoading: (context, event) => false
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.error,
              isNotificationSettingsLoading: (context, event) => false
            })
          }
        }
      },
      resetPasswordModal: {
        on: {
          CLOSE_RESET_PASSWORD_MODAL: 'main',
          PASSWORD_CHANGED: 'main',
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      }
    }
  },
  {
    actions: {
      showNotificationSettingsLoading: assign({
        isNotificationSettingsLoading: true
      }),
      showSuccessNotification: assign({
        isSuccessNotificationVisible: true
      }),
      hideSuccessNotification: assign({
        isSuccessNotificationVisible: false
      }),
      editDashboardUser: assign({
        dashboardUsers: (context, event) => context.dashboardUsers.map(dashboardUser => dashboardUser.id === event.dashboardUser.id ? event.dashboardUser : dashboardUser)
      }),
      addDashboardUser: assign({
        dashboardUsers: (context, event) => [event.dashboardUser, ...context.dashboardUsers]
      }),
      setDashboardUser: assign({
        dashboardUser: (context, event) => event.dashboardUser
      }),
      deleteDashboardUser: assign({
        dashboardUsers: (context, event) => context.dashboardUsers.filter(dashboardUser => dashboardUser.id !== event.dashboardUser.id)
      })
    },
    guards: {
      isSuccessNotificationVisible: (context, event) => {
        return !!context.isSuccessNotificationVisible
      }
    }

  }
  )
}

const DashboardUsersContainer = ({ t, currentDashboardUser }) => {
  const [current, send] = useMachine(getMachine(currentDashboardUser))
  switch (current.value) {
    case 'loadingDashboardUsers':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t('Session Is Over Error')}</div>
    default: {
      const {
        dashboardUsers,
        organizations,
        distributors,
        sites,
        dashboardUser,
        isSuccessNotificationVisible,
        isNotificationSettingsLoading,
        notificationSettings
      } = current.context
      const isDashboardUserModalOpen = current.value === 'dashboardUserModal'
      const isDeleteModalOpen = current.value === 'deleteModal'
      const isResetPasswordModalOpen = current.value === 'resetPasswordModal'
      const isNotificationSettingsModalOpen = current.value === 'notificationSettingsModal'
      return (
        <FullPagePaper>
          <Snackbar
            place='tc'
            color='success'
            icon={NotificationsIcon}
            message={t('Saved') + '!'}
            open={isSuccessNotificationVisible}
          />
          <NotificationSettingsModal
            isOpen={isNotificationSettingsModalOpen}
            onClose={() => send('CLOSE_RESET_PASSWORD_MODAL')}
            dashboardUserId={dashboardUser.id}
            notificationSettingsChanged={() => send(['SHOW_SUCCESS_NOTIFICATION', 'NOTIFICATION_SETTINGS_CHANGED'])}
            isNotificationSettingsLoading={isNotificationSettingsLoading}
            notificationSettings={notificationSettings}
          />
          <DashboardUserModal
            isOpen={isDashboardUserModalOpen}
            organizations={organizations}
            sites={sites}
            distributors={distributors}
            dashboardUser={dashboardUser}
            onClose={() => send('CLOSE_DASHBOARD_USER_MODAL')}
            dashboardUserAdded={(dashboardUser) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'DASHBOARD_USER_ADDED', dashboardUser }])
            }}
            dashboardUserEdited={(dashboardUser) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'DASHBOARD_USER_EDITED', dashboardUser }])
            }}
          />
          <ResetPasswordModal
            isOpen={isResetPasswordModalOpen}
            passwordChanged={() => send(['SHOW_SUCCESS_NOTIFICATION', 'PASSWORD_CHANGED'])}
            dashboardUserId={dashboardUser.id}
            onClose={() => send('CLOSE_RESET_PASSWORD_MODAL')}
          />
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={() => {
              send('CLOSE_DELETE_MODAL')
            }}
            onApply={async () => {
              await deleteDashboardUser(dashboardUser.id)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'DASHBOARD_USER_DELETED', dashboardUser }])
            }}
          />
          <DashboardUsersTableHeader
            openDashboardUserModal={() => {
              send({ type: 'OPEN_DASHBOARD_USER_MODAL', dashboardUser: {} })
            }}
          />
          <DashboardUsersTable
            dashboardUsers={dashboardUsers}
            openDeleteModal={(dashboardUser) => {
              send({ type: 'OPEN_DELETE_MODAL', dashboardUser })
            }}
            openUpdateModal={(dashboardUser) => {
              send({ type: 'OPEN_DASHBOARD_USER_MODAL', dashboardUser })
            }}
            openResetPasswordModal={(dashboardUser) => {
              send({ type: 'OPEN_RESET_PASSWORD_MODAL', dashboardUser })
            }}
            openNotificationSettingsModal={(dashboardUser) => {
              send({ type: 'OPEN_NOTIFICATION_SETTINGS_MODAL', dashboardUser })
            }}
          />
        </FullPagePaper>
      )
    }
  }
}

export default withAuthentication(withTranslation()(DashboardUsersContainer))
