import getSiteConfiguration from '../../../endpoint-requests/site/getSiteConfiguration.js'
import normalizeTemperature from '../../../shared/temperature/normalizeTemperature.js'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useState, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import withAuthentication from '../../../containers/Authentication/withAuthentication'
// import getOrganizationTemperatureConfig from '../../../endpoint-requests/organization/getOrganizationTemperatureConfig'
import toFahrenheit from '../../../shared/temperature/toFahrenheit.js'

const DEFAULT_TEMPERATURE_THRESHOLD = 32

const mapSiteIdsToName = (machines, machineIds) => {
  return machineIds.reduce((acc, machineId) => {
    const machine = machines.find(machine => machine.id === machineId)
    if (machine.site) {
      if (!acc[machine.siteId]) {
        acc[machine.siteId] = machine.site
      }
    }
    return acc
  }, {})
}
const mapSiteIdsToMachineIds = (machines, machineIds) => {
  return machineIds.reduce((acc, machineId) => {
    const machine = machines.find(machine => machine.id === machineId)
    if (machine.site) {
      if (!acc[machine.siteId]) {
        acc[machine.siteId] = [machine.id]
      } else {
        acc[machine.siteId].push(machine.id)
      }
    }
    return acc
  }, {})
}

const getSiteIdFromMachines = (machines, machineIds) => {
  let siteId = ''
  for (const machineId of machineIds) {
    const machine = machines.find(machine => machine.id === machineId)
    if (!machine.site) {
      return null
    } else {
      if (!siteId) {
        siteId = machine.siteId
      }
      if (siteId && siteId !== machine.siteId) return null
    }
  }
  return siteId
}

const MaxTemperatureChart = ({ t, isCelsius, machines, formatChartDates, currentDashboardUser, chartData, chartRef, machineIds, fromDate, toDate, ...rest }) => {
  const [threshold, setThreshold] = useState(DEFAULT_TEMPERATURE_THRESHOLD)
  const [selectedSite, setSelectedSite] = useState('all')
  const [yAxis, setYAxis] = useState([])
  const siteIdsToName = useMemo(() => mapSiteIdsToName(machines, machineIds), [machines, machineIds])
  const siteIdsToMachineIds = useMemo(() => mapSiteIdsToMachineIds(machines, machineIds), [machines, machineIds])

  const updateDailyTemperatures = (chartData, machineIds, isCelsius) => {
    const yAxis = chartData.data.map(machineIdToDailyMachineUsages => {
      if (!machineIdToDailyMachineUsages) return null
      let y = {}
      for (const machineId in machineIdToDailyMachineUsages) {
        if (machineIds.indexOf(machineId) === -1) continue
        if (!y.maxTemperature || (machineIdToDailyMachineUsages[machineId].maxTemperature > y.maxTemperature)) {
          y = {
            maxTemperature: machineIdToDailyMachineUsages[machineId].maxTemperature,
            machineSerialNumber: machineIdToDailyMachineUsages[machineId].machine.serialNumber,
            maxTemperatureFaceId: machineIdToDailyMachineUsages[machineId].maxTemperatureFaceId
          }
        }
      }
      return y
    })
    applyDataToChart(yAxis)
  }

  const selectSite = (siteId) => {
    if (siteId === 'all') {
      return updateDailyTemperatures(chartData, machineIds, isCelsius)
    }
    updateDailyTemperatures(chartData, siteIdsToMachineIds[siteId], isCelsius)
    updateTemperatureThreshold(siteId)
  }

  const updateTemperatureThreshold = async (siteId) => {
    try {
      const configuration = await getSiteConfiguration(siteId)
      const threshold = configuration.temperatureThreshold || DEFAULT_TEMPERATURE_THRESHOLD
      setThreshold(threshold)
    } catch (err) {
      setThreshold(DEFAULT_TEMPERATURE_THRESHOLD)
    }
  }

  useEffect(() => {
    (async () => {
      const siteId = getSiteIdFromMachines(machines, machineIds)
      if (siteId) {
        await updateTemperatureThreshold(siteId)
      }
      updateDailyTemperatures(chartData, machineIds, isCelsius)
    })()
  }, [chartData]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSiteChange = (e) => {
    setSelectedSite(e.target.value)
    selectSite(e.target.value)
  }

  const applyDataToChart = (yAxis) => {
    setYAxis(yAxis)
  }
  const temperatureChart = <HighchartsReact
    highcharts={Highcharts}
    ref={chartRef}
    constructorType='chart'

    options={{
      chart: {
        renderTo: 'container',
        // defaultSeriesType: 'area',
        height: 500
      },
      tooltip: {
        formatter: function () {
          return `
          <b>${this.x}</b> <br/>
            ${this.series.name}: ${this.y}<br/>
            ${yAxis[this.point.index] ? `${t('Machine')}: ${yAxis[this.point.index].machineSerialNumber}<br/>` : ''}
            ${yAxis[this.point.index] && yAxis[this.point.index].maxTemperatureFaceId ? `${t('User')}: ${yAxis[this.point.index].maxTemperatureFaceId}<br/>` : ''}
          `
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      yAxis: {
        title: {
          text: `${t('Temperature')} ${isCelsius ? '°C' : '°F'}`
        }
      },
      xAxis: {
        // this function is important to translate dates, when language changes
        categories: formatChartDates(chartData.dates)
      },
      exporting: { enabled: false },
      series: [
        {
          name: `${t('Maximum Temperature')} ${isCelsius ? '°C' : '°F'}`,
          data: yAxis.map(y => {
            if (!y || !y.maxTemperature) return null
            if (isCelsius) {
              return normalizeTemperature(y.maxTemperature)
            } else {
              return normalizeTemperature(toFahrenheit(y.maxTemperature), false)
            }
          })
        },
        {
          type: 'line',
          color: 'red',
          name: t('Threshold'),
          marker: {
            enabled: false
          },
          data: Array(chartData.dates.length).fill(isCelsius ? normalizeTemperature(threshold) : normalizeTemperature(toFahrenheit(threshold), false))
        }
      ]
    }}
                           />

  return (
    <div id='divToPrint'>
      <div style={{ display: 'flex' }}>

        {
          siteIdsToName && Object.keys(siteIdsToName).length > 1
            ? <FormControl
              style={{
                marginLeft: '2rem',
                minWidth: 150
              }}
            >
              <Select
                variant='outlined'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedSite}
                fullWidth
                onChange={handleSiteChange}
              >
                <MenuItem value='all'>{t('All')}</MenuItem>
                {
                  Object.keys(siteIdsToName).map(siteId => <MenuItem key={siteId} value={siteId}>{siteIdsToName[siteId]}</MenuItem>)
                }

              </Select>
            </FormControl>
            : null
        }

      </div>

      <div
        style={{
          marginTop: 50,
          position: 'relative',
          height: '100%'
        }}
      >
        {temperatureChart}
      </div>

    </div>
  )
}

export default withAuthentication(withTranslation()(MaxTemperatureChart))
