import resolveRequest from '../../utils/resolveRequest.js'
import { SERVER_PATH, apiEndpoints } from '../endpoints.js'
import requestKeywords from '../../shared/data/daily-machine-usage.js'
import axios from 'axios'

export default async (fromDate, toDate, machineIdsList, fromHour, toHour, filterByHours) => {
  const URL = SERVER_PATH + apiEndpoints.machineDailyUsage
  const requestBody = {
    [requestKeywords.FROM_DATE]: fromDate,
    [requestKeywords.TO_DATE]: toDate,
    [requestKeywords.MACHINE_IDS_LIST]: machineIdsList,
    [requestKeywords.TO_HOUR]: filterByHours ? toHour : null,
    [requestKeywords.FROM_HOUR]: filterByHours ? fromHour : null
  }

  return resolveRequest(axios.post(URL, requestBody, {
    withCredentials: true
  }))
}
