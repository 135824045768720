import { Button, TextField, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import washerBulkAddModalStyle from '../../assets-soapy/jss/soapy/views/washerBulkAddModalStyle.jsx'
import Card from '../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../components-soapy/LoadingButton.jsx'
import Modal from '../../components-soapy/Modal.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../containers/withErrorNotification.js'
import addWashersFromCSV from '../../endpoint-requests/user/addWashersFromCSV.js'
import useFormFields from '../../shared/hooks/useFormFields.js'
import validateFormFields from '../../utils/validateFormFields.js'
import {
  CSV_FILE, ORGANIZATION_ID, VALIDATION_STATE
} from './constants.js'

let spinnerTimeout = null

const AddWasherModal = ({
  classes,
  washersAdded,
  currentDashboardUser,
  showErrorNotification,
  organizations,
  onClose,
  isOpen,
  t
}) => {
  const { formFields, setFormFields } = useFormFields({
    [ORGANIZATION_ID]: { value: currentDashboardUser.entity.name === 'organization' ? currentDashboardUser.organizations[0].id : '', isRequired: true, name: 'Organization' },
    [CSV_FILE]: { value: null, isRequired: true, name: 'CSV file' }
  })
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const organizationsObj = {}
  organizations.forEach(o => {
    organizationsObj[o.id] = o
  })

  const handleOrganizationChange = (event, value) => {
    if (value) {
      setFormFields({
        ...formFields,
        [ORGANIZATION_ID]: { ...formFields[ORGANIZATION_ID], value: value.id },
        [ORGANIZATION_ID + VALIDATION_STATE]: ''
      })
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setFormFields({ ...formFields, [CSV_FILE]: { ...formFields[CSV_FILE], value: file }, [CSV_FILE + VALIDATION_STATE]: '' })
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const submitForm = async () => {
    const formData = new FormData()
    formData.append('organizationId', formFields[ORGANIZATION_ID].value)
    formData.append('file', formFields[CSV_FILE].value, formFields[CSV_FILE].value.name)

    activateSpinner()
    try {
      const washers = await addWashersFromCSV(formData)
      disableSpinner()
      washersAdded(washers)
    } catch (err) {
      disableSpinner()
      showErrorNotification(err.message)
    }
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    return () => {
      disableSpinner()
    }
  }, [])
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      hideActions
      showGoBackButton
      dialogPaper={classes.dialogPaper}
      onCancel={() => {
        disableSpinner()
        onClose()
      }}
      title={t('Bulk card upload')}
    >
      <GridContainer id='content-pane-layout' justify='center'>

        <GridItem md={9}>
          <Card style={{ boxShadow: 'none' }}>
            <CardBody>
              <GridContainer>
                {
                  currentDashboardUser.entity.name === 'neo' || currentDashboardUser.entity.name === 'distributor'
                    ? <>
                      <GridItem
                        md={4}
                        style={{
                          marginBottom: '2rem'
                        }}
                      >
                        <Typography style={{ fontSize: '1.2rem' }}>{t('Organization') + '*'}</Typography>
                      </GridItem>
                      <GridItem
                        md={8}
                      >
                        <Autocomplete
                          style={{
                            width: '20rem',
                            height: '3rem'
                          }}
                          disablePortal
                          disableClearable
                          onChange={handleOrganizationChange}
                          id='combo-box-demo'
                          size='medium'
                          value={organizationsObj[formFields[ORGANIZATION_ID].value]
                            ? organizationsObj[formFields[ORGANIZATION_ID].value]
                            : ''}
                          classes={{ input: classes.autocomplete }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) =>
                            <TextField
                              className={
                                formFields[ORGANIZATION_ID + VALIDATION_STATE] === 'error'
                                  ? classes.inputError
                                  : ''
                              }
                              {...params}
                              variant='outlined'
                            />}
                          options={organizations}
                        />
                      </GridItem>
                    </>
                    : null
                }

                <GridContainer
                  justify='center'
                  alignItems='center'
                  id='card-footer-section'
                >
                  <Button
                    variant='contained'
                    component='label'
                    id='bottlesUploadFile'
                    color='primary'
                    style={{
                      minWidth: '36vh',
                      margin: '1rem',
                      marginTop: '4rem'
                    }}
                  >
                    {formFields[CSV_FILE].value ? formFields[CSV_FILE].value.name : t('Upload CSV file')}
                    <input
                      id='myInput'
                      type='file'
                      accept='.csv'
                      style={{ display: 'none' }}
                      onChange={(event) => handleFileChange(event)}
                    />
                  </Button>
                </GridContainer>

              </GridContainer>
              <GridContainer
                justify='center'
                alignItems='center'
                id='card-footer-section'
              >
                <GridItem>
                  <LoadingButton
                    color='info'
                    type='submit'
                    id='washerAddSaveButton'
                    onClick={(e) => {
                      e.preventDefault()
                      handleSaveBtnClick()
                    }}
                    fullWidth
                    disabled={isAwaitingResponse}
                    isLoading={isAwaitingResponse}
                  >
                    {t('Save')}
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Modal>
  )
}

export default withErrorNotification(withAuthentication(withStyles(washerBulkAddModalStyle)(withTranslation()(AddWasherModal))))
