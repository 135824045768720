import {
  Tooltip
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import SettingsIcon from '@material-ui/icons/Settings'
import React from 'react'
import Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import Button from '../../components/CustomButtons/Button'
import CustomInput from '../../components/CustomInput/CustomInput'
import GridItem from '../../components/Grid/GridItem'
import getLanguageLocale from '../../shared/multi-language/getLanguageLocale'
import StatusTableExport from './StatusTableExport.jsx'

const formatDateBtnText = (fromDate, toDate) => `${fromDate.locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm')} - ${toDate.locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm')}`

const StatusTableHeader = ({
  t,
  lastSync,
  lastVersion,
  remoteIotStatus,
  openConfigurationLoginModal,
  fetchWashStatuses,
  isAtpModeActive,
  isLatestVersion,
  bottleWashCount,
  serialNumber,
  setSerialNumber,
  openDateModal,
  washesExist,
  machineFound,
  tableRef,
  date,
  toDate,
  elanMacAddress,
  wlanMacAddress
}) => {
  const dateBtnText = formatDateBtnText(date, toDate)

  const lastSyncTime = lastSync ? Datetime.moment(lastSync).locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm') : 'None'
  const lastVersionText = lastVersion || 'None'

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div>
          <Button
            color='info'
            id='statusDateButton'
            round
            onClick={openDateModal}
            style={{
              marginTop: '1.1rem'
            }}
          >
            {dateBtnText}
          </Button>
        </div>
        <GridItem>
          <CustomInput
            id='statusSerialNumberInput'
            labelText={t('Serial number')}
            inputProps={{
              value: serialNumber,
              type: 'name',
              onChange: event => {
                setSerialNumber(event.target.value)
              }
            }}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <div>
          <Button
            color='info'
            id='statusSearchButton'
            round
            disabled={!serialNumber}
            onClick={() => fetchWashStatuses(serialNumber)}
            style={{ marginTop: '1.1rem' }}
          >
            {t('Search')}
          </Button>

        </div>
        {
          machineFound
            ? <>
              <div>
                <Tooltip title={t('Configuration')}>
                  <Button id='statusConfigBtn' onClick={() => openConfigurationLoginModal()} color='info' style={{ marginTop: '1.1rem', marginLeft: '0.5rem', width: '1rem' }}>
                    <SettingsIcon fontSize='large' />
                  </Button>
                </Tooltip>
              </div>

              </>
            : null
        }

        <div style={{ marginTop: '0.8rem', marginLeft: '0.5rem' }}>
          {
          washesExist
            ? <StatusTableExport tableRef={tableRef} />
            : null
        }
        </div>

        {machineFound
          ? <div style={{
            marginLeft: 'auto'
          }}
            >
            <div
              style={{
                color: '#3c4858',
                display: 'inline-block',
                paddingTop: '12px'
              }}
              id='statusStatistic'
            >
              {`${t('Last sync')}: ${lastSyncTime} | ${t('Version')}: ${lastVersionText}`}
            </div>
            <div style={{
              color: '#3c4858',
              paddingTop: '12px',
              display: 'flex'
            }}
            >
              Latest version:
              {
              isLatestVersion
                ? <Check
                    style={{
                      fillOpacity: 0.7,
                      fontSize: '1rem',
                      color: '#4ADD4A'
                    }}
                  />
                : <Close
                    style={{
                      fillOpacity: 0.7,
                      fontSize: '18px',
                      color: '#DD4A65'
                    }}
                  />
            }

            </div>
            <div style={{
              color: '#3c4858',
              paddingTop: '12px'
            }}
            >
              {`${t('Soap usage count')}: ${bottleWashCount || 0}`}
            </div>
            <div style={{
              color: '#3c4858',
              paddingTop: '12px'
            }}
            >
              {`${t('Remote maintenance')}: ${remoteIotStatus === 1 ? t('Yes') : remoteIotStatus === 0 ? t('Delays') : t('No')}`}
            </div>
            <div style={{
              color: '#3c4858',
              paddingTop: '12px'
            }}
            >
              {`${t('HCA mode')}: ${isAtpModeActive ? t('Active') : t('Inactive')}`}
            </div>
            <div style={{
              color: '#3c4858',
              paddingTop: '12px'
            }}
            >
              {`${t('WLAN Address')}: ${wlanMacAddress || 'N/A'}`}
            </div>
            <div style={{
              color: '#3c4858',
              paddingTop: '12px'
            }}
            >
              {`${t('LAN Address')}: ${elanMacAddress || 'N/A'}`}
            </div>
            </div>
          : null}
      </div>

    </div>
  )
}

export default withTranslation()(StatusTableHeader)
