import { Typography } from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import React, { useMemo } from 'react'
import Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import {
  BOTTLE_ID,
  CAMERA_STATUS,
  CPU_TEMPERATURE, DATE,
  EXTERNAL_CAMERA_STATUS,
  IS_CARD_READER,
  IS_NCS_CONNECTED,
  LATHER_DISTANCES, REAGENT,
  REAGENT_DISTANCES,
  RFID, RINSING_DISTANCES, TEMPERATURE,
  TIME
} from '../../shared/data/status'
import getLanguageLocale from '../../shared/multi-language/getLanguageLocale.js'

const DEFAULT_PAGE_SIZE = 10

const defaultDistances = {
  r1: { topDistance: 0, leftDistance: 0 },
  r2: { topDistance: 0, leftDistance: 0 },
  r3: { topDistance: 0, leftDistance: 0 },
  r4: { topDistance: 0, leftDistance: 0 }
}

const checkIcon = <Check style={{ color: 'green', width: '15px', marginBottom: '-9px' }} />
const crossIcon = <Clear style={{ color: 'red', width: '15px', marginBottom: '-9px' }} />

const verifyDistance = (distanceObject, t) => {
  if (!distanceObject) {
    distanceObject = defaultDistances
  }
  return (
    <div style={{ display: 'inline-grid', borderCollapse: 'collapse' }}>
      <div>
        <tr style={{ textAlign: 'center', border: '1px solid #ece6e6' }}>
          <th style={borderStyle}><Typography variant='body1'>{t('Time')}, {t('S')}</Typography></th>
          <th style={borderStyle}><Typography variant='body1'>{t('Left')}, {t('SM')}</Typography></th>
          <th style={borderStyle}><Typography variant='body1'>{t('Top')}, {t('SM')}</Typography></th>
        </tr>
        {
          distanceObject.map((row, index) => {
            return (
              <tr key={index}>
                <td style={borderStyle}><Typography variant='body1'>{index + 1}</Typography></td>
                <td style={borderStyle}><Typography variant='body1'>{row[1]} {getLeftSensorIcon(row[1])}</Typography>
                </td>
                <td style={borderStyle}><Typography variant='body1'>{row[0]} {getTopSensorIcon(row[0])}</Typography>
                </td>
              </tr>
            )
          })
        }
      </div>
    </div>
  )
}

const borderStyle = { border: '1px solid #ece6e6', padding: '0.4rem' }

const getTopSensorIcon = (distance) => {
  if (distance <= 27 && distance >= 3) {
    return checkIcon
  }
  return crossIcon
}

const getLeftSensorIcon = (distance) => {
  if (distance <= 21 && distance >= 2) {
    return checkIcon
  }
  return crossIcon
}

const convertToTableData = (statuses, t) => {
  return statuses.map(status => {
    return {
      [TIME]: Datetime.moment(status[DATE]).locale(getLanguageLocale()).format('MMM DD HH:mm'),
      [BOTTLE_ID]: status[BOTTLE_ID],
      [IS_CARD_READER]: status[IS_CARD_READER],
      [CAMERA_STATUS]: status[CAMERA_STATUS],
      [EXTERNAL_CAMERA_STATUS]: status[EXTERNAL_CAMERA_STATUS],
      [TEMPERATURE]: status[TEMPERATURE] ? Number.parseFloat(status[TEMPERATURE]).toFixed(1) : null,
      [CPU_TEMPERATURE]: status[CPU_TEMPERATURE] ? Number.parseFloat(status[CPU_TEMPERATURE]).toFixed(2) : null,
      [REAGENT_DISTANCES]: status[REAGENT_DISTANCES],
      [LATHER_DISTANCES]: status[LATHER_DISTANCES],
      [RINSING_DISTANCES]: status[RINSING_DISTANCES],
      [IS_NCS_CONNECTED]: status[IS_NCS_CONNECTED]
    }
  })
}

const StatusTable = ({
  washStatuses,
  tableRef,
  t
}) => {
  const tableData = useMemo(() => convertToTableData(washStatuses, t), [washStatuses]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <ReactTable
      data={tableData}
      columns={[
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0
              }}
            >
              <Typography variant='body2'>{t('Time')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: TIME,
          sortable: false,
          filterable: false
        }, {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0

              }}
            >
              <Typography variant='body2'>{t('bottleID')}</Typography>
            </div>
          ),
          Cell: ({ original }) => {
            const bottleId = original[BOTTLE_ID]
            if (!bottleId) {
              return crossIcon
            }
            return (
              <div style={{ display: 'flex' }}>
                <p style={{ marginBottom: 0 }}>{bottleId}</p>{checkIcon}
              </div>
            )
          },
          style: { textAlign: 'left' },
          accessor: BOTTLE_ID,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0
              }}
            >
              <Typography variant='body2'>{t('Soap or sanitizer received﻿')}</Typography>
            </div>
          ),
          Cell: ({ original }) => {
            return verifyDistance(original[REAGENT_DISTANCES], t)
          },
          style: { margin: 0, textAlign: 'left' },
          minWidth: 180,
          accessor: REAGENT_DISTANCES,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0

              }}
            >
              <Typography variant='body2'>{t('Rub & Scrub')}</Typography>
            </div>
          ),
          Cell: ({ original }) => {
            return verifyDistance(original[LATHER_DISTANCES], t)
          },
          style: { margin: 0, textAlign: 'left' },
          minWidth: 180,
          accessor: LATHER_DISTANCES,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0
              }}
            >
              <Typography variant='body2'>{t('Rinsing')}</Typography>
            </div>
          ),
          style: { margin: 0, textAlign: 'left' },
          Cell: ({ original }) => {
            const isSoapReagent = original[REAGENT] !== 'sanitizer'
            return isSoapReagent ? verifyDistance(original[RINSING_DISTANCES], t) : null
          },
          accessor: RINSING_DISTANCES,
          minWidth: 180,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0

              }}
            >
              <Typography variant='body2'>{t('NCS')}</Typography>
            </div>
          ),
          Cell: ({ original }) => {
            return !original[IS_NCS_CONNECTED] || original[IS_NCS_CONNECTED] === 'None' ? crossIcon : checkIcon
          },
          style: { textAlign: 'left' },
          accessor: IS_NCS_CONNECTED,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0

              }}
            >
              <Typography variant='body2'>{t('Card reader')}</Typography>
            </div>
          ),
          Cell: ({ original }) => {
            const isCardReader = original[IS_CARD_READER]
            const rfid = original[RFID]
            if (isCardReader) {
              return (
                <div style={{ display: 'flex' }}>
                  <p>{rfid}</p>{rfid ? null : checkIcon}
                </div>
              )
            }
            return crossIcon
          },
          style: { textAlign: 'left' },
          accessor: IS_CARD_READER,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0

              }}
            >
              <Typography variant='body2'>{t('Internal camera connection')}</Typography>
            </div>
          ),
          Cell: ({ original }) => {
            return original[CAMERA_STATUS] ? checkIcon : crossIcon
          },
          style: { textAlign: 'left' },
          accessor: CAMERA_STATUS,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0

              }}
            >
              <Typography variant='body2'>{t('External camera connection')}</Typography>
            </div>
          ),
          Cell: ({ original }) => {
            return original[EXTERNAL_CAMERA_STATUS] ? checkIcon : crossIcon
          },
          style: { textAlign: 'left' },
          accessor: EXTERNAL_CAMERA_STATUS,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0

              }}
            >
              <Typography variant='body2'>{t('Body temperature')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: TEMPERATURE,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0

              }}
            >
              <Typography variant='body2'>{t('CPU temperature')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: CPU_TEMPERATURE,
          sortable: false,
          filterable: false
        }

      ]}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      nextText={t('Next')}
      pageText={t('Page')}
      ofText={t('Of')}
      onPageChange={() => {
        document.getElementById('mainPanel').scrollTop = 0
      }}
      rowsText={t('Rows')}
      previousText={t('Previous')}
      minRows={0}
      ref={tableRef}
      showPaginationBottom={
        tableData.length > DEFAULT_PAGE_SIZE
      }
      className='-striped -highlight'
    />
  )
}

export default withTranslation()(StatusTable)
