import {
  Grid,
  Tooltip
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import NotificationsIcon from '@material-ui/icons/Notifications'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import formStyle from '../../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../components-soapy/LoadingButton'
import NotificationSettingsForm from '../../components-soapy/NotificationSettings/NotificationSettingsForm.jsx'
import SweetAlert from '../../components-soapy/SweetAlert.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import CardIcon from '../../components/Card/CardIcon.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../containers/withErrorNotification.js'
import editNotificationSettings from '../../endpoint-requests/dashboard-user/editNotificationSettings.js'

const HIGH_TEMPERATURE_EMAIL = 'highTemperatureEmail'
const FAILED_WASH_EMAIL = 'failedWashEmail'
const MALFUNCTIONS_EMAIL = 'malfunctionsEmail'
const CHANGE_SOAP_EMAIL = 'changeSoapEmail'
const HIGH_TEMPERATURE_SMS = 'highTemperatureSms'
const FAILED_WASH_SMS = 'failedWashSms'
const MALFUNCTIONS_SMS = 'malfunctionsSms'
const CHANGE_SOAP_SMS = 'changeSoapSms'

let spinnerTimeout = null

const NotificationSettings = ({ classes, t, currentDashboardUser, notificationSettings, showErrorNotification }) => {
  const [isSuccessModalActive, setIsSuccessModalActive] = useState(false)
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [formFields, setFormFields] = useState({
    [HIGH_TEMPERATURE_EMAIL]: false,
    [FAILED_WASH_EMAIL]: false,
    [MALFUNCTIONS_EMAIL]: false,
    [CHANGE_SOAP_EMAIL]: false,
    [HIGH_TEMPERATURE_SMS]: false,
    [FAILED_WASH_SMS]: false,
    [MALFUNCTIONS_SMS]: false,
    [CHANGE_SOAP_SMS]: false
  })

  useEffect(() => {
    if (notificationSettings) {
      setFormFields({
        [HIGH_TEMPERATURE_EMAIL]: notificationSettings[HIGH_TEMPERATURE_EMAIL] || false,
        [FAILED_WASH_EMAIL]: notificationSettings[FAILED_WASH_EMAIL] || false,
        [MALFUNCTIONS_EMAIL]: notificationSettings[MALFUNCTIONS_EMAIL] || false,
        [CHANGE_SOAP_EMAIL]: notificationSettings[CHANGE_SOAP_EMAIL] || false,
        [HIGH_TEMPERATURE_SMS]: notificationSettings[HIGH_TEMPERATURE_SMS] || false,
        [FAILED_WASH_SMS]: notificationSettings[FAILED_WASH_SMS] || false,
        [MALFUNCTIONS_SMS]: notificationSettings[MALFUNCTIONS_SMS] || false,
        [CHANGE_SOAP_SMS]: notificationSettings[CHANGE_SOAP_SMS] || false
      })
    }
  }, [notificationSettings])

  const handleSaveBtnClick = async () => {
    activateSpinner()
    try {
      await editNotificationSettings(currentDashboardUser.id, formFields)
      setIsSuccessModalActive(true)
    } catch (err) {
      showErrorNotification(err.message)
    }
    disableSpinner()
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      () => setIsAwaitingResponse(true),
      300
    )
  }

  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const handleSwitchChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: event.target.checked })
  }

  return (
    <GridContainer id='content-pane-layout' justify='center'>
      <GridItem md={9}>
        <Card>
          <CardHeader>
            <Tooltip title={t('Notification settings')}>
              <CardIcon color='info'>
                <NotificationsIcon
                  className={classes.cardHeaderIcon}
                  style={{ fontSize: 30 }}
                />
              </CardIcon>
            </Tooltip>
          </CardHeader>
          <CardBody>
            <form>
              <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
              >
                <NotificationSettingsForm formFields={formFields} handleSwitchChange={handleSwitchChange} />

                <Grid item>
                  <LoadingButton
                    color='info'
                    onClick={handleSaveBtnClick}
                    fullWidth
                    id='notificationSettingsSaveButton'
                    disabled={isAwaitingResponse}
                    isLoading={isAwaitingResponse}
                  >
                    {t('Save')}
                  </LoadingButton>
                </Grid>
              </Grid>

            </form>
            <div id='alerts-section'>

              {isSuccessModalActive
                ? (
                  <SweetAlert
                    success
                    id='changePasswordSuccessAlert'
                    title={<h2 style={{ fontSize: '1rem' }}>{t('Notification settings modified successfully')}</h2>}
                    confirmBtnText={t('Ok')}
                    style={{
                      position: 'absolute'
                    }}
                    onConfirm={() => setIsSuccessModalActive(false)}
                    onCancel={() => setIsSuccessModalActive(false)}
                  />
                  )
                : null}
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withRouter(withTranslation()(NotificationSettings)))))
