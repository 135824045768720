import axios from 'axios'
import dailyWashesRequestKeywords from '../../shared/data/daily-washes.js'
import { SERVER_PATH } from '../endpoints.js'
import failedSession from '../failedSession'


const fetchDailyWashesMultiMachines = async (fromDate, toDate, machineIds, isStatus = false) => {
  const URL = SERVER_PATH + dailyWashesRequestKeywords.ENDPOINT_URL_MULTIMACHINES
  const requestBody = {
    [dailyWashesRequestKeywords.DATE]: new Date(fromDate),
    [dailyWashesRequestKeywords.TO_DATE]: new Date(toDate),
    [dailyWashesRequestKeywords.MACHINE_IDs]: machineIds
  }

  try {
    const { data } = await axios.post(URL, requestBody, {
      withCredentials: true
    })
    return { dailyWashes: data.dailyWashes, temperatureThreshold: data.temperatureThreshold };
  } catch (e) {
    failedSession(e)
    return { error: 'unknown' }
  }
}

export { fetchDailyWashesMultiMachines } 
