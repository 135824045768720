import {
  Tooltip, Typography
} from '@material-ui/core'
import Remove from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import NotificationsIcon from '@material-ui/icons/Notifications'
import React, { useEffect, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getCookie from '../../shared/cookie/get-cookie'
import { CREATED_BY_ORG, CREATED_BY_SITE, DISTRIBUTOR_NAME, EMAIL, ENTITY_NAME, FIRST_NAME, LAST_LANE, ORGANIZATIONS, PHONE_NUMBER, POSITION, ROLE, SITES } from '../../shared/data/dashboard-users'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'

const ID = 'id'
const DASHBOARD_USER = 'dashboardUser'
const ACTIONS = 'actions'

const DEFAULT_PAGE_SIZE = 10

const getDashboardUserOrganizationsCsv = (dashboardUser) => {
  if (dashboardUser.entity && dashboardUser.entity.name === 'distributor' && dashboardUser.role && dashboardUser.role.name === 'admin') {
    return dashboardUser.distributor && dashboardUser.distributor.organizations && dashboardUser.distributor.organizations.map(org => org.name).join(', ')
  } else {
    return dashboardUser.organizations && dashboardUser.organizations.map(org => org.name).join(', ')
  }
}
const getDashboardUserSitesCsv = (dashboardUser) => {
  if (dashboardUser.entity && dashboardUser.role && dashboardUser.role.name) {
    if (dashboardUser.entity.name === 'distributor' && dashboardUser.role.name === 'admin') {
      if (dashboardUser.distributor && dashboardUser.distributor.organizations && dashboardUser.distributor.organizations) {
        const siteNames = dashboardUser.distributor.organizations.reduce((acc, org) => {
          if (org.sites) {
            for (const site of org.sites) {
              acc.push(site.name)
            }
          }
          return acc
        }, [])
        return siteNames.join(', ')
      } else {
        return ''
      }
    }
    if (dashboardUser.entity.name === 'organization' && (dashboardUser.role.name === 'admin' || dashboardUser.role.name === 'user')) {
      if (dashboardUser.organizations) {
        return dashboardUser.organizations.map(org => org.sites && org.sites.map(site => site.name).join(', ')).join(', ')
      } else {
        return ''
      }
    }
  }
  return dashboardUser.sites && dashboardUser.sites.map(site => site.name).join(', ')
}

const checkCanEditDashboardUser = (dashboardUser, me) => {
  if (me.entity.name === 'neo') {
    return true
  }
  // can edit himself himself
  if (me.id === dashboardUser.id) {
    return true
  }
  // distributor admin can't edit other distributor admins
  if (me.entity.name === 'distributor' && dashboardUser.entity && dashboardUser.entity.name === 'distributor') {
    return dashboardUser.role && dashboardUser.role.name !== 'admin'
  }
  // organization admin can't delete main organization admins
  if (me.entity.name === 'organization' && me[CREATED_BY_ORG] && dashboardUser.entity && dashboardUser.entity.name === 'organization' && !dashboardUser[CREATED_BY_ORG]) {
    return false
  }

  // site admin can't delete main site admins
  if (me.entity.name === 'site' && me[CREATED_BY_SITE] && dashboardUser.entity && dashboardUser.entity.name === 'site' && dashboardUser.role.name === 'admin' && !dashboardUser[CREATED_BY_SITE]) {
    return false
  }
  return true
}
const checkCanDeleteDashboardUser = (dashboardUser, me) => {
  if (me.entity.name === 'neo') {
    return true
  }
  // can't delete himself
  if (me.id === dashboardUser.id) {
    return false
  }
  // distributor admin can't delete other distributor admins
  if (me.entity.name === 'distributor' && dashboardUser.entity && dashboardUser.entity.name === 'distributor') {
    return dashboardUser.role && dashboardUser.role.name !== 'admin'
  }
  if (me.entity.name === 'organization' && me[CREATED_BY_ORG] && dashboardUser.entity && dashboardUser.entity.name === 'organization' && !dashboardUser[CREATED_BY_ORG]) {
    return false
  }
  if (me.entity.name === 'site' && me[CREATED_BY_SITE] && dashboardUser.entity && dashboardUser.entity.name === 'site' && dashboardUser.role.name === 'admin' && !dashboardUser[CREATED_BY_SITE]) {
    return false
  }

  return true
}

const checkCanResetPassword = (dashboardUser, me) => {
  if (me.entity.name === 'neo') {
    return true
  }
  // can change password of himself
  if (me.id === dashboardUser.id) {
    return true
  }
  // distributor admin can't change password of other distributor admins
  if (me.entity.name === 'distributor' && dashboardUser.entity && dashboardUser.entity.name === 'distributor') {
    return dashboardUser.role && dashboardUser.role.name !== 'admin'
  }

  if (me.entity.name === 'organization' && me[CREATED_BY_ORG] && dashboardUser.entity && dashboardUser.entity.name === 'organization' && !dashboardUser[CREATED_BY_ORG]) {
    return false
  }

  if (me.entity.name === 'site' && me[CREATED_BY_SITE] && dashboardUser.entity && dashboardUser.entity.name === 'site' && dashboardUser.role.name === 'admin' && !dashboardUser[CREATED_BY_SITE]) {
    return false
  }

  return true
}
const checkCanChangeNotificationSettings = (dashboardUser, me) => {
  if (dashboardUser.entity && dashboardUser.entity.name === 'distributor') {
    return false
  }
  // if (dashboardUser.entity && dashboardUser.entity.name === 'organization' && dashboardUser.role && dashboardUser.role.name === 'user') {
  //   return false
  // }

  if (me.entity && me.entity.name === 'neo') {
    return true
  }

  if (me.entity && me.entity.name === 'distributor') {
    return false
  }

  if (me.id === dashboardUser.id) {
    return true
  }
  if (me.entity.name === 'organization' && me[CREATED_BY_ORG] && dashboardUser.entity && dashboardUser.entity.name === 'organization' && !dashboardUser[CREATED_BY_ORG]) {
    return false
  }

  if (me.entity.name === 'site' && me[CREATED_BY_SITE] && dashboardUser.entity && dashboardUser.entity.name === 'site' && dashboardUser.role.name === 'admin' && !dashboardUser[CREATED_BY_SITE]) {
    return false
  }

  return true
}

const convertToTableData = (
  dashboardUsers,
  showData,
  openDeleteModal,
  t,
  openNotificationSettingsModal,
  openResetPasswordModal,
  currentDashboardUser,
  hasPermission
) => {
  return Object.entries(dashboardUsers).map(([id, dashboardUser]) => {
    if (dashboardUser.role?.name === "user") {
      dashboardUser.role.name = "viewer"
    }
    return {
      [ID]: id,
      [DASHBOARD_USER]: { ...dashboardUser, role: dashboardUser && dashboardUser.role && dashboardUser.role.name ? t(dashboardUser.role.name.charAt(0).toUpperCase() + dashboardUser.role.name.slice(1)) : '' },
      [SITES]: getDashboardUserSitesCsv(dashboardUser),
      [ORGANIZATIONS]: getDashboardUserOrganizationsCsv(dashboardUser),
      [ENTITY_NAME]: dashboardUser.entity && t(dashboardUser.entity.name),
      [DISTRIBUTOR_NAME]: dashboardUser.distributor && dashboardUser.distributor.name,
      [ACTIONS]: (
        <div className='actions-left'>
          {
            hasPermission({ name: 'dashboard users', action: 'edit' }) && checkCanResetPassword(dashboardUser, currentDashboardUser)
              ? <Button
                justIcon
                round
                id='dashboardUsersResetPassword'
                simple
                onClick={() => {
                  openResetPasswordModal(dashboardUser)
                }}
                className='passwordreset'
              >
                <Tooltip title={t('Reset password')}>
                  <LockOpenIcon style={{ color: '#3c4858' }} />
                </Tooltip>
              </Button>
              : null
          }
          {
            hasPermission({ name: 'notification settings', action: 'read' }) && checkCanChangeNotificationSettings(dashboardUser, currentDashboardUser)
              ? <Button
                justIcon
                round
                id='dashboardUsersNotificationSettings'
                simple
                onClick={() => {
                  openNotificationSettingsModal(dashboardUser)
                }}
              >
                <Tooltip title={t('Notification settings')}>
                  <NotificationsIcon style={{ color: '#3c4858' }} />
                </Tooltip>
              </Button>
              : null
          }
          {
            hasPermission({ name: 'dashboard users', action: 'edit' }) && checkCanEditDashboardUser(dashboardUser, currentDashboardUser)
              ? <Button
                justIcon
                round
                simple
                id='dashboardUsersEdit'
                onClick={() => {
                  showData(dashboardUser)
                }}
                className='edit'
              >
                <Tooltip title={t('Edit')}>
                  <Edit style={{ color: '#3c4858' }} />
                </Tooltip>
              </Button>
              : null
          }
          {
            hasPermission({ name: 'dashboard users', action: 'delete' }) && checkCanDeleteDashboardUser(dashboardUser, currentDashboardUser)
              ? <Button
                justIcon
                round
                id='dashboardUsersRemove'
                simple
                onClick={() => {
                  openDeleteModal(dashboardUser)
                }}
                className='remove'
              >
                <Tooltip title={t('Remove')}>
                  <Remove style={{ color: '#3c4858' }} />
                </Tooltip>
              </Button>
              : null
          }
        </div>
      ),
      dashboardUser: { ...dashboardUser, role: dashboardUser && dashboardUser.role && dashboardUser.role.name ? t(dashboardUser.role.name.charAt(0).toUpperCase() + dashboardUser.role.name.slice(1)) : '' }
    }
  })
}

const DashboardUsers = ({
  dashboardUsers,
  openDashboardUserModal,
  openDeleteModal,
  openUpdateModal,
  currentDashboardUser,
  hasPermission,
  openResetPasswordModal,
  openNotificationSettingsModal,
  t
}) => {
  const showData = (dashboardUser) => {
    openUpdateModal(dashboardUser)
  }
  const language = getCookie('language')
  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [language])

  const dashboardUsersAsRows = useMemo(() => convertToTableData(
    dashboardUsers,
    showData,
    openDeleteModal,
    t,
    openNotificationSettingsModal,
    openResetPasswordModal,
    currentDashboardUser,
    hasPermission
  ), [dashboardUsers]) // eslint-disable-line react-hooks/exhaustive-deps

  return (

    <ReactTable
      data={dashboardUsersAsRows}
      filterable
      defaultFilterMethod={defaultFilterMethod}
      columns={[
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('First name')}</Typography>
            </div>
          ),
          id: 'firstName',
          style: { textAlign: 'left' },
          accessor: `${DASHBOARD_USER}.${FIRST_NAME}`,

          sortable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Last name')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: `${DASHBOARD_USER}.${LAST_LANE}`,
          sortable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Email')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: `${DASHBOARD_USER}.${EMAIL}`,
          sortable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Organizations')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          show: currentDashboardUser.entity.name === 'distributor' || currentDashboardUser.entity.name === 'neo',
          accessor: `${ORGANIZATIONS}`,
          sortable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Sites')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          show: currentDashboardUser.entity.name !== 'site',
          accessor: `${SITES}`,
          sortable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Distributor')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          show: currentDashboardUser.entity.name === 'neo',
          accessor: `${DISTRIBUTOR_NAME}`,
          sortable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Entity')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          show: currentDashboardUser.entity.name === 'neo' || currentDashboardUser.entity.name === 'distributor' || currentDashboardUser.entity.name === 'organization',
          accessor: `${ENTITY_NAME}`,
          sortable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Position')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: `${DASHBOARD_USER}.${POSITION}`,
          sortable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Phone number')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: `${DASHBOARD_USER}.${PHONE_NUMBER}`,
          sortable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Role')}</Typography>
            </div>
          ),
          accessor: `${DASHBOARD_USER}.${ROLE}`,
          sortable: true
        }, {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                marginLeft: 0

              }}
            >
              <Typography variant='body2'>{t('Actions')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: `${ACTIONS}`,
          sortable: false,
          filterable: false
        }
      ]}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      minRows={0}
      nextText={t('Next')}
      pageText={t('Page')}
      ofText={t('Of')}
      onPageChange={() => {
        document.getElementById('mainPanel').scrollTop = 0
      }}
      rowsText={t('Rows')}
      previousText={t('Previous')}
      showPaginationBottom={
        dashboardUsersAsRows.length > DEFAULT_PAGE_SIZE
      }
      className='-striped -highlight'

    />
  )
}

export default withAuthentication(withTranslation()(DashboardUsers))
