import { createSlice } from "@reduxjs/toolkit";

const shiftImagesSlice = createSlice({
    name: "shiftImages",
    initialState: {
        clientLogo: "",
        importantImageUpload: "",
        isFetching: false,
        error: false,
    },
    reducers: {


        clientLogoSuccess: (state, action) => {
            state.isFetching = false;
            state.clientLogo = action.payload;


        },


        importantImageUploadSuccess: (state, action) => {
            state.isFetching = false;
            state.importantImageUpload = action.payload;


        },
        shiftImagesLogoutSuccess: (state, action) => {
            state.isFetching = false;
            state.importantImageUpload = "";
            state.clientLogo = ""

        },




    },
});

export const {
    clientLogoSuccess,
    importantImageUploadSuccess,
    shiftImagesLogoutSuccess
} = shiftImagesSlice.actions;


const shiftImagesReducer = shiftImagesSlice.reducer; //it should be export default
export default shiftImagesReducer;