import validationFormsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle'

const formStyle = theme => ({
  ...validationFormsStyle,
  cardHeaderIcon: {
    color: '#ffffff'
  },
  autocomplete: {
    fontSize: 16,
    height: 3,
  },
})

export default formStyle
