import { apiEndpoints, SERVER_PATH } from '../endpoints'
import axios from 'axios'
import dashboardUserRequestKeywords from '../../shared/dashboard-user/request-keywords.js'
import failedSession from '../failedSession'

export const changePassword = async (currentPassword, newPassword) => {
  const URL = SERVER_PATH + apiEndpoints.changePassword

  const requestBody = {
    [dashboardUserRequestKeywords.CURRENT_PASSWORD]: currentPassword,
    [dashboardUserRequestKeywords.NEW_PASSWORD]: newPassword
  }
  try {
    await axios.put(URL, requestBody, { withCredentials: true })
    return { didSucceed: true }
  } catch (e) {
    failedSession(e)
    if (e.response && e.response.status === 401) {
      return {
        didSucceed: false,
        error: { type: 'INVALID_CREDENTIALS' }
      }
    } else {
      // Internal error 500 or received no response or something else
      return { didSucceed: false, error: { type: 'SERVER_ERROR' } }
    }
  }
}
