import GridItem from '../../../components/Grid/GridItem.jsx'
import {
  Grid, Tab, Tabs
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import campaignItemsModalStyle from '../../../assets-soapy/jss/soapy/views/campaignItemsModalStyle.jsx'
import ImageUploadCard from '../../../components-soapy/ImageUpload/ImageUploadCard.jsx'
import Modal from '../../../components-soapy/Modal'
import addReactTableFilterPlaceholder from '../../../shared/react-table/addReactTableFilterPlaceholder.js'

const CampaignItemsModal = ({
  isOpen,
  title,
  onCancel,
  classes,
  campaignItemRinsingUrls,
  campaignItemWelcomeUrls,
  t
}) => {
  useEffect(() => {
    addReactTableFilterPlaceholder()
  })
  const [tabIndex, setTabIndex] = React.useState(0)

  return (
    <Modal
      isOpen={isOpen}
      title={t(title)}
      dialogPaper={classes.dialogPaper}
      hideActions
      onCancel={onCancel}
    >

      <Grid
        container
        alignItems='center'
      >
        <Grid
          md={12}
        >
          <Tabs
            value={tabIndex}
            onChange={(event, newValue) => {
              setTabIndex(newValue)
            }}
            style={{
              marginBottom: '2rem'
            }}
            variant='standard'
            indicatorColor='primary'
            textColor='primary'
          >
            <Tab label={t('Rinsing Screen')} />
            <Tab label={t('Welcome Screen')} />
          </Tabs>
        </Grid>

        {
        (tabIndex === 0 ? campaignItemRinsingUrls : campaignItemWelcomeUrls).map((item, idx) => {
          const name = `${campaignItemRinsingUrls ? t('Rinsing Screen') : t('Welcome Screen')} ${idx + 1}`
          return (
            <GridItem
              md={4}
              style={{
                marginBottom: '1rem'
              }}
              key={name}
            >
              <ImageUploadCard
                title={name}
                previewImageHeight='56.25%'
                src={item}
                showActions={false}
                accept='image/png, image/jpg'
              />
            </GridItem>
          )
        })
        }

      </Grid>

    </Modal>
  )
}

export default withStyles(campaignItemsModalStyle)(withTranslation()(CampaignItemsModal))
