import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'

import customInputStyle from '../../assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx'

function CustomInput ({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    step,
    inputProps,
    error,
    white,
    greyBackground,
    inputRootCustomClasses,
    success,
    helpText
  } = props

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  })
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  })
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  })
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [classes.greyInputBackground]: greyBackground
  })
  var formControlClasses
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    )
  } else {
    formControlClasses = classes.formControl
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  })
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + ' ' + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        inputProps={{
          step: step || 1
        }}
        id={id}
        {...inputProps}
      />
      {helpText !== undefined ? (
        <FormHelperText id={id + '-text'} className={helpTextClasses}>
          {helpText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  step: PropTypes.number,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  greyBackground: PropTypes.bool,
  helpText: PropTypes.node
}

export default withStyles(customInputStyle)(CustomInput)
