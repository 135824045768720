import React, { useEffect, useMemo } from 'react'
import withAuthentication from '../../containers/Authentication/withAuthentication';
import { withTranslation } from 'react-i18next';
import Remove from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import { Tooltip, Typography } from '@material-ui/core'
import Button from '../../components/CustomButtons/Button'
import tableStyle from '../../shared/styles/tableSortIcon.css'
import ReactTable from 'react-table'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import {
  NAME,
  DESCRIPTION,
  EDIT,
  REMOVE,
  ORGANIZATION
} from './constants.js'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod'

//-----------------------Variable for No. of Page--------------------------//
const DEFAULT_PAGE_SIZE = 10

//------------------------Render whole Table-------------------------//
const Groups = (
  {
    currentDashboardUser,
    groups,
    t,
    setAddGroupModal,
    setIsDeleteWasherModalOpen,
    setSelectedGroup
  }) => {

  //-------------------Define Variables for showing Organization, Edit, and Delete Column Conditionally-----------------------//
  const { role, entity } = currentDashboardUser
  const roleName = role.name;
  const entityName = entity.name;
  const isShowOrganizationColumn = roleName === 'admin' && entityName === 'neo'
  const isShowEditDeleteColumn = roleName === 'user' && (entityName === 'organization' || entityName === 'distributor')

  // ---------------------For Set Placeholder In Search Input----------------------------//
  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [])

  // -----------------------Function for Creating TableData--------------------------//
  function convertToTableData(
    groups,
    t,
  ) {
    return groups.map(group => {
      return {
        [NAME]: group.name,
        [ORGANIZATION]: group.organization ? (group.organization.name ? group.organization.name : '') : '',
        [DESCRIPTION]: group.description,
        [EDIT]: (
          <div className='actions-left'>
            <Button
              justIcon
              round
              simple
              onClick={() => {
                setAddGroupModal((prevState) => !prevState)
                setSelectedGroup(group)
              }}
              id='usersEditButton'
              className='edit'
            >
              <Tooltip title={t('Edit')}>
                <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
              </Tooltip>
            </Button>
          </div>
        ),
        [REMOVE]: (
          <div className='actions-left'>
            <Button
              justIcon
              round
              simple
              onClick={() => {
                setIsDeleteWasherModalOpen((prevState) => !prevState) //Write this function whenever you create the modal
                setSelectedGroup(group)
              }}
              id='usersDeleteButton'
            >
              <Tooltip title={t('Delete')}>
                <Remove style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
              </Tooltip>
            </Button>
          </div >
        ),
      }
    })
  }

  const tableData = useMemo(() => convertToTableData(
    groups,
    t,
  ), [groups]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReactTable
      data={tableData}
      filterable
      defaultFilterMethod={defaultFilterMethod}
      style={tableStyle}
      NoDataComponent={() => null}
      columns={[
        {
          Header: () => null,
          Cell: (row) => {
            return <div>{(row.viewIndex + 1) + row.page * row.pageSize}</div>
          },
          sortable: false,
          filterable: false,
          width: 50
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Name')}</Typography>
            </div>
          ),
          accessor: NAME,
          sortable: true,
          filterable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Organization')}</Typography>
            </div>
          ),
          accessor: ORGANIZATION,
          sortable: true,
          filterable: true,
          show: isShowOrganizationColumn
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Description')}</Typography>
            </div>
          ),
          accessor: DESCRIPTION,
          sortable: true,
          filterable: true
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Edit')}</Typography>
            </div>
          ),
          accessor: EDIT,
          style: { textAlign: 'left' },
          sortable: false,
          filterable: false,
          show: !isShowEditDeleteColumn
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Delete')}</Typography>
            </div>
          ),
          accessor: REMOVE,
          style: { textAlign: 'left' },
          sortable: false,
          filterable: false,
          show: !isShowEditDeleteColumn
        }
      ]}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      nextText={t('Next')}
      pageText={t('Page')}
      ofText={t('Of')}
      onPageChange={() => {
        document.getElementById('mainPanel').scrollTop = 0
      }}
      rowsText={t('Rows')}
      previousText={t('Previous')}
      minRows={0}
      showPaginationBottom={
        tableData.length > DEFAULT_PAGE_SIZE
      }
      className='-striped -highlight'
    />
  )
}

export default withAuthentication(withTranslation()(Groups))
