import { getDashboardUserFullName } from '../../utils/textFomatters.js';
import profileRoutes from '../../routes/profileRoutes.js';
import washerRoutes from '../../routes/washerRoutes.js';
import filterRoutesByPaths from '../../shared/dashboard/filterRoutesByPaths.js';
import getAccessiblePaths from '../../shared/dashboard/getAccessiblePaths.js';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import { isMobile } from 'react-device-detect';
import cx from 'classnames';
import PerfectScrollbar from 'perfect-scrollbar';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import sidebarStyle from '../../assets-soapy/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';
import soapDispenserIcon from '../../assets/img/soap-for-hands.svg';
import HeaderLinks from '../../components/Header/HeaderLinks.jsx';
import withAuthentication from '../../containers/Authentication/withAuthentication.js';
import withLogoutModal from '../../containers/LogoutModal/withLogoutModal.jsx';
import dashRoutes from '../../routes/dashRoutes';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import Menu from '@material-ui/icons/Menu';
import { Tooltip } from '@material-ui/core'

// We've created this component, so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.

// -------------------------Sidebar Wrapper------------------------//
const SIDEBAR_WRAPPER = 'sidebarWrapper';
class SidebarWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDashboardMenuOpen: false,
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      this.ps = new PerfectScrollbar(this.refs[SIDEBAR_WRAPPER], {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      this.ps.destroy();
    }
  }

  render() {
    const {
      className,
      currentDashboardUserJsx,
      headerLinks,
      links,
      routes,
    } = this.props;
    return (
      <div className={className} ref={SIDEBAR_WRAPPER}>
        {currentDashboardUserJsx}
        {headerLinks}
        {links}
        {routes}
      </div>
    );
  }
}

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  currentDashboardUserJsx: PropTypes.element,
  headerLinks: PropTypes.element,
  links: PropTypes.element,
};

// --------------------- Function For Getting Classes---------------- //
const getItemText = (classes, miniActive) =>
  classes.itemText +
  ' ' +
  cx({
    [classes.itemTextMini]: miniActive,
  });

const getCollapseItemText = (classes, miniActive) =>
  classes.collapseItemText +
  ' ' +
  cx({
    [classes.collapseItemTextMini]: miniActive,
  });

const getUseWrapperClass = (classes, bgColor) =>
  classes.customer +
  ' ' +
  cx({
    [classes.whiteAfter]: bgColor === 'white',
  });

const getWasherWrapperClass = (classes, bgColor) =>
  classes.customerWasher +
  ' ' +
  cx({
    [classes.whiteAfter]: bgColor === 'white',
  });

const getCaret = (classes, rtlActive) =>
  classes.caret +
  ' ' +
  cx({
    [classes.caretRTL]: rtlActive,
  });

const getCollapseItemMini = (classes, rtlActive) =>
  classes.collapseItemMini +
  ' ' +
  cx({
    [classes.collapseItemMiniRTL]: rtlActive,
  });

const getLogoNormal = (classes, miniActive, rtlActive) =>
  classes.logoNormal +
  ' ' +
  cx({
    [classes.logoNormalSidebarMini]: miniActive,
    [classes.logoNormalSidebarMiniRTL]: rtlActive && miniActive,
    [classes.logoNormalRTL]: rtlActive,
  });

const getLogoClasses = (classes, bgColor) =>
  classes.logo +
  ' ' +
  cx({
    [classes.whiteAfter]: bgColor === 'white',
  });

const getDrawerPaper = (classes, miniActive, rtlActive) =>
  classes.drawerPaper +
  ' ' +
  cx({
    [classes.drawerPaperMini]: miniActive,
    [classes.drawerPaperRTL]: rtlActive,
  });

const getSidebarWrapper = (classes, miniActive) =>
  classes.sidebarWrapper +
  ' ' +
  cx({
    [classes.drawerPaperMini]: miniActive,
    [classes.sidebarWrapperWithPerfectScrollbar]:
      navigator.platform.indexOf('Win') > -1,
  });

// verifies if routeName is the one active (in browser input)
const activeRoute = (pathname, routeName) => {
  return pathname.indexOf(routeName) > -1;
};

// ------------------User and Dashboard Submenu------------------ //
const DashboardUserJsx = ({
  customerWrapperClass,
  classes,
  color,
  currentDashboardUser,
  caret,
  itemText,
  pathname,
  t,
  collapseItemMini,
  collapseItemText,
  openLogoutModal,
}) => {
  const [isProfileMenuOpen, openProfileMenu] = React.useState(false);
  const [isDashboardMenuOpen, openDashboardMenu] = React.useState(false);

  //--------first add routes in layouts->Dashboard.jsx to access here-------//
  const paths = getAccessiblePaths(
    profileRoutes,
    currentDashboardUser,
    isMobile
  );
  const filteredRoutes = filterRoutesByPaths(profileRoutes, paths);

  const pathsDashboard = getAccessiblePaths(
    dashRoutes,
    currentDashboardUser,
    isMobile
  );
  const filteredRoutesDashboard = filterRoutesByPaths(
    dashRoutes,
    pathsDashboard
  );

  return (
    <>
      <div id='sidebarCustomerWrapper' className={customerWrapperClass}>
        <div
          id='sidebarCustomerIconWrapper'
          className={classes.customerIconWrapper}
        >
          <PersonIcon id='sidebarPersonIcon' className={classes.customerIcon} />
        </div>
        <List id='sidebarCustomerList' className={classes.list}>
          <ListItem
            id='sidebarCustomerItem'
            className={classes.item + ' ' + classes.customerItem}
          >
            <NavLink
              id='sidebarCustomerNavLink'
              to={'#'}
              className={
                classes.itemLink + ' ' + classes.customerCollapseButton
              }
              onClick={() => openProfileMenu(!isProfileMenuOpen)}
            >
              <ListItemText
                id='sidebarCustomerItemText'
                primary={getDashboardUserFullName(currentDashboardUser)}
                secondary={
                  <b
                    className={
                      caret +
                      ' ' +
                      classes.customerCaret +
                      ' ' +
                      (isProfileMenuOpen ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={itemText + ' ' + classes.customerItemText}
              />
            </NavLink>
            <Collapse
              id='sidebarProfileMenu'
              style={{ width: '100%', marginLeft: '1rem', overflow: 'hidden' }}
              in={isProfileMenuOpen}
              unmountOnExit
            >
              <List
                id='sidebarProfileMenuList'
                className={classes.list + ' ' + classes.collapseList}
              >
                {/* Design Started With Mapping */}
                {filteredRoutes.map((route) => {
                  if (isMobile && !route.hasMobileView) {
                    return null;
                  }
                  return (
                    <ListItem
                      id='sidebarProfileListItem'
                      key={route.path}
                      className={classes.collapseItem}
                    >
                      <NavLink
                        id={route.path}
                        to={route.path}
                        className={
                          classes.itemLink +
                          ' ' +
                          classes.customerCollapseLinks +
                          ' ' +
                          cx({
                            [' ' + classes[color]]: activeRoute(
                              pathname,
                              route.path
                            ),
                          })
                        }
                      >
                        <span
                          id='sidebarProfileNavLinkSpan'
                          className={collapseItemMini}
                        >
                          <Icon component={route.icon} />
                        </span>
                        <ListItemText
                          id='sidebarProfileListItemText'
                          primary={t(route.name)}
                          disableTypography={true}
                          className={collapseItemText}
                        />
                      </NavLink>
                    </ListItem>
                  );
                })}

                <ListItem
                  id='sidebarProfileEditProfileLogoutListItem'
                  className={classes.collapseItem}
                >
                  <a
                    style={{ cursor: 'pointer' }}
                    href='# '
                    div='sidebarProfileEditProfileLogoutNavLink'
                    className={
                      classes.itemLink + ' ' + classes.customerCollapseLinks
                    }
                    onClick={() => openLogoutModal()}
                  >
                    <span
                      id='sidebarProfileEditProfileLogoutSpan'
                      className={collapseItemMini}
                    >
                      <ExitToAppIcon />
                    </span>
                    <ListItemText
                      id='sidebarProfileEditProfileLogoutListItemText'
                      primary={t('Log out')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </a>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
      <div id='sidebarCustomerWrapper' className={customerWrapperClass}>
        <div
          id='sidebarCustomerIconWrapper'
          className={classes.customerIconWrapper}
        >
          <DashboardIcon />
        </div>
        <List id='sidebarCustomerList' className={classes.list}>
          <ListItem
            id='sidebarCustomerItem'
            className={classes.item + ' ' + classes.customerItem}
          >
            <NavLink
              id='sidebarCustomerNavLink'
              to={'#'}
              className={
                classes.itemLink + ' ' + classes.customerCollapseButton
              }
              onClick={() => openDashboardMenu(!isDashboardMenuOpen)}
            >
              <ListItemText
                id='sidebarCustomerItemText'
                primary={t('Dashboard')}
                secondary={
                  <b
                    className={
                      caret +
                      ' ' +
                      classes.customerCaret +
                      ' ' +
                      (isDashboardMenuOpen ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={itemText + ' ' + classes.customerItemText}
              />
            </NavLink>
            <Collapse
              id='sidebarProfileMenu'
              style={{ width: '100%', marginLeft: '1rem', overflow: 'hidden' }}
              in={isDashboardMenuOpen}
              unmountOnExit
            >
              <List
                id='sidebarProfileMenuList'
                className={classes.list + ' ' + classes.collapseList}
              >
                {/* Design Started With Mapping */}
                {filteredRoutesDashboard.map((route) => {
                  if (isMobile && !route.hasMobileView) {
                    return null;
                  }
                  return (
                    <ListItem
                      id='sidebarProfileListItem'
                      key={route.path}
                      className={classes.collapseItem}
                    >
                      <NavLink
                        id={route.path}
                        to={route.path}
                        className={
                          classes.itemLink +
                          ' ' +
                          classes.customerCollapseLinks +
                          ' ' +
                          cx({
                            [' ' + classes[color]]: activeRoute(
                              pathname,
                              route.path
                            ),
                          })
                        }
                      >
                        <span
                          id='sidebarProfileNavLinkSpan'
                          className={collapseItemMini}
                        >
                          <Icon component={route.icon} />
                        </span>
                        <ListItemText
                          id='sidebarProfileListItemText'
                          primary={t(route.name)}
                          disableTypography={true}
                          className={collapseItemText}
                        />
                      </NavLink>
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    </>
  );
};

// ------------------Brands-------------//
const BrandsJsx = ({
  logoClasses,
  logo,
  classes,
  logoText,
  role,
  logoNormal,
  functionOpenClose,
  open,
}) => (
  <div className={logoClasses}>
    {!open ? (
      <Menu
        onClick={() => functionOpenClose()}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: '20px',
          left: '20px',
        }}
      />
    ) : null}
    <NavLink
      id='brandsNavlink'
      to={role === 'technician' ? '/status' : '/dashboard'}
    >
      <img src={logo} alt='logo' className={classes.img} />
    </NavLink>
    <NavLink
      id='brandsNavlink'
      to={role === 'technician' ? '/status' : '/dashboard'}
      className={logoNormal}
    >
      {logoText}
    </NavLink>
  </div>
);

function getShortRouteName (name, limit= 21) {
  if (name.length > limit){
    return name.substring(0, limit) + "..."
  }
  return name
}

// --------------------Whole Sidebar-----------------//
class Sidebar extends React.Component {
  mainPanel = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      washerMenuOpen: false,
      ...this.getCollapseStates(props.routes),
    };
  }

  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  activeRoute = (routeName) => {
    return window.location.pathname === routeName ? 'active' : '';
  };

  // ------------ Code for sidebar from Trends to Settings ------------- //
  createLinks = ({
    routes,
    washerWrapperClass,
    caret,
    itemText,
    collapseItemMini,
    collapseItemText,
    pathname,
    t,
    filteredRoutesWashers
  }) => {
    const { classes, color, rtlActive } = this.props;

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });
        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          ' ' +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        const caret =
          classes.caret +
          ' ' +
          cx({
            [classes.caretRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          ' ' +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            id='mainListItem'
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              id='mainNavLink'
              to={'#'}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span id='mainNavSpan' className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                id='mainListItemText'
                primary={rtlActive ? prop.rtlName : this.props.t(prop.name)}
                secondary={
                  <b
                    className={
                      caret +
                      ' ' +
                      (this.state[prop.state] ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse
              id='mainListItemCollapse'
              in={this.state[prop.state]}
              unmountOnExit
            >
              <List
                id='mainListItemList'
                className={classes.list + ' ' + classes.collapseList}
              >
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      if (
        prop.name !== 'Dashboard' &&
        prop.name !== 'Shift Monitor' &&
        prop.subMenu === false
      ) {
        const innerNavLinkClasses =
          classes.collapseItemLink +
          ' ' +
          cx({
            [' ' + classes[color]]: this.activeRoute(prop.layout + prop.path),
          });
        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes[color]]: this.activeRoute(prop.layout + prop.path),
          });
        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          ' ' +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });

        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              style={
                prop.name === 'Configuration' || prop.name === 'Sequences'
                  ? { marginLeft: '20%' }
                  : null
              }
              id={prop.path}
              to={prop.layout + prop.path}
              className={cx(
                { [navLinkClasses]: prop.icon !== undefined },
                { [innerNavLinkClasses]: prop.icon === undefined }
              )}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <div style={{ padding: '2px 0 2px 0' }}>
                  <img
                    src={soapDispenserIcon}
                    className={itemIcon}
                    style={{ top: '-1px' }}
                    alt='soapDispenser'
                  />
                </div>
              )}

              {/* dashboard main class side drawer */}
              <Tooltip disableHoverListener={t(prop.name).length <= 30} title={t(prop.name)}>
                <ListItemText
                  primary={rtlActive ? prop.rtlName : this.props.t(getShortRouteName(t(prop.name),30))}
                  disableTypography={true}
                  in={this.state.isDashboardMenuOpen}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </Tooltip>
            </NavLink>
          </ListItem>
        );
      }
      if (
        prop.name !== 'Dashboard' &&
        prop.name !== 'Shift Monitor' &&
        prop.subMenu === true
      ) {
        return (
          <div id='sidebarCustomerWrapper' className={washerWrapperClass}>
            <div
              id='sidebarCustomerIconWrapper'
              className={classes.customerIconWrapper}
            >
              <PeopleOutline />
            </div>
            <List id='sidebarCustomerList' className={classes.list}>
              <ListItem
                id='sidebarCustomerItem'
                className={classes.item + ' ' + classes.customerItem}
              >
                <NavLink
                  id='sidebarCustomerNavLink'
                  to={'#'}
                  className={
                    classes.itemLink + ' ' + classes.customerCollapseButton
                  }
                  onClick={() =>
                    this.setState({
                      washerMenuOpen: !this.state.washerMenuOpen,
                    })
                  }
                >
                  <Tooltip disableHoverListener={t(prop.title).length <= 21} title={t(prop.title)}>
                    <ListItemText
                      id='sidebarCustomerItemText'
                      primary={getShortRouteName(t(prop.title))}
                      secondary={
                        <b
                          className={
                            caret +
                            ' ' +
                            classes.customerCaret +
                            ' ' +
                            (this.state.washerMenuOpen ? classes.caretActive : '')
                          }
                        />
                      }
                      disableTypography={true}
                      className={itemText + ' ' + classes.customerItemText}
                    />
                  </Tooltip>
                </NavLink>
                <Collapse
                  id='sidebarProfileMenu'
                  style={{
                    width: '100%',
                    marginLeft: '1rem',
                    overflow: 'hidden',
                  }}
                  in={this.state.washerMenuOpen}
                  unmountOnExit
                >
                  <List
                    id='sidebarProfileMenuList'
                    className={classes.list + ' ' + classes.collapseList}
                  >
                    {/* Design Started With Mapping */}
                    {filteredRoutesWashers.map((route) => {
                      if (isMobile && !route.hasMobileView) {
                        return null;
                      }
                      return (
                        <ListItem
                          id='sidebarProfileListItem'
                          key={route.path}
                          className={classes.collapseItem}
                        >
                          <NavLink
                            id={route.path}
                            to={route.path}
                            className={
                              classes.itemLink +
                              ' ' +
                              classes.customerCollapseLinks +
                              ' ' +
                              cx({
                                [' ' + classes[color]]: activeRoute(
                                  pathname,
                                  route.path
                                ),
                              })
                            }
                          >
                            <span
                              id='sidebarProfileNavLinkSpan'
                              className={collapseItemMini}
                            >
                              <Icon component={route.icon} />
                            </span>
                            <Tooltip disableHoverListener={t(route.name).length <= 21} title={t(route.name)}>
                              <ListItemText
                                id='sidebarProfileListItemText'
                                primary={getShortRouteName(t(route.name))}
                                disableTypography={true}
                                className={collapseItemText}
                              />
                            </Tooltip>
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </ListItem>
            </List>
          </div>
        );
      }
    });
  };

  // --------------- Combining all Sidebar Components  --------------- //
  render() {
    const {
      classes,
      color,
      logo,
      image,
      logoText,
      bgColor,
      rtlActive,
      miniActive,
      currentDashboardUser,
      openLogoutModal,
      location,
      open,
      routes,
      handleDrawerToggle,
      functionOpenClose,
      t,
    } = this.props;
    const itemText = getItemText(classes, miniActive);
    const collapseItemText = getCollapseItemText(classes, miniActive);
    const customerWrapperClass = getUseWrapperClass(classes, bgColor);
    const washerWrapperClass = getWasherWrapperClass(classes, bgColor);
    const caret = getCaret(classes, rtlActive);
    const collapseItemMini = getCollapseItemMini(classes, rtlActive);
    const { pathname } = location;
    const currentDashboardUserJsxProps = {
      customerWrapperClass,
      classes,
      color,
      currentDashboardUser,
      t,
      caret,
      itemText,
      pathname,
      collapseItemMini,
      collapseItemText,
      openLogoutModal,
    };

    //--------first add routes in layouts->Dashboard.jsx to access here-------//
    const washerPaths = getAccessiblePaths(
      washerRoutes,
      currentDashboardUser,
      isMobile
    );
    const filteredRoutesWashers = filterRoutesByPaths(
      washerRoutes,
      washerPaths
    );

    const createLinksProps = {
      routes,
      washerWrapperClass,
      caret,
      itemText,
      collapseItemMini,
      collapseItemText,
      pathname,
      t,
      filteredRoutesWashers
    };

    const currentDashboardUserJsx = (
      <DashboardUserJsx {...currentDashboardUserJsxProps} />
    );
    const logoNormal = getLogoNormal(classes, miniActive, rtlActive);
    const logoClasses = getLogoClasses(classes, bgColor);
    const brandsJsxProps = {
      logoClasses,
      logo,
      classes,
      logoText,
      logoNormal,
      functionOpenClose,
      open,
    };
    let brandJsx = (
      <BrandsJsx {...brandsJsxProps} role={currentDashboardUser.role.name} />
    );
    const drawerPaper = getDrawerPaper(classes, miniActive, rtlActive);
    const sidebarWrapper = getSidebarWrapper(classes, miniActive);
    const routeList = (
      <List className={classes.list}>
        {this.createLinks({ ...createLinksProps })}
      </List>
    );
    return (
      <div ref='mainPanel'>
        <Hidden mdUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={'right'}
            id='sidebarDrawer'
            open={open}
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brandJsx}
            <SidebarWrapper
              className={sidebarWrapper}
              id='sidebarWrapper'
              currentDashboardUserJsx={currentDashboardUserJsx}
              routes={routeList}
              headerLinks={<HeaderLinks rtlActive={rtlActive} />}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>

        <Hidden smDown implementation='css'>
          <Drawer
            anchor={'left'}
            variant='permanent'
            id='sidebarDrawer'
            open
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
          >
            {brandJsx}
            <SidebarWrapper
              id='sidebarWrapper'
              className={sidebarWrapper}
              currentDashboardUserJsx={currentDashboardUserJsx}
              routes={routeList}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    'white',
    'red',
    'orange',
    'green',
    'blue',
    'purple',
    'rose',
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  currentDashboardUser: PropTypes.object,
  openLogoutModal: PropTypes.func,
};

export default withLogoutModal(
  withAuthentication(withStyles(sidebarStyle)(withTranslation()(Sidebar)))
);



