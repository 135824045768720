import fetchOrganizations from '../../endpoint-requests/organization/fetchOrganizations.js'
import ProceedModal from '../../components-soapy/ProceedModal.jsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useMachine } from '@xstate/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import DeleteModal from '../../components-soapy/ConfirmationModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import Snackbar from '../../components/Snackbar/Snackbar.jsx'
import addDistributorUnits from '../../endpoint-requests/reports/addDistributorUnits'
import deleteReport from '../../endpoint-requests/reports/deleteReport.js'
import fetchReports from '../../endpoint-requests/reports/getReports'
import { getDistributorUnits } from '../../endpoint-requests/reports/getDistributorUnits'
import fetchMachinesLocations from '../../endpoint-requests/machine/getMachines.js'
import ReportModal from './modals/ReportModal'
import ReportsTable from './ReportsTable'
import ReportsTableHeader from './ReportsTableHeader.jsx'
import DistributorsMachinesModal from '../../components-soapy/DistributorsMachinesModal.jsx'
import fetchReportTypes from '../../endpoint-requests/reports/fetchReportTypes'
import fetchSites from '../../endpoint-requests/site/fetchSites'
import getTimeZoneNames from '../../endpoint-requests/machine-configuration/getTimeZoneNames'

const unitsMachine = Machine({
  id: 'units',
  initial: 'loadDistributorData',
  context: {
    reports: {},
    machines: [],
    organizations: {},
    error: '',
    isLoadingMachines: false,
    report: {},
    displayedMachineIds: [],
    isDeleteModalOpen: false,
    isSuccessNotificationVisible: false,
    reportTypes: {},
    sites: {},
    timeZoneNames: [],
    successNotificationText: 'Saved'
  },
  states: {
    loadDistributorData: {
      invoke: {
        src: (context, event) => Promise.all([
          fetchReports(),
          fetchOrganizations({ order: 'asc', orderBy: 'name' }),
          fetchReportTypes(),
          fetchSites({ order: 'asc', orderBy: 'name' }),
          getTimeZoneNames()
        ]),
        onDone: {
          target: 'main',
          actions: assign({
            reports: (context, event) => event.data[0],
            organizations: (context, event) => {
              return event.data[1].reduce((acc, organization) => {
                acc[organization.id] = organization.name
                return acc
              }, {})
            },
            reportTypes: (context, event) => {
              return event.data[2].reduce((acc, reportType) => {
                acc[reportType.id] = reportType.name
                return acc
              }, {})
            },
            sites: (context, event) => {
              return event.data[3].reduce((acc, site) => {
                acc[site.id] = {
                  name: site.name,
                  organizationId: site.organizationId
                }
                return acc
              }, {})
            },
            timeZoneNames: (context, event) => event.data[4]
          })
        },
        onError: {
          target: 'failedFetchingData',
          actions: assign({
            error: (context, event) => event.data.message
          })
        }
      }
    },
    failedFetchingData: {
      type: 'final'
    },
    deleteModal: {
      on: {
        CLOSE_DELETE_MODAL: 'main',
        REPORT_DELETED: {
          target: 'main',
          actions: ['deleteDistributor']
        },
        CLOSE_REPORT_MODAL: 'loadDistributorData',
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    },

    main: {
      after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
      on: {
        OPEN_REPORT_MODAL: {
          target: 'reportModal',
          actions: ['setDistributor', 'hideSuccessNotification']
        },
        OPEN_DELETE_MODAL: {
          target: 'deleteModal',
          actions: ['setDistributor', 'hideSuccessNotification']
        },
        OPEN_MACHINES_MODAL: {
          target: 'machineModal',
          actions: ['setDistributor', 'hideSuccessNotification']
        },
        HIDE_SUCCESS_NOTIFICATION: {
          actions: ['hideSuccessNotification']
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    },
    reportModal: {
      on: {
        CLOSE_REPORT_MODAL: 'main',
        REPORT_EDITED: {
          target: 'main',
          actions: ['editReport']
        },
        REPORT_ADDED: {
          target: 'main',
          actions: ['addReport']
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    },
    proceedModal: {
      on: {
        CLOSE_PROCEED_MODAL: 'machineModal',
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    },
    machineModal: {
      after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
      on: {
        CLOSE_MACHINE_MODAL: 'main',
        OPEN_PROCEED_MODAL: {
          target: 'proceedModal',
          actions: ['setDisplayedMachines', 'hideSuccessNotification']
        },
        REPORT_ADDED: 'loadDistributorData',
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      },
      entry: ['showMachineLoading'],
      invoke: {
        src: (context) => Promise.all([fetchMachinesLocations({ fetchOrphans: true }), getDistributorUnits(context.report.id, { onlyIds: true })]),
        onDone: {
          actions: assign({
            displayedMachineIds: (context, event) => event.data[1].map(machine => machine.id),
            machines: (context, event) => event.data[0],
            isLoadingMachines: (context, event) => false
          })
        },
        onError: {
          target: 'failedFetchingData',
          actions: assign({
            error: (context, event) => event.data.message,
            isLoadingMachines: (context, event) => false
          })
        }
      }
    }

  }
},
  {
    actions: {
      showMachineLoading: assign({
        isLoadingMachines: true
      }),
      showSuccessNotification: assign({
        successNotificationText: (context, event) => event.text,
        isSuccessNotificationVisible: true
      }),
      hideSuccessNotification: assign({
        isSuccessNotificationVisible: false
      }),
      editReport: assign({
        reports: (context, event) => context.reports.map(report => report.id === event.report.id ? event.report : report)
      }),
      addReport: assign({
        reports: (context, event) => [event.report, ...context.reports]
      }),
      setDistributor: assign({
        report: (context, event) => event.report
      }),
      deleteDistributor: assign({
        reports: (context, event) => context.reports.filter(report => report.id !== event.report.id)
      }),
      setDisplayedMachines: assign({
        displayedMachineIds: (context, event) => event.displayedMachineIds
      })
    },
    guards: {
      isSuccessNotificationVisible: (context, event) => !!context.isSuccessNotificationVisible
    }

  }
)

const weekObject = {
  '0': 'Sunday',
  '1': 'Monday',
  '2': 'Tuesday',
  '3': 'Wednesday',
  '4': 'Thursday',
  '5': 'Friday',
  '6': 'Saturday',
}

const DistributorsContainer = ({ t }) => {
  const [current, send] = useMachine(unitsMachine)
  switch (current.value) {
    case 'loadDistributorData':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t('Session Is Over Error')}</div>
    default: {
      const {
        reports,
        sites,
        reportTypes,
        machines,
        report,
        isSuccessNotificationVisible,
        organizations,
        isLoadingMachines,
        displayedMachineIds,
        timeZoneNames,
        successNotificationText,
      } = current.context
      const isReportModalOpen = current.value === 'reportModal'
      const isDeleteModalOpen = current.value === 'deleteModal'
      const isProceedModalOpen = current.value === 'proceedModal'
      const isMachinesModalOpen = current.value === 'machineModal' || current.value === 'proceedModal'
      return (
        <FullPagePaper>
          <Snackbar
            place="tc"
            color="success"
            icon={NotificationsIcon}
            message={t(successNotificationText) + '!'}
            open={isSuccessNotificationVisible}
          />
          <ReportModal
            timeZoneNames={timeZoneNames}
            isOpen={isReportModalOpen}
            weekObject={weekObject}
            report={report}
            sites={sites}
            reportTypes={reportTypes}
            organizations={organizations}
            onClose={() => send('CLOSE_REPORT_MODAL')}
            reportAdded={(report) => {
              send([{ type: 'SHOW_SUCCESS_NOTIFICATION', text: 'Saved' }, { type: 'REPORT_ADDED', report }])
            }}
            reportEdited={(report) => {
              send([{ type: 'SHOW_SUCCESS_NOTIFICATION', text: 'Saved' }, { type: 'REPORT_EDITED', report }])
            }}
          />
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={() => {
              send('CLOSE_DELETE_MODAL')
            }}
            onApply={async () => {
              await deleteReport(report.id)
              send([{ type: 'SHOW_SUCCESS_NOTIFICATION', text: 'Saved' }, { type: 'REPORT_DELETED', report }])
            }}
          />
          {
            isMachinesModalOpen
              ? <DistributorsMachinesModal
                machines={machines}
                isOpen={isMachinesModalOpen}
                multiSelect
                isLoadingMachines={isLoadingMachines}
                title={t('Distributor units')}
                displayedMachineIds={displayedMachineIds}
                onClose={() => {
                  send('CLOSE_MACHINE_MODAL')
                }}
                onApply={async (machineIds) => {
                  try {
                    await addDistributorUnits(machineIds, report.id, false)
                  } catch (err) {
                    if (err.errorType === 'REPORT_CONFLICT') {
                      return send({ type: 'OPEN_PROCEED_MODAL', displayedMachineIds: machineIds })
                    }
                    throw new Error(err.errorType)
                  }
                  send([{ type: 'SHOW_SUCCESS_NOTIFICATION', text: 'Saved' }, 'CLOSE_MACHINE_MODAL'])
                }}
              />
              : null
          }
          {
            isProceedModalOpen
              ? <ProceedModal
                isOpen={isProceedModalOpen}
                onApply={async () => {
                  await addDistributorUnits(displayedMachineIds, report.id, true)
                  send([{ type: 'SHOW_SUCCESS_NOTIFICATION', text: 'Saved' }, 'CLOSE_PROCEED_MODAL'])
                }}
                onClose={() => send('CLOSE_PROCEED_MODAL')}
                msg='Units you chose might belong to a different report, do you still want to proceed?'
              />
              : null
          }
          <ReportsTableHeader
            openReportModal={() => {
              send({ type: 'OPEN_REPORT_MODAL', report: {} })
            }}
          />
          <ReportsTable
            weekObject={weekObject}
            reports={reports}
            reportSent={() => {
              send({ type: 'SHOW_SUCCESS_NOTIFICATION', text: 'Report is sent' })
              setTimeout(() => send('HIDE_SUCCESS_NOTIFICATION'), 3500)
            }}
            openDeleteModal={(report) => {
              send({ type: 'OPEN_DELETE_MODAL', report })
            }}
            openMachinesModal={(report) => {
              send({ type: 'OPEN_MACHINES_MODAL', report })
            }}
            openUpdateModal={(report = {}) => {
              send({ type: 'OPEN_REPORT_MODAL', report })
            }}

          />
        </FullPagePaper>
      )
    }
  }
}

export default withTranslation()(DistributorsContainer)
