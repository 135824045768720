import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'
import moment from 'moment'

export const fetchShiftConfiguration = async ({ nowDate, yesterday, updatedFromDate, dateCategory }) => {
  const URL = SERVER_PATH + apiEndpoints.getShiftConfiguration

  return resolveRequest(axios.post(URL,
    {
      currentHour: moment().hours(),
      nowDate,
      yesterday,
      updatedFromDate,
      dateCategory
    },
    {
      withCredentials: true
    }))

}


