export const DISTRIBUTOR = 'distributor'
export const INTERNAL_AREA = 'internalArea'
export const IS_ARCHIVED = 'isArchived'
export const ORGANIZATION = 'organization'
export const SERIAL_NUMBER = 'serialNumber'
export const SITE = 'site'
export const ID = 'id'
export const VIEWS = 'views'
export const ACTIONS = 'actions'
export const ADDITIONAL_WATER_TIME = 'additionalWaterTime'
export const AFTER_WASH_DELAY = 'afterWashDelay'
export const LATHER_TIME = 'duringLathering'
export const REAGENT = 'reagent'
export const SOAP_TIME = 'soapTime'
export const WATER_TIME_DURING_SOAP = 'waterTimeDuringSoap'
export const WATER_TIME_PER_WATER_CYCLE = 'waterTimePerWaterCycle'
export const TRIGGER_SOAP = 'triggerSoap'
export const MONITOR_ORIENTATION = 'monitorOrientation'
