import axios from 'axios';

import {apiEndpoints, SERVER_PATH} from '../endpoints.js';
import failedSession from "../failedSession";

const createRegentBottles = async (
    reagentSupplierId,
    bottleIds
) => {

    const URL = SERVER_PATH + apiEndpoints.reagentBottles;
    try {
        const body = {
            reagentSupplierId,
            bottleIds
        }

        const {data} = await axios.post(
            URL,
            body,
            {withCredentials: true}
        );

        const {existingBottleIds} = data

        return {didSucceed: existingBottleIds === undefined, existingBottleIds}

    } catch (e) {
        failedSession(e);
        return {didSucceed: false}
    }
};

export default createRegentBottles;
