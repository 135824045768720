import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getNotificationSettings from '../../endpoint-requests/dashboard-user/getNotificationSettings.js'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { assign, Machine } from 'xstate'
import { useMachine } from '@xstate/react'
import { withTranslation } from 'react-i18next'
import NotificationSettings from './Presentational'

function getNotificationSettingsMachine (dashboardUserId) {
  return Machine({
    id: 'units',
    initial: 'loadNotificationSettingsData',
    context: {
      notificationSettings: {},
      errorType: ''
    },
    states: {
      loadNotificationSettingsData: {
        invoke: {
          src: (context, event) => getNotificationSettings(dashboardUserId),
          onDone: {
            target: 'main',
            actions: assign({
              notificationSettings: (context, event) => event.data.data
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.error
            })
          }
        }
      },
      main: {

      },
      failedFetchingData: {
        type: 'final'
      }
    }
  })
}

const NotificationSettingsContainer = ({ t, currentDashboardUser }) => {
  const [current] = useMachine(getNotificationSettingsMachine(currentDashboardUser.id))

  switch (current.value) {
    case 'loadNotificationSettingsData':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t('Session Is Over Error')}</div>
    default: {
      const { notificationSettings } = current.context
      return (
        <NotificationSettings
          notificationSettings={notificationSettings}

        />
      )
    }
  }
}

export default withAuthentication(withTranslation()(NotificationSettingsContainer))
