import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * @typedef {object} siteConfiguration
 * @property {number} siteConfiguration.sanitizerReagent
 * @property {number} siteConfiguration.sanitizerLathering
 * @property {number} siteConfiguration.soapReagent
 * @property {number} siteConfiguration.soapLathering
 * @property {number} siteConfiguration.soapRinsing
 * @property {number} siteConfiguration.temperatureThreshold
 * @property {number} siteConfiguration.failureThreshold
 * @property {string} siteConfiguration.failedResultSmiley
 * @property {string} siteConfiguration.campaignId
 * @property {boolean} siteConfiguration.isActiveCampaign
 * @property {number} siteConfiguration.campaignDelay
 * @property {number} siteConfiguration.verticalMonitor
 */

/**
 * @param {siteConfiguration} configuration
 * @param {string} siteId
 */

export default async (configuration, siteId) => {
  const isConfigurationValid = checkIsConfigurationValid(configuration)
  if (!isConfigurationValid) throw new Error('Sum of soap or reagent must be 100%')
  const endpoint = `${apiEndpoints.sites}/${siteId}/configurations`

  const URL = SERVER_PATH + endpoint
  console.log(configuration)
  return resolveRequest(axios.post(URL, configuration, { withCredentials: true }))
}

/**
 * @param {siteConfiguration} configuration
 */
const checkIsConfigurationValid = (configuration) => {
  const sanitizerSum = configuration.sanitizerReagent + configuration.sanitizerLathering
  const soapSum = configuration.soapReagent + configuration.soapLathering + configuration.soapRinsing

  const isSanitizerValid = sanitizerSum === 100
  const isSoapValid = soapSum === 100

  return isSanitizerValid && isSoapValid
}
