import CampaignItemsModal from './modals/CampaignItemsModal.jsx'
import ActiveCampaignLogsModal from './modals/ActiveCampaignLogsModal.jsx'
import fetchActiveCampaignLogs from '../../endpoint-requests/campaign/fetchCampaignActiveLogs.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useMachine } from '@xstate/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import ConfirmationModal from '../../components-soapy/ConfirmationModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import Snackbar from '../../components/Snackbar/Snackbar'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import deleteCampaign from '../../endpoint-requests/campaign/deleteCampaign.js'
import unarchiveCampaign from '../../endpoint-requests/campaign/unarchiveCampaign.js'
import fetchCampaigns from '../../endpoint-requests/campaign/fetchCampaigns'
import AddCampaignModal from './modals/AddCampaignModal.jsx'
import CampaignsTable from './CampaignsTable.jsx'
import CampaignsTableHeader from './CampaignsTableHeader.jsx'

function getCampaignsMachine(currentDashboardUser) {
  return Machine({
    id: 'units',
    initial: 'loadingCampaigns',
    context: {
      campaigns: [],
      error: '',
      activeCampaignLogs: [],
      campaign: {},
      campaignItemRinsingUrls: [],
      campaignItemWelcomeUrls: [],
      isSuccessNotificationVisible: false
    },
    states: {
      loadingCampaigns: {
        invoke: {
          src: (context, event) => Promise.all([fetchCampaigns(), currentDashboardUser.entity.name === 'neo']),
          onDone: {
            target: 'main',
            actions: assign({
              campaigns: (context, event) => event.data[0]
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message
            })
          }
        }
      },
      failedFetchingData: {
        type: 'final'
      },
      main: {
        exit: ['hideSuccessNotification'],
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        on: {
          OPEN_ADD_CAMPAIGN_MODAL: {
            target: 'addCampaignModal',
            actions: ['setCampaign']
          },
          OPEN_ACTIVE_CAMPAIGN_LOGS_MODAL: {
            target: 'loadingActiveCampaignLogs',
            actions: ['setCampaign']
          },
          OPEN_CAMPAIGN_DELETE_MODAL: {
            target: 'deleteCampaignModal',
            actions: ['setCampaign']
          },
          OPEN_CAMPAIGN_ARCHIVE_MODAL: {
            target: 'archiveCampaignModal',
            actions: ['setCampaign']
          },
          OPEN_CAMPAIGN_UNARCHIVE_MODAL: {
            target: 'unarchiveCampaignModal',
            actions: ['setCampaign']
          }
        }
      },
      addCampaignModal: {
        on: {
          CLOSE_ADD_CAMPAIGN_MODAL: 'main',
          CAMPAIGN_ADDED: {
            target: 'main',
            actions: ['addNewCampaign']
          },
          CAMPAIGN_EDITED: {
            target: 'main',
            actions: ['editCampaign']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      loadingActiveCampaignLogs: {
        invoke: {
          src: (context, event) => fetchActiveCampaignLogs(context.campaign.id),
          onDone: {
            target: 'activeCampaignLogsModal',
            actions: assign({
              activeCampaignLogs: (context, event) => event.data
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.error
            })
          }
        }
      },
      activeCampaignLogsModal: {
        on: {
          CLOSE_ACTIVE_CAMPAIGN_MODAL: 'main',
          OPEN_CAMPAIGN_ITEMS_MODAL: {
            target: 'campaignItemsModal',
            actions: ['setCampaignItemRinsingUrls', 'setCampaignItemWelcomeUrls']
          }
        }
      },
      campaignItemsModal: {
        on: {
          CLOSE_CAMPAIGN_ITEMS_MODAL: 'activeCampaignLogsModal'
        }
      },
      deleteCampaignModal: {
        on: {
          CLOSE_CAMPAIGN_DELETE_MODAL: {
            target: 'main',
            actions: ['setCampaign']
          },
          CAMPAIGN_DELETED: {
            target: 'main',
            actions: ['deleteCampaign']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      archiveCampaignModal: {
        on: {
          CLOSE_CAMPAIGN_ARCHIVE_MODAL: {
            target: 'main',
            actions: ['setCampaign']
          },
          CAMPAIGN_ARCHIVED: {
            target: 'main',
            actions: ['editCampaign']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },
      unarchiveCampaignModal: {
        on: {
          CLOSE_CAMPAIGN_UNARCHIVE_MODAL: {
            target: 'main',
            actions: ['setCampaign']
          },
          CAMPAIGN_UNARCHIVED: {
            target: 'main',
            actions: ['editCampaign']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      }
    }
  },
    {
      actions: {
        showSuccessNotification: assign({
          isSuccessNotificationVisible: true
        }),
        hideSuccessNotification: assign({
          isSuccessNotificationVisible: false
        }),
        setCampaign: assign({
          campaign: (context, event) => event.campaign
        }),
        editCampaign: assign({
          campaigns: (context, event) => context.campaigns.map(campaign => campaign.id === event.campaign.id ? event.campaign : campaign)
        }),
        addNewCampaign: assign({
          campaigns: (context, event) => [event.campaign, ...context.campaigns]
        }),
        setCampaignItemRinsingUrls: assign({
          campaignItemRinsingUrls: (context, event) => event.campaignItemRinsingUrls
        }),
        setCampaignItemWelcomeUrls: assign({
          campaignItemWelcomeUrls: (context, event) => event.campaignItemWelcomeUrls
        }),
        deleteCampaign: assign({
          campaigns: (context, event) => context.campaigns.filter(campaign => campaign.id !== event.campaign.id)
        })
      },
      guards: {
        isSuccessNotificationVisible: (context, event) => {
          return !!context.isSuccessNotificationVisible
        }
      }
    }

  )
}

const CampaignsContainer = ({ t, currentDashboardUser }) => {
  const [current, send] = useMachine(getCampaignsMachine(currentDashboardUser))

  const isAddCampaignModalOpen = current.value === 'addCampaignModal'
  const isDeleteCampaignModalOpen = current.value === 'deleteCampaignModal'
  const isArchiveCampaignModalOpen = current.value === 'archiveCampaignModal'
  const isUnarchiveCampaignModalOpen = current.value === 'unarchiveCampaignModal'
  const isActiveCampaignLogsModalOpen = current.value === 'activeCampaignLogsModal' || current.value === 'loadingActiveCampaignLogs'
  const isCampaignItemsModalOpen = current.value === 'campaignItemsModal'

  const isLoadingActiveCampaignLogs = current.value === 'loadingActiveCampaignLogs'

  switch (current.value) {
    case 'loadingCampaigns':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t(current.context.error)}</div>
    default: {
      const {
        campaigns,
        campaign,
        campaignItemRinsingUrls,
        campaignItemWelcomeUrls,
        isSuccessNotificationVisible,
        activeCampaignLogs
      } = current.context
      return (
        <FullPagePaper>
          <Snackbar
            place='tc'
            color='success'
            icon={NotificationsIcon}
            message={t('Saved') + '!'}
            open={isSuccessNotificationVisible}
          />
          <AddCampaignModal
            isOpen={isAddCampaignModalOpen}
            campaign={campaign}
            onClose={() => send('CLOSE_ADD_CAMPAIGN_MODAL')}
            campaignEdited={(campaign) => {
              send([
                'SHOW_SUCCESS_NOTIFICATION',
                {
                  type: 'CAMPAIGN_EDITED',
                  campaign
                }
              ])
            }}
            campaignAdded={(campaign) => {
              send([
                'SHOW_SUCCESS_NOTIFICATION',
                {
                  type: 'CAMPAIGN_ADDED',
                  campaign
                }
              ])
            }}
          />
          <CampaignItemsModal
            isOpen={isCampaignItemsModalOpen}
            onCancel={() => {
              send('CLOSE_CAMPAIGN_ITEMS_MODAL')
            }}
            title=''
            campaignItemRinsingUrls={campaignItemRinsingUrls}
            campaignItemWelcomeUrls={campaignItemWelcomeUrls}
          />
          <ActiveCampaignLogsModal
            isOpen={isActiveCampaignLogsModalOpen}
            title={t('Active campaign logs')}
            isLoading={isLoadingActiveCampaignLogs}
            onCancel={() => {
              send('CLOSE_ACTIVE_CAMPAIGN_MODAL')
            }}
            openCampaignItemsModal={(campaignItemRinsingUrls, campaignItemWelcomeUrls) => {
              send({ type: 'OPEN_CAMPAIGN_ITEMS_MODAL', campaignItemRinsingUrls, campaignItemWelcomeUrls })
            }}
            activeCampaignLogs={activeCampaignLogs}
          />
          <ConfirmationModal
            isOpen={isDeleteCampaignModalOpen}
            onClose={() => {
              send({ type: 'CLOSE_CAMPAIGN_DELETE_MODAL', campaign: {} })
            }}
            onApply={async () => {
              await deleteCampaign(campaign.id)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'CAMPAIGN_DELETED', campaign }])
            }}
          />
          <ConfirmationModal
            isOpen={isArchiveCampaignModalOpen}
            title='Archive'
            applyBtnText='Archive'
            onClose={() => {
              send({ type: 'CLOSE_CAMPAIGN_ARCHIVE_MODAL', campaign: {} })
            }}
            onApply={async () => {
              const archivedCampaign = await deleteCampaign(campaign.id, true)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'CAMPAIGN_ARCHIVED', campaign: archivedCampaign }])
            }}
          />

          <ConfirmationModal
            isOpen={isUnarchiveCampaignModalOpen}
            title='Unarchive'
            applyBtnText='Unarchive'
            onClose={() => {
              send({ type: 'CLOSE_CAMPAIGN_UNARCHIVE_MODAL', campaign: {} })
            }}
            onApply={async () => {
              const unarchivedCampaign = await unarchiveCampaign(campaign.id)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'CAMPAIGN_UNARCHIVED', campaign: unarchivedCampaign }])
            }}
          />
          <CampaignsTableHeader
            openAddCampaignModal={() => {
              send({ type: 'OPEN_ADD_CAMPAIGN_MODAL', campaign: {} })
            }}
            totalCampaigns={campaigns.length}
          />
          <CampaignsTable
            campaigns={campaigns}
            openUpdateCampaignModal={(campaign) => send({ type: 'OPEN_ADD_CAMPAIGN_MODAL', campaign })}
            openDeleteCampaignModal={(campaign) => send({ type: 'OPEN_CAMPAIGN_DELETE_MODAL', campaign })}
            openArchiveCampaignModal={(campaign) => send({ type: 'OPEN_CAMPAIGN_ARCHIVE_MODAL', campaign })}
            openUnarchiveCampaignModal={(campaign) => send({ type: 'OPEN_CAMPAIGN_UNARCHIVE_MODAL', campaign })}
            openActiveCampaignLogsModal={(campaign) => send({ type: 'OPEN_ACTIVE_CAMPAIGN_LOGS_MODAL', campaign })}
          />
        </FullPagePaper>
      )
    }
  }
}

export default withAuthentication(withTranslation()(CampaignsContainer))
