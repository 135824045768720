import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import blue from '@material-ui/core/colors/blue'
import Button from '../components/CustomButtons/Button'

const circularProgressSize = 24

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(),
    position: 'relative'
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -(circularProgressSize / 2),
    marginLeft: -(circularProgressSize / 2)
  },
  marginbtn: {
    marginTop: '15px'
  }
})

const LoadingButton = ({
  classes,
  children,
  isLoading,
  fullWidth,
  marginBtn,
  divStyle={},
  div2Style={},
  ...rest
}) => {
  return (
    <div
      className={classes.root}
      style={Object.assign({ width: fullWidth ? '100%' : 'auto' },divStyle)}
    >
      <div
        className={classes.wrapper}
        style={Object.assign({ width: fullWidth ? '100%' : 'auto' },div2Style)}
      >
        <Button className={marginBtn ? classes.marginbtn : null} id='lodingButton' {...rest}>{children}</Button>
        {isLoading && (
          <CircularProgress
            id='lodingButtonCircularProgress'
            size={circularProgressSize}
            className={classes.buttonProgress}
          />
        )}
      </div>
    </div>
  )
}

LoadingButton.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LoadingButton)
