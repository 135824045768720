import React from "react";
import BackOfLeftHandIcon from '../../assets/img/hand-recognition/back_of_left_hand.svg';
import BackOfLeftHandFailedIcon from '../../assets/img/hand-recognition/back_of_left_hand_failed.svg';
import BackOfLeftThumbIcon from '../../assets/img/hand-recognition/back_of_left_thumb.svg';
import BackOfLeftThumbFailedIcon from '../../assets/img/hand-recognition/back_of_left_thumb_failed.svg';
import BackOfRightHandIcon from '../../assets/img/hand-recognition/back_of_right_hand.svg';
import BackOfRightHandFailedIcon from '../../assets/img/hand-recognition/back_of_right_hand_failed.svg';
import BackOfRightThumbIcon from '../../assets/img/hand-recognition/back_of_right_thumb.svg';
import BackOfRightThumbFailedIcon from '../../assets/img/hand-recognition/back_of_right_thumb_failed.svg';
import BetweenFingersIcon from '../../assets/img/hand-recognition/between_fingers.svg';
import BetweenFingersFailedIcon from '../../assets/img/hand-recognition/between_fingers_failed.svg';
import FingernailLeftIcon from '../../assets/img/hand-recognition/fingernail_left.svg';
import FingernailLeftFailedIcon from '../../assets/img/hand-recognition/fingernail_left_failed.svg';
import FingernailRightIcon from '../../assets/img/hand-recognition/fingernail_right.svg';
import FingernailRightFailedIcon from '../../assets/img/hand-recognition/fingernail_right_failed.svg';
import PalmToPalmIcon from '../../assets/img/hand-recognition/palm_to_palm.svg';
import PalmToPalmFailedIcon from '../../assets/img/hand-recognition/palm_to_palm_failed.svg';

const drawHandsIcon = (icon) =>
  <img
    src={icon}
    style={{width: '3em', height: '3em', margin: '4px'}}
    alt='hands'
  />


export const BackOfLeftHand = drawHandsIcon(BackOfLeftHandIcon)
export const BackOfLeftHandFailed = drawHandsIcon(BackOfLeftHandFailedIcon)
export const BackOfLeftThumb = drawHandsIcon(BackOfLeftThumbIcon)
export const BackOfLeftThumbFailed = drawHandsIcon(BackOfLeftThumbFailedIcon)
export const BackOfRightHand = drawHandsIcon(BackOfRightHandIcon)
export const BackOfRightHandFailed = drawHandsIcon(BackOfRightHandFailedIcon)
export const BackOfRightThumb = drawHandsIcon(BackOfRightThumbIcon)
export const BackOfRightThumbFailed = drawHandsIcon(BackOfRightThumbFailedIcon)
export const BetweenFingers = drawHandsIcon(BetweenFingersIcon)
export const BetweenFingersFailed = drawHandsIcon(BetweenFingersFailedIcon)
export const FingernailLeft = drawHandsIcon(FingernailLeftIcon)
export const FingernailLeftFailed = drawHandsIcon(FingernailLeftFailedIcon)
export const FingernailRight = drawHandsIcon(FingernailRightIcon)
export const FingernailRightFailed = drawHandsIcon(FingernailRightFailedIcon)
export const PalmToPalm = drawHandsIcon(PalmToPalmIcon)
export const PalmToPalmFailed = drawHandsIcon(PalmToPalmFailedIcon)

