import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const fetchReports = async () => {
  const URL = SERVER_PATH + apiEndpoints.reportTypes
  return resolveRequest(axios.get(URL, { withCredentials: true }))
}

export default fetchReports
