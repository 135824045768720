import { Tooltip } from '@material-ui/core'
import Description from '@material-ui/icons/Description'
import React, { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ExportExcel from '../../components-soapy/Excel/ExportExcel.jsx'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import exportPDF from '../../shared/export/exportPDF'
import toFahrenheit from '../../shared/temperature/toFahrenheit.js'
import {
  DID_USE_SOAP,
  DURING_LATHER,
  DURING_RINSING,
  GROUP,
  HANDS_RECOGNITION,
  HANDS_RECOGNITION_KEYS,
  LATHERING_TIME,
  RESULT,
  RINSING_TIME,
  SERIAL_NUMBER,
  SITE,
  SITE_INTERNAL_AREA,
  TEMPERATURE,
  WASH_DATE,
  WASH_METRICS,
  WASHER
} from './constants'

const DailyWashExportTable = ({
  t,
  tableRef,
  machinesBtnText,
  serialNumbers,
  currentDashboardUser,
  dateBtnText
}) => {
  const dailyWashesExcelData = useSelector((state) => state.dailyWashes.dailyWashesExcelData)
  const headers = {
    [t('Time')]: 'time',
    [t('Group')]: 'group',
    [t('Washer')]: 'washer',
    [t('Machine')]: 'serialNumber',
    [t('Site')]: 'site',
    [t('Internal Area')]: 'internalArea',
    [t('Temperature (F)')]: 'temperatureFahrenheit',
    [t('Temperature (C)')]: 'temperatureCelsius',
    [t('Soap or sanitizer received')]: 'usedReagent',
    [t('Actual Rub & Scrub time')]: 'actualLatheringTime',
    [t('Target Rub & Scrub time')]: 'targetLatheringTime',
    [t('Percentage of Rub&Scrub')]: 'latheringResult',
    [t('Actual Rinsing time')]: 'actualRinsingTime',
    [t('Target Rinsing time')]: 'targetRinsingTime',
    [t('Percentage of Rinsing')]: 'rinsingResult',
    [t('Score')]: 'result',
    [t('Stars Score')]: 'starsScore',
    [t('Palm To Palm')]: 'palmToPalm',
    [t('Between Fingers')]: 'betweenFingers',
    [t('Back Of Left Hand')]: 'backOfLeftHand',
    [t('Back Of Right Hand')]: 'backOfRightHand',
    [t('Back Of Left Thumb')]: 'backOfLeftThumb',
    [t('Back Of Right Thumb')]: 'backOfRightThumb',
    [t('Fingernail Left')]: 'fingernailLeft',
    [t('Fingernail Right')]: 'fingernailRight'
  }

  const excelHeaders = Object.keys(headers).map(header => {
    return {
      value: header,
      style: {
        font: { sz: '12' },
        alignment: {
          wrapText: true,
          horizontal: 'center',
          vertical: 'top'
        },
        fill: {
          patternType: 'solid',
          fgColor: { rgb: '03b2cb' }
        }
      }
    }
  })
  const handleExportPdf = () => {
    const dataset = getExportableDataset()

    const data = dataset.map(wash => Object.values(wash))
    exportPDF(fileName, serialNumbers, Object.keys(headers), data, 'portrait', [1400, 1400])
  }

  const getExportableDataset = () => {

    // if (!tableRef || !tableRef.current) {
    //   return []
    // }
    // const tableData = tableRef.current.getResolvedState().sortedData
    return dailyWashesExcelData?.map(wash => {
      // const wash = row._original
      return {
        time: wash[WASH_DATE],
        group: wash[GROUP] ? wash[GROUP] : '',
        washer: wash[WASHER] ? wash[WASHER] : '',
        serialNumber: wash[SERIAL_NUMBER] ? wash[SERIAL_NUMBER] : '',
        site: wash[SITE],
        internalArea: wash[SITE_INTERNAL_AREA],
        temperatureFahrenheit: toFahrenheit(wash[TEMPERATURE]).toFixed(1),
        temperatureCelsius: String(wash[TEMPERATURE]),
        usedReagent: wash[WASH_METRICS][[DID_USE_SOAP]] ? '1' : '0',
        actualLatheringTime: wash[WASH_METRICS][DURING_LATHER] !== '-' ? String(wash[WASH_METRICS][DURING_LATHER]) : '',
        targetLatheringTime: wash[WASH_METRICS][DURING_LATHER] !== '-' ? String(wash[WASH_METRICS][LATHERING_TIME]) : '',
        latheringResult: wash[WASH_METRICS][DURING_LATHER] !== '-' ? (Math.round((wash[WASH_METRICS][DURING_LATHER] * 100) / wash[WASH_METRICS][LATHERING_TIME])) + '%' : '',
        actualRinsingTime: wash[WASH_METRICS][DURING_RINSING] !== '-' ? String(wash[WASH_METRICS][DURING_RINSING]) : '',
        targetRinsingTime: wash[WASH_METRICS][DURING_RINSING] !== '-' ? String(wash[WASH_METRICS][RINSING_TIME]) : '',
        rinsingResult: wash[WASH_METRICS][DURING_RINSING] !== '-' ? Math.floor((wash[WASH_METRICS][DURING_RINSING] * 100) / wash[WASH_METRICS][RINSING_TIME]) + '%' : '',
        result: wash[WASH_METRICS][[RESULT]] + '%',
        starsScore: (Math.round((parseInt(wash[WASH_METRICS][[RESULT]]) / 100 * 5) * 10) / 10).toString(),
        palmToPalm: wash[HANDS_RECOGNITION][HANDS_RECOGNITION][HANDS_RECOGNITION_KEYS.PALM_TO_PALM] ? '1' : '0',
        betweenFingers: wash[HANDS_RECOGNITION][HANDS_RECOGNITION][HANDS_RECOGNITION_KEYS.BETWEEN_FINGERS] ? '1' : '0',
        backOfLeftHand: wash[HANDS_RECOGNITION][HANDS_RECOGNITION][HANDS_RECOGNITION_KEYS.BACK_OF_LEFT_HAND] ? '1' : '0',
        backOfRightHand: wash[HANDS_RECOGNITION][HANDS_RECOGNITION][HANDS_RECOGNITION_KEYS.BACK_OF_RIGHT_HAND] ? '1' : '0',
        backOfLeftThumb: wash[HANDS_RECOGNITION][HANDS_RECOGNITION][HANDS_RECOGNITION_KEYS.BACK_OF_LEFT_THUMB] ? '1' : '0',
        backOfRightThumb: wash[HANDS_RECOGNITION][HANDS_RECOGNITION][HANDS_RECOGNITION_KEYS.BACK_OF_RIGHT_THUMB] ? '1' : '0',
        fingernailLeft: wash[HANDS_RECOGNITION][HANDS_RECOGNITION][HANDS_RECOGNITION_KEYS.FINGERNAIL_LEFT] ? '1' : '0',
        fingernailRight: wash[HANDS_RECOGNITION][HANDS_RECOGNITION][HANDS_RECOGNITION_KEYS.FINGERNAIL_RIGHT] ? '1' : '0'
      }
    })
  }

  const excelDataset = useMemo(() => {
    const data = getExportableDataset()

    return [
      {
        columns: [],
        data: [
          [
            {
              value: 'This report was generated by SoapyWisdom platform.'
            }
          ],
          [
            {
              value: 'Exported page: Daily washes'
            }
          ],
          [
            {
              value: `Time range: ${dateBtnText}`
            }
          ],
          [
            {
              value: `CleanMachine S/N: ${serialNumbers}`
            }
          ],
          [
            {
              value: `Exported by: ${currentDashboardUser.email}`
            }
          ],
          [
            ...excelHeaders
          ],
          ...(data.map(wash => {
            return Object.values(wash).map(value => {
              return {
                value,
                style: {
                  widthPx: 1000,

                  alignment: {
                    wrapText: true,
                    horizontal: 'center',
                    vertical: 'top'
                  },
                  font: { sz: '12' }
                }
              }
            })
          }))
        ]
      }
    ]
  }, [tableRef.current, serialNumbers]) // eslint-disable-line react-hooks/exhaustive-deps

  const fileName = `${t('Live Washes')} - ${dateBtnText}`
  return (
    <div>
      <ExportExcel fileName={fileName} title={t('Daily Washes')} dataSet={excelDataset}/>
      <Tooltip title={t('Export to pdf')}>
        <Button
          justIcon
          round
          id="dailyWashExportPdfButton"
          style={{ marginLeft: '5px' }}
          color="info"
          onClick={handleExportPdf}
        >
          <Description/>
        </Button>
      </Tooltip>
    </div>
  )
}

export default withAuthentication(withTranslation()(DailyWashExportTable))
