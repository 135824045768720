import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints'

/**
 *
 * @param {object} requestObject
 * @param {string} requestObject.email
 * @param {string} requestObject.password
 * @param {boolean} requestObject.policyAccepted
 * @param {number} requestObject.twoFACode
 */
export default async requestObject => {
  const URL = SERVER_PATH + apiEndpoints.login
  return resolveRequest(axios.post(URL, requestObject, {
    withCredentials: true
  }))
}
