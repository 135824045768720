export const WASH_METRICS = 'washMetrics'
export const ID = 'id'
export const WASHER = 'washer'
export const WASH_DATE = 'washDate'
export const IS_FAILED = 'isFailed'
export const DID_USE_SOAP = 'didUseSoap'
export const DURING_LATHER = 'duringLather'
export const DURING_RINSING = 'rinsing'
export const RINSING_TIME = 'rinsingTime'
export const LATHERING_TIME = 'latheringTime'
export const TEMPERATURE_TYPE = 'temperatureType'
export const TEMPERATURE = 'temperature'
export const RESULT = 'result'
export const HANDS_RECOGNITION = 'handsRecognition'
export const SITE = 'site'
export const SITE_INTERNAL_AREA = 'siteInternalArea'
export const WASHER_ID = "washerId"
export const SERIAL_NUMBER = "serialNumber"
export const GROUP = "group"


export const HANDS_RECOGNITION_KEYS = {
  PALM_TO_PALM: 'palmToPalm',
  BETWEEN_FINGERS: 'betweenFingers',
  BACK_OF_LEFT_HAND: 'backOfLeftHand',
  BACK_OF_RIGHT_HAND: 'backOfRightHand',
  BACK_OF_LEFT_THUMB: 'backOfLeftThumb',
  BACK_OF_RIGHT_THUMB: 'backOfRightThumb',
  FINGERNAIL_LEFT: 'fingernailLeft',
  FINGERNAIL_RIGHT: 'fingernailRight'
}

export const HANDS_ICON_NAMES = {
  BackOfLeftHand: 'Back Of Left Hand',
  BackOfLeftThumb: 'Back Of Left Thumb',
  BackOfRightHand: 'Back Of Right Hand',
  BackOfRightThumb: 'Back Of Right Thumb',
  BetweenFingers: 'Between Fingers',
  FingernailLeft: 'Fingernail Left',
  FingernailRight: 'Fingernail right',
  PalmToPalm: 'Palm To Palm'
}
