import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Check from '@material-ui/icons/Check'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import formStyle from '../../../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../components-soapy/LoadingButton'
import CardBody from '../../../components/Card/CardBody.jsx'
import CustomInput from '../../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import addDashboardUser from '../../../endpoint-requests/dashboard-user/addDashboardUser'
import { editDashboardUser } from '../../../endpoint-requests/dashboard-user/editDashboardUser'
import useFormFields from '../../../shared/hooks/useFormFields.js'
import toCapitalize from '../../../utils/toCapitalize.js'
import validateFormFields from '../../../utils/validateFormFields.js'
import { isEmail, verifyLength } from '../../../utils/validators'
import { allPages, pages } from '../../../routes/pages'

const INFO_COLOR = '#00acc1'

const VERIFY_LENGTH_MESSAGE = 'should not be empty, and up to 100 characters'

const greyBackground = {
  backgroundColor: '#e6e6e6',
  opacity: '1'
}

const FIRST_NAME = 'firstName'
const PHONE_NUMBER = 'phoneNumber'
const LAST_NAME = 'lastName'
const EMAIL = 'email'
const ORGANIZATION = 'organization'
const ORGANIZATIONS = 'organizations'
const ID = 'id'
const DISTRIBUTOR = 'distributor'
const SITE = 'site'
const POSITION = 'position'
const ROLE = 'role'
const ENTITY = 'entity'
const ENTITIES = {
  neo: ['distributor', 'organization', 'neo', 'site'],
  distributor: ['organization', 'distributor'],
  organization: ['site', 'organization']
}
const DEFAULT_PAGE = 'defaultPage'
const FULL_SCREEN = 'fullScreen'
const TWO_FA = 'twoFA'

const ROLES = {
  neo: {
    neo: ['admin'],
    distributor: ['admin', 'technician', 'user'],
    organization: ['admin', 'user'],
    site: ['admin', 'user'],
    me: ['admin', 'technician', 'user']
  },
  distributor: {
    distributor: ['technician', 'user'],
    organization: ['admin'],
    site: ['admin'],
    me: ['admin', 'technician', 'user']
  },
  organization: {
    organization: ['admin', 'user'],
    site: ['admin', 'user'],
    me: ['admin', 'technician', 'user']
  },
  site: {
    site: ['admin', 'user'],
    me: ['admin', 'user']
  }
}

const DEFAULT_ROLE = 'user'
const DEFAULT_ENTITY = 'site'

const VALIDATION_STATE = 'ValidationState'

const filterSitesByOrganizations = (sites, organizations) => {
  return Object.keys(sites).reduce((acc, siteId) => {
    if (organizations.includes(sites[siteId].organizationId)) {
      acc[siteId] = { ...sites[siteId] }
    }
    return acc
  }, {})
}

const getDefaultRole = (userEntityName, entityName) => {
  return ROLES[userEntityName][entityName][0]
}

function getDistributorId (dashboardUser, me) {
  if (dashboardUser.entity && dashboardUser.entity.name !== 'neo') {
    return dashboardUser.distributor.id
  }
  if (me && me.entity.name !== 'neo') {
    return me.distributorId
  }
  return ''
}

function getOrganizationIds (dashboardUser, me) {
  if (me && (me.entity.name === 'organization' || me.entity.name === 'site')) {
    return me.organizations[0].id
  }
  if (dashboardUser[ORGANIZATIONS]) {
    return dashboardUser[ORGANIZATIONS].map(org => org[ID])
  }
  return []
}

function getSiteIds (dashboardUser, me) {
  if (me && me.entity.name === 'site') {
    return me.sites[0].id
  }
  if (dashboardUser.sites) {
    return dashboardUser.sites.map(org => org[ID])
  }
  return []
}

function checkIsRequired (fieldName, formFields) {
  let isRequired = false
  switch (true) {
    case formFields[ENTITY].value === 'neo':
      isRequired = fieldName === FIRST_NAME || fieldName === LAST_NAME || fieldName === EMAIL || fieldName === ROLE
      break
    case formFields[ENTITY].value === 'distributor' && formFields[ROLE].value === 'admin':
      isRequired = fieldName === DISTRIBUTOR || fieldName === FIRST_NAME || fieldName === LAST_NAME || fieldName === EMAIL || fieldName === ROLE
      break
    case formFields[ENTITY].value === 'distributor' && (formFields[ROLE].value === 'technician' || formFields[ROLE].value === 'user'):
      isRequired = fieldName === ORGANIZATION || fieldName === DISTRIBUTOR || fieldName === FIRST_NAME || fieldName === LAST_NAME || fieldName === EMAIL || fieldName === ROLE
      break
    case formFields[ENTITY].value === 'organization':
      isRequired = fieldName === ORGANIZATION || fieldName === DISTRIBUTOR || fieldName === FIRST_NAME || fieldName === LAST_NAME || fieldName === EMAIL || fieldName === ROLE
      break
    case formFields[ENTITY].value === 'site':
      isRequired = fieldName === EMAIL || fieldName === SITE || fieldName === ORGANIZATION || fieldName === DISTRIBUTOR || fieldName === ROLE
      break
    default:
      isRequired = fieldName === FIRST_NAME || fieldName === LAST_NAME
  }
  return isRequired
}

let spinnerTimeout = null

const EditDashboardUser = (
  {
    isClosed,
    dashboardUser,
    classes,
    showErrorNotification,
    sites,
    dashboardUserEdited,
    dashboardUserAdded,
    distributors,
    organizations,
    currentDashboardUser,
    t
  }
) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [renderUserRole, setRenderUserRole] = useState('viewer')
  const { formFields, setFormFields } = useFormFields(
    {
      [FIRST_NAME]: {
        value: '',
        isRequired: (formFields) => false,
        name: 'First name',
        validators: [
          {
            isValid: (value) => verifyLength(value, 1, 100),
            message: 'First name ' + VERIFY_LENGTH_MESSAGE
          }
        ]
      },
      [LAST_NAME]: {
        value: '',
        isRequired: (formFields) => false,
        name: 'Last name',
        validators: [
          {
            isValid: (value) => verifyLength(value, 1, 100),
            message: 'Last name ' + VERIFY_LENGTH_MESSAGE
          }
        ]
      },
      [EMAIL]: {
        value: '',
        isRequired: (formFields) => checkIsRequired(EMAIL, formFields),
        name: 'Email',
        validators: [
          {
            isValid: (value) => isEmail(value),
            message: 'Email is not valid'
          }
        ]
      },
      [ROLE]: { value: DEFAULT_ROLE, isRequired: (formFields) => checkIsRequired(ROLE, formFields), name: 'Role' },
      [ORGANIZATION]: {
        value: [],
        isRequired: (formFields) => checkIsRequired(ORGANIZATION, formFields),
        name: 'Organization'
      },
      [DISTRIBUTOR]: {
        value: [],
        isRequired: (formFields) => checkIsRequired(DISTRIBUTOR, formFields),
        name: 'Distributor'
      },
      [ENTITY]: {
        value: DEFAULT_ENTITY,
        isRequired: (formFields) => checkIsRequired(ENTITY, formFields),
        name: 'Entity'
      },
      [PHONE_NUMBER]: {
        value: '',
        isRequired: false,
        name: 'Phone number',
        validators: [
          {
            isValid: (value) => isValidPhoneNumber(value),
            message: 'Phone number Is Invalid'
          }
        ]
      },
      [SITE]: { value: [], isRequired: (formFields) => checkIsRequired(SITE, formFields), name: 'Site' },
      [POSITION]: { value: '', isRequired: false, name: 'Position' },
      [DEFAULT_PAGE]: { value: '/dashboard', isRequired: false, name: 'Default Page' },
      [FULL_SCREEN]: { value: false, isRequired: false, name: 'Full Screen' },
      [TWO_FA]: { value: false, isRequired: false, name: '2 FA' },
    }
  )

  const defaultPageObject = {}
  if (formFields[ROLE].value && formFields[ENTITY].value) {
    //while creating new entry in db replace "viewer" to "user" as we are not changing role in db
    if (formFields[ROLE].value === 'viewer') {
      formFields[ROLE].value = 'user'
    }
    pages[formFields[ENTITY].value][formFields[ROLE].value].sort().forEach(page => {
      defaultPageObject[allPages[page]] = '/' + page
    })
  }

  const isEdit = !!dashboardUser.id

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      () => setIsAwaitingResponse(true),
      300
    )
  }

  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const submitForm = async () => {
    activateSpinner()

    try {
      if (!isEdit) {
        const createdDashboardUser = await addDashboardUser(
          formFields[FIRST_NAME].value,
          formFields[LAST_NAME].value,
          formFields[EMAIL].value,
          formFields[ORGANIZATION].value,
          formFields[POSITION].value,
          true,
          formFields[ROLE].value,
          formFields[PHONE_NUMBER].value,
          formFields[DISTRIBUTOR].value,
          formFields[ENTITY].value,
          formFields[SITE].value,
          formFields[DEFAULT_PAGE].value,
          formFields[FULL_SCREEN].value,
          formFields[TWO_FA].value,
        )
        dashboardUserAdded(createdDashboardUser)
      } else {
        const editedDashboardUser = await editDashboardUser(
          formFields[FIRST_NAME].value,
          formFields[LAST_NAME].value,
          formFields[ORGANIZATION].value,
          formFields[POSITION].value,
          dashboardUser.id,
          formFields[ROLE].value,
          formFields[PHONE_NUMBER].value ? formFields[PHONE_NUMBER].value : '',
          formFields[DISTRIBUTOR].value,
          formFields[ENTITY].value,
          formFields[SITE].value,
          formFields[DEFAULT_PAGE].value,
          formFields[FULL_SCREEN].value,
          formFields[TWO_FA].value,
        )
        dashboardUserEdited(editedDashboardUser)
      }
    } catch (err) {
      showErrorNotification(err.message)
    }
    disableSpinner()
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  const handleTextChange = (event, fieldName) => {
    const text = event.target.value
    const changedFormFields = {
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value: text },
      [fieldName + VALIDATION_STATE]: ''
    }
    setFormFields(changedFormFields)
    if (fieldName === ENTITY) {
      setFormFields({
        ...changedFormFields,
        [DISTRIBUTOR]: {
          ...changedFormFields[DISTRIBUTOR],
          value: text === 'neo' ? '' : changedFormFields[DISTRIBUTOR].value
        },
        [ORGANIZATION]: {
          ...changedFormFields[ORGANIZATION],
          value: currentDashboardUser.entity.name === 'organization' ? changedFormFields[ORGANIZATION].value : []
        },
        [SITE]: { ...changedFormFields[SITE], value: [] },
        [ROLE]: { ...changedFormFields[ROLE], value: getDefaultRole(currentDashboardUser.entity.name, text) }
      })
    }

    if (fieldName === DISTRIBUTOR) {
      setFormFields({
        ...changedFormFields,
        [ORGANIZATION]: { ...changedFormFields[ORGANIZATION], value: [] },
        [SITE]: { ...changedFormFields[SITE], value: [] }
      })
    }
    if (fieldName === ORGANIZATION) {
      setFormFields({ ...changedFormFields, [SITE]: { ...changedFormFields[SITE], value: [] } })
    }

    if (fieldName === ROLE && text === 'admin' && formFields[ENTITY].value === 'distributor') {
      //while creating new entry in db replace "viewer" to "user" as we are not changing role in db
      if (formFields[ROLE].value === 'viewer') {
        formFields[ROLE].value = 'user'
      }
      setFormFields({
        ...changedFormFields,
        [SITE]: { ...changedFormFields[SITE], value: [] },
        [ORGANIZATION]: { ...changedFormFields[ORGANIZATION], value: [] }
      })

    }

    if (fieldName === SITE && text === formFields[SITE].value) {
      setFormFields({ ...changedFormFields, [SITE]: { ...changedFormFields[SITE], value: [] } })
    }
    if (fieldName === ROLE) {
      setRenderUserRole(text)
    }
  }

  const handleCheckboxChange = (name) => {
    setFormFields({
      ...formFields,
      [name]: { ...formFields[name], value: !formFields[name].value }
    })
  }
  useEffect(() => {

    const entity = dashboardUser.entity ? dashboardUser.entity.name : DEFAULT_ENTITY
    const role = dashboardUser.role ? dashboardUser.role.name : getDefaultRole(currentDashboardUser.entity.name, entity)
    setFormFields(
      (formFields) => ({
        [FIRST_NAME]: { ...formFields[FIRST_NAME], value: dashboardUser[FIRST_NAME] || '' },
        [LAST_NAME]: { ...formFields[LAST_NAME], value: dashboardUser[LAST_NAME] || '' },
        [PHONE_NUMBER]: { ...formFields[PHONE_NUMBER], value: dashboardUser[PHONE_NUMBER] || '' },
        [EMAIL]: { ...formFields[EMAIL], value: dashboardUser[EMAIL] || '' },
        [ORGANIZATION]: { ...formFields[ORGANIZATION], value: getOrganizationIds(dashboardUser, currentDashboardUser) },
        [ROLE]: { ...formFields[ROLE], value: role },
        [DISTRIBUTOR]: { ...formFields[DISTRIBUTOR], value: getDistributorId(dashboardUser, currentDashboardUser) },
        [SITE]: { ...formFields[SITE], value: getSiteIds(dashboardUser, currentDashboardUser) },
        [POSITION]: { ...formFields[POSITION], value: dashboardUser.position || '' },
        [ENTITY]: { ...formFields[ENTITY], value: entity },
        [DEFAULT_PAGE]: { ...formFields[DEFAULT_PAGE], value: dashboardUser[DEFAULT_PAGE] || '/dashboard' },
        [FULL_SCREEN]: { ...formFields[FULL_SCREEN], value: dashboardUser[FULL_SCREEN] || false },
        [TWO_FA]: { ...formFields[TWO_FA], value: dashboardUser[TWO_FA] },
      }))
    setRenderUserRole(role)
  }, [dashboardUser, isClosed]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [])
  return (
    <GridContainer id="content-pane-layout" justify="center">
      <GridItem md={9}>
        <Card style={{ boxShadow: 'none' }}>
          <CardBody>
            <div>
              <form>
                <GridContainer
                  direction="column"
                  justify="center"
                  alignItems="center"
                  id="card-body-section"
                >
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="editProfileFirstName"
                      error={
                        formFields[FIRST_NAME + VALIDATION_STATE] === 'error'
                      }
                      labelText={`${t('First name')}`}
                      inputProps={{
                        value: formFields[FIRST_NAME].value,
                        type: 'name',
                        autoComplete: 'name',
                        onChange: event => {
                          handleTextChange(event, FIRST_NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="editProfileLastName"
                      error={
                        formFields[LAST_NAME + VALIDATION_STATE] === 'error'
                      }
                      labelText={`${t('Last name')}`}
                      inputProps={{
                        value: formFields[LAST_NAME].value,
                        type: 'name',
                        autoComplete: 'name',
                        onChange: event => {
                          handleTextChange(event, LAST_NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="editProfileEmail"
                      error={
                        formFields[EMAIL + VALIDATION_STATE] === 'error'
                      }
                      labelText={`${t('Email')} *`}
                      greyBackground={isEdit}
                      style={{ color: 'grey' }}
                      inputProps={{
                        value: formFields[EMAIL].value,
                        type: 'name',
                        autoComplete: 'name',
                        onChange: event => {
                          handleTextChange(event, EMAIL)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true,
                        disabled: isEdit
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <PhoneInput
                      labelText={t('Phone number')}
                      value={formFields[PHONE_NUMBER].value}
                      id="dashboardUserPhoneInput"
                      inputComponent={
                        (() => {
                          return React.forwardRef(function (props, r) {
                            return (
                              <CustomInput
                                labelText={t('Phone number')}
                                inputProps={{
                                  ...props,
                                  inputRef: r
                                }}
                                formControlProps={{
                                  fullWidth: true
                                }}
                              />
                            )
                          })
                        })()
                      }
                      onChange={(phoneNumber) => {
                        setFormFields({
                          ...formFields,
                          [PHONE_NUMBER]: { ...formFields[PHONE_NUMBER], value: phoneNumber }
                        })
                      }}
                    />

                  </GridItem>

                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="editProfilePositionCustomInput"
                      labelText={t('Position')}
                      inputProps={{
                        value: formFields[POSITION].value,
                        onChange: event => {
                          handleTextChange(event, POSITION)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  {currentDashboardUser.entity.name === 'neo' || currentDashboardUser.entity.name === 'distributor' || currentDashboardUser.entity.name === 'organization'
                    ? <GridItem style={{
                      minWidth: '40vh',
                      margin: '0 0 17px 0',
                      paddingTop: '27px',
                      position: 'relative',
                      verticalAlign: 'unset'
                    }}
                    >
                      <FormControl
                        fullWidth
                      >
                        <InputLabel
                          style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                        >
                          {`${t('Related to entity')} *`}
                        </InputLabel>
                        <Select
                          id="editDashboardUserEntitySelect"
                          style={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#495057',
                            ...(isEdit && currentDashboardUser.entity.name !== 'neo' && currentDashboardUser.id === dashboardUser.id ? greyBackground : null)

                          }}
                          MenuProps={{
                            className: classes.selectMenu,
                            id: 'editDashboardUserRole'
                          }}
                          inputProps={{
                            value: formFields[ENTITY].value,
                            onChange: event => {
                              handleTextChange(event, ENTITY)
                            },
                            disabled: isEdit && currentDashboardUser.entity.name !== 'neo' && currentDashboardUser.id === dashboardUser.id
                          }}

                        >
                          {
                            ENTITIES[currentDashboardUser.entity.name].map(entity => {
                              return (
                                <MenuItem
                                  id={`${entity.name}MenuItem`}
                                  key={entity}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={entity}
                                >
                                  {t(toCapitalize(entity))}
                                </MenuItem>
                              )
                            })

                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    : null}

                  {
                    (formFields[ENTITY].value === 'distributor' || formFields[ENTITY].value === 'organization' || formFields[ENTITY].value === 'site') && currentDashboardUser.entity.name === 'neo'
                      ? <GridItem style={{
                        width: '40vh',
                        margin: '0 0 17px 0',
                        paddingTop: '27px',
                        position: 'relative',
                        verticalAlign: 'unset'
                      }}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            id="editProfileOrganization"
                          >
                            {`${t('Related to distributor')} *`}
                          </InputLabel>
                          <Select
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}

                            id="editDashboardUserDistributorSelect"
                            input={<Input/>}
                            renderValue={(selected) => distributors[formFields[DISTRIBUTOR].value]}
                            value={formFields[DISTRIBUTOR].value}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              id: 'editDashboardUserDistributorInput',
                              onChange: event => {
                                handleTextChange(event, DISTRIBUTOR)
                              }
                            }}
                          >
                            {
                              Object.keys(distributors).map(item => {
                                return (
                                  <MenuItem
                                    id={`${item}MenuItem`}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    key={item}
                                    value={item}
                                  >
                                    <Checkbox
                                      id="editProfileOrganizationSelectCheckbox"
                                      checkedIcon={<Check
                                        className={classes.checkedIcon}
                                        style={{ color: INFO_COLOR }}
                                      />}
                                      icon={<Check
                                        className={classes.uncheckedIcon}
                                      />}
                                      checked={formFields[DISTRIBUTOR].value === item}
                                    />
                                    <ListItemText primary={distributors[item]}/>
                                  </MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </GridItem>
                      : null
                  }
                  {currentDashboardUser.entity.name !== 'organization' && currentDashboardUser.entity.name !== 'site' && (formFields[ENTITY].value === 'organization' || formFields[ENTITY].value === 'site' || (formFields[ENTITY].value === 'distributor' && (formFields[ROLE].value === 'user' || formFields[ROLE].value === 'technician'))) && formFields[DISTRIBUTOR].value
                    ? <GridItem style={{
                      width: '40vh',
                      margin: '0 0 17px 0',
                      paddingTop: '27px',
                      position: 'relative',
                      verticalAlign: 'unset'
                    }}
                    >
                      <FormControl fullWidth>
                        <InputLabel
                          style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          id="editProfileOrganization"
                        >
                          {`${t('Organization Name')} *`}
                        </InputLabel>
                        <Select
                          style={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#495057',
                            ...(isEdit && currentDashboardUser.entity.name !== 'neo' && currentDashboardUser.id === dashboardUser.id ? greyBackground : null)
                          }}
                          id="editProfileOrganizationSelect"
                          multiple={formFields[ENTITY].value === 'distributor'}
                          input={<Input/>}
                          renderValue={
                            formFields[ENTITY].value === 'distributor'
                              ? (selected) => {
                                const names = selected.map(id => organizations[id].name)
                                return names.join(', ')
                              }
                              : (selected) => organizations[selected].name
                          }
                          value={formFields[ORGANIZATION].value}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          inputProps={{
                            onChange: event => {
                              handleTextChange(event, ORGANIZATION)
                            },
                            disabled: isEdit && currentDashboardUser.entity.name !== 'neo' && currentDashboardUser.id === dashboardUser.id
                          }}
                        >
                          {
                            Object.keys(organizations).map(item => {
                              if (organizations[item].distributorId !== formFields[DISTRIBUTOR].value) return null

                              return (
                                <MenuItem
                                  id={`organizationItem${organizations[item].name}`}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={item}
                                  value={item}
                                >
                                  <Checkbox
                                    id="editProfileOrganizationSelectCheckbox"
                                    checkedIcon={<Check
                                      className={classes.checkedIcon}
                                      style={{ color: INFO_COLOR }}
                                    />}
                                    icon={<Check
                                      className={classes.uncheckedIcon}
                                    />}
                                    checked={formFields[ORGANIZATION].value.indexOf(item) > -1}
                                  />
                                  <ListItemText primary={organizations[item].name}/>
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    : null}

                  {/* role */}
                  <GridItem style={{
                    minWidth: '40vh',
                    margin: '0 0 17px 0',
                    paddingTop: '27px',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <FormControl
                      fullWidth
                    >
                      <InputLabel
                        style={{
                          color: '#aaaaaa',
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                        id="editProfileRole"
                      >
                        {`${t('Role')} *`}
                      </InputLabel>
                      <Select
                        id="editProfileRoleSelect"
                        style={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#495057',
                          ...(isEdit && currentDashboardUser.entity.name !== 'neo' && currentDashboardUser.id === dashboardUser.id ? greyBackground : null)
                        }}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        inputProps={{
                          value: renderUserRole,
                          disabled: isEdit && currentDashboardUser.entity.name !== 'neo' && currentDashboardUser.id === dashboardUser.id,
                          onChange: event => {
                            handleTextChange(event, ROLE)
                          }
                        }}
                      >
                        {
                          ROLES[currentDashboardUser.entity.name][currentDashboardUser.id === dashboardUser.id ? 'me' : formFields[ENTITY].value].map(roleName => {
                            //changing user role to viewer role only in frontend
                            if (roleName === 'user') {
                              roleName = 'viewer'
                            }
                            return (
                              <MenuItem
                                id={`menuItem${roleName}`}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={roleName}
                                value={roleName}
                              >
                                {t(roleName.charAt(0).toUpperCase() + roleName.slice(1))}
                              </MenuItem>
                            )
                          })

                        }
                      </Select>
                    </FormControl>
                  </GridItem>

                  {(formFields[ENTITY].value === 'site' || (formFields[ENTITY].value === 'distributor' && (formFields[ROLE].value === 'user' || formFields[ROLE].value === 'technician'))) && formFields[DISTRIBUTOR].value && formFields[ORGANIZATION].value && formFields[ORGANIZATION].value.length
                    ? <GridItem style={{
                      width: '40vh',
                      margin: '0 0 17px 0',
                      paddingTop: '27px',
                      position: 'relative',
                      verticalAlign: 'unset'
                    }}
                    >
                      <FormControl fullWidth>
                        <InputLabel
                          style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          id="editProfileSite"
                        >
                          {`${t('Site')} ${formFields[ENTITY].value === 'site' ? '*' : ''}`}
                        </InputLabel>
                        <Select
                          style={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#495057',
                            ...(isEdit && currentDashboardUser.entity.name !== 'neo' && currentDashboardUser.id === dashboardUser.id ? greyBackground : null)
                          }}
                          id="editProfileSiteSelect"
                          multiple={formFields[ENTITY].value === 'distributor' || (formFields[ENTITY].value === 'organization' && formFields[ROLE].value === 'admin')}
                          input={<Input/>}
                          renderValue={
                            formFields[ENTITY].value === 'distributor' || (formFields[ENTITY].value === 'organization' && formFields[ROLE].value === 'admin') || Array.isArray(formFields[SITE].value)
                              ? (selected) => {
                                const names = selected.map(id => sites[id].name)
                                return names.join(', ')
                              }
                              : (selected) => sites[selected] && sites[selected].name
                          }
                          value={formFields[SITE].value}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          inputProps={{
                            onChange: event => {
                              handleTextChange(event, SITE)
                            },
                            disabled: isEdit && currentDashboardUser.entity.name !== 'neo' && currentDashboardUser.id === dashboardUser.id
                          }}
                        >
                          {
                            Object.keys(filterSitesByOrganizations(sites, formFields[ORGANIZATION].value)).map(siteId => {
                              return (
                                <MenuItem
                                  id={siteId}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={siteId}
                                  key={siteId}
                                >
                                  <Checkbox
                                    id="editProfilesiteSelectCheckbox"
                                    checkedIcon={<Check
                                      className={classes.checkedIcon}
                                      style={{ color: INFO_COLOR }}
                                    />}
                                    icon={<Check
                                      className={classes.uncheckedIcon}
                                    />}
                                    checked={formFields[SITE].value.indexOf(siteId) > -1}
                                  />
                                  <ListItemText primary={sites[siteId].name}/>
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    : null}
                  {/* default page */}
                  <GridItem style={{ minWidth: '40vh' }}>
                    <FormControl
                      fullWidth
                    >
                      <InputLabel style={{
                        color: '#aaaaaa',
                        fontSize: '14px',
                        fontWeight: 400
                      }}
                      >
                        {t('Default page')}
                      </InputLabel>
                      <Select
                        id="defaultPageSelect"
                        value={formFields[DEFAULT_PAGE].value}
                        onChange={e => handleTextChange(e, DEFAULT_PAGE)}
                      >
                        {
                          Object.keys(defaultPageObject).map(key => {
                            return (
                              <MenuItem
                                value={defaultPageObject[key]}
                                key={key}
                              >
                                {t(key)}
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </GridItem>
                  {/* full screen page */}
                  <GridItem style={{ minWidth: '40vh', margin: '16px 0 0px -36px', display: 'flex' }}>
                    <Checkbox
                      id="adminEditUnitEnableVisualErrorCheckbox"
                      tabIndex={-1}
                      onChange={() => handleCheckboxChange(FULL_SCREEN)}
                      checkedIcon={
                        <Check
                          className={classes.checkedIcon}
                          style={{ color: INFO_COLOR }}
                        />
                      }
                      icon={<Check className={classes.uncheckedIcon}/>}
                      checked={formFields[FULL_SCREEN].value}
                      style={{ marginLeft: '9px' }}
                    />
                    <div
                      style={{ marginTop: '8px' }}
                    >{t('Full Screen Page')}
                    </div>
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh', margin: '16px 0 0px -36px', display: 'flex' }}>
                    <Checkbox
                      id="adminEditUnitEnableTwoFACheckbox"
                      tabIndex={-1}
                      onChange={() => handleCheckboxChange(TWO_FA)}
                      checkedIcon={
                        <Check
                          className={classes.checkedIcon}
                          style={{ color: INFO_COLOR }}
                        />
                      }
                      icon={<Check className={classes.uncheckedIcon}/>}
                      checked={formFields[TWO_FA].value}
                      style={{ marginLeft: '9px' }}
                    />
                    <div
                      style={{ marginTop: '8px' }}
                    >{t('Enable 2FA')}
                    </div>
                  </GridItem>
                </GridContainer>
                {/* save button */}
                <GridContainer
                  justify="center"
                  alignItems="center"
                  id="card-footer-section"
                >
                  <GridItem>
                    <LoadingButton
                      color="info"
                      onClick={handleSaveBtnClick}
                      id="editProfileSaveButton"
                      fullWidth
                      disabled={isAwaitingResponse}
                      isLoading={isAwaitingResponse}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </GridItem>
                </GridContainer>
              </form>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

EditDashboardUser.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withTranslation()(EditDashboardUser))))
