import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { getVersion } from '../../endpoint-requests/version/fetchVersion'
import clientVersion from '../../version.json'

const Version = ({
  t
}) => {
  const [version, setVersion] = useState(0)
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(true)

  useEffect(() => {
    (async () => {
      const version = await getVersion()
      setVersion(version)
      setIsAwaitingResponse(false)
    })()
  }, [])

  return (
    isAwaitingResponse
      ? <CircularProgress
        color='primary'
        style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      : <div id='versionContainer' style={{ textAlign: 'center', fontSize: '20px' }}>
        <p>{t('Client version')}: {clientVersion.version}</p>
        <p>{t('Server version')}: {version}</p>
      </div>
  )
}

export default withTranslation()(Version)
