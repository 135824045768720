export default location => {
  if (!location) return 'No machines chosen'
  const { site, internalArea, siteCity } = location
  return `${site || ''}${internalArea ? ' -  ' + internalArea : ''}${siteCity ? ' -  ' + siteCity : ''}`
}


export const formatMachineBtnText = (machines) => {
  const selectedMachines = machines.filter((machine) => machine !== undefined)
  return selectedMachines.length > 0 ? selectedMachines.length : 0
}

