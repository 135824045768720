import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Check from '@material-ui/icons/Check'
import StayPrimaryLandscape from '@material-ui/icons/StayPrimaryLandscape'
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait'
import Settings from '@material-ui/icons/Settings'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import formStyle from '../../../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../components-soapy/LoadingButton'
import SweetAlert from '../../../components-soapy/SweetAlert.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CustomInput from '../../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import useFormFields from '../../../shared/hooks/useFormFields.js'
import validateFormFields from '../../../utils/validateFormFields.js'
import ProceedModal from '../../../components-soapy/ProceedModal.jsx'
import {
  ADDITIONAL_WATER_TIME,
  AFTER_WASH_DELAY,
  ATP_FINISH_DATE,
  ATP_MODE,
  AUTOMATIC_SOAP_PURGE,
  DB_TIME_ZONE,
  DEBUG_HANDS_RECOGNITION,
  DEBUG_MODE,
  DELAY_TIME_PER_WATER_CYCLE,
  ENABLE_VISUAL_ERROR,
  HANDS_RECOGNITION,
  INSTALLATION_DATE,
  INTERNAL_AREA,
  LATHER_TIME,
  LED_PROGRESS_BAR,
  MONITOR,
  MONITOR_ORIENTATION,
  NUMBER_OF_UNIT,
  PRODUCT_NAME_VERSION,
  PRODUCT_NAME_VERSION_VALUES,
  PUBLIC_PLACES_SUPPORT,
  PURGE_MINUTES_AFTER_LAST_WASH,
  PURGE_PRIME_REAGENT_SECONDS,
  PURGE_REPEAT_TIMES,
  REAGENT,
  REAGENT_BOTTLE_ID,
  REAGENT_NAME,
  RECOGNITION,
  SERIAL_NUMBER,
  SHOW_MULTIPLE_UNIT_INPUT,
  SOAP_LOADING_TIME,
  SOAP_TIME,
  SOAP_USE,
  SOAP_WASTE,
  STOP_CYCLE_SUPPORT,
  TEMPERATURE_OFFSET,
  TEMPERATURE_SUPPORT,
  TIME_BETWEEN_SOAP_AND_WATER,
  TYPE,
  TYPE_VALUES,
  WASH_START_SOAP_WATER,
  WATER_CYCLE,
  WATER_TIME_DURING_SOAP,
  WATER_TIME_PER_WATER_CYCLE,
  WATER_USE,
  WATER_WASTE,
  IS_SOAP_PURGE,
  IS_WATER_PURGE,
} from '../constants.js'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { popUpSuccess } from '../../../redux/popUpReducer'
import UnitOrientationModal from './UnitOrientationModal.js'
import { unitMonitorOrientationSuccess } from '../../../redux/unitConfigurationReducer.js'

const VALIDATION_STATE = 'ValidationState'

const reagentNameObject = {
  '': '',
  soapyLore: 'Soapy Lore',
  pgSoap: 'P&G Soap',
}

const sanitizerNameObject = {
  '': '',
  septolFormula: 'Septol Formula',
}

const handsRecognitionObject = {
  'Hand Recognition Local': 'local',
  Off: 'off'
}

const INFO_COLOR = '#00acc1'
const unitConfigurationCheckboxText = { marginTop: '8px' }

const defaultFormFields = {
  [INSTALLATION_DATE]: {
    value: ''
  },
  [TYPE]: {
    value: ''
  },
  [REAGENT]: {
    value: ''
  },
  [DB_TIME_ZONE]: {
    value: ''
  },
  [INTERNAL_AREA]: {
    value: ''
  },
  [WATER_USE]: {
    value: ''
  },
  [WATER_WASTE]: {
    value: ''
  },
  [SOAP_USE]: {
    value: ''
  },
  [SOAP_WASTE]: {
    value: ''
  },
  [SERIAL_NUMBER]: {
    value: ''
  },
  [PRODUCT_NAME_VERSION]: {
    value: '',
    isRequired: true,
    name: 'Product name and version'
  },
  [SOAP_TIME]: {
    value: 0.15
  },
  [WATER_TIME_DURING_SOAP]: {
    value: ''
  },
  [LATHER_TIME]: {
    value: ''
  },
  [WATER_CYCLE]: {
    value: ''
  },
  [WATER_TIME_PER_WATER_CYCLE]: {
    value: ''
  },
  [DELAY_TIME_PER_WATER_CYCLE]: {
    value: ''
  },
  [SOAP_LOADING_TIME]: {
    value: ''
  },
  [TIME_BETWEEN_SOAP_AND_WATER]: {
    value: ''
  },
  [AFTER_WASH_DELAY]: {
    value: ''
  },
  [ADDITIONAL_WATER_TIME]: {
    value: ''
  },
  [TEMPERATURE_OFFSET]: {
    value: ''
  },
  [DEBUG_MODE]: {
    value: false
  },
  [DEBUG_HANDS_RECOGNITION]: {
    value: false
  },
  [TEMPERATURE_SUPPORT]: {
    value: false
  },
  [MONITOR]: {
    value: false
  },
  [REAGENT_BOTTLE_ID]: {
    value: false
  },
  [LED_PROGRESS_BAR]: {
    value: false
  },
  [ENABLE_VISUAL_ERROR]: {
    value: false
  },
  [AUTOMATIC_SOAP_PURGE]: {
    value: false
  },
  [ATP_MODE]: {
    value: false
  },
  [SHOW_MULTIPLE_UNIT_INPUT]: {
    value: false
  },
  [REAGENT_NAME]: {
    value: ''
  },
  [HANDS_RECOGNITION]: {
    value: ''
  },
  [RECOGNITION]: {
    value: ''
  },
  [NUMBER_OF_UNIT]: {
    value: 1
  },
  [MONITOR_ORIENTATION]: {
    value: 'horizontal'
  },
  [WASH_START_SOAP_WATER]: {
    value: 'soap-water'
  },
  [PUBLIC_PLACES_SUPPORT]: {
    value: false
  },
  [PURGE_MINUTES_AFTER_LAST_WASH]: {
    value: 0
  },
  [PURGE_PRIME_REAGENT_SECONDS]: {
    value: 0
  },
  [PURGE_REPEAT_TIMES]: {
    value: 0
  },
  [STOP_CYCLE_SUPPORT]: {
    value: false
  },
  [ATP_FINISH_DATE]: {
    value: null
  },
  [IS_SOAP_PURGE]: {
    value: false
  },
  [IS_WATER_PURGE]: {
    value: false
  },
}

let spinnerTimeout = null

function validNumber (value) {
  const rgx = /^[0-9]*\.?[0-9]*$/
  return value.match(rgx)
}

const MachineModal = ({
  t,
  machine,
  classes,
  handleSubmit,
  showErrorNotification,
  timeZoneNames,
  isEdit = false,
  isBulkEdit,
  openPurgeModal
}) => {
  const unitCampaignActive = useSelector((state) => state.unitConfiguration.unitCampaignActive)
  const unitMonitorOrientation = useSelector((state) => state.unitConfiguration.unitMonitorOrientation)
  const open = useSelector((state) => state.popUp.open)
  const dispatch = useDispatch()
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false)
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [isSubmitSuccessfulModalActive, setIsSubmitSuccessfulModalActive] = useState(false)
  const { formFields, setFormFields } = useFormFields(_.cloneDeep(defaultFormFields))
  const faceRecognitionObject = {
    ...((!formFields[HANDS_RECOGNITION].value || formFields[HANDS_RECOGNITION].value === 'off')
        ? {
          'Internal Camera': 'internal'
        } : null
    ),
    ...((formFields[HANDS_RECOGNITION].value === 'local')
        ? {
          'Card identification with face positioning': 'card-face-positioning'
        } : null
    ),
    'External Camera': 'external',
    'Card identification': 'card-identification',
    'External camera with face positioning': 'external-positioning',
    Off: 'off'
  }

  const reagentObject = {
    soap: t('Soap'),
    sanitizer: t('Sanitizer')
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const handleTextChange = (event, fieldName, isNumber = false) => {
    const value = event.target.value
    if (isNumber && !validNumber(value)) return
    if (unitCampaignActive && fieldName === 'monitorOrientation') {
      if (unitMonitorOrientation !== event.target.value) {
        dispatch(popUpSuccess(true))
        setFormFields({
          ...formFields,
          [fieldName]: { ...formFields[fieldName], value: event.target.value },
          [fieldName + VALIDATION_STATE]: ''
        })
        dispatch(unitMonitorOrientationSuccess(event.target.value))
      } else {

        setFormFields({
          ...formFields,
          [fieldName]: { ...formFields[fieldName], value: event.target.value },
          [fieldName + VALIDATION_STATE]: ''
        })
        dispatch(unitMonitorOrientationSuccess(event.target.value))

      }
    } else {

      let formFieldsObj = {
        ...formFields,
        [fieldName]: { ...formFields[fieldName], value: event.target.value },
        [fieldName + VALIDATION_STATE]: ''
      }

      if (fieldName === REAGENT) {
        formFieldsObj[REAGENT_NAME].value = event.target.value === 'sanitizer' ? 'septolFormula' : 'soapyLore'
      }

      setFormFields(formFieldsObj)
      fieldName === 'monitorOrientation' && dispatch(unitMonitorOrientationSuccess(event.target.value))

    }
  }

  const handleDateChanged = (event, fieldName) => {
    setFormFields({
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value: Datetime.moment(event.target.value, 'YYYY-MM-DD') }
    })
  }

  const handleCheckboxChange = (fieldName) => {
    setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: !formFields[fieldName].value } })
  }

  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  useEffect(() => {
    if (machine) {

      const editedFormFields = _.cloneDeep(defaultFormFields)
      for (const key in editedFormFields) {
        if (key === INSTALLATION_DATE && machine[key] !== undefined) {
          editedFormFields[key].value = Datetime.moment(machine[key])
          continue
        }
        if (key === INSTALLATION_DATE && machine[key] !== undefined) {
          editedFormFields[key].value = Datetime.moment(machine[key])
          continue
        }
        editedFormFields[key].value = machine[key] !== undefined ? machine[key] : editedFormFields[key].value
      }
      if (isBulkEdit || isEdit) {
        editedFormFields[PRODUCT_NAME_VERSION].isRequired = false
      }
      setFormFields(editedFormFields)
    } else {
      const editedFormFields = _.cloneDeep(defaultFormFields)
      if (isBulkEdit || isEdit) {
        editedFormFields[PRODUCT_NAME_VERSION].isRequired = false
      }
      setFormFields(editedFormFields)
    }
  }, [machine]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      disableSpinner()
    }
  }, [machine, dispatch])

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const processForm = async (proceed = false) => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      try {
        if (!proceed && isBulkEdit) {
          return setIsProceedModalOpen(true)
        }
        activateSpinner()
        await handleSubmit(formFields)
        disableSpinner()
      } catch (err) {
        showErrorNotification(err.message)
        disableSpinner()
      }
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  return (
    <GridContainer id="adminEditUnitContainer" justify="center">
      <ProceedModal
        isOpen={isProceedModalOpen}
        onApply={() => {
          setIsProceedModalOpen(false)
          processForm(true)
        }}
        onClose={() => setIsProceedModalOpen(true)}
        msg={t('Bulk editing mode will save all parameters as defined in current step. Changes will apply to all fields!')}
      />
      {
        open === true &&

        <UnitOrientationModal processForm={processForm} setFormFields={setFormFields} formFields={formFields}/>
      }

      <Card style={{ boxShadow: 'none' }}>
        <CardBody>
          <div>
            <form>
              <Grid container spacing={1000}>
                <Grid container item xs={400} spacing={1000}>
                  <Grid style={{ marginLeft: '13%' }} item xs={400}>
                    <div>
                      <div style={{ marginTop: '30px', marginBottom: '10px' }}>
                        <Typography align="center"><h5><b>General parameters</b></h5></Typography>
                      </div>
                      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                        <FormControl
                          fullWidth
                        >
                          <InputLabel style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          >
                            {t('Type')}
                          </InputLabel>
                          <Select
                            id="adminEditUnitList"
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              name: 'simpleSelect'
                            }}
                            value={formFields[TYPE].value}
                            onChange={event => {
                              handleTextChange(event, TYPE)
                            }}
                          >
                            {
                              TYPE_VALUES.map(item => {
                                return <MenuItem
                                  id="editMenuItem"
                                  key={item}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={item}
                                >
                                  {item}
                                </MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                        <FormControl
                          fullWidth
                        >
                          <InputLabel style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          >
                            {t('Reagent')}
                          </InputLabel>
                          <Select
                            id="adminEditUnitReagent"
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              name: 'simpleSelect'
                            }}
                            value={formFields[REAGENT].value}
                            onChange={event => {
                              handleTextChange(event, REAGENT)
                            }}
                          >
                            {
                              Object.keys(reagentObject).map(key => {
                                return <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={key}
                                  value={key}
                                >
                                  {reagentObject[key]}
                                </MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                        <FormControl
                          fullWidth
                        >
                          <InputLabel style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          >
                            {t('Reagent name')}
                          </InputLabel>
                          <Select
                            id="adminEditUnitReagentName"
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              name: 'simpleSelect'
                            }}
                            value={formFields[REAGENT_NAME].value}
                            onChange={event => {
                              handleTextChange(event, REAGENT_NAME)
                            }}
                          >
                            {
                              Object.keys(formFields[REAGENT].value === 'sanitizer' ?
                                sanitizerNameObject : reagentNameObject).map(key => {
                                return <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={key}
                                  value={key}
                                >
                                  {formFields[REAGENT].value === 'sanitizer' ?
                                    sanitizerNameObject[key] : reagentNameObject[key]}
                                </MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                        <FormControl
                          fullWidth
                        >
                          <InputLabel style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          >
                            {t('Hands recognition')}
                          </InputLabel>
                          <Select
                            id="adminEditUnitHandRecognition"
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              name: 'simpleSelect'
                            }}
                            value={formFields[HANDS_RECOGNITION].value}
                            onChange={event => {
                              handleTextChange(event, HANDS_RECOGNITION)
                            }}
                          >
                            {
                              Object.keys(handsRecognitionObject).map(key => {
                                return <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={key}
                                  value={handsRecognitionObject[key]}
                                >
                                  {key}
                                </MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                        <FormControl
                          fullWidth
                        >
                          <InputLabel style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          >
                            {t('Recognition')}
                          </InputLabel>
                          <Select
                            id="adminEditUnitRecognition"
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              name: 'simpleSelect'
                            }}
                            value={formFields[RECOGNITION].value}
                            onChange={event => {
                              handleTextChange(event, RECOGNITION)
                            }}
                          >
                            {
                              Object.keys(faceRecognitionObject).map(key => {
                                return <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={key}
                                  value={faceRecognitionObject[key]}
                                >
                                  {key}
                                </MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                      </GridItem>
                      {!isBulkEdit ? <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                          <FormControl
                            fullWidth
                          >
                            <InputLabel
                              required
                              style={{
                                color: '#aaaaaa',
                                fontSize: '14px',
                                fontWeight: 400
                              }}
                            >
                              {t('Product name and version')}*
                            </InputLabel>
                            <Select
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#495057'
                              }}
                              id="adminEditUnitProductNameSelect"
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              error={formFields[PRODUCT_NAME_VERSION + VALIDATION_STATE]}
                              inputProps={{
                                name: 'simpleSelect'
                              }}
                              disabled={!!formFields[SERIAL_NUMBER].value}
                              value={formFields[PRODUCT_NAME_VERSION].value}
                              onChange={event => {
                                handleTextChange(event, PRODUCT_NAME_VERSION)
                              }}
                            >
                              {
                                PRODUCT_NAME_VERSION_VALUES.map(item => {
                                  return <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    key={item}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                })
                              }
                            </Select>
                          </FormControl>
                        </GridItem>
                        : null}
                      <GridItem style={{ minWidth: '40vh', margin: '5px', marginTop: '-14px' }}>
                        <CustomInput
                          id="adminEditUnitInternalArea"
                          labelText={t('Internal area')}
                          inputProps={{
                            value: formFields[INTERNAL_AREA].value,
                            onChange: event => {
                              handleTextChange(event, INTERNAL_AREA)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem style={{ minWidth: '40vh', margin: '5px', marginTop: '-14px' }}>
                        <TextField
                          id="adminEditUnitInstallationDate"
                          value={formFields[INSTALLATION_DATE].value ? formFields[INSTALLATION_DATE].value.format('YYYY-MM-DD') : ''}
                          onChange={event => handleDateChanged(event, INSTALLATION_DATE)}
                          label={t('Installation date')}
                          type="date"
                          style={{ minWidth: '40vh' }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </GridItem>

                      <div style={{ marginTop: '30px', marginBottom: '10px' }}>
                        <Typography align="center"><h5><b>Features management</b></h5></Typography>
                      </div>
                      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                        <FormControl
                          fullWidth
                        >
                          <InputLabel
                            style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            htmlFor="simple-select"
                          >
                            {t('Time zone')}
                          </InputLabel>
                          <Select
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              name: 'simpleSelect'
                            }}
                            value={formFields[DB_TIME_ZONE].value}
                            onChange={event => {
                              handleTextChange(event, DB_TIME_ZONE)
                            }}
                            id="adminEditUnitTimezones"
                          >
                            {
                              timeZoneNames.map(item => {
                                return <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={item}
                                  value={item.slice(0, item.indexOf(' (UTC'))}
                                >
                                  {item}
                                </MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                      </GridItem>
                      {isBulkEdit ? <GridItem style={{ minWidth: '40vh', margin: '5px', marginTop: '10px' }}>
                          <FormControl
                            fullWidth
                          >
                            <InputLabel style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            >
                              {t('Temperature measurement')}
                            </InputLabel>
                            <Select
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#495057'
                              }}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              inputProps={{
                                name: 'simpleSelect'
                              }}
                              value={formFields[TEMPERATURE_SUPPORT].value}
                              onChange={event => {
                                handleTextChange(event, TEMPERATURE_SUPPORT)
                              }}
                            >
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={null}
                              />
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value
                              >
                                {t('Enabled')}
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={false}
                              >
                                {t('Disabled')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        : <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                          <Checkbox
                            tabIndex={-1}
                            id="temperatureSupportCheckbox"
                            onChange={() => handleCheckboxChange(TEMPERATURE_SUPPORT)}
                            checkedIcon={
                              <Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checked={formFields[TEMPERATURE_SUPPORT].value}
                            style={{ marginLeft: '9px' }}
                          />
                          <div
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              marginTop: '8px',
                              color: '#aaaaaa',
                              lineHeight: 1.42857
                            }}
                          >{t('Temperature measurement')}
                          </div>
                        </GridItem>}
                      {isBulkEdit ? <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                          <FormControl
                            fullWidth
                          >
                            <InputLabel style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            >
                              {t('Monitor support')}
                            </InputLabel>
                            <Select
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#495057'
                              }}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              inputProps={{
                                name: 'simpleSelect'
                              }}
                              value={formFields[MONITOR].value}
                              onChange={event => {
                                handleTextChange(event, MONITOR)
                              }}
                            >
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={null}
                              />
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value
                              >
                                {t('Enabled')}
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={false}
                              >
                                {t('Disabled')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        : <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                          <Checkbox
                            tabIndex={-1}
                            onChange={() => handleCheckboxChange(MONITOR)}
                            checkedIcon={
                              <Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checked={formFields[MONITOR].value}
                            style={{ marginLeft: '9px' }}
                          />
                          <div
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              marginTop: '8px',
                              color: '#aaaaaa',
                              lineHeight: 1.42857
                            }}
                          >{t('Monitor support')}
                          </div>
                        </GridItem>}
                      {isBulkEdit ? <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                          <FormControl
                            fullWidth
                          >
                            <InputLabel style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            >
                              {t('Bottle ID features')}
                            </InputLabel>
                            <Select
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#495057'
                              }}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              inputProps={{
                                name: 'simpleSelect'
                              }}
                              value={formFields[REAGENT_BOTTLE_ID].value}
                              onChange={event => {
                                handleTextChange(event, REAGENT_BOTTLE_ID)
                              }}
                            >
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={null}
                              />
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value
                              >
                                {t('Enabled')}
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={false}
                              >
                                {t('Disabled')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        : <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                          <Checkbox
                            tabIndex={-1}
                            onChange={() => handleCheckboxChange(REAGENT_BOTTLE_ID)}
                            checkedIcon={
                              <Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checked={formFields[REAGENT_BOTTLE_ID].value}
                            style={{ marginLeft: '9px' }}
                          />
                          <div
                            style={{ marginTop: '8px' }}
                          >{t('Bottle ID features')}
                          </div>
                        </GridItem>}
                      {isBulkEdit ? <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                          <FormControl
                            fullWidth
                          >
                            <InputLabel style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            >
                              {t('LED bar as wash progress indicator')}
                            </InputLabel>
                            <Select
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#495057'
                              }}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              inputProps={{
                                name: 'simpleSelect'
                              }}
                              value={formFields[LED_PROGRESS_BAR].value}
                              onChange={event => {
                                handleTextChange(event, LED_PROGRESS_BAR)
                              }}
                            >
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={null}
                              />
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value
                              >
                                {t('Enabled')}
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={false}
                              >
                                {t('Disabled')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        : <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                          <Checkbox
                            tabIndex={-1}
                            onChange={() => handleCheckboxChange(LED_PROGRESS_BAR)}
                            checkedIcon={
                              <Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checked={formFields[LED_PROGRESS_BAR].value}
                            style={{ marginLeft: '9px' }}
                          />
                          <div
                            style={unitConfigurationCheckboxText}
                          >{t('LED bar as wash progress indicator')}
                          </div>
                        </GridItem>}
                      {isBulkEdit ?
                        <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                          <FormControl
                            fullWidth
                          >
                            <InputLabel style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            >
                              {t('LED bar as error display')}
                            </InputLabel>
                            <Select
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#495057'
                              }}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              inputProps={{
                                name: 'simpleSelect'
                              }}
                              value={formFields[ENABLE_VISUAL_ERROR].value}
                              onChange={event => {
                                handleTextChange(event, ENABLE_VISUAL_ERROR)
                              }}
                            >
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={null}
                              />
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value
                              >
                                {t('Enabled')}
                              </MenuItem>
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={false}
                              >
                                {t('Disabled')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        :
                        <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                          <Checkbox
                            id="adminEditUnitEnableVisualErrorCheckbox"
                            tabIndex={-1}
                            onChange={() => handleCheckboxChange(ENABLE_VISUAL_ERROR)}
                            checkedIcon={
                              <Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checked={formFields[ENABLE_VISUAL_ERROR].value}
                            style={{ marginLeft: '9px' }}
                          />
                          <div
                            style={unitConfigurationCheckboxText}
                          >{t('LED bar as error display')}
                          </div>
                        </GridItem>}
                      {isBulkEdit ? <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                          <FormControl
                            fullWidth
                          >
                            <InputLabel style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            >
                              {t('Automatic priming')}
                            </InputLabel>
                            <Select
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#495057'
                              }}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              inputProps={{
                                name: 'simpleSelect'
                              }}
                              value={formFields[AUTOMATIC_SOAP_PURGE].value}
                              onChange={event => {
                                handleTextChange(event, AUTOMATIC_SOAP_PURGE)
                              }}
                            >
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={null}
                              />
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value
                              >
                                {t('Enabled')}
                              </MenuItem>
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={false}
                              >
                                {t('Disabled')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        : <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                          <Checkbox
                            id="adminEditUnitAuromaticSoapPurgeCheckbox"
                            tabIndex={-1}
                            onChange={() => handleCheckboxChange(AUTOMATIC_SOAP_PURGE)}
                            checkedIcon={
                              <Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checked={formFields[AUTOMATIC_SOAP_PURGE].value}
                            style={{ marginLeft: '9px' }}
                          />
                          <div
                            style={unitConfigurationCheckboxText}
                          >{t('Automatic priming')}
                          </div>
                          {formFields[AUTOMATIC_SOAP_PURGE].value && <Settings
                            style={{ cursor: 'pointer', margin: '5px' }}
                            onClick={() => openPurgeModal()}
                          />}
                        </GridItem>
                      }
                      {isBulkEdit ? <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                          <FormControl
                            fullWidth
                          >
                            <InputLabel style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            >
                              {t('Public places support')}
                            </InputLabel>
                            <Select
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#495057'
                              }}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              inputProps={{
                                name: 'simpleSelect'
                              }}
                              value={formFields[PUBLIC_PLACES_SUPPORT].value}
                              onChange={event => {
                                handleTextChange(event, PUBLIC_PLACES_SUPPORT)
                              }}
                            >
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={null}
                              />
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value
                              >
                                {t('Enabled')}
                              </MenuItem>
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={false}
                              >
                                {t('Disabled')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        : <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                          <Checkbox
                            id="adminEditUnitPublicPlacesSupportCheckbox"
                            tabIndex={-1}
                            onChange={() => handleCheckboxChange(PUBLIC_PLACES_SUPPORT)}
                            checkedIcon={
                              <Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checked={formFields[PUBLIC_PLACES_SUPPORT].value}
                            style={{ marginLeft: '9px' }}
                          />
                          <div
                            style={unitConfigurationCheckboxText}
                          >{t('Public places support')}
                          </div>
                        </GridItem>
                      }
                      {isBulkEdit ? <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                          <FormControl
                            fullWidth
                          >
                            <InputLabel style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            >
                              {t('Reset wash cycle')}
                            </InputLabel>
                            <Select
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#495057'
                              }}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              inputProps={{
                                name: 'simpleSelect'
                              }}
                              value={formFields[STOP_CYCLE_SUPPORT].value}
                              onChange={event => {
                                handleTextChange(event, STOP_CYCLE_SUPPORT)
                              }}
                            >
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={null}
                              />
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value
                              >
                                {t('Enabled')}
                              </MenuItem>
                              <MenuItem
                                id="editMenuItem"
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={false}
                              >
                                {t('Disabled')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        : <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                          <Checkbox
                            id="adminEditUnitPublicPlacesSupportCheckbox"
                            tabIndex={-1}
                            onChange={() => handleCheckboxChange(STOP_CYCLE_SUPPORT)}
                            checkedIcon={
                              <Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checked={formFields[STOP_CYCLE_SUPPORT].value}
                            style={{ marginLeft: '9px' }}
                          />
                          <div
                            style={unitConfigurationCheckboxText}
                          >{t('Stop cycle support')}
                          </div>
                        </GridItem>
                      }

                      <GridItem style={{ marginLeft: '3px' }}>
                        <div
                          style={classes.unitConfigurationHeader}
                        >{t('Monitor position')}
                        </div>
                        <RadioGroup
                          value={unitMonitorOrientation}
                          onChange={
                            (e) => handleTextChange(e, MONITOR_ORIENTATION)
                          }
                        >
                          <FormControlLabel
                            value="horizontal"
                            control={<Radio color={'primary'}/>}
                            label={<StayPrimaryLandscape/>
                            }/>
                          <FormControlLabel
                            value="vertical"
                            control={<Radio color={'primary'}/>}
                            label={<StayCurrentPortrait/>}/>
                        </RadioGroup>
                      </GridItem>
                    </div>
                    <div style={{ marginTop: '30px', marginBottom: '10px' }}>
                      <Typography align="center"><h5><b>ATP and troubleshooting options</b></h5></Typography>
                    </div>
                    {isBulkEdit ?
                      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                        <FormControl
                          fullWidth
                        >
                          <InputLabel style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          >
                            {t('Save face recognition pictures')}
                          </InputLabel>
                          <Select
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              name: 'simpleSelect'
                            }}
                            value={formFields[DEBUG_MODE].value}
                            onChange={event => {
                              handleTextChange(event, DEBUG_MODE)
                            }}
                          >
                            <MenuItem
                              id="editMenuItem"
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={null}
                            />
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value
                            >
                              {t('Enabled')}
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={false}
                            >
                              {t('Disabled')}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      :
                      <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                        <Checkbox
                          id="adminEditUnitDebugModeCheckbox"
                          tabIndex={-1}
                          onChange={() => handleCheckboxChange(DEBUG_MODE)}
                          checkedIcon={
                            <Check
                              className={classes.checkedIcon}
                              style={{ color: INFO_COLOR }}
                            />
                          }
                          icon={<Check className={classes.uncheckedIcon}/>}
                          checked={formFields[DEBUG_MODE].value}
                          style={{ marginLeft: '9px' }}
                        />
                        <div
                          style={unitConfigurationCheckboxText}
                        >{t('Save face recognition pictures')}
                        </div>
                      </GridItem>}
                    {isBulkEdit ?
                      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                        <FormControl
                          fullWidth
                        >
                          <InputLabel style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          >
                            {t('Save hands recognition videos')}
                          </InputLabel>
                          <Select
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              name: 'simpleSelect'
                            }}
                            value={formFields[DEBUG_HANDS_RECOGNITION].value}
                            onChange={event => {
                              handleTextChange(event, DEBUG_HANDS_RECOGNITION)
                            }}
                          >
                            <MenuItem
                              id="editMenuItem"
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={null}
                            />
                            <MenuItem
                              id="editMenuItem"
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value
                            >
                              {t('Enabled')}
                            </MenuItem>
                            <MenuItem
                              id="editMenuItem"
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={false}
                            >
                              {t('Disabled')}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      :
                      <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                        <Checkbox
                          id="adminEditUnitEnableVisualErrorCheckbox"
                          tabIndex={-1}
                          onChange={() => handleCheckboxChange(DEBUG_HANDS_RECOGNITION)}
                          checkedIcon={
                            <Check
                              className={classes.checkedIcon}
                              style={{ color: INFO_COLOR }}
                            />
                          }
                          icon={<Check className={classes.uncheckedIcon}/>}
                          checked={formFields[DEBUG_HANDS_RECOGNITION].value}
                          style={{ marginLeft: '9px' }}
                        />
                        <div
                          style={unitConfigurationCheckboxText}
                        >{t('Save hands recognition videos')}
                        </div>
                      </GridItem>}
                    {isBulkEdit ?
                      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
                        <FormControl
                          fullWidth
                        >
                          <InputLabel style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          >
                            {t('HCA mode')}
                          </InputLabel>
                          <Select
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              name: 'simpleSelect'
                            }}
                            value={formFields[ATP_MODE].value}
                            onChange={event => {
                              handleTextChange(event, ATP_MODE)
                            }}
                          >
                            <MenuItem
                              id="editMenuItem"
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={null}
                            />
                            <MenuItem
                              id="editMenuItem"
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value
                            >
                              {t('Enabled')}
                            </MenuItem>
                            <MenuItem
                              id="editMenuItem"
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={false}
                            >
                              {t('Disabled')}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      :
                      <GridItem style={{ minWidth: '40vh', marginLeft: '-16px', display: 'flex' }}>
                        <Checkbox
                          id="adminEditUnitAtpModeCheckbox"
                          tabIndex={-1}
                          onChange={() => handleCheckboxChange(ATP_MODE)}
                          checkedIcon={
                            <Check
                              className={classes.checkedIcon}
                              style={{ color: INFO_COLOR }}
                            />
                          }
                          icon={<Check className={classes.uncheckedIcon}/>}
                          checked={formFields[ATP_MODE].value}
                          style={{ marginLeft: '9px' }}
                        />
                        <div
                          style={unitConfigurationCheckboxText}
                        >{t('HCA mode')}
                        </div>
                      </GridItem>
                    }
                  </Grid>
                  <Grid style={{ marginLeft: '10%' }} item xs={400}>
                    <div>
                      <div style={{ marginTop: '30px', marginBottom: '10px' }}>
                        <Typography align="center"><h5><b>Resources management</b></h5></Typography>
                      </div>
                      {
                        !(formFields[REAGENT].value === 'sanitizer')
                          ? <GridItem style={{ minWidth: '40vh', marginTop: '-6px' }}>
                            <CustomInput
                              id="adminEditUnitWaterUse"
                              error={
                                formFields[WATER_USE + VALIDATION_STATE] === 'error'
                              }
                              labelText={`${t('Water use')} ${t('mL')}`}
                              inputProps={{
                                value: formFields[WATER_USE].value,
                                onChange: event => {
                                  handleTextChange(event, WATER_USE, true)
                                }
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                          : null
                      }

                      {
                        !(formFields[REAGENT].value === 'sanitizer')
                          ? <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                            <CustomInput
                              id="adminEditUnitWaterWaste"
                              error={
                                formFields[WATER_WASTE + VALIDATION_STATE] === 'error'
                              }
                              labelText={`${t('Water waste')} ${t('mL')}`}
                              inputProps={{
                                value: formFields[WATER_WASTE].value,
                                onChange: event => {
                                  handleTextChange(event, WATER_WASTE, true)
                                }
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                          : null
                      }
                      <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                        <CustomInput
                          id="adminEditUnitReagentUse"
                          error={
                            formFields[SOAP_USE + VALIDATION_STATE] === 'error'
                          }
                          labelText={`${t('Reagent use')} ${t('mL')}`}
                          inputProps={{
                            value: formFields[SOAP_USE].value,
                            onChange: event => {
                              handleTextChange(event, SOAP_USE, true)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                        <CustomInput
                          id="adminEditUnitReagentWaste"
                          error={
                            formFields[SOAP_WASTE + VALIDATION_STATE] === 'error'
                          }
                          labelText={`${t('Reagent waste')} ${t('mL')}`}
                          inputProps={{
                            value: formFields[SOAP_WASTE].value,
                            onChange: event => {
                              handleTextChange(event, SOAP_WASTE, true)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <div style={{ marginTop: '30px', marginBottom: '20px' }}>
                        <Typography align="center"><h5><b>Priming management</b></h5></Typography>
                      </div>

                      <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                        <CustomInput
                          id="adminEditUnitReagentLoadingTime"
                          labelText={`${t('Reagent loading time')} (${t('S')})`}
                          inputProps={{
                            value: formFields[SOAP_LOADING_TIME].value,
                            onChange: event => {
                              handleTextChange(event, SOAP_LOADING_TIME, true)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <Typography align="center"><h5><b>Wash sequence management</b></h5></Typography>
                      </div>
                      <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                        <div
                          style={classes.unitConfigurationHeader}
                        >{t('Wash Start')}
                        </div>
                        <RadioGroup
                          value={formFields[WASH_START_SOAP_WATER].value || 'water-soap'}
                          onChange={
                            (e) => handleTextChange(e, WASH_START_SOAP_WATER, true)
                          }
                        >
                          <FormControlLabel
                            value="water-soap"
                            control={<Radio color={'primary'}/>}
                            label={'Water then soap'}/>
                          <FormControlLabel
                            value="soap-water"
                            control={<Radio color={'primary'}/>}
                            label={'Soap then water'}/>
                        </RadioGroup>
                      </GridItem>

                      <GridItem style={{ minWidth: '40vh', marginTop: '-15px' }}>
                        <CustomInput
                          id="adminEditUnitReagentTime"
                          error={
                            formFields[SOAP_TIME + VALIDATION_STATE] === 'error'
                          }
                          labelText={`${t('Reagent time')} (${t('S')})`}
                          inputProps={{
                            value: formFields[SOAP_TIME].value,
                            onChange: event => {
                              handleTextChange(event, SOAP_TIME, true)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                        <CustomInput
                          id="adminEditUnitTimeBetweenSoapAndWater"
                          error={
                            formFields[TIME_BETWEEN_SOAP_AND_WATER + VALIDATION_STATE] === 'error'
                          }
                          labelText={`${t('Time between soap and water')} (${t('S')})`}
                          inputProps={{
                            value: formFields[TIME_BETWEEN_SOAP_AND_WATER].value,
                            onChange: event => {
                              handleTextChange(event, TIME_BETWEEN_SOAP_AND_WATER, true)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {!(formFields[REAGENT] === 'sanitizer')
                        ? <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                          <CustomInput
                            id="adminEditUnitWaterTimeDuringReagent"
                            error={
                              formFields[WATER_TIME_DURING_SOAP + VALIDATION_STATE] === 'error'
                            }
                            labelText={`${t('Water time during reagent')} (${t('S')})`}
                            inputProps={{
                              value: formFields[WATER_TIME_DURING_SOAP].value,
                              onChange: event => {
                                handleTextChange(event, WATER_TIME_DURING_SOAP, true)
                              }
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        : null}
                      <GridItem style={{ minWidth: '40vh', marginTop: '-19px' }}>
                        <CustomInput
                          error={
                            formFields[LATHER_TIME + VALIDATION_STATE] === 'error'
                          }
                          id="adminEditUnitLatherTime"
                          labelText={`${t('Lather time')} (${t('S')})`}
                          inputProps={{
                            value: formFields[LATHER_TIME].value,
                            onChange: event => {
                              handleTextChange(event, LATHER_TIME, true)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {
                        !(formFields[REAGENT].value === 'sanitizer')
                          ? <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                            <CustomInput
                              id="adminEditUnitWaterCycle"
                              error={
                                formFields[WATER_CYCLE + VALIDATION_STATE] === 'error'
                              }
                              labelText={t('Water cycle')}
                              inputProps={{
                                value: formFields[WATER_CYCLE].value,
                                onChange: event => {
                                  handleTextChange(event, WATER_CYCLE, true)
                                }
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                          : null
                      }
                      {
                        !(formFields[REAGENT].value === 'sanitizer')
                          ? <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                            <CustomInput
                              id="adminEditUnitWaterTimePerWaterCycle"
                              error={
                                formFields[WATER_TIME_PER_WATER_CYCLE + VALIDATION_STATE] === 'error'
                              }
                              labelText={`${t('Water time per water cycle')} (${t('S')})`}
                              inputProps={{
                                value: formFields[WATER_TIME_PER_WATER_CYCLE].value,
                                onChange: event => {
                                  handleTextChange(event, WATER_TIME_PER_WATER_CYCLE, true)
                                }
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                          : null
                      }
                      {
                        !(formFields[REAGENT].value === 'sanitizer')
                          ? <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                            <CustomInput
                              id="adminEditUnitDelayTimePerWaterCycle"
                              error={
                                formFields[DELAY_TIME_PER_WATER_CYCLE + VALIDATION_STATE] === 'error'
                              }
                              labelText={`${t('Delay time per water cycle')} (${t('S')})`}
                              inputProps={{
                                value: formFields[DELAY_TIME_PER_WATER_CYCLE].value,
                                onChange: event => {
                                  handleTextChange(event, DELAY_TIME_PER_WATER_CYCLE, true)
                                }
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                          : null
                      }
                      {formFields[REAGENT].value === 'soap'
                        ? <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                          <CustomInput
                            id="adminEditUnitAdditionalWaterTime"
                            error={
                              formFields[ADDITIONAL_WATER_TIME + VALIDATION_STATE] === 'error'
                            }
                            labelText={`${t('Additional water time')} (${t('S')})`}
                            inputProps={{
                              value: formFields[ADDITIONAL_WATER_TIME].value,
                              onChange: event => {
                                handleTextChange(event, ADDITIONAL_WATER_TIME, true)
                              }
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> : null}
                      <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                        <CustomInput
                          id="adminEditUnitAfterWashDelay"
                          error={
                            formFields[AFTER_WASH_DELAY + VALIDATION_STATE] === 'error'
                          }
                          labelText={`${t('After wash delay')} (${t('S')})`}
                          inputProps={{
                            value: formFields[AFTER_WASH_DELAY].value,
                            onChange: event => {
                              handleTextChange(event, AFTER_WASH_DELAY, true)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem style={{ minWidth: '40vh', marginTop: '-23px' }}>
                        <CustomInput
                          labelText={t('Temperature offset')}
                          inputProps={{
                            value: formFields[TEMPERATURE_OFFSET].value,
                            onChange: event => {
                              handleTextChange(event, TEMPERATURE_OFFSET, true)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {!isBulkEdit
                        ? <div>
                          <GridItem style={{
                            minWidth: '40vh',
                            marginTop: '-23px',
                            margin: '-8px 0 0 -20px',
                            display: 'flex'
                          }}
                          >
                            <Checkbox
                              id="adminEditUnitMultipleUnitsCheckbox"
                              tabIndex={-1}
                              onChange={() => handleCheckboxChange(SHOW_MULTIPLE_UNIT_INPUT)}
                              checkedIcon={
                                <Check
                                  className={classes.checkedIcon}
                                  style={{ color: INFO_COLOR }}
                                />
                              }
                              icon={<Check className={classes.uncheckedIcon}/>}
                              checked={formFields[SHOW_MULTIPLE_UNIT_INPUT].value}
                              style={{ marginLeft: '9px' }}
                            />
                            <div
                              style={classes.unitConfigurationHeader}
                            >{t('Multiple units')}
                            </div>
                          </GridItem>
                          {formFields[SHOW_MULTIPLE_UNIT_INPUT].value
                            ? <GridItem style={{ minWidth: '40vh', marginTop: '-18px' }}>
                              <CustomInput
                                id="adminEditUnitNumberOfUnits"
                                labelText={t('Number Of Units')}
                                inputProps={{
                                  value: formFields[NUMBER_OF_UNIT].value,
                                  onChange: event => {
                                    handleTextChange(event, NUMBER_OF_UNIT, true)
                                  }
                                }}
                                formControlProps={{
                                  fullWidth: true
                                }}
                              />
                            </GridItem> : null}
                        </div>
                        : null}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <GridContainer
                justify="center"
                alignItems="center"
              >
                <GridItem>
                  <LoadingButton
                    id="adminUnitSaveButton"
                    color="info"
                    onClick={() => processForm()}
                    fullWidth
                    disabled={!isBulkEdit ? isAwaitingResponse : !!isAwaitingResponse}
                    isLoading={isAwaitingResponse}
                  >
                    {t('Save')}
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </form>
            <div>
              {isSubmitSuccessfulModalActive ? (
                <SweetAlert
                  id="savedAlert"
                  success
                  title={t('Saved') + '!'}
                  confirmBtnText={t('Ok')}
                  style={{
                    position: 'absolute'
                  }}
                  onConfirm={() => setIsSubmitSuccessfulModalActive(false)}
                  onCancel={() => setIsSubmitSuccessfulModalActive(false)}
                />
              ) : null}
            </div>
          </div>
        </CardBody>
      </Card>
    </GridContainer>
  )
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withTranslation()(MachineModal))))
