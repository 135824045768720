const getPaths = (routes, currentDashboardUser, isMobile = false) => {
  const permissions = currentDashboardUser.role?.permissions
  return routes.reduce((acc, route) => {
    if (route.collapse) {
      return {
        ...acc,
        ...getPaths(route.views, currentDashboardUser, isMobile),
      }
    }
    if (isMobile && !route.hasMobileView) {
      return acc
    }

    for (const requiredPermission of route?.requiredPermissions) {
      try {
        if (
          !permissions[(requiredPermission?.name)] ||
          !permissions[(requiredPermission?.name)][(requiredPermission?.action)]
        ) {
          return acc
        }
      } catch (e) {
        console.log(e)
      }
    }
    acc[route.layout + route.path] = route.component
    return acc
  }, {})
}

export default getPaths
