import { createSlice } from "@reduxjs/toolkit";

const championsSlice = createSlice({
    name: "champions",
    initialState: {
        champions: [],
        previousChampion: null,
        currentChampion: null,
        isFetching: false,
        error: false,
    },
    reducers: {

        championsStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        championsSuccess: (state, action) => {
            state.isFetching = false;
            state.champions = action.payload;
        },
        championsFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        previousChampionStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousChampionSuccess: (state, action) => {
            state.isFetching = false;
            state.previousChampion = action.payload;
        },
        previousChampionFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentChampionStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentChampionSuccess: (state, action) => {
            state.isFetching = false;
            state.currentChampion = action.payload;
        },
        currentChampionFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },

        championsLogoutSuccess: (state, action) => {
            state.isFetching = false;
            state.champions = [];


        },



    },
});

export const {
    championsStart,
    championsSuccess,
    championsFailure,
    championsLogoutSuccess,
    previousChampionStart,
    previousChampionSuccess,
    previousChampionFailure,
    currentChampionStart,
    currentChampionSuccess,
    currentChampionFailure,
} = championsSlice.actions;


const championsReducer = championsSlice.reducer; //it should be export default
export default championsReducer;