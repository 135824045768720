//checkIsToday
export const checkIsToday = (i, currentHour, previousFrom, previousTo, shifts) => {
    let isTodayFromHour = true
    let isTodayToHour = true
    const previousFromHour = previousFrom.split(':')[0];
    const previousToHour = previousTo.split(':')[0];

    if (i === 0 && shifts[0].split(":")[0] === "0") {
        if (currentHour < previousFromHour) {
            isTodayFromHour = false
        }
        return { isTodayFromHour, isTodayToHour }
    }
    if (i === 0 && shifts[0].split(":")[0] !== "0") {
        if (currentHour < previousFromHour) {
            isTodayFromHour = false
        }
        return { isTodayFromHour, isTodayToHour }
    }
    if (i === shifts.length - 1 && shifts[0].split(":")[0] !== "0") {
        if (currentHour < previousToHour) {
            isTodayToHour = false
        }
        if (currentHour < previousFromHour) {
            isTodayFromHour = false
        }
        return { isTodayFromHour, isTodayToHour }
    }
    return { isTodayFromHour, isTodayToHour }
}