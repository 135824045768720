import dashboardUserRequestKeywords from '../../shared/dashboard-user/request-keywords'
import axios from 'axios'
import failedSession from '../failedSession'
import { apiEndpoints, SERVER_PATH } from '../endpoints'

export default async (firstName, lastName, position, phoneNumber, defaultPage, fullScreen, twoFA) => {
  const endpoint = apiEndpoints.me
  const URL = SERVER_PATH + endpoint
  const requestBody = {
    [dashboardUserRequestKeywords.FIRST_NAME]: firstName,
    [dashboardUserRequestKeywords.LAST_NAME]: lastName,
    [dashboardUserRequestKeywords.POSITION]: position,
    [dashboardUserRequestKeywords.PHONE_NUMBER]: phoneNumber,
    [dashboardUserRequestKeywords.DEFAULT_PAGE]: defaultPage,
    [dashboardUserRequestKeywords.FULL_SCREEN]: fullScreen,
    [dashboardUserRequestKeywords.TWO_FA]: twoFA,
  }
  try {
    await axios.put(URL, requestBody, { withCredentials: true })
    return { didSucceed: true }
  } catch (e) {
    failedSession(e)
    if (e.response && e.response.data && e.response.data.errorType) {
      return { didSucceed: false, errorType: e.response.data.errorType }
    }
    if (e.response.status === 409) {
      return { didSucceed: false, errorType: 'EMAIL_ALREADY_EXISTS' }
    }
    return { didSucceed: false, errorType: 'SERVER_ERROR' }
  }
}
