import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import withAuthentication from '../../containers/Authentication/withAuthentication'
import withErrorNotification from '../../containers/withErrorNotification.js'
import { withTranslation } from 'react-i18next'
import Modal from '../../components-soapy/Modal.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import Card from '../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../components-soapy/LoadingButton.jsx'
import { InputBase, ListItemText, MenuItem, Select, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import addGroupModalStyle from '../../assets-soapy/jss/soapy/views/addGroupModalStyle'
import useFormFields from '../../shared/hooks/useFormFields.js'
import validateFormFields from '../../utils/validateFormFields.js'
import { verifyLength } from '../../utils/validators'
import { DESCRIPTION, NAME, ORGANIZATION_ID, VALIDATION_STATE } from './constants'
import { addGroupSuccess, editSelectedGroup } from '../../redux/groups/groupsReducer'
import addGroup from '../../endpoint-requests/groups/addGroup'
import editGroup from '../../endpoint-requests/groups/editGroup'
import { useStyles } from '../Shift/shiftStyles'
import { event } from 'react-ga'

let spinnerTimeout = null

const AddGroupModal = ({
  selectedGroup,
  isOpen,
  onClose,
  t,
  classes,
  showErrorNotification,
  isSuccessNotificationVisible,
  setIsSuccessNotificationVisible,
  currentDashboardUser,
  organizationsList,
}) => {
  const dispatch = useDispatch()
  const localClasses = useStyles()

  //-------------------Define Function for finding organization ID-----------------------//
  const getOrganizationId = (currentDashboardUser, selectedGroup) => {
    if (currentDashboardUser.entity.name === 'organization') {
      return currentDashboardUser.organizations && currentDashboardUser.organizations.length && currentDashboardUser.organizations[0].id
    } else {
      return selectedGroup?.organizationId
    }
  }
  //-------------------Define Variables for showing Organization DropDown Conditionally-----------------------//
  const { role, entity, organizations } = currentDashboardUser
  const isEdit = selectedGroup ? !!selectedGroup.id : false
  const roleName = role.name
  const entityName = entity.name
  const organizationId = organizations.length > 0 ? organizations[0].id : ''
  const isShowOrganizationDropDown = roleName === 'admin' && entityName === 'neo'

  //---------------------Setup Form Fields Values and Validation---------------------//
  const { formFields, setFormFields } = useFormFields({
    [NAME]: {
      value: '',
      isRequired: true,
      name: 'Name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 30),
          message: 'Name should not be empty, and up to 30 characters'
        }
      ]
    },
    [DESCRIPTION]: {
      value: '',
      isRequired: true,
      name: 'Description',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 100),
          message: 'Description should not be empty, and up to 100 characters'
        }
      ]
    },
    [ORGANIZATION_ID]: isShowOrganizationDropDown && { value: '', isRequired: true, name: 'Organization' }
  })

  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)

  //---------------------Setup Organization object with OrganizationId---------------------//
  const organizationsName = {}
  organizationsList.forEach(organization => {
    organizationsName[organization.id] = organization
  })

  //---------------------Change input field values---------------------//
  const handleTextChange = (event, fieldName) => {
    setFormFields({
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value: event.target.value },
      [fieldName + VALIDATION_STATE]: ''
    })
  }

  //---------------------Change DropDown Menu Values---------------------//
  const handleOrganizationChange = (event, value) => {
    if (value) {
      setFormFields({
        ...formFields,
        [ORGANIZATION_ID]: { ...formFields[ORGANIZATION_ID], value: value.id },
        [ORGANIZATION_ID + VALIDATION_STATE]: ''
      })
    }
  }

  useEffect(() => {
    setFormFields(
      (formFields) => ({
        [NAME]: { ...formFields[NAME], value: selectedGroup?.[NAME] || '' },
        [DESCRIPTION]: { ...formFields[DESCRIPTION], value: selectedGroup?.[DESCRIPTION] || '' },
        [ORGANIZATION_ID]: {
          ...formFields[ORGANIZATION_ID],
          value: getOrganizationId(currentDashboardUser, selectedGroup) || null
        },
      })
    )
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [selectedGroup, isOpen, isEdit, currentDashboardUser]) // eslint-disable-line react-hooks/exhaustive-deps

  //-------------------Function for show activate spinner-----------------------//
  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }

  //-------------------Function for diablespinner-----------------------//
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  //-------------------Function for create group-----------------------//
  const submitForm = async () => {
    const newGroupData = {
      [NAME]: formFields[NAME].value,
      [DESCRIPTION]: formFields[DESCRIPTION].value,
      [ORGANIZATION_ID]: isShowOrganizationDropDown ? formFields[ORGANIZATION_ID].value : organizationId,
    }
    activateSpinner()
    try {
      let newGroup
      if (isEdit) {
        newGroup = await editGroup(newGroupData, selectedGroup.id)
        dispatch(editSelectedGroup(newGroup))
      } else {
        newGroup = await addGroup(newGroupData)
        dispatch(addGroupSuccess(newGroup))
      }
      disableSpinner()
      setIsSuccessNotificationVisible(() => !isSuccessNotificationVisible)
      onClose()
    } catch (err) {
      disableSpinner()
      showErrorNotification(err.message)
    }
  }

  //-----------------This function run when we press save buttton---------------------//
  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  //----------------Function for Change Validation State To Error----------------------//
  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  //----------------Function for Save Button--------------------------//
  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    return () => {
      disableSpinner()
    }
  }, [])

  //-------------------Render whole add group modal-----------------------//
  return (
    <Modal
      dialogPaper={classes.dialogPaper}
      isOpen={isOpen}
      t={t}
      onCancel={() => {
        onClose()
        disableSpinner()
      }}
      showGoBackButton
      hideActions
      title={!selectedGroup || !Object.keys(selectedGroup).length ? t('Add Group') : t('Edit Group')}
    >
      <GridContainer id="content-pane-layout" justify="center">
        <GridItem md={9}>
          <Card style={{ boxShadow: 'none' }}>
            <CardBody>
              <GridContainer>
                <GridItem
                  md={4}
                  style={{
                    marginBottom: '2rem'
                  }}
                >
                  <Typography style={{ fontSize: '1.2rem' }}>{t('Name')}*</Typography>
                </GridItem>
                <GridItem
                  md={8}

                >
                  <InputBase
                    id="name"
                    style={{
                      width: '20rem'
                    }}
                    inputProps={{
                      className: classes.input
                    }}
                    className={formFields[NAME + VALIDATION_STATE] === 'error' ? classes.inputError : ''}
                    value={formFields[NAME].value}
                    onChange={event => {
                      handleTextChange(event, NAME)
                    }}
                  />
                </GridItem>
                <GridItem
                  md={4}
                  style={{
                    marginBottom: '2rem'
                  }}
                >
                  <Typography style={{ fontSize: '1.2rem' }}>{t('Description')}*</Typography>
                </GridItem>
                <GridItem
                  md={8}

                >
                  <InputBase
                    id="Description"
                    style={{
                      width: '20rem'
                    }}
                    inputProps={{
                      className: classes.input
                    }}
                    className={formFields[DESCRIPTION + VALIDATION_STATE] === 'error' ? classes.inputError : ''}
                    value={formFields[DESCRIPTION].value}
                    onChange={event => {
                      handleTextChange(event, DESCRIPTION)
                    }}
                  />
                </GridItem>
                {
                  isShowOrganizationDropDown ?
                    <>
                      <GridItem
                        md={4}
                        style={{
                          marginBottom: '2rem'
                        }}
                      >
                        <Typography style={{ fontSize: '1.2rem' }}>{t('Organization')}*</Typography>
                      </GridItem>
                      <GridItem
                        md={8}
                      >
                        <Select
                          variant="outlined"
                          value={organizationsName[formFields[ORGANIZATION_ID].value]
                            ? organizationsName[formFields[ORGANIZATION_ID].value]?.name
                            : ''}
                          style={{ width: '320px', fontSize: '14px', height: '45px' }}
                        >
                          {organizationsList.map((organization) => (
                            <MenuItem key={organization.id} value={organization.name}
                                      onClick={() => handleOrganizationChange(event, organization)}
                            >
                              <ListItemText
                                primary={organization.name}
                                localClasses={{ primary: localClasses.selectAllText }}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                    </>
                    : null
                }
              </GridContainer>
              <GridContainer
                justify="center"
                alignItems="center"
                id="card-footer-section"
              >
                <GridItem>
                  <LoadingButton
                    marginBtn
                    fullWidth
                    color="info"
                    type="submit"
                    id="groupAddSaveButton"
                    onClick={(e) => {
                      e.preventDefault()
                      handleSaveBtnClick()
                    }}
                    disabled={isAwaitingResponse}
                    isLoading={isAwaitingResponse}
                  >
                    {t('Save')}
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Modal>
  )
}

export default withErrorNotification(withAuthentication(withStyles(addGroupModalStyle)(withTranslation()(AddGroupModal))))
