import { createSlice } from "@reduxjs/toolkit";

const shiftsSlice = createSlice({
    name: "shifts",
    initialState: {
        shiftConfigurationData: null,
        previousFrom: "",
        previousTo: "",
        currentFrom: "",
        currentTo: "",
        shiftNumber: "",
        previousShift: "",
        previousShiftFromHour: "",
        previousShiftToHour: "",
        currentShift: "",
        currentShiftFromHour: "",
        currentShiftToHour: "",
        autoRefreshTime: 1,
        shiftData: null,
        hoursList: [],
        shiftList: [],
        isTodayFromHour: true,
        isTodayToHour: true,
        isFetching: false,
        error: false,
    },
    reducers: {
        shiftConfigurationDataStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        shiftConfigurationDataSuccess: (state, action) => {
            state.isFetching = false;
            state.shiftConfigurationData = action.payload;
        },
        shiftConfigurationDataFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
        },

        shiftNumberStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        shiftNumberSuccess: (state, action) => {
            state.isFetching = false;
            state.shiftNumber = action.payload;


        },
        shiftNumberFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },


        previousShiftStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousShiftSuccess: (state, action) => {
            state.isFetching = false;

            state.previousShift = action.payload;


        },
        previousShiftFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        previousShiftFromHourStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousShiftFromHourSuccess: (state, action) => {
            state.isFetching = false;
            state.previousShiftFromHour = action.payload;


        },
        previousShiftFromHourFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        previousShiftToHourStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousShiftToHourSuccess: (state, action) => {
            state.isFetching = false;
            state.previousShiftToHour = action.payload;


        },
        previousShiftToHourFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentShiftStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentShiftSuccess: (state, action) => {
            state.isFetching = false;
            state.currentShift = action.payload;


        },
        currentShiftFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentShiftFromHourStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentShiftFromHourSuccess: (state, action) => {
            state.isFetching = false;

            state.currentShiftFromHour = action.payload;


        },
        currentShiftFromHourFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentShiftToHourStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentShiftToHourSuccess: (state, action) => {
            state.isFetching = false;

            state.currentShiftToHour = action.payload;


        },
        currentShiftToHourFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentFromStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentFromSuccess: (state, action) => {
            state.isFetching = false;

            state.currentFrom = action.payload;


        },
        currentFromFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentToStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentToSuccess: (state, action) => {
            state.isFetching = false;

            state.currentTo = action.payload;


        },
        currentToFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        previousFromStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousFromSuccess: (state, action) => {
            state.isFetching = false;

            state.previousFrom = action.payload;


        },
        previousFromFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        previousToStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousToSuccess: (state, action) => {
            state.isFetching = false;

            state.previousTo = action.payload;


        },
        previousToFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },

        autoRefreshTimeStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        autoRefreshTimeSuccess: (state, action) => {
            state.isFetching = false;
            state.autoRefreshTime = action.payload;
        },
        autoRefreshTimeFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        shiftDataStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        shiftDataSuccess: (state, action) => {
            state.isFetching = false;
            state.shiftData = action.payload;
        },
        shiftDataFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        shiftListStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        shiftListSuccess: (state, action) => {
            state.isFetching = false;
            state.shiftList = action.payload;
        },
        shiftListFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        hoursListStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        hoursListSuccess: (state, action) => {
            state.isFetching = false;
            state.hoursList = action.payload;
        },
        hoursListFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        isTodayFromHourStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        isTodayFromHourSuccess: (state, action) => {
            state.isTodayFromHour = action.payload;
        },
        isTodayFromHourFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },

        isTodayToHourStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        isTodayToHourSuccess: (state, action) => {
            state.isFetching = false;
            state.isTodayToHour = action.payload;
        },
        isTodayToHourFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        shiftLogoutSuccess: (state, action) => {
            state.isFetching = false;
            state.previousFrom = ""
            state.previousTo = ""
            state.currentFrom = ""
            state.currentTo = ""
            state.shiftNumber = ""
            state.previousShift = ""
            state.previousShiftFromHour = ""
            state.previousShiftToHour = ""
            state.currentShift = ""
            state.currentShiftFromHour = ""
            state.currentShiftToHour = ""
        },

    },
});

export const {
    shiftConfigurationDataStart,
    shiftConfigurationDataSuccess,
    shiftConfigurationDataFailure,
    shiftNumberStart,
    shiftNumberSuccess,
    shiftNumberFailure,
    previousShiftStart,
    previousShiftSuccess,
    previousShiftFailure,
    currentShiftStart,
    currentShiftSuccess,
    currentShiftFailure,
    previousShiftFromHourStart,
    previousShiftFromHourSuccess,
    previousShiftFromHourFailure,
    previousShiftToHourStart,
    previousShiftToHourSuccess,
    previousShiftToHourFailure,
    currentShiftFromHourStart,
    currentShiftFromHourSuccess,
    currentShiftFromHourFailure,
    currentShiftToHourStart,
    currentShiftToHourSuccess,
    currentShiftToHourFailure,
    currentFromStart,
    currentFromSuccess,
    currentFromFailure,
    currentToStart,
    currentToSuccess,
    currentToFailure,
    previousFromStart,
    previousFromSuccess,
    previousFromFailure,
    previousToStart,
    previousToSuccess,
    previousToFailure,
    autoRefreshTimeStart,
    autoRefreshTimeSuccess,
    autoRefreshTimeFailure,
    shiftDataStart,
    shiftDataSuccess,
    shiftDataFailure,
    shiftListStart,
    shiftListSuccess,
    shiftListFailure,
    hoursListStart,
    hoursListSuccess,
    hoursListFailure,
    isTodayFromHourStart,
    isTodayFromHourSuccess,
    isTodayFromHourFailure,
    isTodayToHourStart,
    isTodayToHourSuccess,
    isTodayToHourFailure
} = shiftsSlice.actions;


const shiftsReducer = shiftsSlice.reducer; //it should be export default
export default shiftsReducer;