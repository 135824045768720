import React from 'react'

import Modal from '../../components-soapy/Modal.jsx'
import { withTranslation } from 'react-i18next'

const LogoutModal = ({ t, ...rest }) => (
  <Modal id='logoutModal' {...rest}>
    <div id='logoutModalDiv'>
      <h4 id='logoutModalQuestion'>{t('Are you sure?')}</h4>
    </div>
  </Modal>
)

export default withTranslation()(LogoutModal)
