exports.FROM_DATE = 'fromDate'
exports.TO_DATE = 'toDate'
exports.MACHINE_IDS_LIST = 'machineIdsList'
exports.SITE_IDS = 'siteIds'
exports.WASH_DATE = 'washDate'
exports.TO_HOUR = 'toHour'
exports.FROM_HOUR = 'fromHour'
exports.FILTER_BY_HOURS = 'filterByHours'
exports.WASH_COUNT = 'washCount'
exports.WASH_DAILED_COUNT = 'washFailedCount'
exports.SITES_IDS = 'sitesIds'
exports.WASHERS_IDS = 'washersIds'
exports.MINIMUM_RESULT_SCORE = 'minimumResultScore'




exports.ERROR_TYPE = 'errorType'
exports.MACHINE_ID_NOT_BELONG_TO_DASHBOARD_USER = 'machineIdNotBelongToDashboardUser'

exports.ORGANIZATIONS = 'organizations'
exports.ORGANIZATION = 'organization'

exports.FROM_DATE = 'fromDate'
exports.TO_DATE = 'toDate'
exports.TITLE = 'title'
exports.CLIENT_LOGO = 'clientLogo'
exports.CURRENT_SHFIT = 'currentShift'
exports.MINIMUM_WASHES = 'minimumWashes'
exports.SITES = 'sites'