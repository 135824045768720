import React from 'react'

import AuthContext from './AuthContext.js'

const withAuthentication = Component => {
  const WithAuthentication = props => {
    return (
      <AuthContext.Consumer>
        {authContext => <Component {...props} {...authContext} />}
      </AuthContext.Consumer>
    )
  }

  return WithAuthentication
}

export default withAuthentication
