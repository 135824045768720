import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Button from '../../components/CustomButtons/Button'
import DailyWashExportTable from './ExportTable'

const WashersTableHeader = ({
  hasPermission,
  openAddWasherModal,
  openWasherBulkAddModal,
  openWasherDownloadCSVModal,
  totalWashers = 0,
  excelDataset,
  t
}) => {
  return (
    <div>
      {
        hasPermission({ name: 'washers', action: 'create' }) && <Button
          color="info"
          round
          id="washerAddBtn"
          onClick={openAddWasherModal}
          style={{ marginRight: 5 }}
        >
          {t('Add Washer')}
        </Button>
      }
      {
        hasPermission({ name: 'washers', action: 'create' }) && <Button
          color="info"
          round
          id="washerBulkCardUploadBtn"
          onClick={openWasherBulkAddModal}
          style={{ marginRight: 5 }}
        >
          {t('Bulk Card Upload')}
        </Button>
      }
      {
        hasPermission({ name: 'washers', action: 'create' }) && <Button
          color="info"
          round
          id="washerDownloadTemplateCSVBtn"
          onClick={openWasherDownloadCSVModal}
          style={{ marginRight: 5 }}
        >
          {t('Download Template CSV')}
        </Button>
      }
      {
        hasPermission({ name: 'washers', action: 'create' }) && <DailyWashExportTable
          excelDataset={excelDataset}
        />
      }

      <div style={{ textAlign: 'right' }}><b>{t('Total washers')}: {totalWashers}</b></div>
    </div>
  )
}

export default withTranslation()(withAuthentication(WashersTableHeader))
