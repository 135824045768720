import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Modal from '../../../components-soapy/Modal.jsx'
import CustomInput from '../../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import addSiteConfiguration from '../../../endpoint-requests/site/addSiteConfiguration.js'
import useFormFields from '../../../shared/hooks/useFormFields.js'
import validateFormFields from '../../../utils/validateFormFields.js'
import withStyles from '@material-ui/core/styles/withStyles'
import formStyle from '../../../assets-soapy/jss/soapy/components/formStyle'
import { useSelector } from 'react-redux'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'

const SANITIZER_REAGENT = 'sanitizerReagent'
const SANITIZER_LATHERING = 'sanitizerLathering'
const SOAP_REAGENT = 'soapReagent'
const SOAP_LATHERING = 'soapLathering'
const SOAP_RINSING = 'soapRinsing'
const FAILURE_THRESHOLD = 'failureThreshold'
const TEMPERATURE_THRESHOLD = 'temperatureThreshold'
const FAILED_RESULT_SMILEY = 'failedResultSmiley'
const CAMPAIGN_ID = 'campaignId'
const IS_ACTIVE_CAMPAIGN = 'isActiveCampaign'
const CAMPAIGN_DELAY = 'campaignDelay'
const HAND_RECOGNITION_ADJUSTMENT = 'handRecognitionAdjustment'
const WASH_FLOW_UI = 'washFlowUI'
const RESULT_SCREEN = 'resultScreen'

const VALIDATION_STATE = 'validationState'

const infoIcon = <InfoOutlined style={{ color: '#666666' }} />

const toolTipText =
  <ul>
    <li>Normal - Hand Gestures-based scoring algorithm. The calculation is fully dependent on gesture recognition. Each gesture has its own weight in the formula.</li>
    <li> Rub & Scrub adjustment - Scoring is based on adding one additional hand gesture to the overall gesture sum. This addition is based on Rub & Scrub time measured by distance sensors.</li>
    <li>Sheba adjustment (Rename to "Moving Weights adjustment" ) - Scoring is based on hand gestures and distance measurement altogether. More hand gestures recognized, less weight to distance measurements.</li>
  </ul>

const smileResultObject = {
  Sad: 'sad',
  Happy: 'happy'
}

const washFlowUIObject = {
  USA: 'usa',
  Taiwan: 'taiwan'
}

const resultScreenObject = {
  'Doughnut based': 'doughnut',
  'Star based': 'star'
}

const handRecognitionAdjustmentObject = {
  'Normal': 'normal',
  // "Rub and scrub adjustment": 'rub_and_scrub_adjustment',
  // "Sheba adjustment": 'sheba_adjustment',
  'Sheba static detect': 'sheba_static_detect',
}

let spinnerTimeout = null

const useStyles = makeStyles({
  tooltip: {
    fontSize: "1em",
  },
});

const ConfigurationModal = ({
  t,
  siteConfiguration,
  isOpen,
  organizationCampaigns,
  currentDashboardUser,
  siteId,
  showErrorNotification,
  isLoading,
  siteConfigurationAdded,
  closeModal,
  classes
}) => {

  const tooltipClasses = useStyles();
  const siteMonitorOrientation = useSelector((state) => state.sites.siteMonitorOrientation)

  const { formFields, setFormFields } = useFormFields({
    [SANITIZER_REAGENT]: {
      value: null,
      isRequired: true,
      name: 'Sanitizer reagent',
      validators: [
        {
          isValid: value => {
            return value >= 0.5
          },
          message: 'Sanitizer reagent should not be empty, and over 0.5'
        }
      ]
    },
    [SANITIZER_LATHERING]: {
      value: null,
      isRequired: true,
      name: 'Sanitizer Rub & Scrub',
      validators: [
        {
          isValid: value => {
            return value >= 0.5
          },
          message: 'Sanitizer Rub & Scrub should not be empty, and over 0.5'
        }
      ]
    },
    [SOAP_REAGENT]: {
      value: null,
      isRequired: true,
      name: 'Soap reagent',
      validators: [
        {
          isValid: value => {
            return value >= 0.5
          },
          message: 'Soap reagent should not be empty, and over 0.5'
        }
      ]
    },
    [FAILURE_THRESHOLD]: {
      value: null,
      isRequired: true,
      name: 'Failure threshold',
      validators: [
        {
          isValid: value => {
            return value >= 0.5
          },
          message: 'Failure threshold should not be empty, and over 0.5'
        }
      ]
    },
    [SOAP_LATHERING]: {
      value: null,
      isRequired: true,
      name: 'Soap Rub & Scrub',
      validators: [
        {
          isValid: value => {
            return value >= 0.5
          },
          message: 'Soap Rub & Scrub should not be empty, and over 0.5'
        }
      ]
    },
    [SOAP_RINSING]: {
      value: null,
      isRequired: true,
      name: 'Soap rinsing',
      validators: [
        {
          isValid: value => {
            return value >= 0.5
          },
          message: 'Soap rinsing should not be empty, and over 0.5'
        }
      ]
    },
    [TEMPERATURE_THRESHOLD]: {
      value: null,
      isRequired: true,
      name: 'Temperature threshold',
      validators: [
        {
          isValid: value => {
            return value >= 34.5 && value <= 42
          },
          message: 'Temperature threshold should be between 34.5 and 42'
        }
      ]
    },
    [FAILED_RESULT_SMILEY]: {
      value: smileResultObject.Sad,
      isRequired: false
    },
    [CAMPAIGN_ID]: {
      value: '',
      isRequired: false
    },
    [IS_ACTIVE_CAMPAIGN]: {
      value: 'inactive',
      isRequired: false
    },
    [CAMPAIGN_DELAY]: {
      value: 1,
      isRequired: false
    },
    [HAND_RECOGNITION_ADJUSTMENT]: {
      value: handRecognitionAdjustmentObject.Normal,
      isRequired: false
    },
    [WASH_FLOW_UI]: {
      value: washFlowUIObject.USA,
      isRequired: false
    },
    [RESULT_SCREEN]: {
      value: resultScreenObject['Doughnut based'],
      isRequired: false
    },
  })
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)

  useEffect(() => {
    setFormFields(
      (formFields) => ({
        [SANITIZER_REAGENT]: {
          ...formFields[SANITIZER_REAGENT],
          value: siteConfiguration[SANITIZER_REAGENT] || null
        },
        [SANITIZER_LATHERING]: {
          ...formFields[SANITIZER_LATHERING],
          value: siteConfiguration[SANITIZER_LATHERING] || null
        },
        [SOAP_REAGENT]: {
          ...formFields[SOAP_REAGENT],
          value: siteConfiguration[SOAP_REAGENT] || null
        },
        [SOAP_LATHERING]: {
          ...formFields[SOAP_LATHERING],
          value: siteConfiguration[SOAP_LATHERING] || null
        },
        [SOAP_RINSING]: {
          ...formFields[SOAP_RINSING],
          value: siteConfiguration[SOAP_RINSING] || null
        },
        [TEMPERATURE_THRESHOLD]: {
          ...formFields[TEMPERATURE_THRESHOLD],
          value: siteConfiguration[TEMPERATURE_THRESHOLD] || null
        },
        [FAILURE_THRESHOLD]: {
          ...formFields[FAILURE_THRESHOLD],
          value: siteConfiguration[FAILURE_THRESHOLD] || null
        },
        [FAILED_RESULT_SMILEY]: {
          ...formFields[FAILED_RESULT_SMILEY],
          value: siteConfiguration[FAILED_RESULT_SMILEY] || smileResultObject.Sad
        },
        [CAMPAIGN_ID]: {
          ...formFields[CAMPAIGN_ID],
          value: siteConfiguration.campaignId || ''
        },
        [IS_ACTIVE_CAMPAIGN]: {
          ...formFields[IS_ACTIVE_CAMPAIGN],
          value: siteConfiguration.isCampaignActive ? 'active' : 'inactive'
        },
        [CAMPAIGN_DELAY]: {
          ...formFields[CAMPAIGN_DELAY],
          value: siteConfiguration.campaignDelay || 10
        },
        [HAND_RECOGNITION_ADJUSTMENT]: {
          ...formFields[HAND_RECOGNITION_ADJUSTMENT],
          value: siteConfiguration[HAND_RECOGNITION_ADJUSTMENT] && siteConfiguration[HAND_RECOGNITION_ADJUSTMENT].toLowerCase() || handRecognitionAdjustmentObject.Normal
        },
        [WASH_FLOW_UI]: {
          ...formFields[WASH_FLOW_UI],
          value: siteConfiguration[WASH_FLOW_UI] && siteConfiguration[WASH_FLOW_UI].toLowerCase() || washFlowUIObject.USA
        },
        [RESULT_SCREEN]: {
          ...formFields[RESULT_SCREEN],
          value: siteConfiguration[RESULT_SCREEN] && siteConfiguration[RESULT_SCREEN].toLowerCase() || resultScreenObject['Doughnut based']
        },
      })
    )
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [siteConfiguration, isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }
  const submitForm = async () => {
    activateSpinner()
    try {
      await addSiteConfiguration({
        sanitizerReagent: parseFloat(formFields[SANITIZER_REAGENT].value),
        sanitizerLathering: parseFloat(formFields[SANITIZER_LATHERING].value),
        soapReagent: parseFloat(formFields[SOAP_REAGENT].value),
        soapLathering: parseFloat(formFields[SOAP_LATHERING].value),
        soapRinsing: parseFloat(formFields[SOAP_RINSING].value),
        temperatureThreshold: parseFloat(formFields[TEMPERATURE_THRESHOLD].value),
        failureThreshold: parseFloat(formFields[FAILURE_THRESHOLD].value),
        failedResultSmiley: formFields[FAILED_RESULT_SMILEY].value,
        campaignId: formFields[CAMPAIGN_ID].value,
        isActiveCampaign: formFields[IS_ACTIVE_CAMPAIGN].value === 'active',
        campaignDelay: formFields[CAMPAIGN_DELAY].value,
        handRecognitionAdjustment: formFields[HAND_RECOGNITION_ADJUSTMENT].value,
        washFlowUI: formFields[WASH_FLOW_UI].value,
        resultScreen: formFields[RESULT_SCREEN].value,
      }, siteId)
      siteConfigurationAdded()
    } catch (err) {
      showErrorNotification(err.message)
    }
    disableSpinner()
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleTextChange = (event, fieldName) => {
    const value = event.target.value
    setFormFields({
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value },
      [fieldName + VALIDATION_STATE]: ''
    })
  }
  return (
    <Modal
      isLoading={isAwaitingResponse}
      isOpen={isOpen}
      title={t('Site Configuration')}
      onApply={() => processForm()}
      onCancel={() => closeModal()}
    >
      {isLoading
        ? <CircularProgress
          color="primary"
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
        : <GridContainer id="content-pane-layout" justify="center">
          <GridItem md={5}>
            <div>
              <div>
                <form>
                  <GridContainer
                    direction="column"
                    justify="center"
                  >
                    <div>{t('Soap Percentage')}</div>
                    <GridItem>
                      <CustomInput
                        id="configModalReagent"
                        labelText={t('Soap')}
                        error={formFields[SOAP_REAGENT + VALIDATION_STATE] === 'error'}
                        inputProps={{
                          value: formFields[SOAP_REAGENT].value,
                          type: 'number',
                          onChange: event => handleTextChange(event, SOAP_REAGENT)
                        }}
                        step={0.5}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                    <GridItem>
                      <CustomInput
                        id="configModalLathering"
                        error={formFields[SOAP_LATHERING + VALIDATION_STATE] === 'error'}
                        labelText={t('Rub, Scrub & Hand movements')}
                        inputProps={{
                          value: formFields[SOAP_LATHERING].value,
                          type: 'number',
                          onChange: event => handleTextChange(event, SOAP_LATHERING)
                        }}
                        step={0.5}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                    <GridItem>
                      <CustomInput
                        id="configModalRinsing"
                        error={formFields[SOAP_RINSING + VALIDATION_STATE] === 'error'}
                        labelText={t('Rinsing')}
                        inputProps={{
                          value: formFields[SOAP_RINSING].value,
                          type: 'number',
                          onChange: event => handleTextChange(event, SOAP_RINSING)
                        }}
                        step={0.5}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                    <div>{t('Sanitizer Percentage')}</div>
                    <GridItem>
                      <CustomInput
                        id="configModalReagentPers"
                        error={formFields[SANITIZER_REAGENT + VALIDATION_STATE] === 'error'}
                        labelText={t('Sanitizer')}
                        inputProps={{
                          value: formFields[SANITIZER_REAGENT].value,
                          type: 'number',
                          onChange: event => handleTextChange(event, SANITIZER_REAGENT)
                        }}
                        step={0.5}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                    <GridItem>
                      <CustomInput
                        id="configModalLatheringPers"
                        error={formFields[SANITIZER_LATHERING + VALIDATION_STATE] === 'error'}
                        labelText={t('Rub, Scrub & Hand movements')}
                        inputProps={{
                          value: formFields[SANITIZER_LATHERING].value,
                          type: 'number',
                          onChange: event => handleTextChange(event, SANITIZER_LATHERING)
                        }}
                        step={0.5}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                    {
                      currentDashboardUser.entity.name === 'neo'
                        ? <>
                          <div>{t('Campaign')}</div>
                          <GridItem style={{ minWidth: '30vh', marginBottom: '1rem' }}>
                            <FormControl
                              fullWidth
                            >
                              <InputLabel style={{
                                color: '#aaaaaa',
                                fontSize: '14px',
                                fontWeight: 400
                              }}
                              >
                                {t('Campaign')}
                              </InputLabel>
                              <Select
                                id="campaign"
                                value={formFields[CAMPAIGN_ID].value}
                                onChange={e => handleTextChange(e, CAMPAIGN_ID)}
                              >
                                {
                                  organizationCampaigns.map(campaign => {
                                    if (campaign.monitorOrientation === null) {
                                      campaign.monitorOrientation = "horizontal"
                                      if (campaign.monitorOrientation === siteMonitorOrientation) {
                                        return (
                                          <MenuItem
                                            value={campaign.id}
                                            key={campaign.id}
                                          >
                                            {campaign.name}
                                          </MenuItem>)
                                      }
                                    }
                                    if (campaign.monitorOrientation === siteMonitorOrientation) {
                                      return (<MenuItem
                                        value={campaign.id}
                                        key={campaign.id}
                                      >
                                        {campaign.name}
                                      </MenuItem>)
                                    }
                                  })
                                }
                              </Select>
                            </FormControl>
                            {
                              formFields[CAMPAIGN_ID].value
                                ? <>
                                  <RadioGroup row value={formFields[IS_ACTIVE_CAMPAIGN].value}
                                    onChange={(e) => handleTextChange(e, IS_ACTIVE_CAMPAIGN)}>
                                    <FormControlLabel id="campaignRadioActive" value="active"
                                      control={<Radio color="primary" />} label="Active" />
                                    <FormControlLabel id="campaignRadioInactive" value="inactive"
                                      control={<Radio color="primary" />} label="Inactive" />
                                  </RadioGroup>
                                  <FormControl fullWidth>
                                    <InputLabel
                                      shrink style={{
                                        width: '100%'
                                      }}
                                    >{t('Campaign delay')}
                                    </InputLabel>
                                    <Select
                                      id="displayTimeSelect"
                                      value={formFields[CAMPAIGN_DELAY].value}
                                      onChange={(event) => {
                                        handleTextChange(event, CAMPAIGN_DELAY)
                                      }}
                                    >
                                      {
                                        Array(30).fill().map((_, i) => {
                                          return <MenuItem key={i + 1} value={i + 1}>{i + 1} Sec</MenuItem>
                                        })
                                      }
                                    </Select>
                                  </FormControl>
                                </>
                                : null
                            }

                          </GridItem>
                        </>
                        : null
                    }

                    <div>{t('Threshold')}</div>
                    <GridItem>
                      <CustomInput
                        id="temperatureThreshold"
                        error={formFields[TEMPERATURE_THRESHOLD + VALIDATION_STATE] === 'error'}
                        labelText={t('Temperature Threshold')}
                        inputProps={{
                          value: formFields[TEMPERATURE_THRESHOLD].value,
                          type: 'float',
                          onChange: event => handleTextChange(event, TEMPERATURE_THRESHOLD)
                        }}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                    <GridItem>
                      <CustomInput
                        id="temperatureThreshold"
                        error={formFields[FAILURE_THRESHOLD + VALIDATION_STATE] === 'error'}
                        labelText={t('Quality Threshold')}
                        inputProps={{
                          value: formFields[FAILURE_THRESHOLD].value,
                          type: 'float',
                          onChange: event => handleTextChange(event, FAILURE_THRESHOLD)
                        }}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                    <div>{t('UI Visibility')}</div>
                    <GridItem>
                      <FormControl
                        fullWidth
                      >
                        <InputLabel style={{
                          color: '#aaaaaa',
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                        >
                          {t('Result Screen')}
                        </InputLabel>
                        <Select
                          id="washFlowUI"
                          value={formFields[RESULT_SCREEN].value}
                          onChange={e => handleTextChange(e, RESULT_SCREEN)}
                        >
                          {
                            Object.keys(resultScreenObject).map(key => {
                              return (
                                <MenuItem
                                  value={resultScreenObject[key]}
                                  key={key}
                                >
                                  {key}
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    {formFields[RESULT_SCREEN].value === resultScreenObject['Doughnut based'] ? <GridItem>
                      <FormControl
                        fullWidth
                      >
                        <InputLabel style={{
                          color: '#aaaaaa',
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                        >
                          {t('Failed Result Smiley')}
                        </InputLabel>
                        <Select
                          id="failedResultSmiley"
                          value={formFields[FAILED_RESULT_SMILEY].value}
                          onChange={e => handleTextChange(e, FAILED_RESULT_SMILEY)}
                        >
                          {
                            Object.keys(smileResultObject).map(key => {
                              return (
                                <MenuItem
                                  value={smileResultObject[key]}
                                  key={key}
                                >
                                  {key}
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem> : null}
                    <GridItem>
                      <FormControl
                        fullWidth
                      >
                        <InputLabel style={{
                          color: '#aaaaaa',
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                        >
                          {t('Wash Flow UI')}
                        </InputLabel>
                        <Select
                          id="washFlowUI"
                          value={formFields[WASH_FLOW_UI].value}
                          onChange={e => handleTextChange(e, WASH_FLOW_UI)}
                        >
                          {
                            Object.keys(washFlowUIObject).map(key => {
                              return (
                                <MenuItem
                                  value={washFlowUIObject[key]}
                                  key={key}
                                >
                                  {key}
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    <Tooltip title={toolTipText}
                      classes={{ tooltip: tooltipClasses.tooltip }}
                      placement="top"
                    >
                      <div style={{ marginTop: '10px', display: 'flex', alignItems: "center", justifyContent: 'space-between', }}>
                        {t('Hands Recognition')}
                        {infoIcon}
                      </div>
                    </Tooltip>
                    <GridItem>
                      <FormControl
                        fullWidth
                      >
                        <InputLabel style={{
                          color: '#aaaaaa',
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                        >
                          {t('Hands recognition adjustment')}
                        </InputLabel>
                        <Select
                          id="handRecognitionAdjustment"
                          value={formFields[HAND_RECOGNITION_ADJUSTMENT].value}
                          onChange={e => handleTextChange(e, HAND_RECOGNITION_ADJUSTMENT)}
                        >
                          {
                            Object.keys(handRecognitionAdjustmentObject).map(key => {
                              return (
                                <MenuItem
                                  value={handRecognitionAdjustmentObject[key]}
                                  key={key}
                                >
                                  {key}
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem>

                  </GridContainer>
                </form>

              </div>
            </div>
          </GridItem>
        </GridContainer>}
    </Modal>
  )
}

export default withAuthentication(withStyles(formStyle)(withErrorNotification(withTranslation()(ConfigurationModal))))
