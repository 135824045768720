import {apiEndpoints, SERVER_PATH} from "../endpoints.js";
import axios from 'axios';

export const deleteUuid = async (id) => {
	const endpoint = `${apiEndpoints.machines}/uuid-delete`;
	const URL = SERVER_PATH + endpoint;
	try {
		await axios.post(URL, {id: id}, {withCredentials: true});
	} catch (e) {
		return {didSucceed: false, errprType: 'SERVER_ERROR'};
	}
};
