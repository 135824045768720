/**
 *
 * @param {import('axios').AxiosPromise} request
 */
import _ from 'lodash'

class ApiCallError {
  constructor(payload) {
    this.message = payload.message
    this.errorType = payload.errorType
  }
}

export default async (request) => {
  try {
    const { data } = await request
    return data
  } catch (e) {
    const error = {
      message: _.get(e, 'response.data.error.message') || 'There was a network error, please try again.',
      errorType: _.get(e, 'response.data.error.errorType') || 'NETWORK_ERROR'
    }
    if (error.errorType === 'FAILED_SESSION_MATCHING') {
      window.location.reload('/login')
    }
    throw new ApiCallError(error)
  }
}
