import resolveRequest from '../../utils/resolveRequest.js'
import { SERVER_PATH, apiEndpoints } from '../endpoints.js'
import axios from 'axios'

export const fetchDateRange = async () => {
    const URL = SERVER_PATH + apiEndpoints.getDateRange

    return resolveRequest(axios.get(URL,
        {
            withCredentials: true
        }))

}


