import * as React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux';
import { useStylesPopUp } from '../../Shift/shiftStyles';
import { popUpSuccess } from '../../../redux/popUpReducer';
import { campaignMonitorOrientationSuccess } from '../../../redux/campaignReducer';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: "none",
    borderRadius: "3px",
    boxShadow: 24,
    p: 4,
};

export default function OrientationFilesModal({ formFields, setFormFields, checkFileOrUrlExist, isActive, setIsActive, setAddOrientation }) {

    const open = useSelector((state) => state.popUp.open)
    const campaign = useSelector((state) => state.campaign.campaign)
    const campaignMonitorOrientation = useSelector((state) => state.campaign.campaignMonitorOrientation)

    const dispatch = useDispatch()
    const classes = useStylesPopUp();

    const handleClose = () => {
        dispatch(popUpSuccess(false))
        if (campaignMonitorOrientation === "horizontal") {
            setAddOrientation("vertical")
            setFormFields({
                ...formFields,
                monitorOrientation: "vertical",
            })
            dispatch(campaignMonitorOrientationSuccess("vertical"))
        }
        else {
            setAddOrientation("horizontal")
            setFormFields({
                ...formFields,
                monitorOrientation: "horizontal",
            })
            dispatch(campaignMonitorOrientationSuccess("horizontal"))
        }
    };

    const handleCancel = () => {
        dispatch(popUpSuccess(false))
        if (campaignMonitorOrientation === "horizontal") {
            setAddOrientation("vertical")
            setFormFields({
                ...formFields,
                monitorOrientation: "vertical",
            })
            dispatch(campaignMonitorOrientationSuccess("vertical"))
        }
        else {
            setAddOrientation("horizontal")
            setFormFields({
                ...formFields,
                monitorOrientation: "horizontal",
            })
            dispatch(campaignMonitorOrientationSuccess("horizontal"))
        }
    }

    const handleOk = () => {
        const { monitorOrientation, } = formFields
        setFormFields({
            ...formFields,
            monitorOrientation: monitorOrientation,
            rinsingItems: {
                ...formFields.rinsingItems, value: formFields.rinsingItems.value.map((item, index) => {
                    return { ...item, file: null, url: undefined }
                })
            },
            welcomeItems: {
                ...formFields.welcomeItems, value: formFields.welcomeItems.value.map((item, index) => {
                    return { ...item, file: null, url: undefined }
                })
            }
        })
        dispatch(campaignMonitorOrientationSuccess(monitorOrientation))
        dispatch(popUpSuccess(false))
    }
    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" >
                        Alert Message
                    </Typography>
                    <Close
                        onClick={handleClose}
                        className={classes.myClassName}
                    />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: "10px", color: 'red' }} >
                        To change orientation all files must be deleted!
                    </Typography>
                    <div className="popupButtonDiv" style={{ float: "right" }}>
                        <p style={{
                            cursor: 'pointer',
                            marginRight: "50px",
                            width: "50px",
                            fontWeight: "300"
                        }} onClick={handleOk} >Ok</p>
                        <p style={{
                            cursor: 'pointer',
                            color: "red",
                            width: "50px",
                            fontWeight: "300"
                        }} onClick={handleCancel} >Cancel</p>

                    </div>
                </Box>
            </Modal>
        </div >
    );
}

