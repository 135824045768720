import axios from 'axios'
import filterEmptyValues from '../../shared/dashboard/filterEmptyValues.js'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export const addReport = async (distributorObject) => {
  const endpoint = `${apiEndpoints.report}`
  const URL = SERVER_PATH + endpoint
  return resolveRequest(axios.post(URL, filterEmptyValues(distributorObject), { withCredentials: true }))
}
