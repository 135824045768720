export const calculateShiftData = (data) => {
    let washCount = 0
    let averageWashScore = 0
    let averageScrubTime = 0
    let failedWashCount = 0
    if (data.length !== 0) {
        const shiftResult = data.map((i) => {
            // i.washCount
            // i.washCount * i.washAveragePercentage
            // i.washCount * i.averageScrubTime
            // i.washFailedCount
            return {
                washCount: i.washCount,
                averageWashScore: i.washCount * i.washAveragePercentage,
                averageScrubTime: i.washCount * i.averageScrubTime,
                failedWashCount: i.washFailedCount
            }
        })

        let shiftData = shiftResult.reduce(function (x, y) {
            x.washCount += y.washCount;
            x.averageWashScore += y.averageWashScore;
            x.averageScrubTime += y.averageScrubTime;
            x.failedWashCount += y.failedWashCount;
            return x
        }, { washCount: 0, averageWashScore: 0, averageScrubTime: 0, failedWashCount: 0 });
        shiftData.averageWashScore = round(shiftData.averageWashScore / shiftData.washCount, 1)
        shiftData.averageScrubTime = round(shiftData.averageScrubTime / shiftData.washCount, 1)
        return shiftData
    }
    return {
        washCount,
        averageWashScore,
        averageScrubTime,
        failedWashCount
    }
}

//round 
const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
