import getLanguageLocale from '../multi-language/getLanguageLocale.js'

export default (startDate, endDate) => {
  let currentDate = startDate.locale(getLanguageLocale()).clone().startOf('day')
  const dates = []
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate)
    currentDate = currentDate.locale(getLanguageLocale()).clone().add(1, 'days')
  }
  return dates
}
