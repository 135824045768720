import React from 'react'

import Modal from '../../components-soapy/Modal.jsx'
import { withTranslation } from 'react-i18next'

const ResetCacheModal = ({ t, ...rest }) => (
  <Modal {...rest}>
    <div id='resetCacheModalContainer'>
      <h4 id='resetCacheModalContainerQuestion'>{t('Are you sure??')}</h4>
    </div>
  </Modal>
)

export default withTranslation()(ResetCacheModal)
