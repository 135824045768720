import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'

export default async (formData) => {
  const URL = SERVER_PATH + apiEndpoints.washers + '/csv'
  return resolveRequest(
    axios.post(URL, formData, {
      withCredentials: true
    })
  )
}
