export default (cellText, searchText) => {
  if (typeof cellText === 'boolean') {
    if (searchText === null) return true
    return cellText === searchText
  }
  if (Array.isArray(cellText)) {
    return cellText.join(' ').includes(searchText.toLowerCase())
  }
  if (typeof cellText !== 'string') {
    cellText = cellText.props.children
  }
  if (!cellText) return false
  return cellText.toLowerCase().includes(searchText.toLowerCase())
}
