import fetchHandsRecognition from '../../endpoint-requests/hands-recognition/fetchHandsRecognition.js'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMachine } from '@xstate/react'
import React from 'react'
import Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import fetchMachinesLocations from '../../endpoint-requests/machine/getMachines.js'
import getCookie from '../../shared/cookie/get-cookie.js'
import setCookie from '../../shared/cookie/set-cookie.js'
import Presentational from './Presentational.jsx'

const defaultFromDate = Datetime.moment().subtract(7, 'day').set({ hour: 0, minute: 0, second: 0 })
const defaultToDate = Datetime.moment().set({ hour: 23, minute: 59, second: 59 })

function getMachine (user) {
  return Machine({
    id: 'units',
    initial: 'loadAll',
    context: () => ({
      handRecognitionData: [],
      temperatureThreshold: 35.8,
      machines: [],
      selectedMachineId: null,
      fromDate: defaultFromDate,
      error: '',
      toDate: defaultToDate
    }),
    states: {
      loadAll: {
        entry: ['extractFromCookie'],
        invoke: {
          src: (context, event) => {
            return Promise.all(
              [
                fetchMachinesLocations({ fetchArchived: true, defaultOrganization: user.entity.name === 'distributor' && user.role.name === 'admin' }),
                context.selectedMachineId && context.selectedMachineId !== 'null'
                  ? fetchHandsRecognition({
                      date: new Date(context.fromDate),
                      toDate: new Date(context.toDate),
                      machineIds: [context.selectedMachineId]
                    })
                  : { data: [] }])
          },
          onDone: {
            target: 'main',
            actions: assign({
              machines: (context, event) => event.data[0],
              selectedMachineId: (context, event) => event.data[0].findIndex(machine => machine.id === context.selectedMachineId) === -1 ? null : context.selectedMachineId,
              handRecognitionData: (context, event) => event.data[1]
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message
            })
          }
        }
      },
      loadingHandsRecognitionData: {
        invoke: {
          src: (context, event) => fetchHandsRecognition({
            date: new Date(context.fromDate),
            toDate: new Date(context.toDate),
            machineIds: [context.selectedMachineId]
          }),
          onDone: {
            target: 'main',
            actions: assign({
              handRecognitionData: (context, event) => event.data
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              error: (context, event) => event.data.message
            })
          }
        }
      },
      failedFetchingData: {
        type: 'final'
      },
      machinesModal: {
        on: {
          SET_DEFAULT_HAND_RECOGNITION_DATA: {
            target: 'main',
            actions: ['setDefaultWashes']
          },
          CLOSE_MACHINES_MODAL: 'main',
          LOAD_HAND_RECOGNITION_DATA: {
            target: 'loadingHandsRecognitionData',
            actions: ['setFromDate', 'setToDate', 'setSelectedMachineId']
          }
        }
      },
      dateModal: {
        on: {
          SET_DEFAULT_HAND_RECOGNITION_DATA: {
            target: 'main',
            actions: ['setDefaultWashes']
          },
          CLOSE_DATE_MODAL: 'main',
          LOAD_HAND_RECOGNITION_DATA: {
            target: 'loadingHandsRecognitionData',
            actions: ['setFromDate', 'setToDate', 'setSelectedMachineId']
          }
        }
      },
      main: {
        on: {
          OPEN_DATE_MODAL: 'dateModal',
          OPEN_MACHINES_MODAL: 'machinesModal',
          LOAD_HAND_RECOGNITION_DATA: {
            target: 'loadingHandsRecognitionData',
            actions: ['setFromDate', 'setToDate', 'setSelectedMachineId']
          }
        }
      }
    }
  }, {
    actions: {
      setDefaultWashes: assign({
        handRecognitionData: (context, event) => [],
        selectedMachineId: (context, event) => null,
        temperatureThreshold: (context, event) => 0,
        fromDate: (context, event) => event.fromDate,
        toDate: (context, event) => event.toDate,
        id: (context, event) => event.id
      }),
      extractFromCookie: assign({
        fromDate: (context, event) => getCookie('handRecognitionFromDate') ? Datetime.moment(getCookie('handRecognitionFromDate')) : defaultFromDate,
        toDate: (context, event) => getCookie('handRecognitionToDate') ? Datetime.moment(getCookie('handRecognitionToDate')) : defaultToDate,
        selectedMachineId: (context, event) => getCookie('handRecognitionMachineId') && getCookie('handRecognitionMachineId') !== 'null' ? getCookie('handRecognitionMachineId') : null
      }),
      setFromDate: assign({
        fromDate: (context, event) => event.fromDate
      }),
      setToDate: assign({
        toDate: (context, event) => event.toDate
      }),
      setSelectedMachineId: assign({
        selectedMachineId: (context, event) => event.selectedMachineId
      })
    }
  })
}

const HandRecognitionsContainer = ({ t, currentDashboardUser }) => {
  const [current, send] = useMachine(getMachine(currentDashboardUser))

  switch (current.value) {
    case 'loadAll':
    case 'loadingHandsRecognitionData':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t('Session Is Over Error')}</div>
    default: {
      const {
        handRecognitionData,
        machines,
        selectedMachineId,
        fromDate,
        toDate
      } = current.context
      const isDateModalOpen = current.value === 'dateModal'
      const isMachinesModalOpen = current.value === 'machinesModal'
      return (
        <Presentational
          selectedMachineId={selectedMachineId}
          fromDate={fromDate}
          openDateModal={
            () => send('OPEN_DATE_MODAL')
          }
          openMachinesModal={
            () => send('OPEN_MACHINES_MODAL')
          }
          closeDateModal={
            () => send('CLOSE_DATE_MODAL')
          }
          closeMachinesModal={
            () => send('CLOSE_MACHINES_MODAL')
          }
          isMachinesModalOpen={isMachinesModalOpen}
          isDateModalOpen={isDateModalOpen}
          toDate={toDate}
          handRecognitionData={handRecognitionData}
          fetchHandRecognitions={(fromDate, toDate, machineId) => {
            setCookie('handRecognitionFromDate', fromDate)
            setCookie('handRecognitionToDate', toDate)
            setCookie('handRecognitionMachineId', machineId)
            if (!machineId) {
              return send({ type: 'SET_DEFAULT_HAND_RECOGNITION_DATA', toDate, fromDate })
            }
            send({ type: 'LOAD_HAND_RECOGNITION_DATA', fromDate, toDate, selectedMachineId: machineId })
          }}
          machines={machines}
        />
      )
    }
  }
}

export default withAuthentication(withTranslation()(HandRecognitionsContainer))
