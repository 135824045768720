const twoThumbsStyle = theme => ({
  thumb: {
    height: '30px',
    width: '30px',
    borderRadius: '4px',
    backgroundColor: '#FFF',
    display: 'flex',
    zIndex: 15000,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 6px #AAA'
  },
  thumbValue: {
    fontSize: '0.625rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.4em'
  },
  track: {
    height: '36px',
    display: 'flex'
  },
  trackLine: {
    height: '5px',
    width: '100%',
    borderRadius: '4px',

    alignSelf: 'center'
  }
})

export default twoThumbsStyle
