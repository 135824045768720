import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export default async (siteId, options = {
  onlyIds: false
}) => {
  const queryParams = new URLSearchParams(options)
  const siteDashboardUsersUrl = SERVER_PATH + `${apiEndpoints.sites}/${siteId}/dashboard-users?${queryParams.toString()}`
  return resolveRequest(axios.get(siteDashboardUsersUrl, { withCredentials: true }))
}
