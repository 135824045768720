import jStat from 'jStat'

export default function regression (arrWeight, arrHeight) {
  let r, sy, sx, b, a, meanX, meanY
  r = jStat.corrcoeff(arrHeight, arrWeight)
  sy = jStat.stdev(arrWeight)
  sx = jStat.stdev(arrHeight)
  meanY = jStat(arrWeight).mean()
  meanX = jStat(arrHeight).mean()
  b = r * (sy / sx)
  a = meanY - meanX * b

  // Set up a line
  let y1, y2, x1, x2
  x1 = Math.floor(jStat.min(arrHeight))
  x2 = Math.floor(jStat.max(arrHeight))
  y1 = Math.floor(a + b * x1)
  y2 = Math.floor(a + b * x2)
  return {
    line: [
      [x1, y1],
      [x2, y2]
    ],
    r
  }
}
