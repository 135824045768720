import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Check from '@material-ui/icons/Check'
import Edit from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import validationFormsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx'
import Card from '../../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../../components-soapy/LoadingButton.jsx'
import CardBody from '../../../../components/Card/CardBody.jsx'
import CardHeader from '../../../../components/Card/CardHeader.jsx'
import CardIcon from '../../../../components/Card/CardIcon.jsx'
import CustomInput from '../../../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../../containers/withErrorNotification.js'
import { addSite } from '../../../../endpoint-requests/site/addSite'
import { editSite } from '../../../../endpoint-requests/site/editSite'
import useFormFields from '../../../../shared/hooks/useFormFields.js'
import validateFormFields from '../../../../utils/validateFormFields.js'
import { isEmail, verifyLength } from '../../../../utils/validators'
import ProceedModal from '../../../../components-soapy/ProceedModal'
import StayPrimaryLandscape from '@material-ui/icons/StayPrimaryLandscape'
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait'
import { useDispatch, useSelector } from "react-redux";
import WarningModal from './WarningModal.js'
import { popUpSuccess } from '../../../../redux/popUpReducer.js'
import { siteMonitorOrientationSuccess } from '../../../../redux/sitesRedux.js'
import fetchSites from '../../../../endpoint-requests/site/fetchSites.js'

const formStyle = {
  ...validationFormsStyle,
  cardHeaderIcon: {
    color: '#ffffff'
  }
}

const INFO_COLOR = '#00acc1'

const NAME = 'name'
const COUNTRY = 'country'
const LATITUDE = 'latitude'
const LONGITUDE = 'longitude'
const STATE = 'state'
const CITY = 'city'
const DISTRICT = 'district'
const ADDRESS = 'address'
const ZIP_CODE = 'zipcode'
const CONTACT_NAME = 'contactName'
const DISTRIBUTOR_ID = 'distributorId'
const CONTACT_EMAIL = 'contactEmail'
const ORGANIZATION_ID = 'organizationId'
const WEBSITE = 'website'
const VALIDATION_STATE = 'ValidationState'
const TEMPERATURE_TYPE = 'temperatureType'
const MONITOR_ORIENTATION = 'monitorOrientation'
const STATUS = 'status'

const temperatureTypeValues = ['C', 'F']

let spinnerTimeout = null

const EditSite = ({
  openDeleteModal,
  classes,
  isSitePage = false,
  showErrorNotification,
  siteAdded,
  currentDashboardUser,
  siteEdited,
  sites,
  site,
  distributors,
  organizations,
  t
}) => {
  const siteCampaignActive = useSelector((state) => state.sites.siteCampaignActive)
  const siteMonitorOrientation = useSelector((state) => state.sites.siteMonitorOrientation)
  const open = useSelector((state) => state.popUp.open)
  const dispatch = useDispatch()

  const isEdit = !!Object.keys(site).length
  if (site.status === null) {
    site.status = "active"
  }
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false)
  const { formFields, setFormFields } = useFormFields({
    [NAME]: {
      value: '',
      isRequired: true,
      name: 'Name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 256),
          message: 'Name should not be empty, and up to 256 characters'
        }
      ]
    },
    [CONTACT_EMAIL]: {
      value: '',
      isRequired: false,
      name: 'Contact email',
      validators: [
        {
          isValid: (value) => isEmail(value),
          message: 'Email is not valid'
        }
      ]
    },
    [COUNTRY]: { value: '', isRequired: false, name: 'Country' },
    [STATE]: { value: '', isRequired: false, name: 'State' },
    [CITY]: { value: '', isRequired: false, name: 'City' },
    [ZIP_CODE]: { value: '', isRequired: false, name: 'Zip code' },
    [LONGITUDE]: { value: '', isRequired: false, name: 'Latitude' },
    [LATITUDE]: { value: '', isRequired: false, name: 'Longitude' },
    [DISTRICT]: { value: '', isRequired: false, name: 'District' },
    [ADDRESS]: { value: '', isRequired: false, name: 'Address' },
    [WEBSITE]: { value: '', isRequired: false, name: 'Website' },
    [DISTRIBUTOR_ID]: { value: '', isRequired: () => !(site && site.id), name: 'Distributor' },
    [CONTACT_NAME]: { value: '', isRequired: false, name: 'Contact name' },
    [ORGANIZATION_ID]: { value: '', isRequired: () => !(site && site.id), name: 'Organization' },
    [TEMPERATURE_TYPE]: { value: temperatureTypeValues[0], isRequired: true, name: 'Temperature type' },
    [MONITOR_ORIENTATION]: { value: 'horizontal', isRequired: true, name: 'Monitor orientation' },
    [STATUS]: { value: '', isRequired: true, name: 'Status' },
  })

  const submitForm = async () => {
    const siteObject = {
      name: formFields[NAME].value,
      country: formFields[COUNTRY].value,
      state: formFields[STATE].value,
      contactName: formFields[CONTACT_NAME].value,
      longitude: formFields[LONGITUDE].value,
      latitude: formFields[LATITUDE].value,
      contactEmail: formFields[CONTACT_EMAIL].value,
      distributorId: formFields[DISTRIBUTOR_ID].value,
      organizationId: formFields[ORGANIZATION_ID].value,
      website: formFields[WEBSITE].value,
      city: formFields[CITY].value,
      district: formFields[DISTRICT].value,
      address: formFields[ADDRESS].value,
      zipcode: formFields[ZIP_CODE].value,
      temperatureType: formFields[TEMPERATURE_TYPE].value,
      monitorOrientation: formFields[MONITOR_ORIENTATION].value,
      status: formFields[STATUS].value
    }

    activateSpinner()
    try {
      if (!site.id) {
        const createdSite = await addSite(siteObject)
        siteAdded(createdSite)
      } else {
        const editedSite = await editSite(siteObject, site.id)
        siteEdited(editedSite)
      }
    } catch (err) {
      if (err.message === 'EXISTS') {
        setIsProceedModalOpen(true)
      } else {
        showErrorNotification(err.message)
      }
    }
    disableSpinner()
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleSaveBtnClick = () => {
    processForm()
    dispatch(siteMonitorOrientationSuccess(formFields.monitorOrientation.value))
  }

  useEffect(() => {
    setFormFields(
      (formFields) =>
      ({
        [NAME]: { ...formFields[NAME], value: site[NAME] || '' },
        [COUNTRY]: { ...formFields[COUNTRY], value: site[COUNTRY] || '' },
        [STATE]: { ...formFields[STATE], value: site[STATE] || '' },
        [CITY]: { ...formFields[CITY], value: site[CITY] || '' },
        [ZIP_CODE]: { ...formFields[ZIP_CODE], value: site[ZIP_CODE] || '' },
        [LONGITUDE]: { ...formFields[LONGITUDE], value: site[LONGITUDE] || '' },
        [LATITUDE]: { ...formFields[LATITUDE], value: site[LATITUDE] || '' },
        [DISTRICT]: { ...formFields[DISTRICT], value: site[DISTRICT] || '' },
        [CONTACT_EMAIL]: { ...formFields[CONTACT_EMAIL], value: site[CONTACT_EMAIL] || '' },
        [CONTACT_NAME]: { ...formFields[CONTACT_NAME], value: site[CONTACT_NAME] || '' },
        [WEBSITE]: { ...formFields[WEBSITE], value: site[WEBSITE] || '' },
        [ADDRESS]: { ...formFields[ADDRESS], value: site[ADDRESS] || '' },
        [MONITOR_ORIENTATION]: { ...formFields[MONITOR_ORIENTATION], value: site[MONITOR_ORIENTATION] || 'horizontal' },
        [TEMPERATURE_TYPE]: {
          ...formFields[TEMPERATURE_TYPE],
          value: site[TEMPERATURE_TYPE] || temperatureTypeValues[0]
        },
        [DISTRIBUTOR_ID]: {
          ...formFields[DISTRIBUTOR_ID],
          value: currentDashboardUser.entity.name === 'distributor' || currentDashboardUser.entity.name === 'organization' ? currentDashboardUser.distributorId : site[DISTRIBUTOR_ID] || ''
        },
        [ORGANIZATION_ID]: {
          ...formFields[ORGANIZATION_ID],
          value: currentDashboardUser.entity.name === 'organization' ? currentDashboardUser.organizations && currentDashboardUser.organizations.length && currentDashboardUser.organizations[0].id : site[ORGANIZATION_ID] || ''
        },
        [STATUS]: { ...formFields[STATUS], value: site[STATUS] || 'active' },

      })
    )
  }, [site]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [])
  const handleTextChange = (event, fieldName) => {
    if (siteCampaignActive) {
      if (siteMonitorOrientation !== event.target.value && fieldName === "monitorOrientation") {
        dispatch(popUpSuccess(true))
        setFormFields({
          ...formFields,
          [fieldName]: { ...formFields[fieldName], value: event.target.value },
          [fieldName + VALIDATION_STATE]: ''
        })
        dispatch(siteMonitorOrientationSuccess(event.target.value))
      }
      else {
        setFormFields({
          ...formFields,
          [fieldName]: { ...formFields[fieldName], value: event.target.value },
          [fieldName + VALIDATION_STATE]: ''
        })
        dispatch(siteMonitorOrientationSuccess(event.target.value))
      }
    }
    else {
      setFormFields({
        ...formFields,
        [fieldName]: { ...formFields[fieldName], value: event.target.value },
        [fieldName + VALIDATION_STATE]: ''
      })
      dispatch(siteMonitorOrientationSuccess(event.target.value))
    }
  }
  return (
    <GridContainer id="content-pane-layout" justify="center">
      {
        open === true &&
        <WarningModal processForm={processForm} formFields={formFields} setFormFields={setFormFields} MONITOR_ORIENTATION={MONITOR_ORIENTATION} />
      }
      <ProceedModal
        isOpen={isProceedModalOpen}
        onClose={() => {
          setIsProceedModalOpen(false);
        }
        }
        msg="Organizations you chose might belong to a different site, do you still want to proceed?"
        onApply={() => {
          setIsProceedModalOpen(false)
          submitForm(true)
        }}
      />
      <GridItem md={9}>
        <Card style={isSitePage ? { boxShadow: 'none' } : {}}>
          {!isSitePage
            ? <CardHeader>
              <CardIcon color="info">
                <Edit
                  className={classes.cardHeaderIcon}
                  style={{ fontSize: 30 }}
                />
              </CardIcon>
            </CardHeader>
            : null}
          <CardBody>
            <div>
              <form>
                <GridContainer
                  direction="column"
                  justify="center"
                  alignItems="center"
                  id="card-body-section"
                >
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="siteName"
                      labelText={`${t('Name')} *`}
                      inputProps={{
                        value: formFields[NAME].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="siteCountry"
                      labelText={t('Country')}
                      inputProps={{
                        value: formFields[COUNTRY].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, COUNTRY)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('State')}
                      id="siteState"
                      inputProps={{
                        value: formFields[STATE].value,
                        type: 'name',
                        onChange: event => {
                          handleTextChange(event, STATE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('City')}
                      id="siteCity"
                      inputProps={{
                        value: formFields[CITY].value,
                        onChange: event => {
                          handleTextChange(event, CITY)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('District')}
                      id="siteDistrict"
                      inputProps={{
                        value: formFields[DISTRICT].value,
                        onChange: event => {
                          handleTextChange(event, DISTRICT)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('Address')}
                      id="siteAddress"
                      inputProps={{
                        value: formFields[ADDRESS].value,
                        onChange: event => {
                          handleTextChange(event, ADDRESS)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('Latitude')}
                      inputProps={{
                        value: formFields[LATITUDE].value,
                        type: 'number',
                        onChange: event => {
                          handleTextChange(event, LATITUDE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      labelText={t('Longitude')}
                      inputProps={{
                        value: formFields[LONGITUDE].value,
                        type: 'number',
                        onChange: event => {
                          handleTextChange(event, LONGITUDE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="siteZipCode"
                      labelText={t('Zip code')}
                      inputProps={{
                        value: formFields[ZIP_CODE].value,
                        onChange: event => {
                          handleTextChange(event, ZIP_CODE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="siteContactName"
                      labelText={t('Contact name')}
                      inputProps={{
                        value: formFields[CONTACT_NAME].value,
                        onChange: event => {
                          handleTextChange(event, CONTACT_NAME)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="siteContactEmail"
                      labelText={t('Contact email')}
                      inputProps={{
                        value: formFields[CONTACT_EMAIL].value,
                        onChange: event => {
                          handleTextChange(event, CONTACT_EMAIL)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ minWidth: '40vh' }}>
                    <CustomInput
                      id="siteWebsite"
                      labelText={t('Website')}
                      inputProps={{
                        value: formFields[WEBSITE].value,
                        onChange: event => {
                          handleTextChange(event, WEBSITE)
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem style={{
                    width: '40vh',
                    margin: '0 0 17px 0',
                    paddingTop: '27px',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        required
                        style={{
                          color: '#aaaaaa',
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                      >
                        {t('Temperature type')}
                      </InputLabel>
                      <Select
                        style={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#495057'
                        }}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        value={formFields[TEMPERATURE_TYPE].value}
                        inputProps={{
                          name: 'simpleSelect',
                          onChange: event => {
                            handleTextChange(event, TEMPERATURE_TYPE)
                          }
                        }}
                      >
                        {
                          temperatureTypeValues.map(item => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={item}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </GridItem>
                  {!isEdit && currentDashboardUser.entity.name === 'neo'
                    ? <GridItem style={{
                      width: '40vh',
                      margin: '0 0 17px 0',
                      paddingTop: '27px',
                      position: 'relative',
                      verticalAlign: 'unset'
                    }}
                    >
                      <FormControl fullWidth>
                        <InputLabel
                          style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          id="editSiteDistributor"
                        >
                          {`${t('Distributor')} *`}
                        </InputLabel>
                        <Select
                          style={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#495057'
                          }}
                          id="editSiteDistributorSelect"
                          input={<Input />}
                          renderValue={(selected) => distributors[formFields[DISTRIBUTOR_ID].value]}
                          value={formFields[DISTRIBUTOR_ID].value}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          inputProps={{
                            onChange: event => {
                              handleTextChange(event, DISTRIBUTOR_ID)
                            }
                          }}
                        >
                          {
                            Object.keys(distributors).map(item => {
                              return (
                                <MenuItem
                                  id="editSiteDistributorSelectMenuItem"
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={item}
                                  value={item}
                                >
                                  <Checkbox
                                    id="editSiteDistributorSelectCheckbox"
                                    checkedIcon={<Check
                                      className={classes.checkedIcon}
                                      style={{ color: INFO_COLOR }}
                                    />}
                                    icon={<Check
                                      className={classes.uncheckedIcon}
                                    />}
                                    checked={formFields[DISTRIBUTOR_ID].value === item}
                                  />
                                  <ListItemText primary={distributors[item]} />
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    : null}
                  {!isEdit && currentDashboardUser.entity.name !== 'organization'
                    ? <GridItem style={{
                      width: '40vh',
                      margin: '0 0 17px 0',
                      paddingTop: '27px',
                      position: 'relative',
                      verticalAlign: 'unset'
                    }}
                    >
                      <FormControl fullWidth>
                        <InputLabel
                          style={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            fontWeight: 400
                          }}
                          id="editProfileOrganization"
                        >
                          {`${t('Organization')} *`}
                        </InputLabel>
                        <Select
                          style={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#495057'
                          }}
                          id="editProfileOrganizationSelect"
                          input={<Input />}
                          renderValue={(selected) => {
                            const organization = organizations[formFields[ORGANIZATION_ID].value]
                            return organization && organization.name
                          }}
                          value={formFields[ORGANIZATION_ID].value}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          inputProps={{
                            onChange: event => {
                              handleTextChange(event, ORGANIZATION_ID)
                            }
                          }}
                        >
                          {
                            Object.keys(organizations).map(item => {
                              if (organizations[item].distributorId !== formFields[DISTRIBUTOR_ID].value) return null

                              return (
                                <MenuItem
                                  id="editProfileOrganizationSelectMenuItem"
                                  key={item}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={item}
                                >
                                  <Checkbox
                                    id="editProfileOrganizationSelectCheckbox"
                                    checkedIcon={<Check
                                      className={classes.checkedIcon}
                                      style={{ color: INFO_COLOR }}
                                    />}
                                    icon={<Check
                                      className={classes.uncheckedIcon}
                                    />}
                                    checked={formFields[ORGANIZATION_ID].value === item}
                                  />
                                  <ListItemText primary={organizations[item].name} />
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    : null}
                  <GridItem
                    style={{
                      width: '40vh',
                      margin: '0 0 17px 0',
                      paddingTop: '27px',
                      position: 'relative',
                      verticalAlign: 'unset'
                    }
                    }
                  >
                    <div
                      style={classes.unitConfigurationHeader}
                    >{t('Monitor position')}
                    </div>
                    <RadioGroup
                      value={
                        // siteMonitorOrientation
                        formFields[MONITOR_ORIENTATION].value ?
                          formFields[MONITOR_ORIENTATION].value : 'horizontal'
                      }
                      onChange={
                        (e) => handleTextChange(e, MONITOR_ORIENTATION)
                      }
                    >
                      <FormControlLabel
                        value="horizontal"
                        control={<Radio color={'primary'} />}
                        label={<StayPrimaryLandscape />
                        } />
                      <FormControlLabel
                        value="vertical"
                        control={<Radio color={'primary'} />}
                        label={<StayCurrentPortrait />} />
                    </RadioGroup>
                  </GridItem>
                  <GridItem style={{
                    width: '40vh',
                    margin: '0 0 17px 0',
                    paddingTop: '27px',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">{t('Status')}</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={formFields[STATUS].value}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        inputProps={{
                          onChange: event => {
                            handleTextChange(event, STATUS);
                          }
                        }}
                        label={t('Status')}
                      >
                        <MenuItem value="active">{t('Active')}</MenuItem>
                        <MenuItem value="archived">{t('Archived')}</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>

                </GridContainer>
                <GridContainer
                  justify="center"
                  alignItems="center"
                  id="card-footer-section"
                >
                  <GridItem>
                    <LoadingButton
                      color="info"
                      type="submit"
                      id="siteEditSaveButton"
                      onClick={(e) => {
                        e.preventDefault()
                        handleSaveBtnClick()
                      }}
                      fullWidth
                      disabled={isAwaitingResponse}
                      isLoading={isAwaitingResponse}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </GridItem>
                </GridContainer>
              </form>

            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

EditSite.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withTranslation()(EditSite))))
