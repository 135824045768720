import { createSlice } from '@reduxjs/toolkit'

const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    pages: {},
    error: false,
  },
  reducers: {
    pagesStart: (state, action) => {
      state.isFetching = true
      state.error = false
      //no action required...u can remove action
    },
    pagesSuccess: (state, action) => {
      state.isFetching = false
      state.pages = action.payload

    },
    pagesFailure: (state, action) => {
      state.isFetching = false
      state.error = true
      //no action required...u can remove action
    },
    pagesLogoutSuccess: (state, action) => {
      state.isFetching = false
      state.pages = ''
    }
  },
})

export const {
  pagesStart,
  pagesSuccess,
  pagesFailure,
} = pagesSlice.actions

const pagesReducer = pagesSlice.reducer //it should be export default
export default pagesReducer