import { Checkbox, ListItemText, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import { useStyles } from '../Shift/shiftStyles'
import InputBase from '@material-ui/core/InputBase'
import withStyles from '@material-ui/core/styles/withStyles'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import groupsOfOrganization from '../../endpoint-requests/groups/groupsOfOrganization'
import SiteSelectionLoader from '../Shift/SiteSelectionLoader'
import addWasherModalStyle from '../../assets-soapy/jss/soapy/views/addWasherModalStyle.jsx'
import Card from '../../components-soapy/Card/Card.jsx'
import ImageUploadCard from '../../components-soapy/ImageUpload/ImageUploadCard.jsx'
import LoadingButton from '../../components-soapy/LoadingButton.jsx'
import Modal from '../../components-soapy/Modal.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../containers/withErrorNotification.js'
import addWasher from '../../endpoint-requests/user/addWasher.js'
import editWasher from '../../endpoint-requests/user/editWasher.js'
import useFormFields from '../../shared/hooks/useFormFields.js'
import validateFormFields from '../../utils/validateFormFields.js'
import { verifyLength } from '../../utils/validators'
import {
  DESCRIPTION,
  DOWN,
  FRONT,
  LEFT,
  NAME,
  ORGANIZATION_ID,
  RFID,
  RIGHT,
  UP,
  VALIDATION_STATE
} from './constants.js'

const INFO_COLOR = '#00acc1'

const MAX_IMAGE_SIZE = 5 // in mb
const IMAGE_FORMATS = 'image/png, image/jpg, image/jpeg'

function getOrganizationId (currentDashboardUser, washer) {
  if (currentDashboardUser.entity.name === 'organization') {
    return currentDashboardUser.organizations && currentDashboardUser.organizations.length && currentDashboardUser.organizations[0].id
  } else {
    return washer.organizationId
  }
}

let spinnerTimeout = null

const AddWasherModal = ({
  groups,
  classes,
  isSitePage = false,
  washerEdited,
  washerAdded,
  currentDashboardUser,
  showErrorNotification,
  organizations,
  washer,
  onClose,
  isOpen,
  t
}) => {
  const isEdit = !!washer.id
  const localClasses = useStyles()

  //-----------------Multi Group Select--------------//
  const currentGroupsNames = []
  const [groupsOfOrganizationData, setGroupsOfOrganizationData] = useState([])
  const [currentGroupsArray, setCurrentGroupsArray] = useState(washer?.groups || [])
  const [currentGroupsNamesArray, setCurrentGroupsNamesArray] = useState([])
  const [currentGroupsIdsString, setCurrentGroupsIdsString] = useState(undefined)
  const [loaderTimer, setLoaderTimer] = useState(false)
  const { formFields, setFormFields } = useFormFields({
    [DESCRIPTION]: {
      value: '',
      isRequired: true,
      name: 'Description',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 100),
          message: 'Description should not be empty, and up to 100 characters'
        }
      ]
    },
    [ORGANIZATION_ID]: { value: '', isRequired: true, name: 'Organization' },
    [RFID]: { value: '', isRequired: false, name: 'Card id' },
    [FRONT]: {
      value: '',
      isRequired: false,
      name: 'Front',
      validators: [
        {
          isValid: (file) => file.size / 1024 / 1024 < MAX_IMAGE_SIZE,
          message: 'Image should be less than 5 mb'
        }
      ]
    },
    [LEFT]: {
      value: '',
      isRequired: false,
      name: 'Left',
      validators: [
        {
          isValid: (file) => file.size / 1024 / 1024 < MAX_IMAGE_SIZE,
          message: 'Image should be less than 5 mb'
        }
      ]
    },
    [RIGHT]: {
      value: '',
      isRequired: false,
      name: 'Right',
      validators: [
        {
          isValid: (file) => file.size / 1024 / 1024 < MAX_IMAGE_SIZE,
          message: 'Image should be less than 5 mb'
        }
      ]
    },
    [UP]: {
      value: '',
      isRequired: false,
      name: 'Up',
      validators: [
        {
          isValid: (file) => file.size / 1024 / 1024 < MAX_IMAGE_SIZE,
          message: 'Image should be less than 5 mb'
        }
      ]
    },
    [DOWN]: {
      value: '',
      isRequired: false,
      name: 'Down',
      validators: [
        {
          isValid: (file) => file.size / 1024 / 1024 < MAX_IMAGE_SIZE,
          message: 'Image should be less than 5 mb'
        }
      ]
    }
  })
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const organizationsObj = {}
  organizations.forEach(o => {
    organizationsObj[o.id] = o
  })
  const handleTextChange = (event, fieldName) => {
    setFormFields({
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value: event.target.value },
      [fieldName + VALIDATION_STATE]: ''
    })
  }

  const handleOrganizationChange = (event, value) => {
    setLoaderTimer(true)
    if (value) {
      setCurrentGroupsArray([])
      setFormFields({
        ...formFields,
        [ORGANIZATION_ID]: { ...formFields[ORGANIZATION_ID], value: value.id },
        [ORGANIZATION_ID + VALIDATION_STATE]: ''
      })
    }
  }

  const getGroups = async (organizationId) => {
    try {
      const groupsData = await groupsOfOrganization(organizationId)
      setGroupsOfOrganizationData(groupsData)
      setLoaderTimer(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (formFields.organizationId.value !== '') {
      getGroups(formFields.organizationId.value)
    }
  }, [formFields.organizationId.value])

  useEffect(() => {
    if (washer?.groups?.length > 0) {
      getGroupsNames(washer?.groups)
      getGroupsIds(washer?.groups)
    }
  }, [])

  const handleMultiSelectGroups = (group) => {
    if (currentGroupsNamesArray.length === 0) {
      let array = []
      array.push(group)
      formatGroups(array)
      return
    } else if (!currentGroupsNamesArray?.includes(group.name)) {
      formatGroups([...currentGroupsArray, group])
      return
    } else {
      const filteredGroups = currentGroupsArray.filter((currentGroup) => currentGroup.id !== group.id)
      formatGroups(filteredGroups)
      return
    }
  }

  const formatGroups = (filteredGroups) => {
    getGroupsNames(filteredGroups)
    getGroupsIds(filteredGroups)
    setCurrentGroupsArray(filteredGroups)
  }

  const getGroupsNames = (groups) => {
    const groupNames = groups.map((group) => {
      return group.name
    })
    setCurrentGroupsNamesArray(groupNames)
  }

  const getGroupsIds = (groups) => {
    const groupIds = groups.map((group) => {
      return group.id
    }).join(',')
    setCurrentGroupsIdsString(groupIds !== '' ? groupIds : undefined)
  }

  const handleImageChange = (value, fieldName) => {
    if (fieldName === 'all') {
      setFormFields({
        ...formFields,
        [FRONT]: { ...formFields[FRONT], value: value[0] || null },
        [FRONT + VALIDATION_STATE]: '',
        [LEFT]: { ...formFields[LEFT], value: value[1] || null },
        [LEFT + VALIDATION_STATE]: '',
        [RIGHT]: { ...formFields[RIGHT], value: value[2] || null },
        [RIGHT + VALIDATION_STATE]: '',
        [UP]: { ...formFields[UP], value: value[3] || null },
        [UP + VALIDATION_STATE]: '',
        [DOWN]: { ...formFields[DOWN], value: value[4] || null },
        [DOWN + VALIDATION_STATE]: '',
      })
    } else {
      setFormFields({
        ...formFields,
        [fieldName]: { ...formFields[fieldName], value },
        [fieldName + VALIDATION_STATE]: ''
      })
    }
  }

  useEffect(() => {
    setFormFields(
      (formFields) => ({
        [DESCRIPTION]: { ...formFields[DESCRIPTION], value: washer[NAME] || '' },
        [RFID]: { ...formFields[RFID], value: washer[RFID] || '' },
        [ORGANIZATION_ID]: {
          ...formFields[ORGANIZATION_ID],
          value: getOrganizationId(currentDashboardUser, washer) || ''
        },
        [FRONT]: { ...formFields[FRONT], value: null },
        [LEFT]: { ...formFields[LEFT], value: null },
        [RIGHT]: { ...formFields[RIGHT], value: null },
        [UP]: { ...formFields[UP], value: null },
        [DOWN]: { ...formFields[DOWN], value: null }
      })
    )
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [washer, isOpen, isEdit, currentDashboardUser]) // eslint-disable-line react-hooks/exhaustive-deps

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const submitForm = async () => {
    const formData = new FormData()
    formData.append('description', formFields[DESCRIPTION].value)
    formData.append('organizationId', formFields[ORGANIZATION_ID].value)
    formData.append('groupsIds', currentGroupsIdsString)

    formFields[RFID].value && formData.append('rfid', formFields[RFID].value)
    formFields[LEFT].value && formData.append('images', formFields[LEFT].value, formFields[LEFT].value.name)
    formFields[RIGHT].value && formData.append('images', formFields[RIGHT].value, formFields[RIGHT].value.name)
    formFields[UP].value && formData.append('images', formFields[UP].value, formFields[UP].value.name)
    formFields[DOWN].value && formData.append('images', formFields[DOWN].value, formFields[DOWN].value.name)
    formFields[FRONT].value && formData.append('images', formFields[FRONT].value, formFields[FRONT].value.name)

    activateSpinner()
    try {
      let newWasher
      if (isEdit) {
        newWasher = await editWasher(formData, washer.id)
      } else {
        newWasher = await addWasher(formData)
      }
      disableSpinner()
      isEdit ? washerEdited(newWasher) : washerAdded(newWasher)
    } catch (err) {
      disableSpinner()
      showErrorNotification(err.message)
    }
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    return () => {
      disableSpinner()
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      t={t}
      hideActions
      showGoBackButton
      dialogPaper={classes.dialogPaper}
      onCancel={() => {
        disableSpinner()
        onClose()
      }}
      title={!washer || !Object.keys(washer).length ? t('Add Washer') : t('Edit Washer')}
    >
      <GridContainer id="content-pane-layout" justify="center">

        <GridItem md={9}>
          <Card style={{ boxShadow: 'none' }}>
            <CardBody>
              <GridContainer>
                <GridItem
                  md={4}
                  style={{
                    marginBottom: '2rem'
                  }}
                >
                  <Typography style={{ fontSize: '1.2rem' }}>Description*</Typography>
                </GridItem>
                <GridItem
                  md={8}
                >
                  <InputBase
                    id="description"
                    style={{
                      width: '20rem'
                    }}
                    inputProps={{
                      className: classes.input
                    }}
                    className={formFields[DESCRIPTION + VALIDATION_STATE] === 'error' ? classes.inputError : ''}
                    value={formFields[DESCRIPTION].value}
                    onChange={event => {
                      handleTextChange(event, DESCRIPTION)
                    }}
                  />
                </GridItem>
                {
                  currentDashboardUser.entity.name === 'neo' || currentDashboardUser.entity.name === 'distributor'
                    ? <>
                      <GridItem
                        md={4}
                        style={{
                          marginBottom: '2rem'
                        }}
                      >
                        <Typography style={{ fontSize: '1.2rem' }}>Organization*</Typography>
                      </GridItem>
                      <GridItem
                        md={8}
                      >
                        <Autocomplete
                          style={{
                            width: '20rem',
                            height: '3rem'
                          }}
                          disablePortal
                          disableClearable
                          onChange={handleOrganizationChange}
                          id="combo-box-demo"
                          size="medium"
                          disabled={isEdit}
                          value={organizationsObj[formFields[ORGANIZATION_ID].value]
                            ? organizationsObj[formFields[ORGANIZATION_ID].value]
                            : ''}
                          classes={{ input: classes.autocomplete }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) =>
                            <TextField
                              className={
                                formFields[ORGANIZATION_ID + VALIDATION_STATE] === 'error'
                                  ? classes.inputError
                                  : ''
                              }
                              {...params}
                              variant="outlined"
                            />}
                          options={organizations}
                        />
                      </GridItem>
                    </>
                    : null
                }
                {formFields.organizationId !== '' && loaderTimer === true ?
                  <>
                    <GridItem
                      md={4}
                      style={{
                        marginBottom: '2rem'
                      }}
                    >
                      <Typography style={{ fontSize: '1.2rem' }}></Typography>
                    </GridItem>
                    <GridItem
                      md={8}
                      style={{
                        marginTop: '1.5rem',
                        marginBottom: '1.5rem',
                        position: 'relative',
                        left: '12%'
                      }}
                    >
                      <SiteSelectionLoader/>
                    </GridItem>
                  </> :
                  <>
                    <GridItem
                      md={4}
                      style={{
                        marginBottom: '2rem'
                      }}
                    >
                      <Typography style={{ fontSize: '1.2rem' }}>Select Groups</Typography>
                    </GridItem>
                    <GridItem
                      md={8}
                      style={{
                        marginBottom: '1rem'
                      }}
                    >
                      <Select
                        variant="outlined"
                        value={currentGroupsArray}
                        style={{ width: '320px', fontSize: '14px', height: '45px' }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        renderValue={(currentGroupsArray) => currentGroupsArray.map((item) => item.name)?.join(', ')}
                      >
                        {groupsOfOrganizationData?.map((group) => (
                          <MenuItem key={group.id}
                                    value={group}
                                    onClick={() => handleMultiSelectGroups(group)}
                          >
                            <Checkbox
                              id="groupDistributorSelectCheckbox"
                              checked={currentGroupsArray.find((currentGroup) => currentGroup.id === group.id) !== undefined}
                              checkedIcon={<Check
                                className={classes.checkedIcon}
                                style={{ color: INFO_COLOR }}
                              />}
                              icon={<Check
                                className={classes.uncheckedIcon}
                              />}
                            />
                            <ListItemText
                              primary={group.name} localClasses={{ primary: localClasses.selectAllText }}
                              selected={true}/>
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                  </>}
                <GridItem
                  md={4}
                  style={{
                    marginBottom: '2rem'
                  }}
                >
                  <Typography style={{ fontSize: '1.2rem' }}>Card id</Typography>
                </GridItem>
                <GridItem
                  md={8}
                >
                  <InputBase
                    id="washersCardId"
                    style={{
                      width: '20rem'
                    }}
                    inputProps={{
                      className: classes.input
                    }}
                    className={formFields[RFID + VALIDATION_STATE] === 'error' && classes.inputError}
                    value={formFields[RFID].value}
                    onChange={event => {
                      handleTextChange(event, RFID)
                    }}
                  />
                </GridItem>
                <GridItem md={4}>
                  <GridContainer>
                    <GridItem
                      md={12}
                      style={{
                        marginBottom: '1rem'
                      }}
                    >
                      <Typography style={{ fontSize: '1.2rem' }}>Photos</Typography>
                    </GridItem>
                    <GridItem
                      md={12}
                      style={{
                        marginBottom: '1rem'
                      }}
                    >
                      <Typography style={{ fontSize: '1rem' }}>Upload 5 pictures</Typography>
                    </GridItem>
                    <GridItem
                      md={12}
                    >
                      <Typography style={{ fontSize: '1rem' }}>Notes:</Typography>
                      <ul>
                        <li><Typography>All washer pictures need to include both eyes, that are recognizable on each
                          picture. Otherwise, an attempt to add a washer will be declined.
                        </Typography>
                        </li>
                        <li><Typography>Photo must be with a clean, simple, white background.</Typography></li>
                        <li><Typography>Photo must be in sharp focus, and correctly exposed, not too dark or too
                          light.
                        </Typography>
                        </li>
                        <li><Typography>Maximum image size is 5 MB.</Typography></li>
                        {
                          isEdit
                            ? <>
                              <li><Typography>Please note that we are not saving pictures therefore you don't see their
                                preview here.
                              </Typography>
                              </li>
                            </>
                            : null
                        }

                      </ul>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem
                  md={8}
                >
                  <ImageUploadCard
                    isUploadAll={true}
                    onChange={files => {
                      handleImageChange(files, 'all')
                    }}
                    // title="Front"
                    // error={
                    //   formFields[FRONT + VALIDATION_STATE] === 'error'
                    // }
                    // onChange={file => {
                    //   handleImageChange(file, FRONT)
                    // }}
                    // file={formFields[FRONT].value}
                  />
                  <GridContainer
                    style={{
                      marginBottom: '2rem'
                    }}
                  >
                    <GridItem
                      md={4}
                      style={{
                        marginBottom: '1rem'
                      }}
                    >
                      <ImageUploadCard
                        accept={IMAGE_FORMATS}
                        title="Front"
                        error={
                          formFields[FRONT + VALIDATION_STATE] === 'error'
                        }
                        onChange={file => {
                          handleImageChange(file, FRONT)
                        }}
                        file={formFields[FRONT].value}
                      />
                    </GridItem>
                    <GridItem md={4}>
                      <ImageUploadCard
                        accept={IMAGE_FORMATS}
                        title="Left"
                        error={
                          formFields[LEFT + VALIDATION_STATE] === 'error'
                        }
                        onChange={file => {
                          handleImageChange(file, LEFT)
                        }}
                        file={formFields[LEFT].value}
                      />
                    </GridItem>
                    <GridItem md={4}>
                      <ImageUploadCard
                        accept={IMAGE_FORMATS}
                        title="Right"
                        error={
                          formFields[RIGHT + VALIDATION_STATE] === 'error'
                        }
                        onChange={file => {
                          handleImageChange(file, RIGHT)
                        }}
                        file={formFields[RIGHT].value}
                      />
                    </GridItem>
                    <GridItem md={4}>
                      <ImageUploadCard
                        accept={IMAGE_FORMATS}
                        title="Up"
                        error={
                          formFields[UP + VALIDATION_STATE] === 'error'
                        }
                        onChange={file => {
                          handleImageChange(file, UP)
                        }}
                        file={formFields[UP].value}
                      />
                    </GridItem>
                    <GridItem md={4}>
                      <ImageUploadCard
                        accept={IMAGE_FORMATS}
                        title="Down"
                        error={
                          formFields[DOWN + VALIDATION_STATE] === 'error'
                        }
                        onChange={file => {
                          handleImageChange(file, DOWN)
                        }}
                        file={formFields[DOWN].value}
                      />
                    </GridItem>

                  </GridContainer>

                </GridItem>

              </GridContainer>
              <GridContainer
                justify="center"
                alignItems="center"
                id="card-footer-section"
              >
                <GridItem>
                  <LoadingButton
                    color="info"
                    type="submit"
                    id="washerAddSaveButton"
                    onClick={(e) => {
                      e.preventDefault()
                      handleSaveBtnClick()
                    }}
                    fullWidth
                    disabled={isAwaitingResponse}
                    isLoading={isAwaitingResponse}
                  >
                    {t('Save')}
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Modal>
  )
}

export default withErrorNotification(withAuthentication(withStyles(addWasherModalStyle)(withTranslation()(AddWasherModal))))
