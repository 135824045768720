import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export default async (options = {
  order: 'desc',
  orderBy: 'createdAt',
  fetchDefault: false
}) => {
  const queryParams = new URLSearchParams(options)
  const URL = SERVER_PATH + apiEndpoints.sites + '?' + queryParams.toString()

  return resolveRequest(axios.get(URL, { withCredentials: true }))
}
