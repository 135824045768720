import extendedFormsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

const machinesMobileModalStyle = theme => ({
  ...extendedFormsStyle,
  dialogPaper: {
    minHeight: '600px',
    maxHeight: '600px',
    maxWidth: '1100px'
  },
  archivedIconBtn: {
    color: 'grey'
  },
  activeIconBtn: {
    color: theme.palette.primary.main
  }
})

export default machinesMobileModalStyle
