import ExportExcel from '../../components-soapy/Excel/ExportExcel.jsx'
import React from 'react'
import ReactExport from 'react-export-excel'
import { withTranslation } from 'react-i18next'
import {
  CONNECTION_TYPE, CURRENT_BOTTLE_WASH_COUNT, DISTRIBUTOR,
  ID, LAST_SYNC, LAST_VERSION, ORGANIZATION, SERIAL_NUMBER, SITE, STATUS, WLAN_MAC_ADDRESS, ELAN_MAC_ADDRESS
} from '../../shared/data/machines.js'
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const getTableDatasetExcel = (tableRef) => {
  if (!tableRef || !tableRef.current) {
    return
  }
  const tableData = tableRef.current.getResolvedState().sortedData
  const dataset = []
  for (const row of tableData) {
    dataset.push({
      id: row[ID] || '',
      serialNumber: row[SERIAL_NUMBER] || '',
      organization: row[ORGANIZATION] || '',
      distributor: row[DISTRIBUTOR] || '',
      site: row[SITE] || '',
      currentBottleWashCount: row[CURRENT_BOTTLE_WASH_COUNT] || '',
      lastVersion: row[LAST_VERSION] || '',
      connectionType: row[CONNECTION_TYPE] || '',
      status: row[STATUS] ? 'v' : 'x',
      lastSync: row[LAST_SYNC] || '',
      wlanMacAddress: row[WLAN_MAC_ADDRESS] || '',
      elanMacAddress: row[ELAN_MAC_ADDRESS] || '',
    })
  }
  return dataset
}

const UnitConfigurationTableExport = ({
  tableRef,
  t
}) => {
  const excelHeaders = {
    [t('ID')]: 'id',
    [t('Serial number')]: 'serialNumber',
    [t('Organization')]: 'organization',
    [t('Distributor')]: 'distributor',
    [t('Site')]: 'site',
    [t('Soap usage count')]: 'currentBottleWashCount',
    [t('Version')]: 'lastVersion',
    [t('Connection Type')]: 'connectionType',
    [t('Online')]: 'status',
    [t('Last connection')]: 'lastSync',
    [t('WLAN Mac Address')]: 'wlanMacAddress',
    [t('ELAN Mac Address')]: 'elanMacAddress'



  }

  const excelColumns = Object.keys(excelHeaders).map(name => {
    return <ExcelColumn key={name} label={name} value={excelHeaders[name]} />
  })
  return (
    <div>
      <ExportExcel
        fileName={'unit-configurations' + new Date()}
        title={t('Unit Configurations')}
        data={getTableDatasetExcel.bind(null, tableRef)}
        columns={excelColumns}
      />
    </div>
  )
}

export default withTranslation()(UnitConfigurationTableExport)
