import extendedTablesStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'

export default theme => ({
  ...extendedTablesStyle,
  dialogPaper: {
    minHeight: '30rem',
    maxHeight: '30rem',
    minWidth: '50rem',
    maxWidth: '50rem'
  }
})
