import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {},
    error: false,
  },
  reducers: {
    userStart: (state, action) => {
      state.isFetching = true
      state.error = false
      //no action required...u can remove action
    },
    userSuccess: (state, action) => {
      state.isFetching = false
      state.user = action.payload

    },
    userFailure: (state, action) => {
      state.isFetching = false
      state.error = true
      //no action required...u can remove action
    },
    userLogoutSuccess: (state, action) => {
      state.isFetching = false
      state.user = ''
    }
  },
})

export const {
  userStart,
  userSuccess,
  userFailure,
} = userSlice.actions

const userReducer = userSlice.reducer //it should be export default
export default userReducer