import { createSlice } from "@reduxjs/toolkit";

const groupsSlice = createSlice({
    name: "groups",
    initialState: {
        groups: [],
        selectedGroup: null,
        isFetching: false,
        error: false,
        addGroupError: ''
    },
    reducers: {
        getGroupsStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getGroupsSuccess: (state, action) => {
            state.isFetching = false;
            state.groups = action.payload
        },
        getGroupsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        addGroupSuccess: (state, action) => {
            state.isFetching = false;
            state.groups = [action.payload, ...state.groups]
        },
        deleteSelectedGroup: (state, action) => {
            state.groups = state.groups.filter(group => group.id !== action.payload)
        },
        editSelectedGroup: (state, action) => {
            state.groups = state.groups.map(group => group.id === action.payload.id ? action.payload : group)
        }
    },
})

export const {
    getGroupsStart,
    getGroupsSuccess,
    getGroupsFailure,
    addGroupStart,
    addGroupSuccess,
    deleteSelectedGroup,
    editSelectedGroup
} = groupsSlice.actions;


const groupsReducer = groupsSlice.reducer; //it should be export default
export default groupsReducer;