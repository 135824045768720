import { checkIsToday } from "./checkIsToday"
import { getShifts } from "./getShifts"

//updateShiftResult
export const updateShiftResult = async (currentHour, shifts, nowDate, yesterday, sitesIds, minimumWashes, washersIds, minimumResultScore) => {
    try {
        let shiftData = null

        for (let i = 0; i < shifts.length; i++) {
            let fromHour = parseInt(shifts[i].split(" - ")[0].split(":")[0])
            let toHour = parseInt(shifts[i].split(" - ")[1].split(":")[0])
            if (fromHour === toHour) {
                let tomorrow = (new Date(Date.now() + 864e5)).toISOString()
                if (fromHour === 0) {
                    shiftData = await getShifts(
                        1,
                        "",
                        shifts[i],
                        `${nowDate.split("T")[0]} ${shifts[i].split(" - ")[0]}:00`,
                        `${tomorrow.split("T")[0]} ${shifts[i].split(" - ")[1]}:00`,
                        ``,
                        ``,
                        sitesIds,
                        minimumWashes,
                        washersIds,
                        minimumResultScore
                    )
                    return shiftData
                }
                else {
                    shiftData = await getShifts(
                        1,
                        currentHour >= fromHour ? "" : shifts[i],
                        currentHour >= fromHour ? shifts[i] : "",
                        currentHour >= fromHour ? `${nowDate.split("T")[0]} ${shifts[i].split(" - ")[0]}:00` : "",
                        currentHour >= fromHour ? `${tomorrow.split("T")[0]} ${shifts[i].split(" - ")[1]}:00` : "",
                        currentHour >= fromHour ? `` : `${yesterday.split("T")[0]} ${shifts[i].split(" - ")[0]}:00`,
                        currentHour >= fromHour ? `` : `${nowDate.split("T")[0]} ${shifts[i].split(" - ")[0]}:00`,
                        sitesIds,
                        minimumWashes,
                        washersIds,
                        minimumResultScore
                    )
                    return shiftData
                }
            }
            else if (currentHour >= fromHour && currentHour < toHour) {
                if (i !== shifts.length - 1) {
                    const { isTodayFromHour, isTodayToHour } = checkIsToday(i, currentHour, `${shifts[i === 0 ? shifts.length - 1 : i - 1].split(" - ")[0]}:00`, `${shifts[i === 0 ? shifts.length - 1 : i - 1].split(" - ")[1]}:00`, shifts)
                    shiftData = await getShifts(
                        i + 1,
                        shifts[i === 0 ? shifts.length - 1 : i - 1],
                        shifts[i],
                        `${nowDate.split("T")[0]} ${shifts[i].split(" - ")[0]}:00`,
                        `${nowDate.split("T")[0]} ${shifts[i].split(" - ")[1]}:00`,
                        `${isTodayFromHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i === 0 ? shifts.length - 1 : i - 1].split(" - ")[0]}:00`,
                        `${nowDate.split("T")[0]} ${shifts[i === 0 ? shifts.length - 1 : i - 1].split(" - ")[1]}:00`,
                        sitesIds,
                        minimumWashes,
                        washersIds,
                        minimumResultScore
                    )
                    return shiftData
                }
                else {
                    shiftData = await getShifts(
                        i + 1,
                        "",
                        shifts[i],
                        `${nowDate.split("T")[0]} ${shifts[i].split(" - ")[0]}:00`,
                        `${nowDate.split("T")[0]} ${shifts[i].split(" - ")[1]}:00`,
                        ``,
                        ``,
                        sitesIds,
                        minimumWashes,
                        washersIds,
                        minimumResultScore
                    )
                    return shiftData
                }
            }
            else if (i !== shifts.length - 1) {
                if (currentHour >= toHour && currentHour < shifts[i + 1].split(" - ")[0].split(":")[0]) {
                    const { isTodayFromHour, isTodayToHour } = checkIsToday(i, currentHour, `${shifts[i === 0 ? 0 : i].split(" - ")[0]}:00`, `${shifts[i === 0 ? 0 : i].split(" - ")[1]}:00`, shifts)
                    shiftData = await getShifts(
                        0,
                        shifts[i === 0 ? 0 : i],
                        "",
                        ``,
                        ``,
                        `${isTodayFromHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i === 0 ? 0 : i].split(" - ")[0]}:00`,
                        `${isTodayToHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i === 0 ? 0 : i].split(" - ")[1]}:00`,
                        sitesIds,
                        minimumWashes,
                        washersIds,
                        minimumResultScore
                    )
                    return shiftData
                }
                else if (currentHour <= fromHour && currentHour < toHour) {
                    const { isTodayFromHour, isTodayToHour } = checkIsToday(i, currentHour, `${shifts[i === 0 ? 0 : i].split(" - ")[0]}:00`, `${shifts[i === 0 ? 0 : i].split(" - ")[1]}:00`, shifts)
                    shiftData = await getShifts(
                        0,
                        shifts[shifts.length - 1],
                        "",
                        ``,
                        ``,
                        `${isTodayFromHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i === 0 ? 0 : i].split(" - ")[0]}:00`,
                        `${isTodayToHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i === 0 ? 0 : i].split(" - ")[1]}:00`,
                        sitesIds,
                        minimumWashes,
                        washersIds,
                        minimumResultScore
                    )
                    return shiftData
                }
                // else if (currentHour >= fromHour && currentHour >= toHour) {
                //     const { isTodayFromHour, isTodayToHour } = checkIsToday(i, currentHour, `${shifts[i === 0 ? 0 : i].split(" - ")[0]}:00`, `${shifts[i === 0 ? 0 : i].split(" - ")[1]}:00`, shifts)
                //     shiftData = await getShifts(
                //         0,
                //         shifts[shifts.length - 1],
                //         "",
                //         ``,
                //         ``,
                //         `${isTodayFromHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i === 0 ? 0 : i].split(" - ")[0]}:00`,
                //         `${isTodayToHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i === 0 ? 0 : i].split(" - ")[1]}:00`,
                //         sitesIds,
                //         minimumWashes,
                //     )
                //     return shiftData
                // }
            }
            else if (i === shifts.length - 1) {
                const { isTodayFromHour, isTodayToHour } = checkIsToday(i, currentHour, `${shifts[i].split(" - ")[0]}:00`, `${shifts[i].split(" - ")[1]}:00`, shifts)
                shiftData = await getShifts(
                    i + 1,
                    shifts[i - 1],
                    shifts[i],
                    `${isTodayFromHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i].split(" - ")[0]}:00`,
                    `${nowDate.split("T")[0]} ${shifts[i].split(" - ")[1]}:00`,
                    `${isTodayFromHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i - 1].split(" - ")[0]}:00`,
                    `${isTodayToHour ? nowDate.split("T")[0] : yesterday.split("T")[0]} ${shifts[i - 1].split(" - ")[1]}:00`,
                    sitesIds,
                    minimumWashes,
                    washersIds,
                    minimumResultScore
                )
                return shiftData
            }
        }
    } catch (error) {
        console.log(error);
    }
}