import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import failedSession from '../failedSession'

export default async () => {
  const endpoint = apiEndpoints.policies
  const URL = SERVER_PATH + endpoint
  try {
    const res = await axios.get(URL, { withCredentials: true })
    return res.data
  } catch (e) {
    failedSession(e)
    return { didSucceed: false, errprType: 'SERVER_ERROR' }
  }
}
