import { Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMachine } from '@xstate/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import SweetAlert from '../../components-soapy/SweetAlert.jsx'
import fetchMachines from '../../endpoint-requests/machine/getMachines.js'
import UnitConfigurationModal from './UnitConfigurationModal.jsx'
import UnitsTable from './UnitsTable.jsx'

const unitsMachine = Machine({
  id: 'units',
  initial: 'loadMachineData',
  context: {
    machines: {},
    machine: {},
    error: ''
  },
  states: {
    loadMachineData: {
      invoke: {
        src: (context, event) => fetchMachines({ fetchArchived: true, fetchOrphans: true }),
        onDone: {
          target: 'main',
          actions: assign({
            machines: (context, event) => event.data
          })
        },
        onError: {
          target: 'failedFetchingData',
          actions: assign({
            error: (context, event) => event.data.message
          })
        }
      }
    },
    failedFetchingData: {
      type: 'final'
    },
    main: {
      on: {
        OPEN_CONFIGURATION_MODAL: {
          target: 'configurationModal',
          actions: ['setMachine']
        }
      }
    },
    configurationModal: {
      on: {
        CLOSE_CONFIGURATION_MODAL: {
          target: 'main',
          actions: ['setMachine']
        },
        OPEN_SUBMIT_SUCCESS_DIALOG: {
          target: 'submitSuccessDialog',
          actions: ['editMachine']
        },
        LOAD_PAGE: 'loadMachineData'
      }
    },
    submitSuccessDialog: {
      on: {
        CLOSE_SUBMIT_SUCCESS_MODAL: 'main'
      }
    }
  }
},
{
  actions: {
    setMachine: assign({
      machine: (context, event) => event.machine
    }),
    editMachine: assign({
      machines: (context, event) => context.machines.map(machine => machine.id === event.machine.id ? event.machine : machine)
    })
  }
}

)

const UnitsContainer = ({ t }) => {
  const [current, send] = useMachine(unitsMachine)

  switch (current.value) {
    case 'loadMachineData':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t(current.context.error)}</div>
    default: {
      const {
        machines,
        machine
      } = current.context

      const isSubmitSuccessDialogOpen = current.value === 'submitSuccessDialog'
      const isConfigurationModalOpen = current.value === 'configurationModal' || isSubmitSuccessDialogOpen

      return (
        <FullPagePaper>
          <UnitConfigurationModal
            isOpen={isConfigurationModalOpen}
            closeModal={() => {
              send({ type: 'CLOSE_CONFIGURATION_MODAL', machine: {} })
            }}
            onApplyConfigurationModal={(machine) => {
              send({ type: 'OPEN_SUBMIT_SUCCESS_DIALOG', machine })
            }}
            machine={machine}
          />
          {isSubmitSuccessDialogOpen ? (
            <SweetAlert
              success
              id='editProfileSuccessAlert'
              confirmBtnText={t('Ok')}
              title={<>
                <Typography variant='title'>{t('Saved') + '!'}</Typography>
                <Typography variant='body1'>Please keep in mind that unit will be updated within 5 minutes!</Typography>
                     </>}
              style={{
                position: 'absolute'
              }}
              onConfirm={() => {
                send('CLOSE_SUBMIT_SUCCESS_MODAL')
              }}
              onCancel={() => {
                send('CLOSE_SUBMIT_SUCCESS_MODAL')
              }}
            />
          ) : null}
          <UnitsTable
            machines={machines}
            openConfigurationModal={(machine) => {
              send({ type: 'OPEN_CONFIGURATION_MODAL', machine })
            }}
          />
        </FullPagePaper>
      )
    }
  }
}

export default withTranslation()(UnitsContainer)
