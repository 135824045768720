import React, { useState, useEffect } from 'react'
import { Range, getTrackBackground } from 'react-range'
import { withStyles } from '@material-ui/core'

import twoThumbsStyle from '../../assets-soapy/jss/soapy/components/twoThumbsStyle'

const STEP = 0.1
const MIN = 0
const MAX = 100

const LabeledTwoThumbs = ({
  classes,
  trackWidth,
  step,
  min,
  max,
  values,
  onFinalChange,
  trackRight,
  trackLeft
}) => {
  useEffect(() => {
    if (values && values.length) {
      setState({ values })
    }
  }, [values])
  const [state, setState] = useState({ values: values || [0, 100] })
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }}
    >
      <Range
        id='rangeSlider'
        values={state.values}
        step={step || STEP}
        min={min || MIN}
        max={max || MAX}
        onFinalChange={onFinalChange}
        onChange={((values) => setState({ values }))}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className={classes.track}
            style={{
              ...props.style,
              width: trackWidth || '100%',
              paddingRight: trackRight || '1rem',
              paddingLeft: trackLeft || '1rem'
            }}
          >
            <div
              ref={props.ref}
              className={classes.trackLine}
              style={{
                background: getTrackBackground({
                  values: state.values,
                  colors: ['#ccc', '#00acc1', '#ccc'],
                  min: min || MIN,
                  max: max || MAX
                })

              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => {
          return <div
            {...props}
            id='rangeSliderThumb'
            className={classes.thumb}
            style={{
              ...props.style
            }}
          >
            <div
              className={classes.thumbValue}
            >
              {state.values[index].toFixed(1)}
            </div>

          </div>
        }}
      />
    </div>
  )
}

export default withStyles(twoThumbsStyle)(LabeledTwoThumbs)
