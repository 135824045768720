import React, { useState, useRef } from 'react'
import {
  Marker,
  GoogleMap,
  withScriptjs,
  withGoogleMap,
} from 'react-google-maps'
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'
import MarkerCarrierIcon from './components/MarkerCarrierIcon'

const Map = withScriptjs(
  withGoogleMap(props => {
    const mapRef = useRef(null)
    const [zoom, setZoom] = useState(4)
    const [marker, setMarker] = useState({ hasMarker: false, position: {} })
    const [center, setCenter] = useState({ lat: 32.0467, lng: 34.8429 })
    const filteredSites = props.sites.filter(site => {
      return site.longitude !== null && site.latitude !== null
    })
    //for searching a place
    // const handlePlacesChanged = place => {
    //   setZoom(16)
    //   setCenter({
    //     lat: place.geometry.location.lat(),
    //     lng: place.geometry.location.lng(),
    //   })
    //   setMarker({
    //     hasMarker: true,
    //     position: {
    //       lat: place.geometry.location.lat(),
    //       lng: place.geometry.location.lng(),
    //     },
    //   })
    // }
    return (
      <GoogleMap
        zoom={zoom}
        ref={mapRef}
        center={center}
        defaultOptions={props.defaultOptions}
        onDragEnd={() => setCenter(mapRef.current.getCenter())}
        onZoomChanged={() => setZoom(mapRef.current.getZoom())}
      >
        {/* <SearchBox onPlacesChanged={handlePlacesChanged} /> */}
        {marker.hasMarker && <Marker position={marker.position} />}

        <MarkerClusterer
          averageCenter
          calculator={markerClustererCalculator}
          enableRetinaIcons
          gridSize={30}
        >
          {filteredSites.map((lac, index) => (
            <>
              <MarkerCarrierIcon key={index} lac={lac} />
            </>
          ))}
        </MarkerClusterer>
      </GoogleMap>
    )
  }),
)

export default Map

// https://nooshu.github.io/blog/2012/10/03/marker-cluster-calculator-for-google-maps-v3/
const markerClustererCalculator = (markers, numStyles) => {

  const index = markers.find(marker => marker.icon.condition === 'anormal')
    ? 3
    : markers.find(marker => marker.icon.condition === 'alerta')
      ? 2
      : markers.find(marker => marker.icon.condition === 'normal')
        ? 1
        : 4

  return {
    index: index,
    text: markers.length,
  }
}
