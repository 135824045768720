import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'

const addWasher = async (formData) => {
  const URL = SERVER_PATH + apiEndpoints.washers
  return resolveRequest(
    axios.post(URL, formData, {
      withCredentials: true
    })
  )
}

export default addWasher
