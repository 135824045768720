import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 *
 * @param {object} requestObject
 * @param {string} requestObject.serialNumber
 * @param {Date} requestObject.date
 * @param {Date} requestObject.toDate
 */
export default (requestObject) => {
  const URL = SERVER_PATH + apiEndpoints.washStatus
  return resolveRequest(axios.post(URL, requestObject, {
    withCredentials: true
  }))
}
