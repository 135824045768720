import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import siteModalStyle from '../../../../assets-soapy/jss/soapy/views/siteModalStyle.jsx'
import Modal from '../../../../components-soapy/Modal.jsx'
import EditSiteModal from './EditSite'

const SiteModal = ({ getMid, siteEdited, sites, site, organizations, distributors, isOpen, siteAdded, onClose, t }) => {
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      hideActions
      showGoBackButton
      onCancel={onClose}
      title={!site || !Object.keys(site).length ? t('Add site') : t('Edit site')}
    >
      <div>
        <EditSiteModal
          isClosed={!isOpen}
          site={site}
          sites={sites}
          siteAdded={siteAdded}
          siteEdited={siteEdited}
          distributors={distributors}
          organizations={organizations}
          onClose={onClose}
          isSitePage
        />
      </div>
    </Modal>
  )
}

export default withStyles(siteModalStyle)(withTranslation()(SiteModal))
