// @material-ui/icons
import Fingerprint from '@material-ui/icons/Fingerprint'
import LoginPage from '../pages/Login.jsx'
import RegisterPage from '../pages/Register.jsx'

const mapPathToRoute = {
  '/login': {
    path: '/login',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    icon: Fingerprint,
    component: LoginPage
  },
  '/register/:registrationToken': {
    path: '/register/:registrationToken',
    name: 'Register Page',
    short: 'Register',
    mini: 'RP',
    icon: Fingerprint,
    component: RegisterPage
  }
}

export default mapPathToRoute
