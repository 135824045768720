export const NAME = 'name'
export const DESCRIPTION = 'description'
export const MONITOR_ORIENTATION = "monitorOrientation"
export const ORIENTATION = 'orientation'
export const VALIDATION_STATE = 'validationState'
export const ORGANIZATION_ID = 'organizationId'
export const UPDATE_DATE = 'updateDate'
export const UPDATED_BY = 'updatedBy'
export const SITES = 'sites'
export const STATUS = 'status'
export const VIEWS = 'views'
export const WELCOME_ITEMS = 'welcomeItems'
export const RINSING_ITEMS = 'rinsingItems'
