// ##############################
// // // LoginPage view styles
// #############################

import {
  container
} from '../../../../assets/jss/material-dashboard-pro-react.jsx'

export default theme => ({
  container: {
    ...container,
    zIndex: '4',
    paddingTop: '6rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px'
    }
  },
  redirectButton: {
    borderColor: 'white',
    borderRadius: '2.5rem',
    width: '20rem',
    marginBottom: '3.5rem'
  },
  card: {
    backgroundColor: '#03b2cb',
    borderRadius: '6px',
    color: 'white'
  }

})
