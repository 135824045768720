import fetchDistributors from '../../endpoint-requests/distributor/fetchDistributors.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useMachine } from '@xstate/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import DeleteModal from '../../components-soapy/ConfirmationModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import Snackbar from '../../components/Snackbar/Snackbar.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import deleteOrganization from '../../endpoint-requests/organization/deleteOrganization.js'
import fetchOrganizations from '../../endpoint-requests/organization/fetchOrganizations'
import fetchOrganizationSites from '../../endpoint-requests/organization/fetchOrganizationSites.js'
import OrganizationModal from './modals/OrganizationModal'
import SiteTableModal from './modals/SiteTableModal.jsx'
import OrganizationsTable from './OrganizationsTable'
import OrganizationsTableHeader from './OrganizationsTableHeader.jsx'

const getOrganizationsMachine = (user) => {
  return Machine({
    id: 'units',
    initial: 'loadingOrganizations',
    context: {
      organizations: {},
      filteredDashboardUserIds: [],
      errorType: '',
      organization: {},
      machineIds: [],
      distributors: {},
      isLoadingSites: false,
      sites: [],
      isDeleteModalOpen: false,
      isSuccessNotificationVisible: false
    },
    states: {
      loadingOrganizations: {
        invoke: {
          src: (context, event) => Promise.all(
            [
              fetchOrganizations(),
              user.entity.name === 'neo' ? fetchDistributors() : []
            ]),
          onDone: {
            target: 'main',
            actions: assign({
              organizations: (context, event) => event.data[0],
              distributors: (context, event) => {
                return event.data[1].reduce((acc, distributor) => {
                  acc[distributor.id] = distributor.name
                  return acc
                }, {})
              }
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.message
            })
          }
        }
      },
      failedFetchingData: {
        type: 'final'
      },
      deleteModal: {
        on: {
          CLOSE_DELETE_MODAL: 'main',
          ORGANIZATION_DELETED: {
            target: 'main',
            actions: ['deleteOrganization']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      },

      main: {
        after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
        exit: ['hideSuccessNotification'],
        on: {
          OPEN_ORGANIZATION_MODAL: {
            target: 'organizationModal',
            actions: ['setOrganization']
          },
          OPEN_SITE_MODAL: {
            target: 'siteModal',
            actions: ['setOrganization']
          },
          OPEN_DELETE_MODAL: {
            target: 'deleteModal',
            actions: ['setOrganization']
          },
          HIDE_SUCCESS_NOTIFICATION: {
            actions: ['hideSuccessNotification']
          }
        }
      },
      siteModal: {
        on: {
          CLOSE_SITE_MODAL: 'main'
        },
        entry: ['showSiteLoading'],
        invoke: {
          src: (context, event) => fetchOrganizationSites(context.organization.id),
          onDone: {
            actions: assign({
              sites: (context, event) => event.data,
              isLoadingSites: (context, event) => false
            })
          },
          onError: {
            target: 'failedFetchingData',
            actions: assign({
              errorType: (context, event) => event.data.error
            })
          }
        }
      },
      organizationModal: {
        on: {
          CLOSE_ORGANIZATION_MODAL: 'main',
          ORGANIZATION_ADDED: {
            target: 'main',
            actions: ['addOrganization']
          },
          ORGANIZATION_EDITED: {
            target: 'main',
            actions: ['editOrganization']
          },
          SHOW_SUCCESS_NOTIFICATION: {
            actions: ['showSuccessNotification']
          }
        }
      }

    }
  },
    {
      actions: {
        showSiteLoading: assign({
          isLoadingSites: true
        }),
        showSuccessNotification: assign({
          isSuccessNotificationVisible: true
        }),
        hideSuccessNotification: assign({
          isSuccessNotificationVisible: false
        }),
        editOrganization: assign({
          organizations: (context, event) => context.organizations.map(organization => organization.id === event.organization.id ? event.organization : organization)
        }),
        addOrganization: assign({
          organizations: (context, event) => [event.organization, ...context.organizations]
        }),
        setOrganization: assign({
          organization: (context, event) => event.organization
        }),
        deleteOrganization: assign({
          organizations: (context, event) => context.organizations.filter(organization => organization.id !== event.organization.id)
        })
      },
      guards: {
        isSuccessNotificationVisible: (context, event) => {
          return !!context.isSuccessNotificationVisible
        }
      }

    }
  )
}

const OrganizationsContainer = ({ t, currentDashboardUser }) => {
  const [current, send] = useMachine(getOrganizationsMachine(currentDashboardUser))
  switch (current.value) {
    case 'loadingOrganizations':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t('Session Is Over Error')}</div>
    default: {
      const {
        organizations,
        organization,
        distributors,
        isSuccessNotificationVisible,
        isLoadingSites,
        sites
      } = current.context
      const isOrganizationModalOpen = current.value === 'organizationModal'
      const isDeleteModalOpen = current.value === 'deleteModal'
      const isSiteModalOpen = current.value === 'siteModal'
      return (
        <FullPagePaper>
          <Snackbar
            place='tc'
            color='success'
            icon={NotificationsIcon}
            message={t('Saved') + '!'}
            open={isSuccessNotificationVisible}
          />
          <SiteTableModal
            isOpen={isSiteModalOpen}
            title={t('Organization Sites')}
            isLoading={isLoadingSites}
            onCancel={() => {
              send('CLOSE_SITE_MODAL')
            }}
            sites={sites}
          />
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={(isDeleteClicked = false) => {
              send('CLOSE_DELETE_MODAL')
            }}
            onApply={async () => {
              await deleteOrganization(organization.id)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'ORGANIZATION_DELETED', organization }])
            }}
          />
          <OrganizationModal
            isOpen={isOrganizationModalOpen}
            organization={organization}
            organizations={organizations}
            distributors={distributors}
            onClose={() => send('CLOSE_ORGANIZATION_MODAL')}
            organizationAdded={(organization) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'ORGANIZATION_ADDED', organization }])
            }}
            organizationEdited={(organization) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'ORGANIZATION_EDITED', organization }])
            }}
          />
          <OrganizationsTableHeader
            openOrganizationModal={() => {
              send({ type: 'OPEN_ORGANIZATION_MODAL', organization: {} })
            }}
          />
          <OrganizationsTable
            organizationAdded={(organization) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'ORGANIZATION_ADDED', organization }])
            }}
            organizationEdited={(organization) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'ORGANIZATION_EDITED', organization }])
            }}
            allOrganizations={organizations}
            openDeleteModal={(organization) => {
              send({ type: 'OPEN_DELETE_MODAL', organization })
            }}
            openSiteModal={(organization) => {
              send({ type: 'OPEN_SITE_MODAL', organization })
            }}

            openUpdateModal={(organization) => {
              send({ type: 'OPEN_ORGANIZATION_MODAL', organization })
            }}

          />
        </FullPagePaper>
      )
    }
  }
}

export default withAuthentication(withTranslation()(OrganizationsContainer))
