import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'

import {
  apiEndpoints,
  SERVER_PATH
} from '../endpoints.js'

export default async () => {
  const URL = SERVER_PATH + apiEndpoints.dashboardUsers
  return resolveRequest(axios.get(URL, {
    withCredentials: true
  }))
}
