import defaultFilterMethod from '../../../shared/react-table/defaultFilterMethod.js'
import addReactTableFilterPlaceholder from '../../../shared/react-table/addReactTableFilterPlaceholder.js'
import siteTableModalStyle from '../../../assets-soapy/jss/soapy/views/siteTableModalStyle.jsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import Modal from '../../../components-soapy/Modal'
import tableStyle from '../../../shared/styles/tableSortIcon.css'

const DEFAULT_PAGE_SIZE = 10
const CITY = 'city'
const STATE = 'state'
const COUNTRY = 'country'
const DISTRICT = 'district'
const NAME = 'name'

const SiteModal = ({
  isOpen,
  title,
  isLoading,
  onCancel,
  classes,
  sites,
  t
}) => {
  const [filtered, setFiltered] = useState([])
  const [sorted, setSorted] = useState([])
  useEffect(() => {
    addReactTableFilterPlaceholder()
  })

  useEffect(() => {
    setFiltered([])
    setSorted([])
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      dialogPaper={classes.dialogPaper}
      hideActions
      isLoading={isLoading}
      onCancel={onCancel}
    >

      {isLoading
        ? <CircularProgress
            color='primary'
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />

        : <ReactTable
            data={sites}
            style={tableStyle}
            defaultFilterMethod={defaultFilterMethod}
            columns={[

              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <span style={{ fontSize: 20 }}>{t('Name')}</span>
                  </div>
                ),
                style: { textAlign: 'left' },
                filterable: true,
                accessor: `${NAME}`
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <span style={{ fontSize: 20 }}>{t('Country')}</span>
                  </div>
                ),
                style: { textAlign: 'left' },
                filterable: true,
                accessor: `${COUNTRY}`,
                id: 'country'
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <span style={{ fontSize: 20 }}>{t('State')}</span>
                  </div>
                ),
                style: { textAlign: 'left' },
                filterable: true,
                accessor: `${STATE}`
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <span style={{ fontSize: 20 }}>{t('City')}</span>
                  </div>
                ),
                style: { textAlign: 'left' },
                filterable: true,
                accessor: `${CITY}`,
                id: 'city'
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <span style={{ fontSize: 20 }}>{t('District')}</span>
                  </div>
                ),
                style: { textAlign: 'left' },
                filterable: true,
                accessor: `${DISTRICT}`
              }

            ]}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSorted={[
              {
                id: 'checkbox',
                desc: false
              }
            ]}
            minRows={0}
            showPaginationBottom={sites.length > DEFAULT_PAGE_SIZE}
            className='-striped -highlight'
            nextText={t('Next')}
            filtered={filtered}
            onFilteredChange={filtered => { setFiltered(filtered) }}
            sorted={sorted}
            onSortedChange={sorted => { setSorted(sorted) }}
            pageText={t('Page')}
            ofText={t('Of')}
            rowsText={t('Rows')}
            previousText={t('Previous')}
          />}
    </Modal>
  )
}

export default withStyles(siteTableModalStyle)(withTranslation()(SiteModal))
