import jsPDF from 'jspdf'
import '../../assets-soapy/font/Roboto-Medium-normal'
import '../../assets-soapy/font/OpenSans-Regular-normal'

const exportPDF = (fileName, serialNumbers, headers, data, orientation = 'portrait', size = 'A4') => {
  const unit = 'pt'

  const marginLeft = 40

  const doc = new jsPDF(orientation, unit, size)
  doc.setFont('Roboto-Medium', 'normal')

  doc.setFontSize(15)

  const title = fileName
  const content = {
    startY: 50,
    head: [headers],
    body: data,
    headStyles: {
      fillColor: '#00acc1',
      font: 'Roboto-Medium'
    }
  }

  doc.text(title, marginLeft, 20)
  doc.text(`CleanMachine S/N: ${serialNumbers.toString()}`, marginLeft, 40)

  doc.autoTable({
    ...content,
    styles: { font: 'Roboto-Medium', fontStyle: 'normal' },
  })
  doc.save(fileName + '.pdf')
}

export default exportPDF
