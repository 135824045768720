import { FACE_ID, GROUPS, ID, NAME, ORGANIZATION, RFID } from './constants.js'
import { Tooltip, Typography } from '@material-ui/core'
import Remove from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import React, { useEffect, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import tableStyle from '../../shared/styles/tableSortIcon.css'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'

const DEFAULT_PAGE_SIZE = 10

const checkIcon = <Check style={{ color: 'green', width: '15px', marginBottom: '-9px' }}/>
const crossIcon = <Clear style={{ color: 'red', width: '15px', marginBottom: '-9px' }}/>

function convertToTableData (
  washers,
  openUpdateWasherModal,
  openDeleteWasherModal,
  hasPermission,
  t
) {
  const washerMasks = washers.washerMasks.map(washerMask => washerMask.washerId)
  return washers.washers.map(washer => {
    return {
      [ID]: washer.id,
      [ORGANIZATION]: washer.organization && washer.organization.name,
      [NAME]: washer.name,
      [FACE_ID]: washer.faceId ? washer.faceId : washerMasks.includes(washer.id),
      [RFID]: washer.rfid,
      [GROUPS]: washer.groups.map(group => group.name).join(', '),
      actions: (
        <div className="actions-left">
          {
            hasPermission({ name: 'washers', action: 'edit' })
              ? <Button
                justIcon
                round
                simple
                onClick={() => {
                  openUpdateWasherModal(washer)
                }}
                id="usersEditButton"
                className="edit"
              >
                <Tooltip title={t('Edit')}>
                  <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
                </Tooltip>
              </Button>
              : null
          }
          {
            hasPermission({ name: 'washers', action: 'delete' })
              ? <Button
                justIcon
                round
                simple
                onClick={() => {
                  openDeleteWasherModal(washer)
                }}
                id="usersDeleteButton"
              >
                <Tooltip title={t('Delete')}>
                  <Remove style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
                </Tooltip>
              </Button>
              : null
          }

        </div>
      )
    }
  })
}

const Washers = ({
  t,
  hasPermission,
  washers,
  openUpdateWasherModal,
  openDeleteWasherModal,
  filterChange,
  tableRef
}) => {
  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [])
  const tableData = useMemo(() => convertToTableData(
    washers,
    openUpdateWasherModal,
    openDeleteWasherModal,
    hasPermission,
    t
  ), [washers]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    washers
      ? <ReactTable
        onFilteredChange={() => filterChange()}
        data={tableData}
        filterable
        ref={tableRef}
        style={tableStyle}
        NoDataComponent={() => null}
        columns={[
          {
            Header: () => null,
            Cell: (row) => {
              return <div>{(row.viewIndex + 1) + row.page * row.pageSize}</div>
            },
            sortable: false,
            filterable: false,
            width: 50
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography variant="body2">{t('Washer ID')}</Typography>
              </div>
            ),
            accessor: ID,
            sortable: true,
            filterable: true

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography variant="body2">{t('Organization')}</Typography>
              </div>
            ),
            accessor: ORGANIZATION,
            sortable: true,
            filterable: true
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography variant="body2">{t('Description')}</Typography>
              </div>
            ),
            accessor: NAME,
            style: { textAlign: 'left' },
            sortable: true,
            filterable: true
          }, {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography variant="body2">{t('Groups')}</Typography>
              </div>
            ),
            accessor: GROUPS,
            style: { textAlign: 'left' },
            sortable: true,
            filterable: true
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography variant="body2">{t('Card ID')}</Typography>
              </div>
            ),
            accessor: RFID,
            style: { textAlign: 'left' },
            sortable: true,
            filterable: true
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography variant="body2">{t('Face ID')}</Typography>
              </div>
            ),
            Cell: ({ original }) => {
              return original[FACE_ID] ? checkIcon : crossIcon
            },
            accessor: FACE_ID,
            style: { textAlign: 'left' },
            filterable: false
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left',
                  marginLeft: 0
                }}
              >
                <Typography variant="body2">{t('Actions')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        nextText={t('Next')}
        pageText={t('Page')}
        ofText={t('Of')}
        onPageChange={() => {
          document.getElementById('mainPanel').scrollTop = 0
        }}
        rowsText={t('Rows')}
        previousText={t('Previous')}
        minRows={0}
        showPaginationBottom={
          tableData.length > DEFAULT_PAGE_SIZE
        }
        className="-striped -highlight"
      />
      : <h1>{t('No campaigns found')}</h1>
  )
}

export default withAuthentication(withTranslation()(Washers))
