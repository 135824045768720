import Button from '../../components/CustomButtons/Button'
import GridOn from '@material-ui/icons/GridOn'
import React from 'react'
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile'
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet'
import { Tooltip } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

const ExportExcel = ({
  fileName,
  title,
  columns,
  dataSet,
  data,
  t
}) => {
  return (
    <ExcelFile
      filename={fileName}
      element={
        <Button
          justIcon
          round
          id='exportExcelButton'
          color='info'
        >
          <Tooltip title={t('Export to excel')}>
            <GridOn />
          </Tooltip>
        </Button>
      }
    >
      {
        data
          ? <ExcelSheet
            data={data}
            name={title}
          >
            {columns}
          </ExcelSheet>
          : <ExcelSheet
            dataSet={dataSet}
            name={title}
          />
      }

    </ExcelFile>
  )
}

export default withTranslation()(ExportExcel)
