import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'

const deleteWasher = async (washerId) => {
  const URL = SERVER_PATH + apiEndpoints.washers + `/${washerId}`

  return resolveRequest(
    axios.delete(URL, {
      withCredentials: true
    })
  )
}

export default deleteWasher
