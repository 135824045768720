import imageModalStyle from '../assets-soapy/jss/soapy/components/imageModalStyle.jsx'
import { Modal, withStyles } from '@material-ui/core'
import React from 'react'

const ImageModal = ({
  isOpen,
  onClose,
  classes,
  imageUrl
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      className={classes.modal}
    >
      <div className={classes.paper}>
        <img
          onClick={onClose}
          alt='atpImage'
          src={imageUrl}
        />
      </div>
    </Modal>
  )
}

export default withStyles(imageModalStyle)(ImageModal)
