import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import axios from 'axios'

//----------------Delete Group API Call------------------//
const deleteGroup = async (groupId) => {
    const URL = SERVER_PATH + apiEndpoints.groups + `/${groupId}`

    return resolveRequest(
        axios.delete(URL, {
            withCredentials: true
        })
    )
}

export default deleteGroup