export const HANDS_RECOGNITION_KEYS = {
  PALM_TO_PALM: 'palmToPalm',
  BETWEEN_FINGERS: 'betweenFingers',
  BACK_OF_LEFT_HAND: 'backOfLeftHand',
  BACK_OF_RIGHT_HAND: 'backOfRightHand',
  BACK_OF_LEFT_THUMB: 'backOfLeftThumb',
  BACK_OF_RIGHT_THUMB: 'backOfRightThumb',
  FINGERNAIL_LEFT: 'fingernailLeft',
  FINGERNAIL_RIGHT: 'fingernailRight'
}

export const HANDS_ICON_NAMES = {
  BackOfLeftHand: "Back Of Left Hand",
  BackOfLeftThumb: "Back Of Left Thumb",
  BackOfRightHand: "Back Of Right Hand",
  BackOfRightThumb: "Back Of Right Thumb",
  BetweenFingers: "Between Fingers",
  FingernailLeft: "Fingernail Left",
  FingernailRight: "Fingernail right",
  PalmToPalm: "Palm To Palm",
}

export const HANDS_ICON_NAMES_LOWERCASE = {
  backOfLeftHand: "Back Of Left Hand",
  backOfLeftThumb: "Back Of Left Thumb",
  backOfRightHand: "Back Of Right Hand",
  backOfRightThumb: "Back Of Right Thumb",
  betweenFingers: "Between Fingers",
  fingernailLeft: "Fingernail Left",
  fingernailRight: "Fingernail Right",
  palmToPalm: "Palm To Palm",
}
