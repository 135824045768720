import {
  getDashboardUserFullName
} from '../../utils/textFomatters.js'
import {
  Tooltip,
  Typography
} from '@material-ui/core'
import Remove from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getCookie from '../../shared/cookie/get-cookie'
import { CITY, COUNTRY, IS_DEFAULT, MACHINE_COUNT, NAME, STATE } from '../../shared/data/organizations'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'
import BusinessIcon from '@material-ui/icons/Business';
import fetchOrganizations from '../../endpoint-requests/organization/fetchOrganizations.js'

const ID = 'id'
const ORGANIZATION = 'organization'
const DISTRIBUTOR_NAME = 'distributor'
const ADMINS = 'admins'
const ACTIONS = 'actions'
const SITES = 'sites'
const STATUS = 'status'


const DEFAULT_PAGE_SIZE = 10

const convertToTableData = (
  organizations,
  showData,
  openDeleteModal,
  entityName,
  t,
  openSiteModal,
  hasPermission

) =>

  organizations.map(organization => {
    if (organization.status === null) {
      organization.status = "active"
    }
    return {
      [ID]: organization.id,
      [ORGANIZATION]: organization,
      [STATUS]: organization.status,
      [ADMINS]: organization.dashboard_users.map(dashboardUser => getDashboardUserFullName(dashboardUser)),
      [DISTRIBUTOR_NAME]: organization.distributor && organization.distributor.name,
      [ACTIONS]: (
        <div>
          {
            hasPermission({ name: 'organizations', action: 'edit' })
              ? <div
                onClick={() => showData(organization)}
                id='organizationConfigurationEditBtn'
              >
                <Tooltip title={t('Edit')}>
                  <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
          {
            hasPermission({ name: 'organizations', action: 'delete' }) && !(organization[IS_DEFAULT] && entityName === 'distributor')
              ? <div
                onClick={() => openDeleteModal(organization)}
                id='organizationConfigurationDeleteBtn'
              >
                <Tooltip title={t('Delete')}>
                  <Remove style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
        </div>
      ),
      organization,
      [SITES]: (
        hasPermission({ name: 'organization sites', action: 'read' })
          ? <div className='actions-left'>
            <Button
              justIcon
              round
              simple
              id='organizationSiteBtn'
              onClick={() => {
                openSiteModal(organization)
              }}
            >
              <Tooltip title={t('Organization Sites')}>
                <SettingsInputCompositeIcon style={{ color: '#3c4858' }} />
              </Tooltip>
            </Button>
          </div>
          : null
      )

    }
  })

const Organizations = ({
  organizationAdded,
  organizationEdited,
  allOrganizations,
  openDeleteModal,
  organization,
  openUpdateModal,
  currentDashboardUser,
  hasPermission,
  openSiteModal,
  t
}) => {
  const language = getCookie('language')
  const [organizations, setOrganizations] = useState(allOrganizations)

  const handleStatusFilterChange = (status) => {
    switch (status) {
      case 'active':
        const activeOrganizations = allOrganizations.filter((organization) => organization.status === status)
        setOrganizations(activeOrganizations)
        break
      case 'archived':
        const archivedOrganizations = allOrganizations.filter((organization) => organization.status === status)
        setOrganizations(archivedOrganizations)

        break
      default: setOrganizations(allOrganizations)
    }
  }


  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [language])

  const showData = (organization) => {
    openUpdateModal(organization)
  }
  const organizationsAsRows = useMemo(() => convertToTableData(
    organizations,
    showData,
    openDeleteModal,
    currentDashboardUser.entity.name,
    t,
    openSiteModal,
    hasPermission
  ), [organizations]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getAllOrganizations = async () => {
      try {
        const result = await fetchOrganizations()
        setOrganizations(result)
      } catch (error) {
        console.log(error);
      }
    }
    getAllOrganizations()
  }, [organizationAdded, organizationEdited,])



  return (
    organizationsAsRows.length
      ? <ReactTable
        data={organizationsAsRows}
        filterable
        defaultFilterMethod={defaultFilterMethod}
        columns={[
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Name')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${ORGANIZATION}.${NAME}`

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Country')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${ORGANIZATION}.${COUNTRY}`

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('State')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${ORGANIZATION}.${STATE}`
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Distributor')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            show: currentDashboardUser.entity.name === 'neo',
            accessor: DISTRIBUTOR_NAME
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('City')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${ORGANIZATION}.${CITY}`

          }, {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Units')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${ORGANIZATION}.${MACHINE_COUNT}`

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left',
                  marginLeft: 0
                }}
              >
                <Typography variant='body2'>{t('Sites')}</Typography>
              </div>
            ),
            accessor: `${SITES}`,
            show: hasPermission({ name: 'organization sites', action: 'read' }),
            sortable: false,
            filterable: false
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Status')}</Typography>
              </div>
            ),
            Cell: ({ original }) => {
              if (original[STATUS] === "archived") {
                return (
                  <Tooltip title={t('Archived')}>
                    <BusinessIcon style={{ color: "gray" }}
                    />
                  </Tooltip>
                )
              } else {
                return (
                  <Tooltip title={t('Active')}>
                    <BusinessIcon style={{ color: "#00acc1" }}
                    />
                  </Tooltip>
                )
              }
            },
            Filter: ({ filter, onChange }) => {
              return (
                <select
                  onChange={event => handleStatusFilterChange(event.target.value)}
                  style={{ width: '100%', fontSize: '1rem' }}
                >
                  <option value='all'>All</option>
                  <option value='archived'>Archived</option>
                  <option value='active'>Active</option>
                </select>
              )
            },
            style: { textAlign: 'left' },
            filterable: true,
            accessor: `${STATUS}`
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography variant='body2'>{t('Admins')}</Typography>
              </div>
            ),
            Cell: (
              {
                original
              }
            ) => {
              return original[ADMINS].map((admin, index) => {
                return <div key={admin + index}>{admin}</div>
              })
            },
            accessor: ADMINS,
            sortable: true,
            filterable: true
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left',
                  marginLeft: 0
                }}
              >
                <Typography variant='body2'>{t('Actions')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: ACTIONS,
            sortable: false,
            filterable: false
          }
        ]}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        minRows={0}
        showPaginationBottom={
          organizationsAsRows.length > DEFAULT_PAGE_SIZE
        }
        onPageChange={() => {
          document.getElementById('mainPanel').scrollTop = 0
        }}
        nextText={t('Next')}
        pageText={t('Page')}
        ofText={t('Of')}
        rowsText={t('Rows')}
        previousText={t('Previous')}
        className='-striped -highlight'
      />
      : null
  )
}

export default withAuthentication(withTranslation()(Organizations))
