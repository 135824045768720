export default (soapTime, reagentName) => {
  let oneWashML = 0

  switch (reagentName) {
    case 'soapyLore':
      oneWashML = 0
      break
    case 'soapyFresh':
      oneWashML = 0
      break
    case 'pgSoap':
      oneWashML = 5.64 * soapTime + 0.121
      break
    case 'pepsiCOSoap':
      oneWashML = 0
      break
    default:
      oneWashML = 0
  }
  const bottleWashCount = oneWashML ? Math.round(1000 / oneWashML * 0.9) : 900
  return bottleWashCount
}
