import React from 'react'

import Modal from '../../../components-soapy/Modal.jsx'
import { withTranslation } from 'react-i18next'
import CustomInput from '../../../components/CustomInput/CustomInput'
import useFormFields from '../../../shared/hooks/useFormFields'
import withErrorNotification from '../../../containers/withErrorNotification'
import { Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'

const ResetCacheModal = ({ t, showErrorNotification, machine, onClose, onApply, ...rest }) => {

  const { formFields, setFormFields } = useFormFields({
    purgeMinutesAfterLastWash: {
      value: null,
      max: 1000,
      error: 'Purge minutes after wash must be in a range 1-1000',
      changed: false
    },
    primeReagentSeconds: {
      value: null,
      max: 10,
      error: 'Prime seconds must be in a range 1-10',
      changed: false
    },
    purgeRepeatTimes: {
      value: null,
      max: 1000,
      error: 'Reagent purge time must be in a range 1-1000',
      changed: false
    },
    isSoapPurge: {
      value: null,
      changed: false
    },
    isWaterPurge: {
      value: null,
      changed: false
    },
  })

  const handleTextChange = (event, key) => {
    const number = parseInt(event.target.value) || 0
    setFormFields({
      ...formFields,
      [key]: {
        ...formFields[key],
        value: number ? number : '',
        changed: true
      }
    })
  }

  const handleCheckboxChange = (key) => {
    const value = formFields[key].changed ? !formFields[key].value : !machine[key]
    setFormFields({
      ...formFields,
      [key]: {
        value,
        changed: true
      },
    })
  }

  const save = () => {
    let keys = [
      'purgeMinutesAfterLastWash',
      'primeReagentSeconds',
      'purgeRepeatTimes',
    ]
    for (let key in keys) {
      const number = getValue(keys[key])
      const valid = number && (1 <= number && number <= formFields[keys[key]].max)
      if (!valid) {
        showErrorNotification(formFields[keys[key]].error)
        return
      }
    }
    onApply({
      ...machine,
      automaticSoapPurge: true,
      purgeMinutesAfterLastWash: formFields.purgeMinutesAfterLastWash.value || machine.purgeMinutesAfterLastWash,
      primeReagentSeconds: formFields.primeReagentSeconds.value || machine.primeReagentSeconds,
      purgeRepeatTimes: formFields.purgeRepeatTimes.value || machine.purgeRepeatTimes,
      isSoapPurge: formFields.isSoapPurge.changed ? formFields.isSoapPurge.value : machine.isSoapPurge,
      isWaterPurge: formFields.isWaterPurge.changed ? formFields.isWaterPurge.value : machine.isWaterPurge,
    }).then(r => {
      setFormFields({
        purgeMinutesAfterLastWash: {
          ...formFields.purgeMinutesAfterLastWash,
          value: null
        },
        primeReagentSeconds: {
          ...formFields.primeReagentSeconds,
          value: null
        },
        purgeRepeatTimes: {
          ...formFields.purgeRepeatTimes,
          value: null
        },
        isSoapPurge: {
          ...formFields.purgeRepeatTimes,
          value: null
        },
        isWaterPurge: {
          ...formFields.purgeRepeatTimes,
          value: null
        },
      })
    })
  }

  const getValue = (key) => {
    return formFields[key].value !== null ? formFields[key].value : machine[key]
  }
  return (
    <Modal
      {...rest}
      onCancel={() => onClose()}
      onApply={() => save()}
    >
      <div id="resetCacheModalContainer" style={{ display: 'inline-grid', marginLeft: '30%' }}>
        <div style={{ display: 'inline-flex', margin: '15px' }}>
          <div>Start</div>
          <CustomInput
            id="adminEditUnitWaterTimePerWaterCycle"
            inputProps={{
              value: getValue('purgeMinutesAfterLastWash'),
              type: 'number',
              onChange: event => {
                handleTextChange(event, 'purgeMinutesAfterLastWash')
              }
            }}
            formControlProps={{
              style: { width: '50px', padding: 0, margin: '-3px 10px' }
            }}
          />
          <div> minutest after last wash</div>
        </div>
        <div style={{ display: 'inline-flex', margin: '15px' }}>
          <div>Prime for</div>
          <CustomInput
            id="adminEditUnitWaterTimePerWaterCycle"
            inputProps={{
              value: getValue('primeReagentSeconds'),
              type: 'number',
              onChange: event => {
                handleTextChange(event, 'primeReagentSeconds')
              }
            }}
            formControlProps={{
              style: { width: '50px', padding: 0, margin: '-3px 10px' }
            }}
          />
          <div> seconds</div>
        </div>
        <div style={{ display: 'inline-flex', margin: '15px' }}>
          <div>Repeat</div>
          <CustomInput
            id="adminEditUnitWaterTimePerWaterCycle"
            inputProps={{
              value: getValue('purgeRepeatTimes'),
              type: 'number',
              onChange: event => {
                handleTextChange(event, 'purgeRepeatTimes')
              }
            }}
            formControlProps={{
              style: { width: '50px', padding: 0, margin: '-3px 10px' }
            }}
          />
          <div> times</div>
        </div>
        <div style={{ marginLeft: '4px' }}>
          <Checkbox
            color="primary"
            checked={formFields.isSoapPurge.changed ? formFields.isSoapPurge.value : machine.isSoapPurge == true}
            onChange={() => handleCheckboxChange('isSoapPurge')}
          />
          <Typography display="inline">Soap</Typography>
          <Checkbox
            color="primary"
            checked={formFields.isWaterPurge.changed ? formFields.isWaterPurge.value : machine.isWaterPurge == true}
            onChange={() => handleCheckboxChange('isWaterPurge')}
          />
          <Typography display="inline">Water</Typography>
        </div>
      </div>
    </Modal>
  )
}

export default withErrorNotification(withTranslation()(ResetCacheModal))
