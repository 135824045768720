import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'

import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * @param {object} machine
 * @param {string} machine.name
 * @param {string} machine.country
 * @param {string} machine.state
 * @param {string} machine.city
 * @param {string} machine.district
 * @param {string} machine.address
 * @param {string} machine.zipcode
 * @param {string} machine.distributorId
 * @param {string} machine.contactEmail
 * @param {string} machine.contactName
 * @param {string} machine.website
 */
export const editOrganization = async (id, org) => {
  const endpoint = `${apiEndpoints.organizations}/${id}`
  const URL = SERVER_PATH + endpoint
  return resolveRequest(axios.put(URL, org, { withCredentials: true }))
}
