const languageSelectorStyle = theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      right: '8%'
    }
  },

  select: {
    fontSize: '14px',
    fontWeight: 400,
    border: 'none'
  },
  selectIcon: {
    color: 'white'
  },
  listItem: {
    paddingLeft: '24px',
    paddingRight: '24px',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '12px',
    paddingBottom: '12px',
    justifyContent: 'flex-start',
    textDecoration: 'none'
  },
  listItemText: {
    flex: '1 1 auto',
    padding: '0 16px',
    minWidth: '0'
  }

})

export default languageSelectorStyle
