import withStyles from '@material-ui/core/styles/withStyles'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import changePasswordModalStyle from '../../../assets-soapy/jss/soapy/views/changePasswordModalStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../components-soapy/LoadingButton'
import Modal from '../../../components-soapy/Modal.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CustomInput from '../../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import changeDashboardUserPassword from '../../../endpoint-requests/dashboard-user/changeDashboardUserPassword'
import isValidPassword from '../../../shared/dashboard-user/isValidPassword.js'

const ResetPasswordModal = ({
  dashboardUserId,
  isOpen,
  passwordChanged,
  showErrorNotification,
  classes,
  onClose,
  t
}) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)

  const resetFields = () => {
    setPassword('')
    setConfirmPassword('')
  }

  const arePasswordsMatching = () => {
    if (!password || !confirmPassword) return false
    return password === confirmPassword
  }

  const submit = async () => {
    if (!isValidPassword(password)) {
      return showErrorNotification({ passwordError: true })
    }
    setImmediate(() => setIsAwaitingResponse(true))
    try {
      await changeDashboardUserPassword(dashboardUserId, password)
      resetFields()
      passwordChanged()
    } catch (err) {
      showErrorNotification(err.message)
    }
    setIsAwaitingResponse(false)
  }
  return (
    <Modal
      isOpen={isOpen}
      hideActions
      dialogPaper={classes.dialogPaper}
      showGoBackButton
      title={t('Reset password')}
      onCancel={() => {
        resetFields()
        onClose()
      }}
      t={t}
    >
      <GridContainer id='content-pane-layout' justify='center'>
        <GridItem md={9}>
          <Card style={{ boxShadow: 'none' }}>

            <CardBody>
              <div>
                <form>
                  <GridContainer
                    direction='column'
                    justify='center'
                    alignItems='center'
                    id='card-body-section'
                  >
                    <GridItem style={{ minWidth: '40vh' }}>
                      <CustomInput
                        id='dashboardUsersResetPasswordNewPassword'
                        labelText={`${t('New password')} *`}
                        inputProps={{
                          value: password,
                          type: 'password',
                          onChange: event => setPassword(event.target.value)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>

                    <GridItem style={{ minWidth: '40vh' }}>
                      <CustomInput
                        id='dashboardUsersResetPasswordConfirmPassword'
                        error={password && confirmPassword && !arePasswordsMatching()}
                        helpText={password && confirmPassword && !arePasswordsMatching() && 'Passwords do not match'}
                        labelText={`${t('Confirm new password')} *`}
                        inputProps={{
                          value: confirmPassword,
                          type: 'password',
                          onChange: event => setConfirmPassword(event.target.value)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>

                  </GridContainer>
                  <GridContainer
                    justify='center'
                    alignItems='center'
                    id='card-footer-section'
                  >
                    <GridItem>
                      <LoadingButton
                        color='info'
                        onClick={submit}
                        id='dashboardUsersResetPasswordSaveButton'
                        fullWidth
                        disabled={isAwaitingResponse || !password || !confirmPassword || !arePasswordsMatching()}
                        isLoading={isAwaitingResponse}
                      >
                        {t('Save')}
                      </LoadingButton>
                    </GridItem>
                  </GridContainer>
                </form>

              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Modal>
  )
}

export default withErrorNotification(withStyles(changePasswordModalStyle)(withTranslation()(ResetPasswordModal)))
