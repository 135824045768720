// ------------ Constant Variable for Creating Table Object ----------//
export const NAME = 'name'
export const DESCRIPTION = 'description'
export const EDIT = 'edit'
export const REMOVE = 'remove'
export const VALIDATION_STATE = 'ValidationState'
export const ORGANIZATION_ID = 'organizationId'
export const ORGANIZATION = 'organization'


