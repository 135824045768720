import { createSlice } from "@reduxjs/toolkit";

const minWashCountSlice = createSlice({
    name: "minWashCount",
    initialState: {
        minWashCount: 0,
        isFetching: false,
        error: false,
    },
    reducers: {
        minWashCountStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        minWashCountSuccess: (state, action) => {
            state.isFetching = false;
            state.minWashCount = action.payload;


        },
        minWashCountFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        minWashCountLogoutSuccess: (state, action) => {
            state.isFetching = false;
            state.minWashCount = 0;


        },

    },
});

export const {
    minWashCountStart,
    minWashCountSuccess,
    minWashCountFailure,
    minWashCountLogoutSuccess
} = minWashCountSlice.actions;


const minWashCountReducer = minWashCountSlice.reducer; //it should be export default
export default minWashCountReducer;