import axios from 'axios'
import failedSession from '../failedSession'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

export default async (unsubscribeToken) => {
  const endpoint = `${apiEndpoints.unsubscribe}?unsubscribeToken=${unsubscribeToken}`
  const URL = SERVER_PATH + endpoint
  try {
    await axios.post(URL, endpoint)
    return { didSucceed: true }
  } catch (e) {
    failedSession(e)
  }
}
