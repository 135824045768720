import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Highcharts from "highcharts";

const removeStartingZeros = (str) => {
  let startIdx = 0
  for (const char of str) {
    if (char !== '0') break
    startIdx++
  }
  return str.substr(startIdx)
}

const WashesChart = ({ t, selectedMachineIds, machines, washesChartRef, formatChartDates, fromDate, toDate, chartData }) => {
  // console.log(t);
  // console.log(selectedMachineIds);
  // console.log(machines);
  // console.log(washesChartRef);
  // console.log(formatChartDates);
  // console.log(fromDate);
  // console.log(toDate);
  // console.log(chartData);



  const [options, setOptions] = useState({
    yAxis: {
      min: 0,
      stackLabels: {
        enabled: true
      },
      title: {
        text: t('Washes')
      }
    }

  })

  useEffect(() => {
    const machineIdToSeries = chartData.data.reduce((acc, machineIdToDailyMachineUsages, index) => {
      if (!machineIdToDailyMachineUsages) return acc
      for (const machineId in machineIdToDailyMachineUsages) {
        const dailyMachineUsage = machineIdToDailyMachineUsages[machineId]
        const siteName = dailyMachineUsage.machine.site && dailyMachineUsage.machine.site.name
        const internalArea = dailyMachineUsage.machine.internalArea
        const serialNumber = dailyMachineUsage.machine.serialNumber
        if (!acc[machineId + 'success']) {
          acc[machineId + 'success'] = {
            data: new Array(chartData.dates.length).fill(null),
            name: `${siteName ? `${siteName} - ` : ''}${internalArea ? `${internalArea} - ` : ''}${serialNumber ? `${removeStartingZeros(serialNumber.split('-')[2])} - ` : ''}${t('Hygienic')}`,
            stack: serialNumber
          }
          acc[machineId + 'failed'] = {
            data: new Array(chartData.dates.length).fill(null),
            name: `${siteName ? `${siteName} - ` : ''}${internalArea ? `${internalArea} - ` : ''}${serialNumber ? `${removeStartingZeros(serialNumber.split('-')[2])} - ` : ''}${t('Non-Hygenic')}`,
            stack: serialNumber
          }
        }
        const washCount = dailyMachineUsage.washCount || 0
        const failedWashCount = dailyMachineUsage.washFailedCount || 0
        // 0 values don't look pretty in the chart so we convert them to null
        acc[machineId + 'success'].data[index] = (acc[machineId + 'success'].data[index] + (washCount - failedWashCount)) || null
        acc[machineId + 'failed'].data[index] = (acc[machineId + 'failed'].data[index] + failedWashCount) || null
      }
      return acc
    }, {})
    // if machine has no data, we still have to add a label
    for (const machineId of selectedMachineIds) {
      if (!machineIdToSeries[machineId + 'success'] && !machineIdToSeries[machineId + 'failed']) {
        const machine = machines.find(machine => machine.id === machineId)
        const siteName = machine.site && machine.site.name
        const internalArea = machine.internalArea
        const serialNumber = machine.serialNumber
        machineIdToSeries[machineId] = {
          name: `${siteName ? `${siteName} - ` : ''}${internalArea ? `${internalArea} - ` : ''}${serialNumber ? `${removeStartingZeros(serialNumber.split('-')[2])} - ` : ''}${t('No Data')}`,
          events: {
            legendItemClick: function () {
              return false
            }
          }
        }
      }
    }
    setOptions({
      ...options,
      series: Object.values(machineIdToSeries)
    }
    )
  }, [chartData]) // eslint-disable-line react-hooks/exhaustive-deps

  const chart = <HighchartsReact
    highcharts={Highcharts}
    constructorType='chart'
    options={{
      ...options,
      xAxis: { categories: formatChartDates(chartData.dates) },
      chart: {
        type: 'column',
        height: 500
      },
      title: {
        text: ''
      },
      yAxis: {
        title:
        {
          text: t('Washes')
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.x + '</b><br/>' +
            this.series.name + ': ' + this.y + '<br/>' +
            t('Total') + ': ' + this.point.stackTotal
        }
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            style: {
              textOutline: '0px'
            }
          }
        }
      },
      colors: [
        '#4074c1',
        '#b2c7e5',
        '#a4a4a4',
        '#d9d9d9',
        '#f67c3d',
        '#fccbb0',
        '#ffd84b',
        '#fff1b6',
        '#8aaac9',
        '#d3dfea'
      ]
    }}
    ref={washesChartRef}
  />
  return (
    <div
      style={{
        marginTop: 30,
        position: 'relative',
        height: '100%'
      }}
    >
      {chart}
    </div>
  )
}

export default withTranslation()(WashesChart)
