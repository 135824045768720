import machineUsagesBySites from '../../../endpoint-requests/usage/machineUsagesBySites'
import { calculateShiftData } from './particularShiftCalculation'

export const getPreviousDailyMachineUsages = async (previousFrom, previousTo, sitesIds, minimumWashes, washersIds) => {
    try {
        let previousTotalWashes = 0
        let previousTotalHygenicWashes = 0
        let previousAveragedResult = 0
        let previousWashesLeft = 0
        let fromDate = new Date(previousFrom).toISOString()
        let toDate = new Date(previousTo).toISOString()

        const fetchDailyMachineUsagesData = await machineUsagesBySites(fromDate, toDate, sitesIds, washersIds, false,)

        if (fetchDailyMachineUsagesData.length !== 0) {
            const previousShiftResult = calculateShiftData(fetchDailyMachineUsagesData)
            previousTotalWashes = previousShiftResult.washCount
            previousAveragedResult = previousShiftResult.averageWashScore
            previousTotalHygenicWashes = previousTotalWashes - previousShiftResult.failedWashCount

            if (minimumWashes - previousTotalWashes > 0) {
                previousWashesLeft = minimumWashes - previousTotalWashes
            }
            return { previousTotalWashes, previousTotalHygenicWashes, previousAveragedResult, previousWashesLeft }
        }
        if (minimumWashes - previousTotalWashes > 0) {
            previousWashesLeft = minimumWashes - previousTotalWashes
        }
        return { previousTotalWashes, previousTotalHygenicWashes, previousAveragedResult, previousWashesLeft }

    } catch (error) {
        console.log(error);

    }
}