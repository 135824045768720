import logout from '../../endpoint-requests/dashboard-user/logout.js'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import LogoutModalContext from './LogoutModalContext.js'
import { withTranslation } from 'react-i18next'
import LogoutModal from './LogoutModal.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'

import deleteAllCookies from '../../shared/cookie/remove-all-cookies'
import { onClear } from '../../redux/actions'

class LogoutModalContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = { isOpen: false }
  }

  onApplyButtonClick = async () => {
    const { history } = this.props
    try {
      await logout();
      deleteAllCookies();
      this.props.logout();
      // this.props.onClear();
      history.push("/login"); // Redirect after log-out
    } catch (e) {
      console.log("Logout failed");
    }
  };

  render() {
    const { isOpen } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <LogoutModal
          isOpen={isOpen}
          title={t("Logging out")}
          onApply={() => this.onApplyButtonClick()}
          onCancel={() => this.setState({ isOpen: false })}
          applyButtonText={t("LOG OUT")}
        />
        <LogoutModalContext.Provider
          value={{
            openLogoutModal: () => this.setState({ isOpen: true }),
          }}
        >
          {this.props.children}
        </LogoutModalContext.Provider>
      </React.Fragment>
    );
  }
}

LogoutModalContainer.propTypes = {
  history: PropTypes.any,
};

export default connect(null, { onClear })(withAuthentication(
  withRouter(withTranslation()(LogoutModalContainer))
))
