import {
  Checkbox, Divider, FormControlLabel, Radio, RadioGroup, Typography
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Check from '@material-ui/icons/Check'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import formStyle from '../../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../components-soapy/LoadingButton.jsx'
import Modal from '../../components-soapy/Modal.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../containers/withErrorNotification.js'
import editStatusConfiguration from '../../endpoint-requests/machine/editStatusConfiguration.js'
import resetUUID from '../../endpoint-requests/machine/resetUUID.js'
import useFormFields from '../../shared/hooks/useFormFields.js'
import validateFormFields from '../../utils/validateFormFields.js'
import {
  ATP_MODE,
  ENABLE_VISUAL_ERROR,
  LED_PROGRESS_BAR
} from './constants.js'
import { MONITOR_ORIENTATION } from '../Unit-Configuration/constants'
import StayPrimaryLandscape from '@material-ui/icons/StayPrimaryLandscape'
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait'

const VALIDATION_STATE = 'validationState'

let spinnerTimeout = null

const StatusConfiguration = ({
  isOpen,
  showErrorNotification,
  title,
  classes,
  statusConfiguration,
  serialNumber,
  onClose,
  configurationSaved,
  password,
  t
}) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const { formFields, setFormFields } = useFormFields({
    [LED_PROGRESS_BAR]: {
      value: false
    },
    [ENABLE_VISUAL_ERROR]: {
      value: false
    },
    [ATP_MODE]: {
      value: false
    },
    [MONITOR_ORIENTATION]: {
      value: 'horizontal'
    }
  })

  const submitForm = async () => {
    activateSpinner()
    try {
      await editStatusConfiguration(serialNumber, {
        atpMode: formFields[ATP_MODE].value,
        ledProgressBar: formFields[LED_PROGRESS_BAR].value,
        enableVisualError: formFields[ENABLE_VISUAL_ERROR].value,
        monitorOrientation: formFields[MONITOR_ORIENTATION].value,
        password
      })
      configurationSaved()
    } catch (err) {
      showErrorNotification(err.message)
    }
    disableSpinner()
  }

  useEffect(() => {
    setFormFields(
      (formFields) => ({
        [LED_PROGRESS_BAR]: { ...formFields[LED_PROGRESS_BAR], value: statusConfiguration[LED_PROGRESS_BAR] || false },
        [ENABLE_VISUAL_ERROR]: { ...formFields[ENABLE_VISUAL_ERROR], value: statusConfiguration[ENABLE_VISUAL_ERROR] || false },
        [ATP_MODE]: { ...formFields[ATP_MODE], value: statusConfiguration[ATP_MODE] || false },
        [MONITOR_ORIENTATION]: { ...formFields[MONITOR_ORIENTATION], value: statusConfiguration[MONITOR_ORIENTATION] || 'horizontal' },

      })
    )
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [statusConfiguration]) // eslint-disable-line react-hooks/exhaustive-deps

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleCheckboxChange = (fieldName) => {
    setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: !formFields[fieldName].value } })
  }

  const handleTextChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: event.target.value } })
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  const handleResetUUID = async () => {
    activateSpinner()
    try {
      await resetUUID({
        password,
        serialNumber
      })
      configurationSaved()
    } catch (err) {
      showErrorNotification(err.message)
    }
    disableSpinner()
  }

  useEffect(() => {
    return () => {
      disableSpinner()
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      t={t}
      hideActions
      showGoBackButton
      onCancel={onClose}
      title={title}
    >
      <div>
        <GridContainer id='content-pane-layout' justify='center'>
          <GridItem md={9}>
            <Card style={{ boxShadow: 'none' }}>
              <CardBody>
                <div>
                  <form>
                    <GridContainer
                      direction='column'
                      justify='center'
                      alignItems='center'
                      id='card-body-section'
                    >
                      <GridItem style={{ minWidth: '30vh' }}>
                        <Checkbox
                          id='adminEditUnitEnableVisualErrorCheckbox'
                          tabIndex={-1}
                          onChange={() => handleCheckboxChange(ENABLE_VISUAL_ERROR)}
                          checkedIcon={
                            <Check
                              className={classes.checkedIcon}
                              color='primary'
                            />
                            }
                          icon={<Check className={classes.uncheckedIcon} />}
                          checked={formFields[ENABLE_VISUAL_ERROR].value}
                        />
                        <Typography display='inline'>{t('LED bar as error display')}</Typography>
                      </GridItem>
                      <GridItem style={{ minWidth: '30vh' }}>
                        <Checkbox
                          id='adminEditUnitEnableVisualErrorCheckbox'
                          tabIndex={-1}
                          onChange={() => handleCheckboxChange(LED_PROGRESS_BAR)}
                          checkedIcon={
                            <Check
                              className={classes.checkedIcon}
                              color='primary'
                            />
                            }
                          icon={<Check className={classes.uncheckedIcon} />}
                          checked={formFields[LED_PROGRESS_BAR].value}
                        />
                        <Typography display='inline'>{t('LED bar as wash progress indicator')}</Typography>
                      </GridItem>
                      <GridItem style={{ minWidth: '30vh' }}>
                        <Checkbox
                          id='adminEditUnitEnableVisualErrorCheckbox'
                          tabIndex={-1}
                          onChange={() => handleCheckboxChange(ATP_MODE)}
                          checkedIcon={
                            <Check
                              className={classes.checkedIcon}
                              color='primary'
                            />
                            }
                          icon={<Check className={classes.uncheckedIcon} />}
                          checked={formFields[ATP_MODE].value}
                        />
                        <Typography display='inline'>{t('HCA mode')}</Typography>
                      </GridItem>
                      <GridItem style={{
                        marginLeft: '-33%'
                      }}>
                        <div>{t('Monitor position')}</div>
                        <RadioGroup
                          value={
                            formFields[MONITOR_ORIENTATION].value ?
                              formFields[MONITOR_ORIENTATION].value : 'horizontal'
                          }
                          onChange={
                            (e) => handleTextChange(e, MONITOR_ORIENTATION)
                          }
                        >
                          <FormControlLabel
                            value="horizontal"
                            control={<Radio color={'primary'}/>}
                            label={<StayPrimaryLandscape/>
                            }/>
                          <FormControlLabel
                            value="vertical"
                            control={<Radio color={'primary'}/>}
                            label={<StayCurrentPortrait/>}/>
                        </RadioGroup>
                      </GridItem>
                      <GridContainer
                        justify='center'
                        alignItems='center'
                        id='card-footer-section'
                      >
                        <GridItem>
                          <LoadingButton
                            color='info'
                            type='submit'
                            id='siteConfigSaveButton'
                            onClick={(e) => {
                              e.preventDefault()
                              handleSaveBtnClick()
                            }}
                            fullWidth
                            disabled={isAwaitingResponse}
                            isLoading={isAwaitingResponse}
                          >
                            {t('Save')}
                          </LoadingButton>
                        </GridItem>
                      </GridContainer>

                      <GridItem style={{
                        width: '30vh',
                        margin: '1.5rem 0 1.5rem 0',
                        paddingTop: '27px',
                        position: 'relative',
                        verticalAlign: 'unset'
                      }}
                      >
                        <Divider />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      justify='center'
                      alignItems='center'
                      id='card-footer-section'
                    >
                      <GridItem>
                        <LoadingButton
                          color='info'
                          type='submit'
                          id='siteConfigSaveButton'
                          onClick={(e) => {
                            e.preventDefault()
                            handleResetUUID()
                          }}
                          fullWidth
                          disabled={isAwaitingResponse}
                          isLoading={isAwaitingResponse}
                        >
                          {t('Reset UUID')}
                        </LoadingButton>
                      </GridItem>
                    </GridContainer>
                  </form>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </Modal>

  )
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withTranslation()(StatusConfiguration))))
