import { Button, CircularProgress, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import envelopeIcon from '../assets-soapy/img/envelope-line.svg'
import unsubscribeStyle from '../assets/jss/material-dashboard-pro-react/views/unsubscribeStyle.jsx'
import LanguageSelector from '../components-soapy/MultiLanguage/LanguageSelector'
import Card from '../components/Card/Card.jsx'
import CardBody from '../components/Card/CardBody.jsx'
import CardHeader from '../components/Card/CardHeader.jsx'
import GridContainer from '../components/Grid/GridContainer.jsx'
import GridItem from '../components/Grid/GridItem.jsx'
import withAuthentication from '../containers/Authentication/withAuthentication.js'
import unsubscribe from '../endpoint-requests/notification-settings/unsubscribe.js'

const Unsubscribe = ({
  classes, t, i18n, history,
  languageCodes, match
}) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(true)
  const { unsubscribeToken } = match.params
  const decodedToken = jwtDecode(unsubscribeToken)

  useEffect(() => {
    (async () => {
      const { unsubscribeToken } = match.params
      await unsubscribe(unsubscribeToken)
      setIsAwaitingResponse(false)
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    isAwaitingResponse
      ? <CircularProgress
        color="white"
        style={{ position: 'absolute', top: '50%', left: '50%' }}
      />
      : <div className={classes.container}>
        <div style={{ position: 'absolute', right: '1rem', top: '1rem' }}>
          <LanguageSelector isDarkBackground languageCodes={languageCodes} i18n={i18n}/>
        </div>
        <GridContainer justify="center">
          <GridItem style={{ textAlign: 'center' }} xs={12} md={6}>
            <Card
              className={classes.card}
            >
              <CardHeader
                style={{
                  marginBottom: '1.8rem'
                }}
              >
                <Typography color="inherit" align="center" variant="title">UNSUBSCRIBE</Typography>
              </CardHeader>
              <CardBody>
                <Typography
                  style={{
                    marginBottom: '3.5rem'
                  }} color="inherit" align="center" variant="subheading"
                >Your request to unsubscribe was received and you will no longer receive this kind of notification from
                  Soapy.
                </Typography>
                <br/>
                <Typography
                  style={{
                    marginBottom: '2.5rem'
                  }} color="inherit" align="center" variant="subheading"
                >Your phone number: {decodedToken.address}
                </Typography>
                <Button
                  variant="outlined" color="inherit" className={classes.redirectButton}
                  onClick={() => {
                    history.replace('/')
                    window.location.reload()
                  }}
                >
                  <Typography color="inherit" variant="body2">In order to resubscribe, please visit
                    soapywisdom.com</Typography>
                </Button>
                <br/>
                <img src={envelopeIcon} alt="envelope" style={{ background: 'white' }}/>
              </CardBody>

            </Card>
          </GridItem>
        </GridContainer>
      </div>
  )
}

export default withAuthentication(withStyles(unsubscribeStyle)(withTranslation()(Unsubscribe)))
