import { Tooltip, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { AssignmentTurnedIn, DeviceHub } from '@material-ui/icons'
import Assessment from '@material-ui/icons/Assessment'
import DonutLarge from '@material-ui/icons/DonutLarge'
import Edit from '@material-ui/icons/Edit'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Link from 'react-router-dom/Link'
import ReactTable from 'react-table'
import appStyle from '../../assets-soapy/jss/soapy/layouts/dashboardStyle'
import withAuthentication from '../../containers/Authentication/withAuthentication'
import setCookie from '../../shared/cookie/set-cookie'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'
import tableStyle from '../../shared/styles/tableSortIcon.css'
import {
  DISTRIBUTOR,
  INTERNAL_AREA,
  IS_ARCHIVED,
  ORGANIZATION,
  SERIAL_NUMBER,
  SITE,
  ID,
  VIEWS,
  ACTIONS,
  MONITOR_ORIENTATION
} from './constants'
import StayPrimaryLandscape from '@material-ui/icons/StayPrimaryLandscape'
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait'
import { useDispatch } from 'react-redux'
import { dailyWashesSelectedMachineIdsSuccess } from '../../redux/dailyWashesReducer'

const DEFAULT_PAGE_SIZE = 10

const convertToTableData = (
  dispatch,
  machines,
  role,
  t,
  openConfigurationModal,
  entity,
  classes
) => {
  return machines.map((machine) => {
    const {
      isArchived,
      distributor,
      site,
      internalArea,
      organization,
      serialNumber,
      isDefaultOrganization,
      monitorOrientation
    } = machine
    const machineId = machine[ID]
    const isOrphan = !(distributor && site && organization)
    return {
      [ID]: machineId,
      [IS_ARCHIVED]: isArchived || isOrphan,
      [DISTRIBUTOR]: isArchived || isOrphan ? <i>{distributor}</i> : distributor,
      [SITE]: isArchived || isOrphan ? <i>{site}</i> : site,
      [INTERNAL_AREA]: isArchived || isOrphan ? <i>{internalArea}</i> : internalArea,
      [ORGANIZATION]: isArchived || isOrphan ? <i>{organization}</i> : organization,
      [SERIAL_NUMBER]: isArchived || isOrphan ? <i>{serialNumber}</i> : serialNumber,
      [ACTIONS]: !isArchived
        ? (
          <div>
            <Tooltip title={t('Edit')}>
              <Edit
                id='unitEditBtn'
                onClick={() => openConfigurationModal(machine)}
                className={classes.actionIconBtn}
              />
            </Tooltip>
          </div>
        )
        : null,
      [VIEWS]: isOrphan || (entity === 'distributor' && !isDefaultOrganization)
        ? null
        : (
          <div>
            {role !== 'technician'
              ? <div>
                <Tooltip title={t('Daily washes')}>

                  <Link
                    to='/daily-washes'
                    id='unitsDailyWashesLink'
                    onClick={() => {
                      let dailyWashesMachineIdArray = []
                      setCookie('dailyWashesMachineId', machineId)
                      dailyWashesMachineIdArray.push(machineId)
                      dispatch(dailyWashesSelectedMachineIdsSuccess(dailyWashesMachineIdArray))
                    }}
                    className='edit'
                  >
                    <DonutLarge className={classes.actionIconBtn} />
                  </Link>
                </Tooltip>
                <Tooltip title={t('Trends')}>
                  <Link
                    to='/trends'
                    id='unitsTrendsLink'
                    onClick={() => {
                      setCookie('usageMachineIds', machineId)
                    }}
                    className='remove'
                  >
                    <Assessment className={classes.actionIconBtn} />
                  </Link>
                </Tooltip>
              </div>
              : <div>
                <Tooltip title={t('Status')}>
                  <Link
                    to='/status'
                    id='unitsStatusLink'
                    onClick={() => {
                      setCookie('statusSerialNumber', serialNumber)
                    }}
                    className='edit'
                  >
                    <AssignmentTurnedIn style={{ color: '#3c4858', margin: '5px', height: '18px' }} />
                  </Link>
                </Tooltip>
              </div>}
          </div>
        ),
      [MONITOR_ORIENTATION]:
      {
        icon: monitorOrientation === 'vertical' ? <StayCurrentPortrait /> : <StayPrimaryLandscape />,
        value: monitorOrientation === 'vertical'
      }
    }
  })
}

const showEditColumn = (role, entity) => {
  return role === 'admin' && (entity === 'neo' || entity === 'distributor' || entity === 'organization' || entity === 'site')
}

const UnitsTable = ({
  machines,
  currentDashboardUser,
  t,
  openConfigurationModal,
  classes
}) => {
  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [])

  const [filtered, setFiltered] = useState([{ id: 'isArchived', value: false }])
  const dispatch = useDispatch()
  const role = currentDashboardUser.role.name
  const entity = currentDashboardUser.entity.name

  const handleStatusFilterChange = (status) => {
    const updatedFiltered = filtered.filter(value => value.id !== 'isArchived')
    switch (status) {
      case 'Active':
        setFiltered([...updatedFiltered, { id: 'isArchived', value: false }])
        break
      case 'Archived':
        setFiltered([...updatedFiltered, { id: 'isArchived', value: true }])
        break
      default:
        setFiltered([...updatedFiltered, { id: 'isArchived', value: null }])
    }
  }

  const tableData = useMemo(() => convertToTableData(
    dispatch,
    machines,
    role,
    t,
    openConfigurationModal,
    entity,
    classes
  ), [machines]) // eslint-disable-line react-hooks/exhaustive-deps

  return (

    <ReactTable
      data={tableData}
      style={tableStyle}
      filterable
      defaultFilterMethod={defaultFilterMethod}
      columns={[
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Serial number')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: SERIAL_NUMBER
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Site')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: SITE
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Internal area')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          accessor: INTERNAL_AREA
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Distributor')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          show: entity === 'neo',
          accessor: DISTRIBUTOR
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant='body2'>{t('Organization')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          show: (entity === 'distributor' && role === 'admin') || entity === 'neo',
          accessor: ORGANIZATION
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <Typography variant="body1">{t('Monitor Position')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          Cell: ({ original }) => original[MONITOR_ORIENTATION].icon,
          accessor: MONITOR_ORIENTATION,
          sortable: true,
          sortMethod: (a, b) => {
            a = a.value
            if (a) return 1
            return -1
          }
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left'

              }}
            >
              <Typography variant='body2'>{t('Status')}</Typography>
            </div>
          ),
          Cell: ({ original }) => {
            if (original[IS_ARCHIVED]) {
              return (
                <Tooltip title={t('Archived')}>
                  <DeviceHub className={classes.archivedIconBtn} />
                </Tooltip>
              )
            } else {
              return (
                <Tooltip title={t('Active')}>
                  <DeviceHub className={classes.activeIconBtn} />
                </Tooltip>
              )
            }
          },
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={event => handleStatusFilterChange(event.target.value)}
                style={{ width: '100%', fontSize: '1rem' }}
                value={filter.value ? 'Archived' : filter.value === null ? 'All' : 'Active'}
              >
                <option value='All'>{t('All')}</option>
                <option value='Archived'>{t('Archived')}</option>
                <option value='Active'>{t('Active')}</option>
              </select>
            )
          },
          style: { textAlign: 'left' },
          filterable: true,
          accessor: IS_ARCHIVED
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                maxHeight: '60px',
                marginLeft: 0,
                paddingBottom: '84px'
              }}
            >
              <Typography variant='body2'>{t('Views')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          show: entity !== 'distributor',
          accessor: VIEWS,
          sortable: false,
          filterable: false
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'left',
                maxHeight: '60px',
                marginLeft: 0,
                paddingBottom: '84px'
              }}
            >
              <Typography variant='body2'>{t('Actions')}</Typography>
            </div>
          ),
          style: { textAlign: 'left' },
          show: showEditColumn(role, entity),
          accessor: ACTIONS,
          sortable: false,
          filterable: false
        }

      ]}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      minRows={0}
      nextText={t('Next')}
      pageText={t('Page')}
      onPageChange={() => {
        document.getElementById('mainPanel').scrollTop = 0
      }}
      ofText={t('Of')}
      rowsText={t('Rows')}
      previousText={t('Previous')}
      showPaginationBottom={
        tableData.length > DEFAULT_PAGE_SIZE
      }
      filtered={filtered}
      onFilteredChange={filtered => {
        setFiltered(filtered)
      }}
      className='-striped -highlight'
    />
  )
}

export default withRouter(withAuthentication(withStyles(appStyle)(withTranslation()(UnitsTable))))
