import { createSlice } from "@reduxjs/toolkit";

const datesSlice = createSlice({
    name: "dates",
    initialState: {
        dateCategory: [],
        washDates: [],
        washValues: [],
        dateRange: "",
        fromDate: "",
        toDate: "",
        yesterday: "",
        isFetching: false,
        error: false,
    },
    reducers: {

        dateCategorySuccess: (state, action) => {
            state.isFetching = false;
            state.dateCategory = action.payload;


        },
        washDatesSuccess: (state, action) => {
            state.isFetching = false;
            state.washDates = action.payload;


        },
        washValuesSuccess: (state, action) => {
            state.isFetching = false;
            state.washValues = action.payload;


        },

        dateRangeStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        dateRangeSuccess: (state, action) => {
            state.isFetching = false;
            state.dateRange = action.payload;


        },
        dateRangeFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },

        fromDateStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        fromDateSuccess: (state, action) => {
            state.isFetching = false;
            state.fromDate = action.payload;


        },
        fromDateFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        toDateStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        toDateSuccess: (state, action) => {
            state.isFetching = false;
            state.toDate = action.payload;


        },

        toDateFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        yesterdaySuccess: (state, action) => {
            state.isFetching = false;
            state.yesterday = action.payload;


        },
        datesLogoutSuccess: (state, action) => {
            state.isFetching = false;

            state.yesterday = "";
            state.dateCategory = []
            state.washDates = []
            state.washValues = []
            state.dateRange = ""
            state.fromDate = ""
            state.toDate = ""


        },

    },
});

export const {
    dateCategorySuccess,
    dateRangeStart,
    dateRangeSuccess,
    dateRangeFailure,
    washDatesSuccess,
    washValuesSuccess,
    fromDateStart,
    fromDateSuccess,
    fromDateFailure,
    toDateStart,
    toDateSuccess,
    toDateFailure,
    yesterdaySuccess,
    datesLogoutSuccess
} = datesSlice.actions;


const datesReducer = datesSlice.reducer; //it should be export default
export default datesReducer;