import profileRoutes from '../routes/profileRoutes.js'
import dashRoutes from '../routes/dashRoutes.js'
import washerRoutes from '../routes/washerRoutes'
import routes from '../routes/dashboardRoutes.js'
import getAccessiblePaths from '../shared/dashboard/getAccessiblePaths.js'
import filterRoutesByPaths from '../shared/dashboard/filterRoutesByPaths.js'
import withStyles from '@material-ui/core/styles/withStyles'
import cx from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import logo from '../assets-soapy/img/logo.png'
import appStyle from '../assets-soapy/jss/soapy/layouts/dashboardStyle.jsx'
import image from '../assets/img/sidebar-2.jpg'
import Header from '../components-soapy/Header/Header.jsx'
import Sidebar from '../components-soapy/Sidebar/Sidebar.jsx'
import withAuthentication from '../containers/Authentication/withAuthentication.js'
import LogoutModalContainer from '../containers/LogoutModal/LogoutModalContainer.jsx'
import { isMobile } from 'react-device-detect'
import Menu from '@material-ui/icons/Menu'

var ps

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.paths = getAccessiblePaths(
      routes,
      this.props.currentDashboardUser,
      isMobile
    )
    this.filteredRoutes = filterRoutesByPaths(routes, this.paths)
    //-----------combining all routes---------//
    this.paths = {
      ...this.paths,
      ...getAccessiblePaths(profileRoutes, this.props.currentDashboardUser, isMobile),
      ...getAccessiblePaths(dashRoutes, this.props.currentDashboardUser, isMobile),
      ...getAccessiblePaths(washerRoutes, this.props.currentDashboardUser, isMobile),
    }

    this.pathToTitle = this.mapPathToTitle([...routes, ...profileRoutes, ...washerRoutes])
    const fullScreen = this.props.currentDashboardUser.fullScreen
    this.state = {
      mobileOpen: false,
      miniActive: fullScreen ? fullScreen : false,
    }
    this.resizeFunction = this.resizeFunction.bind(this)
  }

  mapPathToTitle(routes) {
    return routes.reduce((acc, route) => {
      if (route.collapse) {
        acc = { ...acc, ...this.mapPathToTitle(route.views) }
      } else if (this.paths[route.layout + route.path]) {
        acc[route.layout + route.path] = route.title
      }
      return acc
    }, {})
  }

  getSwitchRoutes(pathToComponent, mainRoute) {
    let array = Object.keys(this.paths)
    array.push('/shift')
    return (
      <Switch>
        {array.map((path, pathsArrayIndex) => {
          return (
            <Route
              exact
              path={path}
              component={pathToComponent[path]}
              key={pathsArrayIndex}
            />
          )
        })}
        <Redirect from={'/'} to={mainRoute} />
      </Switch>
    )
  }

  componentDidMount() {
    if (
      !(this.props.currentDashboardUser.role === 'unit') &&
      navigator.platform.indexOf('Win') > -1
    ) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
      document.body.style.overflow = 'hidden'
    }
    window.addEventListener('resize', this.resizeFunction)
  }

  componentWillUnmount() {
    if (
      !(this.props.currentDashboardUser.role === 'unit') &&
      navigator.platform.indexOf('Win') > -1
    ) {
      ps.destroy()
    }
    window.removeEventListener('resize', this.resizeFunction)
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive })
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false })
    }
  }

  getAllRoutes() {
    return this.getRoute(
      this.paths,
      this.props.classes,
      this.filteredRoutes.length
        ? this.filteredRoutes[0].path
        : '/edit-profile-page'
    )
  }

  getRoute(path, classes, mainRoute) {
    return (
      <div>
        <div className={classes.container}>
          {this.getSwitchRoutes(path, mainRoute)}
        </div>
      </div>
    )
  }

  functionOpenClose = () => {
    this.setState(() => ({ miniActive: !this.state.miniActive }))
  }

  render() {
    const {
      classes,
      currentDashboardUser,
      i18n,
      languageCodes,
      ...rest
    } = this.props

    const role = currentDashboardUser.role.name
    const isUnitRole = role === 'unit'
    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      })
    return (
      <div className={classes.wrapper}>
        <LogoutModalContainer>
          {!isUnitRole ?
            (
              this.state.miniActive === true ?
                !this.state.mobileOpen ?
                  <Menu
                    onClick={this.functionOpenClose}
                    style={{ margin: '20px', cursor: 'pointer' }}
                  />
                  : null
                :
                <Sidebar
                  logo={logo}
                  routes={this.filteredRoutes}
                  image={image}
                  handleDrawerToggle={this.handleDrawerToggle}
                  functionOpenClose={this.functionOpenClose}
                  open={this.state.mobileOpen}
                  color="blue"
                  bgColor="black"
                  // miniActive="false"
                  miniActive={this.state.miniActive}
                  {...rest}
                />

            ) : null}
          <div
            id="mainPanel"
            className={isUnitRole ? '' : mainPanel}
            ref="mainPanel"
          >
            <Header
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              pathToHeaderTitle={this.pathToTitle}
              miniActive={this.state.miniActive}
              i18n={i18n}
              languageCodes={languageCodes}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
              routes={this.filteredRoutes}
            />

            <div
              className={classes.content}
              style={{
                paddingTop: 0,
                marginTop: 0,
                paddingLeft: 30,
                paddingRight: 30,
              }}
            // onClick={this.functionOpenClose}
            >
              {this.getAllRoutes()}
            </div>
          </div>
        </LogoutModalContainer>
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(
  withAuthentication(withStyles(appStyle)(withTranslation()(Dashboard)))
)





