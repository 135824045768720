let allPages = {
  'trends': 'Trends',
  'daily-washes': 'Daily Washes',
  'hands-recognition': 'Hands recognition',
  'myunits': 'Units',
  'status': 'Status',
  'washers': 'Washers',
  'dashboard-users': 'Dashboard Users',
  'configuration': 'Unit configuration',
  'campaigns': 'Campaigns',
  'organizations': 'Organizations',
  'distributors': 'Distributors',
  'sites': 'Sites',
  'bottles': 'Bottles',
  'policy': 'Policy',
  'machine-update-settings': 'Settings',
  'dashboard': 'Dashboard',
  'shift': 'Shift',
  'reports': 'Reports'
}

const pages = {
  'neo': {
    'admin': Object.keys(allPages)
  },
  'distributor': {
    'admin': [
      'sites',
      'organizations',
      'dashboard-users',
      'washers',
      'myunits',
      'trends',
      'daily-washes',
      'dashboard'
    ],
    'technician': ['status'],
    'user': [
      'sites',
      'organizations',
      'status',
      'myunits',
      'dashboard'
    ],
  },
  'site': {
    'admin': [
      'notification-settings',
      'trends',
      'daily-washes',
      'organizations',
      'status',
      'myunits',
      'dashboard'
    ],
    'technician': ['status'],
    'user': [
      'sites',
      'trends',
      'daily-washes',
      'organizations',
      'status',
      'myunits',
      'dashboard'
    ]
  },
  'organization': {
    'admin': [
      'notification-settings',
      'status',
      'shift',
      'dashboard-users',
      'washers',
      'sites',
      'organizations',
      'myunits',
      'trends',
      'dashboard',
      'daily-washes',
    ],
    'user': [
      'trends',
      'sites',
      'organizations',
      'status',
      'myunits',
      'dashboard'
    ],
  },
}

module.exports = {
  pages,
  allPages
}