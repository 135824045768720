import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 *
 * @param {object} requestObject
 * @param {date} requestObject.date
 * @param {date} requestObject.toDate
 * @param {date} requestObject.fromHour
 * @param {date} requestObject.toHour
 * @param {string[]} requestObject.machineIds
 */
export default (requestObject) => {
  const endpoint = apiEndpoints.getHandsRecognition
  const URL = SERVER_PATH + endpoint
  return resolveRequest(axios.post(URL, requestObject, {
    withCredentials: true
  }))
}
