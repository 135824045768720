import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useMachine } from '@xstate/react'
import React, { useRef } from 'react'
import Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import DateModal from '../../components-soapy/DateModal/DateModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import Snackbar from '../../components/Snackbar/Snackbar.jsx'
import fetchStatuses from '../../endpoint-requests/wash-status/fetchStatuses.js'
import getCookie from '../../shared/cookie/get-cookie.js'
import setCookie from '../../shared/cookie/set-cookie.js'
import ConfigurationLoginModal from './ConfigurationLoginModal.jsx'
import ConfigurationModal from './ConfigurationModal.jsx'
import StatusTable from './StatusTable.jsx'
import StatusTableHeader from './StatusTableHeader.jsx'

const defaultDate = Datetime.moment().startOf('day')
const defaultToDate = Datetime.moment().endOf('day')
const getStatusMachine = () => Machine({
  id: 'units',
  entry: ['extractFromCookie'],
  initial: getCookie('statusSerialNumber') ? 'loadStatuses' : 'main',
  context: {
    washStatuses: [],
    lastSync: 'None',
    lastVersion: 'None',
    elanMacAddress: 'N/A',
    wlanMacAddress: 'N/A',
    remoteIotStatus: false,
    isLatestVersion: false,
    machineFound: false,
    isAtpModeActive: false,
    statusConfiguration: {},
    password: '',
    bottleWashCount: '',
    serialNumber: '',
    date: defaultDate,
    toDate: defaultToDate,
    error: ''
  },
  states: {
    loadStatuses: {
      invoke: {
        src: (context, event) => fetchStatuses({
          serialNumber: context.serialNumber,
          date: context.date.toDate(),
          toDate: context.toDate.toDate()
        }),
        onDone: {
          target: 'main',
          actions: assign({
            washStatuses: (context, event) => event.data.washStatuses,
            lastSync: (context, event) => event.data.lastSync,
            isAtpModeActive: (context, event) => event.data.atpMode,
            lastVersion: (context, event) => event.data.lastVersion,
            elanMacAddress: (context, event) => event.data.elanMacAddress,
            wlanMacAddress: (context, event) => event.data.wlanMacAddress,
            remoteIotStatus: (context, event) => event.data.remoteIotStatus,
            isLatestVersion: (context, event) => event.data.isLatestVersion,
            machineFound: (context, event) => !!event.data.id,
            error: null,
            bottleWashCount: (context, event) => event.data.currentBottleWashCount
          })
        },
        onError: {
          target: 'main',
          actions: assign({
            error: (context, event) => event.data.message
          })
        }
      }
    },
    main: {
      exit: ['hideSuccessNotification'],
      after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
      on: {
        OPEN_DATE_MODAL: {
          target: 'dateModal'
        },
        CHANGE_SERIAL_NUMBER: {
          actions: ['setSerialNumber']
        },
        LOAD_WASH_STATUSES: {
          target: 'loadStatuses',
          actions: ['setSerialNumber']
        },
        OPEN_CONFIGURATION_LOGIN_MODAL: {
          target: 'configurationLoginModal'
        }

      }
    },
    configurationModal: {
      on: {
        CLOSE_CONFIGURATION_MODAL: {
          target: 'main'
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    },
    configurationLoginModal: {
      on: {
        CLOSE_CONFIGURATION_LOGIN_MODAL: {
          target: 'main'
        },
        OPEN_CONFIGURATION_MODAL: {
          target: 'configurationModal',
          actions: ['setStatusConfiguration', 'setPassword']
        }

      }
    },

    dateModal: {
      on: {
        CLOSE_DATE_MODAL: {
          target: 'main',
          actions: ['setDate', 'setToDate']
        }
      }
    },
    submitSuccessDialog: {
      on: {
        CLOSE_SUBMIT_SUCCESS_MODAL: 'main'
      }
    }
  }
},
{
  actions: {
    extractFromCookie: assign({
      serialNumber: (context, event) => getCookie('statusSerialNumber') || '',
      date: (context, event) => getCookie('statusDate') ? Datetime.moment(getCookie('statusDate')) : defaultDate,
      toDate: (context, event) => getCookie('statusToDate') ? Datetime.moment(getCookie('statusToDate')) : defaultToDate
    }),
    setDate: assign({
      date: (context, event) => event.date || context.date
    }),
    setToDate: assign({
      toDate: (context, event) => event.toDate || context.toDate
    }),
    setSerialNumber: assign({
      serialNumber: (context, event) => event.serialNumber
    }),
    setStatusConfiguration: assign({
      statusConfiguration: (context, event) => event.statusConfiguration
    }),
    setPassword: assign({
      password: (context, event) => event.password
    }),
    showSuccessNotification: assign({
      isSuccessNotificationVisible: true
    }),
    hideSuccessNotification: assign({
      isSuccessNotificationVisible: false
    })
  },
  guards: {
    isSuccessNotificationVisible: (context, event) => {
      return !!context.isSuccessNotificationVisible
    },
    serialNumberExists: (context, event) => {
      return false
    }
  }
}

)

const StatusContainer = ({ t }) => {
  const [current, send] = useMachine(getStatusMachine())
  const tableRef = useRef()

  switch (current.value) {
    case 'loadStatuses':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    default: {
      const {
        washStatuses,
        lastSync,
        lastVersion,
        remoteIotStatus,
        isAtpModeActive,
        elanMacAddress,
        wlanMacAddress,
        isLatestVersion,
        isSuccessNotificationVisible,
        date,
        toDate,
        machineFound,
        bottleWashCount,
        password,
        statusConfiguration,
        serialNumber
      } = current.context

      const isDateModalOpen = current.value === 'dateModal'
      const isConfigurationModalOpen = current.value === 'configurationModal'
      const isConfigurationLoginModalOpen = current.value === 'configurationLoginModal'

      return (
        <FullPagePaper>
          <Snackbar
            place='tc'
            color='success'
            icon={NotificationsIcon}
            message={t('Saved') + '!'}
            open={isSuccessNotificationVisible}
          />
          {
             isConfigurationLoginModalOpen
               ? <ConfigurationLoginModal
                   isOpen={isConfigurationLoginModalOpen}
                   title=''
                   password={password}
                   openConfigurationModal={(statusConfiguration, password) => {
                     send([{ type: 'OPEN_CONFIGURATION_MODAL', statusConfiguration, password }])
                   }}
                   onClose={() => send('CLOSE_CONFIGURATION_LOGIN_MODAL')}
                   serialNumber={serialNumber}
                   submitBtnText={t('Open configuration')}
                 />
               : null
           }

          <ConfigurationModal
            isOpen={isConfigurationModalOpen}
            statusConfiguration={statusConfiguration}
            password={password}
            title={t('Configuration')}
            configurationSaved={() => {
              send(['SHOW_SUCCESS_NOTIFICATION', 'CLOSE_CONFIGURATION_MODAL'])
            }}
            onClose={() => send('CLOSE_CONFIGURATION_MODAL')}
            serialNumber={serialNumber}
          />
          <DateModal
            isOpen={isDateModalOpen}
            closeModal={
              () => {
                send('CLOSE_DATE_MODAL')
              }
            }
            isDateRange
            displayedDate={date}
            displayedToDate={toDate}
            onApply={(fromDate, toDate) => {
              setCookie('statusDate', fromDate)
              setCookie('statusToDate', toDate)
              send({ type: 'CLOSE_DATE_MODAL', date: fromDate, toDate })
            }}
          />
          <StatusTableHeader
            lastSync={lastSync}
            lastVersion={lastVersion}
            remoteIotStatus={remoteIotStatus}
            isAtpModeActive={isAtpModeActive}
            elanMacAddress={elanMacAddress}
            wlanMacAddress={wlanMacAddress}
            isLatestVersion={isLatestVersion}
            toDate={toDate}
            washesExist={Boolean(washStatuses.length) && !current.context.error}
            tableRef={tableRef}
            bottleWashCount={bottleWashCount}
            machineFound={machineFound && !current.context.error}
            openConfigurationLoginModal={() => {
              send('OPEN_CONFIGURATION_LOGIN_MODAL')
            }}
            setSerialNumber={(serialNumber) => {
              send({ type: 'CHANGE_SERIAL_NUMBER', serialNumber })
            }}
            serialNumber={serialNumber}
            fetchWashStatuses={(serialNumber) => {
              if (serialNumber) {
                setCookie('statusSerialNumber', serialNumber)
              }
              send({ type: 'LOAD_WASH_STATUSES', serialNumber })
            }}
            openDateModal={
              () => {
                send('OPEN_DATE_MODAL')
              }
            }
            date={date}
          />
          {current.context.error || !machineFound
            ? <div style={{
              textAlign: 'center',
              fontSize: '1.3rem',
              padding: '2%'
            }}
              >
              <span>
                {t(current.context.error)}
              </span>
              </div>
            : <StatusTable tableRef={tableRef} washStatuses={washStatuses} />}
        </FullPagePaper>
      )
    }
  }
}

export default withTranslation()(StatusContainer)
