import literToGallon from '../../utils/literToGallon.js'
import { faChild, faHistory, faTint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Tooltip } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import DeviceHub from '@material-ui/icons/DeviceHub'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ChartistGraph from 'react-chartist'
import { withTranslation } from 'react-i18next'
import soapDispenserIcon from '../../assets/img/soap-for-hands.svg'
import washHandsIcon from '../../assets/img/washing-hand.svg'
import dashboardStyle from '../../assets/jss/material-dashboard-pro-react/views/dashboardStyle'
import Card from '../../components-soapy/Card/Card'
import CardBody from '../../components/Card/CardBody'
import CardHeader from '../../components/Card/CardHeader'
import CardIcon from '../../components/Card/CardIcon'
import GridContainer from '../../components/Grid/GridContainer'
import ctBarLabels from '../../plugins/chartist-bar-labels.js'
import dashboardStatisticsRequestKeywords from '../../shared/data/dashboard-statistics.js'
import './style.css'
import DateModal from '../../shared/DateModal/DateModal'
import Button from '../../components/CustomButtons/Button'
import getLanguageLocale from '../../shared/multi-language/getLanguageLocale'
import setCookie from '../../shared/cookie/set-cookie'
import getCookie from '../../shared/cookie/get-cookie'
import Datetime from 'react-datetime'

const reactChartistConfig = {
  options: {
    distributeSeries: true,
    axisX: {
      showGrid: false,
      labelOffset: {
        x: 0,
        y: 10
      }
    },
    axisY: {
      offset: 80,
      labelInterpolationFnc: (value, index) => {
        return Math.round(value * 1000) / 1000
      }
    },
    height: '250px',
    plugins: [
      ctBarLabels({
        labelInterpolationFnc: function (value) {
          return value.toFixed(1)
        }
      })
    ]
  }

}

const formatDateBtnText = (fromDate, toDate) =>
  `${fromDate.locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm')} - ${toDate.locale(getLanguageLocale()).format('MMMM DD YYYY, HH:mm')}`

const Dashboard = ({
  classes,
  waterUsageConsumption,
  soapUsageConsumption,
  totalMachines,
  totalWashes,
  operatingMachineDaysSinceFirstInstallationText,
  operatingMachineDaysSinceFirstInstallation,
  adjustToNewDateRange,
  defaultFromDate,
  defaultToDate,
  t
}) => {
  const [measurement, setMeasurement] = useState('imperial')
  const {
    [dashboardStatisticsRequestKeywords.TOTAL_WATER_USED]: totalWaterUsed,
    [dashboardStatisticsRequestKeywords.ALTERNATIVE_TOTAL_WATER_USED]: alternativeTotalWaterUsed
  } = waterUsageConsumption
  const {
    [dashboardStatisticsRequestKeywords.TOTAL_SOAP_USED]: totalSoapUsed,
    [dashboardStatisticsRequestKeywords.ALTERNATIVE_TOTAL_SOAP_USED]: alternativeTotalSoapUsed
  } = soapUsageConsumption
  const [fromHour, setFromHour] = useState(defaultFromDate)
  const [toHour, setToHour] = useState(defaultToDate)
  const [fromDate, setFromDate] = useState(getCookie('dashboardFromDate') ? Datetime.moment(getCookie('dashboardFromDate')) : defaultFromDate)
  const [toDate, setToDate] = useState(getCookie('dashboardToDate') ? Datetime.moment(getCookie('dashboardToDate')) : defaultToDate)
  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [filterByHours, setFilterByHours] = useState(false)

  const closeDateModal = () => {
    setIsDateModalOpen(false)
  }

  const openDateModal = () => {
    setIsDateModalOpen(true)
  }

  const dateBtnText = formatDateBtnText(fromDate, toDate)

  const dataToReactChartistConfig = data => {
    return {
      data: {
        labels: ['Soapy', t('Alternative')],
        series: data
      }
    }
  }

  const totalDonatedUnits = Math.round(totalMachines / 10)
  const totalKidsUsingDonatedUnits = totalDonatedUnits * 100
  const totalDonatedWashes = totalKidsUsingDonatedUnits * 3

  const {
    data: waterConsumptionComparisonChartistData
  } = dataToReactChartistConfig([
    measurement === 'metric' ? totalWaterUsed : literToGallon(totalWaterUsed),
    measurement === 'metric' ? alternativeTotalWaterUsed : literToGallon(alternativeTotalWaterUsed)
  ])

  const amountWaterSavedLiters = (
    alternativeTotalWaterUsed - totalWaterUsed
  ).toFixed(1)

  const toLiters = millileterAmount => millileterAmount * 0.001
  const {
    data: soapConsumptionComparisonChartistData
  } = dataToReactChartistConfig([
    measurement === 'metric' ? toLiters(totalSoapUsed).toFixed(1) : literToGallon(toLiters(totalSoapUsed)).toFixed(1),
    measurement === 'metric' ? toLiters(alternativeTotalSoapUsed).toFixed(1) : literToGallon(toLiters(alternativeTotalSoapUsed)).toFixed(1)
  ])
  const amountSoapSavedLiters = (
    (alternativeTotalSoapUsed - totalSoapUsed) *
    0.001
  ).toFixed(1)

  const dailySavedWaterLiter = parseFloat(
    (((measurement === 'metric' ?
      amountWaterSavedLiters / operatingMachineDaysSinceFirstInstallation :
      literToGallon(amountWaterSavedLiters) / operatingMachineDaysSinceFirstInstallation) * 10) / 10).toFixed(2)
  )

  return (
    <div>
      <GridContainer>
        <DateModal
          displayedFromDate={fromDate}
          displayedToDate={toDate}
          filterByHours={filterByHours}
          fromHour={fromHour}
          toHour={toHour}
          handleApplyBtnClick={(fromDate, toDate, fromHour, toHour, filterByHours) => {
            setCookie('dashboardFromDate', fromDate)
            setCookie('dashboardToDate', toDate)
            adjustToNewDateRange(fromDate, toDate, fromHour, toHour, filterByHours)
            closeDateModal()
          }}
          closeModal={closeDateModal}
          isOpen={isDateModalOpen}
        />
        <Grid
          item md={12}
          style={{
            marginBottom: '2rem'
          }}
        >
          <ToggleButtonGroup
            value={measurement}
            exclusive
            onChange={(e, value) => {
              setMeasurement(value)
            }}
            aria-label="text unitValue"
          >
            <ToggleButton id="unitConfImperialBtn" value="imperial" aria-label="left aligned">
              {t('Imperial')}
            </ToggleButton>
            <ToggleButton id="unitConfMetricBtn" value="metric" aria-label="centered">
              {t('Metric')}
            </ToggleButton>
          </ToggleButtonGroup>
          <Button
            style={{ marginLeft: '2%' }}
            color="info"
            id="trendsDateButton"
            round
            onClick={openDateModal}
          >
            {dateBtnText}
          </Button>
        </Grid>
        <Grid item md={4} style={{ paddingRight: 20 }}>
          <Card>
            <CardHeader color="success" icon>
              <Tooltip title={t('Soapy Give washes')}>
                <CardIcon
                  color="info"
                  style={{
                    paddingTop: 8,
                    paddingBottom: 10,
                    marginTop: -10,
                    marginBottom: -10,
                    width: 48,
                    height: 48,
                    paddingLeft: 10
                  }}
                >
                  <img
                    src={washHandsIcon}
                    style={{ width: '2em', height: '2em' }}
                    alt="washHands"
                  />
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Soapy Give washes')}</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <h3 className={classes.cardTitle}>{totalDonatedWashes}</h3>
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={4} style={{ paddingRight: 20 }}>
          <Card>
            <CardHeader color="success" icon>
              <Tooltip title={t('Children reached by Soapy Give')}>
                <CardIcon
                  color="info"
                  style={{
                    paddingTop: 8,
                    paddingBottom: 10,
                    marginTop: -10,
                    marginBottom: -10,
                    width: 48,
                    height: 48,
                    paddingLeft: 13
                  }}
                >
                  <FontAwesomeIcon icon={faChild} size="lg"/>
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Children reached by Soapy Give')}</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <h3 className={classes.cardTitle}>

                  {totalKidsUsingDonatedUnits}
                </h3>
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card style={{ marginBottom: 20 }}>
            <CardHeader color="success" icon>
              <Tooltip title={t('Soapy Give units')}>
                <CardIcon
                  color="info"
                  style={{
                    paddingTop: 8,
                    paddingBottom: 10,
                    marginTop: -10,
                    marginBottom: -10,
                    width: 48,
                    height: 48,
                    paddingLeft: 10
                  }}
                >
                  <DeviceHub/>
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Soapy Give units')}</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <h3 className={classes.cardTitle}>{totalDonatedUnits}</h3>
              </div>
            </CardBody>
          </Card>
        </Grid>


        <Grid item md={6} style={{ paddingRight: 20 }}>

          <Card style={{ marginBottom: 20 }}>

            <CardHeader icon>
              <Tooltip title={t(`Water consumption (${measurement === 'metric' ? 'liters' : 'gallons'})`)}>
                <CardIcon
                  color="info"
                  style={{
                    paddingTop: 8,
                    paddingBottom: 10,
                    marginTop: -10,
                    marginBottom: -10,
                    width: 48,
                    height: 48
                  }}
                >
                  <FontAwesomeIcon icon={faTint} size="lg"/>
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>
                {t(`Water consumption (${measurement === 'metric' ? 'liters' : 'gallons'})`)}
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                className="bar-chart"
                data={waterConsumptionComparisonChartistData}
                type="Bar"
                options={reactChartistConfig.options}
              />
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card style={{ marginBottom: 20 }}>
            <CardHeader color="rose" icon>
              <Tooltip title={t('Soap consumption (liters)')}>
                <CardIcon
                  color="info"
                  style={{
                    paddingTop: 8,
                    paddingBottom: 10,
                    marginTop: -10,
                    marginBottom: -10,
                    width: 48,
                    height: 48,
                    paddingLeft: 10
                  }}
                >
                  <img
                    src={soapDispenserIcon}
                    style={{ width: '2em', height: '2em' }}
                    alt="soapDispenser"
                  />
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>
                {t(`Soap consumption (${measurement === 'metric' ? 'liters' : 'gallons'})`)}
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                className="bar-chart"
                data={soapConsumptionComparisonChartistData}
                type="Bar"
                options={reactChartistConfig.options}
              />
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={6} style={{ paddingRight: 20 }}>
          <Card style={{ marginBottom: 20 }}>
            <div style={{ display: 'inherit' }}>
              <div>
                <CardHeader color="rose" icon style={{ display: 'inline-flex' }}>
                  <Tooltip title={t('Water saved')}>
                    <CardIcon
                      color="info"
                      style={{
                        paddingTop: 8,
                        paddingBottom: 10,
                        marginTop: -10,
                        marginBottom: -10,
                        width: 48,
                        height: 48
                      }}
                    >
                      <FontAwesomeIcon icon={faTint} size="lg"/>
                    </CardIcon>
                  </Tooltip>
                  <h4 className={classes.cardIconTitle}>{t('Water saved')}</h4>
                </CardHeader>
              </div>
              <div style={{ width: '40%' }}>
                <button style={{
                  border: '1px solid rgba(156,156,156,0.4)',
                  color: '#00bcd4',
                  fontWeight: 500,
                  fontSize: '11pt',
                  margin: '1% 1% 0 0',
                  width: '35%',
                  position: 'absolute',
                  right: 0,
                  borderRadius: '8px',
                  textAlign: 'center',
                  backgroundColor: '#eeeeee'
                }}
                >
                  <p style={{ margin: '4%' }}>
                    {
                      measurement === 'metric'
                        ? `${t('Daily')} ${dailySavedWaterLiter}${t('L')}`
                        : `${t('Daily')} ${literToGallon(dailySavedWaterLiter).toFixed(2)}${t('G')}`
                    }
                  </p>
                </button>
              </div>
            </div>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <h3 className={classes.cardTitle}>
                  {
                    measurement === 'metric'
                      ? `${amountWaterSavedLiters}${t('L')}`
                      : `${literToGallon(amountWaterSavedLiters).toFixed(2)}${t('G')}`
                  }

                </h3>
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card style={{ marginBottom: 20 }}>
            <CardHeader color="rose" icon>
              <Tooltip title={t('Soap saved')}>
                <CardIcon
                  color="info"
                  style={{
                    paddingTop: 8,
                    paddingBottom: 10,
                    marginTop: -10,
                    marginBottom: -10,
                    width: 48,
                    height: 48,
                    paddingLeft: 10
                  }}
                >
                  <img
                    src={soapDispenserIcon}
                    style={{ width: '2em', height: '2em' }}
                    alt="soapDispenser"
                  />
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Soap saved')}</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <h3 className={classes.cardTitle}>
                  {
                    measurement === 'metric'
                      ? `${amountSoapSavedLiters}${t('L')}`
                      : `${literToGallon(amountSoapSavedLiters).toFixed(2)}${t('G')}`
                  }
                </h3>
              </div>
            </CardBody>
          </Card>
        </Grid>

        <Grid item md={4} style={{ paddingRight: 20 }}>
          <Card>
            <CardHeader color="success" icon>
              <Tooltip title={t('Total washes')}>
                <CardIcon
                  color="info"
                  style={{
                    paddingTop: 8,
                    paddingBottom: 10,
                    marginTop: -10,
                    marginBottom: -10,
                    width: 48,
                    height: 48,
                    paddingLeft: 10
                  }}
                >
                  <img
                    src={washHandsIcon}
                    style={{ width: '2em', height: '2em' }}
                    alt="washHands"
                  />
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Total washes')}</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <h3 className={classes.cardTitle}>{totalWashes}</h3>
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={4} style={{ paddingRight: 20 }}>
          <Card>
            <CardHeader color="success" icon>
              <Tooltip title={t('Operating time')}>
                <CardIcon
                  color="info"
                  style={{
                    paddingTop: 8,
                    paddingBottom: 10,
                    marginTop: -10,
                    marginBottom: -10,
                    width: 48,
                    height: 48,
                    paddingLeft: 10
                  }}
                >
                  <FontAwesomeIcon icon={faHistory} size="lg"/>
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Operating time')}</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <h3 className={classes.cardTitle}>

                  {operatingMachineDaysSinceFirstInstallationText}
                </h3>
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardHeader color="success" icon>
              <Tooltip title={t('Total units')}>
                <CardIcon
                  color="info"
                  style={{
                    paddingTop: 8,
                    paddingBottom: 10,
                    marginTop: -10,
                    marginBottom: -10,
                    width: 48,
                    height: 48,
                    paddingLeft: 10
                  }}
                >
                  <DeviceHub/>
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Total units')}</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <h3 className={classes.cardTitle}>{totalMachines}</h3>
              </div>
            </CardBody>
          </Card>
        </Grid>
      </GridContainer>
    </div>
  )
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  waterUsageConsumption: PropTypes.object.isRequired,
  soapUsageConsumption: PropTypes.object.isRequired,
  totalMachines: PropTypes.number.isRequired,
  totalWashes: PropTypes.number.isRequired,
  combinedDurationOfMachinesOperationText: PropTypes.string
}

export default withStyles(dashboardStyle)(withTranslation()(Dashboard))
