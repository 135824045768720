import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux';
import { popUpSuccess } from '../../../../redux/popUpReducer';
import { useStylesPopUp } from '../../../Shift/shiftStyles'
import { siteMonitorOrientationSuccess } from '../../../../redux/sitesRedux';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: "none",
    borderRadius: "3px",
    boxShadow: 24,
    p: 4,
};

export default function WarningModal({ processForm, formFields, setFormFields, MONITOR_ORIENTATION }) {

    const open = useSelector((state) => state.popUp.open)
    const siteMonitorOrientation = useSelector((state) => state.sites.siteMonitorOrientation)

    const dispatch = useDispatch()
    const classes = useStylesPopUp();

    const handleClose = () => {
        dispatch(popUpSuccess(false))
        if (siteMonitorOrientation === "horizontal") {
            setFormFields({
                ...formFields,
                [MONITOR_ORIENTATION]: { ...formFields[MONITOR_ORIENTATION], value: "vertical" },
            })
            dispatch(siteMonitorOrientationSuccess("vertical"))
        } else {
            setFormFields({
                ...formFields,
                [MONITOR_ORIENTATION]: { ...formFields[MONITOR_ORIENTATION], value: "horizontal" },

            })
            dispatch(siteMonitorOrientationSuccess("horizontal"))
        }
    };

    const handleSubmit = () => {
        dispatch(popUpSuccess(false))
    }
    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" >
                        Change Orientation

                    </Typography>
                    <Close
                        onClick={() => handleClose()}
                        className={classes.myClassName}
                    />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: "10px" }} >
                        This site has an active campaign running, would you like to proceed?
                    </Typography>
                    <div className="popupButtonDiv" style={{ float: "right" }}>
                        <p
                            style={{
                                cursor: 'pointer',
                                marginRight: "50px",
                                width: "50px",
                                fontWeight: "300"
                            }} onClick={() => handleSubmit()}  >Yes</p>
                        <p
                            style={{
                                cursor: 'pointer',
                                color: "red",
                                width: "50px",
                                fontWeight: "300"
                            }} onClick={() => {
                                dispatch(popUpSuccess(false));
                                if (siteMonitorOrientation === "horizontal") {
                                    setFormFields({
                                        ...formFields,
                                        [MONITOR_ORIENTATION]: { ...formFields[MONITOR_ORIENTATION], value: "vertical" },
                                    })
                                    dispatch(siteMonitorOrientationSuccess("vertical"))
                                } else {
                                    setFormFields({
                                        ...formFields,
                                        [MONITOR_ORIENTATION]: { ...formFields[MONITOR_ORIENTATION], value: "horizontal" },
                                    })
                                    dispatch(siteMonitorOrientationSuccess("horizontal"))
                                }
                            }} > Cancel</p>

                    </div>
                </Box>
            </Modal>
        </div >
    );
}
