export const ID = 'id'
export const DESCRIPTION = 'description'
export const NAME = 'name'
export const ORGANIZATION = 'organization'
export const ORGANIZATION_ID = 'organizationId'
export const GROUPS_IDS = 'groupsIds'
export const LEFT = 'left'
export const UP = 'up'
export const CSV_FILE = 'csvFile'
export const FACE_ID = 'faceId'
export const RFID = 'rfid'
export const DOWN = 'down'
export const RIGHT = 'right'
export const FRONT = 'front'
export const VALIDATION_STATE = 'ValidationState'
export const GROUPS = 'groups'
