import sweetAlertStyle from '../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
const CustomSweetAlert = ({ classes, ...rest }) => (
  <SweetAlert
    {...rest}

    confirmBtnCssClass={classes.button + ' ' + classes.success}
  />
)

export default withStyles(sweetAlertStyle)(CustomSweetAlert)
