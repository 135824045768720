import WasherBulkAddModal from './WasherBulkAddModal.jsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useMachine } from '@xstate/react'
import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import DeleteModal from '../../components-soapy/ConfirmationModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import Snackbar from '../../components/Snackbar/Snackbar'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import fetchOrganizations from '../../endpoint-requests/organization/fetchOrganizations.js'
import deleteWasher from '../../endpoint-requests/user/deleteWasher.js'
import getWashers from '../../endpoint-requests/user/getWashers.js'
import AddWasherModal from './AddWasherModal.jsx'
import WashersTable from './WashersTable.jsx'
import WashersTableHeader from './WashersTableHeader.jsx'
import WasherDownloadCSV from './WasherDownloadCSV'
import { getGroups } from '../../redux/groups/apiCalls'
import { useDispatch, useSelector } from 'react-redux'

const headers = {
  'Washer ID': 'id',
  'Organization': 'organization',
  'Description': 'name',
  'Groups': 'groups',
  'Card ID': 'rfid',
  'Face ID': 'faceId',
}
const excelHeaders = Object.keys(headers).map(header => {
  return {
    value: header,
    style: {
      font: { sz: '12' },
      alignment: {
        wrapText: true,
        horizontal: 'center',
        vertical: 'top'
      },
      fill: {
        patternType: 'solid',
        fgColor: { rgb: '03b2cb' }
      }
    }
  }
})

function getWashersMachine (currentDashboardUser) {
  return Machine({
      id: 'units',
      initial: 'loadingWashers',
      context: {
        washers: [],
        error: '',
        washer: {},
        organizations: [],
        isSuccessNotificationVisible: false
      },
      states: {
        loadingWashers: {
          invoke: {
            src: (context, event) => Promise.all([
              getWashers(),
              currentDashboardUser.entity.name === 'neo' || currentDashboardUser.entity.name === 'distributor'
                ? fetchOrganizations({
                  fetchDefault: currentDashboardUser.entity.name === 'distributor',
                  order: 'asc',
                  orderBy: 'name'
                })
                : []]),
            onDone: {
              target: 'main',
              actions: assign({
                washers: (context, event) => event.data[0],
                organizations: (context, event) => event.data[1]
              })
            },
            onError: {
              target: 'failedFetchingData',
              actions: assign({
                error: (context, event) => event.data.message
              })
            }
          }
        },
        failedFetchingData: {
          type: 'final'
        },
        main: {
          exit: ['hideSuccessNotification'],
          after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
          on: {
            OPEN_ADD_WASHER_MODAL: {
              target: 'addWasherModal',
              actions: ['setWasher']
            },
            OPEN_WASHER_BULK_ADD_MODAL: {
              target: 'washerBulkAddModal'
            },
            OPEN_WASHER_DOWNLOAD_CSV_MODAL: {
              target: 'washerDownloadCSVModal'
            },
            OPEN_WASHER_DELETE_MODAL: {
              target: 'deleteWasherModal',
              actions: ['setWasher']
            }
          }
        },
        addWasherModal: {
          on: {
            CLOSE_ADD_WASHER_MODAL: 'main',
            WASHER_ADDED: {
              target: 'main',
              actions: ['addNewWasher']
            },
            WASHER_EDITED: {
              target: 'main',
              actions: ['editWasher']
            },
            SHOW_SUCCESS_NOTIFICATION: {
              actions: ['showSuccessNotification']
            }
          }
        },
        washerBulkAddModal: {
          on: {
            CLOSE_WASHER_BULK_ADD_MODAL: 'main',
            WASHERS_ADDED: {
              target: 'main',
              actions: ['addNewWashers']
            },
            SHOW_SUCCESS_NOTIFICATION: {
              actions: ['showSuccessNotification']
            }
          }
        },
        washerDownloadCSVModal: {
          on: {
            CLOSE_WASHER_DOWNLOAD_CSV_MODAL: 'main',
            WASHERS_ADDED: {
              target: 'main',
              actions: ['addNewWashers']
            },
            SHOW_SUCCESS_NOTIFICATION: {
              actions: ['showSuccessNotification']
            }
          }
        },
        deleteWasherModal: {
          on: {
            CLOSE_WASHER_DELETE_MODAL: {
              target: 'main',
              actions: ['setWasher']
            },
            WASHER_DELETED: {
              target: 'main',
              actions: ['deleteWasher']
            },
            SHOW_SUCCESS_NOTIFICATION: {
              actions: ['showSuccessNotification']
            }
          }
        }
      }
    },
    {
      actions: {
        showSuccessNotification: assign({
          isSuccessNotificationVisible: true
        }),
        hideSuccessNotification: assign({
          isSuccessNotificationVisible: false
        }),
        setWasher: assign({
          washer: (context, event) => event.washer
        }),
        editWasher: assign({
          washers: (context, event) => {
            // console.log(context, event)
            return {
              washers: context.washers.washers.map(washer => washer.id === event.washer.id ? event.washer : washer),
              washerMasks: context.washers.washerMasks
            }
          }
        }),
        addNewWasher: assign({
          washers: (context, event) => {
            return {
              washers: [event.washer, ...context.washers.washers],
              washerMasks: context.washers.washerMasks
            }
          }
        }),
        addNewWashers: assign({
          washers: (context, event) => {
            return {
              washers: [event.washers, ...context.washers.washers],
              washerMasks: context.washers.washerMasks
            }
          }
        }),
        deleteWasher: assign({
          washers: (context, event) => {
            return {
              washers: context.washers.washers.filter(washer => washer.id !== event.washer.id),
              washerMasks: context.washers.washerMasks
            }
          }
        })
      },
      guards: {
        isSuccessNotificationVisible: (context, event) => {
          return !!context.isSuccessNotificationVisible
        }
      }
    }
  )
}

const WashersContainer = ({ t, currentDashboardUser }) => {
  const dispatch = useDispatch()
  const { groups } = useSelector((state) => state.groups)
  const [current, send] = useMachine(getWashersMachine(currentDashboardUser))

  const isAddWasherModalOpen = current.value === 'addWasherModal'
  const isWasherBulkAddModalOpen = current.value === 'washerBulkAddModal'
  const isWasherDownloadCSVModalOpen = current.value === 'washerDownloadCSVModal'
  const isDeleteWasherModalOpen = current.value === 'deleteWasherModal'

  const tableRef = useRef()

  const getExportableDataset = () => {
    let dailyWashesExcelData = []
    if (tableRef.current) {
      dailyWashesExcelData = tableRef.current.getResolvedState().sortedData
    }

    return dailyWashesExcelData?.map(washer => {
      return {
        id: washer.id ? washer.id.toString() : '',
        organization: washer.organization || '',
        name: washer.name || '',
        groups: washer.groups || '',
        rfid: washer.rfid ? washer.rfid.toString() : '',
        faceId: washer.faceId ? washer.faceId.toString() : '',
      }
    })
  }

  const getExcelDataset = () => {
    const data = getExportableDataset()
    return [
      {
        columns: [],
        data: [
          [
            ...excelHeaders
          ],
          ...(data.map(washer => {
            return Object.values(washer).map(value => {
              return {
                value,
                style: {
                  widthPx: 1000,
                  alignment: {
                    wrapText: true,
                    horizontal: 'center',
                    vertical: 'top'
                  },
                  font: { sz: '12' }
                }
              }
            })
          }))
        ]
      }
    ]
  }

  const [excelDataset, setExcelDataset] = useState([])

  const filterChange = () => {
    let dataset = getExcelDataset()
    setExcelDataset(dataset)
  }

  //-------------UseEffect api call for get all groups and get all organizations------------------//
  useEffect(() => {
    getGroups(dispatch)
  }, [])

  useEffect(() => {
    filterChange()
  }, [current.context])

  switch (current.value) {
    case 'loadingWashers':
      return (
        <CircularProgress
          color="primary"
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t(current.context.error)}</div>
    default: {
      const {
        washers,
        washer,
        organizations,
        isSuccessNotificationVisible
      } = current.context

      return (
        <FullPagePaper>
          <Snackbar
            place="tc"
            color="success"
            icon={NotificationsIcon}
            message={t('Saved') + '!'}
            open={isSuccessNotificationVisible}
          />
          {
            isAddWasherModalOpen
              ? <AddWasherModal
                groups={groups}
                isOpen={isAddWasherModalOpen}
                organizations={organizations}
                washer={washer}
                onClose={() => send('CLOSE_ADD_WASHER_MODAL')}
                washerEdited={(washer) => {
                  send([
                    'SHOW_SUCCESS_NOTIFICATION',
                    {
                      type: 'WASHER_EDITED',
                      washer
                    }
                  ])
                }}
                washerAdded={(washer) => {
                  send([
                    'SHOW_SUCCESS_NOTIFICATION',
                    {
                      type: 'WASHER_ADDED',
                      washer
                    }
                  ])
                }}
              />
              : null
          }
          {
            isWasherBulkAddModalOpen
              ? <WasherBulkAddModal
                isOpen={isWasherBulkAddModalOpen}
                organizations={organizations}
                onClose={() => send('CLOSE_WASHER_BULK_ADD_MODAL')}
                washersAdded={(washers) => {
                  send([
                    'SHOW_SUCCESS_NOTIFICATION',
                    {
                      type: 'WASHERS_ADDED',
                      washers
                    }
                  ])
                }}
              />
              : null
          }
          {isWasherDownloadCSVModalOpen
            ? <WasherDownloadCSV
              isOpen={isWasherDownloadCSVModalOpen}
              washers={washers}
              organizations={organizations}
              onClose={() => send('CLOSE_WASHER_DOWNLOAD_CSV_MODAL')}
              washersAdded={(washers) => {
                send([
                  'SHOW_SUCCESS_NOTIFICATION',
                  {
                    type: 'WASHERS_ADDED',
                    washers
                  }
                ])
              }}
            />
            : null
          }

          <DeleteModal
            isOpen={isDeleteWasherModalOpen}
            onClose={() => {
              send({ type: 'CLOSE_WASHER_DELETE_MODAL', washer: {} })
            }}
            onApply={async () => {
              await deleteWasher(washer.id)
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'WASHER_DELETED', washer }])
            }}
          />
          <WashersTableHeader
            excelDataset={excelDataset}
            openAddWasherModal={() => {
              send({ type: 'OPEN_ADD_WASHER_MODAL', washer: {} })
            }}
            openWasherBulkAddModal={() => {
              send({ type: 'OPEN_WASHER_BULK_ADD_MODAL', washer: {} })
            }}
            openWasherDownloadCSVModal={() => {
              send({ type: 'OPEN_WASHER_DOWNLOAD_CSV_MODAL', washer: {} })
            }}
            totalWashers={washers.length}
          />
          <WashersTable
            tableRef={tableRef}
            filterChange={filterChange}
            washers={washers}
            openUpdateWasherModal={(washer) => send({ type: 'OPEN_ADD_WASHER_MODAL', washer })}
            openDeleteWasherModal={(washer) => send({ type: 'OPEN_WASHER_DELETE_MODAL', washer })}
          />
        </FullPagePaper>
      )
    }
  }
}

export default withAuthentication(withTranslation()(WashersContainer))
