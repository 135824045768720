import withErrorNotification from '../../../containers/withErrorNotification.js'
import React from 'react'
import { withTranslation } from 'react-i18next'
import CycleModal from '../../../components-soapy/CycleModal.jsx'
import { setTrigger } from '../../../endpoint-requests/machine/setTrigger'

const TriggerSoapModalContainer = ({
  onClose,
  showErrorNotification,
  isOpen,
  machine,
  t
}) => {
  const onApply = async (machine, triggerType) => {
    const body = {
      id: machine.id
    }
    if (triggerType === 'soap') {
      body.soap = true
    } else {
      body.water = true
    }
    try {
      await setTrigger(body)
      onClose(true)
    } catch (err) {
      showErrorNotification(err.message)
    }
  }
  return (
    <>
      <CycleModal
        isOpen={isOpen}
        title={t('Trigger water or reagent')}
        machine={machine}
        onApply={() => {
          onApply(machine, 'soap')
        }}
        onApply2={() => {
          onApply(machine, 'water')
        }}
        onCancel={() => {
          onClose()
        }}
        applyButtonText='reagent'
        applyButtonText2='water'
      />
    </>
  )
}

export default withErrorNotification(withTranslation()(TriggerSoapModalContainer))
