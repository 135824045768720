import { Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert';
import React from 'react';
import Datetime from 'react-datetime';
import { withTranslation } from 'react-i18next';
import trendsDateModalStyle from '../../assets-soapy/jss/soapy/views/trendsDateModalStyle.jsx';
import Modal from '../../components-soapy/Modal';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Snackbar from '../../components/Snackbar/Snackbar.jsx';
// @material-ui/core components
import getLanguageLocale from '../multi-language/getLanguageLocale';
import './DateModal.css';


///////////// UTILITY /////////////////////
const isValidDate = current => {
    const today = Datetime.moment();
    return current.isBefore(today);
};

////////////// COMPONENT ///////////////////
class DateModal extends React.Component {
    constructor(props) {
        super(props);

        const {displayedFromDate, displayedToDate, fromHour, toHour, filterByHours} = this.props;

        this.state = {
            fromDate: displayedFromDate,
            filterByHours: filterByHours,
            fromHour: fromHour,
            toHour: toHour,
            toDate: displayedToDate,
            isErrorNotificationActive: false
        };
    }

    handleFromDateChanged = newDate => {
        this.setState({fromDate: newDate});
    };

    handleToDateChanged = newDate => {
        this.setState({toDate: newDate});
    };
    handleToHourChanged = newHour => {
        this.setState({toHour: newHour});
    };
    handleFromHourChanged = newHour => {
        this.setState({fromHour: newHour});
    };

    handleApplyBtnClick = () => {
        const {fromDate, toDate,fromHour,toHour,filterByHours} = this.state;
        const {handleApplyBtnClick} = this.props;
        if (fromDate.isSameOrBefore(toDate)) {
            handleApplyBtnClick(fromDate, toDate,fromHour,toHour,filterByHours);
        } else {
            this.showErrorNotificationNotValidDateRange();
        }
    };

    showErrorNotificationNotValidDateRange = () => {
        const {isErrorNotificationActive} = this.state;
        if (!isErrorNotificationActive) {
            this.setState({
                isErrorNotificationActive: true
            });
            setTimeout(
                function () {
                    this.setState({isErrorNotificationActive: false});
                }.bind(this),
                6000
            );
        }
    };

    closeModal = () => {
        const {displayedFromDate, displayedToDate, closeModal} = this.props;
        this.setState({fromDate: displayedFromDate, toDate: displayedToDate});
        closeModal();
    };

    render() {
        const {fromDate, toDate, isErrorNotificationActive,fromHour, toHour} = this.state;
        const {isOpen, t, classes} = this.props;
        const title = t('Filter by date');
        return (
            <Modal
                isOpen={isOpen}
                title={title}
                dialogPaper={classes.dialogPaper}
                isLoading={this.props.isLoading}
                onApply={this.handleApplyBtnClick}
                onCancel={this.closeModal}
            >
                <Snackbar
                    place="tc"
                    color="danger"
                    icon={AddAlert}
                    message={'Date range is invalid'}
                    open={isErrorNotificationActive}
                    closeNotification={() =>
                        this.setState({isErrorNotificationActive: false})
                    }
                    close
                />
                <br/>
                <br/>
                <GridContainer>
                    <GridItem md={6}>
                        <InputLabel style={{fontSize: '14px'}}>{t('From date')}</InputLabel>
                        <Datetime
                            locale={getLanguageLocale()}
                            inputProps={{style: {fontSize: '12px'}, id:'fromDateInput', onChange: ()=>null}}
                            value={fromDate}
                            onChange={this.handleFromDateChanged}
                            dateFormat="MMMM DD YYYY"
                            timeFormat={"HH:mm"}
                            closeOnSelect={true}
                            isValidDate={isValidDate} // Limit selectable dates
                        />
                    </GridItem>
                    <GridItem md={6}>
                        <InputLabel style={{fontSize: '14px'}}>{t('To date')}</InputLabel>
                        <Datetime
                            locale={getLanguageLocale()}
                            inputProps={{style: {fontSize: '12px'}, id:'toDateInput', onChange: ()=>null}}
                            value={toDate}
                            onChange={this.handleToDateChanged}
                            dateFormat="MMMM DD YYYY"
                            timeFormat={"HH:mm"}
                            closeOnSelect={true}
                            isValidDate={isValidDate} // Limit selectable dates
                        />
                    </GridItem>
                    <GridItem md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                            checked={this.state.filterByHours}
                            onChange={()=>this.setState({filterByHours:!this.state.filterByHours})}
                            value="checkedB"
                            color="primary"
                            />
                        }
                        style={{
                            fontWeight:'bold',
                            color:'inherit',
                            fontSize: '2rem'
                        }}
                        label={t('Limit hours')}
                        />
                    </GridItem>
                    <br/>
                   
                    <GridItem md={6}>
                        <InputLabel style={{fontSize: '14px'}}>{t('From')}</InputLabel>
                        <Datetime
                            locale={getLanguageLocale()}
                            inputProps={{style: {fontSize: '12px'},autoComplete:'noAutocomplete', id:'fromHourInput', disabled:!this.state.filterByHours, onChange: ()=>null}}
                            value={fromHour}
                            viewMode='time'
                            onChange={this.handleFromHourChanged}
                            dateFormat=""
                            timeFormat={"HH"}
                            closeOnSelect={true}
                            // isValidDate={isValidDate} // Limit selectable dates
                        />
                    </GridItem>
                    <GridItem md={6}>
                        <InputLabel style={{fontSize: '14px'}}>{t('To')}</InputLabel>
                        <Datetime
                            locale={getLanguageLocale()}
                            autoComplete='new-password'
                            inputProps={{style: {fontSize: '12px'},autoComplete:'noAutocomplete',disabled:!this.state.filterByHours, id:'toHourInput', onChange: ()=>null}}
                            value={toHour}
                            viewMode='time'
                            onChange={this.handleToHourChanged}
                            dateFormat=""
                            timeFormat={"HH"}
                            closeOnSelect={true}
                            // isValidDate={isValidDate} // Limit selectable dates
                        />
                    </GridItem>
                </GridContainer>
            </Modal>
        );
    }
}

export default withStyles(trendsDateModalStyle)(withTranslation()(DateModal));
