import PoliciesTableHeader from './PoliciesTableHeader.jsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMachine } from '@xstate/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import DeleteModal from '../../components-soapy/ConfirmationModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper.jsx'
import fetchEntities from '../../endpoint-requests/entity/fetchEntities'
import fetchPermissions from '../../endpoint-requests/permission/fetchPermissions.js'
import deletePolicyByPermissionName from '../../endpoint-requests/policy/deletePolicyByPermissionName.js'
import fetchPolicies from '../../endpoint-requests/policy/fetchPolicies'
import AddPolicyModal from './AddPolicyModal.jsx'
import PoliciesTable from './PoliciesTable.jsx'

const PERMISSION_ACTIONS = 'permissionActions'

const policyMachine = Machine({
  id: 'units',
  initial: 'loadingPolicies',
  context: {
    policies: [],
    entities: [],
    permissions: [],
    isSuccessNotificationVisible: false,
    filteredMachineIds: [],
    policy: {},
    errorType: ''
  },
  states: {
    loadingPolicies: {
      invoke: {
        src: (context, event) => Promise.all([fetchPolicies(), fetchEntities(), fetchPermissions()]),
        onDone: {
          target: 'main',
          actions: assign({
            policies: (context, event) => event.data[0],
            entities: (context, event) => event.data[1],
            permissions: (context, event) => {
              const permissions = event.data[2]
              const grouppedByPermissionNames = permissions.reduce((acc, permission) => {
                const permissionName = permission.name
                if (acc[permissionName]) {
                  if (permission.action) {
                    acc[permissionName][PERMISSION_ACTIONS].push(permission.action)
                  }
                } else {
                  acc[permissionName] = {
                    ...permission,
                    [PERMISSION_ACTIONS]: permission.action ? [permission.action] : []
                  }
                }
                return acc
              }, {})
              return Object.values(grouppedByPermissionNames)
            }
          })
        },
        onError: {
          target: 'failedFetchingData',
          actions: assign({
            errorType: (context, event) => event.data.error
          })
        }
      }
    },
    failedFetchingData: {
      type: 'final'
    },
    main: {
      exit: ['hideSuccessNotification'],
      after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
      on: {
        OPEN_ADD_POLICY_MODAL: {
          target: 'addPolicyModal',
          actions: ['setPolicy']
        },
        OPEN_POLICY_DELETE_MODAL: {
          target: 'deletePolicyModal',
          actions: ['setPolicy']
        }
      }
    },
    addPolicyModal: {
      on: {
        CLOSE_ADD_POLICY_MODAL: 'main',
        LOAD_PAGE: 'loadingPolicies',
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    },
    deletePolicyModal: {
      on: {
        CLOSE_POLICY_DELETE_MODAL: {
          target: 'main',
          actions: ['setPolicy']
        },
        POLICY_DELETED: 'loadingPolicies',
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    }
  }
},
{
  actions: {
    showSuccessNotification: assign({
      isSuccessNotificationVisible: true
    }),
    hideSuccessNotification: assign({
      isSuccessNotificationVisible: false
    }),
    setPolicy: assign({
      policy: (context, event) => event.policy
    })
  },
  guards: {
    isSuccessNotificationVisible: (context, event) => {
      return !!context.isSuccessNotificationVisible
    }
  }
}
)

const UnitsContainer = ({ t }) => {
  const [current, send] = useMachine(policyMachine)

  switch (current.value) {
    case 'loadingPolicies':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t('Session Is Over Error')}</div>
    default: {
      const {
        policies,
        policy,
        permissions,
        isSuccessNotificationVisible,
        entities
      } = current.context

      const isAddPolicyModalOpen = current.value === 'addPolicyModal'
      const isDeletePolicyModalOpen = current.value === 'deletePolicyModal'

      return (
        <FullPagePaper>
          <AddPolicyModal
            entities={entities}
            permissions={permissions}
            policy={policy}
            onClose={() => {
              send('CLOSE_ADD_POLICY_MODAL')
            }}
            isOpen={isAddPolicyModalOpen}
            policyAdded={() => {
              send('LOAD_PAGE')
            }}
          />
          <DeleteModal
            isOpen={isDeletePolicyModalOpen}
            onClose={(isDeleteClicked = false) => {
              send({ type: 'CLOSE_POLICY_DELETE_MODAL', policy: {} })
            }}
            onApply={async () => {
              await deletePolicyByPermissionName({
                permissionName: policy.permission.name,
                roleId: policy.role.id
              })
              send(['SHOW_SUCCESS_NOTIFICATION', 'POLICY_DELETED'])
            }}
          />
          <PoliciesTableHeader
            openAddPolicyModal={
              (policy = {}) => {
                send({ type: 'OPEN_ADD_POLICY_MODAL', policy })
              }
            }
          />
          <PoliciesTable
            policies={policies}
            isSuccessNotificationVisible={isSuccessNotificationVisible}
            entities={entities}
            permissions={permissions}
            isAddPolicyModalOpen={isAddPolicyModalOpen}
            openAddPolicyModal={(policy = {}) => {
              send({ type: 'OPEN_ADD_POLICY_MODAL', policy })
            }}
            openDeletePolicyModal={(policy) => {
              send({ type: 'OPEN_POLICY_DELETE_MODAL', policy })
            }}

            closeAddPolicyModal={() => {
              send('CLOSE_ADD_POLICY_MODAL')
            }}
            onApplyPolicyModal={() => {
              send('LOAD_PAGE')
            }}
          />
        </FullPagePaper>
      )
    }
  }
}

export default withTranslation()(UnitsContainer)
