import axios from 'axios'
import filterEmptyValues from '../../shared/dashboard/filterEmptyValues.js'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * @param {object} distributorObject
 * @param {string} distributorObject.name
 * @param {string} distributorObject.country
 * @param {string} distributorObject.state
 * @param {string} distributorObject.city
 * @param {string} distributorObject.district
 * @param {string} distributorObject.address
 * @param {string} distributorObject.zipcode
 * @param {boolean} distributorObject.proceed
 * @param {string[]]} distributorObject.organizationIds
 */
export const addDistributor = async (distributorObject) => {
  const endpoint = `${apiEndpoints.distributors}`
  const URL = SERVER_PATH + endpoint
  return resolveRequest(axios.post(URL, filterEmptyValues(distributorObject), { withCredentials: true }))
}
