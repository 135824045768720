import { createSlice } from "@reduxjs/toolkit";

const sitesSlice = createSlice({
    name: "sites",
    initialState: {
        sites: [],
        // siteMachinesIds: [],
        // currentSite: "",
        // currentSiteId: "",
        currentSites: [],
        currentSitesNames: [],
        currentSitesIds: [],
        sitesMachinesIds: [],
        siteMonitorOrientation: "",
        siteCampaignActive: false,
        isFetching: false,
        error: false,
    },
    reducers: {
        userSitesStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        userSitesSuccess: (state, action) => {
            state.isFetching = false;

            state.sites = action.payload;


        },
        userSitesFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        // currentSiteStart: (state, action) => {
        //     state.isFetching = true;
        //     state.error = false;
        //     //no action required...u can remove action
        // },
        // currentSiteSuccess: (state, action) => {
        //     state.isFetching = false;
        //     state.currentSite = action.payload;
        // },
        // currentSiteFailure: (state, action) => {
        //     state.isFetching = false;
        //     state.error = true;
        //     //no action required...u can remove action
        // },
        // currentSiteIdStart: (state, action) => {
        //     state.isFetching = true;
        //     state.error = false;
        //     //no action required...u can remove action
        // },
        // currentSiteIdSuccess: (state, action) => {
        //     state.isFetching = false;

        //     state.currentSiteId = action.payload;


        // },
        // currentSiteIdFailure: (state, action) => {
        //     state.isFetching = false;
        //     state.error = true;
        //     //no action required...u can remove action
        // },

        currentSitesStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentSitesSuccess: (state, action) => {
            state.isFetching = false;
            state.currentSites = action.payload;
        },
        currentSitesFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentSitesNamesStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentSitesNamesSuccess: (state, action) => {
            state.isFetching = false;
            state.currentSitesNames = action.payload;
        },
        currentSitesNamesFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentSitesIdsStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentSitesIdsSuccess: (state, action) => {
            state.isFetching = false;

            state.currentSitesIds = action.payload;


        },
        currentSitesIdsFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        siteCampaignActiveStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        siteCampaignActiveSuccess: (state, action) => {
            state.isFetching = false;
            if (action.payload === null) {
                state.siteCampaignActive = false;
            } else {

                state.siteCampaignActive = action.payload;
            }
        },
        siteCampaignActiveFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },



        // siteMachinesIdsStart: (state, action) => {
        //     state.isFetching = true;
        //     state.error = false;
        //     //no action required...u can remove action
        // },
        // siteMachinesIdsSuccess: (state, action) => {
        //     state.isFetching = false;
        //     state.siteMachinesIds = action.payload;


        // },
        // siteMachinesIdsFailure: (state, action) => {
        //     state.isFetching = false;
        //     state.error = true;
        //     //no action required...u can remove action
        // },
        sitesMachinesIdsStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        sitesMachinesIdsSuccess: (state, action) => {
            state.isFetching = false;
            state.sitesMachinesIds = action.payload;


        },
        sitesMachinesIdsFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },

        siteMonitorOrientationSuccess: (state, action) => {
            state.isFetching = false;
            if (action.payload === null) {
                state.siteMonitorOrientation = "horizontal";

            } else {

                state.siteMonitorOrientation = action.payload;
            }
        },

        // siteLogoutSuccess: (state, action) => {
        //     state.isFetching = false;

        //     state.siteMachinesIds = [];
        //     state.sites = []
        //     state.currentSite = ""
        //     state.currentSiteId = ""

        // },
    },
});

export const {
    getSitesStart,
    getSitesSuccess,
    getSitesFailure,
    userSitesStart,
    userSitesSuccess,
    userSitesFailure,
    currentSiteStart,
    currentSiteSuccess,
    currentSiteFailure,
    currentSiteIdStart,
    currentSiteIdSuccess,
    currentSiteIdFailure,
    siteMachinesIdsStart,
    siteMachinesIdsSuccess,
    siteMachinesIdsFailure,
    siteLogoutSuccess,
    siteMonitorOrientationSuccess,
    siteCampaignActiveStart,
    siteCampaignActiveSuccess,
    siteCampaignActiveFailure,
    currentSitesStart,
    currentSitesSuccess,
    currentSitesFailure,
    currentSitesNamesStart,
    currentSitesNamesSuccess,
    currentSitesNamesFailure,
    currentSitesIdsStart,
    currentSitesIdsSuccess,
    currentSitesIdsFailure,
    sitesMachinesIdsStart,
    sitesMachinesIdsSuccess,
    sitesMachinesIdsFailure
} = sitesSlice.actions;


const sitesReducer = sitesSlice.reducer; //it should be export default
export default sitesReducer;