import {
  Menu,
  MenuItem
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import React from 'react'
import Button from '../components/CustomButtons/Button.jsx'

export default function SelectButton({
  btnProps = {},
  btnTxt = '',
  onSelect = () => null,
  selected = null,
  menuItems = []
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedValue, setSelectedValue] = React.useState(selected)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e) => {
    setAnchorEl(null)
  }
  const handleSelect = (value) => {
    onSelect(value)
    setAnchorEl(null)
    setSelectedValue(value)
  }

  return (
    <div>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        {...btnProps}
      >
        {btnTxt}
        <span style={{ borderBottom: '1px solid white', marginLeft: '0.3rem' }}>{selectedValue}</span>
        <ArrowDropDownIcon style={{ marginLeft: '0.4rem' }} />
      </Button>
      <Menu
        variant='menu'
        id='simple-menu'
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          menuItems.map(item => {
            return <MenuItem
              key={item}
              onClick={() => handleSelect(item)}
            >
              {item}
            </MenuItem>
          })
        }
      </Menu>
    </div>
  )
}
