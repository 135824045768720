import axios from 'axios'
import resolveRequest from '../../utils/resolveRequest.js'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const addWasher = async (formData, washerId) => {
  const URL = SERVER_PATH + apiEndpoints.washers + `/${washerId}`

  return resolveRequest(
    axios.put(URL, formData, {
      withCredentials: true
    })
  )
}

export default addWasher
