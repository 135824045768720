import enumerateMonthsBetweenDates from '../../shared/date/enumerateMonthsBetweenDates'
import enumerateWeeksBetweenDates from '../../shared/date/enumerateWeeksBetweenDates'
import enumerateDaysBetweenDates from '../../shared/date/enumerateDaysBetweenDates'
import moment from 'moment'
import getLanguageLocale from '../../shared/multi-language/getLanguageLocale'
// split types
const DAY = 'day'
const WEEK = 'week'
const MONTH = 'month'

const WASH_FAILED_COUNT = 'washFailedCount'
const MAX_TEMPERATURE = 'maxTemperature'
const WASH_COUNT = 'washCount'

export default (dailyMachineUsages, startDate, endDate, splitBy = DAY) => {
  let momentFormatString
  let dates = []
  switch (splitBy) {
    case MONTH:
      momentFormatString = 'YYYY-MM'
      dates = enumerateMonthsBetweenDates(startDate, endDate).map(date => date.format(momentFormatString))
      break
    case WEEK:
      momentFormatString = 'YYYY WW'
      dates = enumerateWeeksBetweenDates(startDate, endDate).map(date => date.endOf('week').format(momentFormatString))
      break
    case DAY:
      momentFormatString = 'YYYY-MM-DD'
      dates = enumerateDaysBetweenDates(startDate, endDate).map(date => date.format(momentFormatString))
      break
    default:
      momentFormatString = 'YYYY-MM-DD'
      dates = enumerateDaysBetweenDates(startDate, endDate)
  }
  const dailyMachineUsagesByDate = dailyMachineUsages.reduce((acc, dailyMachineUsage) => {
    const washDate = dailyMachineUsage.washDate
    const formattedWashDate = moment(washDate).locale(getLanguageLocale()).format(momentFormatString)
    if (!acc[formattedWashDate]) {
      acc[formattedWashDate] = {}
    }
    if (acc[formattedWashDate][dailyMachineUsage.machine.id]) {
      const maxTemperature = dailyMachineUsage[MAX_TEMPERATURE]
      const machineDailyWash = acc[formattedWashDate][dailyMachineUsage.machine.id]
      acc[formattedWashDate][dailyMachineUsage.machine.id] = {
        ...machineDailyWash,
        [MAX_TEMPERATURE]: machineDailyWash[MAX_TEMPERATURE] > maxTemperature ? machineDailyWash[MAX_TEMPERATURE] : maxTemperature,
        [WASH_FAILED_COUNT]: machineDailyWash[WASH_FAILED_COUNT] + dailyMachineUsage[WASH_FAILED_COUNT],
        [WASH_COUNT]: machineDailyWash[WASH_COUNT] + dailyMachineUsage[WASH_COUNT]
      }
    } else {
      acc[formattedWashDate][dailyMachineUsage.machine.id] = {
        ...dailyMachineUsage,
        [MAX_TEMPERATURE]: dailyMachineUsage[MAX_TEMPERATURE]
      }
    }
    return acc
  }, {})
  const dailyMachineChartData = {
    dates: [],
    data: []
  }
  for (const date of dates) {
    dailyMachineChartData.dates.push(moment(date, momentFormatString))
    dailyMachineChartData.data.push(dailyMachineUsagesByDate[date] || null)
  }

  return dailyMachineChartData
}