import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import LanguageSelector from '../MultiLanguage/LanguageSelector'
import { isMobile } from 'react-device-detect'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import { withTranslation } from 'react-i18next'
// material-ui icons
import Menu from '@material-ui/icons/Menu'
// core components
import Button from '../../components/CustomButtons/Button.jsx'

import headerStyle from '../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx'

const templateHeaderStyle = headerStyle(undefined)
const soapyHeaderStyle = {
  ...templateHeaderStyle,
  appBar: {
    ...templateHeaderStyle.appBar,
    position: "static",
  },
};

function Header(props) {
  function getContentPaneHeader() {
    const { history, pathToHeaderTitle } = props;
    const path = history.location.pathname;
    switch (path) {
      case "/":
        return "";
      default:
        return pathToHeaderTitle[path];
    }
  }

  const { classes, color, t, i18n, languageCodes } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  return (
    <div>
      <AppBar
        id="headerAppBar"
        style={
          isMobile ? { position: "absolute", backgroundColor: "#eeeeee" } : {}
        }
        className={classes.appBar + appBarClasses}
      >
        <Toolbar id="headerToolbar" className={classes.container}>
          {isMobile ? (
            <div id="headerNavbarBrand" className={classes.flex}>
              <LanguageSelector i18n={i18n} languageCodes={languageCodes} />
            </div>
          ) : (
            <div className={classes.flex} style={{ paddingLeft: 15 }}>
              {/* Here we create navbar brand, based on route name */}

              <span id="headerNavbarBrandContent" style={{ fontSize: 30 }}>
                {t(getContentPaneHeader())}
              </span>
            </div>
          )}
          {!isMobile && (
            <LanguageSelector i18n={i18n} languageCodes={languageCodes} />
          )}
          <Hidden mdUp implementation="css">
            <Button
              id="headerOpenDrawerButton"
              className={classes.appResponsive}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu id="headerMenu" />
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
      {isMobile && <div style={{ height: "5.5rem", width: "100%" }} />}
    </div>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
};

export default withStyles(soapyHeaderStyle)(withTranslation()(Header));
