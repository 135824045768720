import fetchChampionsResults from '../../../endpoint-requests/champions-results/createChampionsResults'

//get champions
export const getChampionsList = async (sitesIds, fromDate, toDate, washersIds, minimumResultScore) => {
    try {
        const championsData = await fetchChampionsResults(sitesIds, fromDate, toDate, washersIds, minimumResultScore)
        return championsData
    } catch (error) {
        console.log(error);

    }
}