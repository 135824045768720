import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'

import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

/**
 * @param {object} requestObject
 * @param {string} requestObject.password
 * @param {boolean} requestObject.atpMode
 * @param {boolean} requestObject.ledProgressBar
 * @param {boolean} requestObject.enableVisualError
 */
export default async (serialNumber, requestObject) => {
  const endpoint = apiEndpoints.machines
  const URL = SERVER_PATH + endpoint + `/${serialNumber}/status-configuration`

  return resolveRequest(axios.put(URL, requestObject, { withCredentials: true }))
}
