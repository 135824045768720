import {
    getGroupsStart,
    getGroupsSuccess,
    getGroupsFailure,
} from './groupsReducer'
import fetchGroups from '../../endpoint-requests/groups/fetchGroups'

//Get all Groups
export const getGroups = async (dispatch, setLoading) => {
    dispatch(getGroupsStart())
    try {
        const groupsData = await fetchGroups()
        dispatch(getGroupsSuccess(groupsData))
        setLoading(false)
    } catch (error) {
        dispatch(getGroupsFailure());
    }
}


