import { Tooltip, Typography } from '@material-ui/core'
import Remove from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import React, { useEffect, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getCookie from '../../shared/cookie/get-cookie'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'
import { Send } from '@material-ui/icons'
import sendReport from '../../endpoint-requests/reports/sendReport'

const NAME = 'name'
const TYPE = 'type'
const ORGANIZATION = 'organization'
const SITES = 'sites'
const EMAILS = 'emails'
const RANGE = 'range'
const SCHEDULE = 'scheduleWeekDays'
const ACTIVE = 'active'
const ACTIONS = 'actions'
const REPORT = 'report'

const DEFAULT_PAGE_SIZE = 10

const convertReportsToArray = (
  reports,
  showData,
  openDeleteModal,
  openMachinesModal,
  hasPermission,
  weekObject,
  reportSent,
  t
) =>
  reports.map(report => {
    if (!report) return {}
    return {
      [SITES]: report.sites.map(site => site.name).join(', '),
      [EMAILS]: report.emails.map(email => email.email).join(', '),
      [SCHEDULE]: report.scheduleWeekDays ? `${report.scheduleWeekDays.split(',').map(day => weekObject[day]).join(', ')}` : '',
      [ACTIVE]: report.active ? 'Active' : 'Inactive',
      [ACTIONS]: (
        <div style={{ display: 'flex' }}>
          {
            // hasPermission({ name: 'reports', action: 'edit' })
            1
              ? <div
                onClick={() => showData(report)}
                id="reportEditBtn"
              >
                <Tooltip title={t('Edit')}>
                  <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
          {
            // hasPermission({ name: 'reports', action: 'delete' })
            1
              ? <div
                onClick={() => openDeleteModal(report)}
                id='reportEditDelete'
              >
                <Tooltip title={t('Delete')}>
                  <Remove style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
          {
            <div
              onClick={async () => {
                sendReport(report.id)
                reportSent()
              }}
              id='send'
            >
              <Tooltip title={t('Send')}>
                <Send style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
              </Tooltip>
            </div>
          }
        </div>
      ),
      report,
    }
  })

const Reports = ({
  reports,
  openDeleteModal,
  openUpdateModal,
  openMachinesModal,
  hasPermission,
  weekObject,
  reportSent,
  t
}) => {
  const language = getCookie('language')
  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [language])

  const showData = (report) => {
    openUpdateModal(report)
  }

  const reportsAsRows = useMemo(() => convertReportsToArray(
    reports,
    showData,
    openDeleteModal,
    openMachinesModal,
    hasPermission,
    weekObject,
    reportSent,
    t
  ), [reports]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    reportsAsRows.length
      ? <ReactTable
        data={reportsAsRows}
        filterable
        defaultFilterMethod={defaultFilterMethod}
        columns={[
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Name')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${REPORT}.${NAME}`
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Type')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${REPORT}.${TYPE}.${NAME}`
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Organization')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${REPORT}.${ORGANIZATION}.${NAME}`
          },          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Sites')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${SITES}`
          },          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Range')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${REPORT}.${RANGE}`
          }, {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Emails')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${EMAILS}`
          }, {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Schedule')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${SCHEDULE}`
          }, {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Status')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${ACTIVE}`
          },{
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Actions')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${ACTIONS}`
          },
        ]}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        minRows={0}
        showPaginationBottom={
          reportsAsRows.length > DEFAULT_PAGE_SIZE
        }
        onPageChange={() => {
          document.getElementById('mainPanel').scrollTop = 0
        }}
        nextText={t('Next')}
        pageText={t('Page')}
        ofText={t('Of')}
        rowsText={t('Rows')}
        previousText={t('Previous')}
        className='-striped -highlight'
      />
      : null
  )
}

export default withAuthentication(withTranslation()(Reports))
