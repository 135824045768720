import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { DeviceHub, FileCopy, People, Settings } from '@material-ui/icons'
import Remove from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import ExploreIcon from '@material-ui/icons/Explore'
import NotificationsIcon from '@material-ui/icons/Notifications'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import { CITY, COUNTRY, DISTRIBUTOR, NAME, ORGANIZATION, STATE } from '../../shared/data/sites'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import StayPrimaryLandscape from '@material-ui/icons/StayPrimaryLandscape'
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait'
import { siteMonitorOrientationSuccess } from '../../redux/sitesRedux'
import { useDispatch } from 'react-redux'
import { getSiteCampaignActive } from '../../redux/apiCalls'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import MapApp from './MapApp'

import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite'
import fetchSites from '../../endpoint-requests/site/fetchSites'
import ActionIconsDropDown from './modals/ActionIconsDropDown'

const ID = 'id'
const SITE = 'site'
const MACHINE_COUNT = 'machineCount'
const DASHBOARD_USER_COUNT = 'dashboardUserCount'
const ACTIONS = 'actions'
const MACHINES = 'machines'
const DASHBOARD_USERS = 'dashboardUsers'
const MONITOR_ORIENTATION = 'monitorOrientation'
const STATUS = 'status'

const DEFAULT_PAGE_SIZE = 10

const convertSitesToArray = (
  dispatch,
  sites,
  openSiteModal,
  openCloneModal,
  openContactsModal,
  openDeleteModal,
  openMachinesModal,
  t,
  openDashboardUsersModal,
  openMapModal,
  hasPermission,
  openConfigurationModal,
  openSiteMapModal,
  handleClick,
  openActionIcons,
  setOpenActionIcons,
  selectedSiteId,
) =>
  sites.map(site => {

    let actions = []

    if (site.latitude && site.longitude) {
      actions.push({
        mapView:
          <Tooltip title={t('Map view')} placement="right">
            <ExploreIcon
              id="siteTableMapButton"
              onClick={() => openMapModal(site)}
              style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}
            />
          </Tooltip>
      },)
    }

    if (hasPermission({ name: 'site map', action: 'read' })) {
      actions.push({
        siteMap:
          <div
            id="siteMapBtn"
            onClick={() => {
              openSiteMapModal(site)
            }}
          >
            <Tooltip title={t('Site Map')} placement="right">
              <AccountTreeIcon style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
            </Tooltip>
          </div>
      })
    }

    if (hasPermission({ name: 'site contacts', action: 'read' })) {
      actions.push({
        contacts:
          <div
            id="siteContactBtn"
            onClick={() => {
              openContactsModal(site)
            }}
          >
            <Tooltip title={t('Contacts')} placement="right">
              <NotificationsIcon style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
            </Tooltip>
          </div>
      })
    }

    if (hasPermission({ name: 'site configuration', action: 'create' })) {
      actions.push(
        {
          configuration:
            <div
              onClick={() => {
                dispatch(siteMonitorOrientationSuccess(site.monitorOrientation))
                openConfigurationModal(site)
              }}
              id="organizationConfigurationBtn"
            >
              <Tooltip title={t('Configuration')} placement="right">
                <Settings style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
              </Tooltip>
            </div>
        })
    }
    if (hasPermission({ name: 'sites', action: 'edit' })) {
      actions.push({
        edit:
          <div
            id="siteEditBtn"
            onClick={() => {
              dispatch(siteMonitorOrientationSuccess(site.monitorOrientation))
              getSiteCampaignActive(dispatch, site.id)
              openSiteModal(site)
            }
            }
          >
            <Tooltip title={t('Edit')} placement="right">
              <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
            </Tooltip>
          </div>
      })
    }
    if (hasPermission({ name: 'sites', action: 'create' })) {
      actions.push({
        clone:
          <div
            id="siteEditBtn"
            onClick={() => openCloneModal(site)}
          >
            <Tooltip title={t('Clone')} placement="right">
              <FileCopy style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
            </Tooltip>
          </div>
      })

    }
    if (hasPermission({ name: 'sites', action: 'delete' })) {
      actions.push({
        delete:
          <div
            id="siteDeleteBtn"
            onClick={() => openDeleteModal(site)}
          >
            <Tooltip title={t('Delete')} placement="right">
              <Remove style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }}/>
            </Tooltip>
          </div>
      })
    }

    if (site.status === null) {
      site.status = 'active'
    }
    return {
      [ID]: site.id,
      [SITE]: site,
      [STATUS]: site.status,
      [MACHINE_COUNT]: site.machines.length,
      [DASHBOARD_USER_COUNT]: site.dashboard_users.length,
      [ORGANIZATION]: site.organization && site.organization.name,
      [DISTRIBUTOR]: site.distributor && site.distributor.name,
      [ACTIONS]: (
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={() => handleClick(site)}
          >
            <Tooltip title={t('More Actions')}>
              <MoreHorizIcon/>
            </Tooltip>
          </IconButton>
          {
            openActionIcons && site.id === selectedSiteId &&
            <ActionIconsDropDown actions={actions} setOpenActionIcons={setOpenActionIcons}/>
          }
        </div>
      ),
      site,
      [MACHINES]: (
        <div className="actions-left">
          <Button
            justIcon
            round
            id="siteUnitsBtn"
            simple
            onClick={() => {
              dispatch(siteMonitorOrientationSuccess(site.monitorOrientation))
              openMachinesModal(site)
            }}
          >
            <Tooltip title={t('Site units')}>
              <DeviceHub style={{ color: '#3c4858' }}/>
            </Tooltip>
          </Button>
          {site.machines.length}
        </div>
      ),
      [DASHBOARD_USERS]: (
        <div className="actions-left">
          <Button
            justIcon
            id="siteDashboardUsersBtn"
            round
            simple
            onClick={() => {
              openDashboardUsersModal(site)
            }}
          >
            <Tooltip title={t('Dashboard Users')}>
              <People style={{ color: '#3c4858' }}/>
            </Tooltip>
          </Button>
          {site.dashboard_users.length}
        </div>
      ),
      [MONITOR_ORIENTATION]: {
        icon: site.monitorOrientation === 'vertical' ? <StayCurrentPortrait/> : <StayPrimaryLandscape/>,
        value: site.monitorOrientation === 'vertical'
      },
    }
  })

const SitesTable = ({
  openActionIcons,
  setOpenActionIcons,
  // sites,
  setOpenMap,
  openMap,
  siteAdded,
  siteEdited,
  allSites,
  hasPermission,
  openSiteModal,
  openContactsModal,
  currentDashboardUser,
  openDeleteModal,
  openCloneModal,
  openMachinesModal,
  openDashboardUsersModal,
  openMapModal,
  openConfigurationModal,
  openSiteMapModal,
  t
}) => {
  const dispatch = useDispatch()
  const [sites, setSites] = useState(allSites)
  const [selectedSiteId, setSelectedSiteId] = useState('')

  const handleClick = (site) => {
    setSelectedSiteId(site.id)
    if (openActionIcons === false) {
      setOpenActionIcons(true)
    }
    if (openActionIcons === true) {
      setOpenActionIcons(false)
    }
  }

  const handleStatusFilterChange = (status) => {
    switch (status) {
      case 'active':
        const activeSites = allSites.filter((site) => site.status === status)
        setSites(activeSites)
        break
      case 'archived':
        const archivedSites = allSites.filter((site) => site.status === status)
        setSites(archivedSites)

        break
      default:
        setSites(allSites)
    }
  }
  const tableData = useMemo(() => convertSitesToArray(dispatch,
    sites,
    openSiteModal,
    openCloneModal,
    openContactsModal,
    openDeleteModal,
    openMachinesModal,
    t,
    openDashboardUsersModal,
    openMapModal,
    hasPermission,
    openConfigurationModal,
    openSiteMapModal,
    handleClick,
    openActionIcons,
    setOpenActionIcons,
    selectedSiteId,
  ), [sites, openActionIcons]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getAllSites = async () => {
      try {
        const result = await fetchSites()
        setSites(result)
      } catch (error) {
        console.log(error)
      }
    }
    getAllSites()
  }, [siteAdded, siteEdited])

  return (
    <>
      <MapApp sites={sites} setOpenMap={setOpenMap} openMap={openMap}/>

      {tableData.length
        ? <ReactTable
          data={tableData}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          columns={[
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <Typography variant="body2">{t('Name')}</Typography>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${SITE}.${NAME}`
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <Typography variant="body2">{t('Country')}</Typography>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${SITE}.${COUNTRY}`
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <Typography variant="body2">{t('State')}</Typography>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${SITE}.${STATE}`
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <Typography variant="body2">{t('Organization')}</Typography>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${ORGANIZATION}`
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <Typography variant="body2">{t('Distributor')}</Typography>
                </div>
              ),
              style: { textAlign: 'left' },
              show: currentDashboardUser.entity.name === 'neo',
              accessor: `${DISTRIBUTOR}`
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <Typography variant="body2">{t('City')}</Typography>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${SITE}.${CITY}`
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  <Typography variant="body1">{t('Monitor Position')}</Typography>
                </div>
              ),
              style: { textAlign: 'left' },
              Cell: ({ original }) => original[MONITOR_ORIENTATION].icon,
              accessor: MONITOR_ORIENTATION,
              sortable: true,
              sortMethod: (a, b) => {
                a = a.value
                if (a) return 1
                return -1
              }
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left'

                  }}
                >
                  <Typography variant="body2">{t('Status')}</Typography>
                </div>
              ),
              Cell: ({ original }) => {
                if (original[STATUS] === 'archived') {
                  return (
                    <Tooltip title={t('Archived')}>
                      <SettingsInputCompositeIcon style={{ color: 'gray' }}
                      />
                    </Tooltip>
                  )
                } else {
                  return (
                    <Tooltip title={t('Active')}>
                      <SettingsInputCompositeIcon style={{ color: '#00acc1' }}
                      />
                    </Tooltip>
                  )
                }
              },
              Filter: ({ filter, onChange }) => {
                return (
                  <select
                    onChange={event => handleStatusFilterChange(event.target.value)}
                    style={{ width: '100%', fontSize: '1rem' }}
                  >
                    <option value='All'>{t('All')}</option>
                    <option value='Archived'>{t('Archived')}</option>
                    <option value='Active'>{t('Active')}</option>
                  </select>
                )
              },
              style: { textAlign: 'left' },
              filterable: true,
              accessor: `${STATUS}`
            },
            {
              Header: () => (
                <div
                  style={{
                    marginBottom: '42px'
                  }}
                >
                  <Typography variant="body2">{t('Units')}</Typography>
                </div>
              ),
              Cell: ({ original }) => original[MACHINES],
              accessor: `${MACHINE_COUNT}`,
              show: hasPermission({ name: 'site machines', action: 'read' }),
              sortable: true,
              filterable: false
            },
            {
              Header: () => (
                <div
                  style={{
                    marginBottom: '42px'
                  }}
                >
                  <Typography variant="body2">{t('Dashboard Users')}</Typography>
                </div>
              ),
              Cell: ({ original }) => original[DASHBOARD_USERS],
              accessor: `${DASHBOARD_USER_COUNT}`,
              show: hasPermission({ name: 'site dashboard users', action: 'read' }),
              sortable: true,
              filterable: false
            },
            {
              Header: () => (
                <div
                  style={{
                    textAlign: 'left',
                    marginBottom: '42px',
                    marginLeft: 0
                  }}
                >
                  <Typography variant="body2">{t('Actions')}</Typography>
                </div>
              ),
              style: { textAlign: 'left' },
              accessor: `${ACTIONS}`,
              sortable: false,
              filterable: false
            }
          ]}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          minRows={0}
          showPaginationBottom={
            tableData.length > DEFAULT_PAGE_SIZE
          }
          onPageChange={() => {
            document.getElementById('mainPanel').scrollTop = 0
          }}
          nextText={t('Next')}
          pageText={t('Page')}
          ofText={t('Of')}
          rowsText={t('Rows')}
          previousText={t('Previous')}
          className="-striped -highlight"
        />
        : <h1>{t('No sites found')}</h1>}
    </>

  )
}

export default withAuthentication(withTranslation()(SitesTable))
