import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import React, { useEffect } from 'react'
import Button from '../../components/CustomButtons/Button'
import { withTranslation } from 'react-i18next'
import { useState } from 'react'
import { fetchReportVersion } from '../../redux/apiCalls.js'

const ReportsTable = ({
  openReportModal,
  hasPermission,
  t
}) => {
  const [reportVersion, setReportVersion] = useState("")

  useEffect(() => {
    fetchReportVersion(setReportVersion)
  }, [])

  return (
    // hasPermission({ name: 'distributors', action: 'create' }) &&
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Button
        color="info"
        round
        id="addDistributorBtn"
        onClick={() => openReportModal()}
        style={{ marginRight: 5 }}>
        {t('Add Report')}
      </Button>
      <p>Version : {reportVersion}</p>
    </div>
  )
}

export default withTranslation()(withAuthentication(ReportsTable))
