export default {
  consentContainer: {
    width: '20%',
    background: 'white',
    padding: '20px 20px 20px 20px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    borderRadius: '3px',
    transform: 'translate(-50%, -50%)'
  },
  logo: {
    marginTop: '3%'
  }

}
