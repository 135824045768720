import { createSlice } from "@reduxjs/toolkit";

const previousShiftResultsSlice = createSlice({
    name: "previousShiftResults",
    initialState: {
        previousShiftResults: [],
        totalWashCount: 0,
        avgWashScore: 0,
        failedCount: 0,
        previousWashLeft: 0,
        isFetching: false,
        error: false,
    },
    reducers: {
        previousShiftResultsStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousShiftResultsSuccess: (state, action) => {
            state.isFetching = false;

            state.previousShiftResults = action.payload;


        },
        previousShiftResultsFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        previousWashCountStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousWashCountSuccess: (state, action) => {
            state.isFetching = false;

            state.totalWashCount = action.payload;


        },
        previousWashCountFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        previousAvgWashScoreStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousAvgWashScoreSuccess: (state, action) => {
            state.isFetching = false;

            state.avgWashScore = action.payload;


        },
        previousAvgWashScoreFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },

        previousFailedCountStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        previousFailedCountSuccess: (state, action) => {
            state.isFetching = false;

            state.failedCount = action.payload;


        },
        previousFailedCountFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        previousWashLeftSuccess: (state, action) => {
            state.isFetching = false;


            if ((action.payload.minWashCount - action.payload.previousTotalWashCount) < 0) {
                state.previousWashLeft = 0
            } else {
                state.previousWashLeft = Math.abs(action.payload.minWashCount - action.payload.previousTotalWashCount);

            }


        },
        previousWashLeftSuccessAuto: (state, action) => {
            state.isFetching = false;

            if ((action.payload.minWashCnt - action.payload.washCount) < 0) {
                state.previousWashLeft = 0
            } else {
                state.previousWashLeft = Math.abs(action.payload.minWashCnt - action.payload.washCount);

            }


        },
        previousShiftLogoutSuccess: (state, action) => {
            state.isFetching = false;

            state.failedCount = 0;
            state.previousShiftResults = []
            state.totalWashCount = 0
            state.avgWashScore = 0
            state.previousWashLeft = 0

        },


    },
});

export const {
    previousShiftResultsStart,
    previousShiftResultsSuccess,
    previousShiftResultsFailure,
    previousWashCountStart,
    previousWashCountSuccess,
    previousWashCountFailure,
    previousAvgWashScoreStart,
    previousAvgWashScoreSuccess,
    previousAvgWashScoreFailure,

    previousFailedCountStart,
    previousFailedCountSuccess,
    previousFailedCountFailure,
    previousWashLeftSuccess,
    previousShiftLogoutSuccess,
    previousWashLeftSuccessAuto
} = previousShiftResultsSlice.actions;


const previousShiftResultsReducer = previousShiftResultsSlice.reducer; //it should be export default
export default previousShiftResultsReducer;