import { createSlice } from "@reduxjs/toolkit";

const popUpSlice = createSlice({
    name: "popUp",
    initialState: {
        open: false,
        isFetching: false,
        error: false,
    },
    reducers: {

        popUpSuccess: (state, action) => {
            state.isFetching = false;
            state.open = action.payload
            state.error = false;


        },


    },
});

export const {
    popUpSuccess
} = popUpSlice.actions;


const popUpReducer = popUpSlice.reducer; //it should be export default
export default popUpReducer;