import { Tooltip, Typography } from '@material-ui/core'
import { DeviceHub } from '@material-ui/icons'
import Remove from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import React, { useEffect, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import Button from '../../components/CustomButtons/Button'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import getCookie from '../../shared/cookie/get-cookie'
import { ADDRESS, CITY, COUNTRY, DISTRICT, NAME, ORGANIZATIONS, STATE, ZIPCODE } from '../../shared/data/distributors'
import addReactTableFilterPlaceholder from '../../shared/react-table/addReactTableFilterPlaceholder.js'
import defaultFilterMethod from '../../shared/react-table/defaultFilterMethod.js'

const ID = 'id'
const DISTRIBUTOR = 'distributor'
const ACTIONS = 'actions'
const MACHINES = 'machines'

const DEFAULT_PAGE_SIZE = 10

const convertDistributorsToArray = (
  distributors,
  showData,
  openDeleteModal,
  openMachinesModal,
  hasPermission,
  t
) =>
  distributors.map(distributor => {
    return {
      [ID]: distributor.id,
      [DISTRIBUTOR]: distributor,
      [ORGANIZATIONS]: distributor.organizations && distributor.organizations.map(organization => `${organization.name}`).join(', '),
      [ACTIONS]: (
        <div style={{ display: 'flex' }}>
          {
            hasPermission({ name: 'distributors', action: 'edit' })
              ? <div
                onClick={() => showData(distributor)}
                id='distributorEditBtn'
              >
                <Tooltip title={t('Edit')}>
                  <Edit style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }
          {
            hasPermission({ name: 'distributors', action: 'delete' })
              ? <div
                onClick={() => openDeleteModal(distributor)}
                id='distributorEditDelete'
              >
                <Tooltip title={t('Delete')}>
                  <Remove style={{ color: '#3c4858', height: '18px', cursor: 'pointer' }} />
                </Tooltip>
              </div>
              : null
          }

        </div>
      ),
      distributor,
      [MACHINES]: (
        hasPermission({ name: 'distributor machines', action: 'read' })
          ? <div className='actions-left'>
            <Button
              justIcon
              round
              id='distributorUnitsBtn'
              simple
              onClick={() => {
                openMachinesModal(distributor)
              }}
            >
              <Tooltip title={t('Distributor units')}>
                <DeviceHub style={{ color: '#3c4858' }} />
              </Tooltip>
            </Button>
          </div >
          : null

      )

    }
  })

const Distributors = ({
  distributors,
  openDeleteModal,
  openUpdateModal,
  openMachinesModal,
  currentDashboardUser,
  hasPermission,
  t
}) => {
  const language = getCookie('language')
  useEffect(() => {
    addReactTableFilterPlaceholder()
  }, [language])

  const showData = (distributor) => {
    openUpdateModal(distributor)
  }

  const distributorsAsRows = useMemo(() => convertDistributorsToArray(
    distributors,
    showData,
    openDeleteModal,
    openMachinesModal,
    hasPermission,
    t
  ), [distributors]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    distributorsAsRows.length
      ? <ReactTable
        data={distributorsAsRows}
        filterable
        defaultFilterMethod={defaultFilterMethod}
        columns={[
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Name')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${DISTRIBUTOR}.${NAME}`

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Country')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${DISTRIBUTOR}.${COUNTRY}`

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('State')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${DISTRIBUTOR}.${STATE}`

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('Organizations')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${ORGANIZATIONS}`

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('City')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${DISTRIBUTOR}.${CITY}`

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'

                }}
              >
                <Typography variant='body2'>{t('District')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            accessor: `${DISTRIBUTOR}.${DISTRICT}`

          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography variant='body2'>{t('Address')}</Typography>
              </div>
            ),
            accessor: `${DISTRIBUTOR}.${ADDRESS}`
          }, {
            Header: () => (
              <div style={{
                textAlign: 'left'
              }}
              >
                <Typography variant='body2'>{t('Zip code')}</Typography>
              </div>
            ),
            accessor: `${DISTRIBUTOR}.${ZIPCODE}`
          },
          {
            Header: () => (
              <div
                style={{
                  marginBottom: '42px',
                  marginLeft: 0
                }}
              >
                <Typography variant='body2'>{t('Units')}</Typography>
              </div>
            ),
            accessor: `${MACHINES}`,
            sortable: false,
            show: currentDashboardUser.role.name !== 'user',
            filterable: false
          },

          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '42px',
                  marginLeft: 0
                }}
              >
                <Typography variant='body2'>{t('Actions')}</Typography>
              </div>
            ),
            style: { textAlign: 'left' },
            show: currentDashboardUser.role.name !== 'user',
            accessor: `${ACTIONS}`,
            sortable: false,
            filterable: false
          }
        ]}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        minRows={0}
        showPaginationBottom={
          distributorsAsRows.length > DEFAULT_PAGE_SIZE
        }
        onPageChange={() => {
          document.getElementById('mainPanel').scrollTop = 0
        }}
        nextText={t('Next')}
        pageText={t('Page')}
        ofText={t('Of')}
        rowsText={t('Rows')}
        previousText={t('Previous')}
        className='-striped -highlight'
      />
      : null
  )
}

export default withAuthentication(withTranslation()(Distributors))
