import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import distributorModalStyle from '../../../assets-soapy/jss/soapy/views/distributorModalStyle.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import EditDistributorModal from './EditDistributor'

const DistributorModal = ({ getMid, distributorEdited, distributor, organizations, isOpen, distributorAdded, onClose, classes, t }) => {
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      dialogPaper={classes.dialogPaper}
      hideActions
      showGoBackButton
      onCancel={onClose}
      title={!distributor || !Object.keys(distributor).length ? t('Add Distributor') : t('Edit distributor')}
    >
      <div>
        <EditDistributorModal
          isClosed={!isOpen}
          distributor={distributor}
          distributorEdited={distributorEdited}
          distributorAdded={distributorAdded}
          organizations={organizations}
          onClose={onClose}
          isDistributorPage
        />
      </div>
    </Modal>
  )
}

export default withStyles(distributorModalStyle)(withTranslation()(DistributorModal))
