import {
  Checkbox, FormControl,
  Input, InputLabel,
  ListItemText, MenuItem, Select
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Check from '@material-ui/icons/Check'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import addPolicyModalStyle from '../../assets-soapy/jss/soapy/views/addPolicyModalStyle.jsx'
import Card from '../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../components-soapy/LoadingButton.jsx'
import Modal from '../../components-soapy/Modal.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import addPolicy from '../../endpoint-requests/policy/addPolicy.js'
import editPolicy from '../../endpoint-requests/policy/editPolicy.js'
import useFormFields from '../../shared/hooks/useFormFields.js'
import validateFormFields from '../../utils/validateFormFields.js'

const INFO_COLOR = '#00acc1'
const ID = 'id'
const PERMISSION_NAME = 'permissionId'
const NAME = 'name'
const PERMISSION_ACTIONS = 'permissionActions'
const ENTITY_ID = 'entityId'
const ROLE_ID = 'roleId'

const VALIDATION_STATE = 'ValidationState'

let spinnerTimeout = null

const AddWasherModal = ({
  classes,
  isSitePage = false,
  policyAdded,
  currentDashboardUser,
  permissions,
  entities,
  policy,
  showErrorNotification,
  onClose,
  isOpen,
  t
}) => {
  const isEdit = !!Object.keys(policy).length

  const { formFields, setFormFields } = useFormFields({
    [PERMISSION_NAME]: { value: '', isRequired: true, name: 'Permission Name' },
    [PERMISSION_ACTIONS]: { value: [], isRequired: true, name: 'Actions' },
    [ENTITY_ID]: { value: '', isRequired: true, name: 'Entity Name' },
    [ROLE_ID]: { value: '', isRequired: true, name: 'Role Name' }
  })
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)

  const handleTextChange = (event, fieldName) => {
    const editedFormFields = { ...formFields, [fieldName]: { ...formFields[fieldName], value: event.target.value }, [fieldName + VALIDATION_STATE]: '' }
    setFormFields(editedFormFields)
    if (fieldName === ENTITY_ID) {
      setFormFields({ ...editedFormFields, [ROLE_ID]: { ...editedFormFields[ROLE_ID], value: null }, [ROLE_ID + VALIDATION_STATE]: '' })
    }
    if (fieldName === PERMISSION_NAME) {
      setFormFields({ ...editedFormFields, [PERMISSION_ACTIONS]: { ...editedFormFields[PERMISSION_ACTIONS], value: [], isRequired: Boolean(permissions.find(permission => permission.name === editedFormFields[PERMISSION_NAME].value)[PERMISSION_ACTIONS].length) }, [PERMISSION_ACTIONS + VALIDATION_STATE]: '' })
    }
  }

  useEffect(() => {
    setFormFields(
      (formFields) => ({
        [ENTITY_ID]: { ...formFields[ENTITY_ID], value: policy && policy.role ? policy.role.entity[ID] : '' },
        [ROLE_ID]: { ...formFields[ROLE_ID], value: policy && policy.role ? policy.role[ID] : '' },
        [PERMISSION_NAME]: { ...formFields[PERMISSION_NAME], value: policy && policy.permission ? policy.permission[NAME] : '' },
        [PERMISSION_ACTIONS]: { ...formFields[PERMISSION_ACTIONS], value: policy[PERMISSION_ACTIONS] || [] }
      })
    )
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [policy, isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const submitForm = async () => {
    activateSpinner()
    const requestBody = {
      ...(isEdit ? {
        newPermissionName: formFields[PERMISSION_NAME].value,
        newRoleId: formFields[ROLE_ID].value,
        roleId: policy.role.id,
        permissionName: policy.permission.name
      }
        : {
          permissionName: formFields[PERMISSION_NAME].value,
          roleId: formFields[ROLE_ID].value
        }),
      actions: formFields[PERMISSION_ACTIONS].value
    }
    let response
    if (!isEdit) {
      response = await addPolicy(requestBody)
    } else {
      response = await editPolicy(requestBody)
    }
    disableSpinner()

    handleResponse(response)
  }

  const handleResponse = (response) => {
    if (response.didSucceed) {
      policyAdded()
    } else {
      showErrorNotification(t('Could not complete the request.'))
    }
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [])
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      hideActions
      showGoBackButton
      dialogPaper={classes.dialogPaper}
      onCancel={() => {
        setIsAwaitingResponse(false)
        onClose()
      }}
      title={!policy || !Object.keys(policy).length ? t('Add Policy') : t('Edit Policy')}
    >
      <GridContainer id='content-pane-layout' justify='center'>

        <GridItem md={9}>
          <Card style={{ boxShadow: 'none' }}>
            <CardBody>
              <form>
                <GridContainer
                  direction='column'
                  justify='center'
                  alignItems='center'
                  id='card-body-section'
                >
                  <GridItem style={{
                    width: '40vh',
                    margin: '0 0 17px 0',
                    paddingTop: '27px',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        style={{
                          color: '#aaaaaa',
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                        id='addPolicyEntityLabel'
                      >
                        {`${t('Entity')} *`}
                      </InputLabel>
                      <Select
                        error={formFields[ENTITY_ID + VALIDATION_STATE]}
                        style={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#495057'
                        }}
                        id='addPolicyEntityInput'
                        renderValue={(selected) => entities.find(entity => entity[ID] === selected).name}
                        value={formFields[ENTITY_ID].value}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        inputProps={{
                          onChange: event => {
                            handleTextChange(event, ENTITY_ID)
                          }
                        }}
                      >
                        {
                          entities.map(entity => {
                            return <MenuItem
                              id='addPolicyEntityMenuItem'
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              key={entity[ID]}
                              value={entity[ID]}
                            >
                              <Checkbox
                                id='addPolicyEntityCheckbox'
                                checkedIcon={<Check
                                  className={classes.checkedIcon}
                                  style={{ color: INFO_COLOR }}
                                />}
                                icon={<Check
                                  className={classes.uncheckedIcon}
                                />}
                                checked={formFields[ENTITY_ID].value === entity[ID]}
                              />
                              <ListItemText primary={entity.name} />
                            </MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>

                  </GridItem>
                  <GridItem style={{
                    width: '40vh',
                    margin: '0 0 17px 0',
                    paddingTop: '27px',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    {
                      formFields[ENTITY_ID].value
                        ? <FormControl fullWidth>
                          <InputLabel
                            style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            id='addPolicyRoleLabel'
                          >
                            {`${t('Role')} *`}
                          </InputLabel>
                          <Select
                            error={formFields[ROLE_ID + VALIDATION_STATE]}
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            id='addPolicyRoleInput'
                            input={<Input />}
                            renderValue={() => {
                              if (formFields[ROLE_ID].value) {
                                const entity = entities.find(entity => entity[ID] === formFields[ENTITY_ID].value)
                                return entity.roles.find(role => role[ID] === formFields[ROLE_ID].value).name
                              }
                            }}
                            value={formFields[ROLE_ID].value}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              onChange: event => {
                                handleTextChange(event, ROLE_ID)
                              }
                            }}
                          >
                            {
                              entities.find(entity => entity[ID] === formFields[ENTITY_ID].value).roles.map(role => {
                                return <MenuItem
                                  id='addPolicyRoleMenuItem'
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={role[ID]}
                                  value={role[ID]}
                                >
                                  <Checkbox
                                    id='addPolicyRoleCheckbox'
                                    checkedIcon={<Check
                                      className={classes.checkedIcon}
                                      style={{ color: INFO_COLOR }}
                                    />}
                                    icon={<Check
                                      className={classes.uncheckedIcon}
                                    />}
                                    checked={formFields[ROLE_ID].value === role[ID]}
                                  />
                                  <ListItemText primary={role.name} />
                                </MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                        : null
                    }
                  </GridItem>
                  <GridItem style={{
                    width: '40vh',
                    margin: '0 0 17px 0',
                    paddingTop: '27px',
                    position: 'relative',
                    verticalAlign: 'unset'
                  }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        style={{
                          color: '#aaaaaa',
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                        id='addPolicyPermissionLabel'
                      >
                        {`${t('Permission')} *`}
                      </InputLabel>
                      <Select
                        error={formFields[PERMISSION_NAME + VALIDATION_STATE]}
                        style={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#495057'
                        }}
                        id='addPolicyPermissionInput'
                        input={<Input />}
                        renderValue={() => {
                          if (formFields[PERMISSION_NAME].value) {
                            return formFields[PERMISSION_NAME].value
                          }
                        }}
                        value={formFields[PERMISSION_NAME].value}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        inputProps={{
                          onChange: event => {
                            handleTextChange(event, PERMISSION_NAME)
                          }
                        }}
                      >
                        {
                          permissions.map(permission => {
                            return <MenuItem
                              id='addPolicyPermissionMenuItem'
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              key={permission[NAME]}
                              value={permission[NAME]}
                            >
                              <Checkbox
                                id='addPolicyPermissionCheckbox'
                                checkedIcon={<Check
                                  className={classes.checkedIcon}
                                  style={{ color: INFO_COLOR }}
                                />}
                                icon={<Check
                                  className={classes.uncheckedIcon}
                                />}
                                checked={formFields[PERMISSION_NAME].value === permission[NAME]}
                              />
                              <ListItemText primary={permission[NAME]} />
                            </MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>

                  </GridItem>
                  {
                    formFields[PERMISSION_NAME].value && permissions.find(permission => permission.name === formFields[PERMISSION_NAME].value)[PERMISSION_ACTIONS].length
                      ? <GridItem style={{
                        width: '40vh',
                        margin: '0 0 17px 0',
                        paddingTop: '27px',
                        position: 'relative',
                        verticalAlign: 'unset'
                      }}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            style={{
                              color: '#aaaaaa',
                              fontSize: '14px',
                              fontWeight: 400
                            }}
                            id='addPolicyAllowedActionsLabel'
                          >
                            {`${t('Allowed Actions')} *`}
                          </InputLabel>
                          <Select
                            error={formFields[PERMISSION_ACTIONS + VALIDATION_STATE]}

                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#495057'
                            }}
                            id='addPolicyAllowedActionsInput'
                            multiple
                            input={<Input />}
                            renderValue={() => {
                              if (formFields[PERMISSION_ACTIONS].value) {
                                return formFields[PERMISSION_ACTIONS].value.join(', ')
                              }
                            }}
                            value={formFields[PERMISSION_ACTIONS].value}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              onChange: event => {
                                handleTextChange(event, PERMISSION_ACTIONS)
                              }
                            }}
                          >
                            {
                              permissions.find(permission => permission.name === formFields[PERMISSION_NAME].value)[PERMISSION_ACTIONS].map(action => {
                                return <MenuItem
                                  id='addPolicyAllowedActionsMenuItem'
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={action}
                                  value={action}
                                >
                                  <Checkbox
                                    id='addPolicyAllowedActionsCheckbox'
                                    checkedIcon={<Check
                                      className={classes.checkedIcon}
                                      style={{ color: INFO_COLOR }}
                                    />}
                                    icon={<Check
                                      className={classes.uncheckedIcon}
                                    />}
                                    checked={formFields[PERMISSION_ACTIONS].value.indexOf(action) !== -1}
                                  />
                                  <ListItemText primary={action} />
                                </MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>

                      </GridItem>
                      : null
                  }

                </GridContainer>
                <GridContainer
                  justify='center'
                  alignItems='center'
                  id='card-footer-section'
                >
                  <GridItem>
                    <LoadingButton
                      color='info'
                      type='submit'
                      id='policySaveBtn'
                      onClick={(e) => {
                        e.preventDefault()
                        handleSaveBtnClick()
                      }}
                      fullWidth
                      disabled={isAwaitingResponse}
                      isLoading={isAwaitingResponse}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Modal>
  )
}

export default withAuthentication(withStyles(addPolicyModalStyle)(withTranslation()(AddWasherModal)))
