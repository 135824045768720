import React from 'react'
import ResetCacheModal from './resetCacheModal'
import resetCache from '../../endpoint-requests/machine-update-settings/resetCache'

class ResetCacheModalContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = { isOpen: false }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.isOpen) {
      this.setState({
        isOpen: nextProps.isOpen
      })
    }
  }

  render () {
    const { isOpen } = this.state
    const { onClose } = this.props
    return (
      <>
        <ResetCacheModal
          id='resetCacheModal'
          isOpen={isOpen}
          title='Reset Cache'
          onApply={() => {
				    resetCache()
				    this.setState({ isOpen: false })
            onClose(true)
          }}
          onCancel={() => {
				    this.setState({ isOpen: false })
            onClose()
          }}
          applyButtonText='RESET'
        />
      </>
    )
  }
}

export default ResetCacheModalContainer
