import Modal from '../../../../components-soapy/Modal.jsx'
import addContactModalStyle from '../../../../assets-soapy/jss/soapy/views/addContactModalStyle.jsx'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import AddContact from './AddContact.jsx'

const SiteModal = ({ getMid, contactEdited, contact, siteId, isOpen, classes, contactAdded, onClose, t }) => {
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      onCancel={onClose}
      dialogPaper={classes.dialogPaper}
      hideActions
      showGoBackButton
      title={!contact || !Object.keys(contact).length ? t('Add Contact') : t('Edit Contact')}
    >
      <div>
        <AddContact
          contactEdited={contactEdited}
          isClosed={!isOpen}
          contact={contact}
          siteId={siteId}
          contactAdded={contactAdded}
          isContactPage
        />
      </div>
    </Modal>
  )
}

export default withStyles(addContactModalStyle)(withTranslation()(SiteModal))
