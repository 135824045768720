import { Tooltip } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import AddAlert from '@material-ui/icons/AddAlert'
import Edit from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import formStyle from '../../assets-soapy/jss/soapy/components/formStyle.jsx'
import Card from '../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../components-soapy/LoadingButton'
import SweetAlert from '../../components-soapy/SweetAlert.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import CardIcon from '../../components/Card/CardIcon.jsx'
import CustomInput from '../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Snackbar from '../../components/Snackbar/Snackbar.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import editProfile from '../../endpoint-requests/dashboard-user/editProfile'
import { verifyLength } from '../../utils/validators'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import Check from '@material-ui/icons/Check'
import { allPages, pages } from '../../routes/pages'

const ENTER_KEY = 13
const VERIFY_LENGTH_MESSAGE = 'should not be empty, and up to 100 characters'
const INFO_COLOR = '#00acc1'

const FIRST_NAME = 'firstName'
const PHONE_NUMBER = 'phoneNumber'
const ENTITY = 'entity'
const EMAIL = 'email'
const LAST_NAME = 'lastName'
const POSITION = 'position'
const DEFAULT_ENTITY = 'neo'
const DEFAULT_PAGE = 'defaultPage'
const FULL_SCREEN = 'fullScreen'
const TWO_FA = 'twoFA'

const VALIDATION_STATE = 'ValidationState'

const fieldNames = [
  FIRST_NAME,
  PHONE_NUMBER,
  ENTITY,
  EMAIL,
  LAST_NAME,
  POSITION,
  DEFAULT_PAGE,
  FULL_SCREEN,
  TWO_FA,
]

const mapFieldNameToValidationHandler = {
  [FIRST_NAME]: (text) => {
    return verifyLength(text, 1, 100)
  },
  [LAST_NAME]: (text) => {
    return verifyLength(text, 1, 100)
  },
  [PHONE_NUMBER]: (phoneNumber) => isValidPhoneNumber(phoneNumber),
}

const mapFieldNameToInvalidFieldNotificationMessage = {
  [FIRST_NAME]: 'First name ' + VERIFY_LENGTH_MESSAGE,
  [LAST_NAME]: 'Last name ' + VERIFY_LENGTH_MESSAGE,
  [PHONE_NUMBER]: 'Phone number Is Invalid',
}

class EditProfile extends React.Component {
  phoneInputComponent = (() => {
    const self = this
    return React.forwardRef(function (props, r) {
      return (
        <CustomInput
          labelText={`${self.props.t('Phone number')}`}
          inputProps={{
            ...props,
            inputRef: r,
          }}
          formControlProps={{
            fullWidth: true,
          }}
        />
      )
    })
  })()

  constructor (props) {
    super(props)

    const {
      firstName,
      lastName,
      position,
      email,
      phoneNumber,
      defaultPage,
      fullScreen,
      role,
      entity,
      twoFA
    } = props.currentDashboardUser

    const defaultPageObject = {}
    if (role && entity) {
      pages[entity.name][role.name].sort().forEach(page => {
        defaultPageObject[allPages[page]] = '/' + page
      })
    }

    const mapFieldNameToDashboardUserData = {
      [FIRST_NAME]: firstName,
      [PHONE_NUMBER]: phoneNumber,
      [EMAIL]: email,
      [LAST_NAME]: lastName,
      [POSITION]: position,
      [DEFAULT_PAGE]: defaultPage,
      [FULL_SCREEN]: fullScreen,
      [TWO_FA]: twoFA,
    }

    let fieldsStates = {}
    fieldNames.forEach((fieldName) => {
      fieldsStates[fieldName] =
        mapFieldNameToDashboardUserData[fieldName] || ''
      fieldsStates[fieldName + VALIDATION_STATE] = ''
    })
    this.state = {
      ...fieldsStates,
      entity: DEFAULT_ENTITY,
      id: '',
      isSubmitSuccessfulModalActive: false,
      isErrorNotificationActive: false,
      errorNotificationMessage: '',
      isAwaitingResponse: false,
      isEditLoaded: false,
      defaultPageObject
    }
  }

  componentDidUpdate () {
    const { t } = this.props
    this.validationErrors = {
      unknownError: {
        message: t('Could not complete the request.'),
        affectedFieldsNames: [],
      },
    }
  }

  validateForm = () => {
    /* Validate individual fields */
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i]
      const fieldValue = this.state[fieldName]
      if (!mapFieldNameToValidationHandler[fieldName]) {
        continue
      }
      if (!fieldValue) {
        continue
      }
      const isValid = mapFieldNameToValidationHandler[fieldName](fieldValue)
      if (!isValid) {
        return {
          isValid: false,
          error: {
            message: this.props.t(
              mapFieldNameToInvalidFieldNotificationMessage[fieldName]
            ),
            affectedFieldsNames: [this.props.t(fieldName)],
          },
        }
      }
    }
    return { isValid: true }
  }

  submitForm = async () => {
    let {
      firstName,
      lastName,
      position,
      phoneNumber = '',
      defaultPage,
      fullScreen,
      twoFA
    } = this.state

    if (fullScreen === '') {
      fullScreen = false
    }
    if (twoFA === '') {
      twoFA = false
    }

    this.activateSpinner()
    const response = await editProfile(
      firstName,
      lastName,
      position,
      phoneNumber,
      defaultPage,
      fullScreen,
      twoFA
    )
    this.disableSpinner()
    const dashboardUserData = {
      firstName,
      lastName,
      phoneNumber,
      position,
      defaultPage,
      fullScreen,
      twoFA,
    }
    this.handleResponse(response, dashboardUserData)
  }

  activateSpinner = () => {
    this.spinnerTimeout = setTimeout(
      function () {
        this.setState({ isAwaitingResponse: true })
      }.bind(this),
      300
    )
  }

  disableSpinner = () => {
    clearTimeout(this.spinnerTimeout)
    this.setState({ isAwaitingResponse: false })
  }

  handleResponse = (didSucceed, dashboardUserData, isDashboardUserPage) => {
    if (didSucceed['didSucceed']) {
      this.hideErrorNotification()
      this.showSuccessfulSubmitModal()
      this.props.updateCurrentDashboardUser(dashboardUserData)
    } else {
      let errorMessage = ''
      switch (didSucceed['errorType']) {
        // case EMAIL_ALREADY_EXISTS:
        //     errorMessage = this.validationErrors.email.message
        //     break
        case 'adminRoleLimit':
          errorMessage = this.validationErrors.adminRoleLimit.message
          break
        default:
          errorMessage = this.validationErrors.unknownError.message
      }

      this.showErrorNotification(errorMessage)
    }
  }

  changeValidationStateToError = (affectedFieldsNames) => {
    affectedFieldsNames.forEach((fieldName) => {
      this.setState({ [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  showSuccessfulSubmitModal = () => {
    this.setState({
      isSubmitSuccessfulModalActive: true,
    })
  }

  hideSuccessfulSubmitModal = () => {
    this.setState({
      isSubmitSuccessfulModalActive: false,
    })
  }

  showErrorNotification = (errorMessage) => {
    const { isErrorNotificationActive } = this.state
    if (!isErrorNotificationActive) {
      this.setState({
        isErrorNotificationActive: true,
        errorNotificationMessage: errorMessage,
      })
      setTimeout(
        function () {
          this.setState({ isErrorNotificationActive: false })
        }.bind(this),
        6000
      )
    }
  }

  hideErrorNotification = () => {
    this.setState({ isErrorNotificationActive: false })
  }

  processForm = () => {
    const { isValid, error } = this.validateForm()
    if (isValid) {
      this.submitForm()
    } else {
      this.showErrorNotification(error.message)
      this.changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  handleTextChange = (event, fieldName) => {
    const text = event.target.value
    this.setState({ [fieldName]: text, [fieldName + VALIDATION_STATE]: '' })
  }

  handleKeyDown = (event) => {
    if (event.which === ENTER_KEY) {
      this.processForm()
    }
  }

  handleSaveBtnClick = () => {
    this.processForm()
  }

  componentDidMount () {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      700
    )
  }

  checkCanSave = () => {
    const { firstName, lastName } = this.state
    return firstName && lastName
  }

  componentWillUnmount () {
    clearTimeout(this.spinnerTimeout)
    this.spinnerTimeout = null
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.isDashboardUserPage) {
      const {
        firstName = '',
        lastName = '',
        email = '',
        entity,
        position = '',
        phoneNumber = '',
        id,
        defaultPage = '/dashboard',
        fullScreen = false,
        role,
        twoFA = false,
      } = nextProps.dashboardUserEdit

      const defaultPageObject = {}
      if (role && entity) {
        pages[entity.name][role.name].sort().forEach(page => {
          defaultPageObject[allPages[page]] = '/' + page
        })
      }

      this.setState({
        firstName: firstName || this.state.firstName,
        email: email || this.state.email,
        entity: entity ? entity.name : DEFAULT_ENTITY,
        lastName: lastName || this.state.lastName,
        phoneNumber: phoneNumber || this.state.phoneNumber,
        position: position || this.state.position,
        id: id || this.state.id,
        defaultPage: defaultPage || this.state.defaultPage,
        fullScreen: fullScreen || this.state.fullScreen,
        twoFA: twoFA || this.state.twoFA,
      })
    }
  }

  handleCheckboxChange (name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  render () {
    const {
      isSubmitSuccessfulModalActive,
      isAwaitingResponse,
      firstName,
      lastName,
      position,
      email,
      phoneNumber,
      defaultPage,
      fullScreen,
      twoFA,
      defaultPageObject = {}
    } = this.state
    let { classes, t } = this.props

    return (
      <GridContainer id="content-pane-layout" justify="center">
        <GridItem md={9}>
          <Card>
            <CardHeader>
              <Tooltip title={t('Edit profile')}>
                <CardIcon color="info">
                  <Edit
                    className={classes.cardHeaderIcon}
                    style={{ fontSize: 30 }}
                  />
                </CardIcon>
              </Tooltip>
            </CardHeader>
            <CardBody>
              <div>
                <form>
                  <GridContainer
                    direction="column"
                    justify="center"
                    alignItems="center"
                    id="card-body-section"
                  >
                    <GridItem style={{ minWidth: '40vh' }}>
                      <CustomInput
                        id="editProfileFirstName"
                        error={
                          this.state[FIRST_NAME + VALIDATION_STATE] === 'error'
                        }
                        labelText={`${t('First name')} *`}
                        inputProps={{
                          value: firstName,
                          type: 'name', // TODO: Verify this
                          autoComplete: 'name', // TODO: Verify this
                          onChange: (event) => {
                            this.handleTextChange(event, FIRST_NAME)
                          },
                          onKeyDown: (event) => this.handleKeyDown(event),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>

                    <GridItem style={{ minWidth: '40vh' }}>
                      <CustomInput
                        id="editProfileLastName"
                        error={
                          this.state[LAST_NAME + VALIDATION_STATE] === 'error'
                        }
                        labelText={`${t('Last name')} *`}
                        inputProps={{
                          value: lastName,
                          type: 'name', // TODO: Verify this
                          autoComplete: 'name', // TODO: Verify this
                          onChange: (event) => {
                            this.handleTextChange(event, LAST_NAME)
                          },
                          onKeyDown: (event) => this.handleKeyDown(event),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem style={{ minWidth: '40vh' }}>
                      <CustomInput
                        id="editProfileEmail"
                        labelText={`${t('Email')} *`}
                        greyBackground={true}
                        style={{ color: 'grey' }}
                        inputProps={{
                          value: email,
                          type: 'name', // TODO: Verify this
                          autoComplete: 'name', // TODO: Verify this
                          onChange: (event) => {
                            this.handleTextChange(event, EMAIL)
                          },
                          onKeyDown: (event) => this.handleKeyDown(event),
                        }}
                        formControlProps={{
                          fullWidth: true,
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem style={{ minWidth: '40vh' }}>
                      <PhoneInput
                        value={phoneNumber}
                        inputComponent={this.phoneInputComponent}
                        onChange={(phoneNumber) => {
                          this.setState({ phoneNumber })
                        }}
                        id="editProfilePhoneNumber"
                      />
                    </GridItem>

                    <GridItem style={{ minWidth: '40vh' }}>
                      <CustomInput
                        id="editProfilePositionCustomInput"
                        labelText={t('Entity')}
                        style={{ color: 'grey' }}
                        greyBackground={true}
                        inputProps={{
                          value: this.props.currentDashboardUser.entity.name,
                        }}
                        formControlProps={{
                          fullWidth: true,
                          disabled: true,
                        }}
                      />
                    </GridItem>

                    <GridItem style={{ minWidth: '40vh' }}>
                      <CustomInput
                        id="editProfilePositionCustomInput"
                        labelText={t('Position')}
                        inputProps={{
                          value: position,
                          onChange: (event) => {
                            this.handleTextChange(event, POSITION)
                          },
                          onKeyDown: (event) => this.handleKeyDown(event),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem style={{ minWidth: '40vh' }}>
                      <FormControl
                        fullWidth
                      >
                        <InputLabel style={{
                          color: '#aaaaaa',
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                        >
                          {t('Default page')}
                        </InputLabel>
                        <Select
                          id="defaultPageSelect"
                          value={defaultPage}
                          onChange={e => this.handleTextChange(e, DEFAULT_PAGE)}
                        >
                          {
                            Object.keys(defaultPageObject).map(key => {
                              return (
                                <MenuItem
                                  value={defaultPageObject[key]}
                                  key={key}
                                >
                                  {key}
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem style={{ minWidth: '40vh', margin: '16px 0 0px -36px', display: 'flex' }}>
                      <Checkbox
                        id="adminEditUnitEnableVisualErrorCheckbox"
                        tabIndex={-1}
                        onChange={() => this.handleCheckboxChange(FULL_SCREEN)}
                        checkedIcon={
                          <Check
                            className={classes.checkedIcon}
                            style={{ color: INFO_COLOR }}
                          />
                        }
                        icon={<Check className={classes.uncheckedIcon}/>}
                        checked={fullScreen}
                        style={{ marginLeft: '9px' }}
                      />
                      <div
                        style={{ marginTop: '8px' }}
                      >{t('Full Screen Page')}
                      </div>
                    </GridItem>
                    <GridItem style={{ minWidth: '40vh', margin: '16px 0 0px -36px', display: 'flex' }}>
                      <Checkbox
                        id="dashboardUserEditTwoFA"
                        tabIndex={-1}
                        onChange={() => this.handleCheckboxChange(TWO_FA)}
                        checkedIcon={
                          <Check
                            className={classes.checkedIcon}
                            style={{ color: INFO_COLOR }}
                          />
                        }
                        icon={<Check className={classes.uncheckedIcon}/>}
                        checked={twoFA}
                        style={{ marginLeft: '9px' }}
                      />
                      <div
                        style={{ marginTop: '8px' }}
                      >{t('Enable 2FA')}
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    justify="center"
                    alignItems="center"
                    id="card-footer-section"
                  >
                    <GridItem>
                      <LoadingButton
                        color="info"
                        onClick={this.handleSaveBtnClick}
                        id="editProfileSaveButton"
                        fullWidth={true}
                        disabled={isAwaitingResponse || !this.checkCanSave()}
                        isLoading={isAwaitingResponse}
                      >
                        {t('Save')}
                      </LoadingButton>
                    </GridItem>
                  </GridContainer>
                </form>
                <div id="alerts-section">
                  <Snackbar
                    place="tc"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.errorNotificationMessage}
                    open={this.state.isErrorNotificationActive}
                    closeNotification={() =>
                      this.setState({ isErrorNotificationActive: false })
                    }
                    close
                  />
                  {isSubmitSuccessfulModalActive ? (
                    <SweetAlert
                      success
                      id="editProfileSuccessAlert"
                      confirmBtnText={t('Ok')}
                      title={t('Saved') + '!'}
                      style={{
                        position: 'absolute',
                      }}
                      onConfirm={() => this.hideSuccessfulSubmitModal()}
                      onCancel={() => this.hideSuccessfulSubmitModal()}
                    />
                  ) : null}
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

EditProfile.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withAuthentication(
  withStyles(formStyle)(withTranslation()(EditProfile))
)
