import axios from 'axios'
import { apiEndpoints, SERVER_PATH } from '../endpoints.js'
import failedSession from '../failedSession'

export const getVersion = async () => {
  const endpoint = `${apiEndpoints.version}`
  const URL = SERVER_PATH + endpoint
  try {
    const { data: version } = await axios.get(URL, { withCredentials: true })
    return version
  } catch (e) {
    failedSession(e)
    return { didSucceed: false, errprType: 'SERVER_ERROR' }
  }
}
