import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import SettingsIcon from '@material-ui/icons/Settings'
import './Shift.css'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Highcharts from 'highcharts'
import { HANDS_ICON_NAMES_LOWERCASE, } from '../../shared/hands-recognition/handsRecognitionNames'
import { apiEndpoints, SERVER_PATH } from '../../endpoint-requests/endpoints'
import { Grid, Tooltip } from '@material-ui/core'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components-soapy/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardIcon from '../../components/Card/CardIcon'
import washHandsIcon from '../../assets/img/washing-hand.svg'
import GridContainer from '../../components/Grid/GridContainer'
import dashboardStyle from '../../assets/jss/material-dashboard-pro-react/views/dashboardStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import { useDispatch, useSelector } from 'react-redux'
import { getOrganizations, getShiftConfiguration, getSites, } from '../../redux/apiCalls.js'
import ShiftPopup from './ShiftPopup.js'
import { popUpSuccess } from '../../redux/popUpReducer.js'
import soapDispenserIcon from '../../assets/img/soap-for-hands.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCalendarDay, faHands, faHandSparkles, faImage, faPoll } from '@fortawesome/free-solid-svg-icons'
import { store } from '../../redux/store'
import washSummaryIcon from '../../assets/img/arrow-trend-up-solid.svg'
import HighchartsReact from 'highcharts-react-official'

function getMds (showHandMovement, showShiftStatus, showTopWashers, showImportantUpdates) {
  const mds = {
    md0: 6,
    md1: 6,
    md2: 6,
    md3: 6,
    md4: 6,
  }

  if (!showTopWashers && !showHandMovement && !showShiftStatus && !showImportantUpdates) {
    mds.md0 = 12
    return mds
  }

  if (showTopWashers && !showHandMovement && !showShiftStatus && !showImportantUpdates) {
    mds.md0 = 9
    mds.md1 = 3
    return mds
  }

  return mds
}

const getCards = (configuration, classes, options2, options, t) => {

  let showHandMovement = configuration ? configuration.showHandMovement : true
  let showShiftStatus = configuration ? configuration.showShiftStatus : true
  let showTopWashers = configuration ? configuration.showTopWashers : true
  let showImportantUpdates = configuration ? configuration.showImportantUpdates : true

  const cards = [
    { show: showHandMovement, card: getHandMovementCard(classes, options2, t) },
    { show: showShiftStatus, card: getShiftStatusCard(classes, configuration, t) },
    { show: showTopWashers, card: getTopWashersCard(classes, configuration, t) },
    { show: showImportantUpdates, card: getImportantUpdatesCard(classes, configuration) }
  ]
  const [card1 = null, card2 = null, card3 = null, card4 = null] = cards.filter(card => card.show).map(card => card.card)
  return {
    card1,
    card2,
    card3,
    card4,
    ...getMds(
      showHandMovement,
      showShiftStatus,
      showTopWashers,
      showImportantUpdates
    )
  }
}

const getHandMovementCard = (classes, options2, t) =>
  <div>
    <Card style={{ marginBottom: 20 }}>
      <CardHeader color="success" icon>
        <Tooltip title="Wash Summary">
          <CardIcon className="cardIcon"
                    color="info"
                    style={{
                      paddingTop: 8,
                      paddingBottom: 10,
                      marginTop: -10,
                      marginBottom: -10,
                      width: 48,
                      height: 48,
                      paddingLeft: 10
                    }}>
            <FontAwesomeIcon icon={faHands} size="3x" className="svgIconStyle"/>
          </CardIcon>
        </Tooltip>
        <h4 className={classes.cardIconTitle}>{t('Hand Movement Summary')}</h4>
      </CardHeader>
      <CardBody>
        <HighchartsReact
          highcharts={Highcharts}
          options={options2}
          containerProps={{ style: { height: '325px', width: 'auto', border: '1px solid teal' } }}/>
      </CardBody>
    </Card>
  </div>
const getShiftStatusCard = (classes, shiftConfigurationData, t) =>
  <div>
    <Card style={{ marginBottom: 20, backgroundColor: 'rgba(211, 211, 211, 0.8' }}>
      <CardHeader color="success" icon>
        <Tooltip title="Wash Summary">
          <CardIcon className="cardIcon"
                    color="info"
                    style={{
                      paddingTop: 8,
                      paddingBottom: 10,
                      marginTop: -10,
                      marginBottom: -10,
                      width: 48,
                      height: 48,
                      paddingLeft: 10
                    }}>
            <FontAwesomeIcon icon={faCalendarDay} size="3x" className="svgIconStyle"/>
          </CardIcon>
        </Tooltip>
        <h4 className={classes.cardIconTitle}>{t('Current Shift Status')}</h4>
      </CardHeader>
      <CardBody>
        <div className="divTableWrapper">
          <table>
            <tr>
              <td className="shiftResultTableTd">
                <div className="shiftsResults">
                  <span className="shiftResultTitle">{t('Current Shift Results')}</span>
                  <span
                    className="shiftResultTitle"> ( {shiftConfigurationData && shiftConfigurationData.currentShiftListData && shiftConfigurationData.currentShiftListData.shift} ) </span>
                </div>
              </td>
              <td>
                <div className="shiftsResults">
                  <span className="shiftResultTitle">{t('Previous Shift Results')}</span>
                  <span
                    className="shiftResultTitle"> ( {shiftConfigurationData && shiftConfigurationData.previousShiftListData && shiftConfigurationData.previousShiftListData.shift} ) </span>
                </div>
              </td>
            </tr>
            <tr>
              <td className="shiftResultTableTd">
                <div className="shiftsResults">
                  <p className="shiftResultContent">{t('Total Washes')}
                    : {shiftConfigurationData && shiftConfigurationData.currentShiftListData && shiftConfigurationData.currentShiftListData.totalWashes}</p>
                  <p className="shiftResultContent"><b>{t('Total Hygienic Washes')}
                    : {shiftConfigurationData && shiftConfigurationData.currentShiftListData && shiftConfigurationData.currentShiftListData.totalHygenicWashes}</b>
                  </p>
                  <p className="shiftResultContent">{t('Averaged Results')}
                    : {shiftConfigurationData && shiftConfigurationData.currentShiftListData && shiftConfigurationData.currentShiftListData.averagedResult}%</p>
                </div>
              </td>
              <td>
                <div className="shiftsResults">
                  <p className="shiftResultContent">{t('Total Washes')}
                    : {shiftConfigurationData && shiftConfigurationData.previousShiftListData && shiftConfigurationData.previousShiftListData.totalWashes}</p>
                  <p className="shiftResultContent"><b>{t('Total Hygienic Washes')}
                    : {shiftConfigurationData && shiftConfigurationData.previousShiftListData && shiftConfigurationData.previousShiftListData.totalHygenicWashes}</b>
                  </p>
                  <p className="shiftResultContent">{t('Averaged Results')}
                    : {shiftConfigurationData && shiftConfigurationData.previousShiftListData && shiftConfigurationData.previousShiftListData.averagedResult}%</p>
                </div>
              </td>
            </tr>
            <tr>
              {
                shiftConfigurationData
                  ?
                  (shiftConfigurationData.currentShiftListData
                      ?
                      <>
                        <td className="shiftResultTableTd" style={{
                          fontSize: '20px', fontWeight: 700,
                          color: `${shiftConfigurationData ?
                            (shiftConfigurationData.currentShiftListData ?
                              (shiftConfigurationData.currentShiftListData.washesLeft > 0 ?
                                'orange' :
                                'green') :
                              'orange') :
                            'orange'}`
                        }}>
                          <div className="shiftsResults">
                            {shiftConfigurationData.currentShiftListData.washesLeft} {t('Washes Left')}
                          </div>
                        </td>
                      </>
                      :
                      <>
                        <td className="shiftResultTableTd" style={{
                          fontSize: '20px', fontWeight: 700, color: `${shiftConfigurationData ?
                            (shiftConfigurationData.currentShiftListData ?
                              (shiftConfigurationData.currentShiftListData.washesLeft > 0 ?
                                'orange' :
                                'green') :
                              'orange') :
                            'orange'}`
                        }}>
                          <div className="shiftsResults">
                            {shiftConfigurationData.minimumWashes} {t('Washes Left')}
                          </div>
                        </td>
                      </>
                  )
                  :
                  <>
                    <td className="shiftResultTableTd" style={{
                      fontSize: '20px', fontWeight: 700, color: `${shiftConfigurationData ?
                        (shiftConfigurationData.currentShiftListData ?
                          (shiftConfigurationData.currentShiftListData.washesLeft > 0 ?
                            'orange' :
                            'green') :
                          'orange') :
                        'orange'}`
                    }}>
                      <div className="shiftsResults">
                        0 {t('Washes Left')}
                      </div>
                    </td>
                  </>
              }
              {
                shiftConfigurationData
                  ?
                  (shiftConfigurationData.previousShiftListData
                      ?
                      <>
                        <td style={{
                          fontSize: '20px', fontWeight: 700,
                          color: `${shiftConfigurationData ?
                            (shiftConfigurationData.previousShiftListData ?
                              (shiftConfigurationData.previousShiftListData.washesLeft > 0 ?
                                'orange' :
                                'green') :
                              'orange') :
                            'orange'}`
                        }}>
                          <div className="shiftsResults">
                            {shiftConfigurationData.previousShiftListData.washesLeft} {t('Washes Left')}
                          </div>
                        </td>
                      </>
                      :
                      <>
                        <td style={{
                          fontSize: '20px', fontWeight: 700,
                          color: `${shiftConfigurationData ?
                            (shiftConfigurationData.previousShiftListData ?
                              (shiftConfigurationData.previousShiftListData.washesLeft > 0 ?
                                'orange' :
                                'green') :
                              'orange') :
                            'orange'}`
                        }}>
                          <div className="shiftsResults">
                            {shiftConfigurationData.minimumWashes} {t('Washes Left')}
                          </div>
                        </td>
                      </>
                  )
                  :
                  <>
                    <td style={{
                      fontSize: '20px', fontWeight: 700,
                      color: `${shiftConfigurationData ?
                        (shiftConfigurationData.previousShiftListData ?
                          (shiftConfigurationData.previousShiftListData.washesLeft > 0 ?
                            'orange' :
                            'green') :
                          'orange') :
                        'orange'}`
                    }}>
                      <div className="shiftsResults">
                        0 {t('Washes Left')}
                      </div>
                    </td>
                  </>
              }
            </tr>
            <tr>
              <td>
                <div className="shiftsResults">
                  <p className="shiftResultContent" style={{
                    fontSize: '20px',
                    fontWeight: 700,
                    color: 'green'
                  }}>{shiftConfigurationData ? (shiftConfigurationData.currentShiftListData ? shiftConfigurationData.currentShiftListData.currentChampion : '') : ''}</p>
                </div>
              </td>
              <td>
                <div className="shiftsResults">
                  <p className="shiftResultContent" style={{
                    fontSize: '20px',
                    fontWeight: 700,
                    color: 'green'
                  }}>{shiftConfigurationData ? (shiftConfigurationData.currentShiftListData ? shiftConfigurationData.previousShiftListData.previousChampion : '') : ''}</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </CardBody>
    </Card>
  </div>
const getTopWashersCard = (classes, shiftConfigurationData, t) =>
  <div>
    <Card style={{ marginBottom: 20 }}>
      <CardHeader color="success" icon>
        <Tooltip title="Wash Summary">
          <CardIcon className="cardIcon"
                    color="info"
                    style={{
                      paddingTop: 8,
                      paddingBottom: 10,
                      marginTop: -10,
                      marginBottom: -10,
                      width: 48,
                      height: 48,
                      paddingLeft: 10
                    }}>
            <FontAwesomeIcon icon={faHandSparkles} size="3x" className="svgIconStyle"/>
          </CardIcon>
        </Tooltip>
        <h4 className={classes.cardIconTitle}>Top Washers</h4>
      </CardHeader>
      <CardBody>
        <div className="divTableWrapper">
          <div className="divTable">
            <div className="divTableHeader">
              <div className="divTableCell">Rank</div>
              <div className="divTableCell">Champion</div>
              <div className="divTableCell">Points</div>
            </div>
            <div className="divTableBody">
              {shiftConfigurationData && shiftConfigurationData.champions?.map((champion, index) => (
                <div className="divTableRow" key={index}>
                  <div className="divTableCell">{index + 1}</div>
                  <div className="divTableCell">{champion?.washer?.name}</div>
                  <div className="divTableCell">{Math.round(champion.score * 10) / 10}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  </div>
const getImportantUpdatesCard = (classes, shiftConfigurationData) =>
  <div>
    <Card style={{ marginBottom: 20 }}>
      <CardHeader color="success" icon>
        <Tooltip title="Wash Summary">
          <CardIcon className="cardIcon"
                    color="info"
                    style={{
                      paddingTop: 8,
                      paddingBottom: 10,
                      marginTop: -10,
                      marginBottom: -10,
                      width: 48,
                      height: 48,
                      paddingLeft: 10
                    }}>
            <FontAwesomeIcon icon={faBell} size="3x" className="svgIconStyle"/>
          </CardIcon>
        </Tooltip>
        <h4 className={classes.cardIconTitle}>Important Updates</h4>
      </CardHeader>
      <CardBody>
        <div className="divTableWrapper">
          {
            shiftConfigurationData ? (
                shiftConfigurationData.importantUpdateImage ?
                  <img src={shiftConfigurationData.importantUpdateImage} className="bottomImage"/>
                  :
                  <FontAwesomeIcon icon={faImage} style={{
                    height: '200px',
                    width: '200px',
                    marginLeft: '32%',
                    marginTop: '10%',
                  }}/>
              ) :
              <FontAwesomeIcon icon={faImage} style={{
                height: '200px',
                width: '200px',
                marginLeft: '32%',
                marginTop: '10%',
              }}/>
          }
        </div>
      </CardBody>
    </Card>
  </div>

const endpoint = `${apiEndpoints.getHandsRecognitionIcons}`
const handsRecognitionIconsURL = SERVER_PATH + endpoint

const Shift = ({ classes, t }) => {
  const dispatch = useDispatch()
  const elementRef = useRef()

  const [isActive, setIsActive] = useState(false)
  const autoRefreshTimeRedux = useSelector((state) => state.shifts?.autoRefreshTime)
  const { currentSitesNames, currentSitesIds, sitesMachinesIds } = useSelector((state) => state.sites)

  const organizations = useSelector((state) => state.organizations.organizations)
  //dynamic shift creation and selection 
  const [shiftList, setShiftList] = useState(store.getState().shifts.shiftList ? store.getState().shifts.shiftList : [])
  const [hoursList, setHoursList] = useState(store.getState().shifts.hoursList ? store.getState().shifts.hoursList : [])
  const [shiftData, setShiftData] = useState(store.getState().shifts.shiftData ? store.getState().shifts.shiftData :
    {
      currentShiftFromHour: '',
      currentShiftToHour: '',
      previousShiftFromHour: '',
      previousShiftToHour: '',
    })
  const shiftConfigurationDataRedux = useSelector((state) => state.shifts?.shiftConfigurationData)

  const [shiftConfigurationData, setShiftConfigurationData] = useState(shiftConfigurationDataRedux)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!elementRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      window.dispatchEvent(new Event('resize'))
    })
    resizeObserver.observe(elementRef.current)
    return () => resizeObserver.disconnect() // clean up
  }, [])

  //---taking ref to have control on previous value---//
  const autoRefreshTimerRef = useRef()
  //---taking global variable for setInterval---//
  let intervalTime

  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: shiftConfigurationData ? shiftConfigurationData.washDates : [],
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Washes'
      }
    },
    series: [{
      name: shiftConfigurationData ? shiftConfigurationData.currentSitesNames.toString() : '',
      data: shiftConfigurationData ? shiftConfigurationData.washValues : []
    },],
    colors: ['#4074c1',],
    credits: { enabled: false },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    }
  }

  const options2 = {
    chart: { type: 'column' },
    title: { text: '' },
    xAxis: {
      categories: shiftConfigurationData ? shiftConfigurationData.xaxis : [],
      labels: {
        useHTML: true,
        formatter: function () {
          const name = this.value
          let output = ''
          Object.keys(HANDS_ICON_NAMES_LOWERCASE).forEach(key => {
            const iconName = HANDS_ICON_NAMES_LOWERCASE[key]
            if (name === iconName) {
              output = key
            }
          })
          return `<br><img src=${handsRecognitionIconsURL}/${output}.svg height="40" width="40"/>`
        },
        style: { 'text-align': 'center' }
      }
    },
    yAxis: {
      min: 0,
      title: { text: 'Wash Count' },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: ( // theme
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || 'gray',
          textOutline: 'none'
        }
      }
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: { enabled: true }
      }
    },
    series: shiftConfigurationData ? shiftConfigurationData.series : [],
    colors: ['#4074c1', '#ffe78a'],
    credits: { enabled: false },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    }
  }

  const handleOpen = async () => {
    setIsActive(true)
    // getFromToDate(dispatch, dateRange, fromDate, new Date().toISOString())
    dispatch(popUpSuccess(true))
    if (organizations && organizations.length === 1) {
      await getSites(dispatch, organizations[0].id)
    }
  }

  const apiCall = () => {
    setLoading(true)
    getShiftConfiguration(dispatch, setShiftConfigurationData, setLoading)
  }

  const commonFunction = () => {
    //------api call------//
    apiCall()
  }

  useEffect(() => {
      getOrganizations(dispatch)
      //------api call------//
      apiCall()
    },
    []
  )

  useEffect(() => {
    const { autoRefreshTime } = store.getState().shifts
    //----calling commonFunction----//
    intervalTime = setInterval(() => {
      commonFunction()
    }, autoRefreshTime * 60 * 1000)
    //---appending value to current field---//
    autoRefreshTimerRef.current = intervalTime
    return () => clearInterval(intervalTime)
  }, [autoRefreshTimeRedux])

  useEffect(() => {
    if (shiftConfigurationData) {
      getSites(dispatch, shiftConfigurationData.organization)
    }
  }, [])

  const {
    card1 = null,
    card2 = null,
    card3 = null,
    card4 = null,
    md0 = 6,
    md1 = 6,
    md2 = 6,
    md3 = 6,
    md4 = 6,
  } = getCards(shiftConfigurationData, classes, options2, options, t)

  return (
    <>
      {
        loading &&
        <>
          <Box sx={{ position: 'absolute', marginLeft: '45%', marginTop: '25%', zIndex: '100' }}>
            <CircularProgress/>
          </Box>
        </>
      }
      <GridContainer>
        <Grid item md={12} style={{ marginBottom: '1rem' }}>
          <div className="topItemsWrapper">
            <div style={{ fontSize: '1.5em' }}>
              <span>{t('Selected Dates')} :</span>
              {shiftConfigurationData ?
                (shiftConfigurationData.fromDate === '' ? t('Today') :
                  moment(shiftConfigurationData.fromDate).format('DD/MM/YYYY'))
                :
                'Today'
              } - {moment().format('DD/MM/YYYY')}
            </div>
            {/* <h1>{title}</h1> */}
            <SettingsIcon onClick={handleOpen} style={{ width: '30px', height: '30px', cursor: 'pointer' }}/>
            {/* <img src={logo} style={{width: '50px'}}/> */}
          </div>
        </Grid>
        <Grid item md={4} style={{ paddingRight: 20, marginBottom: '1rem' }}>
          <Card style={{ marginBottom: 20 }}>
            <CardHeader color="success" icon>
              <Tooltip title="Total wash count">
                <CardIcon className="cardIcon"
                          color="info"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 10,
                            marginTop: -10,
                            marginBottom: -10,
                            width: 48,
                            height: 48,
                            paddingLeft: 10
                          }}>
                  <img
                    src={washHandsIcon}
                    style={{ width: '2em', height: '2em' }}
                    alt="washHands"
                  />
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Total Wash Count')}</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center', }}>
                <h3 className={classes.cardTitle}>{shiftConfigurationData ? shiftConfigurationData.washCount : 0}</h3>
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={4} style={{ paddingRight: 20, marginBottom: '1rem' }}>
          <Card style={{ marginBottom: 20 }}>
            <CardHeader color="success" icon>
              <Tooltip title={t('Total average wash score')}>
                <CardIcon className="cardIcon"
                          color="info"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 10,
                            marginTop: -10,
                            marginBottom: -10,
                            width: 48,
                            height: 48,
                            paddingLeft: 10
                          }}>
                  <FontAwesomeIcon icon={faPoll} size="3x" className="svgIconStyle"/>
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Total Average Wash Score')}</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center', }}>
                <h3
                  className={classes.cardTitle}>{shiftConfigurationData ? shiftConfigurationData.averageWashScore : 0} %</h3>
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={4} style={{ paddingRight: 20, marginBottom: '1rem' }}>
          <Card style={{ marginBottom: 20 }}>
            <CardHeader color="success" icon>
              <Tooltip title="Total average scrub time">
                <CardIcon className="cardIcon"
                          color="info"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 10,
                            marginTop: -10,
                            marginBottom: -10,
                            width: 48,
                            height: 48,
                            paddingLeft: 10
                          }}>
                  <img
                    src={soapDispenserIcon}
                    style={{ width: '2em', height: '2em' }}
                    alt="soapDispenser"
                  />
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>Total Average Scrub Time</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center', }}>
                <h3
                  className={classes.cardTitle}>{shiftConfigurationData ? shiftConfigurationData.averageScrubTime : 0} sec</h3>
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid ref={elementRef} item md={md0} style={{ paddingRight: 20, marginBottom: '1rem' }}>
          <Card style={{ marginBottom: 20 }}>
            <CardHeader color="success" icon>
              <Tooltip title={t('Wash Summary')}>
                <CardIcon className="cardIcon"
                          color="info"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 10,
                            marginTop: -10,
                            marginBottom: -10,
                            width: 48,
                            height: 48,
                            paddingLeft: 10
                          }}>
                  <img
                    src={washSummaryIcon}
                    style={{ width: '2em', height: '2em' }}
                    alt="washIcon"
                  />
                </CardIcon>
              </Tooltip>
              <h4 className={classes.cardIconTitle}>{t('Wash Summary')}</h4>
            </CardHeader>
            <CardBody>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                containerProps={{ style: { height: '325px', width: 'auto', border: '1px solid teal' } }}
              />
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={md1} style={{ paddingRight: 20, marginBottom: '1rem' }}>
          {card1}
        </Grid>
      </GridContainer>
      {isActive && <ShiftPopup setIsActive={setIsActive} isActive={isActive} apiCall={apiCall}
                               autoRefreshTimerId={autoRefreshTimerRef.current} shiftData={shiftData}
                               setShiftData={setShiftData} shiftList={shiftList} setShiftList={setShiftList}
                               hoursList={hoursList} setHoursList={setHoursList}
                               shiftConfigurationData={shiftConfigurationData}
                               setShiftConfigurationData={setShiftConfigurationData} setLoading={setLoading}/>}
      <GridContainer>
        <div className="bottom">
          <Grid item md={md2} style={{ paddingRight: 20 }}>
            {card2}
          </Grid>
          <Grid item md={md3} style={{ paddingRight: 20, marginBottom: '1rem' }}>
            {card3}
          </Grid>
          <Grid item md={md4} style={{ paddingRight: 20 }}>
            {card4}
          </Grid>
        </div>
      </GridContainer>
      {/* </LoadingOverlay> */}
    </>
  )
}

export default withAuthentication(withStyles(dashboardStyle)(withTranslation()(Shift)))

