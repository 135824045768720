import { createSlice } from "@reduxjs/toolkit";

const campaignSlice = createSlice({
    name: "campaign",
    initialState: {
        campaign: null,
        campaignMonitorOrientation: "",
        welcomeItems: [],
        risingItems: [],
        campaignActive: false,
        isFetching: false,
        error: false,
    },
    reducers: {
        campaignStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        campaignSuccess: (state, action) => {
            state.isFetching = false;
            state.campaign = action.payload;
        },
        campaignFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
        },
        campaignActiveStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        campaignActiveSuccess: (state, action) => {
            state.isFetching = false;

            if (action.payload.length > 0) {
                action.payload.map((campSite) => {
                    if (campSite.siteConfiguration.isCampaignActive === true) {
                        state.campaignActive = true;
                    }
                    else {
                        state.campaignActive = false;

                    }
                })
            } else {
                state.campaignActive = false;
            }
            // state.campaignActive = action.payload;
        },
        campaignActiveFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
        },

        campaignMonitorOrientationSuccess: (state, action) => {
            state.isFetching = false;
            if (action.payload === null || action.payload === "") {
                state.campaignMonitorOrientation = "horizontal";

            } else {

                state.campaignMonitorOrientation = action.payload;
            }
        },
        welcomeItemsSuccess: (state, action) => {
            state.isFetching = false;

            state.welcomeItems = action.payload;
        },
        risingItemsSuccess: (state, action) => {
            state.isFetching = false;

            state.risingItems = action.payload;
        },


    },
});

export const {
    campaignStart,
    campaignSuccess,
    campaignFailure,
    campaignActiveStart,
    campaignActiveSuccess,
    campaignActiveFailure,
    campaignMonitorOrientationSuccess,
    welcomeItemsSuccess,
    risingItemsSuccess,
} = campaignSlice.actions;


const campaignReducer = campaignSlice.reducer; //it should be export default
export default campaignReducer;