import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Button from '../../components/CustomButtons/Button'

const CampaignsTableHeader = ({
  hasPermission,
  openAddCampaignModal,
  totalCampaigns = 0,
  t
}) => {
  return (
    <div>
      {
        hasPermission({ name: 'campaigns', action: 'create' }) && <Button
          color='info'
          round
          id='washerAddBtn'
          onClick={openAddCampaignModal}
          style={{ marginRight: 5 }}
                                                                  >
          {t('Add Campaign')}
                                                                  </Button>
      }

      <div style={{ textAlign: 'right' }}><b>{t('Total campaigns')}: {totalCampaigns}</b></div>
    </div>
  )
}

export default withTranslation()(withAuthentication(CampaignsTableHeader))
