exports.ID = 'id'
exports.NAME = 'name'
exports.COUNTRY = 'country'
exports.STATE = 'state'
exports.CITY = 'city'
exports.CONTACT_NAME = 'contactName'
exports.CONTACT_EMAIL = 'contactEmail'
exports.WEBSITE = 'website'
exports.DISTRICT = 'district'
exports.ORGANIZATIONS = 'organizations'
exports.ADDRESS = 'address'
exports.ZIPCODE = 'zipcode'

exports.ERROR_TYPE = 'errorType' // property
exports.ERROR_TYPE_NO_ORGANIZATION_FOUND = 'noOrganizationFound' // value
