import axios from 'axios'
import dailyWashesRequestKeywords from '../../shared/data/daily-washes.js'
import { SERVER_PATH } from '../endpoints.js'
import failedSession from '../failedSession'

const fetchDailyWashes = async (fromDate, toDate, machineId, isStatus = false) => {
  const URL = SERVER_PATH + dailyWashesRequestKeywords.ENDPOINT_URL
  const requestBody = {
    [dailyWashesRequestKeywords.DATE]: new Date(fromDate),
    [dailyWashesRequestKeywords.TO_DATE]: new Date(toDate),
    [dailyWashesRequestKeywords.MACHINE_ID]: machineId
  }

  try {
    const {
      data: {
        [dailyWashesRequestKeywords.DAILY_WASHES]: dailyWashes,
        [dailyWashesRequestKeywords.TEMPERATURE_THRESHOLD]: temperatureThreshold
      }
    } = await axios.post(URL, requestBody, {
      withCredentials: true
    })

    return { data: dailyWashes, temperatureThreshold }
  } catch (e) {
    failedSession(e)
    return { error: 'unknown' }
  }
}






export default fetchDailyWashes 
