import { createSlice } from "@reduxjs/toolkit";

const organizationsSlice = createSlice({
    name: "organizations",
    initialState: {
        organizations: [],
        currentOrganization: "",
        currentOrganizationId: "",
        isFetching: false,
        error: false,
    },
    reducers: {
        getOrganizationsStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        getOrganizationsSuccess: (state, action) => {
            state.isFetching = false;
            state.organizations = action.payload
        },
        getOrganizationsFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentOrganizationStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentOrganizationSuccess: (state, action) => {
            state.isFetching = false;
            state.currentOrganization = action.payload
        },
        currentOrganizationFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        currentOrganizationIdStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        currentOrganizationIdSuccess: (state, action) => {
            state.isFetching = false;
            state.currentOrganizationId = action.payload
        },
        currentOrganizationIdFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        organizationLogoutSuccess: (state, action) => {
            state.isFetching = false;
            state.currentOrganizationId = ""
            state.organizations = []
            state.currentOrganization = ""
        },
    },
});

export const {
    getOrganizationsStart,
    getOrganizationsSuccess,
    getOrganizationsFailure,
    currentOrganizationStart,
    currentOrganizationSuccess,
    currentOrganizationFailure,
    currentOrganizationIdStart,
    currentOrganizationIdSuccess,
    currentOrganizationIdFailure,
    organizationLogoutSuccess
} = organizationsSlice.actions;


const organizationsReducer = organizationsSlice.reducer; //it should be export default
export default organizationsReducer;