import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import Card from '../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../components-soapy/LoadingButton.jsx'
import Modal from '../../components-soapy/Modal.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CustomInput from '../../components/CustomInput/CustomInput.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import withAuthentication from '../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../containers/withErrorNotification.js'
import getStatusConfiguration from '../../endpoint-requests/machine/getStatusConfiguration.js'
import useFormFields from '../../shared/hooks/useFormFields.js'
import validateFormFields from '../../utils/validateFormFields.js'
import { verifyLength } from '../../utils/validators'
import {
  PASSWORD
} from './constants.js'

const VALIDATION_STATE = 'validationState'

let spinnerTimeout = null

const ConfigurationLoginModal = ({
  isOpen,
  showErrorNotification,
  title,
  submitBtnText,
  serialNumber,
  password,
  onClose,
  openConfigurationModal,
  t
}) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const { formFields, setFormFields } = useFormFields({
    [PASSWORD]: {
      value: password || '',
      isRequired: true,
      name: 'Password',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 256),
          message: 'Password should not be empty, and up to 256 characters'
        }
      ]
    }
  })

  const submitForm = async () => {
    activateSpinner()
    try {
      const statusConfiguration = await getStatusConfiguration(serialNumber, formFields[PASSWORD].value)
      openConfigurationModal(statusConfiguration, formFields[PASSWORD].value)
    } catch (err) {
      showErrorNotification(err.message)
    }
    disableSpinner()
  }

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }

  const processForm = async () => {
    const { isValid, error } = await validateFormFields(formFields)
    if (isValid) {
      submitForm()
    } else {
      showErrorNotification(error.message)
      changeValidationStateToError(error.affectedFieldsNames)
    }
  }

  const handleSaveBtnClick = () => {
    processForm()
  }

  useEffect(() => {
    return () => {
      disableSpinner()
      clearTimeout(spinnerTimeout)
    }
  }, [])

  const handleTextChange = (event, fieldName) => {
    setFormFields({ ...formFields, [fieldName]: { ...formFields[fieldName], value: event.target.value } })
  }
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      hideActions
      showGoBackButton
      onCancel={onClose}
      title={title}
    >
      <div>
        <GridContainer id='content-pane-layout' justify='center'>
          <GridItem md={9}>
            <Card style={{ boxShadow: 'none' }}>
              <CardBody>
                <div>
                  <form>
                    <GridContainer
                      direction='column'
                      justify='center'
                      alignItems='center'
                      id='card-body-section'
                    >
                      <GridItem style={{ minWidth: '30vh' }}>
                        <CustomInput
                          id='siteConfigPwd'
                          error={formFields[PASSWORD + VALIDATION_STATE] === 'error'}
                          labelText={`${t('Password')} *`}
                          inputProps={{
                            value: formFields[PASSWORD].value,
                            type: 'password',
                            onChange: event => {
                              handleTextChange(event, PASSWORD)
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem style={{
                        width: '30vh',
                        margin: '0 0 17px 0',
                        paddingTop: '27px',
                        position: 'relative',
                        verticalAlign: 'unset'
                      }}
                      />
                    </GridContainer>
                    <GridContainer
                      justify='center'
                      alignItems='center'
                      id='card-footer-section'
                    >
                      <GridItem>
                        <LoadingButton
                          color='info'
                          type='submit'
                          id='siteConfigSaveButton'
                          onClick={(e) => {
                            e.preventDefault()
                            handleSaveBtnClick()
                          }}
                          fullWidth
                          disabled={isAwaitingResponse}
                          isLoading={isAwaitingResponse}
                        >
                          {submitBtnText}
                        </LoadingButton>
                      </GridItem>
                    </GridContainer>
                  </form>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </Modal>

  )
}

export default withErrorNotification(withAuthentication(withTranslation()(ConfigurationLoginModal)))
