import cookieConsentStyle from '../../assets-soapy/jss/soapy/components/cookieConsentStyle.jsx'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '../../components/CustomButtons/Button'
import { Modal, Grid, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import logo from '../../assets-soapy/img/logo_30_30.png'

const CookieConsent = ({ classes, isOpen, handleMoreInfo, handleApproval, t }) => {
  return (
    <Modal
      open={isOpen}
    >
      <div
        className={classes.consentContainer}
      >

        <Grid
          container
          spacing={3}
        >
          <Grid
            item xs={2}
          >
            <img
              src={logo} alt='logo' className={classes.logo}
            />
          </Grid>

          <Grid
            item xs={10}
          >
            <Typography>{t('We use cookies to enhance your experience. by continuing to visit this site you agree to our use of cookies.')}</Typography>
          </Grid>

          <Grid
            item xs={12}
          >
            <Button
              color='info'
              size='sm'
              id='acceptConsentBtn'
              onClick={handleApproval}
            >
              {t('Accept all cookies')}
            </Button>
          </Grid>

        </Grid>
      </div>
    </Modal>
  )
}

export default withStyles(cookieConsentStyle)(withTranslation()(CookieConsent))
