import { InputBase, Tab, Tabs, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import addCampaignModalStyle from '../../../assets-soapy/jss/soapy/views/addCampaignModalStyle.jsx'
import Card from '../../../components-soapy/Card/Card.jsx'
import LoadingButton from '../../../components-soapy/LoadingButton.jsx'
import Modal from '../../../components-soapy/Modal.jsx'
import ProceedModal from '../../../components-soapy/ProceedModal.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import addCampaign from '../../../endpoint-requests/campaign/addCampaign.js'
import editCampaign from '../../../endpoint-requests/campaign/editCampaign.js'
import useFormFields from '../../../shared/hooks/useFormFields.js'
import { getImageDimensions } from '../../../utils/image.js'
import validateFormFields from '../../../utils/validateFormFields.js'
import { verifyLength } from '../../../utils/validators'
import { DESCRIPTION, NAME, RINSING_ITEMS, VALIDATION_STATE, WELCOME_ITEMS } from '../constants.js'
import CampaignItemCards from './CampaignItemCards.jsx'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useDispatch, useSelector } from "react-redux";
import { popUpSuccess } from '../../../redux/popUpReducer.js'
import CampaignOrientationModal from './CampaignOrientationModal.js'
import OrientationFilesModal from './OrientationFilesModal.js'
import { campaignMonitorOrientationSuccess } from '../../../redux/campaignReducer.js'

const MAX_IMAGE_SIZE = 3 // in mb
const ITEM_SIZE_1 = [1600, 900]
const ITEM_SIZE_2 = [1050, 1400]
const MAX_RINSING_ITEM_COUNT = 10
const MAX_WELCOME_ITEM_COUNT = 10
const IMAGE_TYPE = ["png", "jpg", "jpeg"]

let spinnerTimeout = null

const AddCampaignModal = ({
  classes,
  campaignEdited,
  campaignAdded,
  currentDashboardUser,
  showErrorNotification,
  campaign,
  onClose,
  isOpen,
  t
}) => {
  const dispatch = useDispatch()
  const open = useSelector((state) => state.popUp.open)
  const campaignMonitorOrientation = useSelector((state) => state.campaign.campaignMonitorOrientation)
  const campaignActive = useSelector((state) => state.campaign.campaignActive)
  const [addOrientation, setAddOrientation] = useState("horizontal")
  const [asyncImageError, setAsyncImageError] = useState(false)


  const isEdit = !!campaign.id
  const { formFields, setFormFields } = useFormFields({
    [NAME]: {
      value: '',
      isRequired: true,
      name: 'Name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 100),
          message: 'Name should not be empty, and up to 100 characters'
        }
      ]
    },
    [DESCRIPTION]: {
      value: '',
      isRequired: false,
      name: 'Name',
      validators: [
        {
          isValid: (value) => verifyLength(value, 1, 200),
          message: 'Name should not be empty, and up to 200 characters'
        }
      ]
    },

    [RINSING_ITEMS]: {
      value: [
        {
          file: null,
          url: null,
          campaignItemId: null
        }
      ],
      isRequired: false,
      name: 'Rinsing Items',
      validators: [
        {
          isValid: async function (items) {
            for (const [index, item] of items.entries()) {
              if (!item.file) continue
              const { width, height } = await getImageDimensions(URL.createObjectURL(item.file))
              if (!imageSizeCheck(width, height)) {
                this.message = `Item${index + 1} resolution should be 1600x900 or 1050x1400`
                item[VALIDATION_STATE] = 'error'
                return false
              }
            }
            return true
          }
        },
        {
          isValid: function (items) {
            for (const [index, item] of items.entries()) {
              if (!item.file) continue
              if (!(item.file.size / 1024 / 1024 < MAX_IMAGE_SIZE)) {
                this.message = `Welcome Screen ${index + 1} should be less than 3 mb`
                // temporary solution for item validation state
                item[VALIDATION_STATE] = 'error'
                return false
              }
            }
            return true
          }
        },
        {
          isValid: function (items) {
            for (const [index, item] of items.entries()) {
              if (!item.file) continue
              if (!(IMAGE_TYPE.includes(item.file?.type?.split("/")[1]))) {
                this.message = `Rinsing Screen ${index + 1} should not support ${item.file?.type?.split("/")[1]}`
                // temporary solution for item validation state
                item[VALIDATION_STATE] = 'error'
                return false
              }

            }
            return true
          }
        }
      ]
    },
    [WELCOME_ITEMS]: {
      value: [
        {
          file: null,
          url: null,
          campaignItemId: null,
          displayTime: null
        }
      ],
      isRequired: false,
      name: 'Welcome Items',
      validators: [
        {
          isValid: async function (items) {
            for (const [index, item] of items.entries()) {
              if (!item.file) continue
              const { width, height } = await getImageDimensions(URL.createObjectURL(item.file))
              if (!imageSizeCheck(width, height)) {
                this.message = `Item${index + 1} resolution should be 1600x900 or 1050x1400`
                // temporary solution for item validation state
                item[VALIDATION_STATE] = 'error'
                return false
              }
            }
            return true
          }
        },
        {
          isValid: function (items) {
            for (const [index, item] of items.entries()) {
              if (!item.file) continue
              if (!(item.file.size / 1024 / 1024 < MAX_IMAGE_SIZE)) {
                this.message = `Item${index + 1} should be less than 3 mb`
                // temporary solution for item validation state
                item[VALIDATION_STATE] = 'error'
                return false
              }
            }
            return true
          }
        },
        {
          isValid: function (items) {
            for (const [index, item] of items.entries()) {
              if (!item.file) continue
              if (!(IMAGE_TYPE.includes(item.file?.type?.split("/")[1]))) {
                this.message = `Welcome Screen ${index + 1} should not support ${item.file?.type?.split("/")[1]}`
                // temporary solution for item validation state
                item[VALIDATION_STATE] = 'error'
                return false
              }

            }
            return true
          }
        }
      ]
    }

  })

  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false)
  const handleTextChange = (event, fieldName) => {
    setFormFields({
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value: event.target.value },
      [fieldName + VALIDATION_STATE]: ''
    })
  }


  const handleItemChange = (value, index, itemType) => {
    setFormFields({
      ...formFields,
      [itemType]: {
        ...formFields[itemType],
        value: formFields[itemType].value.map((item, idx) => {
          return idx === index ? { ...item, file: value, url: null, [VALIDATION_STATE]: '' } : item
        })
      }
    }
    )
  }

  const handleOrientation = (e) => {

    if (isEdit) {
      if (campaignActive && campaignMonitorOrientation !== e.target.value) {
        if (checkFileOrUrlExist()) {
          dispatch(popUpSuccess(true))
          setFormFields({
            ...formFields,
            monitorOrientation: e.target.value,
          })
          dispatch(campaignMonitorOrientationSuccess(e.target.value))
        }
      }
      else {
        // if monitor orientation same 
        // dispatch(popUpSuccess(true))
        if (checkFileOrUrlExist()) {
          dispatch(popUpSuccess(true))
          setFormFields({
            ...formFields,
            monitorOrientation: e.target.value,
          })
          dispatch(campaignMonitorOrientationSuccess(e.target.value))
        }
        else {
          setFormFields({
            ...formFields,
            monitorOrientation: e.target.value,
          })
          dispatch(campaignMonitorOrientationSuccess(e.target.value))
        }
      }
    }
    else {
      //adding campaign
      if (formFields.rinsingItems?.value[0]?.file !== null ||
        formFields.welcomeItems?.value[0]?.file !== null) {
        dispatch(popUpSuccess(true))
        setAddOrientation(e.target.value)
        setFormFields({
          ...formFields,
          monitorOrientation: e.target.value,
        })
        dispatch(campaignMonitorOrientationSuccess(e.target.value))
      }
      else {
        setAddOrientation(e.target.value)
        setFormFields({
          ...formFields,
          monitorOrientation: e.target.value,
        })
        dispatch(campaignMonitorOrientationSuccess(e.target.value))
      }
    }
  }
  const imageSizeCheck = (width, height) => {
    return (width === ITEM_SIZE_1[0] && height === ITEM_SIZE_1[1]) ||
      (width === ITEM_SIZE_2[0] && height === ITEM_SIZE_2[1])
  }

  const handleDisplayTimeChange = (index, itemType, displayTime) => {
    setFormFields({
      ...formFields,
      [itemType]: {
        ...formFields[itemType],
        value: formFields[itemType].value.map((item, idx) => {
          return idx === index ? { ...item, displayTime, [VALIDATION_STATE]: '' } : item
        })
      }
    }
    )
  }

  useEffect(() => {
    setFormFields(
      (formFields) => ({
        [NAME]: { ...formFields[NAME], value: campaign[NAME] || '' },
        [DESCRIPTION]: { ...formFields[DESCRIPTION], value: campaign[DESCRIPTION] || '' },
        monitorOrientation: campaignMonitorOrientation,
        [WELCOME_ITEMS]: {
          ...formFields[WELCOME_ITEMS],
          value: campaign && campaign.campaignItems
            ? campaign.campaignItems.reduce((acc, campaignItem) => {
              if (campaignItem.place === 'welcome') {
                acc.push(
                  {
                    file: null,
                    url: campaignItem.url,
                    campaignItemId: campaignItem.id,
                    displayTime: campaignItem.displayTime
                  }
                )
              }
              return acc
            }, [])
            : [{
              file: null,
              displayTime: 3
            }]
        },
        [RINSING_ITEMS]: {
          ...formFields[RINSING_ITEMS],
          value: campaign && campaign.campaignItems
            ? campaign.campaignItems.reduce((acc, campaignItem) => {
              if (campaignItem.place === 'rinsing') {
                acc.push(
                  {
                    file: null,
                    url: campaignItem.url,
                    campaignItemId: campaignItem.id
                  }
                )
              }
              return acc
            }, [])
            : [{
              file: null
            }]
        }
      })
    )
    return () => {
      clearTimeout(spinnerTimeout)
      spinnerTimeout = null
    }
  }, [campaign, isOpen, isEdit, currentDashboardUser]) // eslint-disable-line react-hooks/exhaustive-deps

  const activateSpinner = () => {
    spinnerTimeout = setTimeout(
      function () {
        setIsAwaitingResponse(true)
      },
      300
    )
  }
  const disableSpinner = () => {
    clearTimeout(spinnerTimeout)
    setIsAwaitingResponse(false)
  }

  const [tabIndex, setTabIndex] = React.useState(0)

  function appendCampaignItemsToFormData(formData, place) {
    for (const item of formFields[place === 'rinsing' ? RINSING_ITEMS : WELCOME_ITEMS].value) {
      if (item.file) {
        if (place === 'welcome') {
          formData.append('welcomeDisplayTimes', item.displayTime || '3')
        }
        formData.append(place === 'rinsing' ? 'rinsing' : 'welcome', item.file, item.file.name)
        if (isEdit) {
          item.campaignItemId && formData.append(place === 'rinsing' ? 'rinsingCampaignItemIds' : 'welcomeCampaignItemIds', item.campaignItemId)
        }
      } else {
        // if only display time is changed, we need to edit only display time
        if (isEdit && item.campaignItemId && item.url && item.displayTime && place === 'welcome') {
          formData.append('welcomeDisplayTimesEdit', item.displayTime + '&&' + item.campaignItemId)
        }
        if (isEdit && !item.url && item.campaignItemId) {
          formData.append('campaignItemsToRemove', item.campaignItemId)
        }
      }
    }
  }

  const submitForm = async (proceed = false) => {
    activateSpinner()
    const formData = new FormData()
    formData.append('name', formFields[NAME].value)
    formData.append('description', formFields[DESCRIPTION].value)
    formData.append('monitorOrientation', formFields.monitorOrientation)
    appendCampaignItemsToFormData(formData, 'rinsing')
    appendCampaignItemsToFormData(formData, 'welcome')

    if (!proceed && isEdit) {
      for (const site of campaign.sites) {
        const isActive = _.get(site, 'siteConfiguration.isCampaignActive')
        if (isActive) {
          disableSpinner()
          return setIsProceedModalOpen(true)
        }
      }
    }
    try {
      let newCampaign
      if (isEdit) {
        newCampaign = await editCampaign(formData, campaign.id)
      } else {
        newCampaign = await addCampaign(formData)
      }
      disableSpinner()
      isEdit ? campaignEdited(newCampaign) : campaignAdded(newCampaign)
    } catch (err) {
      setAsyncImageError(true)
      showErrorNotification(`Only png/jpeg/jpg image type is valid`)
    }
    disableSpinner()
  }

  const processForm = async () => {
    try {
      const { isValid, error } = await validateFormFields(formFields)
      console.log({ isValid, error });
      if (isValid) {
        submitForm()
      } else {
        showErrorNotification(error.message)
        changeValidationStateToError(error.affectedFieldsNames)
      }
    } catch (error) {
      setAsyncImageError(true)
      showErrorNotification(`Only png/jpeg/jpg image type is valid`)
    }
  }
  const changeValidationStateToError = affectedFieldsNames => {
    affectedFieldsNames.forEach(fieldName => {
      setFormFields({ ...formFields, [fieldName + VALIDATION_STATE]: 'error' })
    })
  }
  const handleSaveBtnClick = () => {
    processForm()
  }

  const checkFileOrUrlExist = () => {
    let rinsingItemsStatus = "false";
    let welcomeItemsStatus = "false";
    if (formFields.rinsingItems.value.length !== 0) {
      if (formFields.rinsingItems.value[0].file !== null ||
        formFields.rinsingItems.value[0].url !== undefined) {
        rinsingItemsStatus = "true";
      }
    }
    if (formFields.welcomeItems.value.length !== 0) {
      if (formFields.welcomeItems.value[0].file !== null ||
        formFields.welcomeItems.value[0].url !== undefined) {
        welcomeItemsStatus = "true";
      }
    }
    if (rinsingItemsStatus === "true" || welcomeItemsStatus === "true") {
      return true;
    }
    return false;
  }

  const handleAddItem = (itemType) => {
    setFormFields({
      ...formFields,
      [itemType]: {
        ...formFields[itemType],
        value: [
          ...formFields[itemType].value,
          {
            file: null,
            ...(itemType === WELCOME_ITEMS && { displayTime: 3 })
          }
        ]
      }
    })
  }


  // const handleRemoveLastItem = () => {
  //   setFormFields({
  //     ...formFields,
  //     [ITEMS]: {
  //       ...formFields[ITEMS],
  //       value: formFields[ITEMS].value.slice(0, formFields[ITEMS].value.length - 1)
  //     }
  //   })
  // }

  useEffect(() => {
    return () => {
      disableSpinner()
    }
  }, [])
  return (
    <Modal
      isOpen={isOpen}
      t={t}
      hideActions
      showGoBackButton
      dialogPaper={classes.dialogPaper}
      onCancel={() => {
        disableSpinner()
        setAddOrientation("horizontal")
        onClose()
        setAsyncImageError(false)
      }}
      title={!campaign || !Object.keys(campaign).length ? t('Add Campaign') : t('Edit Campaign')}
    >
      {open && isEdit ?
        campaignActive ?
          <CampaignOrientationModal
            onCancel={() => {
              dispatch(popUpSuccess(false))
              disableSpinner()
              onClose()
            }}
            setFormFields={setFormFields}
            formFields={formFields} /> :
          <OrientationFilesModal setFormFields={setFormFields} formFields={formFields} checkFileOrUrlExist={checkFileOrUrlExist} setAddOrientation={setAddOrientation} /> :
        <OrientationFilesModal setFormFields={setFormFields} formFields={formFields} checkFileOrUrlExist={checkFileOrUrlExist} setAddOrientation={setAddOrientation} />}

      <ProceedModal
        isOpen={isProceedModalOpen}
        onApply={async () => {
          setIsProceedModalOpen(false)
          await submitForm(true)
        }}
        onClose={() => setIsProceedModalOpen(false)}
        msg="Campaign is active in one or more sites, do you still want to proceed?"
      />
      <GridContainer id="content-pane-layout" justify="center">
        <GridItem md={12}>
          <Card style={{ boxShadow: 'none' }}>
            <CardBody style={{ marginLeft: '40px' }}>
              <GridContainer>
                <GridItem
                  md={4}
                  style={{
                    marginBottom: '2rem'
                  }}
                >
                  <Typography style={{ fontSize: '1.2rem' }}>Name*</Typography>
                </GridItem>
                <GridItem
                  md={8}
                >
                  <InputBase
                    id="name"
                    style={{
                      width: '20rem'
                    }}
                    inputProps={{
                      className: classes.input
                    }}
                    className={formFields[NAME + VALIDATION_STATE] === 'error' ? classes.inputError : ''}
                    value={formFields[NAME].value}
                    onChange={event => {
                      handleTextChange(event, NAME)
                    }}
                  />
                </GridItem>
                <GridItem
                  style={{
                    marginTop: '0.8rem',
                    marginBottom: '2rem',
                  }}
                >
                  <FormControl>
                    <GridContainer >
                      <GridItem md={4} style={{ width: '60rem', marginTop: '10px', }}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '1.2rem' }}>Monitor Orientation</FormLabel>
                      </GridItem>
                      <GridItem style={{
                        width: '22rem'
                      }} >
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={isEdit ? campaignMonitorOrientation : addOrientation}
                          // value={orientation}
                          onChange={(e) => {
                            handleOrientation(e);
                          }}
                          style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                          <FormControlLabel name="monitorOrientation" value="horizontal" style={{ color: "black", letterSpacing: "3px" }} control={<Radio color={'primary'} />} label="Horizontal" />
                          <FormControlLabel name="monitorOrientation" value="vertical" style={{ color: "black", letterSpacing: "3px" }} control={<Radio color={'primary'} />} label="Vertical" />
                        </RadioGroup>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem
                  md={4}
                  style={{
                    marginBottom: '2rem'
                  }}
                >
                  <Typography style={{ fontSize: '1.2rem' }}>Description</Typography>
                </GridItem>
                <GridItem
                  md={8}
                >
                  <InputBase
                    id="name"
                    style={{
                      width: '20rem'
                    }}
                    inputProps={{
                      className: classes.input
                    }}
                    className={formFields[DESCRIPTION + VALIDATION_STATE] === 'error' ? classes.inputError : ''}
                    value={formFields[DESCRIPTION].value}
                    onChange={event => {
                      handleTextChange(event, DESCRIPTION)
                    }}
                  />
                </GridItem>
                <GridItem md={4}>
                  <GridContainer>
                    <GridItem
                      md={12}
                    >
                      <Typography style={{ fontSize: '1rem' }}>Notes:</Typography>
                      <ul>
                        <li><Typography>Each screen can have up to 10 images.</Typography></li>
                        {
                          tabIndex === 1
                            ? <li><Typography><b>Welcome screen pictures will be shown in a loop for the defined period
                              of time for each picture, while CleanMachine is at standby mode.</b></Typography></li>
                            : tabIndex === 0
                              ? <li><Typography><b>Rinsing screen pictures will be shown one by one for each handwashing
                                sequence. A new handwashing sequence will show the next uploaded picture from the defined
                                campaign.</b></Typography></li>
                              : null
                        }
                        <li><Typography>Supported resolutions are: 1600x900 and 1050x1400</Typography></li>
                        <li><Typography>Supported formats are: png and jpg</Typography></li>
                        <li><Typography>Maximum image size is 3 MB.</Typography></li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem
                  md={8}
                >
                  <Tabs
                    value={tabIndex}
                    onChange={(event, newValue) => {
                      setTabIndex(newValue)
                    }}
                    style={{
                      marginBottom: '2rem'
                    }}
                    variant="standard"
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label={t('Rinsing Screen')} />
                    <Tab label={t('Welcome Screen')} />
                  </Tabs>
                  {
                    tabIndex === 0
                      ? <CampaignItemCards
                        setAsyncImageError={setAsyncImageError}
                        asyncImageError={asyncImageError}
                        items={formFields[RINSING_ITEMS].value}
                        maxCount={MAX_RINSING_ITEM_COUNT}
                        handleItemChange={handleItemChange}
                        itemName={t('Rinsing Screen')}
                        handleAddItem={handleAddItem}
                        itemType={RINSING_ITEMS}
                      />
                      : <CampaignItemCards
                        setAsyncImageError={setAsyncImageError}
                        asyncImageError={asyncImageError}
                        items={formFields[WELCOME_ITEMS].value}
                        maxCount={MAX_WELCOME_ITEM_COUNT}
                        showDisplayTime
                        itemName={t('Welcome Screen')}
                        handleItemChange={handleItemChange}
                        handleDisplayTimeChange={handleDisplayTimeChange}
                        handleAddItem={handleAddItem}
                        itemType={WELCOME_ITEMS}
                      />
                  }

                </GridItem>

              </GridContainer>
              <GridContainer
                justify="center"
                alignItems="center"
                id="card-footer-section"
              >
                <GridItem>
                  <LoadingButton
                    color="info"
                    type="submit"
                    id="campaignAddSaveButton"
                    onClick={(e) => {
                      e.preventDefault()
                      handleSaveBtnClick()
                    }}
                    fullWidth
                    disabled={isAwaitingResponse}
                    isLoading={isAwaitingResponse}
                  >
                    {t('Save')}
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Modal>
  )
}

export default withErrorNotification(withAuthentication(withStyles(addCampaignModalStyle)(withTranslation()(AddCampaignModal))))
