import { getShiftData } from './getShiftData'

//getShifts
export const getShifts = async (shiftNumber, previousShift,
  currentShift, currentFrom, currentTo,
  previousFrom, previousTo, sitesIds, minimumWashes, washersIds, minimumResultScore) => {
  try {
    let currentShiftListData
    let previousShiftListData

    const ctFrom = currentFrom !== '' ? new Date(currentFrom).toISOString() : ''
    const ctTo = currentTo !== '' ? (new Date(new Date(new Date(currentTo).toISOString()) - 1000)).toISOString() : '' //subtracting one sec from to hour
    const prFrom = previousFrom !== '' ? new Date(previousFrom).toISOString() : ''
    const prTo = previousTo !== '' ? (new Date(new Date(new Date(previousTo).toISOString()) - 1000)).toISOString() : '' //subtracting one sec from to hour

    const {
      currentTotalWashes,
      currentTotalHygenicWashes,
      currentAveragedResult,
      currentWashesLeft,
      previousTotalWashes,
      previousTotalHygenicWashes,
      previousAveragedResult,
      previousWashesLeft,
      previousChampion,
      currentChampion
    } = await getShiftData(prFrom, prTo, ctFrom, ctTo, sitesIds, minimumWashes, washersIds, minimumResultScore)

    currentShiftListData = {
      shift: currentShift,
      totalWashes: currentTotalWashes,
      totalHygenicWashes: currentTotalHygenicWashes,
      averagedResult: currentAveragedResult,
      washesLeft: currentWashesLeft,
      currentChampion: currentChampion,

    }
    previousShiftListData = {
      shift: previousShift,
      totalWashes: previousTotalWashes,
      totalHygenicWashes: previousTotalHygenicWashes,
      averagedResult: previousAveragedResult,
      washesLeft: previousWashesLeft,
      previousChampion: previousChampion,

    }

    return { previousShiftListData, currentShiftListData }
  } catch (error) {
    console.log(error)
  }
}