import React from 'react';

import LogoutModalContext from './LogoutModalContext.js';

const withLogoutModal = Component => {
  const WithLoginModal = props => {
    return (
      <LogoutModalContext.Consumer>
        {logoutModalContext => <Component {...props} {...logoutModalContext} />}
      </LogoutModalContext.Consumer>
    );
  };

  return WithLoginModal;
};

export default withLogoutModal;
