import resolveRequest from '../../utils/resolveRequest.js'
import { SERVER_PATH, apiEndpoints } from '../endpoints.js'
import requestKeywords from '../../shared/data/daily-machine-usage.js'
import axios from 'axios'

export default async (sites, fromDate, toDate, washersIds, minimumResultScore) => {
    const URL = SERVER_PATH + apiEndpoints.championsResults
    const requestBody = {
        [requestKeywords.SITES]: sites,
        [requestKeywords.FROM_DATE]: fromDate,
        [requestKeywords.TO_DATE]: toDate,
        [requestKeywords.WASHERS_IDS]: washersIds,
        [requestKeywords.MINIMUM_RESULT_SCORE]: minimumResultScore,
    }
    return resolveRequest(axios.post(URL, requestBody, {
        withCredentials: true
    }))
}
