import { getChampionsList } from "./getChampionsList"
import { getCurrentDailyMachineUsages } from "./getCurrentDailyMachineUsages"
import { getPreviousDailyMachineUsages } from "./getPreviousDailyMachineUsages"

//getShiftData
export const getShiftData = async (prFrom, prTo, ctFrom, ctTo, sitesIds, minimumWashes, washersIds, minimumResultScore) => {
    let previousChampion = ""
    let currentChampion = ""
    try {
        if (ctFrom === "" && ctTo === "") {
            const { previousTotalWashes, previousTotalHygenicWashes, previousAveragedResult, previousWashesLeft } = await getPreviousDailyMachineUsages(prFrom, prTo, sitesIds, minimumWashes, washersIds)
            const previousChampions = await getChampionsList(sitesIds, prFrom, prTo, washersIds, minimumResultScore)
            if (previousChampions.length > 0) {
                previousChampion = previousChampions[0].washer ? previousChampions[0].washer.name : ""
            }
            return { currentTotalWashes: 0, currentTotalHygenicWashes: 0, currentAveragedResult: 0, currentWashesLeft: 0, previousTotalWashes, previousTotalHygenicWashes, previousAveragedResult, previousWashesLeft, previousChampion, currentChampion }
        }
        if (prFrom === "" && prTo === "") {
            const { currentTotalWashes, currentTotalHygenicWashes, currentAveragedResult, currentWashesLeft } = await getCurrentDailyMachineUsages(ctFrom, ctTo, sitesIds, minimumWashes, washersIds)
            const currentChampions = await getChampionsList(sitesIds, ctFrom, ctTo, washersIds, minimumResultScore)
            if (currentChampions.length > 0) {
                currentChampion = currentChampions[0].washer ? currentChampions[0].washer.name : ""
            }
            return { currentTotalWashes, currentTotalHygenicWashes, currentAveragedResult, currentWashesLeft, previousTotalWashes: 0, previousTotalHygenicWashes: 0, previousAveragedResult: 0, previousWashesLeft: 0, previousChampion, currentChampion }
        }
        if (ctFrom && ctTo && prFrom && prTo) {
            const { previousTotalWashes, previousTotalHygenicWashes, previousAveragedResult, previousWashesLeft } = await getPreviousDailyMachineUsages(prFrom, prTo, sitesIds, minimumWashes, washersIds)
            const { currentTotalWashes, currentTotalHygenicWashes, currentAveragedResult, currentWashesLeft } = await getCurrentDailyMachineUsages(ctFrom, ctTo, sitesIds, minimumWashes, washersIds)
            const previousChampions = await getChampionsList(sitesIds, prFrom, prTo, washersIds, minimumResultScore)
            const currentChampions = await getChampionsList(sitesIds, ctFrom, ctTo, washersIds, minimumResultScore)

            if (previousChampions.length > 0) {
                previousChampion = previousChampions[0].washer ? previousChampions[0].washer.name : ""
            }
            if (currentChampions.length > 0) {
                currentChampion = currentChampions[0].washer ? currentChampions[0].washer.name : ""
            }

            return { currentTotalWashes, currentTotalHygenicWashes, currentAveragedResult, currentWashesLeft, previousTotalWashes, previousTotalHygenicWashes, previousAveragedResult, previousWashesLeft, previousChampion, currentChampion }
        }
    } catch (error) {
        console.log(error);
    }
}