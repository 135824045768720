import axios from 'axios';

import {apiEndpoints, SERVER_PATH} from '../endpoints.js';
import failedSession from "../failedSession";

const ReagentSuppliers = async () => {
    const URL = SERVER_PATH + apiEndpoints.reagentSuppliers;
    try {
        return await axios.get(URL, {withCredentials: true});
    } catch (e) {
        failedSession(e);
        return {error: 'unknown'};
    }
};

export default ReagentSuppliers;
