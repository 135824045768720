exports.ENDPOINT_URL = '/daily-washes'
exports.ENDPOINT_URL_MULTIMACHINES = '/daily-washes/multiMachines'

exports.DAILY_WASHES_STATISTICS = 'dailyWashesStatistics'
exports.WASH_PERCENTAGE = 'washPercentage'
exports.IS_FAILED = 'isFailed'
exports.DATE = 'date'
exports.TEMPERATURE_THRESHOLD = 'temperatureThreshold'
exports.FROM_DATE = 'fromDate'
exports.TO_DATE = 'toDate'
exports.TIMEZONE = 'timeZone'
exports.MACHINE_ID = 'machineId'
exports.REAGENT = 'reagent'
exports.MACHINE_IDs = 'machineIds'
exports.ERROR_TYPE = 'errorType'
exports.MACHINE_ID_NOT_BELONG_TO_DASHBOARD_USER = 'machineIdNotBelongToDashboardUser'
exports.WASHER = 'washer'
exports.ID = 'id'
exports.WASH_METRICS = 'washMetrics'
exports.DID_USE_SOAP = 'didUseSoap'
exports.DID_LATHER = 'didLather'
exports.RINSE_CYCLES_OUTCOMES = 'rinseCyclesOutcomes'
exports.RINSE_ALL_CYCLES_OUTCOMES = 'rinseAllCyclesOutcomes'
exports.DAILY_WASHES = 'dailyWashes'
exports.STATISTIC_DAILY_WASHES = 'statisticDailyWashes'
exports.DURING_LATHERING = 'duringLathering'
exports.DURING_RINSING = 'duringRinsing'
exports.FULL_TIME = 'fullTime'
exports.is_STATUS = 'isStatus'
exports.LATHERING_TIME = 'latheringTime'
exports.TEMPERATURE = 'temperature'
exports.RINSING_TIME = 'rinsingTime'
exports.HANDS_RECOGNITION = 'handsRecognition'
exports.SITE = 'site'
exports.SITE_INTERNAL_AREA = 'siteInternalArea'
exports.WASHER_ID = 'washerId'
