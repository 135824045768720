/**
 * @typedef {import('../shared/hooks/useFormFields').formField} formField
 */

/**
 * @param {Object.<string, formField>} formFields
 */
// validates form fields
export default async (formFields) => {
  for (const fieldName in formFields) {
    if (typeof formFields[fieldName] !== 'object') continue
    const fieldValue = formFields[fieldName].value

    let isValid = true
    let message = ''
    // if value is empty, we only need to check if it's required or not, otherwise we need to execute it's validator functions
    if (!fieldValue || (Array.isArray(fieldValue) && !fieldValue.length)) {
      if (formFields[fieldName].isRequired) {
        message = `${formFields[fieldName].name} is required!`
        isValid = false
        if (typeof (formFields[fieldName].isRequired) === 'function') {
          isValid = !formFields[fieldName].isRequired(formFields)
        }
      }
    } else {
      if (!formFields[fieldName].validators || !formFields[fieldName].validators.length) {
        continue
      }
      for (const validator of formFields[fieldName].validators) {
        isValid = await Promise.resolve(validator.isValid(fieldValue))
        if (!isValid) {
          message = validator.message
          break
        }
      }
    }
    if (!isValid) {
      return {
        isValid: false,
        error: {
          message,
          affectedFieldsNames: [fieldName]
        }
      }
    }
  }

  return { isValid: true }
}
