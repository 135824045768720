import { createSlice } from "@reduxjs/toolkit";

const dailyWashesSlice = createSlice({
    name: "dailyWashes",
    initialState: {
        dailyWashes: [],
        dailyWashesSelectedMachineIds: [],
        dailyWashesFromDate: "",
        dailyWashesToDate: "",
        dailyWashesExcelData: [],

        isFetching: false,
        error: false,
    },
    reducers: {
        dailyWashesStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        dailyWashesSuccess: (state, action) => {
            state.isFetching = false;
            state.dailyWashes = action.payload;
        },
        dailyWashesFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        dailyWashesSelectedMachineIdsStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        dailyWashesSelectedMachineIdsSuccess: (state, action) => {
            state.isFetching = false;
            state.dailyWashesSelectedMachineIds = action.payload;
        },
        dailyWashesSelectedMachineIdsFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        dailyWashesFromDateStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        dailyWashesFromDateSuccess: (state, action) => {
            state.isFetching = false;
            state.dailyWashesFromDate = action.payload;
        },
        dailyWashesFromDateFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        dailyWashesToDateStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        dailyWashesToDateSuccess: (state, action) => {
            state.isFetching = false;
            state.dailyWashesToDate = action.payload;
        },
        dailyWashesToDateFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },
        dailyWashesExcelDataStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
            //no action required...u can remove action
        },
        dailyWashesExcelDataSuccess: (state, action) => {
            state.isFetching = false;
            state.dailyWashesExcelData = action.payload;
        },
        dailyWashesExcelDataFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            //no action required...u can remove action
        },

    },
});

export const {
    dailyWashesStart,
    dailyWashesSuccess,
    dailyWashesFailure,
    dailyWashesSelectedMachineIdsStart,
    dailyWashesSelectedMachineIdsSuccess,
    dailyWashesSelectedMachineIdsFailure,
    dailyWashesFromDateStart,
    dailyWashesFromDateSuccess,
    dailyWashesFromDateFailure,
    dailyWashesToDateStart,
    dailyWashesToDateSuccess,
    dailyWashesToDateFailure,
    dailyWashesExcelDataStart,
    dailyWashesExcelDataSuccess,
    dailyWashesExcelDataFailure,

} = dailyWashesSlice.actions;


const dailyWashesReducer = dailyWashesSlice.reducer; //it should be export default
export default dailyWashesReducer;