export const isEmail = value => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRegex.test(value)) {
    return true
  }
  return false
}

export const verifyLength = (value, minLength, maxLength) => {
  if (value.length >= minLength && value.length <= maxLength) {
    return true
  }
  return false
}
