import resolveRequest from '../../utils/resolveRequest.js'
import axios from 'axios'

import { apiEndpoints, SERVER_PATH } from '../endpoints.js'

const fetchLanguages = async () => {
  const URL = SERVER_PATH + apiEndpoints.multiLanguage
  return resolveRequest(await axios.get(URL, { withCredentials: true }))
}

export default fetchLanguages
