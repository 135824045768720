import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import routes from '../routes/routes.js'
import pagesStyle from '../assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx'
import bgImage from '../assets/img/register.jpeg'

class Pages extends React.Component {
  componentDidMount () {
    document.body.style.overflow = 'unset'
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <div className={classes.wrapper} ref='wrapper'>
          <div
            className={classes.fullPage}
            style={{ backgroundImage: 'url(' + bgImage + ')' }}
          >
            <Switch>
              {Object.values(routes).map((route, routesArrayIndex) => (
                <Route
                  exact
                  path={route.path}
                  component={route.component}
                  key={routesArrayIndex}
                />
              ))}
              <Redirect from='/' to='/login' />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(pagesStyle)(Pages)
